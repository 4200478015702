import React, {
  useCallback,
  useEffect,
  useContext,
  useState,
  createContext,
} from "react";
import {
  UNSAFE_NavigationContext as NavigationContext,
  useLocation,
  useNavigate,
  // unstable_useBlocker as useBlocker,
} from "react-router-dom";
import type { History } from "history";
// import type { unstable_Blocker as Blocker } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import Dialog from "@mui/material/Dialog";
import { Navigator } from "react-router";
import { LanguageContext } from "../localization/localization";
import CustomDialog, { ButtonType } from "./CustomDialog";

export type LeavePageContextType = {
  blockLeave: boolean;
  setBlockLeave: (blockLeave: React.SetStateAction<boolean>) => void;
};

export const LeavePageContext = createContext<LeavePageContextType>({
  blockLeave: false,
  setBlockLeave: (blockLeave) => null,
});

export const LeavePageContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [blockLeave, setBlockLeave] = useState<boolean>(false);

  return (
    <LeavePageContext.Provider value={{ blockLeave, setBlockLeave }}>
      {children}
    </LeavePageContext.Provider>
  );
};

// export const ConfirmLeavePage = ({ blocker }: { blocker: Blocker }) => {
//   const [open, setOpen] = useState<boolean>(blocker.state === "blocked");
export const ConfirmLeavePage = (props: any) => {
  const { open, onAccept, onClose, message } = props;
  const { words } = useContext(LanguageContext);
  const { setBlockLeave } = useContext(LeavePageContext);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleAccept = () => {
    handleClose();
    setBlockLeave(false);
    onAccept();
  };

  const handleClose = () => {
    setDialogOpen(false);
    onClose();
  };

  useEffect(() => setDialogOpen(open), [open]);

  return (
    <div>
      <CustomDialog
        title={words.setting_warning}
        message={message || words.leave_page_defalt_msg}
        buttonType={ButtonType.OkCancel}
        onAccept={handleAccept}
        onClose={handleClose}
        open={dialogOpen}
      />
    </div>
  );
};
