import React, { ReactNode, createContext, useState } from "react";

type ErrTitleContextType = {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  initTitleContext: () => void;
};

export const ErrorTitleContext = createContext<ErrTitleContextType>(
  {} as ErrTitleContextType
);

const ErrTitleContextProvider = ({ children }: { children: ReactNode }) => {
  const [title, setTitle] = useState("");
  const initTitleContext = () => {
    setTitle("");
  };
  return (
    <ErrorTitleContext.Provider
      value={{
        title: title,
        setTitle: setTitle,
        initTitleContext: initTitleContext,
      }}
    >
      {children}
    </ErrorTitleContext.Provider>
  );
};

export default ErrTitleContextProvider;
