// 需要調達計画
import React, { useContext, useReducer, useRef, useState } from "react";
import { ClipboardEvent } from "react";
import { useEffect } from "react";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../../common/styledComponents/styledTable";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import Typography from "@mui/material/Typography";
import { StyledButton } from "../../common/styledComponents/styledButton";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import SaveDataButton from "../../common/customComponents/SaveDataButton";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import {
  DateContext,
  GlobalDatePicker,
} from "../../common/customComponents/GlobalDatePicker";
import { format, isBefore, isSameDay, subDays } from "date-fns";
import { LanguageContext } from "../../common/localization/localization";
import { PaletteContext } from "../../common/paletteMode";
import axios from "axios";
import {
  demandPlan,
  demandPlanGroup,
  provideConsignmentList,
  provideAreaList,
  saleAreaList,
  postDemandPlanList,
  postSalePlanList,
  postProvidePlanList,
  postTransactionPlanList,
  planValues,
} from "../../../types/occto/DemandForecastPlan";
import { useLocation } from "react-router-dom";
import { cellTime } from "../../../common/cellTime";
import { elements } from "chart.js";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import { type } from "os";
import { RoleContext } from "../../common/customComponents/RoleProvider";
import { useAreaUtility } from "../../../common/area";
import { useVoltageLevelUtility } from "../../../common/voltageLevel";
import { AREA_ID } from "../../../common/constant";
import { useAuthedApi } from "../../../common/axios";

// エリア一覧
const AREA_LIST_URL = "/getDemandAreaNameList";
// エリア内需要計画情報
const GET_DEMAND_PLAN = "/demandPlan/getDemnadPlan";
const SET_DEMAND_PLAN = "/demandPlan/setDemnadPlan";
// 調達計画
const GET_SUPPLY_PLAN = "/demandPlan/getSupplyPlan";
const SET_SUPPLY_PLAN = "/demandPlan/setSupplyPlan";
// 販売計画
const GET_PROVIDE_PLAN = "/demandPlan/getProvidePlan";
const SET_PROVIDE_PLAN = "/demandPlan/setProvidePlan";
// 計画ヘッダ
const GET_DEMAND_HEADER = "/demandPlan/getDemandHeader";
// ロール判定
const GET_ROLE_JUDGMENT_URL = "/getIsEditableRolePlan";

const DemandForecastPlan = () => {
  //言語を切り替えるデータコンテキスト
  const languageContext = useContext(LanguageContext);
  const { areaId, setAreaId } = useContext(AreaContext);
  const leavePageContext = useContext(LeavePageContext);
  const timeContext = useContext(TimeContext);
  // ログイン中ユーザのロール
  const roleContext = useContext(RoleContext);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  // 保存ボタンの表示・非表示
  const [hidden, setHidden] = useState<boolean>(true);

  const [error, setError] = useState<any>();
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [digOpen, setDigOpen] = useState(false);

    // 通信エラーを監視
    const api = useAuthedApi();

  const { convertIdToVolLevelName } = useVoltageLevelUtility();
  // 取引計画テーブル用
  const TABLE_DEFINE = [
    { label: languageContext.words.demand_plan, key: "survice" },
    { label: languageContext.words.procurement_plan, key: "demandGeneration" },
    { label: languageContext.words.sales_plan, key: "supply" },
    { label: languageContext.words.shortage_of_procurement, key: "sale" },
  ];

  // 販売計画テーブル用
  const TABLE_SALE_DEFINE = [
    { label: languageContext.words.spot, key: "spot" },
    { label: languageContext.words.hour_ago, key: "hour" },
  ];

  // 調達計画テーブル用
  const TABLE_PROCUREMENT_DEFINE = [
    { label: languageContext.words.internal_other_bg, key: "otherBg" },
    { label: languageContext.words.spot, key: "spot" },
    { label: languageContext.words.hour_ago, key: "hour" },
  ];

  const CELLCOUNT = 48;

  //エリア一覧
  const [areas, setAreas] = useState<string[]>([]);
  const { areaOptions, convertIdToAreaName } = useAreaUtility();
  const currentDate = useContext(DateContext);
  const [lastUpdateInfo, setLastUpdateInfo] = useState<{
    date: Date;
    user: string;
  }>();
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpenOkCancel, setDigOpenOkCancel] = useState<boolean>(false);
  const [digMessageOkCancel, setDigMessageOkCancel] = useState<string>("");

  // 需要計画(供給力合計)
  const [demandPlanData, setDemandPlanData] = useState<number[]>(
    new Array(48).fill(0)
  );
  // 調整量合計
  const [dealTotal, setDealTotal] = useState<number[]>(new Array(48).fill(0));
  // 需要計画
  const [allDemandPlan, setAllDemandPlan] = useState<demandPlan[]>([]);
  // 需要計画構成(特高、高圧、低圧)
  const [allDemandCategory, setAllDemandCategory] = useState<demandPlanGroup[]>(
    []
  );
  // 自己託送(調達)
  const [selfProcurementData, setSelfProcurementData] = useState<
    provideConsignmentList[]
  >([]);
  // スポット(調達)
  const [spotProcurementData, setSpotProcurementData] = useState<
    provideAreaList[]
  >([]);
  // 1時間前(調達)
  const [hourProcurementData, setHourProcurementData] = useState<
    provideAreaList[]
  >([]);
  // 調達合計
  const [procurementTotal, setProcurementTotal] = useState<number[]>(
    new Array(48).fill(0)
  );
  // スポット(販売)
  const [spotSalesData, setSpotSalesData] = useState<saleAreaList[]>([]);
  // 1時間前(販売)
  const [hourSalesData, setHourSalesData] = useState<saleAreaList[]>([]);
  // 販売合計
  const [salesTotal, setSalesTotal] = useState<number[]>(new Array(48).fill(0));

  //現在時刻をコマの番号に変換
  const convertTimeToCd = (time: Date) => {
    let timeCd = 0;
    cellTime.forEach((value, index) => {
      if (
        time.toLocaleTimeString().slice(0, -3).padStart(5, "0") >= value &&
        index !== 48
      ) {
        timeCd = index;
      }
    });
    return timeCd;
  };

  //saletype --スポットの時に当日だったら非活性にする用
  const isDisabled = (index: number, type: string) => {
    const timeCd = convertTimeToCd(timeContext.time);
    //スポット
    if (type === "spot") {
      if (
        format(timeContext.time, "yyyy-MM-dd") >
          format(currentDate.pickDate as Date, "yyyy-MM-dd") ||
        format(timeContext.time, "yyyy-MM-dd") ===
          format(currentDate.pickDate as Date, "yyyy-MM-dd")
      ) {
        return true;
      }
      return false;
    } else {
      //スポット以外
      if (
        format(timeContext.time, "yyyy-MM-dd") >
          format(currentDate.pickDate as Date, "yyyy-MM-dd") ||
        (format(timeContext.time, "yyyy-MM-dd") ===
          format(currentDate.pickDate as Date, "yyyy-MM-dd") &&
          timeCd + 2 >= index)
      ) {
        return true;
      }
      return false;
    }
  };

  //不整合表示用フラグ
  const isMismatch = (chgCd?: string): boolean => {
    return Boolean(chgCd == "2");
  };

  const DemandTable = 2;
  const SalesTable = 3;
  const ProcurementTable = 4;
  // タブによるテーブルの切替
  const [tableType, setTabletype] = useState<number>(DemandTable);

  //計算に使う用に、IDと名称をセットにして、配列にしておく
  type searchInfo = { id: number; name: string };
  const demandInfos: searchInfo[] = []; // 需要家一覧
  const [demandNamesList, setDemandNamesList] = useState(demandInfos);

  // 初期検索条件の取得
  const url = useLocation().search;
  const query = new URLSearchParams(url);
  useEffect(() => {
    dicideSaveButtonVisibility();
    // 初期検索
    initAreaList();
    leavePageContext.setBlockLeave(false);
  }, []);

  useEffect(() => {
    // 日付、またはエリアが変更になった
    if (areaId !== AREA_ID.NONE) {
      getDemandHd();
      getDemandPlan();
      getProvidePlan();
      getSupplyPlan();
    }
  }, [areaId, currentDate]);

  useEffect(() => {
    // 需要計画が更新された
    const total: number[] = new Array(48).fill(0);
    // 自動計算部分
    for (let index = 0; index < CELLCOUNT; index++) {
      total[index] =
        demandPlanData[index] + (salesTotal[index] - procurementTotal[index]);
    }
    setDealTotal(total);
  }, [demandPlanData, procurementTotal, salesTotal]);

  useEffect(() => {
    // 需要計画が更新された
    const total: number[] = new Array(48).fill(0);
    // 自動計算部分
    for (let index = 0; index < CELLCOUNT; index++) {
      allDemandPlan.forEach((element: demandPlan) => {
        total[index] =
          total[index] + checkNumber(element.valueList[index].value);
      });
    }
    setDemandPlanData(total);
  }, [allDemandPlan, ignored]);

  useEffect(() => {
    // 販売計画が更新された
    const total: number[] = new Array(48).fill(0);
    // 自動計算部分
    for (let index = 0; index < CELLCOUNT; index++) {
      spotSalesData.forEach((element: saleAreaList) => {
        total[index] =
          total[index] + checkNumber(element.valueList[index].value);
      });
      hourSalesData.forEach((element: saleAreaList) => {
        total[index] =
          total[index] + checkNumber(element.valueList[index].value);
      });
    }

    setSalesTotal(total);
  }, [spotSalesData, hourSalesData, ignored]);

  useEffect(() => {
    // 調達計画が更新された
    const total: number[] = new Array(48).fill(0);
    // 自動計算部分
    for (let index = 0; index < CELLCOUNT; index++) {
      selfProcurementData.forEach((element: provideConsignmentList) => {
        total[index] =
          total[index] + checkNumber(element.valueList[index].value);
      });
      spotProcurementData.forEach((element: provideAreaList) => {
        total[index] =
          total[index] + checkNumber(element.valueList[index].value);
      });
      hourProcurementData.forEach((element: provideAreaList) => {
        total[index] =
          total[index] + checkNumber(element.valueList[index].value);
      });
    }

    setProcurementTotal(total);
  }, [selfProcurementData, spotProcurementData, hourProcurementData, ignored]);

  // ログインユーザのロールによって保存ボタンの表示・非表示を切り替える
  const dicideSaveButtonVisibility = () => {
    api
      .get(GET_ROLE_JUDGMENT_URL, { params: { userRole: roleContext.role } })
      .then((res) => {
        setHidden(res.data ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // エリア名
  const initAreaList = () => {
    api
      .get(AREA_LIST_URL, { params: null })
      .then((res) => {
        const areas = res.data.map((v: any) => v.areaId);
        setAreas(areas);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  // 計画ヘッダ取得
  const getDemandHd = () => {
    const requestParam = {
      areaId: areaId,
      targetDate: format(currentDate.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_DEMAND_HEADER, { params: requestParam })
      .then((res) => {
        if (
          res.data !== undefined &&
          res.data.lastUpdate !== undefined &&
          res.data.lastUpdateUser !== undefined &&
          res.data.lastUpdate !== null &&
          res.data.lastUpdateUser !== null
        ) {
          setLastUpdateInfo({
            date: new Date(res.data.lastUpdate),
            user: res.data.lastUpdateUser,
          });
        } else {
          setLastUpdateInfo(undefined);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 需要計画取得
  function getDemandPlan() {
    // 需要一覧の取得
    api
      .post(GET_DEMAND_PLAN, {
        areaId: areaId,
        targetDate: currentDate.pickDate?.toLocaleDateString(),
        demandList: demandNamesList,
      })
      .then((res) => {
        const category: demandPlanGroup[] = [];
        console.log("需要計画", res.data);
        // 需要家が取れなかった場合
        if (res.data.demandPlan.length == 0) {
          // TODO エラー表示
        } else {
          res.data.demandPlan.forEach((element: demandPlan) => {
            if (element.valueList.length == 0) {
              // 初期値を投入
              element.valueList = new Array(48).fill(true).map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                value: 0,
              }));
            }

            if (category.some((e) => e.volCategory == element.volCategory)) {
              // 既に確保済みのカテゴリの場合
              category.forEach((item) => {
                if (item.volCategory == element.volCategory) {
                  // 需要家を追加
                  item.demandId.push(element.demandId);
                }
              });
            } else {
              const cate: demandPlanGroup = {
                demandId: [],
                volCategory: element.volCategory,
              };
              cate.demandId.push(element.demandId);
              category.push(cate);
            }
          });

          const value: number[] = new Array(48).fill(0);
          const dataChgCd: string[] = new Array(48).fill("0");

          let timecd = "";
          let timecdcount = 0;
          //全量
          for (let i = 0; i < res.data.demandPlan[0].valueList.length; i++) {
            if (i == 0) {
              //初回
              timecd = res.data.demandPlan[0].valueList[i].timeCd;
            }
            //timecdでグルーピングし、加算
            if (timecd == res.data.demandPlan[0].valueList[i].timeCd) {
              value[timecdcount] =
                Number(value[timecdcount]) +
                Number(res.data.demandPlan[0].valueList[i].value);
              dataChgCd[timecdcount] =
                Number(res.data.demandPlan[0].valueList[i].dataChgCd) >
                Number(dataChgCd[timecdcount])
                  ? res.data.demandPlan[0].valueList[i].dataChgCd
                  : dataChgCd[timecdcount];
            } else {
              //timecdが切り替わった
              timecdcount++;
              timecd = res.data.demandPlan[0].valueList[i].timeCd;
              value[timecdcount] =
                Number(value[timecdcount]) +
                Number(res.data.demandPlan[0].valueList[i].value);
              dataChgCd[timecdcount] =
                Number(res.data.demandPlan[0].valueList[i].dataChgCd) >
                Number(dataChgCd[timecdcount])
                  ? res.data.demandPlan[0].valueList[i].dataChgCd
                  : dataChgCd[timecdcount];
            }
          }

          //timecd 01のレコード
          const _planValues: planValues[] = [];

          for (let crevalue = 0; crevalue < 48; crevalue++) {
            _planValues.push({
              timeCd: ("00" + (crevalue + 1)).slice(-2),
              value: value[crevalue],
              dataChgCd: dataChgCd[crevalue],
            });
          }

          const _resdemandPlan: demandPlan[] = new Array({
            volCategory: res.data.demandPlan[0].volCategory,
            demandId: res.data.demandPlan[0].demandId,
            demandName: res.data.demandPlan[0].demandName,
            valueList: _planValues,
          });

          console.log(_resdemandPlan);

          setAllDemandPlan(_resdemandPlan);

          // 電圧カテゴリ毎にテーブル構成要素を作成
          setAllDemandCategory(category);
        }
      })
      .catch((error) => {
        setError(error);
      });
  }

  // 調達計画取得(発電所、JEPX)
  function getProvidePlan() {
    // 調達計画の取得
    api
      .post(GET_PROVIDE_PLAN, {
        areaId: areaId,
        targetDate: currentDate.pickDate?.toLocaleDateString(),
      })
      .then((res) => {
        // 計画値の取得(調達計画のデータがない場合は表示できない)
        if (res.data.selfConsignmentList != undefined) {
          res.data.selfConsignmentList.forEach(
            (element: provideConsignmentList) => {
              if (element.valueList.length == 0) {
                element.valueList = new Array(48)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
            }
          );
          setSelfProcurementData(res.data.selfConsignmentList);
        } else {
          // TODO エラーメッセージ
        }
            res.data.spotList.forEach((element: provideAreaList) => {
              if (element.valueList.length == 0) {
                element.valueList = new Array(48)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
            });
            setSpotProcurementData(res.data.spotList);
            res.data.oneHourList.forEach(
              (element: provideAreaList, index: number) => {
                if (element.valueList.length == 0) {
                  element.valueList = new Array(48)
                    .fill(true)
                    .map((_, index) => ({
                      timeCd: (index + 1).toString().padStart(2, "0"),
                      value: 0,
                    }));
                }
              }
            );
            setHourProcurementData(res.data.oneHourList);
      })
      .catch((error) => {
        setError(error);
      });
  }

  // 販売計画取得(JEPXのみ)
  function getSupplyPlan() {
    // 需要一覧の取得
    api
      .post(GET_SUPPLY_PLAN, {
        areaId: areaId,
        targetDate: currentDate.pickDate?.toLocaleDateString(),
      })
      .then((res) => {
        // 販売計画での事業者間やり取りはなし
            res.data.spotList.forEach((element: saleAreaList) => {
              if (element.valueList.length == 0) {
                element.valueList = new Array(48)
                  .fill(true)
                  .map((e: any) => ({ timeCd: "", value: 0 }));
              }
            });
            setSpotSalesData(res.data.spotList);

            res.data.oneHourList.forEach((element: saleAreaList) => {
              if (element.valueList.length == 0) {
                element.valueList = new Array(48)
                  .fill(true)
                  .map((e: any) => ({ timeCd: "", value: 0 }));
              }
            });
            setHourSalesData(res.data.oneHourList);
      })
      .catch((error) => {
        setError(error);
      });
  }

  //調達計画テキストフィールド50kWh単位丸め
  const updateBlurProcurementData = (
    event: any,
    i: number,
    j: number,
    type: string
  ) => {
    if (type === "spot") {
      spotProcurementData[i].valueList[j].value =
        Number(Math.floor(Number(event.target.value) / 50)) * 50;
      setSpotProcurementData(spotProcurementData);
    } else if (type === "hour") {
      // 一時間前
      hourProcurementData[i].valueList[j].value =
        Number(Math.floor(Number(event.target.value) / 50)) * 50;
      setHourProcurementData(hourProcurementData);
    }
    forceUpdate();
  };
  //販売計画テキストフィールド50kWh単位丸め
  const updateBlurSalesData = (
    event: any,
    i: number,
    j: number,
    type: string
  ) => {
    if (type === "spot") {
      spotSalesData[i].valueList[j].value =
        Number(Math.floor(Number(event.target.value) / 50)) * 50;
      setSpotSalesData(spotSalesData);
    } else if (type === "hour") {
      // 一時間前
      hourSalesData[i].valueList[j].value =
        Number(Math.floor(Number(event.target.value) / 50)) * 50;
      setHourSalesData(hourSalesData);
    }
    forceUpdate();
  };

  //テキストフィールドに想定しない値がぺーストされてしまわないように制限
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData.getData("text/plain");
    if (!pastedText) {
      return;
    }
    // text を正規表現で分割して配列にする
    const textArray = pastedText.split(/ |\u3000/);
    textArray.forEach((item) => {
      // 数字以外がマッチしたらペーストできない
      if (!item.match(/^[0-9]*$/)) {
        e.preventDefault();
      }
    });
  };

  // 保存ボタン押下
  const OnAccept = () => {
    if (isDivfifty()) {
      setDemandPlan();
    } else {
      setDigMessageOkCancel(languageContext.words.save_check);
      setDigOpenOkCancel(true);
    }
  };

  const isDivfifty = () => {
    let flagDivFifty = true;
    const cells = new Array(CELLCOUNT).fill(0);
    cells.map((cell, index) => {
      //調達計画のスポット計画に50kWhで割り切れない数があるかチェック
      spotProcurementData.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
      //調達計画の一時間前計画に50kWhで割り切れない数があるかチェック
      hourProcurementData.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
      //販売計画の一スポット計画に50kWhで割り切れない数があるかチェック
      spotSalesData.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
      //販売計画の一時間前計画に50kWhで割り切れない数があるかチェック
      hourSalesData.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
    });

    return flagDivFifty;
  };

  // 需調計画保存
  function setDemandPlan() {
    // 保存処理
    const updatePlan: number[] = [];
    // 需要家別需要計画

    // 販売グループ別調達計画

    // 販売グループ取引達計画

    const postDemandPlan: postDemandPlanList[] = []; // 需要計画
    const postSalePlan: postSalePlanList[] = []; // 販売計画
    const postProvidePlan: postProvidePlanList[] = []; // 調達計画
    const postTranPlan: postTransactionPlanList[] = []; // 取引計画

    allDemandPlan.forEach((element, index) => {
      const demand = element.demandId;
      element.valueList.forEach((value, time) => {
        const tmp: postDemandPlanList = {
          demandId: parseInt(demand),
          demandQu: value.value,
          timeCd: (time + 1).toString().padStart(2, "0"),
          dataChgCd: value.dataChgCd,
        };
        postDemandPlan.push(tmp);
      });
    });

    // 販売計画("スポット"、1時間前)
    spotSalesData.forEach((element, index) => {
      const powerId = element.saleId;
      const saleGroup = element.saleGroupId;
      const kind = 21;
      const saleId = element.saleId;
      element.valueList.forEach((item, time) => {
        const tmp: postSalePlanList = {
          saleId: powerId,
          saleGroupId: saleGroup,
          saleGroupKind: kind,
          demandQu: item.value == undefined ? 0 : item.value,
          timeCd: (time + 1).toString().padStart(2, "0"),
          dataChgCd: item.dataChgCd,
        };
        postSalePlan.push(tmp);
      });
    });

    // 販売計画(スポット、"1時間前")
    hourSalesData.forEach((element, index) => {
      const powerId = element.saleId;
      const saleGroup = element.saleGroupId;
      const kind = 31;
      const saleId = element.saleId;
      element.valueList.forEach((item, time) => {
        const tmp: postSalePlanList = {
          saleId: powerId,
          saleGroupId: saleGroup,
          saleGroupKind: kind,
          demandQu: item.value == undefined ? 0 : item.value,
          timeCd: (time + 1).toString().padStart(2, "0"),
          dataChgCd: item.dataChgCd,
        };
        postSalePlan.push(tmp);
      });
    });

    // 調達計画("自己託送",スポット、1時間前)
    selfProcurementData.forEach((element, index) => {
      const powerId = element.powerId;
      const saleGroup = element.saleGroupId;
      const kind = element.saleGroupKind;
      const saleId = element.saleId;
      element.valueList.forEach((item, time) => {
        const tmp: postProvidePlanList = {
          powerId: powerId,
          saleGroupId: saleGroup,
          saleGroupKind: kind,
          saleId: powerId,
          demandQu: item.value == undefined ? 0 : item.value,
          timeCd: (time + 1).toString().padStart(2, "0"),
          dataChgCd: item.dataChgCd,
        };
        postProvidePlan.push(tmp);
      });
    });

    // 調達計画(自己託送,"スポット"、1時間前)
    spotProcurementData.forEach((element, index) => {
      const powerId = 0; // 需要から見た調達先は発電所
      const saleGroup = element.saleGroupId;
      const kind = element.saleGroupKind;
      const saleId = 0;
      element.valueList.forEach((item, time) => {
        const tmp: postProvidePlanList = {
          powerId: powerId,
          saleGroupId: saleGroup,
          saleGroupKind: kind,
          saleId: saleId,
          demandQu: item.value == undefined ? 0 : item.value,
          timeCd: (time + 1).toString().padStart(2, "0"),
          dataChgCd: item.dataChgCd,
        };
        postProvidePlan.push(tmp);
      });
    });

    // 調達計画(自己託送,スポット、"1時間前")
    hourProcurementData.forEach((element, index) => {
      const powerId = 0; // 需要から見た調達先は発電所
      const saleGroup = element.saleGroupId;
      const kind = element.saleGroupKind;
      const saleId = 0;
      element.valueList.forEach((item, time) => {
        const tmp: postProvidePlanList = {
          powerId: powerId,
          saleGroupId: saleGroup,
          saleGroupKind: kind,
          saleId: saleId,
          demandQu: item.value == undefined ? 0 : item.value,
          timeCd: (time + 1).toString().padStart(2, "0"),
          dataChgCd: item.dataChgCd,
        };
        postProvidePlan.push(tmp);
      });
    });

    // 取引計画(全体合計)
    dealTotal.forEach((element, index) => {
      const tmp: postTransactionPlanList = {
        deal: element,
        demand: demandPlanData[index],
        procurement: procurementTotal[index],
        sale: salesTotal[index],
        timeCd: (index + 1).toString().padStart(2, "0"),
      };
      postTranPlan.push(tmp);
    });

    api
      .post(SET_DEMAND_PLAN, {
        areaId: areaId,
        targetDate: currentDate.pickDate?.toLocaleDateString(),
        demandPlanList: postDemandPlan,
        salePlanList: postSalePlan,
        providePlanList: postProvidePlan,
        transactionList: postTranPlan,
      })
      .then((res) => {
        // 保存後の処理
        const tmpsavedate1 = new Date(res.data.date);
        const tmpsavedate2 = tmpsavedate1.toLocaleString();
        leavePageContext.setBlockLeave(false);
        if(res.data.duplicated === true){
          setDialogMessage(languageContext.words.saved_dupicated);          
        }
        else{
          setDialogMessage(languageContext.words.note_saved);          
        }

        setDigOpen(true);
        getDemandHd();
        getDemandPlan();
        getProvidePlan();
        getSupplyPlan();
      })
      .catch((error) => {
        setError(error);
        setDialogMessage(languageContext.words.note_not_saved);
        setDigOpen(true);
      });
  }

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  function checkNumber(val: number | undefined) {
    if (val == undefined) {
      return 0;
    } else {
      return val;
    }
  }

  //テキストフィールド入力値6桁制限判定
  function isMaxLength(value: string) {
    if (value.length > 6) {
      return false;
    } else {
      return true;
    }
  }

  //線の色
  const changeBordercolor = () => {
    let borderColor : string;
    
    if(PaletteMode === "dark"){
      borderColor = "1px solid #808080";
    } else {
      borderColor =  "1px solid #d3d3d3";
    }

    return borderColor;
  }

  //ページレンダリング
  if (error) {
    // errorはstate
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    // isLoadedはstate
    return <div>Loading...</div>;
  }
  // 自動計算部分のテーブルの構成
  const ConstantTableRender = () => {
    return (
      <StyledTable
        sx={{
          width: "30%",
          height: "100%",
          position: "sticky",
          left: 0,
          zIndex: 1,
          borderCollapse: "separate",
        }}
        style={{ borderRightWidth: "2px", borderTop: "none", borderBottom: "none", }}
      >
        <TableHead
          sx={{
            display: "block",
            //background: "rgb(220,230,241)",
            position: "sticky",
            top: 0,
            zIndex: 1,
            height: 138,
          }}
          style={{ borderRight: "none", borderTop: changeBordercolor(), }}
        >
          <TableRow
           sx={{
              width: "100%",
              display: "flex",
            }}
          >
            <StyledTableHeaderLG
              align="center"
              sx=
              {{ 
                minWidth: "80px", 
                display: "flex", 
                height: "105px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {languageContext.words.timeB}
            </StyledTableHeaderLG>
            <TableRow>
              <StyledTableHeaderLG
              align="center"
              sx={{display: "block"}}
              style={{ borderRight: "none" }}
              >
              {languageContext.words.balance_supply_and_demand}
              </StyledTableHeaderLG>
              <TableRow sx={{display: "flex"}}>
                {/* 取引計画表示 */}
                {TABLE_DEFINE.map((data, index) => (
                <StyledTableHeaderLG
                  sx={{display:"block", width: "75px"}}
                  key={index}
                  align="center"
                  style={{ 
                    borderRight: index === 3 ? "none" : "",      
                    overflow: "hidden", 
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                  }}
                  title={data.label}
                >
                  {data.label}
                  <br />
                  (kWh)
                </StyledTableHeaderLG>
                ))}                
              </TableRow>
            </TableRow>
          </TableRow>
        </TableHead>
        {ConstantDataTable()}
      </StyledTable>
    );
  };

  // 自動計算部分のテーブルの構成
  const ConstantDataTable = () => {
    return (
      <ScrollSyncPane group="vertical">
        <TableBody
          sx={{
            display: "block",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "0px",
              backgroundColor: "transparent",
              height: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "0px",
            },
          }}
        >
          <>
            {demandPlanData.map((value, index) => (
              <TableRow 
                key={"demandForecastPlan_" + index} 
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <StyledTableCell
                  key={"demandForecastData_" + index + "_time"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 42,
                    width: 112,
                    padding: 0,
                    backgroundColor: isDisabled(index, "")
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="center"
                >
                  {cellTime[index] + "～" + cellTime[index + 1]}
                </StyledTableCell>

                <StyledTableCell
                  key={"demandForecastData_1" + index + "_supply"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    height: 42,
                    width: 99,
                    padding: "0px 8px 0px 0px",
                    textAlign: "right",
                    backgroundColor: isDisabled(index, "")
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="center"
                >
                  {/* 供給力 */}
                  {demandPlanData[index].toLocaleString()}
                </StyledTableCell>

                <StyledTableCell
                  key={"demandForecastData_2" + index + "_supply"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    height: 42,
                    width: 99,
                    padding: "0px 8px 0px 0px",
                    textAlign: "right",
                    backgroundColor: isDisabled(index, "")
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="center"
                >
                  {/* 調達量 */}
                  {procurementTotal[index].toLocaleString()}
                </StyledTableCell>

                <StyledTableCell
                  key={"demandForecastData_3" + index + "_supply"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    height: 42,
                    width: 99,
                    padding: "0px 8px 0px 0px",
                    textAlign: "right",
                    backgroundColor: isDisabled(index, "")
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="center"
                >
                  {salesTotal[index].toLocaleString()}
                </StyledTableCell>

                <StyledTableCell
                  key={"demandForecastData_4" + index + "_supply"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    height: 42,
                    width: 99,
                    padding: "0px 8px 0px 0px",
                    textAlign: "right",
                    backgroundColor: isDisabled(index, "")
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="center"
                  style={{ borderRight: "none" }}
                >
                  {/* 調整量 */}
                  {Math.floor(dealTotal[index]).toLocaleString()}
                </StyledTableCell>
              </TableRow>
            ))}
          </>
        </TableBody>
      </ScrollSyncPane>
    );
  };

  // 需要計画テーブル構成
  const DemandTableRender = () => {
    //ヘッダーデータの生成
    return (
      <StyledTable
        sx={{
          width: "auto",
          height: "100%",
          position: "sticky",
          left: 0,
          zIndex: 1,
          borderCollapse: "separate",
        }}
        style={{ borderRight: "none", borderTop: "none", borderBottom: "none", }}
      >
        <TableHead
          sx={{
            display: "block",
            background: "#00000000", //"rgb(220,230,241)",
            position: "sticky",
            top: 0,
            zIndex: 1,
            height: 138,
            borderTop: changeBordercolor(),
          }}
        >
          <TableRow>
            {allDemandCategory.map((group, index) => {
              return (
                <>
                  <StyledTableHeaderLG
                    key={"demandData_" + index + "_areaName"}
                    sx={{ height: 56, padding: "0px", width: 124 }}
                    align="center"
                    colSpan={group.demandId.length}
                  >
                    {/* 需要家カテゴリ(特高、高圧、低圧) */}
                    {convertIdToVolLevelName(group.volCategory)}
                  </StyledTableHeaderLG>
                </>
              );
            })}
          </TableRow>
          <TableRow>
            {allDemandPlan.map((demand, index) => {
              return (
                <>
                  <StyledTableHeaderLG
                    key={"demandData_" + index + "_areaName"}
                    sx={{ 
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex", 
                      height: 80, 
                      width: 124,
                      padding: "0px",                      
                    }}
                    align="center"
                  >
                    <Box sx=
                      {{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",

                      }}>
                      {/* 需要家 */}
                      {demand.demandName}
                      <br />
                      (kWh)
                    </Box>
                  </StyledTableHeaderLG>
                </>
              );
            })}
          </TableRow>
        </TableHead>
        {DemandDataTable()}
      </StyledTable>
    );
  };

  // 需要計画データテーブル構成
  const DemandDataTable = () => {
    //ヘッダーデータの生成
    const cells = new Array(48);
    cells.fill(0);

    return (
      <ScrollSyncPane group="vertical">
        <TableBody
          sx={{
            display: "block",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "0px",
              backgroundColor: "transparent",
              height: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          <>
            {cells.map((cell, index) => {
              return (
                <TableRow key={index}>
                  {allDemandPlan.map((item, i) => {
                    return (
                      <StyledTableCell
                        key={"demandData_" + index + "_areaName"}
                        sx={{
                          height: 42,
                          padding: "0px",
                          width: 124,
                          backgroundColor: isDisabled(index, "")
                            ? PaletteMode === "dark"
                              ? "rgb(90,90,90)"
                              : "rgb(200,200,200)"
                            : "",
                        }}
                        align="center"
                      >
                        <TextField
                          sx={{ margin: "2px" }}
                          inputProps={{
                            style: {
                              padding: 0,
                              height: "30px",
                              textAlign: "right",
                              backgroundColor: isDisabled(index, "")
                                ? PaletteMode === "dark"
                                  ? "rgb(90,90,90)"
                                  : "rgb(200,200,200)"
                                : "",
                            },
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          error={isMismatch(item.valueList[index].dataChgCd)}
                          disabled={isDisabled(index, "")}
                          type="number"
                          onKeyDown={(event) => {
                            if (event.key === "." || event.key === "-") {
                              event.preventDefault();
                            }
                          }}
                          onPaste={handlePaste} //負の値などのペースト制限
                          value={
                            Number(item.valueList[index].value) <= -1
                              ? ""
                              : item.valueList[index].value === undefined
                              ? ""
                              : checkNumber(
                                  item.valueList[index].value
                                ).toFixed(0)
                          }
                          onFocus={(e) => {
                            if (Number(item.valueList[index].value) === 0) {
                              e.currentTarget.select();
                            }
                          }}
                          onChange={(e) => {
                            if (isMaxLength(e.target.value)) {
                              item.valueList[index].value = Number(
                                Number(e.target.value).toFixed(0)
                              );
                            }
                            leavePageContext.setBlockLeave(true);
                            if (Number(item.valueList[index].value) <= -1) {
                              item.valueList[index].value = 0;
                            }
                            setAllDemandPlan(allDemandPlan); // データを変更したので再設定
                            forceUpdate();
                          }}
                        />
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </>
        </TableBody>
      </ScrollSyncPane>
    );
  };

  // 販売計画テーブル構成
  const SalesTableRender = () => {
    const SALEGROUP = [
      languageContext.words.internal_self_consignment,
      languageContext.words.spot,
      languageContext.words.one_hour_ago,
    ];

    return (
      <Box sx={{ display: "flex", width: "auto", zIndex: "0" }}>
        {/* スポット */}
        {spotSalesData.length === 0 ? null : (
          <StyledTable
            stickyHeader
            sx={{ padding: 0 }}
            style={{ borderRight: "none", borderTop: "none", borderBottom:"none", }}
          >
            <TableHead
              sx={{
                display: "block",
                height: 138,
                position: "sticky",
                top: 0,
                zIndex: "1",
                borderTop: changeBordercolor(),
              }}
            >
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 56,
                    width: 124,
                  }}
                  colSpan={2}
                  style={{ borderRight: "none" }}
                >
                  {SALEGROUP[1]}
                </StyledTableHeaderLG>
              </TableRow>
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 80,
                    width: 124,
                  }}
                >
                  {languageContext.words.subtotal}
                  <br />
                  (kWh)
                </StyledTableHeaderLG>
                {spotSalesData.map((item, index) => {
                  return (
                    <StyledTableHeaderLG
                      key={index}
                      sx={{
                        textAlign: "center",
                        padding: 0,
                        height: 80,
                        width: 124,
                      }}
                      style={{ borderRight: "none" }} 
                    >
                      {convertIdToAreaName(item.areaId)}
                      <br />
                      (kWh)
                    </StyledTableHeaderLG>
                  );
                })}
              </TableRow>
            </TableHead>
            {SalesDataTable(spotSalesData, "spot")}
          </StyledTable>
        )}
        {/* 時間前 */}
        {hourSalesData.length === 0 ? null : (
          <StyledTable 
            stickyHeader 
            sx={{ padding: 0 }} 
            style ={{ borderTop: "none", borderBottom: "none",}}>
            <TableHead
              sx={{
                display: "block",
                height: 138,
                position: "sticky",
                top: 0,
                zIndex: "1",
                borderTop: changeBordercolor(),
              }}
            >
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 56,
                    width: 124,
                  }}
                  style={{ borderRight: "none" }}
                  colSpan={2}
                >
                  {SALEGROUP[2]}
                </StyledTableHeaderLG>
              </TableRow>
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 80,
                    width: 124,
                  }}
                >
                  {languageContext.words.subtotal}
                  <br />
                  (kWh)
                </StyledTableHeaderLG>
                {hourSalesData.map((item, index) => {
                  return (
                    <StyledTableHeaderLG
                      key={index}
                      sx={{
                        textAlign: "center",
                        padding: 0,
                        height: 80,
                        width: 124,
                      }}
                      style={{ borderRight: "none" }}
                    >
                      {convertIdToAreaName(item.areaId)}
                      <br />
                      (kWh)
                    </StyledTableHeaderLG>
                  );
                })}
              </TableRow>
            </TableHead>
            {SalesDataTable(hourSalesData, "hour")}
          </StyledTable>
        )}
      </Box>
    );
  };

  // 販売計画データテーブル構成
  const SalesDataTable = (plans: saleAreaList[], type: string) => {
    //ヘッダーデータの生成
    const cells = new Array(48).fill(0);

    if (plans.length == 0) {
      // データがないため表示しない
      return null;
    }

    return (
      <ScrollSyncPane group="vertical">
        <TableBody
          sx={{
            display: "block",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "0px",
              backgroundColor: "transparent",
              height: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          {cells.map((cell, index) => {
            return (
              <TableRow key={index}>
                {/* 販売合計合計 */}
                <StyledTableCell
                  key={"salesData_" + index + "_areaName"}
                  sx={{
                    height: 42,
                    padding: "0px 8px 0px 0px",
                    width: 116,
                    textAlign: "right",
                    backgroundColor: isDisabled(index, "")
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                >
                  {plans.reduce(
                    (sum, i) => sum + checkNumber(i.valueList[index].value),
                    0
                  ).toLocaleString()}
                </StyledTableCell>
                {/* 各販売計画 */}
                {plans.map((demand, i) => {
                  return (
                    <StyledTableCell
                      key={"salesData_" + index + "_areaName" + i}
                      sx={{
                        height: 42,
                        padding: 0,
                        width: 124,
                        backgroundColor: isDisabled(index, "")
                          ? PaletteMode === "dark"
                            ? "rgb(90,90,90)"
                            : "rgb(200,200,200)"
                          : "",
                      }}
                      align="center"
                      style={{
                        borderRight:
                          (plans.length > 1 && i === 2) || plans.length === 1
                            ? "none"
                            : "",
                      }}
                    >
                      <TextField
                        sx={{ margin: "2px" }}
                        inputProps={{
                          style: {
                            padding: 0,
                            height: "30px",
                            textAlign: "right",
                            backgroundColor: isDisabled(index, "")
                              ? PaletteMode === "dark"
                                ? "rgb(90,90,90)"
                                : "rgb(200,200,200)"
                              : "",
                          },
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          step: tableType != undefined ? "50" : "1",
                        }}
                        error={isMismatch(demand.valueList[index].dataChgCd)}
                        disabled={isDisabled(index, type)}
                        type="number"
                        onKeyDown={(event) => {
                          if (event.key === "." || event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                        onFocus={(e) => {
                          if (Number(demand.valueList[index].value) === 0) {
                            e.currentTarget.select();
                          }
                        }}
                        value={
                          demand.valueList[index].value === undefined
                            ? ""
                            : Number(demand.valueList[index].value) <= -1
                            ? ""
                            : checkNumber(
                                demand.valueList[index].value
                              ).toFixed(0)
                        }
                        //テキストフィールド50kWh丸め
                        onBlur={(e) => {
                          updateBlurSalesData(e, i, index, type);
                        }}
                        onPaste={handlePaste} //負の値などのペースト制限
                        // データ更新用
                        onChange={(e) => {
                          leavePageContext.setBlockLeave(true);
                          if (
                            isMaxLength(e.target.value) &&
                            0 <= Number(e.target.value)
                          ) {
                            demand.valueList[index].value = Number(
                              Number(e.target.value).toFixed(0)
                            );
                          }
                          if (type == "spot") {
                            setSpotSalesData(spotSalesData);
                          } else {
                            setHourSalesData(hourSalesData);
                          }
                          forceUpdate();
                        }}
                      />
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </ScrollSyncPane>
    );
  };

  // 調達計画テーブル構成
  const ProcurementTableRender = () => {
    const SALEGROUP = [
      languageContext.words.internal_other_bg,
      languageContext.words.spot,
      languageContext.words.one_hour_ago,
    ];

    return (
      <Box sx={{ display: "flex", width: "auto", zIndex: "0" }}>
        <Box>
          {/* 域内他BG */}
          {selfProcurementData.length == 0 ? null : (
            <StyledTable
              stickyHeader
              sx={{ padding: 0 }}
              style={{ borderRight: "none", display: "block", borderTop: "none", borderBottom: "none", }}
            >
              <TableHead
                sx={{
                  display: "block",
                  height: 138,
                  position: "sticky",
                  top: 0,
                  zIndex: "1",
                  borderTop: changeBordercolor(),
                }}
              >
                <TableRow sx={{width: "100%",display:"block"}}>
                  <StyledTableHeaderLG
                    sx={{
                      display: "flex",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 0,
                      height: 56,
                      width: "auto",
                      borderLeft: "none"
                    }}
                    colSpan={selfProcurementData.length + 1}
                  >
                    {languageContext.words.self_consignment}
                  </StyledTableHeaderLG>
                </TableRow>
                <TableRow sx={{display: "flex"}}>
                  <StyledTableHeaderLG
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 0,
                      height: 80,
                      width: 124,
                      borderLeft: "none"
                    }}
                  >
                    {languageContext.words.subtotal}
                    <br />
                    (kWh)
                  </StyledTableHeaderLG>
                  {selfProcurementData.map((item, index) => {
                    return (
                      <StyledTableHeaderLG
                        key={index}
                        sx={{
                          display:"inline-flex",
                          textAlign: "center",
                          justifyContent:"center",
                          alignItems:"center",
                          padding: 0,
                          height: 80,
                          width: 124,
                          borderLeft: "none"
                        }}
                        title={item.powerName}
                      >
                        <Box sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}>
                        {item.powerName}
                        <br />
                        (kWh)
                        </Box>
                      </StyledTableHeaderLG>
                    );
                  })}
                </TableRow>
              </TableHead>
              {ProcurementDataTable(selfProcurementData, "self")}
            </StyledTable>
          )}
        </Box>
        <Box sx={{ display: "flex", width: "500px"}}>
          {/* スポット */}
          {spotProcurementData.length == 0 ? null : (
            <StyledTable
              stickyHeader
              sx={{ padding: 0 }}
              style={{borderLeft: "none", borderRight: "none",  borderTop:"none", borderBottom: "none"}}
            >
              <TableHead
                sx={{
                  display: "block",
                  height: 138,
                  position: "sticky",
                  top: 0,
                  zIndex: "1",
                  borderTop: changeBordercolor(),
                }}
              >
                <TableRow>
                  <StyledTableHeaderLG
                    sx={{ textAlign: "center", padding: 0, height: 56 }}
                    colSpan={2}
                    style={{ borderLeft: "none" }}
                  >
                    {SALEGROUP[1]}
                  </StyledTableHeaderLG>
                </TableRow>
                <TableRow>
                  <StyledTableHeaderLG
                    sx={{
                      textAlign: "center",
                      padding: 0,
                      height: 80,
                      width: 124,
                    }}
                  >
                    {languageContext.words.subtotal}
                    <br />
                    (kWh)
                  </StyledTableHeaderLG>
                  {spotProcurementData.map((item, index) => {
                    return (
                      <StyledTableHeaderLG
                        key={index}
                        sx={{
                          textAlign: "center",
                          padding: 0,
                          height: 80,
                          width: 125,
                        }}
                        style={{ borderLeft: "none" }}
                      >
                        {convertIdToAreaName(item.areaId)}
                        <br />
                        (kWh)
                      </StyledTableHeaderLG>
                    );
                  })}
                </TableRow>
              </TableHead>
              {ProcurementDataTable(spotProcurementData, "spot")}
            </StyledTable>
          )}
          {/* 時間前 */}
          {hourProcurementData.length == 0 ? null : (
            <StyledTable
              stickyHeader
              sx={{

                padding: 0,
              }}
              style={{borderLeft: "none", borderRight: "none", borderTop:"none", borderBottom: "none" }}
            >
              <TableHead
                sx={{
                  display: "block",
                  height: 138,
                  position: "sticky",
                  top: 0,
                  zIndex: "1",
                  borderTop: changeBordercolor(),
                }}
              >
                <TableRow>
                  <StyledTableHeaderLG
                    sx={{
                      textAlign: "center",
                      padding: 0,
                      height: 56,
                      width: 124,
                    }}
                    colSpan={2}
                    style={{ borderLeft: "none" }}
                  >
                    {SALEGROUP[2]}
                  </StyledTableHeaderLG>
                </TableRow>
                <TableRow>
                  <StyledTableHeaderLG
                    sx={{
                      textAlign: "center",
                      padding: 0,
                      height: 80,
                      width: 124,
                    }}
                  >
                    {languageContext.words.subtotal}
                    <br />
                    (kWh)
                  </StyledTableHeaderLG>
                  {hourProcurementData.map((item, index) => {
                    return (
                      <StyledTableHeaderLG
                        key={index}
                        sx={{
                          textAlign: "center",
                          padding: 0,
                          height: 80,
                          width: 124,
                        }}
                        style={{ borderLeft: "none" }}
                      >
                        {convertIdToAreaName(item.areaId)}
                        <br />
                        (kWh)
                      </StyledTableHeaderLG>
                    );
                  })}
                </TableRow>
              </TableHead>
              {ProcurementDataTable(hourProcurementData, "hour")}
            </StyledTable>
          )}
        </Box>
      </Box>
    );
  };

  // 調達計画データテーブル構成
  const ProcurementDataTable = (plans: any[], type: string) => {
    //ヘッダーデータの生成
    const cells = new Array(48);
    cells.fill(0);

    if (plans.length == 0) {
      // データがないため表示しない
      return null;
    }

    return (
      <ScrollSyncPane group="vertical">
        <TableBody
          sx={{
            display: "block",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "0px",
              backgroundColor: "transparent",
              height: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          {cells.map((cell, index) => {
            return (
              <TableRow key={index}>
                <StyledTableCell
                  key={"procurement_" + index + "_areaName"}
                  sx={{
                    height: 42,
                    padding: "0px 8px 0px 0px",
                    width: 116,
                    textAlign: "right",
                    backgroundColor: isDisabled(index, "")
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  
                  align="center"
                >
                  {Math.floor(
                    plans.reduce(
                      (sum, i) => sum + checkNumber(i.valueList[index].value),
                      0
                    )
                  ).toLocaleString()}
                </StyledTableCell>
                {plans.map((demand, i) => {
                  return (
                    <StyledTableCell
                      key={"procurement_" + index + "_areaName" + i}
                      sx={{
                        height: 42,
                        padding: "0px",
                        width: 124,
                        backgroundColor: isDisabled(index, "")
                          ? PaletteMode === "dark"
                            ? "rgb(90,90,90)"
                            : "rgb(200,200,200)"
                          : "",
                      }}
                      align="center"
                    >
                      <TextField
                        sx={{ margin: "2px" }}
                        inputProps={{
                          style: {
                            padding: 0,
                            height: "30px",
                            width: 118,
                            textAlign: "right",
                            backgroundColor: isDisabled(index, "")
                              ? PaletteMode === "dark"
                                ? "rgb(90,90,90)"
                                : "rgb(200,200,200)"
                              : "",
                          },
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          step: type != "self" ? "50" : "1",
                        }}
                        error={isMismatch(demand.valueList[index].dataChgCd)}
                        disabled={isDisabled(index, type)}
                        type="number"
                        onFocus={(e) => {
                          if (Number(demand.valueList[index].value) === 0) {
                            e.currentTarget.select();
                          }
                        }}
                        value={
                          demand.valueList[index].value === undefined
                            ? ""
                            : Number(demand.valueList[index].value) <= -1
                            ? ""
                            : checkNumber(
                                demand.valueList[index].value
                              ).toFixed(0)
                        }
                        onKeyDown={(event) => {
                          if (event.key === "." || event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                        //テキストフィールド50kWh丸め
                        onBlur={(e) => {
                          updateBlurProcurementData(e, i, index, type);
                        }}
                        onPaste={handlePaste} //負の値などのペースト制限
                        // データ更新用
                        onChange={(e) => {
                          leavePageContext.setBlockLeave(true);
                          if (
                            isMaxLength(e.target.value) &&
                            0 <= Number(e.target.value)
                          ) {
                            demand.valueList[index].value = Number(
                              Number(e.target.value).toFixed(0)
                            );
                          }
                          if (type == "self") {
                            setSelfProcurementData(selfProcurementData);
                          } else if (type == "spot") {
                            setSpotProcurementData(spotProcurementData);
                          } else {
                            setHourProcurementData(hourProcurementData);
                          }
                          forceUpdate();
                        }}
                      />
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </ScrollSyncPane>
    );
  };

  const TableRender = (): JSX.Element => {
    if (tableType === DemandTable) {
      return DemandTableRender();
    } else if (tableType === SalesTable) {
      return SalesTableRender();
    } else {
      return ProcurementTableRender();
    }
  };

  return (
    <div
      //ダークモード対応
      className={`cn-main-display ${PaletteMode === "dark" ? "dark" : "light"}`}
    >
      <Box sx={{ display: "flex", width: "100%", alignItems: "center"  }}>
        <Box sx={{ display: "flex", width: "50%", alignItems: "center"  }}>
          <Box sx={{ width: "200px", margin: "4px", }}>
            <CustomSelectList
              value={areaId}
              options={areaOptions(areas, false)}
              onChange={setAreaId}
            />
          </Box>
          <GlobalDatePicker isDipsTodayBtn={true} />
          <ButtonGroup
            aria-label="outlined primary button group"
            sx={{ 
              marginLeft: "30px", 
              height: "40px", 
              whiteSpace: "nowrap"
            }}
          >
            {/* 需要計画タブボタン */}
            <StyledButton
              style={{
                backgroundColor:
                  tableType === DemandTable ? "rgba(25, 118, 210, 0.25)" : "",
              }}
              onClick={(event) => {
                setTabletype(DemandTable);
              }}
            >
              {languageContext.words.demand_plan}
            </StyledButton>
            {/* 調達計画タブボタン */}
            <StyledButton
              style={{
                backgroundColor:
                  tableType === ProcurementTable
                    ? "rgba(25, 118, 210, 0.25)"
                    : "",
              }}
              onClick={() => {
                setTabletype(ProcurementTable);
              }}
            >
              {languageContext.words.procurement_plan}
            </StyledButton>
            {/* 販売計画タブボタン */}
            <StyledButton
              style={{
                backgroundColor:
                  tableType === SalesTable ? "rgba(25, 118, 210, 0.25)" : "",
              }}
              onClick={(event) => {
                setTabletype(SalesTable);
              }}
            >
              {languageContext.words.sales_plan}
            </StyledButton>
          </ButtonGroup>
        </Box>
        <Box 
          sx={{
            display: "flex",
            width: "50%",
            height: "40px",
            justifyContent: "flex-end",
            marginRight: "40px"
          }}
        >
          <Typography 
            sx={{ 
              color: PaletteMode === "dark" ? "lightgray" : "gray",
              width: "660px",
              display: "flex",
              alignItems: "center"
             }}
          >
            {lastUpdateInfo
              ? `${languageContext.words.last_update_info}
                : ${languageContext.convertDateToLocalizedString(
                  lastUpdateInfo.date,
                  "HH:mm:ss"
                )}
                (${lastUpdateInfo.user})`
              : ""}
          </Typography>
          <SaveDataButton
            hidden={hidden}
            disabled={
              hidden ||
              isBefore(
                new Date(format(currentDate.pickDate as Date, "yyyy/MM/dd")),
                new Date(format(timeContext.time as Date, "yyyy/MM/dd"))
              )
            }
            message={languageContext.words.saved}
            onAccept={OnAccept}
          />
        </Box>
      </Box>
      <ScrollSync>
        <TableContainer
          sx={{
            height: "90%",
            backgroundColor: "#00000000",
            overflow: "auto",
            width: "100%",
            margin: "10px",
            marginTop: "15px",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor:
                PaletteMode === "dark" ? "#5e5e5e" : "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: PaletteMode === "dark" ? "white" : "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          <Box sx={{ display: "flex", backgroundColor: "#00000000" }}>
            {ConstantTableRender()}
            {TableRender()}
          </Box>
        </TableContainer>
      </ScrollSync>
      <CustomDialog
        title={languageContext.words.demandforecastplan}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
      <CustomDialog
        title={languageContext.words.demandforecastplan}
        message={digMessageOkCancel}
        buttonType={ButtonType.OkCancel}
        open={digOpenOkCancel}
        onAccept={setDemandPlan} //OK押下後の挙動
        onClose={() => setDigOpenOkCancel(false)}
      />
    </div>
  );
};
export default DemandForecastPlan;
