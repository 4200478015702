import React, { useState, useEffect, useContext } from "react";
import "./css/SetPowerContractList.css";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { padding, width } from "@mui/system";
import axios from "axios";
import CustomModal from "../common/customComponents/CustomModal";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import CustomDatePickerTryal from "../common/customComponents/CustomDatePickerTryal";
import { LanguageContext } from "../common/localization/localization";
import {
  ContractSetInfo as contractSetInfo,
  resContractorInfo,
} from "../../types/master/SetConnectContractList";
import { modalMode } from "./ConnectContractList";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import { resAreaList } from "../../types/common/Search";
import { addDays, format, subDays } from "date-fns";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListBig from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorBig";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";

type Props = {
  userId: string;
  propsShowUserCreateDialog: any;
};

// type UserInfo = {
//   UserId: string;
//   UserName: string;
//   role: number;
//   mailAddress: string;
//   password: string;
//   eria01: boolean;
//   eria02: boolean;
//   eria03: boolean;
//   eria04: boolean;
//   eria05: boolean;
//   eria06: boolean;
//   eria07: boolean;
//   eria08: boolean;
//   eria09: boolean;
// };

type role = {
  role_id: number;
  role_nm: string;
};

const CustomTextField = styled(TextField)(() => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "auto",
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
}));

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  areaId: boolean;
  contractName: boolean;
  powerContractorId: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  areaId: false,
  contractName: false,
  powerContractorId: false,
};

const SetPowerContractList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  connectNumberEdit: number
) => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);

  // 通信エラー
  const api = useAuthedApi();

  //エリアIDをエリア名に変換
  const { convertIdToAreaName } = useAreaUtility();

  type areaInfo = { id: string; name: string };
  type powerContractorSellect = {
    id: number;
    name: string;
    code: string;
    contractorId: number;
    contractor_areaId: string;
    contractor_areaName: string;
  };

  const areaInfos = React.useRef<areaInfo[]>([{ id: "00", name: "未選択" }]);
  const contractorInfos = React.useRef<powerContractorSellect[]>([
    {
      id: 0,
      name: "未選択",
      code: "",
      contractorId: 0,
      contractor_areaId: "",
      contractor_areaName: "",
    }, //初期表示用
  ]);
  //const [contractorList, setContractorList] = useState<string[]>(["1","12"]);
  const [userRoles, setUserRole] = useState<role[]>();
  const [conectContractInfos, setConectContractInfos] =
    useState<contractSetInfo>();
  const [selContractantNo, setSelContractantNo] = useState<number>(0);
  const [selContractantArea, setSelContractantArea] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [finishDate, setFinishDate] = useState<Date>(addDays(new Date(), 1));
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化
  ////

  //開始datePicker
  useEffect(() => {
    const tmpInfo: contractSetInfo = Object.assign({}, conectContractInfos);
    tmpInfo.startDate = format(startDate, "yyyy/MM/dd");
    setConectContractInfos(tmpInfo);
  }, [startDate]);

  ////終了datePicker
  useEffect(() => {
    const tmpInfo: contractSetInfo = Object.assign({}, conectContractInfos);
    tmpInfo.finishDate =
      finishDate == null ? "" : format(finishDate, "yyyy/MM/dd");
    setConectContractInfos(tmpInfo);
  }, [finishDate]);

  ///////エリア関連

  //データベースからエリアデータ取得
  useEffect(() => {
    // エリア一覧取得
    //console.log("エリアデータ取得開始");
    getAreaMst();
  }, []); //初期化

  const getAreaMst = () => {
    api
      .get(API_URL.GET_AREA_MST)
      .then((response) => {
        //console.log("エリアマスタ取得開始");
        //console.log(response.data.result);
        areaInfos.current = areaInfos.current.concat(
          convertAreaData(response.data as resAreaList[])
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //console.log(areaInfos.current);
  // console.log(conectContractInfos?.areaId);

  const convertAreaData = (result: resAreaList[]) => {
    const retAreaData: areaInfo[] = [];
    result.map((data) => {
      const areaData: areaInfo = { id: "", name: "" };
      areaData.id = data.areaId.toString();
      areaData.name = data.areaName;
      retAreaData.push(areaData);
      //console.log("エリア変換1データ");
      //console.log(JSON.stringify(areaData));
    });
    //console.log("エリア変換後データ");
    //console.log(JSON.stringify(retAreaData));
    return retAreaData;
  };

  function setAreaId(id: number) {
    //numberを文字列にしてコピーしたsaleDetailInfoのIDにセットする
    const areaIdString = ("00" + id).slice(-2).toString();
    const tmpAreaInfo: contractSetInfo = Object.assign({}, conectContractInfos);
    tmpAreaInfo.areaId = areaIdString;

    setConectContractInfos(tmpAreaInfo);
  }
  ////////エリア関連

  ////////////発電契約者
  //データベースから発電契約者データ一覧取得
  useEffect(() => {
    // 発電契約者データ一覧取得
    //console.log("発電契約者データ一覧取得");
    getContractorMst();
  }, []); //初期化

  const getContractorMst = () => {
    api
      .get(API_URL.GET_CONTRACTOR_MST)
      .then((response) => {
        //console.log("発電契約者データマスタ取得開始");
        //console.log(response.data.result);
        contractorInfos.current = contractorInfos.current.concat(
          convertContractorData(response.data.result as resContractorInfo[])
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //console.log(contractorInfos);

  const convertContractorData = (result: resContractorInfo[]) => {
    const retContractorData: powerContractorSellect[] = [];
    result.map((data, index) => {
      const contractorData: powerContractorSellect = {
        id: 0,
        name: "",
        code: "",
        contractorId: 0,
        contractor_areaId: "00",
        contractor_areaName: "",
      };
      contractorData.id = index + 1; //0要素にはデフォルトのものが入るため
      contractorData.name = data.name;
      contractorData.contractorId = data.contractorId;
      contractorData.contractor_areaId = data.contractor_areaId;
      contractorData.contractor_areaName = data.contractor_areaName;

      retContractorData.push(contractorData);
      //console.log("発電契約者変換1データ");
      //console.log(JSON.stringify(contractorData));
    });
    //console.log("発電契約者変換後データ");
    //console.log(JSON.stringify(retContractorData));
    return retContractorData;
  };

  const setContractor = (e: number) => {
    const tmpPowerBGInfo: contractSetInfo = Object.assign(
      {},
      conectContractInfos
    );
    const tmpContractInfo: powerContractorSellect[] = contractorInfos.current;
    // console.log(e);
    tmpPowerBGInfo.powerContractorId = tmpContractInfo[e].contractorId;
    tmpPowerBGInfo.areaId = tmpContractInfo[e].contractor_areaId;
    // setSelContractantCode(tmpContractInfo[e].code);
    // setSelContractantNo(tmpContractInfo[e].id);
    ContractorCompornentChanged(tmpContractInfo[e]);
    setConectContractInfos(tmpPowerBGInfo);
    setSelContractantArea(
      tmpContractInfo[e].contractor_areaId === ""
        ? ""
        : convertIdToAreaName(tmpContractInfo[e].contractor_areaId)
    );
  };

  //console.log(conectContractInfos);

  //発電契約者部分のコンポーネント変更（セレクトリストとフィールド）
  const ContractorCompornentChanged = (
    contractorInfo: powerContractorSellect
  ) => {
    setSelContractantNo(contractorInfo.id);
  };
  ///////////発電契約者

  //初期化用
  const initialDemandBGInfo: contractSetInfo = {
    connectContraId: 0,
    areaId: "00",
    applicationNumber: "",
    contractName: "",
    powerContractorId: NaN,
    contractPower: 0,
    //linkageCode: 0,
    startDate: format(new Date(), "yyyy/MM/dd"),
    finishDate: format(new Date(), "yyyy/MM/dd"),
    area01Id1: "",
    area01Id2: "",
    area02Id1: "",
    area02Id2: "",
    area03Id2: "",
    area03Id1: "",
    area04Id1: "",
    area04Id2: "",
    area05Id1: "",
    area05Id2: "",
    area06Id1: "",
    area06Id2: "",
    area07Id1: "",
    area07Id2: "",
    area08Id1: "",
    area08Id2: "",
    area09Id1: "",
    area09Id2: "",
    area10Id1: "",
    area10Id2: "",
  };

  useEffect(() => {
    setConectContractInfos(initialDemandBGInfo);
  }, []); //初期化

  useEffect(() => {
    if (toOpen === true) {
      contractorInfos.current[0] = {
        id: 0,
        name: languageContext.words.setting_unselected,
        code: "",
        contractorId: 0,
        contractor_areaId: "",
        contractor_areaName: "",
      };
      if (mode == modalMode.editMode) {
        getDataLinkedBgCode(connectNumberEdit);
      } else {
        setSelContractantNo(0); //セレクターを初期値に
        setConectContractInfos(initialDemandBGInfo);
        setStartDate(new Date());
        setFinishDate(addDays(new Date(), 1));
        setSelContractantArea("");
      }
    } else {
      setSelContractantNo(0); //セレクターを初期値に
      setConectContractInfos(initialDemandBGInfo);
      setStartDate(new Date());
      setFinishDate(addDays(new Date(), 1));
      setInputError(initErrorInput);
      setSelContractantArea("");
    }
  }, [toOpen]); //初期化

  // 接続供給契約データ一行分取得
  const getDataLinkedBgCode = (connectNumber: number) => {
    //console.log("接続供給契約1行データ取得開始");
    let responseData: contractSetInfo;
    const url = api
      .get(API_URL.GET_ONE_CONTRACTOR_DATA, {
        params: { connectNumber: connectNumber },
      })
      .then((response) => {
        //console.log(response.data.result);
        responseData = response.data.result as contractSetInfo;

        // console.log("入っとるよ");
        // console.log(responseData);
        setConectContractInfos(responseData);
        setPowerContractNameSellect(responseData.powerContractorId);
        setStartDate(new Date(responseData.startDate));
        setFinishDate(new Date(responseData.finishDate));
      })
      .catch((err) => {
        console.log(err);
        setConectContractInfos(initialDemandBGInfo);
      });
  };

  const setPowerContractNameSellect = (contractantID: number) => {
    contractorInfos.current.map((contractorInfo) => {
      if (contractantID === contractorInfo.contractorId) {
        setSelContractantNo(contractorInfo.id);
      }
    });
  };

  //テキストフィールドの中身が変化したら、contractInfosの中身をそのたびに変える
  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name: string = e.currentTarget.name;
    let value: string = e.currentTarget.value;

    const tmpInfo: contractSetInfo = Object.assign({}, conectContractInfos);
    switch (name) {
      case "applicationNumber":
        tmpInfo.applicationNumber = value;
        break;
      case "contractName":
        tmpInfo.contractName = value;
        break;
      case "contractPower":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 9999999999, 0)) {
          tmpInfo.contractPower = parseInt(value);
        }
        // if (!isNaN(parseInt(value)) && 0 <= parseInt(value)) {
        //   tmpInfo.contractPower = parseInt(value);
        // }
        break;
      //以下のフラグはPh1（域内自己託送のみ対応）時点では""を設定
      //Ph2ではvalueを設定すること
      case "area01Id1":
        tmpInfo.area01Id1 = /*value*/ "";
        break;
      case "area01Id2":
        tmpInfo.area01Id2 = /*value*/ "";
        break;
      case "area02Id1":
        tmpInfo.area02Id1 = /*value*/ "";
        break;
      case "area02Id2":
        tmpInfo.area02Id2 = /*value*/ "";
        break;
      case "area03Id2":
        tmpInfo.area03Id2 = /*value*/ "";
        break;
      case "area03Id1":
        tmpInfo.area03Id1 = /*value*/ "";
        break;
      case "area04Id1":
        tmpInfo.area04Id1 = /*value*/ "";
        break;
      case "area04Id2":
        tmpInfo.area04Id2 = /*value*/ "";
        break;
      case "area05Id1":
        tmpInfo.area05Id1 = /*value*/ "";
        break;
      case "area05Id2":
        tmpInfo.area05Id2 = /*value*/ "";
        break;
      case "area06Id1":
        tmpInfo.area06Id1 = /*value*/ "";
        break;
      case "area06Id2":
        tmpInfo.area06Id2 = /*value*/ "";
        break;
      case "area07Id1":
        tmpInfo.area07Id1 = /*value*/ "";
        break;
      case "area07Id2":
        tmpInfo.area07Id2 = /*value*/ "";
        break;
      case "area08Id1":
        tmpInfo.area08Id1 = /*value*/ "";
        break;
      case "area08Id2":
        tmpInfo.area08Id2 = /*value*/ "";
        break;
      case "area09Id1":
        tmpInfo.area09Id1 = /*value*/ "";
        break;
      case "area09Id2":
        tmpInfo.area09Id2 = /*value*/ "";
        break;
      case "area10Id1":
        tmpInfo.area10Id1 = /*value*/ "";
        break;
      case "area10Id2":
        tmpInfo.area10Id2 = /*value*/ "";
        break;
      // case "linkageCode":
      //   tmpInfo.linkageCode = isNaN(parseInt(value)) ? 0 : parseInt(value);
      //   break;
      default:
        break;
    }
    setConectContractInfos(tmpInfo);
  }

  //テキストフィールドに正しい数値が入っているかチェック
  const isSafeNumInTextField = (
    value: string,
    maxNum: number,
    minNum: number
  ): boolean => {
    // //空文字チェック
    // if (value === "") {
    //   return true;
    // }
    //正しい範囲内の数値が入っているかチェック
    if (!isNaN(parseInt(value))) {
      const valueNum = parseInt(value);
      if (valueNum >= minNum && valueNum <= maxNum) {
        return true;
      }
    }
    return false;
  };
  //console.log(conectContractInfos);

  const Delete = (connectContraId: number | undefined) => {
    // 削除(論理削除)
    // 削除API呼出

    api
      .post(API_URL.DELETE_CONNECTCONTRACT_INFO, {
        connectContraId: connectContraId,
      })
      .then((response) => {
        //console.log(`削除結果: ${response.data.result}`);
        setJobClear(true);
        setDialogMessage(languageContext.words.interconnection_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.interconnection_failed_delete);
        setDigOpen(true);
      });
  };

  // 描画している内容を保存する
  const AddDatas = () => {
    const error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_confirm_entry);
      setDigOpen(true);
    } else if (error === false) {
      const url = api
        .post(API_URL.ADD_CONTRACT_INFO, conectContractInfos)
        .then((response) => {
          if ((response.data.updateCount as number) == 1) {
            setJobClear(true);
            setDialogMessage(languageContext.words.interconnection_added);
            setDigOpen(true);
          } else {
            setDialogMessage(
              languageContext.words.interconnection_err_message_1
            );
            setDigOpen(true);
          }
        })
        .catch((err) => {
          setDialogMessage(languageContext.words.interconnection_failed_add);
          setDigOpen(true);
          console.log(err);
        });
    }
  };

  //BGコードの値をもとにデータを更新する
  function UpdateDatas() {
    //console.log(conectContractInfos);
    const error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_confirm_entry);
      setDigOpen(true);
    } else if (error === false) {
      const url = api
        .post(API_URL.UPDATE_CONTRACTOR_INFO, conectContractInfos)
        .then((response) => {
          if ((response.data.updateCount as number) == 1) {
            setJobClear(true);
            setDialogMessage(languageContext.words.interconnection_updated);
            setDigOpen(true);
          } else {
            setDialogMessage(
              languageContext.words.interconnection_err_message_1
            );
            setDigOpen(true);
          }
        })
        .catch((err) => {
          setDialogMessage(languageContext.words.interconnection_failed_update);
          setDigOpen(true);
          console.log(err);
        });
    }
  }

  //入力内容に漏れがないかチェック
  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (conectContractInfos?.areaId == "00") {
      error = true;
      tempInputErrors.areaId = true;
    }
    if (conectContractInfos?.contractName == "") {
      error = true;
      tempInputErrors.contractName = true;
    }
    if (Number(conectContractInfos?.powerContractorId) == 0) {
      error = true;
      tempInputErrors.powerContractorId = true;
    }
    // if (
    //   conectContractInfos?.areaId == "00" ||
    //   conectContractInfos?.contractName == "" ||
    //   Number.isNaN(conectContractInfos?.powerContractorId)
    // ) {
    //   error = true;
    // }
    setInputError(tempInputErrors);
    return error;
  };

  const DoModeJob = () => {
    if (mode == modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.supply_contract_setting_setting}
      open={toOpen}
      onAcceptLeft={() => DoModeJob()}
      onAcceptRight={() => Delete(conectContractInfos?.connectContraId)}
      onClose={() => HaldleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
      }
    >
      <Stack direction="column" sx={{ height: "540px", width: "550px" }}>
        <Stack direction="row">
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.power_gen_contractor}
            </CustomModalTypography>
            <CustomSelectListBig
              //key={"demandForecastData_CustomSelectList_customer"}
              name="manegeId"
              label={languageContext.words.all_areas}
              //defaultValue={0}
              value={selContractantNo}
              options={contractorInfos.current}
              onChange={(e: number) => {
                setContractor(e);
              }}
              error={inputError.powerContractorId}
            />
          </CustomBoxSmall>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.power_receiving_area}
            </CustomModalTypography>

            <CustomTextFieldSmall
              //key={"demandForecastData_CustomSelectList_customer"}
              name="areaId"
              //label={languageContext.words.all_areas}
              value={selContractantArea}
              disabled={true}
              error={inputError.areaId}
            />
          </CustomBoxSmall>
        </Stack>

        <CustomBoxSmall>
          <CustomModalTypography gutterBottom>
            {languageContext.words.application_number_B}
          </CustomModalTypography>
          <CustomTextFieldSmall
            type="text"
            value={conectContractInfos?.applicationNumber} //申込番号
            onChange={onChangeValue}
            name="applicationNumber"
            inputProps={{
              maxLength: 13,
            }}
          ></CustomTextFieldSmall>
        </CustomBoxSmall>

        <CustomBoxBig>
          <CustomModalTypography gutterBottom>
            {languageContext.words.contract_name}
          </CustomModalTypography>
          <CustomTextFieldBig
            type="text"
            value={conectContractInfos?.contractName} //契約名称
            onChange={onChangeValue}
            name="contractName"
            inputProps={{
              maxLength: 50,
            }}
            error={inputError.contractName}
            helperText={languageContext.words.setting_required}
          ></CustomTextFieldBig>
        </CustomBoxBig>

        <CustomBoxSmall>
          <CustomModalTypography gutterBottom>
            {languageContext.words.contracted_power}(MW)
          </CustomModalTypography>
          <CustomTextFieldSmall
            type="number"
            onFocus={(e) => {
              if (Number(conectContractInfos?.contractPower) === 0) {
                e.currentTarget.select();
              }
            }}
            value={Number(conectContractInfos?.contractPower).toFixed(0)} //契約電力
            onChange={onChangeValue}
            name="contractPower"
            inputProps={{
              step: 1,
              min: 0,
              max: 9999999999,
            }}
          ></CustomTextFieldSmall>
        </CustomBoxSmall>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.effective_date_start_date}
            </CustomModalTypography>
            <CustomDatePickerTryal
              setValue={setStartDate}
              value={startDate}
              maxDate={subDays(finishDate, 1)}
            />
          </CustomBoxSmall>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.expiration_date}
            </CustomModalTypography>
            <CustomDatePickerTryal
              setValue={setFinishDate}
              value={finishDate}
              minDate={addDays(startDate, 1)}
            />
          </CustomBoxSmall>
        </Stack>
      </Stack>

      {/* 有効期限 */}

      {/* 有効期限 */}

      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetPowerContractList;
