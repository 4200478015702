import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useBeforeUnload } from "react-router-dom";
import {
  DateContext,
  GlobalSkeletonDatePicker,
} from "../../common/customComponents/GlobalDatePicker";
import JapanMap from "./JapanMap";
import * as StatusList from "./StatusList";
import { differenceInMinutes, format, subDays, subHours } from "date-fns";
import styled from "styled-components";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import OutlinedFlagIcon from "@mui/icons-material/OutlinedFlag";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckIcon from "@mui/icons-material/Check";
import BarChartIcon from "@mui/icons-material/BarChart";
import BoltIcon from "@mui/icons-material/Bolt";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import Chip from "@mui/material/Chip";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import { statusListInfo, systemTime } from "./StatusList";
import { LanguageContext } from "../../common/localization/localization";
import axios from "axios";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import { PaletteContext } from "../../common/paletteMode";
import { useAreaUtility } from "../../../common/area";
import {
  DEMAND_FORECAST_URL,
  DEMAND_FORECAST_PLAN_URL,
  POWER_FORECAST_URL,
  POWER_FORECAST_PLAN_URL,
  DEMAND_MONITORING_URL,
  POWER_GENERATION_MONITORING_URL,
  SPOT_BIDDING_URL,
  INTRADAY_BIDDING_URL,
  BALANCING_PLAN_URL,
} from "../../../common/url";
import { useAuthedApi } from "../../../common/axios";

const StyledPaper = styled(Paper)`
  padding: 10px;
`;

const StatusButton = styled(Button)`
  && {
    padding: 0px;
    margin: 2px;
    min-width: 48px;
  }
`;

const SingleStatusButton = styled(Button)`
  && {
    padding: 0px;
    margin: 2px 10px;
    min-width: 48px;
  }
`;

type diffTime = {
  hours: number;
  minutes: number;
};

type circularStatusInfo = {
  closingSystem: string;
  closingSystemTime: Date;
  bidAreaNum: number;
  unBidAreaNum: number;
  limitTime: diffTime;
  AreaFixStr: string;
  AreaUnFixStr: string;
};

const GET_AREA_STATUS = "/getAreaStatus";
const GET_AREA_FIXED_STATUS = "/getAreaFixedStatus";

const WHITE = "#FFFFFF";
const LIGHTGRAY = "#383838";
const BLUE = "#1976d2";
const RED = "#D32F2F";

const PlanStatusList = () => {
  //言語を切り替えるデータコンテキスト
  const { words, mode } = useContext(LanguageContext);

  //エリアコンテキスト
  const areaContext = useContext(AreaContext);
  const pickDate = useContext(DateContext);
  const { time } = useContext(TimeContext);
  const [dispStatusData, setDispDate] = useState<statusListInfo[]>([]);
  const [limitJepx, setDispLimitJepx] = useState(systemTime.balancingTimeLimit);
  const [limitOccto, setDispLimitOccto] = useState(
    systemTime.balancingTimeLimit
  );
  const [areaCount, setAreaCount] = useState(0);
  const [areaSpotFixedCount, setAreaSpotFixedCount] = useState(0);
  const [areaOcctoFixedCount, setAreaOcctoFixedCount] = useState(0);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);
  const initMapColor = PaletteMode === "dark" ? LIGHTGRAY : WHITE;

  // 地図の色
  const [area01, setArea01] = useState(initMapColor);
  const [area02, setArea02] = useState(initMapColor);
  const [area03, setArea03] = useState(initMapColor);
  const [area04, setArea04] = useState(initMapColor);
  const [area05, setArea05] = useState(initMapColor);
  const [area06, setArea06] = useState(initMapColor);
  const [area07, setArea07] = useState(initMapColor);
  const [area08, setArea08] = useState(initMapColor);
  const [area09, setArea09] = useState(initMapColor);
  const [area10, setArea10] = useState(initMapColor);

  const [digOpen, setDigOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const { convertIdToAreaName } = useAreaUtility();

  // 通信エラーを監視
  const api = useAuthedApi();
  //日付変更時
  useEffect(() => {
    upDate();
  }, [pickDate.pickDate, time, PaletteMode]);

  function upDate() {
    // エリア毎の情報を取得
    getAreaStatus();
    // 入札済み、提出済み計画数を取得
    // getAreaFixedStatus();
    // 制限時間更新
    setDispLimitJepx(
      new Date(
        subDays(pickDate.pickDate as Date, 1).toDateString() + " 10:00:00"
      )
    );
    setDispLimitOccto(
      new Date(
        subDays(pickDate.pickDate as Date, 1).toDateString() + " 12:00:00"
      )
    );
  }

  function getAreaStatus() {
    // 表示エリアの取得
    api
      .get(GET_AREA_STATUS, {
        params: { date: pickDate.pickDate?.toLocaleString() },
      })
      .then((res) => {
        if (res.data.length != 0) {
          // 対象エリア数
          setAreaCount(res.data.length);
          // 入札済みエリア数
          let spotCount = 0;
          // 提出済みエリア数
          let occtoCount = 0;

          // エリアの状況によって地図の色味を更新
          for (let i = 0; i < res.data.length; i++) {
            const item: statusListInfo = res.data[i];

            // 入札済み
            if (item.spotFixedTime != null) {
              spotCount++;
            }

            if (
              item.BalancingPowerFixedTime != null ||
              item.BalancingDemandFixedTime != null
            ) {
              occtoCount++;
            }

            // 入札状態確認(スポット)
            if (item.spotPlanBitCount == 0) {
              item.spotPlanStatus = 0;
            } else if (item.spotFixedTime != null) {
              item.spotPlanStatus = 2;
            } else {
              item.spotPlanStatus = 1;
            }
            // 入札状態確認(1時間前)
            if (
              item.hourFixedTime == null &&
              item.hourPlanBitCountBeforeGC == 0
            ) {
              item.hourPlanStatus = 0;
            } else if (
              item.hourPlanBitCountBeforeGC != item.hourPlanCountBeforeGC
            ) {
              item.hourPlanStatus = 1;
            } else {
              item.hourPlanStatus = 2;
            }

            // 計画提出状態
            // if (item.powerFixedTime == null) {
            //   item.BalancingPowerStatus = 0;
            // } else if (item.BalancingPowerFixedTime == null) {
            //   item.BalancingPowerStatus = 1;
            // } else {
            //   item.BalancingPowerStatus = 2;
            // }

            if (item.BalancingPowerFixedTime != null) {
              item.BalancingPowerStatus = 2;
            } else if (item.BalancingPowerUnsendTime == null) {
              item.BalancingPowerStatus = 0;
            } else {
              item.BalancingPowerStatus = 1;
            }

            // 計画提出状態
            // if (item.demandFixedTime == null) {
            //   item.BalancingDemandPlanStatus = 0;
            //   item.BalancingPartsPlanStatus = 0;
            // } else if (item.BalancingDemandFixedTime == null) {
            //   item.BalancingDemandPlanStatus = 1;
            //   item.BalancingPartsPlanStatus = 1;
            // } else {
            //   item.BalancingDemandPlanStatus = 2;

            //   if (item.BalancingPartsFixedTime != null) {
            //     item.BalancingPartsPlanStatus = 2;
            //   } else {
            //     item.BalancingPartsPlanStatus = 0;
            //   }
            // }

            if (item.BalancingDemandFixedTime != null) {
              item.BalancingDemandPlanStatus = 2;
            } else if (item.BalancingDemandUnsendTime == null) {
              item.BalancingDemandPlanStatus = 0;
            } else {
              item.BalancingDemandPlanStatus = 1;
            }

            if (item.BalancingPartsFixedTime != null) {
              item.BalancingPartsPlanStatus = 2;
            } else if (item.BalancingPartsUnsendTime == null) {
              item.BalancingPartsPlanStatus = 0;
            } else {
              item.BalancingPartsPlanStatus = 1;
            }

            let mapColor = BLUE;
            if (
              item.demandFixedTime == null ||
              item.powerFixedTime == null ||
              item.spotFixedTime == null ||
              item.hourFixedTime == null ||
              item.BalancingDemandFixedTime == null ||
              item.BalancingPowerFixedTime == null ||
              item.BalancingPartsFixedTime == null
            ) {
              // 作業が終わっていないエリア
              mapColor = RED;
            }
            // 該当エリアの色を変更
            MapColorChanger(item.areaId, mapColor);

            res.data[i] = item;
          }

          // 該当エリア以外のマップの色を初期値に戻す
          const initColorArea = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          for (let i = 0; i < initColorArea.length; i++) {
            const areaId = ("00" + (i + 1)).slice(-2);
            for (let j = 0; j < res.data.length; j++) {
              if (areaId === res.data[j].areaId) initColorArea[i] = 1;
            }
            if (initColorArea[i] != 1) MapColorChanger(areaId, initMapColor);
          }

          // 所属エリアの情報を取得
          setDispDate(res.data);
          setAreaSpotFixedCount(spotCount);
          setAreaOcctoFixedCount(occtoCount);
        } else {
          // エリア関連の登録が未設定
          setDialogMessage(words.status_not_area_setting);
          setDigOpen(true);
        }
      })
      .catch((error) => {
        // TODO エラー対応
      });
  }

  const toHourAndMinutes = (diff: number) => {
    if (diff < 0) {
      return { hours: 0, minutes: 0 };
    }
    const hour = Math.floor(diff / 60);
    const minute = diff % 60;
    return { hours: hour, minutes: minute };
  };

  const spotDiffTime: diffTime = toHourAndMinutes(
    differenceInMinutes(
      new Date(
        subDays(pickDate.pickDate as Date, 1).toDateString() + " 10:00:00"
      ),
      time
    )
  );

  const balancingDiffTime: diffTime = toHourAndMinutes(
    differenceInMinutes(
      new Date(
        subDays(pickDate.pickDate as Date, 1).toDateString() + " 12:00:00"
      ),
      time
    )
  );

  const MapColorChanger = (areaId: string, mapColor: string) => {
    // 該当エリアの色を変更
    switch (areaId) {
      case "01":
        setArea01(mapColor);
        break;
      case "02":
        setArea02(mapColor);
        break;
      case "03":
        setArea03(mapColor);
        break;
      case "04":
        setArea04(mapColor);
        break;
      case "05":
        setArea05(mapColor);
        break;
      case "06":
        setArea06(mapColor);
        break;
      case "07":
        setArea07(mapColor);
        break;
      case "08":
        setArea08(mapColor);
        break;
      case "09":
        setArea09(mapColor);
        break;
      case "10":
        setArea10(mapColor);
        break;
    }
  };

  const SinglePlanStatus = (props: StatusList.statusListInfo) => {
    const languageContext = useContext(LanguageContext);
    const statusData: StatusList.statusListInfo = props;
    const navigate = useNavigate();
    const handleClickLink = (path: string) => {
      navigate(path);
    };
    const areaContext = useContext(AreaContext);

    function showStatusJepxChip(isShow: boolean, status: number) {
      let text = "";
      let chipColor: any = "success";

      switch (status) {
        case 0: // 未入札
          text = languageContext.words.unbid;
          chipColor = "error";
          break;
        case 1: // 約定待ち
          text = languageContext.words.waiting_for_contract;
          chipColor = "info";
          break;
        case 2: // 約定済
          text = languageContext.words.contracted;
          chipColor = "primary";
          break;
        default:
          break;
      }

      if (isShow == true) {
        return (
          <Chip
            label={text}
            variant="filled"
            size="small"
            color={chipColor}
            style={{ fontSize: "16px", padding: "0px 4px" }}
          />
        );
      } else {
        return <></>;
      }
    }

    function showStatusOcctoChip(isShow: boolean, status: number) {
      let text = "";
      let chipColor: any = "success";

      switch (status) {
        case 0: // 未計画
          text = languageContext.words.unplanned;
          chipColor = "error";
          break;
        case 1: // 計画済
          text = languageContext.words.fixed;
          chipColor = "info";
          break;
        case 2: // 提出済
          text = languageContext.words.submitted;
          chipColor = "primary";
          break;
      }

      if (isShow == true) {
        return (
          <Chip
            label={text}
            variant="filled"
            size="small"
            color={chipColor}
            style={{ fontSize: "16px", padding: "0px 4px" }}
          />
        );
      } else {
        return <></>;
      }
    }

    return (
      <Card variant="outlined" sx={{ maxWidth: "100%", m: "5px", p: "5px" }}>
        <CardContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                fontSize="22px"
                fontWeight={"bold"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <LocationOnIcon
                  color={
                    statusData.demandFixedTime == null ||
                    statusData.powerFixedTime == null ||
                    statusData.spotFixedTime == null ||
                    statusData.hourFixedTime == null ||
                    statusData.BalancingDemandFixedTime == null ||
                    statusData.BalancingPowerFixedTime == null ||
                    statusData.BalancingPartsFixedTime == null
                      ? "error"
                      : "primary"
                  }
                  fontSize="large"
                />
                {convertIdToAreaName(statusData.areaId)}
              </Grid>
              <Grid item xs={6}>
                <StyledPaper
                  variant="outlined"
                  sx={{
                    backgroundColor:
                      statusData.powerFixedTime !== null
                        ? "#00000000"
                        : PaletteMode === "dark"
                        ? "#3E2F2F"
                        : "#D32F2F0A",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      fontSize="18px"
                      fontWeight={"bold"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <BoltIcon
                        color={
                          statusData.powerFixedTime == null
                            ? "error"
                            : "primary"
                        }
                        fontSize="small"
                        sx={{ mr: "5px" }}
                      />
                      {languageContext.words.power_gen_plan}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      direction={"row"}
                      justifyContent={"end"}
                    >
                      <SingleStatusButton
                        variant="outlined"
                        size="small"
                        style={{ fontSize: "16px", padding: "0px 10px" }}
                        onClick={() =>
                          handleClickLink(
                            ".." +
                              POWER_GENERATION_MONITORING_URL +
                              "?areaId=" +
                              statusData.areaId
                          )
                        }
                      >
                        {languageContext.words.monitoring}
                      </SingleStatusButton>
                      <SingleStatusButton
                        variant="outlined"
                        size="small"
                        style={{ fontSize: "16px", padding: "0px 10px" }}
                        onClick={() =>
                          handleClickLink(
                            ".." +
                              POWER_FORECAST_URL +
                              "?areaId=" +
                              statusData.areaId
                          )
                        }
                      >
                        {languageContext.words.forecast}
                      </SingleStatusButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="16px"
                      color={"gray"}
                      sx={{ mt: "15px" }}
                    >
                      {languageContext.words.decision_date_and_time}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="18px"
                      fontWeight={"bold"}
                      sx={{ mt: "10px" }}
                    >
                      {statusData.powerFixedTime !== null
                        ? languageContext.convertDateToLocalizedStringGoodAcu(
                            new Date(statusData.powerFixedTime)
                          )
                        : "-"}
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>
              <Grid item xs={6}>
                <StyledPaper
                  variant="outlined"
                  sx={{
                    backgroundColor:
                      statusData.demandFixedTime !== null
                        ? "#00000000"
                        : PaletteMode === "dark"
                        ? "#3E2F2F"
                        : "#D32F2F0A",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      fontSize="18px"
                      fontWeight={"bold"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <BarChartIcon
                        color={
                          statusData.demandFixedTime == null
                            ? "error"
                            : "primary"
                        }
                        fontSize="small"
                        sx={{ mr: "5px" }}
                      />
                      {languageContext.words.demandforecast}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      direction={"row"}
                      justifyContent={"end"}
                    >
                      <SingleStatusButton
                        variant="outlined"
                        size="small"
                        style={{ fontSize: "16px", padding: "0px 10px" }}
                        onClick={() =>
                          handleClickLink(
                            ".." +
                              DEMAND_MONITORING_URL +
                              "?areaId=" +
                              statusData.areaId
                          )
                        }
                      >
                        {languageContext.words.monitoring}
                      </SingleStatusButton>
                      <SingleStatusButton
                        variant="outlined"
                        size="small"
                        style={{ fontSize: "16px", padding: "0px 10px" }}
                        onClick={() =>
                          handleClickLink(
                            ".." +
                              DEMAND_FORECAST_URL +
                              "?areaId=" +
                              statusData.areaId
                          )
                        }
                      >
                        {languageContext.words.forecast}
                      </SingleStatusButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="16px"
                      color={"gray"}
                      sx={{ mt: "15px" }}
                    >
                      {languageContext.words.decision_date_and_time}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="18px"
                      fontWeight={"bold"}
                      sx={{ mt: "10px" }}
                    >
                      {statusData.demandFixedTime !== null
                        ? languageContext.convertDateToLocalizedStringGoodAcu(
                            new Date(statusData.demandFixedTime)
                          )
                        : "-"}
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>
              <Grid item xs={6}>
                <StyledPaper
                  variant="outlined"
                  sx={{
                    backgroundColor: statusData.spotFixedTime
                      ? "#00000000"
                      : PaletteMode === "dark"
                      ? "#3E2F2F"
                      : "#D32F2F0A",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      fontSize="18px"
                      fontWeight={"bold"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <OutlinedFlagIcon
                        color={statusData.spotFixedTime ? "primary" : "error"}
                        fontSize="small"
                        sx={{ mr: "5px" }}
                      />
                      {languageContext.words.spot_bidding}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      direction={"row"}
                      justifyContent={"end"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {showStatusJepxChip(true, statusData.spotPlanStatus)}
                      <SingleStatusButton
                        variant="outlined"
                        size="small"
                        style={{ fontSize: "16px", padding: "0px 10px" }}
                        onClick={() => {
                          areaContext.setAreaId(statusData.areaId);
                          handleClickLink(
                            ".." +
                              SPOT_BIDDING_URL +
                              "?areaId=" +
                              statusData.areaId
                          );
                        }}
                      >
                        {languageContext.words.bidding}
                      </SingleStatusButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="16px"
                      color={"gray"}
                      sx={{ mt: "15px" }}
                    >
                      {languageContext.words.date_and_time_reflection_contract}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="18px"
                      fontWeight={"bold"}
                      sx={{ mt: "10px" }}
                    >
                      {statusData.spotFixedTime !== null
                        ? languageContext.convertDateToLocalizedStringGoodAcu(
                            subHours(new Date(statusData.spotFixedTime), 9)
                          )
                        : "-"}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      fontSize="16px"
                      color={"gray"}
                      sx={{ mt: "15px" }}
                    >
                      {languageContext.words.num_of_normal_bid}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      fontSize="16px"
                      color={"gray"}
                      sx={{ mt: "15px" }}
                    >
                      {languageContext.words.block_bids_executed_planned_num}
                    </Grid>
                    <Grid item xs={4}>
                      <LinearProgress
                        style={{
                          height: "10px",
                          width: "100%",
                          marginTop: "5px",
                        }}
                        variant="determinate"
                        value={
                          (statusData.spotPlanBitCount /
                            statusData.spotPlanCount) *
                          100
                        }
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      {statusData.spotPlanBitCount}/{statusData.spotPlanCount}
                    </Grid>
                    <Grid item xs={4}>
                      <LinearProgress
                        style={{
                          height: "10px",
                          width: "100%",
                          marginTop: "5px",
                        }}
                        variant="determinate"
                        value={
                          (statusData.blockPlanBitCount /
                            statusData.blockPlanCount) *
                          100
                        }
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      {statusData.blockPlanBitCount}/{statusData.blockPlanCount}
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>
              <Grid item xs={6}>
                <StyledPaper
                  variant="outlined"
                  sx={{
                    backgroundColor: statusData.hourFixedTime
                      ? "#00000000"
                      : PaletteMode === "dark"
                      ? "#3E2F2F"
                      : "#D32F2F0A",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      fontSize="18px"
                      fontWeight={"bold"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <TimerOutlinedIcon
                        color={statusData.hourFixedTime ? "primary" : "error"}
                        fontSize="small"
                        sx={{ mr: "5px" }}
                      />
                      {languageContext.words.one_hour_ago_bidding}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      direction={"row"}
                      justifyContent={"end"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {showStatusJepxChip(true, statusData.hourPlanStatus)}
                      <SingleStatusButton
                        variant="outlined"
                        size="small"
                        style={{ fontSize: "16px", padding: "0px 10px" }}
                        onClick={() => {
                          areaContext.setAreaId(statusData.areaId);
                          handleClickLink(
                            ".." +
                              INTRADAY_BIDDING_URL +
                              "?areaId=" +
                              statusData.areaId
                          );
                        }}
                      >
                        {languageContext.words.bidding}
                      </SingleStatusButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="16px"
                      color={"gray"}
                      sx={{ mt: "10px" }}
                    >
                      {languageContext.words.date_and_time_reflection_contract}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="18px"
                      fontWeight={"bold"}
                      sx={{ mt: "15px" }}
                    >
                      {statusData.hourFixedTime !== null
                        ? languageContext.convertDateToLocalizedStringGoodAcu(
                            subHours(new Date(statusData.hourFixedTime), 9)
                          )
                        : "-"}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      fontSize="16px"
                      color={"gray"}
                      sx={{ mt: "15px" }}
                    >
                      {languageContext.words.contracts_bids_num_after_gc}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      fontSize="16px"
                      color={"gray"}
                      sx={{ mt: "15px" }}
                    >
                      {languageContext.words.contracts_bids_num_before_gc}
                    </Grid>
                    <Grid item xs={4}>
                      <LinearProgress
                        style={{
                          height: "10px",
                          width: "100%",
                          marginTop: "5px",
                        }}
                        variant="determinate"
                        value={
                          (statusData.hourPlanCountAfterGC /
                            statusData.hourPlanBitCountAfterGC) *
                          100
                        }
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      {statusData.hourPlanCountAfterGC}/
                      {statusData.hourPlanBitCountAfterGC}
                    </Grid>
                    <Grid item xs={4}>
                      <LinearProgress
                        style={{
                          height: "10px",
                          width: "100%",
                          marginTop: "5px",
                        }}
                        variant="determinate"
                        value={
                          (statusData.hourPlanCountBeforeGC /
                            statusData.hourPlanBitCountBeforeGC) *
                          100
                        }
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      {statusData.hourPlanCountBeforeGC}/
                      {statusData.hourPlanBitCountBeforeGC}
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>
              <Grid item xs={12}>
                <StyledPaper
                  variant="outlined"
                  sx={{
                    backgroundColor:
                      statusData.BalancingDemandFixedTime &&
                      statusData.BalancingPartsFixedTime &&
                      statusData.BalancingPowerFixedTime
                        ? "#00000000"
                        : PaletteMode === "dark"
                        ? "#3E2F2F"
                        : "#D32F2F0A",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      fontSize="18px"
                      fontWeight={"bold"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <TaskOutlinedIcon
                        color={
                          statusData.BalancingDemandFixedTime &&
                          statusData.BalancingPartsFixedTime &&
                          statusData.BalancingPowerFixedTime
                            ? "primary"
                            : "error"
                        }
                        fontSize="small"
                        sx={{ mr: "5px" }}
                      />
                      {languageContext.words.simultaneous_commensurate_plan}
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      fontSize="16px"
                      color={"gray"}
                      sx={{ mt: "15px" }}
                    >
                      {
                        languageContext.words
                          .final_confirmation_time_power_gen_and_sales_plan
                      }
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      fontSize="16px"
                      color={"gray"}
                      sx={{ mt: "15px" }}
                    >
                      {
                        languageContext.words
                          .final_confirmation_time_demand_and_procurement_plan
                      }
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      fontSize="16px"
                      color={"gray"}
                      sx={{ mt: "15px" }}
                    >
                      {
                        languageContext.words
                          .final_confirmation_time_partial_supply_plan
                      }
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {showStatusOcctoChip(
                        true,
                        statusData.BalancingPowerStatus
                      )}
                      <SingleStatusButton
                        size="small"
                        style={{ marginTop: "10px", fontSize: "16px" }}
                        onClick={() =>
                          handleClickLink(
                            ".." +
                              POWER_FORECAST_PLAN_URL +
                              "?areaId=" +
                              statusData.areaId
                          )
                        }
                      >
                        {languageContext.words.power_gen_and_sales_plan}
                      </SingleStatusButton>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={4}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {showStatusOcctoChip(
                        true,
                        statusData.BalancingDemandPlanStatus
                      )}
                      <SingleStatusButton
                        size="small"
                        style={{ marginTop: "10px", fontSize: "16px" }}
                        onClick={() =>
                          handleClickLink(
                            ".." +
                              DEMAND_FORECAST_PLAN_URL +
                              "?areaId=" +
                              statusData.areaId
                          )
                        }
                      >
                        {languageContext.words.demand_procurement_plan}
                      </SingleStatusButton>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={4}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {showStatusOcctoChip(
                        true,
                        statusData.BalancingPartsPlanStatus
                      )}
                      <SingleStatusButton
                        size="small"
                        style={{ marginTop: "10px", fontSize: "16px" }}
                        onClick={() =>
                          handleClickLink(
                            ".." +
                              BALANCING_PLAN_URL +
                              "?areaId=" +
                              statusData.areaId
                          )
                        }
                      >
                        {languageContext.words.simultaneous_commensurate_plan}
                      </SingleStatusButton>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={4}
                      fontSize="18px"
                      fontWeight={"bold"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mt: "15px",
                        mb: "10px",
                      }}
                    >
                      {statusData.BalancingPowerFixedTime !== null
                        ? languageContext.convertDateToLocalizedStringGoodAcu(
                            new Date(statusData.BalancingPowerFixedTime)
                          )
                        : ""}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={4}
                      fontSize="18px"
                      fontWeight={"bold"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {statusData.BalancingDemandFixedTime !== null
                        ? languageContext.convertDateToLocalizedStringGoodAcu(
                            new Date(statusData.BalancingDemandFixedTime)
                          )
                        : ""}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={4}
                      fontSize="18px"
                      fontWeight={"bold"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {statusData.BalancingPartsFixedTime !== null
                        ? languageContext.convertDateToLocalizedStringGoodAcu(
                            new Date(statusData.BalancingPartsFixedTime)
                          )
                        : ""}
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    );
  };

  const MultiPlanStatus = (props: StatusList.statusListInfo) => {
    const languageContext = useContext(LanguageContext);
    const statusData: StatusList.statusListInfo = props;
    const navigate = useNavigate();
    const handleClickLink = (path: string) => {
      navigate(path);
    };
    const areaContext = useContext(AreaContext);

    function showStatusJepxChip(isShow: boolean, status: number) {
      let text = "";
      let chipColor: any = "success";

      switch (status) {
        case 0: // 未入札
          text = languageContext.words.unbid;
          chipColor = "error";
          break;
        case 1: // 約定待ち
          text = languageContext.words.waiting_for_contract;
          chipColor = "info";
          break;
        case 2: // 約定済
          text = languageContext.words.contracted;
          chipColor = "primary";
          break;
        default:
          break;
      }

      if (isShow == true) {
        return (
          <Chip
            label={text}
            variant="filled"
            size="small"
            color={chipColor}
            style={{ fontSize: "16px", padding: "0px 4px" }}
          />
        );
      } else {
        return <></>;
      }
    }

    function showStatusOcctoChip(isShow: boolean, status: number) {
      let text = "";
      let chipColor: any = "success";

      switch (status) {
        case 0: // 未計画
          text = languageContext.words.unplanned;
          chipColor = "error";
          break;
        case 1: // 計画済
          text = languageContext.words.fixed;
          chipColor = "info";
          break;
        case 2: // 提出済
          text = languageContext.words.submitted;
          chipColor = "primary";
          break;
      }

      if (isShow == true) {
        return (
          <Chip
            label={text}
            variant="filled"
            size="small"
            color={chipColor}
            style={{ fontSize: "16px", padding: "0px 4px" }}
          />
        );
      } else {
        return <></>;
      }
    }

    return (
      <Card variant="outlined" sx={{ maxWidth: "100%", m: "5px" }}>
        <CardContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                fontSize="18px"
                fontWeight={"bold"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <LocationOnIcon
                  color={
                    statusData.demandFixedTime == null ||
                    statusData.powerFixedTime == null ||
                    statusData.spotFixedTime == null ||
                    statusData.hourFixedTime == null ||
                    statusData.BalancingDemandFixedTime == null ||
                    statusData.BalancingPowerFixedTime == null ||
                    statusData.BalancingPartsFixedTime == null
                      ? "error"
                      : "primary"
                  }
                  fontSize="large"
                />
                {convertIdToAreaName(statusData.areaId)}
              </Grid>
              <Grid item xs={mode === "jp" ? 6 : 12}>
                <StyledPaper
                  variant="outlined"
                  sx={{
                    backgroundColor:
                      statusData.powerFixedTime !== null
                        ? "#00000000"
                        : PaletteMode === "dark"
                        ? "#3E2F2F"
                        : "#D32F2F0A",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      fontSize="14px"
                      fontWeight={"bold"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <BoltIcon
                        color={
                          statusData.powerFixedTime == null
                            ? "error"
                            : "primary"
                        }
                        fontSize="small"
                        sx={{ mr: "5px" }}
                      />
                      {languageContext.words.power_gen_plan}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      direction={"row"}
                      justifyContent={"end"}
                    >
                      <StatusButton
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          handleClickLink(
                            ".." +
                              POWER_GENERATION_MONITORING_URL +
                              "?areaId=" +
                              statusData.areaId
                          )
                        }
                      >
                        {languageContext.words.monitoring}
                      </StatusButton>
                      <StatusButton
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          {
                            areaContext.setAreaId(statusData.areaId);
                          handleClickLink(
                            ".." +
                              POWER_FORECAST_URL +
                              "?areaId=" +
                              statusData.areaId
                          )
                        }
                        }
                      >
                        {languageContext.words.plan}
                      </StatusButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="12px"
                      color={"gray"}
                      sx={{ mt: "10px" }}
                    >
                      {languageContext.words.decision_date_and_time}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="14px"
                      fontWeight={"bold"}
                      sx={{ m: "0px" }}
                    >
                      {statusData.powerFixedTime !== null
                        ? languageContext.convertDateToLocalizedStringGoodAcu(
                            new Date(statusData.powerFixedTime)
                          )
                        : "-"}
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>
              <Grid item xs={mode === "jp" ? 6 : 12}>
                <StyledPaper
                  variant="outlined"
                  sx={{
                    backgroundColor:
                      statusData.demandFixedTime !== null
                        ? "#00000000"
                        : PaletteMode === "dark"
                        ? "#3E2F2F"
                        : "#D32F2F0A",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      fontSize="14px"
                      fontWeight={"bold"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <BarChartIcon
                        color={
                          statusData.demandFixedTime == null
                            ? "error"
                            : "primary"
                        }
                        fontSize="small"
                        sx={{ mr: "5px" }}
                      />
                      {languageContext.words.demand_plan}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      direction={"row"}
                      justifyContent={"end"}
                    >
                      <StatusButton
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          handleClickLink(
                            ".." +
                              DEMAND_MONITORING_URL +
                              "?areaId=" +
                              statusData.areaId
                          )
                        }
                      >
                        {languageContext.words.monitoring}
                      </StatusButton>
                      <StatusButton
                        variant="outlined"
                        size="small"
                        onClick={() =>
                          {areaContext.setAreaId(statusData.areaId);
                          handleClickLink(
                            ".." +
                              DEMAND_FORECAST_URL +
                              "?areaId=" +
                              statusData.areaId
                          )}
                        }
                      >
                        {languageContext.words.plan}
                      </StatusButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="12px"
                      color={"gray"}
                      sx={{ mt: "10px" }}
                    >
                      {languageContext.words.decision_date_and_time}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="14px"
                      fontWeight={"bold"}
                      sx={{ m: "0px" }}
                    >
                      {statusData.demandFixedTime !== null
                        ? languageContext.convertDateToLocalizedStringGoodAcu(
                            new Date(statusData.demandFixedTime)
                          )
                        : "-"}
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>
              <Grid item xs={12}>
                <StyledPaper
                  variant="outlined"
                  sx={{
                    backgroundColor: statusData.spotFixedTime
                      ? "#00000000"
                      : PaletteMode === "dark"
                      ? "#3E2F2F"
                      : "#D32F2F0A",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      fontSize="14px"
                      fontWeight={"bold"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <OutlinedFlagIcon
                        color={statusData.spotFixedTime ? "primary" : "error"}
                        fontSize="small"
                        sx={{ mr: "5px" }}
                      />
                      {languageContext.words.spot_bidding}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      direction={"row"}
                      justifyContent={"end"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {showStatusJepxChip(true, statusData.spotPlanStatus)}
                      <StatusButton
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          areaContext.setAreaId(statusData.areaId);
                          handleClickLink(
                            ".." +
                              SPOT_BIDDING_URL +
                              "?areaId=" +
                              statusData.areaId
                          );
                        }}
                      >
                        {languageContext.words.bidding}
                      </StatusButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="12px"
                      color={"gray"}
                      sx={{ mt: "10px" }}
                    >
                      {languageContext.words.date_and_time_reflection_contract}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="14px"
                      fontWeight={"bold"}
                      sx={{ m: "0px" }}
                    >
                      {statusData.spotFixedTime !== null
                        ? languageContext.convertDateToLocalizedStringGoodAcu(
                            subHours(new Date(statusData.spotFixedTime), 9)
                          )
                        : "-"}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      fontSize="12px"
                      color={"gray"}
                      sx={{ mt: "10px" }}
                    >
                      {languageContext.words.num_of_normal_bid}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      fontSize="12px"
                      color={"gray"}
                      sx={{ mt: "10px" }}
                    >
                      {languageContext.words.block_bids_executed_planned_num}
                    </Grid>
                    <Grid item xs={4}>
                      <LinearProgress
                        style={{
                          height: "10px",
                          width: "100%",
                          marginTop: "5px",
                        }}
                        variant="determinate"
                        value={
                          (statusData.spotPlanBitCount /
                            statusData.spotPlanCount) *
                          100
                        }
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      {statusData.spotPlanBitCount}/{statusData.spotPlanCount}
                    </Grid>
                    <Grid item xs={4}>
                      <LinearProgress
                        style={{
                          height: "10px",
                          width: "100%",
                          marginTop: "5px",
                        }}
                        variant="determinate"
                        value={
                          (statusData.blockPlanBitCount /
                            statusData.blockPlanCount) *
                          100
                        }
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      {statusData.blockPlanBitCount}/{statusData.blockPlanCount}
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>
              <Grid item xs={12}>
                <StyledPaper
                  variant="outlined"
                  sx={{
                    backgroundColor: statusData.hourFixedTime
                      ? "#00000000"
                      : PaletteMode === "dark"
                      ? "#3E2F2F"
                      : "#D32F2F0A",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      fontSize="14px"
                      fontWeight={"bold"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <TimerOutlinedIcon
                        color={statusData.hourFixedTime ? "primary" : "error"}
                        fontSize="small"
                        sx={{ mr: "5px" }}
                      />
                      {languageContext.words.one_hour_ago_bidding}
                    </Grid>
                    <Grid
                      container
                      item
                      xs={6}
                      direction={"row"}
                      justifyContent={"end"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {showStatusJepxChip(true, statusData.hourPlanStatus)}
                      <StatusButton
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          areaContext.setAreaId(statusData.areaId);
                          handleClickLink(
                            ".." +
                              INTRADAY_BIDDING_URL +
                              "?areaId=" +
                              statusData.areaId
                          );
                        }}
                      >
                        {languageContext.words.bidding}
                      </StatusButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="12px"
                      color={"gray"}
                      sx={{ mt: "10px" }}
                    >
                      {languageContext.words.date_and_time_reflection_contract}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="14px"
                      fontWeight={"bold"}
                      sx={{ m: "0px" }}
                    >
                      {statusData.hourFixedTime !== null
                        ? languageContext.convertDateToLocalizedStringGoodAcu(
                            subHours(new Date(statusData.hourFixedTime), 9)
                          )
                        : "-"}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      fontSize="12px"
                      color={"gray"}
                      sx={{ mt: "10px" }}
                    >
                      {languageContext.words.contracts_bids_num_after_gc}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      fontSize="12px"
                      color={"gray"}
                      sx={{ mt: "10px" }}
                    >
                      {languageContext.words.contracts_bids_num_before_gc}
                    </Grid>
                    <Grid item xs={4}>
                      <LinearProgress
                        style={{
                          height: "10px",
                          width: "100%",
                          marginTop: "5px",
                        }}
                        variant="determinate"
                        value={
                          (statusData.hourPlanCountAfterGC /
                            statusData.hourPlanBitCountAfterGC) *
                          100
                        }
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      {statusData.hourPlanCountAfterGC}/
                      {statusData.hourPlanBitCountAfterGC}
                    </Grid>
                    <Grid item xs={4}>
                      <LinearProgress
                        style={{
                          height: "10px",
                          width: "100%",
                          marginTop: "5px",
                        }}
                        variant="determinate"
                        value={
                          (statusData.hourPlanCountBeforeGC /
                            statusData.hourPlanBitCountBeforeGC) *
                          100
                        }
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      {statusData.hourPlanCountBeforeGC}/
                      {statusData.hourPlanBitCountBeforeGC}
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>
              <Grid item xs={12}>
                <StyledPaper
                  variant="outlined"
                  sx={{
                    backgroundColor:
                      statusData.BalancingDemandFixedTime &&
                      statusData.BalancingPartsFixedTime &&
                      statusData.BalancingPowerFixedTime
                        ? "#00000000"
                        : PaletteMode === "dark"
                        ? "#3E2F2F"
                        : "#D32F2F0A",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      fontSize="14px"
                      fontWeight={"bold"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <TaskOutlinedIcon
                        color={
                          statusData.BalancingDemandFixedTime &&
                          statusData.BalancingPartsFixedTime &&
                          statusData.BalancingPowerFixedTime
                            ? "primary"
                            : "error"
                        }
                        fontSize="small"
                        sx={{ mr: "5px" }}
                      />
                      {languageContext.words.simultaneous_commensurate_plan}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="12px"
                      color={"gray"}
                      sx={{ mt: "10px" }}
                    >
                      {
                        languageContext.words
                          .final_confirmation_time_power_gen_and_sales_plan
                      }
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {showStatusOcctoChip(
                        true,
                        statusData.BalancingPowerStatus
                      )}
                      <Grid
                        item
                        fontWeight={"bold"}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {statusData.BalancingPowerFixedTime !== null
                          ? languageContext.convertDateToLocalizedStringGoodAcu(
                              new Date(statusData.BalancingPowerFixedTime)
                            )
                          : ""}
                      </Grid>
                      <StatusButton
                        size="small"
                        onClick={() =>{
                          areaContext.setAreaId(statusData.areaId);
                          handleClickLink(
                            ".." +
                              POWER_FORECAST_PLAN_URL +
                              "?areaId=" +
                              statusData.areaId
                          )
                        }}
                      >
                        {languageContext.words.power_gen_and_sales_plan}
                      </StatusButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="12px"
                      color={"gray"}
                      sx={{ mt: "10px" }}
                    >
                      {
                        languageContext.words
                          .final_confirmation_time_demand_and_procurement_plan
                      }
                    </Grid>
                    <Grid
                      // container
                      item
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {showStatusOcctoChip(
                        true,
                        statusData.BalancingDemandPlanStatus
                      )}
                      <Grid
                        item
                        fontWeight={"bold"}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {statusData.BalancingDemandFixedTime !== null
                          ? languageContext.convertDateToLocalizedStringGoodAcu(
                              new Date(statusData.BalancingDemandFixedTime)
                            )
                          : ""}
                      </Grid>
                      <StatusButton
                        size="small"
                        onClick={() =>{
                          areaContext.setAreaId(statusData.areaId);
                          handleClickLink(
                            ".." +
                              DEMAND_FORECAST_PLAN_URL +
                              "?areaId=" +
                              statusData.areaId
                          )}
                        }
                      >
                        {languageContext.words.demand_and_procurement_plan}
                      </StatusButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      fontSize="12px"
                      color={"gray"}
                      sx={{ mt: "10px" }}
                    >
                      {
                        languageContext.words
                          .final_confirmation_time_partial_supply_plan
                      }
                    </Grid>
                    <Grid
                      // container
                      item
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {showStatusOcctoChip(
                        true,
                        statusData.BalancingPartsPlanStatus
                      )}
                      <Grid
                        item
                        fontWeight={"bold"}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {statusData.BalancingPartsFixedTime !== null
                          ? languageContext.convertDateToLocalizedStringGoodAcu(
                              new Date(statusData.BalancingPartsFixedTime)
                            )
                          : ""}
                      </Grid>
                      <StatusButton
                        size="small"
                        onClick={() =>
                          handleClickLink(
                            ".." +
                              BALANCING_PLAN_URL +
                              "?areaId=" +
                              statusData.areaId
                          )
                        }
                      >
                        {languageContext.words.simultaneous_commensurate_plan}
                      </StatusButton>
                    </Grid>
                  </Grid>
                </StyledPaper>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <div
      className="cn-main-display"
      style={{
        width: "calc(100% - 24px - 24px)",
        backgroundColor: "transparent",
        padding: "24px 0px",
      }}
    >
      <Stack>
        <GlobalSkeletonDatePicker />
      </Stack>
      <Stack sx={{ flexDirection: "row", mt: "30px" }}>
        <Box
          sx={{
            width: "calc((100% / 3) - 24px)",
            mt: "45px",
            mb: "45px",
          }}
        >
          <Box sx={{ width: "100%", margin: "auto" }}>
            {JapanMap(
              area01,
              area02,
              area03,
              area04,
              area05,
              area06,
              area07,
              area08,
              area09,
              area10
            )}
          </Box>
          <Box sx={{ width: "83%", margin: "auto" }}>
            <CircularStatus
              closingSystem={words.spot_bidding_deadline_time}
              closingSystemTime={limitJepx}
              bidAreaNum={areaSpotFixedCount}
              unBidAreaNum={areaCount - areaSpotFixedCount}
              limitTime={spotDiffTime}
              AreaFixStr={words.bidded_area}
              AreaUnFixStr={words.unbid_area}
            />
            <CircularStatus
              closingSystem={words.balancingplan_submitted_on_the_day}
              closingSystemTime={limitOccto}
              bidAreaNum={areaOcctoFixedCount}
              unBidAreaNum={areaCount - areaOcctoFixedCount}
              limitTime={balancingDiffTime}
              AreaFixStr={words.submitted_area}
              AreaUnFixStr={words.not_submitted_area}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "calc(100% - (100% / 3) + 24px)",
            height: "920px",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "0px",
              backgroundColor: "transparent",
              height: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "0px",
            },
          }}
        >
          {1 < dispStatusData.length ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "10px",
                }}
              >
                {dispStatusData.map((statusData: StatusList.statusListInfo) => (
                  <MultiPlanStatus key={statusData.areaId} {...statusData} />
                ))}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "48px 0px",
                width: "100%",
              }}
            >
              {dispStatusData.map((statusData: StatusList.statusListInfo) => (
                <SinglePlanStatus key={statusData.areaId} {...statusData} />
              ))}
            </Box>
          )}
        </Box>
      </Stack>
      <CustomDialog
        title={words.status_list}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={() => {
          //
        }}
        onClose={() => setDigOpen(false)}
      />
    </div>
  );
};

const CircularStatus = (props: circularStatusInfo) => {
  const {
    closingSystem,
    closingSystemTime,
    bidAreaNum,
    unBidAreaNum,
    limitTime,
    AreaFixStr,
    AreaUnFixStr,
  } = props;

  const languageContext = useContext(LanguageContext);
  const [percentage, setPercentage] = useState<number>(0);
  const [circleColor, setCircleColor] = useState<string>("rgb(33, 150, 243)");
  const [circleBgColor, setCircleBgColor] =
    useState<string>("rgb(187, 222, 251)");
  const [limitColor, setLimitColor] = useState<string>("");
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const [fixMsg, setFixMsg] = useState<string>("");

  //propsが変更された際に表示情報を更新する。
  useEffect(() => {
    updatecircularStatusInfo();
  }, [closingSystemTime, bidAreaNum, unBidAreaNum, limitTime]);

  const updatecircularStatusInfo = () => {
    let tempPer = 0;
    let fixMessage = "";
    let isFix = false;
    let tempLimitColor = "";
    let tempColor = "";
    let tempBgColor = "";

    if (0 === unBidAreaNum) {
      tempPer = 100;
      fixMessage = languageContext.words.confirmed;
      tempColor = "#616161";
      tempBgColor = "#aaa";
      tempLimitColor = "#616161";
      isFix = true;
    }
    if (limitTime.hours <= 0 && limitTime.minutes <= 0) {
      //グレー
      tempPer = 100;
      fixMessage = languageContext.words.expiration_of_a_term;
      tempColor = "#616161";
      tempBgColor = "#aaa";
      tempLimitColor = "#616161";
      isFix = true;
    } else {
      if (
        //赤(15分前)
        limitTime.hours < 1 &&
        limitTime.minutes <= 15 &&
        0 <= limitTime.minutes
      ) {
        tempPer = 75;
        tempColor = "#d32f2f";
        tempBgColor = "#ffcdd2";
        tempLimitColor = "#d32f2f";
      } else if (
        //黄色
        limitTime.hours < 1 &&
        limitTime.minutes <= 30 &&
        15 < limitTime.minutes
      ) {
        tempPer = 50;
        tempColor = "#ff9800";
        tempBgColor = "#ffe0b2";
        tempLimitColor = "#ff9800";
      } else if (
        (limitTime.hours <= 1 && limitTime.minutes <= 30) ||
        (limitTime.hours <= 0 && 30 < limitTime.minutes)
      ) {
        //青
        tempPer = 50;
        tempColor = "#1976d2";
        tempBgColor = "#bbdefb";
        tempLimitColor = "#";
      } else {
        //青
        tempPer = 25;
        tempColor = "#1976d2";
        tempBgColor = "#bbdefb";
        tempLimitColor = "";
      }
      fixMessage =
        limitTime.hours.toString().padStart(2, "0") +
        ":" +
        limitTime.minutes.toString().padStart(2, "0");
      isFix = false;
    }
    setPercentage(tempPer);
    setCircleColor(tempColor);
    setCircleBgColor(tempBgColor);
    setLimitColor(tempLimitColor);
    setFixMsg(fixMessage);
    setIsFixed(isFix);
  };

  return (
    <Grid container sx={{ margin: "24px" }}>
      <Grid item xs={7}>
        <Grid container sx={{ padding: "12px 0px 20px 12px" }}>
          <Grid
            item
            xs={12}
            fontSize="12px"
            color={"gray"}
            fontWeight={"bold"}
            sx={{ mt: "10px" }}
          >
            {closingSystem}
          </Grid>
          <Grid item xs={12} fontSize="18px" fontWeight={"bold"}>
            {languageContext.convertDateToLocalizedStringGoodAcu(
              closingSystemTime
            )}
          </Grid>
          <Grid item xs={12} sx={{ fontWeight: "bold" }}>
            <span style={{ color: "gray", fontSize: "12px" }}>
              {AreaFixStr}
            </span>{" "}
            {bidAreaNum}
            {"　"}
            <span style={{ color: "gray", fontSize: "12px" }}>
              {AreaUnFixStr}
            </span>{" "}
            {unBidAreaNum}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={5} style={{ height: "100%" }}>
        <Box
          sx={{
            position: "relative",
            display: "inline-flex",
          }}
        >
          <CircularProgress
            className="frontCircular"
            variant="determinate"
            size={130}
            value={percentage}
            thickness={4}
            sx={{
              zIndex: 2,
              position: "relative",
            }}
            style={{ color: circleColor }}
          />
          <CircularProgress
            className="backCircular"
            variant="determinate"
            size={130}
            value={100}
            thickness={4}
            sx={{
              zIndex: 1,
              position: "absolute",
            }}
            style={{ color: circleBgColor }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "16px",
            }}
          >
            {isFixed ? (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  fontSize="16px"
                  fontWeight={"bold"}
                  sx={{
                    textAlign: "center",
                    color: "rgb(33, 150, 243)",
                  }}
                >
                  {fixMsg}
                </Grid>
              </Grid>
            ) : (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  fontSize="12px"
                  color={"gray"}
                  sx={{ textAlign: "center" }}
                >
                  {languageContext.words.to_be_confirmed}
                </Grid>
                <Grid
                  item
                  xs={12}
                  fontSize="14px"
                  fontWeight={"bold"}
                  color={limitColor}
                  sx={{ textAlign: "center" }}
                >
                  {fixMsg}
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default PlanStatusList;
