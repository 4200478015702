import React, { useContext, useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import { LanguageContext } from "../localization/localization";
import {
  PLAN_STATUS_LIST_URL,
  DEMAND_FORECAST_LIST_URL,
  DEMAND_FORECAST_URL,
  DEMAND_FORECAST_PLAN_URL,
  POWER_FORECAST_LIST_URL,
  POWER_FORECAST_URL,
  POWER_FORECAST_PLAN_URL,
  DEMAND_MONITORING_URL,
  POWER_GENERATION_MONITORING_URL,
  SPOT_BIDDING_URL,
  INTRADAY_BIDDING_URL,
  BALANCING_PLAN_URL,
  SETTING_URL,
  NOTIFICATION_URL,
  CONNECTION_ERROR_URL,
} from "../../../common/url";

const LocationInfomation = () => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const [title, setTitle] = useState<string>("");
  const location = useLocation();
  const key = useRef<string>("");

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setTitle("");
        break;
      case "/status":
        break;
      case PLAN_STATUS_LIST_URL:
        key.current = "status_list";
        break;
      case DEMAND_FORECAST_LIST_URL:
        key.current = "demandforecast_list";
        setTitle(languageContext.words.demandforecast_list);
        break;
      case DEMAND_FORECAST_URL:
        key.current = "demand_plan";
        setTitle(languageContext.words.demand_plan);
        break;
      case DEMAND_FORECAST_PLAN_URL:
        key.current = "demand_and_procurement_plan";
        setTitle(languageContext.words.demand_and_procurement_plan);
        break;
      case POWER_FORECAST_LIST_URL:
        key.current = "powerforecast_list";
        setTitle(languageContext.words.powerforecast_list);
        break;
      case DEMAND_MONITORING_URL:
        key.current = "demand_monitoring_menu";
        setTitle(languageContext.words.demand_monitoring_menu);
        break;
      case POWER_FORECAST_URL:
        key.current = "power_gen_plan";
        setTitle(languageContext.words.power_gen_plan);
        break;
      case POWER_FORECAST_PLAN_URL:
        key.current = "power_gen_and_sales_plan";
        setTitle(languageContext.words.power_gen_and_sales_plan);
        break;
      case POWER_GENERATION_MONITORING_URL:
        key.current = "power_gen_imbalance_monitor";
        setTitle(languageContext.words.power_gen_imbalance_monitor);
        break;
      case SPOT_BIDDING_URL:
        key.current = "spot_bidding";
        setTitle(languageContext.words.spot_bidding);
        break;
      case INTRADAY_BIDDING_URL:
        key.current = "onehouradvancebidplan_title";
        setTitle(languageContext.words.onehouradvancebidplan_title);
        break;
      case BALANCING_PLAN_URL:
        key.current = "simultaneous_commensurate_plan";
        setTitle(languageContext.words.simultaneous_commensurate_plan);
        break;
      case SETTING_URL:
        key.current = "setting";
        setTitle(languageContext.words.setting);
        break;
      case NOTIFICATION_URL:
        key.current = "notification_list";
        setTitle(languageContext.words.notification_list);
        break;
      case CONNECTION_ERROR_URL:
        setTitle(languageContext.words[key.current]);
        break;
    }
  }, [languageContext, location.pathname]);

  return <>{title}</>;
};

export default LocationInfomation;
