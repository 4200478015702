import React, { useContext, useEffect, useState } from "react";
import { Chart as ChartJS } from "chart.js";
import Stack from "@mui/material/Stack";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  Box,
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  Icon,
  Radio,
  RadioGroup,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import LightModeIcon from "@mui/icons-material/LightMode";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useNavigate } from "react-router-dom";
import AccountInfoSetting from "./SetAccountInfo";
import { StyledButton } from "../../common/styledComponents/styledButton";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import NightsStayOutlinedIcon from "@mui/icons-material/NightsStayOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import {
  LanguageContext,
  LanguageLocalStrageKey,
} from "../../common/localization/localization";
import api, { useAuthedApi, useAuthedModalApi } from "../../../common/axios";
import { PaletteContext, palletColorKey } from "../../common/paletteMode";
import axios from "axios";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import { RoleContext } from "../../common/customComponents/RoleProvider";

ChartJS.register(zoomPlugin);
//a要素ボタン
const ButtonLink = styled(Button)`
  &.MuiButton-root {
    height: 100%;
    border-radius: 0px;
    color: black;
    font-size: 14px;
  }
  &.MuiButton-root:hover,
  &.Mui-focusVisible {
    color: white;
    background-color: #1976d2;
  }
` as typeof Button;

//どの言語が選ばれているか表現するためのenum
const languageNumber = {
  jp: 0,
  en: 1,
};

const GET_ACCOUNT_INFO = "_api/getAccountInfo";

// アカウント情報
export interface notificationListType {
  userName: string;
  userId: string;
  mail: string;
}

// アカウント画面
const AccountInfo = (props: any) => {
  const { HaldleClose } = props;
  const [open, setOpen] = useState(false);

  const closeAccountSetting = () => setOpen(false);

  //どのチェックボックスが選択されているかのstate
  const [checkedLanguage, setCheckedLanguage] = useState<string>("jp");

  //言語を切り替えるデータコンテキスト
  const { words, mode, setMode } = useContext(LanguageContext);

  const { initAreaContext } = useContext(AreaContext);

  const { initRoleContext } = useContext(RoleContext);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  const navigate = useNavigate();

  const authedApi = useAuthedApi();

  //翻訳切り替え関数、受け取ったstring型の値に応じて、全ページから参照可能なデータコンテキストを切り替える
  // 初期化
  const initAccount: notificationListType = {
    userName: "",
    userId: "",
    mail: "",
  };

  useEffect(() => {
    getAccountInfo();
    setCheckedLanguage(mode);
  }, []);

  const [accountInfo, setAccountInfo] =
    useState<notificationListType>(initAccount);

  // アカウント情報の取得
  function getAccountInfo() {
    // console.log("アカウント情報の取得")
    axios
      .get(GET_ACCOUNT_INFO)
      .then((res) => {
        if (res.data) {
          // アカウント情報を取得
          setAccountInfo(res.data);
        } else {
          // アカウント情報なし
        }
      })
      .catch((error) => {
        // TODO エラー対応
      });
  }

  //ログイン画面に遷移
  const handleToNextPage = () => {
    navigate("../");
  };

  //ログアウトボタン押下
  const handleLogout = () => {
    authedApi.post("/logout").then((res) => {
      initAreaContext();
      initRoleContext();
      handleToNextPage();
    });
    HaldleClose();
  };

  //翻訳用関数、受け取ったstring型の値に応じて、全ページから参照可能な翻訳スイッチャーのデータコンテキストを切り替える
  const switchLanguage = (language: string) => {
    switch (language) {
      case "jp": {
        setMode("jp");
        setCheckedLanguage("jp");
        localStorage.setItem(LanguageLocalStrageKey, "jp");
        break;
      }
      case "en": {
        setMode("en");
        setCheckedLanguage("en");
        localStorage.setItem(LanguageLocalStrageKey, "en");
        break;
      }
      default: {
        break;
      }
    }
  };

  //データコンテキストを召喚する//矢口
  const palletContextBase = useContext(PaletteContext);

  const switchPallet = (pallet: string) => {
    // console.log("パレット関数呼ばれた、" + pallet);
    switch (pallet) {
      case "light": {
        palletContextBase.setPaletteMode("light");
        // console.log("いまlight");
        localStorage.setItem(palletColorKey, "light");
        break;
      }
      case "dark": {
        palletContextBase.setPaletteMode("dark");
        // console.log("いまdark");
        localStorage.setItem(palletColorKey, "dark");
        break;
      }
      default: {
        //絶対初期値は入るはずだが
        break;
      }
    }
  };

  return (
    <div
      className={`cn-sub-display-account ${
        PaletteMode === "dark" ? "dark" : "light"
      }`}
    >
      <Stack sx={{ width: "100%", height: "100%" }} spacing={1}>
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid
            container
            alignItems="center"
            sx={{
              paddingTop: "10px",
              paddingLeft: "10px",
              height: "40px",
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <Grid item xs={2}>
              <Person2OutlinedIcon />
            </Grid>
            <Grid item xs={10}>
              <Typography
                sx={{ height: "31px" }}
                title={accountInfo.userName}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                whiteSpace={"nowrap"}
              >
                {accountInfo.userName}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={0}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              textAlign: "justify",
              paddingX: "10px",
            }}
          >
            <Typography sx={{ color: "gray", paddingTop: "8px" }}>
              言語/Language
            </Typography>
            <RadioGroup
              defaultValue="jp"
              name="radio-buttons-group"
              row
              sx={{ display: "flex", justifyContent: "center" }}
              onChange={(event) => {
                switchLanguage(event.target.value.toString());
              }}
            >
              <FormControlLabel
                value="jp"
                control={<Radio />}
                label="日本語"
                checked={checkedLanguage === "jp"}
              />
              <FormControlLabel
                value="en"
                control={<Radio />}
                label="English"
                checked={checkedLanguage === "en"}
              />
            </RadioGroup>
          </Grid>
          <Grid
            item
            xs={12}
            md={0}
            sx={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
              textAlign: "justify",
              paddingX: "10px",
              paddingBottom: "10px",
            }}
          >
            <Typography sx={{ color: "gray", paddingTop: "8px" }}>
              {words.theme}
            </Typography>
            <ButtonGroup
              defaultValue="light"
              aria-label="outlined primary button group"
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "40px",
                marginTop: "12px",
              }}
            >
              <StyledButton
                onClick={(event) => {
                  switchPallet("light");
                }}
              >
                <LightModeOutlinedIcon></LightModeOutlinedIcon>
                <Typography component="span">{words.light}</Typography>
              </StyledButton>
              {/* <StyledButton
              >
                <SettingsBrightnessIcon></SettingsBrightnessIcon>
                <Typography sx={{ color: "gray"}} component="span">
                  システム
                </Typography>
              </StyledButton> */}
              <StyledButton
                onClick={(event) => {
                  switchPallet("dark");
                }}
              >
                <NightsStayOutlinedIcon></NightsStayOutlinedIcon>
                <Typography component="span">{words.dark}</Typography>
              </StyledButton>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} md={0} sx={{ textAlign: "justify" }}>
            <ButtonLink
              onClick={() => setOpen(true)}
              sx={{
                width: "100%",
                WebkitTextFillColor:
                  PaletteMode === "dark" ? "#ffffff" : "#000000",
              }}
            >
              {words.account_setting}
            </ButtonLink>
            <AccountInfoSetting
              toOpen={open}
              HandleClose={closeAccountSetting}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "justify", paddingBottom: "10px" }}>
            <Button
              onClick={() => handleLogout()}
              sx={{
                width: "100%",
                WebkitTextFillColor:
                  PaletteMode === "dark" ? "#ffffff" : "#000000",
              }}
            >
              {words.log_out}
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default AccountInfo;
