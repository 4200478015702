import React, { useState, useEffect, useContext, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./views/system/Login/Login";
import "./App.css";
import HeaderContents from "./views/common/globalMenu/Header";
// import Menu from "./components/Menu";
import LocationInfomation from "./views/common/globalMenu/LocationInfomation";
import DemandForecastList from "./views/occto/DemandForecast/DemandForecastList";
import DemandForecast from "./views/occto/DemandForecast/DemandForecast";
import PowerForecastList from "./views/occto/PowerForecast/PowerForecastList";
import PowerForecast from "./views/occto/PowerForecast/PowerForecast";
import DemandMonitoring from "./views/power_transmission/DemandMonitoring";
import PowerGenerationMonitoring from "./views/power_transmission/PowerGenerationMonitoring";
import SpotBidding from "./views/jepx/BiddingScreen/BiddingScreen";
import IntradayBidding from "./views/jepx/OneHourAdvanceBid/OneHourAdvanceBidPlan";
import BalancingPlan from "./views/occto/BalancingPlan/BalancingPlan";

// テスト用
import LoadSample from "./views/LoadSample";

//認証ルーティング
import AuthProvider from "./AuthProvider";
import PrivateRoute from "./PrivateRoute";

import PowerForecastPlan from "./views/occto/PowerForecast/PowerForecastPlan";
import DemandForecastPlan from "./views/occto/DemandForecast/DemandForecastPlan";
import Setting from "./views/master/Setting";
import Notification from "./views/system/Utility/NotificationList";
import PlanStatusList from "./views/system/Common/PlanStatusList";
import Box from "@mui/system/Box";
import MenuInfo from "./views/common/menuInfo/MenuInfo";
import { DateContextProvider } from "./views/common/customComponents/GlobalDatePicker";
import {
  LanguageContext,
  LanguageContextProvider,
} from "./views/common/localization/localization";
import { replaceValueWithKey } from "./views/common/localization/localization";
import TitleInfo from "./views/common/titleInfo/TitleInfo";
import { getTheme } from "./views/common/changeTheme";
import { ModeProvider, PaletteContext } from "./views/common/paletteMode";
import { ThemeProvider } from "@mui/material";
import { AreaContextProvider } from "./views/common/customComponents/CustomAreaContext";
import { TimeContextProvider } from "./views/common/globalMenu/LiveClock";
import {
  LeavePageContext,
  LeavePageContextProvider,
} from "./views/common/customComponents/CustomConfirmLeavePage";
import RoleContextProvider from "./views/common/customComponents/RoleProvider";
import {
  PLAN_STATUS_LIST_URL,
  DEMAND_FORECAST_LIST_URL,
  DEMAND_FORECAST_URL,
  DEMAND_FORECAST_PLAN_URL,
  POWER_FORECAST_LIST_URL,
  POWER_FORECAST_URL,
  POWER_FORECAST_PLAN_URL,
  DEMAND_MONITORING_URL,
  POWER_GENERATION_MONITORING_URL,
  SPOT_BIDDING_URL,
  INTRADAY_BIDDING_URL,
  BALANCING_PLAN_URL,
  SETTING_URL,
  NOTIFICATION_URL,
  CONNECTION_ERROR_URL,
} from "./common/url";
import ConnectionError from "./views/system/Utility/ConnectionError";
import ErrMessageContextProvider from "./views/common/customComponents/ErrorMessageProvider";
import ErrTitleContextProvider from "./views/common/customComponents/ErrorTitleProvider";
import ErrOperationContextProvider from "./views/system/Login/ErrOperationProvider";

const App = () => {
  const useVisibledHeader = () => {
    const location = useLocation();
    if (location.pathname === "/") {
      return null;
    } else {
      return <HeaderContents />;
    }
  };

  const useVisibledInfomation = () => {
    const location = useLocation();
    if (location.pathname === PLAN_STATUS_LIST_URL) {
      if (palletContextBase.PaletteMode === "dark") {
        return { info: "none", color: "rgba(34, 34, 34, 0.9)" };
      } else {
        return { info: "none", color: "rgba(236, 239, 241, 0.9)" };
      }
    } else {
      if (palletContextBase.PaletteMode === "dark") {
        return { info: "flex", color: "rgba(34, 34, 34, 1)" };
      } else {
        return { info: "flex", color: "rgba(236, 239, 241, 1)" };
      }
    }
  };

  const useContentInfomation = () => {
    return <LocationInfomation />;
  };

  //useEffectで各ステイトを監視して、どれかが変わった瞬間に検索関数を動かす
  useEffect(() => {
    replaceValueWithKey();
  }, []);

  //LanguageContextProviderのThemaを決定する
  const palletContextBase = useContext(PaletteContext);
  const [theme, setTheme] = useState(getTheme(palletContextBase.PaletteMode));
  useEffect(() => {
    setTheme(getTheme(palletContextBase.PaletteMode));

    // console.log("正常にテーマが変化した");
  }, [palletContextBase.PaletteMode]);

  const { setBlockLeave } = useContext(LeavePageContext);
  const tempLoc = useRef<string | null>("");

  const useBlockLeave = () => {
    const location = useLocation();
    if (tempLoc.current !== location.pathname) {
      setBlockLeave(false);
    }
    tempLoc.current = location.pathname;
    return null;
  };

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <LanguageContextProvider>
          <TimeContextProvider>
            <LeavePageContextProvider>
              <DateContextProvider>
                <AreaContextProvider>
                  <RoleContextProvider>
                    <ErrTitleContextProvider>
                      <ErrMessageContextProvider>
                        <ErrOperationContextProvider>
                          <div
                            className={`App ${
                              palletContextBase.PaletteMode === "dark"
                                ? "dark"
                                : "light"
                            }`}
                          >
                            <header>{useVisibledHeader()}</header>
                            <main
                              className={`Mode ${
                                palletContextBase.PaletteMode === "dark"
                                  ? "dark"
                                  : "light"
                              }`}
                            >
                              <div
                                className="cn-main-background"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  backgroundImage: `url('${process.env.PUBLIC_URL}/circle.png')`,
                                  backgroundColor: useVisibledInfomation().color,
                                  backgroundBlendMode:
                                    palletContextBase.PaletteMode === "dark"
                                      ? "darken"
                                      : "lighten",
                                  backgroundSize: "40%",
                                  backgroundRepeat: "no-repeat",
                                }}
                              >
                                {/* <Menu /> */}
                                {useBlockLeave()}
                                <div
                                  className="cn-main-content-infomation"
                                  style={{
                                    display: useVisibledInfomation().info,
                                  }}
                                >
                                  {useContentInfomation()}
                                  <Box sx={{ marginLeft: "auto" }}>
                                    <MenuInfo
                                      location={useLocation().pathname}
                                    ></MenuInfo>
                                  </Box>
                                </div>
                                <Routes>
                                  <Route path="/" element={<Login />} />
                                  <Route
                                    path={PLAN_STATUS_LIST_URL}
                                    element={<PrivateRoute><PlanStatusList /></PrivateRoute>}
                                  ></Route>
                                  <Route
                                    path={DEMAND_FORECAST_LIST_URL}
                                    element={<PrivateRoute><DemandForecastList /></PrivateRoute>}
                                  />
                                  <Route
                                    path={DEMAND_FORECAST_URL}
                                    element={<PrivateRoute><DemandForecast /></PrivateRoute>}
                                  />
                                  <Route
                                    path={DEMAND_FORECAST_PLAN_URL}
                                    element={<PrivateRoute><DemandForecastPlan /></PrivateRoute>}
                                  />
                                  <Route
                                    path={POWER_FORECAST_LIST_URL}
                                    element={<PrivateRoute><PowerForecastList /></PrivateRoute>}
                                  />
                                  <Route
                                    path={POWER_FORECAST_URL}
                                    element={<PrivateRoute><PowerForecast /></PrivateRoute>}
                                  />
                                  <Route
                                    path={POWER_FORECAST_PLAN_URL}
                                    element={<PrivateRoute><PowerForecastPlan /></PrivateRoute>}
                                  />
                                  <Route
                                    path={DEMAND_MONITORING_URL}
                                    element={<PrivateRoute><DemandMonitoring /></PrivateRoute>}
                                  />
                                  <Route
                                    path={POWER_GENERATION_MONITORING_URL}
                                    element={<PrivateRoute><PowerGenerationMonitoring /></PrivateRoute>}
                                  />
                                  <Route
                                    path={SPOT_BIDDING_URL}
                                    element={<PrivateRoute><SpotBidding /></PrivateRoute>}
                                  />
                                  <Route
                                    path={INTRADAY_BIDDING_URL}
                                    element={<PrivateRoute><IntradayBidding /></PrivateRoute>}
                                  />
                                  <Route
                                    path={BALANCING_PLAN_URL}
                                    element={<PrivateRoute><BalancingPlan /></PrivateRoute>}
                                  />
                                  <Route
                                    path={SETTING_URL}
                                    element={<PrivateRoute><Setting /></PrivateRoute>}
                                  />
                                  <Route
                                    path={NOTIFICATION_URL}
                                    element={<PrivateRoute><Notification /></PrivateRoute>}
                                  />
                                  <Route
                                    path={CONNECTION_ERROR_URL}
                                    element={<PrivateRoute><ConnectionError /></PrivateRoute>}
                                  />
                                  <Route
                                    path="*"
                                    element={
                                      <main style={{ padding: "1rem" }}>
                                        <p>Invalid URL.</p>
                                      </main>
                                    }
                                  />
                                </Routes>
                              </div>
                            </main>
                          </div>
                        </ErrOperationContextProvider>
                      </ErrMessageContextProvider>
                    </ErrTitleContextProvider>
                  </RoleContextProvider>
                </AreaContextProvider>
              </DateContextProvider>
            </LeavePageContextProvider>
          </TimeContextProvider>
        </LanguageContextProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
