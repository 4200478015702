import React, { useContext, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { StyledButtonMenu } from "../../styledComponents/styledButton";
import { LanguageContext } from "../../localization/localization";
import { AreaContext } from "../../customComponents/CustomAreaContext";
import {
  ConfirmLeavePage,
  LeavePageContext,
} from "../../customComponents/CustomConfirmLeavePage";
import {
  DEMAND_FORECAST_PLAN_URL,
  DEMAND_MONITORING_URL,
} from "../../../../common/url";

// 発電計画画面 画面名右横のコンポーネント
const DemandForecastMenu = () => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const areaContext = useContext(AreaContext);
  const leavePageContext = useContext(LeavePageContext);
  const [digOpen, setDigOpen] = useState(false);
  const url = useRef<string | null>("");
  const navigate = useNavigate();

  const acceptHandler = () => {
    navigate(url.current as string);
  };

  return (
    <>
      <Box>
        <StyledButtonMenu
          sx={{ marginRight: "15px" }}
          onClick={() => {
            url.current = DEMAND_FORECAST_PLAN_URL;
            if (areaContext.areaId !== "") {
              url.current = `${DEMAND_FORECAST_PLAN_URL}?areaId=${areaContext.areaId}`;
            }

            if (leavePageContext.blockLeave) {
              setDigOpen(true);
            } else {
              navigate(url.current);
            }
          }}
        >
          {languageContext.words.demand_procurement_plan}
        </StyledButtonMenu>
        <StyledButtonMenu
          onClick={() => {
            url.current = DEMAND_MONITORING_URL;
            if (areaContext.areaId !== "") {
              url.current = `${DEMAND_MONITORING_URL}?areaId=${areaContext.areaId}`;
            }

            if (leavePageContext.blockLeave) {
              setDigOpen(true);
            } else {
              navigate(url.current);
            }
          }}
        >
          {languageContext.words.demand_monitoring_menu}
        </StyledButtonMenu>
      </Box>
      <ConfirmLeavePage
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      ></ConfirmLeavePage>
    </>
  );
};

export default DemandForecastMenu;
