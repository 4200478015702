import React, { ReactNode, createContext, useState } from "react";

  type ErrOperationContextType = {
    errOperation: boolean;
    setErrOperation: React.Dispatch<React.SetStateAction<boolean>>;
  };

  export const ErrOperationContext = createContext<ErrOperationContextType>(
    {} as ErrOperationContextType
  );
  
  const ErrOperationContextProvider: React.FC<{ children: ReactNode }> = ({
    children,
  }) => {
    const [errOperation, setErrOperation] = useState<boolean>(false);
  
    return (
      <ErrOperationContext.Provider value={{ errOperation, setErrOperation }}>
        {children}
      </ErrOperationContext.Provider>
    );
  };
  
  export default ErrOperationContextProvider;  