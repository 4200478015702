// 需要計画
import {
  StyledTable,
  StyledTableHeaderG,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import { StyledButton } from "../../common/styledComponents/styledButton";
import CustomSelectList, {
  CustomMultipleSelectList,
} from "../../common/customComponents/CustomSelectList";
import {
  GlobalDatePicker,
  DateContext,
} from "../../common/customComponents/GlobalDatePicker";
import CustomDatePickerTryal from "../../common/customComponents/CustomDatePickerTryal";
import {
  cellTime,
  datasetType,
  forcastGraphLabels,
} from "../../../common/cellTime";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useReducer,
  useMemo,
} from "react";
import { ClipboardEvent } from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import { useLocation, useSearchParams } from "react-router-dom";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import SaveDataButton from "../../common/customComponents/SaveDataButton";
import Typography from "@mui/material/Typography";
import zoomPlugin from "chartjs-plugin-zoom";
import { addDays, isBefore, isSameDay, subDays } from "date-fns";
import { format } from "date-fns/fp";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { LanguageContext } from "../../common/localization/localization";
import {
  demandForecastList,
  getpowerValue,
} from "../../../types/occto/DemandForecast";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";
import { RoleContext } from "../../common/customComponents/RoleProvider";
import { PaletteContext } from "../../common/paletteMode";
import { useAreaUtility } from "../../../common/area";
import { AREA_ID } from "../../../common/constant";
import { useAuthedApi } from "../../../common/axios";
import { powerDemandInfo } from "../../common/customComponents/CustomSelectList";
import { searchInfo } from "../../../types/occto/PowerForecastPlan";

// APIパス
// 需要BG、需要家一覧
const GET_DEMAND_FORECAST_INFO_URL = "/getDemandForecastInfoList";
// エリア一覧
const AREA_LIST_URL = "/getDemandAreaNameList";
// 需要BG一覧
const DEMAND_BG_LIST_URL = "/demandBg";
// 需要家一覧
const DEMAND_PLANT_LIST_URL = "/demand";
// 計画取得
const GET_DEMAND_PLAN = "/getDemandForecast";
// 実績取得
const GET_DEMAND_ACTUAL = "/getDemandActual";
// 計画保存
const SET_DEMAND_PLAN = "/setDemandPlan";
//自己託送分複写用発電計画値取得
const SET_SELF_CONSIGNMENT = "/getSelfconsignment";
// 計画保存者・日時取得
const GET_SAVETIME = "/getDemandSavetime";
// ロール判定
const GET_ROLE_JUDGMENT_URL = "/getIsEditableRoleDemandForecast";

interface demandForecast {
  demandName: string;
  targetDate: string;
  dayDemand: number;
  values: number[];
}

const DemandForecast = () => {
  //言語を切り替えるデータコンテキスト
  const languageContext = useContext(LanguageContext);
  const { areaId, setAreaId } = useContext(AreaContext);
  const leavePageContext = useContext(LeavePageContext);

  const api = useAuthedApi();

  // グローバルカレンダー
  const dateContext = useContext(DateContext);
  // タイムコンテキスト
  const timeContext = useContext(TimeContext);

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);

  //計算に使う用に、IDと地域名をセットにして、配列にしておく
  type searchInfo = { id: number; name: string };
  const bgInfos: searchInfo[] = []; // BG一覧
  const demandInfos: searchInfo[] = []; // 需要家一覧

  // 日付フォーマット用(SQLに渡す形状)
  const requestDateFormat = format("yyyy-MM-dd");

  //セレクトリストのState
  const [demandBg, setDemandBg] = useState<number>(0);
  const [demand, setDemand] = useState<number>(0);
  const [allDemandList, setAllDemandList] = useState<powerDemandInfo[]>([]);

  //エリア一覧
  const [areaList, setAreaList] = useState<string[]>([]);
  //セレクトリストに表示する文面のstate
  const [demandbgNamesList, setDemandBgNamesList] = useState([]);
  const [demandNamesList, setDemandNamesList] = useState([]);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);
  // 作業日
  const selectedDate = useContext(DateContext);
  // ログイン中ユーザのロール
  const roleContext = useContext(RoleContext);

  //Load管理
  const [error, setError] = useState<any>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [isLoadingArea, setIsLoadingArea] = useState<boolean>(false);
  const [isLoadingBg, setIsLoadingBg] = useState<boolean>(false);
  const [isLoadingDemand, setIsLoadingDemand] = useState<boolean>(false);
  const [isLoadingPlan, setIsLoadingPlan] = useState<boolean>(false);
  const [isLoadingActual, setIsLoadingActual] = useState<boolean>(false);
  const [isLoadingPast, setIsLoadingPast] = useState<boolean>(false);
  const [isLoadingSelf, setIsLoadingSelf] = useState<boolean>(false);
  const [isLoadingSaveTime, setIsLoadingSaveTime] = useState<boolean>(false);
  //const [isSaving, setIsSaving] = useState<boolean>(false); ToDo保存ボタンの待っている間の制御
  const isLoading =
    isLoadingArea ||
    isLoadingBg ||
    isLoadingDemand ||
    isLoadingPlan ||
    isLoadingActual ||
    isLoadingPast ||
    isLoadingSelf ||
    isLoadingSaveTime;

  const [pastDate, setPastDate] = useState<Date>(addDays(new Date(), 1));
  const [inputDate, setInputDate] = useState<Date>(
    selectedDate.pickDate === null
      ? addDays(new Date(), 1)
      : selectedDate.pickDate
  );
  // 参照データ表示列数
  const [displayNum, setDisplayNum] = useState(4);

  const [selectedValue, setSelectedValue] = useState("a"); // 実績取得か計画取得化のラジオボタンの選択状態
  const [selectedNumber, setSelectedNumber] = useState(-1); // 参照値の選択番号

  const [disableControl, setDisableControl] = useState(false); //保存state
  const [pastAreaData, setPastAreaData] = useState<demandForecastList[]>([]); // 過去参照値(エリア単位)
  const [areaPlanData, setAreaPlanData] = useState<demandForecast[]>([]); // 計画値(エリア単位)
  const [pastData, setPastData] = useState<demandForecast[]>([]); // 過去参照値
  const [planData, setPlanData] = useState<number[]>([]);
  //const planData = useRef<number[]>([]); // 計画値(48コマ)
  const diffplanData = useRef<number[]>([]); //GC済コマ比較用
  const [planDayData, setPlanDayData] = useState<number>(0); // 日量(計画)
  const [planContract, setPlanContract] = useState<number>(0); // 契約電力(計画)
  const [actualData, setActualData] = useState<number[]>([]); // 実績データ
  const [clickData, setClickData] = useState<number[]>([]); // 過去参照値(選択中,48コマ)
  const [clickDate, setClickDate] = useState<Date>(addDays(new Date(), 1)); // 過去参照日(選択中)
  const [lastUpdateInfo, setLastUpdateInfo] = useState<{
    date: Date;
    user: string;
  }>();
  const [firstChartset, setFirstChartSet] = useState<boolean>(false);
  // 保存ボタンの表示・非表示
  const [hidden, setHidden] = useState<boolean>(true);

  const firstlender_flag = useRef<boolean>(true);
  const firstlenderPastActual_flag = useRef<boolean>(true);

  // グラフデータ
  const graphData = useMemo(() => {
    const datasets: datasetType[] = [];
    const labels: string[] = forcastGraphLabels;
    if (demand) {
      //過去参照値追加時
      if (selectedNumber > -1) {
        datasets.push({
          type: "line" as const,
          label: languageContext.convertDateToLocalizedString(clickDate),
          borderColor: PaletteMode === "dark" ? "red" : "red",
          backgroundColor: "rgba(75, 192, 192, 0)",
          borderWidth: 2,
          stepped: "middle" as const,
          data: clickData,
          yAxisID: "y1", // optionsで設定したIDを割り振ってY軸を設定する
          categoryPercentage: 1.19, //全てのグラフ一本一本の幅
          order: 3,
        });
      }

      //需要家単位
      datasets.push({
        type: "line" as const,
        label: languageContext.words.planned_demand_value, //このタイミングでプッシュして画面表示に使っているので、途中で英語化しても時すでに遅しとなり翻訳されない
        borderColor: PaletteMode === "dark" ? "aqua" : "blue",
        backgroundColor: "rgba(75, 192, 192, 0)",
        borderWidth: 4,
        stepped: "middle" as const,
        data: planData,
        yAxisID: "y1", // optionsで設定したIDを割り振ってY軸を設定する
        categoryPercentage: 1.19, //全てのグラフ一本一本の幅
        order: 1,
      });
    } else {
      //エリア単位
      const r = 75;
      const g = 192;
      const b = 192;

      areaPlanData.forEach((demand, index) => {
        if (!demand.demandName || !demand.values.length) return;
        datasets.push({
          type: "bar" as const, // 棒グラフ
          label: demand.demandName,
          backgroundColor: `rgb(${r * (1 + index)}, ${g * (1 + index)}, ${
            b - index
          })`,
          data: demand.values,
          yAxisID: "y", // Y軸の設定
          borderColor: "",
          borderWidth: 0,
          stepped: false,
          categoryPercentage: 1.0,
          order: 2,
        });
      });
    }

    return { labels: labels, datasets: datasets };
  }, [
    demand,
    languageContext.words,
    PaletteMode,
    planData,
    selectedNumber,
    clickDate,
    clickData,
    areaPlanData,
  ]);

  const [chartMax, setChartMax] = useState<number>(0);
  const [chartStep, setChartStep] = useState<number>(0);

  // 初期検索条件の取得
  const url = useLocation().search;
  const query = new URLSearchParams(url);

  //需要家情報一括取得
  const getDemandList = () => {
    api
      .get(GET_DEMAND_FORECAST_INFO_URL)
      .then((res) => {
       const demandList : powerDemandInfo[] = res.data.map((v: any) => {
          return {
            areaId: v.areaId,
            bgId: v.bgId,
            bgName: v.bgName,
            powerDemandId: v.demandId,
            powerDemandName: v.demandName,
          }; 
        });
        setAllDemandList(demandList);
        
        //初期表示をセット
        let startArea: string = AREA_ID.NONE;
        let startBg = 0;
        let startdemand = 0;

        const paramAreaId = query.get("areaId");
        const paramBgId = query.get("bgId");
        const paramDemandId = query.get("demandId");
        startArea = paramAreaId ? paramAreaId : AREA_ID.NONE;

        //ステータス一覧、需調、インバランスから遷移したとき
        if (paramBgId == null && paramDemandId == null) {
          setAreaId(startArea);
          const tmpArea = demandList.filter((v) => v.areaId === startArea);
          const tmpBg = [...new Set(tmpArea.map((v) => v.bgId))];
          const tmpDemand = [...new Set(tmpArea.map((v) => v.powerDemandId))];
          //選択したエリアに所属するBGが1つの場合、そのBGを選択されているBGに設定
          if(tmpBg.length === 1){
            startBg = tmpBg[0];
            setDemandBg(startBg);
          } else {
            setDemandBg(0);
          }

          //選択したエリアに所属する需要家が1つの場合、その発電所を選択されている需要家に設定
          if(tmpDemand.length === 1){
            startdemand = tmpDemand[0];
            setDemand(startdemand);
          } else {
            setDemand(0);
          }
        } else  {
          //需要計画一覧から遷移したとき
          setAreaId(startArea);
          //getDemandBg(startArea, false);

          // 需要BGが特定されている場合
          if (
            paramBgId != null &&
            !Number.isNaN(paramBgId) &&
            parseInt(paramBgId) != 0
          ) {
            setDemandBg(parseInt(paramBgId));
            //getDemand(startArea, parseInt(paramBgId), false);
            startBg = Number(paramBgId);
            setDemandBg(startBg);

            // 需要家が特定されている場合
            if (
              paramDemandId != null &&
              !Number.isNaN(paramDemandId) &&
              parseInt(paramDemandId) != 0
            ) {
              setDemand(parseInt(paramDemandId));
              startdemand = Number(paramDemandId);
              setDemand(startdemand);
            }
          } 
        }
        ProcessGetDemandPlan(areaList, startBg, startdemand);
      })

      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  // 日付のみが変更時
  useEffect(() => {
    
    ChangeInputDate();

    // 当日の場合は乖離情報を出すための実績を取得
    //if (
    //  selectedDate.pickDate != undefined &&
    //  isSameDay(selectedDate.pickDate, new Date()) &&
    //  firstlender_flag.current == false
    //) {
    //  getDemandActual(area, demandBg, demand);
    //}
    firstlender_flag.current = false;
  }, [selectedDate.pickDate]);

  /*
  // 検索条件変更
  useEffect(() => {
    // 需要計画取得
    getDemandPlan();
    // 過去参照情報取得
    if (selectedValue == 'a') {
      // 実績取得
      getDemandPastActual();
    } else {
      // 過去計画取得
      getDemandPastPlan();
    }

    if(demand == 0){      
    CreateAreaDatasets(inputDate, pastDate, area, pastData);
    } else{
    CreateDemandDatasets(clickData, clickDate, planData); // グラフの再描画
    }

    ChangeInputDate();
  
    // 当日の場合は乖離情報を出すための実績を取得
    if (selectedDate.pickDate != undefined && isSameDay(selectedDate.pickDate, new Date())) {
      getDemandActual();
    }

  }, [area, demandBg ,demand, selectedDate]);

*/

  // 過去参照データ変更時
  useEffect(() => {
    // 過去参照情報取得
    if (selectedValue == "a") {
      if (!firstlenderPastActual_flag.current) {
        getDemandPastActual(areaId, demandBg, demand);
      }
    } else {
      getDemandPastPlan(areaId, demandBg, demand);
    }

    firstlenderPastActual_flag.current = false;

    // 需要計画取得
    //if(firstChartset === true){
    //  getDemandPlan(area, demandBg, demand, clickData, clickDate);
    //}

    //ここでグラフ描画
    /*if (demand == 0) {
      CreateAreaDatasets(inputDate, pastDate, areaId, areaPlanData);
    } else {
      CreateDemandDatasets(clickData, clickDate, planData.current); // グラフの再描画
    }*/
  }, [pastDate, selectedValue]);

  // 入力値の更新
  useEffect(() => {
    // 日量の計算
    calcdaypower();
    /*if (demand == 0) {
      CreateAreaDatasets(inputDate, pastDate, areaId, areaPlanData);
    } else {
      CreateDemandDatasets(clickData, clickDate, planData.current); // グラフの再描画
    }*/
  }, [planData]);

  // 日量の計算
  const calcdaypower = () => {
    setPlanDayData(planData.reduce((sum, element) => sum + element, 0));
  };

  // 過去参照日変更時
  const ChangePastDate = (pastDate: Date) => {
    setPastDate(pastDate);

    // 取得した時間で固定されてしまうので年月日のみの文字列で比較
    const dateStr = subDays(pastDate, selectedNumber).toLocaleDateString();

    // そのエリアのその需要家のその日のデータをclickDataに格納
    const clickData: number[] = [];
    let clickDate = "";
    pastData.map((target) => {
      if (target.targetDate.replaceAll("-", "/") === dateStr) {
        target.values.forEach((value) => clickData.push(Math.floor(value)));
        clickDate = target.targetDate;
      }
    });

    setClickData(clickData);
    setClickDate(subDays(pastDate, selectedNumber));
  };

  // 初期検索
  useEffect(() => {
    //需要家情報一括取得
    getDemandList();
    setFirstChartSet(true);

    dicideSaveButtonVisibility();

    leavePageContext.setBlockLeave(false);
  }, []);

  // 保存してないコマがGCした場合、元の値に戻す
  useEffect(() => {
    if (!planData.length || !diffplanData.current.length) return;
    const temp = planData.map((v, i) =>
      IsOneHourLate(i) ? diffplanData.current[i] : v
    );
    setPlanData(temp);
  }, [timeContext.time]);

  //需要計画画面に表示するデータを取得
  async function ProcessGetDemandPlan(
    areaIds: string[], //startArea: string,
    startbg: number,
    startdemand: number
  ) {

    let startArea: string = areaId;
    if (areaIds.length === 1) {
      startArea = areaIds[0];
    } else if (areaIds.length > 1) {
      startArea = AREA_ID.NONE;
    } 
    // 需要計画取得 URLから拾わない。
    await getDemandPlan(
      startArea,
      startbg,
      startdemand,
      clickData,
      clickDate
    );

    setLastUpdateInfo(undefined); //保存日時初期化
    if (
      startArea != AREA_ID.NONE &&
      startbg != 0 &&
      startdemand != 0
    ) {
      await getSaveDate(startbg, startdemand);
    }

    // 過去参照情報取得
    if (selectedValue == "a") {
      // 実績取得
      await getDemandPastActual(startArea, startbg, startdemand);
    } else {
      // 過去計画取得
      await getDemandPastPlan(startArea, startbg, startdemand);
    }

    const cmpDateFormat = format("yyyy/MM/dd");
    if (startdemand !== 0 && !isBefore(
      new Date(cmpDateFormat(dateContext.pickDate as Date)),
      new Date(cmpDateFormat(timeContext.time as Date))
    )) {
      setDisableControl(false);
    } else {
      setDisableControl(true);
    }

    // 当日の場合は乖離情報を出すための実績を取得
    if (
      selectedDate.pickDate != undefined &&
      isSameDay(selectedDate.pickDate, new Date())
    ) {
      await getDemandActual(startArea, startbg, startdemand);
    }
  }

  // ログインユーザのロールによって保存ボタンの表示・非表示を切り替える
  const dicideSaveButtonVisibility = () => {
    api
      .get(GET_ROLE_JUDGMENT_URL, { params: { userRole: roleContext.role } })
      .then((res) => {
        setHidden(res.data ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 需要計画
  async function getDemandPlan(
    area_Id: string,
    demand_Bg: number,
    demand_: number,
    clickdata_: number[],
    clickdate_: Date
  ) {
    setIsLoadingPlan(true);
    api
      .post(GET_DEMAND_PLAN, {
        areaId: area_Id,
        demandBg: demand_Bg,
        demand: demand_,
        date: requestDateFormat(selectedDate.pickDate as Date),
        count: 1, // 該当日1件のみ取得
      })
      .then(async (res) => {
        setIsLoadingPlan(false);
        const initialVal: number[] = [];
        const initialVal2: number[] = [];
        // データがない場合を考慮して初期化
        for (let i = 0; i < 48; i++) {
          initialVal[i] = 0;
        }

        for (let i = 0; i < 48; i++) {
          initialVal2[i] = 0;
        }

        const ret: demandForecast[] = [];
        res.data.forEach((element: demandForecastList) => {
          const demandData: demandForecast = {
            demandName: element.demandName,
            targetDate: element.targetDate,
            dayDemand: element.dayPower,
            values: [],
          };
          for (let i = 0; i < element.values.length; i++) {
            demandData.values.push(element.values[i]);
            initialVal[i] = initialVal[i] + element.values[i];
            initialVal2[i] = initialVal2[i] + element.values[i];
          }

          // データがない場合
          if (demandData.values.length === 0) {
            for (let i = 0; i < 48; i++) {
              demandData.values.push(-1);
            }
          }
          ret.push(demandData);
        });

        // 表示データに設定
        setPlanData(initialVal);
        //planData.current = initialVal;
        //setDiffPlanData(initialVal); // GC比較
        diffplanData.current = initialVal2;

        //グラフの作成---
        if (demand_ == 0) {
          //エリア単位の表示

          //エリア合算
          const areatotal: number[] = [];

          for (let reset = 0; reset < 48; reset++) {
            areatotal[reset] = 0;
          }

          for (let i = 0; i < ret.length; i++) {
            for (let j = 0; j < 48; j++) {
              areatotal[j] = Number(areatotal[j]) + Number(ret[i].values[j]);
            }
          }
          //グラフ最大高をセット(エリア)
          let maxHeight = Math.round(Math.max.apply(null, areatotal) * 1.1);
          if (maxHeight == -1) {
            maxHeight = 0;
          }
          setChartMax(maxHeight);
          setChartStep(Math.round(maxHeight / 10));
        } else {
          //グラフ最大高をセット(需要家)
          setChartMax(Math.round(res.data[0].power * 1.1));
          setChartStep(Math.round((res.data[0].power * 1.1) / 10));
        }
        //---

        if (
          demand_ != 0 &&
          res.data.length != 0 &&
          res.data[0].dayPower != undefined
        ) {
          setPlanDayData(res.data[0].dayPower); // 日量
        }

        if (
          demand_ != 0 &&
          res.data.length != 0 &&
          res.data[0].power != undefined
        ) {
          setPlanContract(res.data[0].power); // 契約電力
        }

        setAreaPlanData(ret);

        setIsLoaded(true); // 読込終了
      })
      .catch((error) => {
        setError(error);
      });
  }

  // 過去計画
  function getDemandPastPlan(
    _area: string,
    _demandBg: number,
    _demand: number
  ) {
    setIsLoadingPast(true);
    api
      .post(GET_DEMAND_PLAN, {
        areaId: _area,
        demandBg: _demandBg,
        demand: _demand,
        date: requestDateFormat(pastDate as Date),
        count: displayNum,
      })
      .then((res) => {
        setIsLoadingPast(false);
        const ret: demandForecast[] = [];
        //console.log("過去計画取得したデータ", res.data);

        // 表示データ件数分
        res.data.forEach((element: demandForecastList) => {
          const demandData: demandForecast = {
            demandName: element.demandName,
            targetDate: element.targetDate,
            dayDemand: element.dayPower,
            values: [],
          };
          for (let i = 0; i < 48; i++) {
            if (element.values[i] != undefined) {
              demandData.values.push(element.values[i]);
            } else {
              demandData.values.push(0);
            }
          }

          // データがない場合
          if (demandData.values.length === 0) {
            for (let i = 0; i < 48; i++) {
              demandData.values.push(-1);
            }
          }
          ret.push(demandData);
        });

        setPastData(ret); // 過去データ

        // グラフデータに適用
        if (selectedNumber != -1) {
          const dateStr = clickDate.toLocaleDateString();

          //ここで
          const _clickData: number[] = [];
          let _clickDate = "";
          ret.map((target) => {
            if (target.targetDate.replaceAll("-", "/") === dateStr) {
              target.values.forEach((value) =>
                _clickData.push(Math.floor(value))
              );
              _clickDate = target.targetDate;
            }
          });

          //選択データ格納
          setClickData(_clickData);
        }

        setIsLoaded(true); // 読込終了
      })
      .catch((error) => {
        setError(error);
      });
  }

  // 実績データの取得
  function getDemandActual(areaId: string, demandBg: number, demand: number) {
    setIsLoadingActual(true);
    api
      .post(GET_DEMAND_ACTUAL, {
        areaId: areaId,
        demandBg: demandBg,
        demand: demand,
        date: requestDateFormat(selectedDate.pickDate as Date),
        count: 1, // 該当日1件のみ取得
      })
      .then((res) => {
        setIsLoadingActual(false);
        const initialVal: number[] = [];
        for (let i = 0; i < 48; i++) {
          if (res.data[0].values[i] != undefined) {
            initialVal.push(parseInt(res.data[0].values[i]));
          } else {
            initialVal.push(0);
          }
        }

        // データがない場合
        if (initialVal.length === 0) {
          for (let i = 0; i < 48; i++) {
            initialVal.push(-1);
          }
        }

        //console.log("実績データ", res.data);

        // 表示データに設定
        setActualData(initialVal);
        // グラフデータに適用
        setIsLoaded(true); // 読込終了
      })
      .catch((error) => {
        setError(error);
      });
  }

  // 過去実績
  function getDemandPastActual(
    _area: string,
    _demandBg: number,
    _demand: number
  ) {
    setIsLoadingPast(true);
    api
      .post(GET_DEMAND_ACTUAL, {
        areaId: _area,
        demandBg: _demandBg,
        demand: _demand,
        date: requestDateFormat(pastDate as Date),
        count: displayNum, // 該当日から7件取得
      })
      .then((res) => {
        setIsLoadingPast(false);
        //console.log("実績値の取得", res.data);
        const ret: demandForecast[] = [];
        res.data.forEach((element: demandForecastList) => {
          const demandData: demandForecast = {
            demandName: "",
            targetDate: element.targetDate,
            dayDemand: element.dayPower,
            values: [],
          };
          for (let i = 0; i < 48; i++) {
            if (element.values[i] != undefined) {
              demandData.values.push(element.values[i]);
            } else {
              demandData.values.push(0);
            }
          }

          // データがない場合
          if (demandData.values.length === 0) {
            for (let i = 0; i < 48; i++) {
              demandData.values.push(-1);
            }
          }
          ret.push(demandData);
        });

        setPastData(ret); // 過去データ
        // グラフデータに適用
        if (selectedNumber != -1) {
          const dateStr = clickDate.toLocaleDateString();

          //ここで
          const _clickData: number[] = [];
          let _clickDate = "";
          ret.map((target) => {
            if (target.targetDate.replaceAll("-", "/") === dateStr) {
              target.values.forEach((value) =>
                _clickData.push(Math.floor(value))
              );
              _clickDate = target.targetDate;
            }
          });

          //選択データ格納
          setClickData(_clickData);
          setIsLoaded(true); // 読込終了
        }
      })
      .catch((error) => {
        setError(error);
      });
  }

  // 保存ボタン押下
  const OnAccept = () => {
    // 表示データ取得
    setDemandPlan();
  };

  // 計画保存
  function setDemandPlan() {
    //計画値48コマ中1コマでも契約電力を上回る入力値がある場合エラー
    let currentDayPowerOver = Number(0);
    for (let i = 0; i < planData.length; i++) {
      if (Number(planData[i]) * 2 > Number(planContract)) {
        currentDayPowerOver = Number(1);
      }
    }

    if (currentDayPowerOver != 0) {
      setDialogMessage(languageContext.words.dp_check_power);
      setDigOpen(true);
    } else {
      // 保存処理
      api
        .post(SET_DEMAND_PLAN, {
          areaId: areaId,
          demandBg: demandBg,
          demand: demand,
          date: requestDateFormat(selectedDate.pickDate as Date),
          values: planData,
          dayPower: planDayData,
        })
        .then((res) => {
          // 保存後の処理
          leavePageContext.setBlockLeave(false);
          setDialogMessage(languageContext.words.saved);
          setDigOpen(true);
          setLastUpdateInfo({
            date: new Date(res.data.date),
            user: res.data.user,
          });
        })
        .catch((error: Error) => {
          if (error.message == "Request failed with status code 400") {
            setDialogMessage(languageContext.words.saved_dupicated);
            setDigOpen(true);
          } else {
            setDialogMessage(languageContext.words.saved_failed);
            setDigOpen(true);
          }
        });
    }
  }

  // 入力日付変更時(Global)
  const ChangeInputDate = async () => {
    if (!(selectedDate.pickDate === null)) {
      setInputDate(selectedDate.pickDate);

      const toDate = new Date();
      if (
        selectedDate.pickDate.toLocaleDateString() ===
        toDate.toLocaleDateString()
      ) {
        // 参照データの表示列数調整(当日表示)
        setDisplayNum(4);
      } else {
        // 参照データの表示列数調整(当日以外表示)
        setDisplayNum(7);
      }

      // 需要計画取得
      if (firstChartset === true) {
        const tmpAreaList = areaId === "00" ? areaList.filter((e) => e !== "00") : areaList.filter((e) => e === areaId);
        await ProcessGetDemandPlan(tmpAreaList, demandBg, demand);
      }
      setFirstChartSet(true);
    }
  };

  // 現在時刻を見て引数で受けた値と比較し、hourCountが現在時刻から１時間後までなら、trueを、そうでないならfalseを戻す
  const IsOneHourLate = (index: number) => {
    // TODO グローバルタイマーから取得
    const nowTime = timeContext.time; //  現在日時を得る
    let hourCount = nowTime.getHours() * 2; // 時間を取得を24→48最大に変更
    const minutes = nowTime.getMinutes();

    if (demand === 0 || demandBg === 0) {
      // 前提としてエリア単位表示中(需要家が特定されていない場合は入力不可)
      return true;
    }

    // 現在時刻と検索日が一致したときのみ時刻判定
    if (
      selectedDate.pickDate != undefined &&
      isSameDay(nowTime, selectedDate.pickDate)
    ) {
      if (minutes >= 30) {
        hourCount += 1;
      }
      // 現在の時刻から1時間半先の時刻を判定
      if (hourCount + 2 < index) {
        return false;
      } else {
        return true;
      }
    } else if (
      selectedDate.pickDate != undefined &&
      isBefore(nowTime, selectedDate.pickDate)
    ) {
      return false;
    } else {
      return true;
    }
  };

  // グラフオプション
  // 凡例等グラフの設定をする
  // 「as const」が肝なので付けるプロパティを間違えないこと
  const options = useMemo(() => {
    // グラフデフォルト値
    // ダークモード対応でボーダー線の色等を変える
    ChartJS.defaults.color = PaletteMode === "dark" ? "#999" : "#666";
    ChartJS.defaults.borderColor =
      PaletteMode === "dark"
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)";
    return {
      //折れ線グラフの点を削除
      elements: {
        point: {
          radius: 0,
        },
      },
      plugins: {
        title: {
          position: "left" as const,
          display: true,
          text: languageContext.words.demand_kwh,
          font: { size: 14 },
        },
        legend: {
          // 凡例の設定
          position: "top" as const,
          reverse: true,
          labels: {
            font: {
              size: 14,
            },
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "xy" as const,
            modifierKey: "meta" as const,
            scaleMode: undefined,
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            drag: {
              enabled: true,
              backgroundColor: "rgba(225,0,0,0.3)",
            },
            mode: "x" as const,
          },
          mode: "x",
          limits: {
            x: {
              min: 0,
              max: 47,
            },
            y: {
              min: 0,
              max: 2.0,
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          stacked: true,
          //labelの表示が斜めにならないように
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            font: { size: 14 },
          },
          grid: {
            display: false,
          },
        },
        y: {
          // y軸複数指定
          stacked: true,
          max: chartMax,
          min: 0,
          ticks: {
            stepSize: chartStep,
            font: { size: 14 },
          },
        },
        y1: {
          // y軸複数指定
          stacked: false,
          position: "right" as const,
          max: chartMax,
          min: 0,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },
    };
  }, [languageContext.words, chartMax, chartStep, PaletteMode]);

  // グラフに表示するデータを抽出する
  const CreateAreaChartData = (
    inputDate: Date | null,
    areaData: demandForecast[]
  ) => {
    const demandDatas: number[][] = [];

    // 取得した時間で固定されてしまうので年月日のみの文字列で比較
    const dateStr = inputDate?.toLocaleDateString();

    // そのエリアのその日のデータを格納
    let index = 0;
    areaData.forEach((data) => {
      if (data.targetDate.replaceAll("-", "/") === dateStr) {
        demandDatas.push([]);
        data.values.forEach((value) =>
          demandDatas[index].push(Math.floor(value))
        );
        index++;
      }
    });
    return demandDatas;
  };

  // Todo 前日までのみに反応するように
  const CreateAreaPrevData = (
    pastDate: Date,
    areaId: number,
    pastData: demandForecast[]
  ) => {
    // 取得した時間で固定されてしまうので年月日のみの文字列で比較
    const dateStr = pastDate.toLocaleDateString();

    const prev: number[] = [];
    const areaTotal: number[] = new Array(48).fill(0);

    // そのエリアのその日のデータを合算して格納
    pastData.forEach((demand) => {
      if (demand.targetDate.replaceAll("-", "/") === dateStr) {
        demand.values.forEach((value) => prev.push(Math.floor(value)));
        for (let i = 0; i < 48; i++) {
          areaTotal[i] += prev[i];
        }
        prev.splice(0);
      }
    });

    return areaTotal;
  };

  // tableCellの背景色決定
  const CellColor = (enable: boolean) => {
    const cellColor = enable
      ? PaletteMode === "dark"
        ? "rgb(90,90,90)"
        : "rgb(200,200,200)"
      : "";
    return cellColor;
  };

  // tableの行ごとに数字を二つ戻す
  const CreateRowNumbers = (i: number) => {
    let minutesNumber;
    let hoursNumber;
    if (i % 2 === 0) {
      minutesNumber = 0;
      hoursNumber = i / 2;
    } else {
      minutesNumber = 30;
      hoursNumber = i - i / 2;
    }
    const rowNumbers = [hoursNumber, minutesNumber];

    return rowNumbers;
  };

  //テキストフィールドに想定しない値がぺーストされてしまわないように制限
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData.getData("text/plain");
    if (!pastedText) {
      return;
    }
    // text を正規表現で分割して配列にする
    const textArray = pastedText.split(/ |\u3000/);
    textArray.forEach((item) => {
      // 数字以外がマッチしたらペーストできない
      if (!item.match(/^[0-9]*$/)) {
        e.preventDefault();
      }
    });
  };

  //数値をカンマ区切りに変換
  const convertToLocaleString = (value: number) => {
    return Number(value).toLocaleString();
  };

  // テーブルの列データ
  const records = () => {
    const datas = [];
    for (let i = 0; i < 48; i++) {
      datas.push(
        <TableRow sx={{ height: "29.49px" }}>
          <StyledTableCell
            sx={{
              padding: 0,
              // paddingRight: "1px",
              textAlign: "center",
              width: "95px",
            }}
            style={{
              height: "30px",
              backgroundColor: CellColor(IsOneHourLate(i)),
            }}
          >
            {`${cellTime[i]}～${cellTime[i + 1]}`}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              padding: 0,
              textAlign: "center",
              minWidth: "74px",
              height: "30px",
            }}
            style={{
              borderRight: "none",
              backgroundColor: CellColor(IsOneHourLate(i)),
            }}
          >
            <TextField
              sx={{ margin: "2px" }}
              InputProps={{
                // inputMode: "numeric",
                // pattern: "^[1-9][0-9]*$",
                inputProps: {
                  min: 0,
                  style: {
                    padding: 0,
                    height: "30px",
                    textAlign: "right",
                    backgroundColor: CellColor(IsOneHourLate(i)),
                  },
                  step: "1",
                },
              }}
              type="number"
              disabled={IsOneHourLate(i)}
              onKeyDown={(event) => {
                if (event.key === "." || event.key === "-") {
                  event.preventDefault();
                }
              }}
              value={
                planData[i] <= -1
                  ? ""
                  : planData[i] == undefined
                  ? ""
                  : planData[i].toFixed(0)
              }
              onPaste={handlePaste} //負の値などのペースト制限
              onFocus={(e) => {
                if (Number(planData[i]) === 0) {
                  e.currentTarget.select();
                }
              }}
              onChange={(event) => {
                leavePageContext.setBlockLeave(true);
                //入力制限6桁(isMaxLength)
                if (isMaxLength(event.target.value)) {
                  const temp = [...planData];
                  temp[i] = Number(Number(event.target.value).toFixed(0));
                  setPlanData(temp);
                }
              }}
            />
          </StyledTableCell>
        </TableRow>
      );
    }
    return datas;
  };

  // テーブルのヘッダーのデータ(日付)
  const TableHeaderDate = () => {
    const tableHeaderData: Date[] = [];
    for (let i = 0; i < 7; i++) {
      tableHeaderData.push(subDays(pastDate, i));
    }
    return tableHeaderData;
  };

  const CurrentDayDemand = () => {
    let dayvalue = 0;
    for (let i = 0; i < 48; i++) {
      dayvalue += Number(Number(planData[i]).toFixed(0));
    }

    // 表示している需要家の日量
    return dayvalue;
  };

  const CreateDayDemand = (i: number) => {
    // 取得した時間で固定されてしまうので年月日のみの文字列で比較
    const dateStr = subDays(pastDate, i).toLocaleDateString();

    let dayDemandValues: any = 0;
    // そのエリアのその需要家のその日のデータをcopyDataに格納
    pastData.map((target) => {
      if (target.targetDate.replaceAll("-", "/") === dateStr) {
        dayDemandValues += Number(target.dayDemand);
      }
    });

    if (dayDemandValues === 0) {
      // データがない場合
      dayDemandValues = "-";
    }

    return dayDemandValues.toLocaleString();
  };

  /*  const CreateDemand = (i: number, target: demandForecast[]) => {
    // 取得した時間で固定されてしまうので年月日のみの文字列で比較
    const dateStr = subDays(pastDate, i).toLocaleDateString();

    console.log("右表データ", target);

    //let dayDemandValues: any = 0;
    // そのエリアのその需要家のその日のデータをcopyDataに格納

    //かえしたいのはその日付の48コマ合算値
    
    target.map((item, index) => {
      if (
        item.targetDate === dateStr
      ) { 
        if(demand == 0 && demandBg == 0 && index < displayNum){
          const vals : number[] = new Array(48).fill(0);
          for(let i = 0; i< target.length / displayNum; i++){
            target[index + displayNum* i].values.forEach((val, pos) => {
              vals[pos] +=  val;
            })
          }
          dayDemandValues = vals;
        } else {
          dayDemandValues = item.values;
        }
        
      }
    });

    //if (dayDemandValues === 0) {
    //  // データがない場合
    //  dayDemandValues = "-";
    //}

    
  };*/

  //自己託送分の複写
  const Set_self_consignment = () => {
    //発電計画値の取得
    setIsLoadingSelf(true);
    api
      .post(SET_SELF_CONSIGNMENT, {
        demand: demand,
        date: requestDateFormat(selectedDate.pickDate as Date),
      })
      .then((res) => {
        setIsLoadingSelf(false);
        const setself_consignment: number[] = [];
        const getself_consignment: getpowerValue[] =
          res.data as getpowerValue[];

        for (let i = 0; i < getself_consignment.length; i++) {
          setself_consignment[i] = Number(
            getself_consignment[i].supply_power_qu
          );
        }

        // テキストフィールドが非活性のコマのデータは上書きしないようにデータ作成
        // ここで小数点のデータが入っている
        const data: number[] = [];
        planData.forEach((planData, index) => {
          if (IsOneHourLate(index)) {
            data.push(planData);
          } else {
            data.push(setself_consignment[index]);
          }
        });

        //計画値にセット
        //todo GCを考慮
        //todo 計画がないとき、ダイアログだす
        if (setself_consignment.length == 0) {
          setDialogMessage(languageContext.words.dp_copy_error);
          setDigOpen(true);
        } else {
          leavePageContext.setBlockLeave(true);
          setPlanData(data);
        }
      });
  };

  //保存日時の取得
  async function getSaveDate(_demandbg: number, _demand: number) {
    //取得
    setIsLoadingSaveTime(true);
    api
      .post(GET_SAVETIME, {
        Bg: _demandbg,
        demand: _demand,
        targetDate: requestDateFormat(selectedDate.pickDate as Date),
      })
      .then((res) => {
        setIsLoadingSaveTime(false);
        //useRefで保持
        setLastUpdateInfo({
          date: new Date(res.data[0].date),
          user: res.data[0].user,
        });
      })
      .finally(() => {
        //ToDo thenに移行
        //保存してない場合エラーが帰るため、finalyでフラグを折る
        setIsLoadingSaveTime(false);
      });
  }

  // レンダリング
  // 需要計画(入力)テーブル
  const demandTable = () => {
    return (
      <StyledTable
        stickyHeader
        aria-label="sticky table"
        sx={{
          maxWidth: "170px",
          left: 0,
          padding: "0px",
          borderRight: "none",
          height: "100%",
          zIndex: 0,
        }}
        style={{ borderRight: "none" }}
      >
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
            padding: "0px",
            zIndex: 1,
            display: "block",
          }}
        >
          <TableRow sx={{ height: "122px" }}>
            <StyledTableHeaderLG
              sx={{
                padding: "0px",
                textAlign: "center",
                width: "95px",
                height: "147.44px",
              }}
            >
              {languageContext.words.timeB}
            </StyledTableHeaderLG>
            <StyledTableHeaderLG
              sx={{
                padding: "0px",
                textAlign: "center",
                minWidth: "74px",
                height: "147.44px",
              }}
              style={{
                borderRight: "none",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                }}
              >
                {languageContext.words.planned_demand_value}
                (kWh)
              </Typography>
              <StyledButton
                sx={{ height: "20px" }}
                style={{
                  //発電計画複写ボタン
                  borderColor:
                    disableControl === false 
                      ? "#00a1e9"
                      : (PaletteMode === "dark"
                          ? "rgba(255, 255, 255, 0.3)"
                          : "rgba(0, 0, 0, 0.26)"),
                  width: "70px",
                  height: "auto",
                }}
                disabled={disableControl}
                onClick={Set_self_consignment}
              >
                <Typography
                  sx={{
                    fontSize: "11px",
                    color:
                      disableControl === false 
                        ? "#00a1e9"
                        : (PaletteMode === "dark"
                            ? "rgba(255, 255, 255, 0.3)"
                            : "rgba(0, 0, 0, 0.26)"),
                  }}
                >
                  {languageContext.words.powerforecast +
                    " " +
                    languageContext.words.copy}
                </Typography>
              </StyledButton>
            </StyledTableHeaderLG>
          </TableRow>
          <TableRow id="contract-demand">
            <StyledTableHeaderG
              sx={{
                padding: "0px 3.6px",
                textAlign: "center",
                maxWidth: "84px",
                minWidth: "84px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              title={
                languageContext.mode === "en"
                  ? languageContext.words.contracted_power + " (kW)"
                  : ""
              }
            >
              {languageContext.words.contracted_power}(kW)
            </StyledTableHeaderG>
            {/* 契約量 */}
            <StyledTableHeaderG
              sx={{
                padding: "0px",
                paddingRight: "8px",
                textAlign: "right",
                width: "65px",
                height: "34px",
              }}
              style={{
                borderRight: "none",
              }}
            >
              {convertToLocaleString(planContract)}
            </StyledTableHeaderG>
          </TableRow>
          <TableRow id="contract-demand">
            <StyledTableHeaderG
              sx={{
                padding: "0px 3.6px",
                textAlign: "center",
                maxWidth: "84px",
                minWidth: "84px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              title={
                languageContext.mode === "en"
                  ? languageContext.words.daily_amount + " (kWh)"
                  : ""
              }
            >
              {languageContext.words.daily_amount}(kWh)
            </StyledTableHeaderG>
            {/* 日量の表示 */}
            <StyledTableHeaderG
              sx={{
                padding: "0px",
                paddingRight: "8px",
                textAlign: "right",
                width: "65px",
                height: "34px",
              }}
              style={{
                borderRight: "none",
              }}
            >
              {convertToLocaleString(CurrentDayDemand())}
            </StyledTableHeaderG>
          </TableRow>
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflowY: "scroll",
              height: "575px",
              "&::-webkit-scrollbar": {
                width: "0px",
                backgroundColor: "transparent",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#AAA",
                borderRadius: "0px",
              },
            }}
          >
            {records()}
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>
    );
  };

  // 当日を選択時のみ表示するテーブル
  const CurrentDateTable = () => {
    // 乖離パーセント計算
    const CalculateDeviation = (data: number, index: number) => {
      let deviation: any = "";

      if (data === -1 || planData[index] === -1) {
        deviation = "";
      } else if (data === 0) {
        deviation = "0.00";
      } else {
        deviation =
          Math.floor(
            ((data - planData[index]) / data) * 100 * Math.pow(10, 2)
          ) / Math.pow(10, 2);
        deviation = Number(deviation).toFixed(2);
      }

      return deviation;
    };

    const DicideTextColor = (num: number) => {
      let textColor = "";
      if (num >= 0 || num === -Infinity) {
        textColor = PaletteMode === "dark" ? "white" : "black";
      } else if (num < 0) {
        textColor = PaletteMode === "dark" ? "aqua" : "Blue";
      }

      return textColor;
    };

    const inputs: JSX.Element[] = [];

    actualData.map((data, index) => {
      inputs.push(
        <TableRow>
          <StyledTableCell
            sx={{
              padding: 0,
              paddingRight: "8px",
              textAlign: "right",
              minWidth: "70.25px",
              height: "34px",
            }}
            style={{ backgroundColor: CellColor(IsOneHourLate(index)) }}
          >
            {data === -1 ? "" : convertToLocaleString(data)}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              padding: 0,
              paddingRight: "8px",
              textAlign: "right",
              minWidth: "70.25px",
              height: "34px",
              color: DicideTextColor(data - planData[index]),
            }}
            style={{ backgroundColor: CellColor(IsOneHourLate(index)) }}
          >
            {data === -1 || planData[index] === -1
              ? ""
              : convertToLocaleString(
                  Math.round(Math.floor((data - planData[index]) * Math.pow(10, 2)) /
                    Math.pow(10, 2))
                )}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              padding: 0,
              paddingRight: "8px",
              textAlign: "right",
              minWidth: "70.25px",
              height: "34px",
              color: DicideTextColor(
                Math.floor(
                  ((data - planData[index]) / data) * 100 * Math.pow(10, 2)
                ) / Math.pow(10, 2)
              ),
            }}
            style={{
              borderRight: "none",
              backgroundColor: CellColor(IsOneHourLate(index)),
            }}
          >
            {CalculateDeviation(data, index)}
          </StyledTableCell>
        </TableRow>
      );
    });

    return (
      <StyledTable
        stickyHeader
        aria-label="sticky table"
        sx={{
          maxWidth: "180px",
          left: 0,
          padding: "0px",
          borderRight: "none",
          height: "100%",
          zIndex: 0,
        }}
        style={{ borderRight: 0 }}
      >
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
            padding: "0px",
            zIndex: 1,
            display: "block",
          }}
        >
          <TableRow sx={{ height: "122px" }}>
            <StyledTableHeaderLG
              sx={{
                padding: "0px",
                textAlign: "center",
                minWidth: "70.25px",
                height: "147.44px",
                zIndex: 1,
              }}
            >
              {languageContext.words.results_jissekichi}
              <br />
              (kWh)
            </StyledTableHeaderLG>
            <StyledTableHeaderLG
              sx={{
                padding: "0px",
                textAlign: "center",
                minWidth: "70.25px",
                zIndex: 1,
              }}
            >
              {languageContext.words.deviation}
              <br />
              (kWh)
            </StyledTableHeaderLG>
            <StyledTableHeaderLG
              sx={{
                padding: "0px",
                textAlign: "center",
                minWidth: "70.25px",
                zIndex: 0,
              }}
              style={{ borderRight: "none" }}
            >
              {languageContext.words.deviation}
              <br />
              (%)
              {/* {languageContext.words.deviation_percent} */}
            </StyledTableHeaderLG>
          </TableRow>
          {(() => {
            const rowElement = [];
            for (let i = 0; i < 2; i++) {
              rowElement.push(
                <TableRow id="contract-demand">
                  {(() => {
                    const element = [];
                    for (let j = 0; j < 3; j++) {
                      element.push(
                        <StyledTableHeaderG
                          key={`当日${i} ${j}`}
                          sx={{
                            padding: "0px",
                            paddingRight: "8px",
                            textAlign: "right",
                            minWidth: "70.25px",
                            height: "34px",
                            zIndex: j === 2 ? 0 : 1,
                          }}
                          style={{
                            borderRight: j === 2 ? "none" : "",
                          }}
                        >
                          -
                        </StyledTableHeaderG>
                      );
                    }
                    return element;
                  })()}
                </TableRow>
              );
            }
            return rowElement;
          })()}
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflowY: "scroll",
              height: "575px",
              "&::-webkit-scrollbar": {
                width: "0px",
                backgroundColor: "transparent",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#AAA",
                borderRadius: "0px",
              },
            }}
          >
            {inputs}
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>
    );
  };

  // 過去参照値を表示するテーブル
  const PastReferenceTable = () => {
    const demandList = [];
    for (let i = 0; i < 7; i++) {
      if (i < displayNum) {
        demandList.push(
          <StyledTableHeaderG
            sx={{
              padding: "0px",
              paddingRight: "8px",
              zIndex: 0,
              textAlign: "right",
              height: "34px",
              minWidth: "70px",
              width: "105.35px",
            }}
          >
            {/*CreateDemand(i, pastData)*/}
          </StyledTableHeaderG>
        );
      }
    }

    const dayDemand = [];
    for (let i = 0; i < 7; i++) {
      if (i < displayNum) {
        dayDemand.push(
          <StyledTableHeaderG
            sx={{
              padding: "0px",
              paddingRight: "8px",
              zIndex: 0,
              textAlign: "right",
              height: "34px",
              minWidth: "70px",
            }}
          >
            {CreateDayDemand(i)}
          </StyledTableHeaderG>
        );
      }
    }

    const constractPowers = [];
    for (let i = 0; i < 7; i++) {
      if (i < displayNum) {
        constractPowers.push(
          <StyledTableHeaderG
            sx={{
              padding: "0px",
              paddingRight: "8px",
              zIndex: 0,
              textAlign: "right",
              height: "34px",
              minWidth: "70px",
            }}
          >
            {convertToLocaleString(planContract)}
          </StyledTableHeaderG>
        );
      }
    }

    //ヘッダーデータの生成（過去参照日）
    const headerDate = [];
    for (let i = 0; i < 7; i++) {
      if (i < displayNum) {
        headerDate.push(
          <StyledTableHeaderLG
            sx={{
              padding: "1px",
              zIndex: 0,
              textAlign: "center",
              minWidth: "50px",
              height: "30px",
            }}
          >
            {languageContext.convertDateToLocalizedString(TableHeaderDate()[i])}
          </StyledTableHeaderLG>
        );
      }
    }

    // ラジオボタン操作時
    const handleCheck = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setSelectedNumber(Number(event.target.value));

      // 取得した時間で固定されてしまうので年月日のみの文字列で比較
      const dateStr = subDays(pastDate, index).toLocaleDateString();

      // そのエリアのその需要家のその日のデータをclickDataに格納
      const clickData: number[] = [];
      let clickDate = "";
      pastData.map((target) => {
        if (target.targetDate.replaceAll("-", "/") === dateStr) {
          target.values.forEach((value) => clickData.push(Math.floor(value)));
          clickDate = target.targetDate;
        }
      });

      setClickData(clickData);
      setClickDate(subDays(pastDate, index));
    };

    // ラジオボタンの生成
    const radioButton = [];
    for (let i = 0; i < 7; i++) {
      if (i < displayNum) {
        radioButton.push(
          <StyledTableHeaderLG
            key={`radioGroup${i}`}
            sx={{
              padding: "0px",
              zIndex: 0,
              textAlign: "center",
              height: "32px",
              minWidth: "70px",
            }}
          >
            <Radio
              checked={selectedNumber === i}
              onChange={(event) => {
                handleCheck(i, event);
              }}
              value={i.toString()}
              name="radio-buttons"
              inputProps={{ "aria-label": `${i}` }}
              sx={{
                minWidth: "auto",
                "& .MuiSvgIcon-root": {
                  fontSize: 17,
                },
              }}
              disabled={disableControl}
              style={{ padding: "1px" }}
            />
          </StyledTableHeaderLG>
        );
      }
    }

    //複写ボタンの生成
    const reproductionButton = [];
    for (let i = 0; i < 7; i++) {
      if (i < displayNum) {
        reproductionButton.push(
          <StyledTableHeaderLG
            key={`copyPastData ${i}`}
            sx={{
              padding: "0px",
              zIndex: 0,
              textAlign: "center",
              height: "32px",
              minWidth: "70px",
            }}
          >
            <StyledButton
              sx={{ height: "20px" }}
              style={{
                color:
                  disableControl === false 
                    ? "#00a1e9"
                    : (PaletteMode === "dark"
                        ? "rgba(255, 255, 255, 0.3)"
                        : "rgba(0, 0, 0, 0.26)"),
                borderColor:
                  disableControl === false 
                    ? "#00a1e9"
                    : (PaletteMode === "dark"
                        ? "rgba(255, 255, 255, 0.3)"
                        : "rgba(0, 0, 0, 0.26)"),
                height: "13px",
              }}
              disabled={disableControl}
              onClick={() => {
                copyDayData(i);
              }}
            >
              {languageContext.words.copy}
            </StyledButton>
          </StyledTableHeaderLG>
        );
      }
    }

    // 選択した需要家の過去参照用データを抽出 (列、セル番号)
    const CreateTableData = (i: number, index: number) => {
      let tableData = 0;
      const dateStr = subDays(pastDate, i).toLocaleDateString();
      //console.log("pastDataの確認", pastData);
      pastData.map((target) => {
        if (target.targetDate.replaceAll("-", "/") === dateStr) {
          // 該当時刻コードのデータを返す
          //console.log("日付とバリュー" ,dateStr, i, target.values[index])
          tableData += Number(Math.floor(target.values[index]));
        }
      });
      //console.log("テーブルデータの確認",tableData);
      return tableData;
    };

    // 過去参照のテーブルボディ部分
    const PastReferenceTableData = () => {
      return Array(48)
        .fill(0)
        .map((value, index) => {
          return (
            <TableRow key={`values${index}`}>
              {(() => {
                const tableData = [];
                for (let i = 0; i < displayNum; i++) {
                  let width;
                  if (displayNum === 7) {
                    width = i === 6 ? 92 : 106;
                  } else {
                    width = i === 3 ? 113.9 : 126;
                  }

                  tableData.push(
                    <StyledTableCell
                      key={`tableValue${index}${i}`}
                      sx={{
                        height: "34px",
                        textAlign: "right",
                        padding: 0,
                        paddingRight: "8px",
                        minWidth: "70px",
                        width: `${width}px`,
                      }}
                      style={{
                        backgroundColor: CellColor(IsOneHourLate(index)),
                      }}
                    >
                      {convertToLocaleString(CreateTableData(i, index))}
                    </StyledTableCell>
                  );
                }
                return tableData;
              })()}
            </TableRow>
          );
        });
    };

    return (
      <StyledTable
        stickyHeader
        aria-label="sticky table"
        sx={{
          Width: "100%",
          left: 0,
          padding: "0px",
          borderRight: "none",
          height: "100%",
          zIndex: 0,
        }}
        style={{ borderRight: 0 }}
      >
        {/* <table id="demand-table" border={1}> */}
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
            padding: "0px",
            zIndex: 1,
            display: "block",
          }}
        >
          <TableRow>
            <StyledTableHeaderLG
              sx={{
                height: "48.44px",
                textAlign: "center",
                padding: 0,
                minWidth: "483.5px",
                width: "737.5px",
              }}
              colSpan={displayNum}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                color={"gray"}
                alignItems={"center"}
              >
                {/* 過去計画 */}
                <FormControlLabel
                  value="end"
                  control={
                    <Radio
                      checked={selectedValue === "a"}
                      onChange={(event) => {
                        // 選択肢の制御
                        setSelectedValue(event.target.value);
                        //ChangePastDate(clickDate);
                      }}
                      value="a"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      sx={{
                        minWidth: "auto",
                        "& .MuiSvgIcon-root": {
                          fontSize: 17,
                        },
                      }}
                      style={{ padding: "4px" }}
                    />
                  }
                  sx={{ margin: 0 }}
                  label={
                    <Typography sx={{ fontSize: "14px" }}>
                      {languageContext.words.results}
                    </Typography>
                  }
                />
                {/* 過去実績 */}
                <FormControlLabel
                  value="end"
                  control={
                    <Radio
                      checked={selectedValue === "b"}
                      onChange={(event) => {
                        setSelectedValue(event.target.value);
                        //ChangePastDate(clickDate);
                      }}
                      value="b"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                      sx={{
                        minWidth: "auto",
                        "& .MuiSvgIcon-root": {
                          fontSize: 17,
                        },
                      }}
                      style={{ padding: "4px" }}
                    />
                  }
                  sx={{ margin: 0 }}
                  label={
                    <Typography sx={{ fontSize: "14px" }}>
                      {languageContext.words.plan}
                    </Typography>
                  }
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: PaletteMode === "dark" ? "lightgray" : "black",
                    paddingLeft: "8px",
                  }}
                >
                  {languageContext.words.display_start_date}
                </Typography>
                <CustomDatePickerTryal setValue={ChangePastDate} />
              </Box>
            </StyledTableHeaderLG>
          </TableRow>
          <TableRow>{headerDate}</TableRow>
          <TableRow>{radioButton}</TableRow>
          <TableRow>{reproductionButton}</TableRow>
          <TableRow>{constractPowers}</TableRow>
          <TableRow>{dayDemand}</TableRow>
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflowY: "scroll",
              height: "575px",
              "&::-webkit-scrollbar": {
                width: "10px",
                backgroundColor:
                  PaletteMode === "dark" ? "#5e5e5e" : "transparent",
                height: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: PaletteMode === "dark" ? "white" : "#AAA",
                borderRadius: "10px",
              },
            }}
          >
            {PastReferenceTableData()}
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>
    );
  };

  //複写ボタン押下時
  const copyDayData = (i: number) => {
    const copyData: number[] = [];

    // 取得した時間で固定されてしまうので年月日のみの文字列で比較
    const dateStr = subDays(pastDate, i).toLocaleDateString();

    // そのエリアのその需要家のその日のデータをcopyDataに格納
    pastData.map((target) => {
      if (target.targetDate.replaceAll("-", "/") === dateStr) {
        target.values.forEach((value) => copyData.push(Math.floor(value)));
      }
    });

    if (copyData.length === 0) {
      // データがない場合
      for (let i = 0; i < 48; i++) {
        copyData.push(0);
      }
    }

    // テキストフィールドが非活性のコマのデータは上書きしないようにデータ作成
    const data: number[] = [];
    planData.forEach((planData, index) => {
      if (IsOneHourLate(index)) {
        data.push(planData);
      } else {
        data.push(copyData[index]);
      }
    });

    setPlanData(data);
  };

  // 当日なら乖離などのテーブルをレンダーする
  const ConditionalCurrentTableRender = () => {
    const toDate = new Date();
    if (inputDate.toLocaleDateString() === toDate.toLocaleDateString()) {
      return CurrentDateTable();
    }
  };

  //テキストフィールド入力値6桁制限判定
  function isMaxLength(value: string) {
    if (value.length > 6) {
      return false;
    } else {
      return true;
    }
  }

  //ページレンダリング
  if (error) {
    // errorはstate
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <CustomModalProgress open={true} />;
  }
  return (
    <div
      //ダークモード対応
      className={`cn-main-display ${PaletteMode === "dark" ? "dark" : "light"}`}
    >
      <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
        <CustomMultipleSelectList
          powerFg={false}
          list={allDemandList}
          areaId={areaId}
          bgId={demandBg}
          powerDemandId={demand}
          handleValueChangeAreaList={setAreaList}
          handleValueChangeArea={setAreaId}
          handleValueChangeBG={setDemandBg}
          handleValueChangePowerDemand={setDemand}
          getData={ProcessGetDemandPlan}
        ></CustomMultipleSelectList>
        {/*         <CustomSelectList
          value={areaId}
          options={areaOptions(areaList)}
          onChange={selectArea}
        />
    
        <CustomSelectList
          value={demandBg}
          options={demandbgOptions}
          onChange={selectDemandBg}
        />
     
        <CustomSelectList
          value={demand}
          options={demandOptions}
          onChange={selectDemand}
        />  */}
        {/* 作業日 */}
        <GlobalDatePicker isDipsTodayBtn={true} />
        <Typography
          sx={{
            color: PaletteMode === "dark" ? "lightgray" : "gray",
            width: "660px",
            marginLeft: "15%",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {lastUpdateInfo
            ? `${languageContext.words.last_update_info}
              : ${languageContext.convertDateToLocalizedString(
                lastUpdateInfo.date,
                "HH:mm:ss"
              )}
              (${lastUpdateInfo.user})`
            : ""}
        </Typography>
        <Box
          sx={{
            height: "40px",
            display: "flex",
          }}
        >
          <SaveDataButton
            hidden={hidden}
            disabled={
              disableControl ||
              hidden ||
              isBefore(
                new Date((dateContext.pickDate as Date).toLocaleDateString()),
                new Date(timeContext.time.toLocaleDateString())
              )
                ? true
                : false
            }
            message={languageContext.words.note_saved}
            onAccept={OnAccept}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex" }} width={"100%"} height={"99%"}>
        <Box sx={{ width: "50%", height: "99%", mt: "0px", mr: "5px" }}>
          <ChartRender data={graphData} options={options} />
        </Box>
        <Box
          sx={{
            width: "50%",
            height: "92%",
            color: PaletteMode === "dark" ? "lightgray" : "black",
            marginTop: "9px",
          }}
        >
          <Box sx={{ marginLeft: "90%", marginTop: "1px" }}>
            {languageContext.words.unit}：kWh
          </Box>
          <ScrollSync>
            <TableContainer
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                marginLeft: "5px",
                // marginTop: "17px",
                overflowY: "hidden",
                fontSize: "14px",
              }}
            >
              {demandTable()}
              {ConditionalCurrentTableRender()}
              {PastReferenceTable()}
            </TableContainer>
          </ScrollSync>
        </Box>
      </Box>
      <CustomDialog
        title={languageContext.words.demand_plan}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onAccept={() => {}}
        onClose={() => setDigOpen(false)}
      />
      <CustomModalProgress open={isLoading} />
    </div>
  );
};

// レンダリング
// チャート
const ChartRender = (props: any) => {
  const { data, options } = props;
  const chartRef = useRef<ChartJS>(null);
  // ズームリセット
  const handleResetZoom = () => {
    if (!(chartRef.current === null)) {
      chartRef.current.resetZoom();
    }
  };
  console.log("グラフ レンダー");
  return (
    <Chart
      type={"bar"}
      data={data}
      // chartの動的な大きさ変更調査
      height={"187"}
      width={"205"}
      options={options}
      ref={chartRef}
      onDoubleClick={() => handleResetZoom()}
    />
  );
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
  zoomPlugin
);

export default DemandForecast;
