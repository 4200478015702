import styled from "styled-components";
import {
  StyledTable,
  StyledTableHeaderG,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import { StyledButton } from "../../common/styledComponents/styledButton";
import CustomSelectList, {
  CustomMultipleSelectList,
} from "../../common/customComponents/CustomSelectList";
import {
  GlobalDatePicker,
  DateContext,
} from "../../common/customComponents/GlobalDatePicker";
import CustomDatePickerTryal from "../../common/customComponents/CustomDatePickerTryal";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
  ChartData,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { ClipboardEvent } from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import { useLocation, useSearchParams } from "react-router-dom";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import SaveDataButton from "../../common/customComponents/SaveDataButton";
import Typography from "@mui/material/Typography";
import zoomPlugin from "chartjs-plugin-zoom";
import { addDays, isBefore, isSameDay, subDays } from "date-fns";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { LanguageContext } from "../../common/localization/localization";
import {
  reqLeftPowerForecast,
  resLeftPowerForecast,
  reqRightPowerActualPlan,
  resPowerActualPlan,
  reqSaveActualPlan,
} from "../../../types/occto/PowerForecast";
import { format, getWeek } from "date-fns";
import { isNullOrUndef } from "chart.js/dist/helpers/helpers.core";
import * as Common from "../../../types/common/Search";
import SetPowerBgList from "../../master/SetPowerBgList";
import PowerBgList from "../../master/PowerBgList";
import { Session } from "inspector";
import { Button, Hidden } from "@mui/material";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import { CustomModalProgress } from "../../common/customComponents/CustomProgress";
import { RoleContext } from "../../common/customComponents/RoleProvider";
import { PaletteContext } from "../../common/paletteMode";
import { useAreaUtility } from "../../../common/area";
import { cellTime, forcastGraphLabels } from "../../../common/cellTime";
import { useAuthedApi } from "../../../common/axios";
import { powerDemandInfo } from "../../common/customComponents/CustomSelectList";
import { searchInfo } from "../../../types/occto/PowerForecastPlan";
import { AREA_ID } from "../../../common/constant";

// APIパス
// 発電BG、発電所一覧
const GET_POWER_FORECAST_INFO_URL = "/getPowerForecastInfoList";
// エリア一覧
const AREA_LIST_URL = "/getAreaNameList";
// 発電BG一覧
const POWER_BG_LIST_URL = "/getPowerBgNameList";
// 発電所一覧
const POWER_PLANT_LIST_URL = "/getPowerNameList";
//発電計画
const LeftDataUrl = "/LeftpowerForecast";
const RightDataUrl = "/RightpowerForecast";
const SaveDataUrl = "/SavepowerForecast";
const WEATHER_GET_URL = "/GetWeather";
const SOLAR_RADIATION_GET_URL = "/GetSolar_Radiation";
const GetSaveTimeNameUrl = "/GetSaveTimeName";
const GetContractPowerUrl = "/GetContractPower";
// ロール判定
const GET_ROLE_JUDGMENT_URL = "/getIsEditableRolePowerForecast";

//
interface powerForecastList {
  areaName: string;
  bgName: string;
  plantName: string;
  targetDate: string;
  power: number;
  dayPower: number;
  actualValues: powerValue[];
  planValues: powerValue[];
}

interface powerForecast {
  areaName: string;
  bgName: string;
  plantName: string;
  targetDate: string;
  power: number;
  dayPower: number;
  values: powerValue[];
}

interface powerValue {
  powerValue: number;
  option1: string; // 計画値以外に出したいもの(予備)
  option2: string; // 計画値以外に出したいもの(予備)
}

//乖離、乖離%、実績格納用
interface StructureConditional {
  actualData: number[];
  divergence: number[];
  divergencerate: number[];
}

const ConditionalData: StructureConditional = {
  actualData: [],
  divergence: [],
  divergencerate: [],
};

//計算に使う用に、IDと地域名をセットにして、配列にしておく
//type searchInfo = { id: number; name: string };

//グローバル変数 天気情報での発電所検索、表示制御用
let POWERPLANT_ID = 0;

const PowerForecast = () => {
  //言語を切り替えるデータコンテキスト
  const languageContext = useContext(LanguageContext);
  const { areaId, setAreaId } = useContext(AreaContext);

  const api = useAuthedApi();
  // 保存ボタンの表示・非表示
  const [hidden, setHidden] = useState<boolean>(true);

  //セレクトタブのState
  const [areaList, setAreaList] = useState<string[]>([]);
  const [powerBg, setPowerBg] = useState(0);
  const [power, setPower] = useState(0);
  const [allPowerList, setAllPowerPlantList] = useState<powerDemandInfo[]>([]);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);
  // ログイン中ユーザのロール
  const roleContext = useContext(RoleContext);

  //セレクトタブに表示する文面のstate
  //const bgInfos_test = [{id: 1,name: '北海道第一発電所'},{id: 2, name: '北海道第二発電所'}] //test

  const context = useContext(DateContext); 
  const [pastDate, setPastDate] = useState<Date>(addDays(new Date(), 1)); //画面右表の表示開始日。
  const [inputDate, setInputDate] = useState<Date>(
    context.pickDate === null 
    ? addDays(new Date(), 1) 
    : context.pickDate
  );
  const [useEffectCount, setCount] = useState(0);
  const [selectedValue, setSelectedValue] = useState("a"); //ラジオボタン選択状態
  const [leftData, setLeftData] = useState<resLeftPowerForecast[]>([]);
  const [getRightData, setRightData] = useState<any>([]);
  const getLeftDBdata: any[] = [];

  const [predict, setPredict] = useState<number[]>([]); //システム予測(weather情報)

  const [contractpower, setContractPower] = useState<number>(0);

  //ダイアログ関連
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const processFlag = useRef<boolean>(true);

  // グローバルカレンダー
  const dateContext = useContext(DateContext);
  // タイムコンテキスト
  const { time } = useContext(TimeContext);

  const firstlender_flag = useRef<boolean>(true);
  const firstlenderRight_flag = useRef<boolean>(true);

  //天気情報表示用のState
  const [WeatherImg1, setWeatherImg1] = useState<string>();
  const [WeatherImg2, setWeatherImg2] = useState<string>();
  const [WeatherImg3, setWeatherImg3] = useState<string>();
  const [WeatherImg4, setWeatherImg4] = useState<string>();
  const [WeatherImg5, setWeatherImg5] = useState<string>();
  const [WeatherImg6, setWeatherImg6] = useState<string>();
  const [WeatherImg7, setWeatherImg7] = useState<string>();
  const [WeatherImg8, setWeatherImg8] = useState<string>();

  const [WeatherWord1, setWeatherWord1] = useState<string>();
  const [WeatherWord2, setWeatherWord2] = useState<string>();
  const [WeatherWord3, setWeatherWord3] = useState<string>();
  const [WeatherWord4, setWeatherWord4] = useState<string>();
  const [WeatherWord5, setWeatherWord5] = useState<string>();
  const [WeatherWord6, setWeatherWord6] = useState<string>();
  const [WeatherWord7, setWeatherWord7] = useState<string>();
  const [WeatherWord8, setWeatherWord8] = useState<string>();

  const [WeatherTmp1, setWeatherTmp1] = useState<string>();
  const [WeatherTmp2, setWeatherTmp2] = useState<string>();
  const [WeatherTmp3, setWeatherTmp3] = useState<string>();
  const [WeatherTmp4, setWeatherTmp4] = useState<string>();
  const [WeatherTmp5, setWeatherTmp5] = useState<string>();
  const [WeatherTmp6, setWeatherTmp6] = useState<string>();
  const [WeatherTmp7, setWeatherTmp7] = useState<string>();
  const [WeatherTmp8, setWeatherTmp8] = useState<string>();

  //グラフチャート表示用*********************************************
  //const [dataset, setDatasets] = useState<datasetType[]>([]); //エリア単位棒グラフ用
  const dataset = useRef<datasetType[]>([]);
  //const [rightAriaoneline, setChartAriaUnitOneline] = useState<datasetType[]>([]); //赤グラフ用
  const rightAriaoneline = useRef<datasetType[]>([]);
  //const [rightoneline, setChartPlantUnitOneline] = useState<datasetType[]>([]); //発電所単位青グラフと発電予測用
  const rightoneline = useRef<datasetType[]>([]);

  const leftreqvalue: reqLeftPowerForecast = {
    userId: 1, //todo 動的に入れられるように
    bgId: powerBg,
    area: areaId, //todo 動的に入れられるように
    powerPlantId: power,
    targetDate: inputDate,
  };

  const rightreqvalue: reqRightPowerActualPlan = {
    userId: 1,
    area: areaId, //todo 動的に入れられるように
    bgId: powerBg,
    powerPlantId: power,
    targetDate: inputDate,
    type: selectedValue, //動的に動かせるように
  };

  // 初期検索条件の取得
  const url = useLocation().search;
  const query = new URLSearchParams(url);

  //初期検索
  useEffect(() => {
    //発電所情報一括取得
    getPowerPlantList();
    dicideSaveButtonVisibility();
  }, []);

  // ログインユーザのロールによって保存ボタンの表示・非表示を切り替える
  const dicideSaveButtonVisibility = () => {
    api
      .get(GET_ROLE_JUDGMENT_URL, { params: { userRole: roleContext.role } })
      .then((res) => {
        setHidden(res.data ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /**
   * 発電計画画面に表示するデータを取得
   * @param areaIds エリア一覧
   * @param powerBg 発電Bg
   * @param power 発電所
   */
  async function getPowerPlan(
    areaIds: string[],
    powerBg: number,
    power: number
  ) {
    let selectedArea: string = areaId;
    if (areaIds.length === 1) {
      selectedArea = areaIds[0];
    } else if (areaIds.length > 1) {
      selectedArea = AREA_ID.NONE;
    }
    //発電所がひとつに絞られている時、システム予測と契約電力と天気予測を取得する
    if(selectedArea !== AREA_ID.NONE && powerBg !== 0 && power !== 0) {
      POWERPLANT_ID = power;
      GetContractPower(power);
      SerchPredictData();
      SetWeatherInfo();
    } else {
      setContractPower(0);
    }

    await getLeftPowerValue(selectedArea, powerBg, power);
    await getRightPowerValue(selectedArea, powerBg, power);

  }
  /**
   * 画面左側のデータを取得
   * @param area エリアId
   * @param powerBg 発電Bg
   * @param power 発電所
   */
  async function getLeftPowerValue(
    area: string,
    powerBg: number,
    power: number
  ) {
    //メッセージリセット
    setLastUpdateInfo(undefined);
    setIsLoadingLeft(true);
    leftreqvalue.area = area;
    leftreqvalue.bgId = powerBg;
    leftreqvalue.powerPlantId = power;
    leftreqvalue.targetDate = context.pickDate as Date;
    api
      .post(LeftDataUrl, leftreqvalue)
      .then(async (res) => {

        setIsLoadingLeft(false);
        setLeftData(res.data);
        if (powerBg === 0 || power === 0) {
          //エリア指定の時
          const getMaxHeight = (a: number, b: number) => {
            return Math.max(a, b);
          };
          const tempPlanData = reqGetsetPlandata(res.data);
          setPlanData(tempPlanData);
          savediff.current = tempPlanData;
          const maxHeight = Math.round(tempPlanData.reduce(getMaxHeight) * 1.1); //計画値の配列で最大の値をグラフ最大高として設定
          setChartMax(maxHeight);
          setChartStep(Math.round(maxHeight / 10));
          setDisableControl(true); //表示、非表示制御？
          setTextFieldDisable(disableTrue); //入力制限
          //グラフリセット
          dataset.current = [];
          rightAriaoneline.current = [];
          rightoneline.current = [];

          //実績、乖離、乖離％のデータをuseStateの変数に投入
          const toDate = new Date();
          if ((context.pickDate !== null ? context.pickDate.toLocaleDateString() : inputDate.toLocaleDateString()) === toDate.toLocaleDateString()) {
            await CreateActualDayData(res.data, Number(power));
          }
          try {
            //ここのエリア名はつかってないため
            /*CreateAreaDatasets(
              context.pickDate === null ? inputDate : context.pickDate, 
              pastDate, 
              "",//areaInfos[Number(Serch_area)].name,
              leftres.data
            );*/
          } catch {
            //
            setDialogMessage(languageContext.words.get_error);
            setDigOpen(true);
          }
        } else if (power != 0) {
          //発電所指定の時
          if(isBefore(
            new Date(format(dateContext.pickDate as Date, "yyyy/MM/dd")),
            new Date(format(time as Date, "yyyy/MM/dd"))
          )) {
            setDisableControl(true); //表示、非表示制御
          } else {
            setDisableControl(false);
          }
          setTextFieldDisable(disableTrue); //入力制限

          const tempPlanData = reqGetsetPlandata(res.data);
          setPlanData(tempPlanData);
          savediff.current = tempPlanData;
          //グラフリセット
          dataset.current = [];
          rightAriaoneline.current = [];
          rightoneline.current = [];

          //実績、乖離、乖離％のデータをuseStateの変数に投入
          const toDate = new Date();
          if ((context.pickDate !== null ? context.pickDate.toLocaleDateString() : inputDate.toLocaleDateString()) === toDate.toLocaleDateString()) {
            await CreateActualDayData(res.data, Number(power));
          }

          //保存日時の取得
          await GetSaveTime(powerBg, power);

        }
        //todo 乖離値とかその辺もここでセットする？:初期表示時は当日+1日になると思うのでここでは不要

        //setIsLoaded(true);
      })
      .catch((error) => {
        setIsLoaded(true);
        setError(error);
        console.error(error);
      });
  }

  // 画面右側のデータを取得
  async function getRightPowerValue(
    area: string,
    powerBg: number,
    power: number
  ) {
    //画面右側取得
    //console.log("画面右側バックにリクエスト(初回)リクエスト引数",leftreqvalue);
    setIsLoadingRight(true);
    rightreqvalue.area = area;
    rightreqvalue.bgId = powerBg;
    rightreqvalue.powerPlantId = power;
    rightreqvalue.targetDate = pastDate;
    rightreqvalue.type = selectedValue;
    api
      .post(RightDataUrl, rightreqvalue)
      .then(async (response) => {
        //メッセージリセット
        setLastUpdateInfo(undefined);
        setIsLoadingRight(false);

        setRightData(response);
        //表に表示する内容をセット
        setPastData(response.data);

        //グラフリセット
/*         dataset.current = [];
        rightAriaoneline.current = [];
        rightoneline.current = []; */

        //グラフ用配列作成
        if (power === 0) {
          //エリア指定の時
          setDisableControl(true);
          //console.log("clickdate",addDays(pastDate,2));
          /*
          await CreateAreaRedDatasets(
            pastDate, 
            response.data,
            selectedValue
          );
          */
        } else if (power != 0) {
          //発電所指定の時
        //グラフリセット
          dataset.current = [];
          rightAriaoneline.current = [];
          rightoneline.current = [];
          if(isBefore(
            new Date(format(dateContext.pickDate as Date, "yyyy/MM/dd")),
            new Date(format(time as Date, "yyyy/MM/dd"))
          )) {
            setDisableControl(true); //表示、非表示制御
          } else {
            setDisableControl(false);
          }
          setTextFieldDisable(disableTrue);// 入力制限
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        setIsLoaded(true);
        setError(error);
        console.error(error);
      });
    leavePageContext.setBlockLeave(false);
  }

  //発電所情報一括取得
  const getPowerPlantList = () => {
    api
      .get(GET_POWER_FORECAST_INFO_URL)
      .then((res) => {
        const powerList: powerDemandInfo[] = res.data.map((v: any) => {
          return {
            areaId: v.areaId,
            bgId: v.bgId,
            bgName: v.bgName,
            powerDemandId: v.powerId,
            powerDemandName: v.powerName,
          };
        });
        setAllPowerPlantList(powerList);

        // 初期表示をセット
        let initArea: string = AREA_ID.NONE;
        let initBg = 0;
        let initPower = 0;
        const paramAreaId = query.get("areaId");
        const paramBgId = query.get("bgId");
        const paramPowerId = query.get("plantId");
        initArea = paramAreaId ? paramAreaId : AREA_ID.NONE;
        POWERPLANT_ID = Number(paramPowerId);

        //ステータス一覧、発販、インバランスからの遷移
        if (paramBgId == null && paramPowerId == null) {
          //planstatuslistから遷移したとき
          leftreqvalue.area = initArea;
          rightreqvalue.area = initArea;
          leftreqvalue.bgId = 0;
          rightreqvalue.bgId = 0;
          leftreqvalue.powerPlantId = 0;
          rightreqvalue.powerPlantId = 0;

          setAreaId(initArea);
          const tmpArea = powerList.filter((v) => v.areaId === initArea);
          const tmpBg = [...new Set(tmpArea.map((v) => v.bgId))];
          const tmpPower = [...new Set(tmpArea.map((v) => v.powerDemandId))];

          //選択したエリアに所属するBGが1つの場合、そのBGを選択されているBGに設定
          if(tmpBg.length === 1){
            initBg = tmpBg[0];
            leftreqvalue.bgId = tmpBg[0];
            rightreqvalue.bgId = tmpBg[0];
            setPowerBg(initBg);
          } else {
            setPowerBg(0);
          }
          //選択したエリアに所属する発電所が1つの場合、その発電所を選択されている発電所に設定
          if(tmpPower.length === 1) {
            initPower = tmpPower[0];
            leftreqvalue.powerPlantId = tmpPower[0];
            rightreqvalue.powerPlantId = tmpPower[0];
            setPower(initPower);
          } else {
            setPower(0);
          }

        } else {
          //発電計画一覧から遷移したとき
          //APIクエリにセット
          setAreaId(initArea);
          leftreqvalue.area = initArea;
          rightreqvalue.area = initArea;
          leftreqvalue.bgId = Number(paramBgId);
          rightreqvalue.bgId = Number(paramBgId);
          leftreqvalue.powerPlantId = Number(paramPowerId);
          rightreqvalue.powerPlantId = Number(paramPowerId);
          POWERPLANT_ID = Number(paramPowerId);
         // SerchPredictData();
          initBg = Number(paramBgId);
          initPower = Number(paramPowerId);
          setPowerBg(Number(paramBgId));
          setPower(Number(paramPowerId));
        }
        getPowerPlan(areaList, initBg, initPower);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  //発電計画値をuseStateにセット
  const reqGetsetPlandata = (plan: any) => {
    console.log("発電計画値", plan);
    const powervalue: number[] = [];

    //配列初期化
    for (let fact = 0; fact < 48; fact++) {
      powervalue[fact] = 0;
    }

    //console.log("発電計画値の確認",req);
    //console.log("長さ",req.length);

    //エリア単位かどうか判定
    if (plan.length >= 0) {
      //エリア単位
      //加算 todo 文字列結合になってしまう
      for (let i = 0; i < plan.length; i++) {
        for (let j = 0; j < plan[i].supply_power_qu.length; j++) {
          powervalue[j] =
            Number(powervalue[j]) +
            Number(plan[i].supply_power_qu[j].powerValue || 0);
        }
      }
      return powervalue;
    } else {
      //発電所単位取得は1件だけなのでreq[0]
      for (let j = 0; j < plan.supply_power_qu.length; j++) {
        powervalue[j] = Number(plan.supply_power_qu[j].powerValue);
      }
      //console.log("発電計画値をuseStateにセットする",powervalue);
      return powervalue;
    }
  };

  // Todo {kushibiki} 前の画面からの遷移時に受け取るIDによってstateの中身を変更可能にする
  //todo 初期表示時のstateはどうするか

  const [selectedNumber, setSelectedNumber] = useState(-1);
  const [error, setError] = useState<any>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isLoadingAreas, setIsLoadingAreas] = useState<boolean>(false);
  const [isLoadingBgs, setIsLoadingBgs] = useState<boolean>(false);
  const [isLoadingPlants, setIsLoadingPlants] = useState<boolean>(false);
  const [isLoadingLeft, setIsLoadingLeft] = useState<boolean>(false);
  const [isLoadingRight, setIsLoadingRight] = useState<boolean>(false);
  const [isLoadingPredict, setIsLoadingPredict] = useState<boolean>(false);
  const [isLoadingSaveTime, setIsLoadingSaveTime] = useState<boolean>(false);
  const [isLoadingContractPower, setIsLoadingContractPower] =
    useState<boolean>(false);
  const isLoading =
    isLoadingAreas ||
    isLoadingBgs ||
    isLoadingPlants ||
    isLoadingLeft ||
    isLoadingRight ||
    isLoadingPredict ||
    isLoadingSaveTime ||
    isLoadingContractPower;

  const [displayNum, setDisplayNum] = useState(4); //画面右表の列表示数。 デフォルトは当日表示の4列
  const predictRef = useRef(true);

  const disableTrue = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ];
  const disableFalse = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  const [textFieldDisable, setTextFieldDisable] = useState(disableFalse);
  const [disableControl, setDisableControl] = useState(false); //画面右ラジオボタン活性非活性制御
  const [pastAreaData, setPastAreaData] = useState<resLeftPowerForecast[]>([]);
  const [currentAreaData, setCurrentAreaData] = useState<powerForecastList[]>(
    []
  );
  const [pastData, setPastData] = useState<resPowerActualPlan[]>([]); //初期表示、右日付、ラジオボタン操作時に格納 画面右側の処理で取得した計画値or実績値の格納に利用
  const [planData, setPlanData] = useState<number[]>([]);
  //const planData = useRef<number[]>([]); //エリア単位と発電所単位の計画値格納に利用する(画面左側をとってきたとき)
  const savediff = useRef<number[]>([]); //保存時にGC後のセルが保存されないように比較するためのもの
  const [clickData, setClickData] = useState<number[]>([]); //ラジオボタンで選択中のデータ
  //const [clickDate, setClickDate] = useState<Date>(addDays(new Date(), 1));
  const clickDate = useRef<Date>(addDays(new Date(), 1));
  const [lastUpdateInfo, setLastUpdateInfo] = useState<{
    date: Date;
    user: string;
  }>();
  //const date = new Date(2023, 0, 1, 0, 0);
  const [chartMax, setChartMax] = useState<number>();
  const [chartStep, setChartStep] = useState<number>();

  // グラフオプション
  // 凡例等グラフの設定をする
  // 「as const」が肝なので付けるプロパティを間違えないこと
  const options = useMemo(() => {
    // グラフデフォルト値
    // ダークモード対応でボーダー線の色等を変える
    ChartJS.defaults.color = PaletteMode === "dark" ? "#999" : "#666";
    ChartJS.defaults.borderColor =
      PaletteMode === "dark"
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)";

    return {
      //折れ線グラフの点を削除
      // title: {
      //   fontSize: 14,
      // },
      elements: {
        point: {
          radius: 0,
        },
      },
      plugins: {
        title: {
          position: "left" as const,
          display: true,
          text: languageContext.words.power_gen_energy,
          font: { size: 14 },
        },
        legend: {
          // 凡例の設定
          position: "top" as const,
          reverse: true,
          labels: {
            font: {
              size: 14,
            },
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "xy" as const,
            modifierKey: "meta" as const,
            scaleMode: undefined,
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            drag: {
              enabled: true,
              backgroundColor: "rgba(225,0,0,0.3)",
            },
            mode: "x" as const,
          },
          mode: "x",
          limits: {
            x: {
              min: 0,
              max: 47,
            },
            y: {
              min: 0,
              max: 2.0,
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        x: {
          stacked: true,
          //labelの表示が斜めにならないように
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            font: { size: 14 },
          },
          grid: {
            display: false,
          },
        },
        y: {
          // y軸複数指定
          stacked: true,
          max: chartMax,
          min: 0,
          ticks: {
            stepSize: chartStep,
            font: { size: 14 },
          },
        },
        y1: {
          // y軸複数指定
          stacked: false,
          position: "right" as const,
          max: chartMax,
          min: 0,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },
    };
  }, [languageContext.words, chartMax, chartStep, PaletteMode]);

  // グラフに表示するデータを抽出する
  const CreateAreaChartData = (
    inputDate: Date,
    areaName: string,
    pastData: resLeftPowerForecast[]
  ) => {
    const powerDatas: number[][] = [];

    // 取得した時間で固定されてしまうので年月日のみの文字列で比較
    const dateStr = inputDate.toLocaleDateString();

    // そのエリアのその日のデータを格納
    let index = 0;
    pastData.forEach((powerPlant) => {
      powerDatas.push([]);
      powerPlant.supply_power_qu.forEach((value) =>
        powerDatas[index].push(Math.floor(value.powerValue))
      );
      index++;
    });

    return powerDatas;
  };

  // 当日の実績データを抽出
  async function CreateActualDayData(
    leftgetdata: resLeftPowerForecast[],
    repower: number
  ) {
    //初期化
    for (let reset = 0; reset < 48; reset++) {
      ConditionalData.actualData[reset] = 0;
      ConditionalData.divergence[reset] = 0;
      ConditionalData.divergencerate[reset] = 0;
    }

    leftgetdata.forEach((power) => {
      power.supply_result.forEach(
        (v, index) =>
          (ConditionalData.actualData[index] += Number(v.powerValue))
      );
      power.divergence.forEach(
        (v, index) =>
          (ConditionalData.divergence[index] += Number(v.powerValue))
      );
      power.divergenceRate.forEach(
        (v, index) =>
          (ConditionalData.divergencerate[index] += Number(v.powerValue))
      );
    });
  }

  // Todo 前日までのみに反応するように
  const CreateAreaPrevData = (
    pastDate: Date,
    pastData: resLeftPowerForecast[],
    type: string
  ) => {
    //console.log("赤グラフの基",pastData);
    //console.log("日付",pastDate);
    // 取得した時間で固定されてしまうので年月日のみの文字列で比較
    //const dateStr = pastDate.toLocaleDateString();

    let CreRedGrpCounter = 0;
    //const prev: number[] = [];
    const areaTotal: number[] = new Array(48).fill(0);

    //if文で実績/計画ラジオボタンの判定追加
    if (type === "b") {
      //計画
      while (CreRedGrpCounter < pastData.length) {
        if (
          new Date(
            pastData[CreRedGrpCounter].tergetDate
          ).toLocaleDateString() === new Date(pastDate).toLocaleDateString()
        ) {
          for (let i = 0; i < 48; i++) {
            areaTotal[i] +=
              pastData[CreRedGrpCounter].supply_power_qu[i].powerValue;
          }
        }
        CreRedGrpCounter++;
      }
    } else if (type === "a") {
      //実績
      while (CreRedGrpCounter < pastData.length) {
        if (
          new Date(
            pastData[CreRedGrpCounter].tergetDate
          ).toLocaleDateString() === new Date(pastDate).toLocaleDateString()
        ) {
          for (let i = 0; i < 48; i++) {
            areaTotal[i] +=
              pastData[CreRedGrpCounter].supply_result[i].powerValue;
          }
        }
        CreRedGrpCounter++;
      }
    }
    /*
    pastData.forEach((power) => {
        power.supply_power_qu .forEach((value) =>
          prev.push(Math.floor(value.powerValue))
        );
        for (let i = 0; i < 48; i++) {
          areaTotal[i] += prev[i];
        }
        prev.splice(0);
    });
    */

    //console.log("赤グラフのデータ部分：",areaTotal)

    return areaTotal;

    // return areaTotal;
  };

  // チャートのデータセットの型
  type datasetType = {
    type: "line" | "bar";
    label: string;
    borderColor: string;
    backgroundColor: string;
    borderWidth: number;
    stepped: "middle" | false;
    data: number[];
    yAxisID: string;
    categoryPercentage: number;
    order: number;
  };

  // エリア単位のグラフに表示するデータの作成
  async function CreateAreaDatasets(
    inputDate: Date,
    pastDate: Date,
    areaName: string,
    pastData: resLeftPowerForecast[]
  ) {
    const datasets: datasetType[] = [];
    const r = 75;
    const g = 192;
    const b = 192;

    console.log("エリア単位グラフ表示pastData確認", pastData);
    // datasetsの中身をpushで作る
    //棒グラフ
    let index = 0;
    //const dateStr = inputDate.toLocaleDateString();
    Array.from(pastData).forEach((power) => {
      //console.log("グラフ", pastData)
      //console.log(power)
      //console.log("発電所名", power.power_nm)
      datasets.push({
        type: "bar" as const, // 棒グラフ
        label: power.power_nm,
        backgroundColor: `rgb(${r * (1 + index)}, ${g * (1 + index)}, ${
          b - index
        })`,
        data: CreateAreaChartData(inputDate, areaName, pastData)[index],
        yAxisID: "y", // Y軸の設定
        borderColor: "",
        borderWidth: 0,
        stepped: false,
        categoryPercentage: 1.0,
        order: 2,
      });
      index++;
    });

    console.log("グラフデータ", datasets);
    dataset.current = datasets;
    //setDatasets(datasets);
  }

  //エリア単位の赤グラフ(右の表1列分)を表示するデータの作成
  //※画面左要素
  //
  async function CreateAreaRedDatasets(
    pastDate: Date,
    pastData: resLeftPowerForecast[],
    type: string
  ) {
    //計画値(赤いグラフ)
    const datasetElement = {
      type: "line" as const,
      label: `${pastDate.getFullYear()}/${(pastDate.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${pastDate.getDate().toString().padStart(2, "0")}`,
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(75, 192, 192, 0)",
      borderWidth: 4,
      stepped: "middle" as const,
      data: CreateAreaPrevData(pastDate, pastData, type), //
      yAxisID: "y1", // optionsで設定したIDを割り振ってY軸を設定する
      categoryPercentage: 1.19, //全てのグラフ一本一本の幅
      order: 1,
    };

    const getMaxHeight = (a: number, b: number) => {
      return Math.max(a, b);
    };
    //const maxHeight = Math.round(datasetElement.data.reduce(getMaxHeight) * 1.1); //計画値の配列で最大の値をグラフ最大高として設定
    //setChartMax(maxHeight);
    //setChartStep(Math.round(maxHeight / 10));
    //chartMax.current = maxHeight;
    //chartStep.current = Math.round(maxHeight / 10);

    const datasets: datasetType[] = [datasetElement];

    //console.log("赤グラフにセットする値", datasets);
    rightAriaoneline.current = datasets;
    //setChartAriaUnitOneline(datasets);
    setIsLoaded(true);
  }

  // 発電所単位画面のグラフに表示するデータセットの作成
  async function CreatePlantDatasets(
    planData: any, //DBから取得したデータ
    predictData: number[], //システム予測データ
    pastData: number[], //
    pastDate: Date //日付
  ) {
    rightAriaoneline.current = [];
    //setChartAriaUnitOneline([]);

    const datasets: datasetType[] = [];
    const datalist: number[] = [];

    console.log("planDataの中身確認", planData);

    if (planData.length >= 0) {
      for (let i = 0; i < 48; i++) {
        datalist[i] = planData[i];
      }
    } else {
      for (let i = 0; i < 48; i++) {
        if (planData.supply_power_qu.length === 0) {
          datalist[i] = 0; //計画がないとき、0埋め
        } else {
          datalist[i] = Number(planData.supply_power_qu[i].powerValue);
        }
      }
    }

    //console.log("datalistの中身確認",datalist);

    // datasetsの中身をpushで作る
    datasets.push({
      type: "line" as const,
      label: languageContext.words.plan_value,
      borderColor: PaletteMode === "dark" ? "aqua" : "blue",
      backgroundColor: "rgba(75, 192, 192, 0)",
      borderWidth: 4,
      stepped: "middle" as const,
      data: datalist,
      yAxisID: "y1", // optionsで設定したIDを割り振ってY軸を設定する
      categoryPercentage: 1.19, //全てのグラフ一本一本の幅
      order: 1,
    });

    // 過去参照ラジオボタン押下時
    if (!(pastData.length === 0) && selectedNumber != -1) {
      datasets.push({
        type: "line" as const,
        label: `${pastDate.getFullYear()}/${(pastDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${pastDate.getDate().toString().padStart(2, "0")}`,
        borderColor: `red`,
        backgroundColor: "rgba(75, 192, 192, 0)",
        borderWidth: 2,
        stepped: "middle" as const,
        data: pastData,
        yAxisID: "y1", // optionsで設定したIDを割り振ってY軸を設定する
        categoryPercentage: 1.19, //全てのグラフ一本一本の幅
        order: 3,
      });
    }

    //console.log("システム予測のdata確認", predictData)

    // システム予測
    datasets.push({
      type: "bar" as const,
      label: languageContext.words.system_forecasting,
      borderColor: "",
      backgroundColor: "rgba(255, 165, 0, 0.7)",
      borderWidth: 0,
      stepped: "middle" as const,
      data: predictData,
      yAxisID: "y", // optionsで設定したIDを割り振ってY軸を設定する
      categoryPercentage: 1, //全てのグラフ一本一本の幅
      order: 2,
    });

    console.log("青グラフ、システム予測の表示内容", datasets);
    rightoneline.current = datasets;
    setIsLoaded(true);
    //setChartPlantUnitOneline(datasets);
  }

  // const dataset = createDatasets();

  // 複数グラフのデータ例
  // 折れ線グラフ + 棒グラフ
  const Creategraphadd = (
    arg1: datasetType[],
    arg2: datasetType[],
    arg3: datasetType[]
  ) => {
    //全てのグラフを一つにまとめる
    const allGraphDataset: datasetType[] = [];
    let arg1count = 0;
    let arg2count = 0;
    let arg3count = 0;

    while (arg1count < arg1.length) {
      allGraphDataset.push(arg1[arg1count]);
      arg1count++;
    }

    //発電所グラフ
    if (power != 0) {
      while (arg2count < arg2.length) {
        allGraphDataset.push(arg2[arg2count]);
        arg2count++;
      }
    }

    //エリア積み上げグラフ
    if (power == 0) {
      while (arg3count < arg3.length) {
        allGraphDataset.push(arg3[arg3count]);
        arg3count++;
      }
    }

    //console.log("セットするグラフ：",allGraphDataset)
    return allGraphDataset;
  };

  const graphData = useMemo(() => {
    const datasets: datasetType[] = [];
    if (power) {
      // 過去実績or計画
      if (selectedNumber != -1) {
        datasets.push({
          type: "line" as const,
          label: languageContext.convertDateToLocalizedString(
            clickDate.current
          ),
          borderColor: `red`,
          backgroundColor: "rgba(75, 192, 192, 0)",
          borderWidth: 2,
          stepped: "middle" as const,
          data: clickData,
          yAxisID: "y1", // optionsで設定したIDを割り振ってY軸を設定する
          categoryPercentage: 1.19, //全てのグラフ一本一本の幅
          order: 3,
        });
      }

      // システム予測
      datasets.push({
        type: "bar" as const,
        label: languageContext.words.system_forecasting,
        borderColor: "",
        backgroundColor: "rgba(255, 165, 0, 0.7)",
        borderWidth: 0,
        stepped: "middle" as const,
        data: predict,
        yAxisID: "y", // optionsで設定したIDを割り振ってY軸を設定する
        categoryPercentage: 1, //全てのグラフ一本一本の幅
        order: 2,
      });

      //発電所単位
      datasets.push({
        type: "line" as const,
        label: languageContext.words.planned_gen_value, //グラフ内ラベル計画値
        borderColor: PaletteMode === "dark" ? "aqua" : "blue",
        backgroundColor: "rgba(75, 192, 192, 0)",
        borderWidth: 4,
        stepped: "middle" as const,
        data: planData,
        yAxisID: "y1", // optionsで設定したIDを割り振ってY軸を設定する
        categoryPercentage: 1.19, //全てのグラフ一本一本の幅
        order: 1,
      });
    } else {
      //エリア単位
      const r = 75;
      const g = 192;
      const b = 192;

      //棒グラフ
      leftData.forEach((power, index) => {
        datasets.push({
          type: "bar" as const, // 棒グラフ
          label: power.power_nm,
          backgroundColor: `rgb(${r * (1 + index)}, ${g * (1 + index)}, ${
            b - index
          })`,
          data: power.supply_power_qu.map((v) => Number(v.powerValue)),
          yAxisID: "y", // Y軸の設定
          borderColor: "",
          borderWidth: 0,
          stepped: false,
          categoryPercentage: 1.0,
          order: 2,
        });
      });
    }

    return {
      labels: forcastGraphLabels,
      datasets: datasets,
    };
  }, [
    languageContext,
    PaletteMode,
    planData,
    selectedNumber,
    clickData,
    predict,
  ]);

  //数値をカンマ区切りに変換
  const convertToLocaleString = (value: number) => {
    return Number(value).toLocaleString();
  };

  const CurrentDayPower = () => {
    let daypower = 0;
    for (let i = 0; i < planData.length; i++) {
      if (planData[i] == -1) {
        daypower = Number(daypower) + 0;
      } else {
        daypower = Number(daypower) + Number(planData[i]);
      }
    }
    return daypower.toLocaleString();
  };

  // tableCellの背景色決定
  const CellColor = (i: number) => {
    const cellColor = IsOneHourLate(i)
      ? PaletteMode === "dark"
        ? "rgb(90,90,90)"
        : "rgb(200,200,200)"
      : "";
    return cellColor;
  };

  //テキストフィールドに想定しない値がぺーストされてしまわないように制限
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData.getData("text/plain");
    if (!pastedText) {
      return;
    }
    // text を正規表現で分割して配列にする
    const textArray = pastedText.split(/ |\u3000/);
    textArray.forEach((item) => {
      // 数字以外がマッチしたらペーストできない
      if (!item.match(/^[0-9]*$/)) {
        e.preventDefault();
      }
    });
  };

  // テーブルの列データ
  const records = () => {
    const datas = [];
    const newPlanDatas: number[] = [];

    const onWheelHandler = (e: React.WheelEvent<HTMLInputElement>) => {
      e.currentTarget.blur();
      e.stopPropagation();
    };

    for (let i = 0; i < 48; i++) {
      const disable = textFieldDisable;
      if (IsOneHourLate(i) || power === 0) {
        //1時間前までまたはエリア指定のとき、入力不可
        disable[i] = true;
      } else {
        disable[i] = false;
      }

      datas.push(
        <TableRow sx={{ height: "29.49px" }}>
          {/*時刻の列*/}
          <StyledTableCell
            sx={{
              padding: 0,
              // paddingRight: "1px",
              textAlign: "center",
              width: "95px",
            }}
            style={{ height: "30px", backgroundColor: CellColor(i) }}
          >
            {`${cellTime[i]}～${cellTime[i + 1]}`}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              padding: 0,
              textAlign: "center",
              minWidth: "74px",
              height: "30px",
            }}
            style={{ borderRight: "none", backgroundColor: CellColor(i) }}
          >
            <TextField
              sx={{ margin: "2px" }}
              InputProps={{
                // inputMode: "numeric",
                // pattern: "^[1-9][0-9]*$",
                inputProps: {
                  min: 0,
                  style: {
                    padding: 0,
                    height: "30px",
                    textAlign: "right",
                    backgroundColor: CellColor(i),
                  },
                },
              }}
              type="number"
              disabled={disable[i]}
              onWheel={onWheelHandler}
              onKeyDown={(event) => {
                if (event.key === "." || event.key === "-") {
                  event.preventDefault();
                }
              }}
              onPaste={handlePaste} //負の値などのペースト制限
              value={
                planData[i] <= -1 || planData[i] === undefined
                  ? ""
                  : planData[i].toFixed(0)
              }
              onFocus={(e) => {
                if (Number(planData[i]) === 0) {
                  e.currentTarget.select();
                }
              }}
              onChange={(event) => {
                //セルに変更が入った際、setPlanDataのuseStateにセットする
                //ここで契約電力との比較
                //変更後計画値を含めた計画値全量を取得
                //console.log("発電計画画面のオンチェンジイベント(計画値変更)");

                leavePageContext.setBlockLeave(true);

                //console.log(leavePageContext);
                planData.forEach((planData, index) => {
                  newPlanDatas.push(planData);
                  if (index === i) {
                    newPlanDatas[i] = Number(event.target.value);
                  }
                });

                let sumcount = 0;
                let sumvalue = 0;
                while (sumcount < newPlanDatas.length) {
                  sumvalue += Number(newPlanDatas[sumcount]);
                  sumcount++;
                }

                //計画合計値が契約電力より大きいとき
                //if(contractpower < sumvalue){
                //  setDialogMessage("契約値を超える計画はできません。");
                //  setDigOpen(true);
                //}else{
                //  setPlanData(
                //    planData.map((planData, index) =>
                //      index === i ? Number(event.target.value) : planData
                //    )
                //  );

                //入力制限6桁(isMaxLength)
                if (isMaxLength(event.target.value)) {
                  const tempPlanData = [...planData];
                  tempPlanData[i] = Number(
                    Number(event.target.value).toFixed(0)
                  );
                  setPlanData(tempPlanData);
                }

                /*CreatePlantDatasets(
                  planData.current, //計画値
                  predict.current, //システム予測
                  clickData.current,
                  clickDate.current
                );
                  forceUpdate();*/
                //発電所単位の時、コマの値を変更した際にグラフを変動させる
              }}
            />
          </StyledTableCell>
        </TableRow>
      );
    }
    //作成した時刻列、発電計画列を返却
    return datas;
  };

  // テーブルのヘッダーのデータ(日付)
  const TableHeaderDate = () => {
    const tableHeaderData: Date[] = [];
    for (let i = 0; i < 7; i++) {
      tableHeaderData.push(subDays(pastDate, i));
    }
    return tableHeaderData;
  };

  // 条件に合った日量のデータを抽出 表示する過去日付分順番に呼び出す
  const CreateDayPower = (
    i: number
    //date: Date,
    //areaData: resPowerActualPlan[]
  ) => {
    // 取得した時間で固定されてしまうので年月日のみの文字列で比較
    const dateStr = subDays(pastDate, i).toLocaleDateString();
    //console.log("日付をstringに変換したもの", dateStr);

    let dayPowerValues: any = 0;
    /*
    if(pastData[i] == undefined){
      return '-';
    }
*/

    let Daycount = 0;

    while (Daycount < pastData.length) {
      //日付の比較確認
      //console.log("日量表示の日付確認", dateStr, new Date(pastData[Daycount].tergetDate).toLocaleDateString());
      if (
        dateStr == new Date(pastData[Daycount].tergetDate).toLocaleDateString()
      ) {
        //
        if (selectedValue == "a" && pastData[Daycount] != undefined) {
          //実績の時
          for (
            let actualC = 0;
            actualC < pastData[Daycount].supply_result.length;
            actualC++
          ) {
            if (pastData[Daycount].supply_result.length == 0) {
              return "-";
            }
            dayPowerValues =
              Number(dayPowerValues) +
              Number(pastData[Daycount].supply_result[actualC].powerValue);
          }
        } else if (selectedValue == "b" && pastData[Daycount] != undefined) {
          //計画の時
          for (
            let planC = 0;
            planC < pastData[Daycount].supply_power_qu.length;
            planC++
          ) {
            if (pastData[Daycount].supply_power_qu.length == 0) {
              return "-";
            }
            dayPowerValues =
              Number(dayPowerValues) +
              Number(pastData[Daycount].supply_power_qu[planC].powerValue);
          }
        }
      }
      Daycount++;
    }

    if (dayPowerValues == 0) {
      dayPowerValues = "-";
    }

    return dayPowerValues.toLocaleString();
  };

  // 条件に合った契約電力量のデータを抽出
  const CreatePower = (
    i: number,
    date: Date,
    areaData: resPowerActualPlan[]
  ) => {
    // 取得した時間で固定されてしまうので年月日のみの文字列で比較
    const dateStr = subDays(date, i).toLocaleDateString();

    let dayPowerValues: any = 0;
    // そのエリアのその発電所のその日のデータをcopyDataに格納
    areaData.forEach((plantpower) => {
      dayPowerValues = plantpower.supply_power_qu;
    });

    if (dayPowerValues === 0) {
      // データがない場合
      dayPowerValues = "-";
    }

    return dayPowerValues;
  };

  // レンダリング
  // テーブル
  const PlanTable = () => {
    return (
      <StyledTable
        stickyHeader
        aria-label="sticky table"
        sx={{
          maxWidth: "170px",
          left: 0,
          padding: "0px",
          borderRight: "none",
          height: "100%",
          zIndex: 0,
        }}
        style={{ borderRight: "none" }}
      >
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
            padding: "0px",
            zIndex: 1,
            display: "block",
          }}
        >
          <TableRow sx={{ height: "122px" }}>
            <StyledTableHeaderLG
              sx={{
                padding: "0px",
                textAlign: "center",
                width: "95px",
                height: "147.44px",
              }}
            >
              {languageContext.words.timeB}
            </StyledTableHeaderLG>
            <StyledTableHeaderLG
              sx={{
                padding: "0px",
                textAlign: "center",
                minWidth: "74px",
                height: "147.44px",
              }}
              style={{
                borderRight: "none",
              }}
            >
              {languageContext.words.planned_gen_value}
              (kWh)
            </StyledTableHeaderLG>
          </TableRow>
          <TableRow id="contract-power">
            <StyledTableHeaderG
              sx={{
                padding: "0px",
                textAlign: "center",
                maxWidth: "87px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              title={languageContext.words.contracted_power + "(kW)"}
            >
              {languageContext.words.contracted_power}(kW)
            </StyledTableHeaderG>
            <StyledTableHeaderG
              sx={{
                padding: "0px",
                paddingRight: "8px",
                textAlign: "right",
                width: "65px",
                height: "34px",
              }}
              style={{
                borderRight: "none",
              }}
            >
              {contractpower ? convertToLocaleString(contractpower) : "-"}{" "}
              {/*todo useStateで持たせる*/}
            </StyledTableHeaderG>
          </TableRow>
          <TableRow id="contract-power">
            <StyledTableHeaderG
              sx={{
                padding: "0px",
                textAlign: "center",
                minWidth: "95px",
                maxWidth: "95px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              title={languageContext.words.daily_amount + "(kWh)"}
            >
              {languageContext.words.daily_amount}(kWh)
            </StyledTableHeaderG>
            <StyledTableHeaderG
              sx={{
                padding: "0px",
                paddingRight: "8px",
                textAlign: "right",
                width: "65px",
                height: "34px",
              }}
              style={{
                borderRight: "none",
              }}
            >
              {CurrentDayPower()} {/*useStateで持たせる*/}
            </StyledTableHeaderG>
          </TableRow>
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflowY: "scroll",
              height: "558px",
              "&::-webkit-scrollbar": {
                width: "0px",
                backgroundColor: "transparent",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#AAA",
                borderRadius: "0px",
              },
            }}
          >
            {records()} {/*ボディ部分のレコード(計画値)を表示*/}
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>
    );
  };

  //システム予測関連*****************************************

  async function SerchPredictData() {
    interface searchParams {
      power_id: number;
      forecast_time: string | undefined;
    }
    const Params: searchParams = {
      power_id: POWERPLANT_ID,
      forecast_time: context.pickDate?.toLocaleDateString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    };

    //console.log("日射量のリクエストパラメータ",Params);

    //axiosでget
    setIsLoadingPredict(true);
    api.post(SOLAR_RADIATION_GET_URL, Params).then((res) => {
      setIsLoadingPredict(false);
      const tempPredict: number[] = Array(48).fill(0);

      ///console.log("システム予測値", res.data);

      let incount = 0;

      while (incount < res.data.length) {
        tempPredict[incount] = Number(res.data[incount].solar_radiation);
        incount++;
      }

      //console.log("作成した日射量の配列。これを基にグラフと予測値表示する", tempPredict);

      setPredict(tempPredict);
    });
  }

  // システム予測を表示するテーブル (不要)

  const PredictTable = () => {
    const inputs = [];
    for (let i = 0; i < 48; i++) {
      inputs.push(
        <TableRow>
          <StyledTableCell
            sx={{
              padding: 0,
              paddingRight: "8px",
              textAlign: "right",
              height: "34px",
              minWidth: "64.1px",
            }}
            style={{ borderRight: "none", backgroundColor: CellColor(i) }}
          >
            {convertToLocaleString(predict[i] || 0)}
          </StyledTableCell>
        </TableRow>
      );
    }

    // 再予測
    const rePrediction = () => {
      // システム予測の実行
      SerchPredictData();
    };

    return (
      <StyledTable
        stickyHeader
        aria-label="sticky table"
        sx={{
          left: 0,
          padding: "0px",
          borderRight: "none",
          height: "100%",
          zIndex: 0,
          display: "block",
          width: "73.9px",
        }}
        style={{ borderRight: "none" }}
      >
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
            padding: "0px",
            zIndex: 1,
          }}
        >
          <TableRow sx={{ height: "68px" }}>
            <TableCell
              sx={{
                backgroundColor: PaletteMode === "dark" ? "#373B3F" : "#E7ECF8",
                padding: "0px",
                textAlign: "center",
                color: "#00a1e9",
                height: "81.44px",
                width: "73.9px",
                borderRight: "none",
              }}
            >
              {languageContext.words.system}
              <br />
              {languageContext.words.forecasting}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: PaletteMode === "dark" ? "#373B3F" : "#E7ECF8",
                padding: "0px",
                textAlign: "center",
                height: "32px",
                width: "73.9px",
                borderRight: "none",
              }}
            >
              <Button
                style={{
                  borderColor: "#00000000",
                  backgroundColor: 
                    disableControl === false 
                      ? (PaletteMode === "dark" 
                          ? "#90CAF9" 
                          : "rgb(0, 100, 200)" ) 
                      : (PaletteMode === "dark" 
                          ? "rgba(255, 255, 255, 0.12)" 
                          : "rgba(0, 0, 0, 0.12)"),
                  color: 
                    disableControl === false 
                      ? (PaletteMode === "dark" 
                          ? "black" 
                          : "white") 
                      : (PaletteMode === "dark" 
                          ? "rgba(255, 255, 255, 0.3)" : "rgba(0, 0, 0, 0.26)"),
                  padding: "0px",
                }}
                disabled={disableControl}
                onClick={rePrediction}
              >
                {languageContext.words.reforecast}
              </Button>
            </TableCell>
          </TableRow>
          <TableRow id="forecast-copy">
            <TableCell
              sx={{
                backgroundColor: PaletteMode === "dark" ? "#373B3F" : "#E7ECF8",
                padding: "0px",
                textAlign: "center",
                height: "32px",
                borderRight: "none",
              }}
            >
              <StyledButton
                style={{
                  //システム予測の複写ボタン
                  color: 
                    disableControl === false 
                      ? "#00a1e9"
                      : (PaletteMode === "dark"
                          ? "rgba(255, 255, 255, 0.3)"
                          : "rgba(0, 0, 0, 0.26)"),
                  borderColor: 
                    disableControl === false 
                      ? "#00a1e9"
                      : (PaletteMode === "dark"
                          ? "rgba(255, 255, 255, 0.3)"
                          : "rgba(0, 0, 0, 0.26)"),
                  fontSize: "13px",
                  height: "24px",
                }}
                disabled={disableControl}
                onClick={async () => {
                  // テキストフィールドが非活性のコマのデータは上書きしないようにデータ作成
                  const data: number[] = [];
                  planData.forEach((planData, index) => {
                    if (IsOneHourLate(index)) {
                      data.push(planData);
                    } else {
                      data.push(predict[index]);
                    }
                  });
                  //setPlanData(data);
                  setPlanData(data);
                  /*await CreatePlantDatasets(
                    planData.current, //計画値
                    predict.current, //システム予測
                    clickData.current,
                    clickDate.current
                  );
                  forceUpdate();*/
                }}
              >
                {languageContext.words.copy}
              </StyledButton>
            </TableCell>
          </TableRow>
          <TableRow id="contract-power">
            <StyledTableHeaderG
              sx={{
                padding: "0px",
                paddingRight: "8px",
                textAlign: "right",
                height: "34px",
              }}
              style={{
                borderRight: "none",
              }}
            >
              {"-" /*契約電力*/}
            </StyledTableHeaderG>
          </TableRow>
          <TableRow>
            <StyledTableHeaderG
              sx={{
                padding: "0px",
                paddingRight: "8px",
                textAlign: "right",
                height: "34px",
              }}
              style={{
                borderRight: "none",
              }}
            >
              {"-" /*日量(システム予測の合算値)*/}
            </StyledTableHeaderG>
          </TableRow>
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflowY: "scroll",
              height: "558px",
              "&::-webkit-scrollbar": {
                width: "0px",
                backgroundColor: "transparent",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#AAA",
                borderRadius: "0px",
              },
            }}
          >
            {inputs}
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>
    );
  };

  //***************************************************** */

  // 当日を選択時のみ表示するテーブル
  const CurrentDateTable = () => {
    // 乖離パーセント計算
    const CalculateDeviation = (data: number, index: number) => {
      let deviation: any = "";

      if (data === -1 || planData[index] === -1) {
        deviation = "";
      } else if (data === 0) {
        //deviation = Math.abs(
        //  Math.floor(((planData.current[index] - data) / 1) * 100 * Math.pow(10, 2)) /
        //    Math.pow(10, 2)
        //);
        deviation = "0.00";
      } else {
        //deviation = Math.abs(
        deviation =
          Math.floor(
            ((data - planData[index]) / data) * 100 * Math.pow(10, 2)
          ) / Math.pow(10, 2);
        deviation = Number(deviation).toFixed(2);
        //);
      }

      return deviation;
    };

    const DicideTextColor = (num: number) => {
      let textColor = "";
      if (num > 0) {
        textColor = PaletteMode === "dark" ? "aqua" : "Blue";
      } else if (num < 0 && num != -Infinity) {
        textColor = "Red";
      } else {
        textColor = PaletteMode === "dark" ? "white" : "black"; //0,-Infinityのとき
      }

      return textColor;
    };

    //console.log("bodyに表示する中身の確認(実績/乖離/乖離%)",ConditionalData);

    //bodyに表示する部分の作成
    const inputs: JSX.Element[] = [];
    ConditionalData.actualData.map((data, index) => {
      //実績のmap
      inputs.push(
        <TableRow>
          <StyledTableCell
            sx={{
              padding: 0,
              paddingRight: "8px",
              textAlign: "right",
              minWidth: "60px",
              height: "34px",
            }}
            style={{ backgroundColor: CellColor(index) }}
          >
            {data === -1 ? "" : convertToLocaleString(data)}
            {/* 実績値 */}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              padding: 0,
              paddingRight: "8px",
              textAlign: "right",
              minWidth: "60px",
              height: "34px",
              color: DicideTextColor(data - planData[index]), //マイナスだったら赤文字
            }}
            style={{ backgroundColor: CellColor(index) }}
          >
            {data === -1 || planData[index] === -1
              ? ""
              : convertToLocaleString(
                  Math.floor((data - planData[index]) * Math.pow(10, 2)) /
                    Math.pow(10, 2)
                )}
            {/* 乖離 */}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              padding: 0,
              paddingRight: "8px",
              textAlign: "right",
              minWidth: "60px",
              height: "34px",
              color: DicideTextColor(
                Math.floor(
                  ((data - planData[index]) / data) * 100 * Math.pow(10, 2)
                ) / Math.pow(10, 2)
              ),
            }}
            style={{
              borderRight: "none",
              backgroundColor: CellColor(index),
            }}
          >
            {CalculateDeviation(data, index)}
          </StyledTableCell>
        </TableRow>
      );
    });

    return (
      <StyledTable
        stickyHeader
        aria-label="sticky table"
        sx={{
          maxWidth: "180px",
          left: 0,
          padding: "0px",
          borderRight: "none",
          height: "100%",
          zIndex: 0,
        }}
        style={{ borderRight: 0 }}
      >
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
            padding: "0px",
            zIndex: 1,
            display: "block",
          }}
        >
          <TableRow sx={{ height: "122px" }}>
            <StyledTableHeaderLG
              sx={{
                padding: "0px",
                textAlign: "center",
                width: "62px",
                height: "147.44px",
              }}
            >
              {languageContext.words.results_jissekichi}
              <br />
              (kWh)
            </StyledTableHeaderLG>
            <StyledTableHeaderLG
              sx={{
                padding: "0px",
                textAlign: "center",
                width: "62px",
              }}
            >
              {languageContext.words.deviation}
              <br />
              (kWh)
            </StyledTableHeaderLG>
            <StyledTableHeaderLG
              sx={{
                padding: "0px",
                textAlign: "center",
                width: "62px",
              }}
              style={{ borderRight: "none" }}
            >
              {languageContext.words.deviation_percent}
            </StyledTableHeaderLG>
          </TableRow>
          {(() => {
            const rowElement = [];
            for (let i = 0; i < 2; i++) {
              rowElement.push(
                <TableRow id="contract-power">
                  {(() => {
                    const element = [];
                    for (let j = 0; j < 3; j++) {
                      element.push(
                        <StyledTableHeaderG
                          key={`当日${i} ${j}`}
                          sx={{
                            padding: "0px",
                            paddingRight: "8px",
                            textAlign: "right",
                            width: "60px",
                            height: "34px",
                          }}
                          style={{
                            borderRight: j === 2 ? "none" : "",
                          }}
                        >
                          -
                        </StyledTableHeaderG>
                      );
                    }
                    return element;
                  })()}
                </TableRow>
              );
            }
            return rowElement;
          })()}
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflowY: "scroll",
              height: "558px",
              "&::-webkit-scrollbar": {
                width: "0px",
                backgroundColor: "transparent",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#AAA",
                borderRadius: "0px",
              },
            }}
          >
            {inputs}
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>
    );
  };

  // ラジオボタン操作時
  const handleChange = (
    index: number
    // event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedNumber(index);
    //画面右日付からindexを引いた日付すなわちラジオボタンで選択している列の日付をセット
    const dateStr = subDays(pastDate, index).toLocaleDateString();
    console.log("ラジオボタンで選択中の日付", dateStr);
    // ラジオボタンで選択した日のデータをclickDataに格納
    const _clickData: number[] = [];
    let serchDate = 0;
    while (serchDate < pastData.length) {
      if (
        new Date(pastData[serchDate].tergetDate).toLocaleDateString() ===
        dateStr
      ) {
        for (let i = 0; i < 48; i++) {
          if (selectedValue === "a") {
            //実績
            if (pastData[serchDate].supply_result[i] != undefined) {
              _clickData.push(pastData[serchDate].supply_result[i].powerValue);
            } else {
              _clickData.push(0);
            }
          } else if (selectedValue === "b") {
            //計画
            if (pastData[serchDate].supply_power_qu[i] != undefined) {
              _clickData.push(
                pastData[serchDate].supply_power_qu[i].powerValue
              );
            } else {
              _clickData.push(0);
            }
          }
        }
      }
      serchDate++;
    }

    //console.log("ラジオボタン押下時ペースト用データ作成",clickData);
    setClickData(_clickData); //ラジオボタンで選択した日付の48コマをセット

    //setClickDate(subDays(pastDate, index)); //セットしているデータの日付をセット
    clickDate.current = subDays(pastDate, index);
    console.log(
      "ラジオボタン押下時",
      clickDate.current,
      subDays(pastDate, index),
      index
    );

    //RegetRightSetData(area, powerBg, power, selectedValue, pastDate/*clickDate.current*/);
    //ラジオボタンで選択した日付のデータをグラフ表示する
    /*CreatePlantDatasets(planData.current, predict.current, _clickData, subDays(pastDate, index));
    forceUpdate();*/
  };

  // 過去参照値を表示するテーブル
  const PastReferenceTable = () => {
    const setpower = [];
    //displayNum = 表示対象列の数。当日 = 4 過去 or 未来日 = 7

    //契約電力セット
    for (let i = 0; i < displayNum; i++) {
      setpower.push(
        <StyledTableHeaderG
          sx={{
            padding: "0px",
            paddingRight: "8px",
            zIndex: 0,
            textAlign: "right",
            height: "34px",
            minWidth: "70px",
          }}
        >
          {contractpower ? convertToLocaleString(contractpower) : "-"}{" "}
          {/*DBから持ってくる*/}
        </StyledTableHeaderG>
      );
    }

    //発電日量セット
    const dayPower = [];
    //console.log("〇〇〇pastDataの確認〇〇〇", pastData, pastData.length);
    for (let i = 0; i < displayNum; i++) {
      dayPower.push(
        <StyledTableHeaderG
          sx={{
            padding: "0px",
            paddingRight: "8px",
            zIndex: 0,
            textAlign: "right",
            height: "34px",
            minWidth: "70px",
          }}
        >
          {CreateDayPower(i)}{" "}
          {/*todo 計算して合計値を出す 計算関数作成 戻り値push*/}
        </StyledTableHeaderG>
      );
    }

    //ヘッダーデータの生成（過去参照日）
    //日付セット 最大7日分
    const headerDate = [];
    for (let i = 0; i < displayNum; i++) {
      headerDate.push(
        <StyledTableHeaderLG
          sx={{
            padding: "1px",
            zIndex: 0,
            textAlign: "center",
            minWidth: "70px",
            height: "30px",
            width: "96.2px",
          }}
        >
          {languageContext.convertDateToLocalizedString(TableHeaderDate()[i])}
        </StyledTableHeaderLG>
      );
    }

    // ラジオボタンの生成
    const radioButton = [];
    for (let i = 0; i < displayNum; i++) {
      radioButton.push(
        <StyledTableHeaderLG
          key={`radioGroup${i}`}
          sx={{
            padding: "0px",
            zIndex: 0,
            textAlign: "center",
            height: "32px",
            minWidth: "70px",
          }}
        >
          <Radio
            checked={selectedNumber === i}
            onChange={(event) => {
              handleChange(i);
            }}
            value={i.toString()}
            name="radio-buttons"
            inputProps={{ "aria-label": `${i}` }}
            sx={{
              minWidth: "auto",
              "& .MuiSvgIcon-root": {
                fontSize: 17,
              },
            }}
            disabled={returndisable()} //表示するか否か
            style={{ padding: "1px" }}
          />
        </StyledTableHeaderLG>
      );
    }

    //模写ボタンの生成
    const reproductionButton = [];
    for (let i = 0; i < displayNum; i++) {
      reproductionButton.push(
        <StyledTableHeaderLG
          key={`copyPastData ${i}`}
          sx={{
            padding: "0px",
            zIndex: 0,
            textAlign: "center",
            height: "32px",
            minWidth: "70px",
          }}
        >
          <StyledButton
            sx={{ height: "20px" }}
            style={{
              color: 
                disableControl === false 
                  ? "#00a1e9"
                  : (PaletteMode === "dark"
                      ? "rgba(255, 255, 255, 0.3)"
                      : "rgba(0, 0, 0, 0.26)"),
              borderColor:
                disableControl === false 
                  ? "#00a1e9"
                  : (PaletteMode === "dark"
                      ? "rgba(255, 255, 255, 0.3)"
                      : "rgba(0, 0, 0, 0.26)"),
              height: "13px",
            }}
            disabled={disableControl}
            onClick={() => {
              copyDayData(i); //複写ボタン押下時のイベント
            }}
          >
            {languageContext.words.copy}
          </StyledButton>
        </StyledTableHeaderLG>
      );
    }

    // 選択した発電所の過去参照用データを抽出
    // pastDate : 右側表ので選択した日付
    // pastData : 右側表48コマ分の配列を持ったやつ
    // index    : 48コマの何コマ目かの情報を持つ
    const CreateTableData = (i: number, index: number) => {
      let tableData;
      //subDays 指定日数を減らす。
      //dateStr : 表の列の日付
      const dateStr = subDays(pastDate, i).toLocaleDateString();
      pastData.forEach((power) => {
        const dateStr2 = new Date(power.tergetDate).toLocaleDateString();
        //console.log("比較している日付の確認",dateStr2, dateStr)
        if (new Date(power.tergetDate).toLocaleDateString() === dateStr) {
          if (!power.supply_power_qu.length) {
            if (index < 48) {
              if (power.supply_result[index] != undefined) {
                tableData = Number(
                  Math.floor(power.supply_result[index].powerValue)
                ).toLocaleString(); //実績値
              } else {
                tableData = 0 as number;
              }
            } else {
              tableData = 0 as number;
            }
          } else {
            if (index < 48) {
              if (power.supply_power_qu[index] != undefined) {
                tableData = Number(
                  Math.floor(power.supply_power_qu[index].powerValue)
                ).toLocaleString(); //計画値
              } else {
                tableData = 0 as number;
              }
            } else {
              tableData = 0 as number;
            }
          }
        }
      });
      //console.log("body部表示データの取得：",dateStr,index,tableData);
      return tableData;
    };

    // 過去参照のテーブルボディ部分
    const PastReferenceTableData = () => {
      // Todo {kushibiki} Array().fillでない方法検討
      return Array(48)
        .fill(0)
        .map((value, index) => {
          return (
            <TableRow key={`values${index}`}>
              {(() => {
                const tableData = [];
                // Todo {kushibiki} スクロールバーのzindexを変えて前に出せるか確認
                //画面に出す日付分loopする。4 or 7
                for (let i = 0; i < displayNum; i++) {
                  let width;
                  if (displayNum === 7) {
                    width = i === 6 ? 80 : 97;
                  } else {
                    width = i === 3 ? 109 : 124;
                  }
                  //実際にbody部分のデータをセットしているところ
                  tableData.push(
                    <StyledTableCell
                      key={`tableValue${index}${i}`}
                      sx={{
                        height: "34px",
                        textAlign: "right",
                        padding: 0,
                        paddingRight: "8px",
                        minWidth: "70px",
                        width: `${width}px`,
                      }}
                      style={{ backgroundColor: CellColor(index) }}
                    >
                      {CreateTableData(i, index)}
                    </StyledTableCell>
                  );
                }
                return tableData;
              })()}
            </TableRow>
          );
        });
    };
    return (
      <StyledTable
        stickyHeader
        aria-label="sticky table"
        sx={{
          Width: "100%",
          left: 0,
          padding: "0px",
          borderRight: "none",
          height: "100%",
          zIndex: 0,
        }}
        style={{ borderRight: 0 }}
      >
        {/* <table id="demand-table" border={1}> */}
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
            padding: "0px",
            zIndex: 1,
            display: "block",
          }}
        >
          <TableRow>
            <StyledTableHeaderLG
              sx={{
                height: "48.44px",
                textAlign: "center",
                padding: 0,
                width: "672.5px",
              }}
              colSpan={displayNum}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                color={"black"}
                alignItems={"center"}
              >
                <FormControlLabel
                  value="end"
                  control={
                    <Radio
                      //実績、計画の切り替えラジオボタン「実績」押下時
                      checked={selectedValue === "a"}
                      onChange={(event) => {
                        setSelectedValue(event.target.value);
                      }}
                      value="a"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 17,
                        },
                      }}
                      style={{ padding: "4px" }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "14px" }}>
                      {languageContext.words.results}
                    </Typography>
                  }
                  sx={{ marginX: "0px" }}
                />
                <FormControlLabel
                  value="end"
                  control={
                    <Radio
                      //実績・計画の切り替えラジオボタン「計画」押下時
                      checked={selectedValue === "b"}
                      onChange={(event) => {
                        //console.log("計画押下時");
                        setSelectedValue(event.target.value);
                      }}
                      value="b"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                      sx={{
                        minWidth: "auto",
                        "& .MuiSvgIcon-root": {
                          fontSize: 17,
                        },
                      }}
                      style={{ padding: "4px" }}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "14px" }}>
                      {languageContext.words.plan}
                    </Typography>
                  }
                  sx={{ marginX: "0px" }}
                />
                <Typography
                  sx={{
                    paddingLeft: "8px",
                    fontSize: "14px",
                    color: PaletteMode === "dark" ? "lightgray" : "black",
                  }}
                >
                  {languageContext.words.display_start_date}
                </Typography>
                <CustomDatePickerTryal setValue={ChangePastDate} />{" "}
                {/*実績、計画ラジオボタン横の日付オブジェクト*/}
              </Box>
            </StyledTableHeaderLG>
          </TableRow>
          <TableRow>{headerDate}</TableRow>
          <TableRow>{radioButton}</TableRow>
          <TableRow>{reproductionButton}</TableRow>
          <TableRow>{setpower}</TableRow>
          <TableRow>{dayPower}</TableRow>
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              // position: "fixed",
              overflowY: "scroll",
              height: "558px",
              "&::-webkit-scrollbar": {
                width: "10px",
                backgroundColor:
                  PaletteMode === "dark" ? "#5e5e5e" : "transparent",
                height: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: PaletteMode === "dark" ? "white" : "#AAA",
                borderRadius: "10px",
              },
            }}
          >
            {PastReferenceTableData()}
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>
    );
  };

  //模写ボタン押下時
  const copyDayData = (i: number) => {
    const copyData: number[] = [];

    // 取得した時間で固定されてしまうので年月日のみの文字列で比較
    const dateStr = subDays(pastDate, i).toLocaleDateString();

    // そのエリアのその発電所のその日のデータをcopyDataに格納
    let serchDate = 0;
    while (serchDate < pastData.length) {
      if (
        new Date(pastData[serchDate].tergetDate).toLocaleDateString() ===
        dateStr
      ) {
        for (let i = 0; i < 48; i++) {
          if (selectedValue === "a") {
            //実績
            if (pastData[serchDate].supply_result[i] != undefined) {
              copyData.push(
                Number(pastData[serchDate].supply_result[i].powerValue)
              );
            } else {
              copyData.push(0);
            }
          } else if (selectedValue === "b") {
            //計画
            if (pastData[serchDate].supply_power_qu[i] != undefined) {
              copyData.push(
                Number(pastData[serchDate].supply_power_qu[i].powerValue)
              );
            } else {
              copyData.push(0);
            }
          }
        }
      }
      serchDate++;
    }

    if (copyData.length === 0) {
      // データがない場合
      for (let i = 0; i < 48; i++) {
        copyData.push(0);
      }
    }

    // テキストフィールドが非活性のコマのデータは上書きしないようにデータ作成
    const data: number[] = [];
    planData.forEach((planData, index) => {
      if (IsOneHourLate(index)) {
        data.push(planData); //非活性のコマなのでもともとのコマデータをセット
      } else {
        data.push(copyData[index]); //活性中のコマなのでコピーしたコマデータをセット
      }
    });

    setPlanData(data);
    /*CreatePlantDatasets(planData.current, predict.current, clickData.current, clickDate.current);
    forceUpdate();*/
    //RegetRightSetData(area, powerBg, power, selectedValue, pastDate/*clickDate.current*/);
  };

  // 当日なら乖離などのテーブルをレンダーする
  const ConditionalCurrentTableRender = () => {
    const toDate = new Date();

    if (inputDate.toLocaleDateString() === toDate.toLocaleDateString()) {
      return CurrentDateTable();
    }
  };

  // 入力日付変更時(Global)
  const ChangeInputDate = async () => {
    if (!(context.pickDate === null)) {
      setInputDate(context.pickDate);

      const toDate = new Date();

      //変更日付が「今日」であれば乖離等を表示するため表示列数を4列に変更する
      if (
        context.pickDate.toLocaleDateString() === toDate.toLocaleDateString()
      ) {
        setDisplayNum(4);
      } else {
        setDisplayNum(7);
      }
      //初回レンダリング時は実行しない(area:0,Bg:0,power:0でreqされることを防ぐ)
      const datecount = 0;
      if (datecount < useEffectCount) {
        //グラフと計画値セット
        SerchPredictData();

/*         await RegetLeftSetData(areaId, powerBg, power, context.pickDate);
        //ここで右も取得
        await RegetRightSetData(
          areaId,
          powerBg,
          power,
          selectedValue,
          pastDate
        ); */
        const tmpAreaList = areaId === "00" ? areaList.filter((e) => e !== "00") : areaList.filter((e) => e === areaId);
        await getPowerPlan(tmpAreaList, powerBg, power);// 発電計画取得
      }
      setCount(useEffectCount + 1);
      console.log("入力日付の変更,カウンター確認", useEffectCount);
    }
  };

  //画面左側日付操作時にコール
  useEffect(() => {
    console.log("左日付操作");
    resetWeather(); //天気情報リセット
   // SetWeatherInfo(); //天気情報セット
    ChangeInputDate();
    firstlender_flag.current = false;
  }, [context.pickDate]);

  // 過去参照データ変更時
  useEffect(() => {
    if(!firstlenderRight_flag.current){
      getRightPowerValue(areaId, powerBg, power);
    }
    firstlenderRight_flag.current = false;
  }, [pastDate, selectedValue]);

  //GCタイミングで保存していない入力値をリセット
  useEffect(() => {
    if (!planData.length || !savediff.current.length) return;
    const temp = planData.map((v, i) =>
      IsOneHourLate(i) ? savediff.current[i] : v
    );
    setPlanData(temp);
  }, [time]);

  // 画面右側日付変更時の処理
  const ChangePastDate = async (pastDate: Date) => {
    setPastDate(pastDate);
    clickDate.current = subDays(pastDate, selectedNumber);
   // await RegetRightSetData(areaId, powerBg, power, selectedValue, pastDate);
    /*
    if (powerInfos[power].name === "" || powerInfos[power].name === languageContext.words.all) {
      //CreateAreaDatasets(inputDate, pastDate, area, pastData);
    } else {
      CreatePlantDatasets(planData, predict, clickData, clickDate);
    }
    */
  };

  // 現在時刻を見て引数で受けた値と比較し、hourCountが現在時刻から１時間後までなら、trueを、そうでないならfalseを戻す
  const IsOneHourLate = (index: number) => {
    const nowTime = time; //  現在日時を得る
    let hourCount = nowTime.getHours() * 2; // 時間を取得を24→48最大に変更
    const minutes = nowTime.getMinutes();

    if (power === 0 || powerBg === 0) {
      // 前提としてエリア単位表示中(需要家が特定されていない場合は入力不可)
      return true;
    }

    // 現在時刻と検索日が一致したときのみ時刻判定
    if (
      dateContext.pickDate != undefined &&
      isSameDay(nowTime, dateContext.pickDate)
    ) {
      if (minutes >= 30) {
        hourCount += 1;
      }
      // 現在の時刻から1時間半先の時刻を判定
      if (hourCount + 2 < index) {
        return false;
      } else {
        return true;
      }
    } else if (
      dateContext.pickDate != undefined &&
      isBefore(nowTime, dateContext.pickDate)
    ) {
      return false;
    } else {
      return true;
    }
  };

  //検索条件に応じてDBから左側に表示するデータを取得し、セット
  async function RegetLeftSetData(
    rearea: string,
    repowerBg: number,
    repower: number,
    reDate: Date
  ) {
    //axios用の引数作成
    const releftreqvalue: reqLeftPowerForecast = {
      userId: 1, //todo 動的に入れられるように
      bgId: repowerBg,
      area: rearea,
      powerPlantId: repower,
      targetDate: context.pickDate as Date,
    };

    //システム予測取得
    //await SerchPredictData();

    //グラフリセット
    //setDatasets([]);
    //setChartAriaUnitOneline([]);
    //setChartPlantUnitOneline([]);
    dataset.current = [];
    rightAriaoneline.current = [];
    rightoneline.current = [];

    //メッセージリセット
    setLastUpdateInfo(undefined);

    //console.log("オンチェンジイベントのリクエスト値",releftreqvalue);
    setIsLoadingLeft(true);
    api
      .post(LeftDataUrl, releftreqvalue)
      .then(async (res) => {
        //console.log("画面左側バックから受け取ったデータの確認(2回目以降)",releftres.data);
        console.log("左引数：エリア/BG/発電所", rearea, repowerBg, repower);
        setIsLoadingLeft(false);
        setLeftData(res.data);
        if (repower == 0) {
          //エリア指定の時
          const getMaxHeight = (a: number, b: number) => {
            return Math.max(a, b);
          };
          const tempPlanData = reqGetsetPlandata(res.data);
          setPlanData(tempPlanData);
          savediff.current = tempPlanData;
          const maxHeight = Math.round(tempPlanData.reduce(getMaxHeight) * 1.1); //計画値の配列で最大の値をグラフ最大高として設定
          setChartMax(maxHeight);
          setChartStep(Math.round(maxHeight / 10));
          setDisableControl(true); //表示、非表示制御？
          setTextFieldDisable(disableTrue); //入力制限
          //setIsLoaded(true);
          //setPlanData(reqGetsetPlandata(releftres.data)); //計画値(1列分)をセット
          //実績、乖離、乖離％のデータをuseStateの変数に投入
          const toDate = new Date();
          if (reDate.toLocaleDateString() === toDate.toLocaleDateString()) {
            //console.log("当日日付。乖離、乖離%、実績を変数に投入(エリア)");
            await CreateActualDayData(res.data, repower);
            //console.log("投入されたデータの確認",ConditionalData);
          }
          /*await CreateAreaDatasets(
            context.pickDate === null ? inputDate : context.pickDate, 
            pastDate, //画面右側の選択日付 
            Areanm, 
            releftres.data
          );*/
        } else if (repower != 0) {
          //発電所指定の時
          //setChartMax(Math.round((contractpower)*1.1));
          //setChartStep(Math.round(contractpower * 1.1 / 10));
          if(isBefore(
            new Date(format(dateContext.pickDate as Date, "yyyy/MM/dd")),
            new Date(format(time as Date, "yyyy/MM/dd"))
          )) {
            setDisableControl(true); //表示、非表示制御
          } else {
            setDisableControl(false);
          }
          setTextFieldDisable(disableTrue); //入力制限
          //setPlanData(reqGetsetPlandata(releftres.data));
          const tempPlanData = reqGetsetPlandata(res.data);
          setPlanData(tempPlanData);
          savediff.current = tempPlanData;
          //実績、乖離、乖離％のデータをuseStateの変数に投入
          const toDate = new Date();
          if (reDate.toLocaleDateString() === toDate.toLocaleDateString()) {
            //console.log("当日日付。乖離、乖離%、実績を変数に投入(発電所)");
            await CreateActualDayData(res.data, repower);
            //console.log("投入されたデータの確認",ConditionalData);
          }

          //保存日時の取得
          await GetSaveTime(repowerBg, repower);

          /*//グラフ用配列作成
          await CreatePlantDatasets(
            planData.current, //計画値
            predict.current, //システム予測
            clickData.current, 
            clickDate.current
          );*/
        }
        //todo 乖離値とかその辺もここでセットする
        //setIsLoaded(true);
        console.log("左側完了");
      })
      .catch((error) => {
        setIsLoaded(true);
        setError(error);
        console.error(error);
      });
  }

  // 保存ボタン押下
  const OnAccept = () => {
    SavePowerPlan();
    //GetSaveTime();
  };

  //保存
  function SavePowerPlan() {
    const SaveValue: reqSaveActualPlan = {
      //userId : 1, //動的に
      area: areaId,
      bgId: powerBg,
      powerPlantId: power,
      targetDate: inputDate,
      planvalue: planData,
    };

    //計画値48コマ中1コマでも契約電力を上回る入力値がある場合エラー
    let currentDayPowerOver = Number(0);
    for (let i = 0; i < planData.length; i++) {
      if (Number(planData[i]) * 2 > Number(contractpower)) {
        currentDayPowerOver = Number(1);
      }
    }

    if (currentDayPowerOver != 0) {
      setDialogMessage(languageContext.words.dp_check_power);
      setDigOpen(true);
    } else {
      //console.log("保存用の引数確認",SaveValue);
      api
        .post(SaveDataUrl, SaveValue)
        .then((res) => {
          leavePageContext.setBlockLeave(false);
          //console.log("保存時の戻り値", saveres.data);
          //ダイアログ

          setDialogMessage(languageContext.words.saved);
          setDigOpen(true);
          const savedate: string = res.data.date;

          console.log(savedate);
          setLastUpdateInfo({
            date: new Date(res.data.date),
            user: res.data.user,
          });
          //setIsLoaded(true);
        })
        .catch((error: Error) => {
          console.log(error);
          if (error.message == "Request failed with status code 400") {
            setDialogMessage(languageContext.words.saved_dupicated);
            setDigOpen(true);
          } else {
            setDialogMessage(languageContext.words.saved_failed);
            setDigOpen(true);
          }
        });

      //setIsLoaded(true);
      //setError(error);
    }
  }

  //保存日時取得
  async function GetSaveTime(_powerBg: number, _power: number) {
    setIsLoadingSaveTime(true);
    api
      .post(GetSaveTimeNameUrl, {
        Bg: _powerBg,
        power: _power,
        targetDate: context.pickDate?.toLocaleDateString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      })
      .then((res) => {
        //console.log("保存日付と保存者名", res.data)
        setLastUpdateInfo({
          date: new Date(res.data[0].date),
          user: res.data[0].user,
        });
      })
      .finally(() => {
        setIsLoadingSaveTime(false);
      });
  }

  const SetWeatherInfo = () => {
    //プロパティ----
    const sunny_img = `/weather/FewClouds.svg`;
    const sunny_word = "晴れ";
    const cloudy_img = `/weather/BrokenClouds.svg`;
    const cloudy_word = "曇り";
    const rain_img = `/weather/Rain.svg`;
    const rain_word = "雨";

    //-------------

    interface searchParams {
      power_id: number;
      forecast_time: string | undefined;
    }

    const Params: searchParams = {
      power_id: POWERPLANT_ID,
      forecast_time: context.pickDate?.toLocaleDateString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }),
    };

    //引数：電源ID、日付
    api
      .post(WEATHER_GET_URL, Params)
      .then((res) => {
        let State_setCount = 0;

        //todo 仕様確認：画面に表示する際の画像と気温は平均をとるのか?
        for (let i = 0; i < res.data.length; i++) {
          //6の倍数で処理 * 8回分
          if ((i + 1) % 6 == 0) {
            ++State_setCount;
            //console.log("天気情報setstate用カウント",State_setCount)
            if (res.data[i].precipitation != 0) {
              //雨
              switch (State_setCount) {
                case 1:
                  setWeatherImg1(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord1(rain_word);
                  setWeatherTmp1(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 2:
                  setWeatherImg2(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord2(rain_word);
                  setWeatherTmp2(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 3:
                  setWeatherImg3(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord3(rain_word);
                  setWeatherTmp3(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 4:
                  setWeatherImg4(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord4(rain_word);
                  setWeatherTmp4(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 5:
                  setWeatherImg5(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord5(rain_word);
                  setWeatherTmp5(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 6:
                  setWeatherImg6(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord6(rain_word);
                  setWeatherTmp6(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 7:
                  setWeatherImg7(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord7(rain_word);
                  setWeatherTmp7(Math.round(res.data[i].temperature) + "℃");
                  break;
                case 8:
                  setWeatherImg8(`${process.env.PUBLIC_URL}${rain_img}`);
                  setWeatherWord8(rain_word);
                  setWeatherTmp8(Math.round(res.data[i].temperature) + "℃");
                  break;
              }
            } else if (res.data[i].precipitation == 0) {
              //雨以外
              //雲量が9以上
              if (Math.round(res.data[i].total_cloud) >= 9) {
                //曇り
                switch (State_setCount) {
                  case 1:
                    setWeatherImg1(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord1(cloudy_word);
                    setWeatherTmp1(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 2:
                    setWeatherImg2(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord2(cloudy_word);
                    setWeatherTmp2(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 3:
                    setWeatherImg3(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord3(cloudy_word);
                    setWeatherTmp3(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 4:
                    setWeatherImg4(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord4(cloudy_word);
                    setWeatherTmp4(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 5:
                    setWeatherImg5(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord5(cloudy_word);
                    setWeatherTmp5(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 6:
                    setWeatherImg6(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord6(cloudy_word);
                    setWeatherTmp6(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 7:
                    setWeatherImg7(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord7(cloudy_word);
                    setWeatherTmp7(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 8:
                    setWeatherImg8(`${process.env.PUBLIC_URL}${cloudy_img}`);
                    setWeatherWord8(cloudy_word);
                    setWeatherTmp8(Math.round(res.data[i].temperature) + "℃");
                    break;
                }
              } else if (Math.round(res.data[i].total_cloud) <= 8) {
                //晴れ
                switch (State_setCount) {
                  case 1:
                    setWeatherImg1(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord1(sunny_word);
                    setWeatherTmp1(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 2:
                    setWeatherImg2(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord2(sunny_word);
                    setWeatherTmp2(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 3:
                    setWeatherImg3(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord3(sunny_word);
                    setWeatherTmp3(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 4:
                    setWeatherImg4(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord4(sunny_word);
                    setWeatherTmp4(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 5:
                    setWeatherImg5(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord5(sunny_word);
                    setWeatherTmp5(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 6:
                    setWeatherImg6(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord6(sunny_word);
                    setWeatherTmp6(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 7:
                    setWeatherImg7(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord7(sunny_word);
                    setWeatherTmp7(Math.round(res.data[i].temperature) + "℃");
                    break;
                  case 8:
                    setWeatherImg8(`${process.env.PUBLIC_URL}${sunny_img}`);
                    setWeatherWord8(sunny_word);
                    setWeatherTmp8(Math.round(res.data[i].temperature) + "℃");
                    break;
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        setError(error);
      });
    return null;
  };

  const resetWeather = () => {
    setWeatherImg1(undefined);
    setWeatherImg2(undefined);
    setWeatherImg3(undefined);
    setWeatherImg4(undefined);
    setWeatherImg5(undefined);
    setWeatherImg6(undefined);
    setWeatherImg7(undefined);
    setWeatherImg8(undefined);

    setWeatherWord1(undefined);
    setWeatherWord2(undefined);
    setWeatherWord3(undefined);
    setWeatherWord4(undefined);
    setWeatherWord5(undefined);
    setWeatherWord6(undefined);
    setWeatherWord7(undefined);
    setWeatherWord8(undefined);

    setWeatherTmp1(undefined);
    setWeatherTmp2(undefined);
    setWeatherTmp3(undefined);
    setWeatherTmp4(undefined);
    setWeatherTmp5(undefined);
    setWeatherTmp6(undefined);
    setWeatherTmp7(undefined);
    setWeatherTmp8(undefined);
  };

  const ControlWeatherBox = (weather_no: string) => {
    //発電所単位でなければ隠す
    if (power == 0) {
      return "hidden" as const;
    }

    //発電所単位だった時
    switch (weather_no) {
      case "1":
        if (WeatherImg1 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "2":
        if (WeatherImg2 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "3":
        if (WeatherImg3 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "4":
        if (WeatherImg4 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "5":
        if (WeatherImg5 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "6":
        if (WeatherImg6 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "7":
        if (WeatherImg7 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }

      case "8":
        if (WeatherImg8 == undefined) {
          return "hidden" as const;
        } else {
          return "visible" as const;
        }
    }
  };

  //契約電力取得
  async function GetContractPower(powerid: number) {
    type getContract = {
      power_id: number;
    };

    const getcontract: getContract = { power_id: powerid };
    setIsLoadingContractPower(true);
    api
      .post(GetContractPowerUrl, getcontract)
      .then((res) => {
        setIsLoadingContractPower(false);
        //console.log("取得した契約電力", res.data);
        //todo 現状は変更後契約番号を見ていない
        if (res.data.length != 0) {
          setContractPower(res.data[0].contract_power);
          setChartMax(Math.round(res.data[0].contract_power * 1.1));
          setChartStep(Math.round((res.data[0].contract_power * 1.1) / 10));
        }
      })
      .catch((error) => {
        setIsLoaded(true);
        setError(error);
        console.error(error);
      });
  }

  const selectArea = async (_area: string) => {
    //この中でsetを呼ぶ？
    if (areaId != _area) {
      console.log("エリアonchange処理");

      //エリア
      setAreaId(("00" + _area).slice(-2));
      //setPowerBgNamesList([{id : 0, name : languageContext.words.all_power_gen_bg}]);
      //setPowerNamesList([{id : 0, name : languageContext.words.all_power_plants}]);
      resetWeather();

      //await getPowerBg(_area, false, true); //
      //setPowerBg(0);
      //setPower(0);

      //POWERPLANT_ID = 0;

      //setContractPower(0);

      //エリアコンテキストにエリアをセット

      //画面左側
      //await RegetLeftSetData(_area,0/*powerBg*/,0/*power*/,inputDate);
      //画面右側
      //await RegetRightSetData(_area,0/*powerBg*/,0/*power*/,"",pastDate);
    }
  };

  const selectpowerBg = async (p_bg: number) => {
    //setPowerNamesList([{id : 0, name : languageContext.words.all_power_plants}]);
    if (powerBg != p_bg) {
      console.log("BGonchange処理", p_bg);
      setPowerBg(p_bg);
      await resetWeather();
     // await getPower(areaId, p_bg, false, true);
      //setPower(0);

      //POWERPLANT_ID = 0;

      //setContractPower(0);
      //画面左側
      //await RegetLeftSetData(area,p_bg,0,inputDate); //発電所選択状態 → 発電BG選択でリセット
      //画面右側
      //await RegetRightSetData(area,p_bg,0,"",pastDate);
    }
  };

  const selectpower = async (_power: number) => {
    //leavePageContext.setBlockLeave(false);

    if (power != _power) {
      console.log("発電所onchange処理");
      setPower(_power);
      //console.log("プルダウン操作時の発電所ID", e)
      POWERPLANT_ID = _power;
      //console.log("プルダウン操作後のグローバル変数(発電所)",POWERPLANT_ID)
      resetWeather();
      SetWeatherInfo(); //天気情報セット
      setContractPower(0);
      SerchPredictData();
      await GetContractPower(_power);

      //システム予測(日射量)再取得
      //画面左側
      //await RegetLeftSetData(areaId, powerBg, _power, inputDate); //発電所選択状態 → 発電BG選択でリセット
      //画面右側
      //await RegetRightSetData(areaId, powerBg, _power, selectedValue, pastDate);
    }
  };

  const returndisable = () => {
    if (power == 0) {
      return true;
    } else {
      return false;
    }
  };

  //テキストフィールド入力値6桁制限判定
  function isMaxLength(value: string) {
    if (value.length > 6) {
      return false;
    } else {
      return true;
    }
  }

  //ページレンダリング
  if (error) {
    // errorはstate
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    // isLoadedはstate
    processFlag.current = false;
    return <CustomModalProgress open={true} />;
  }

  return (
    <div
      //ダークモード対応
      className={`cn-main-display ${PaletteMode === "dark" ? "dark" : "light"}`}
    >
      <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
        <CustomMultipleSelectList
          powerFg={true}
          list={allPowerList}
          areaId={areaId}
          bgId={powerBg}
          powerDemandId={power}
          handleValueChangeAreaList={setAreaList}
          handleValueChangeArea={setAreaId}
          handleValueChangeBG={setPowerBg}
          handleValueChangePowerDemand={setPower}
          getData={getPowerPlan}
        ></CustomMultipleSelectList>
        {/*     <CustomSelectList
          //エリアプルダウン
          //key={"powerForecastData_CustomSelectList_area"}
          //label={languageContext.words.all}
          value={areaId}
          options={areaOptions(AreaNameList)}
          onChange={selectArea(_area: number) =>{         
          }}
        />
        <CustomSelectList
          //bg名プルダウン
          //key={"powerForecastData_CustomSelectList_powerBg"}
          //label={languageContext.words.all}
          value={powerBg}
          options={powerBgOptions}
          onChange={selectpowerBg/*(p_bg: number)=>{
          }}
        />
        <CustomSelectList
          //発電所プルダウン
          //key={"powerForecastData_CustomSelectList_power"}
          //label={languageContext.words.all}
          value={power}
          options={powerPlantOptions}
          onChange={selectpower/*(power: number) =>{
          }}
        /> */}
        <GlobalDatePicker isDipsTodayBtn={true} />
        <Typography
          sx={{
            color: PaletteMode === "dark" ? "lightgray" : "gray",
            width: "660px",
            marginLeft: "15%",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {lastUpdateInfo
            ? `${languageContext.words.last_update_info}
              : ${languageContext.convertDateToLocalizedString(
                lastUpdateInfo.date,
                "HH:mm:ss"
              )}
              (${lastUpdateInfo.user})`
            : ""}
        </Typography>
        <Box
          sx={{
            height: "40px",
            display: "flex",
          }}
        >
          <SaveDataButton
            hidden={hidden}
            disabled={
              returndisable() ||
              hidden ||
              isBefore(
                new Date(format(dateContext.pickDate as Date, "yyyy/MM/dd")),
                new Date(format(time as Date, "yyyy/MM/dd"))
              )
                ? true
                : false
            }
            message={languageContext.words.note_saved}
            onAccept={OnAccept}
            //保存処理追加
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex" }} width={"100%"} height={"99%"}>
        <Box sx={{ width: "50%", height: "90%", mt: "7px" }}>
          {/*グラフチャート生成*/}
          <ChartRender data={graphData} options={options} />
          <Box paddingLeft={"78px"} display={"flex"}>
            <Box
              visibility={ControlWeatherBox("1")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg1}
                alt={WeatherWord1}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp1}
            </Box>
            <Box
              visibility={ControlWeatherBox("2")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg2}
                alt={WeatherWord2}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp2}
            </Box>
            <Box
              visibility={ControlWeatherBox("3")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg3}
                alt={WeatherWord3}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp3}
            </Box>
            <Box
              visibility={ControlWeatherBox("4")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg4}
                alt={WeatherWord4}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp4}
            </Box>
            <Box
              visibility={ControlWeatherBox("5")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg5}
                alt={WeatherWord5}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp5}
            </Box>
            <Box
              visibility={ControlWeatherBox("6")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg6}
                alt={WeatherWord6}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp6}
            </Box>

            <Box
              visibility={ControlWeatherBox("7")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg7}
                alt={WeatherWord7}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp7}
            </Box>

            <Box
              visibility={ControlWeatherBox("8")}
              textAlign={"center"}
              fontSize={"14px"}
              display={"grid"}
              width={"100px"}
              justifyContent={"center"}
            >
              <img
                src={WeatherImg8}
                alt={WeatherWord8}
                height={"30px"}
                width={"98px"}
              />
              {WeatherTmp8}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: "50%",
            height: "90%",
            color: PaletteMode === "dark" ? "lightgray" : "black",
            marginTop: "15px",
          }}
        >
          <Box sx={{ marginLeft: "90%", marginTop: "2px" }}>
            {languageContext.words.unit}：kWh
          </Box>
          <ScrollSync>
            <TableContainer
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                marginLeft: "5px",
                overflowY: "hidden",
                fontSize: "14px",
              }}
            >
              {PlanTable()} {/*発電計画値列と時刻列。*/}
              {PredictTable()}{" "}
              {/*システム予測の列。社外秘のため対応メンバ要確認*/}
              {ConditionalCurrentTableRender()} {/*実績、乖離、乖離%の列。*/}
              {PastReferenceTable()}{" "}
              {/*実績or計画表示列 4~7日分が表示される。*/}
            </TableContainer>
          </ScrollSync>
        </Box>
      </Box>
      <CustomDialog
        title={languageContext.words.power_gen_plan}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={() => { ; }}
        onClose={() => setDigOpen(false)}
      />
      <CustomModalProgress open={isLoading} />
    </div>
  );
};

const ChartRender = (props: any) => {
  const { data, options } = props;
  const chartRef = useRef<ChartJS>(null);

  // ズームリセット
  const handleResetZoom = () => {
    if (!(chartRef.current === null)) {
      chartRef.current.resetZoom();
    }
  };

  return (
    <Chart
      type={"bar"}
      data={data}
      // chartの動的な大きさ変更調査
      height={"176"}
      width={"205"}
      options={options}
      ref={chartRef}
      onDoubleClick={() => handleResetZoom()}
    />
  );
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
  zoomPlugin
);

export default PowerForecast;
