//エリアID定数
export const AREA_ID = {
  NONE: "00",
  HOKKAIDO: "01",
  TOHOKU: "02",
  TOKYO: "03",
  CHUBU: "04",
  HOKURIKU: "05",
  KANSAI: "06",
  CHUGOKU: "07",
  SHIKOKU: "08",
  KYUSYU: "09",
  OKINAWA: "10",
} as const;

//電圧階級
export const VOLTAGE_LEVEL = {
  NONE: "0",
  EXTRA_HIGH: "1",
  HIGH: "2",
  LOW: "3",
};

//情報区分コード
export const INFO_KIND_CODE = {} as const;

//ロールID
export const ROLE_ID = {
  ALL: "1111",
  SYSTEM: "00",
  TENANT: "01",
  MULTI_AREA: "10",
  SINGLE_AREA: "20",
  POWER: "40",
  DEMAND: "41",
  FIT: "99",
} as const;

//電源種別
export const POWER_SUPPLY_TYPE_ID = {
  NONE: "0",
  UNADJUSTED: "1",
  ADJUSTMENT: "2",
} as const;

//管理種別
export const MANAGEMENT_TYPE = {
  OWN_COMPANY: "00",
  OTHER_POWER_PLANT: "01",
  OTHER_RETAIL: "02",
  JBU: "03",
} as const;

//発電方式
export const POWER_ENERGY_KIND = {
  NONE: "0",
  THERMAL: "1", //火力
  HYDRO: "2", //水力
  SOLAR: "3", //太陽光
  SMALL_HYDRO: "4", //小水力
  WIND: "5", //風力
  BINARY: "6", //バイナリ
  BIOMASS: "7", //バイオマス
} as const;

//電源契約者
export const POWER_CONTRACTOR = {
  NONE: "0",
  COMMON: "1",
  OTHER_COMPANIES: "2",
} as const;

//販売先種別
export const SALE_KIND = {
  NONE: "0",
  SELF_CONSIGMENT: "12",
  SPOT: "21",
  INTRADAY: "31",
} as const;

//APIで使用するURL
export const API_URL = {
  //共通（ロール、エリア関連）
  CHECK_EDIT_ROLE: "/canLoginUserEditSetting", //編集権限チェック
  GET_AREA_MST: "/getAreaMst",
  GET_BP_AREA: "/getAreaOfBp",
  GET_AREA_NAME: "/getAreaNameList",
  GET_DEMAND_AREA_LIST: "/getBpDemandAreaNameList",
  GET_AREA_LIST: "/getBpAreaList",

  //発電事業者取得
  GET_POWER_BP: "/getPowerBp",

  //エリアロス
  GET_AREA_LOSS: "/getAreaLoss",
  SET_AREA_LOSS: "/setAreaLoss",

  //証明書登録
  OCCTO_POST: "/postOcctoCertificate",
  GENERAL_POST: "/postAreaCertificate",
  OCCTO_GET: "/getOCCTOCert",
  GENERAL_GET: "/getAreaCert",

  //接続供給契約情報取得
  GET_CONNECT_CONTRACTS: "/getConnectContracts",

  //発電BG情報取得URL
  GET_POWERBG_LIST: "/getPowerBgList",

  //発電事業者
  GET_BP: "/getPowerBpList",
  GET_BP_DETAIL: "/getPowerBpDetail",

  //発電契約者
  GET_POWERCONTRACTOR_LIST: "/getAllPowerContractorList",

  //発電所
  POWERPLANT_LIST: "/getAllPowerPlantList",

  //販売先グループ内訳
  GET_SALEDETAS: "/getSaleDetas",

  //販売先グループ一覧
  GET_SALEGROUP_LIST: "/getSaleGroupList",

  //接続供給契約
  ADD_CONTRACT_INFO: "/addContractInfo",
  GET_ONE_CONTRACTOR_DATA: "/getContractorInfoLinkedPK",
  UPDATE_CONTRACTOR_INFO: "/updateContractorInfo",
  GET_CONTRACTOR: "/getContractorList",
  GET_CONTRACTOR_MST: "/getMstContractorList",
  DELETE_CONNECTCONTRACT_INFO: "/deleteConnectContractInfo",

  //JEPXユーザ情報
  GET_JEPX_PARTICIPANT: "/getJepxGuest", // JEPX参加者取得
  GET_JEPX_USER: "/getJepxUser", //JEPXユーザ取得
  GET_JEPX_CERTIFICATE: "/getCertificateList", //JEPXユーザ証明書取得
  POST_JEPX_DATA: "/postJepxData", //JEPXユーザ情報登録
  POST_ADDITIONAL_JEPX_DATA: "/postAddJepxUserData", //JEPX追加ユーザ情報登録

  //部分供給
  GET_ONE_DEMANDDATA: "/getDemandPartInfoLinkedPK", //一件入手
  UPDATE_PART_DEMAND_INFO: "/updatePartDemandList",
  DELETE_PART_DEMAND_INFO: "/deletePartDemandInfo",

  //発電BG
  ADD_POWERBG_INFO: "/addPowerBgInfo",
  GET_ONE_POWERBGDATA: "/getPowerBgInfoLinkedPK",
  UPDATE_POWERBG_INFO: "/updatePowerBgInfoLinkedPK",
  DELETE_POWERBG_INFO: "/deletePowerBg",

  //発電事業者
  INSERT_BP: "/insertPowerBpInfo",
  UPDATE_BP: "/updatePowerBpInfo",
  DELETE_BP: "/deletePowerBpInfo",

  //発電契約者
  ADD_POWERCONTRACTOR_INFO: "/insertPowerContractorInfo",
  DELETE_POWERCONTRACTOR_INFO: "/deletePowerContractorInfo",
  GET_RECORD_CONTRACTOR: "/getPowerContractorInfoLinkedPK",
  UPDATE_POWERCONTRACTOR_INFO: "/updatePowerContractorInfo",

  //発電閾値
  UPDATE_LIMIT_POWERPLANT_INFO: "/updatePowerPlantLimitList",
  GET_LIMITDATA: "/getPowerPlantLimitInfoLinkedPK",

  //発電所
  SUBMIT: "/submitPower",
  GET_POWERBG_MST: "/getMstPowerBgIDList",
  GET_ONE_POWERPLANT_DATA: "/getOnePowerPlant",
  ADD_POWERPLANT_INFO: "/insertPowerPlantInfo",
  UPDATE_POWERPLANT_INFO: "/updatePowerPlantInfo",
  DELETE_POWERPLANT_INFO: "/deletePowerPlant",
  GET_PANNEL_RISE: "/getPannelRiseType", // パネル設置形態
  GET_PANNEL_DOWNWARD: "/getPannelDownwardType", // パネル型

  //販売先グループ内訳
  ADD_SALEDETAIL_INFO: "/insertSaleDetailInfo",
  GET_ONE_SALEDETAIL_DATA: "/getSaleDetailInfoLinkedPK",
  UPDATE_SALEDETAIL_INFO: "/updateSaleDetailInfo",
  DELETE_SALEDETAIL_INFO: "/deleteSaleDetailInfo",
  GET_AREA_POWER: "/getPowerNameList",
  GET_AREA_DEMAND: "/demand",
  GET_SALE_GR_MST: "/getMstSaleGroupData",

  //販売先グループ一覧
  ADD_SALEGROUP_INFO: "/addSaleGroupInfo",
  GET_ONE_SALEGR_DATA: "/getSaleGroupInfoLinkedPK",
  UPDATE_SALEGROUP_INFO: "/updateSaleGroupInfo",
  DELETE_SALEGROUP_INFO: "/deleteSaleGroupInfo",
  GET_SALES_TYPE_AREA: "/getSalesTypeArea",

  //需要BG
  SET_DEMANDBG_LIST_API: "/setDemandBgList",
  GET_ONE_DEMANDBGDATA: "/getDemandBgInfoLinkedPK",
  UPDATE_DEMANDBG_INFO: "/updateDemandBgInfo",
  DELETE_DEMANDBG_INFO: "/deleteDemandBg",

  //需要家
  SET_DEMAND_LIST_API: "/setDemandList",
  GET_RECORD_DEMAND_LIST_API: "/getRecordDemandList", //1行
  UPDATE_RECORD_DEMAND_LIST_API: "/updateRecordDemandList", //1行更新
  DELETE_DEMAND_INFO: "/deleteDemandInfo", //削除
  GET_DEMANDBG_MST: "/getMstDemandBgIDList", //発電事業者のデータ入手（セレクター用）

  //ユーザ一覧
  GET_ROLE: "/targetRoleList",
  SET_USER_LIST_API: "/setUserList",
  GET_RECORD_USER_LIST_API: "/getRecordUserList", //1行取得
  UPDATE_RECORD_USER_LIST_API: "/updateRecordUserList", //1行更新
  DELETE_RECORD_USER_LIST_API: "/deleteRecordUserList", //ユーザ削除
  CHECK_DELETE_USER: "/checkDeleteUser", //ユーザ削除前確認
  GET_USER_ROLE: "/getUserRole", //ユーザのロール取得

  //需要BG
  GET_BG_LIST: "/getDemandBgList",

  //需要家一覧
  DEMAND_LIST_API: "/getDemandList",

  //ユーザ一覧
  USER_LIST_API: "/getUserList",
} as const;
