import React, { useState, useEffect, useContext } from "react";
import "./css/SetSupplyList.css";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { padding, width } from "@mui/system";
import axios from "axios";
import CustomModal from "../common/customComponents/CustomModal";
import { LanguageContext } from "../common/localization/localization";
import { demandListInfo } from "../../types/occto/DemandForecastList";
import {
  resDemandBgInfo,
  resPowerBpInfo,
  setDemandInfo,
  updateDemandInfo,
} from "../../types/master/SetSupplyList";
import { response } from "express";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import { type } from "@testing-library/user-event/dist/type";
import { modalMode } from "./SupplyList";
import { resAreaList } from "../../types/common/Search";

import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import CustomSelectListBig from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorBig";
import { useAreaUtility } from "../../common/area";
import { useVoltageLevelUtility } from "../../common/voltageLevel";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";

type Props = {
  userId: string;
  propsShowUserCreateDialog: any;
};

type UserInfo = {
  UserId: string;
  UserName: string;
  role: number;
  mailAddress: string;
  password: string;
  eria01: boolean;
  eria02: boolean;
  eria03: boolean;
  eria04: boolean;
  eria05: boolean;
  eria06: boolean;
  eria07: boolean;
  eria08: boolean;
  eria09: boolean;
};

type role = {
  role_id: number;
  role_nm: string;
};

//退避
const CustomTextField = styled(TextField)(() => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "auto",
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
}));

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  volLevel: boolean;
  volUnit: boolean;
  demandName: boolean;
  areaId: boolean;
  bpId: boolean;
  powerBpCode: boolean;
  powerBpName: boolean;
  supplyPointSpNo: boolean;
  demandBgId: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  volLevel: false,
  volUnit: false,
  demandName: false,
  areaId: false,
  bpId: false,
  powerBpCode: false,
  powerBpName: false,
  supplyPointSpNo: false,
  demandBgId: false,
};

//引数で編集/新規登録 を判断する。編集の場合情報もらう
const SetDemandList = (
  toOpen: boolean,
  HandleClose: () => void,
  mode: modalMode,
  demandIdForEdit: number
) => {
  type areaInfo = { id: string; name: string };
  type powerBpInfo = {
    id: number;
    bpid: number;
    name: string;
    code: string;
  };
  type demandBgInfo = {
    //需要BGセレクター用
    id: number;
    bgid: number;
    name: string;
    areaId: string;
    areaName: string;
  };
  //電圧階級指定番号
  type volLevelInfoSellect = {
    name: string; //名前レベル
    id: number; //No
  };
  // 電力量番号
  type volUnitInfoSellect = {
    name: string; //名前レベル
    id: number; //No
  };

  //通信エラー
  const api = useAuthedApi();

  //エリアリスト
  const { convertIdToAreaName } = useAreaUtility();
  //電圧階級
  const { volLevelOptions } = useVoltageLevelUtility();

  const demandBgInfos = React.useRef<demandBgInfo[]>([]);
  const powerBpInfos = React.useRef<powerBpInfo[]>([]);
  const editflg = React.useRef<boolean>(false); //編集用データ取得した直後かを判別するフラグ

  const [demandListInfo, setDemandListInfo] = useState<setDemandInfo>();

  const [PowerBpNo, setPowerBpNo] = useState<number>(0); //発電事業者セレクターの選択された番号を管理
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する
  const [demandBgNo, setDemandBgNo] = useState<number>(0); //需要BGのインデックス
  const [demandBgArea, setDemandBgArea] = useState<string>(""); //需要BGに紐づくエリアを表示するstate

  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HandleClose();
    }
  }, [digOpen]); //初期化
  ////

  ////////エリア関連

  // const getAreaMst = (bpIndex: number) => {
  //   axios
  //     .get(GET_AREA_MST_URL, {
  //       params: { bpId: powerBpInfos.current[bpIndex].bpid },
  //     })
  //     .then((response) => {
  //       //console.log("エリアマスタ取得開始");
  //       //console.log(response.data.result);
  //       setareaInfos(convertAreaData(response.data as resAreaList[]));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const convertAreaData = (result: resAreaList[]) => {
  //   const retAreaData: areaInfo[] = [{ id: "00", name: "未選択" }];
  //   result.map((data) => {
  //     const areaData: areaInfo = { id: "", name: "" };
  //     areaData.id = data.areaId.toString();
  //     areaData.name = data.areaName;
  //     retAreaData.push(areaData);
  //     // console.log("エリア変換1データ");
  //     //console.log(JSON.stringify(areaData));
  //   });
  //   //console.log("エリア変換後データ");
  //   //console.log(JSON.stringify(retAreaData));
  //   return retAreaData;
  // };

  // function setAreaId(id: number) {
  //   //numberを文字列にしてコピーしたsaleDetailInfoのIDにセットする
  //   const areaIdString = ("00" + id).slice(-2).toString();
  //   const tmpAreaInfo: setDemandInfo = Object.assign({}, demandListInfo);
  //   tmpAreaInfo.areaId = areaIdString;

  //   setDemandListInfo(tmpAreaInfo);
  // }
  ////////エリア関連

  ///////発電事業者と事業者コード

  //データベースから発電事業者データ一覧取得
  useEffect(() => {
    getPowerBp();
    getDemandBgMst();
  }, []); //初期化

  const getPowerBp = () => {
    api
      .get(API_URL.GET_POWER_BP)
      .then((res) => {
        powerBpInfos.current = res.data.map(
          (v: any, index: number): powerBpInfo => {
            return {
              id: index + 1,
              bpid: v.bpid,
              name: v.name,
              code: v.code,
            };
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setPowerBp = (e: number) => {
    const tmpDemandInfo: setDemandInfo = Object.assign({}, demandListInfo);
    const tmpPowerBpInfo: powerBpInfo[] = createBpListJoinUnselect();
    //console.log(e);
    tmpDemandInfo.bpId = tmpPowerBpInfo[e].bpid; //事業者ID
    tmpDemandInfo.powerBpName = tmpPowerBpInfo[e].name; //発電事業者名称
    tmpDemandInfo.powerBpCode = tmpPowerBpInfo[e].code; //発電事業者こーど
    // setSelContractantCode(tmpContractInfo[e].code);
    // setSelContractantNo(tmpContractInfo[e].id);
    setPowerBpNo(tmpPowerBpInfo[e].id);
    setDemandListInfo(tmpDemandInfo);
  };

  ////////発電事業者と事業者コード

  ////////需要BG
  const getDemandBgMst = () => {
    api
      .get(API_URL.GET_DEMANDBG_MST)
      .then((response) => {
        demandBgInfos.current = convertDemandBgSelecterDatas(
          response.data.result as resDemandBgInfo[]
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertDemandBgSelecterDatas = (result: resDemandBgInfo[]) => {
    const retDemandBgData: demandBgInfo[] = [];
    result.map((data, index) => {
      const demandBgData: demandBgInfo = {
        id: 0,
        name: "",
        bgid: 0,
        areaId: "00",
        areaName: "",
      };
      demandBgData.id = index + 1; //0要素にはデフォルトのものが入るため
      demandBgData.name = data.name;
      demandBgData.bgid = data.bgid;
      demandBgData.areaId = data.areaId;
      demandBgData.areaName = data.areaName;

      retDemandBgData.push(demandBgData);
    });
    return retDemandBgData;
  };

  //セレクターから選択された所属需要BGのIDをsaleDetailInfoに入れる
  const setDemandBgId = (e: number) => {
    const tmpDemandInfo: setDemandInfo = Object.assign({}, demandListInfo);
    const tmpBgInfo: demandBgInfo[] = createBgListJoinUnselect();

    tmpDemandInfo.demandBgId = tmpBgInfo[e].bgid;
    tmpDemandInfo.areaId = tmpBgInfo[e].areaId;

    setDemandBgArea(tmpBgInfo[e].areaId);
    setDemandBgNo(tmpBgInfo[e].id);
    setDemandListInfo(tmpDemandInfo);
  };

  //編集用にDBから出したデータのpowerBgIDと一致しているものを
  //powerBgInfosからさがし、その情報をコンポーネントに表示
  const setDemandBgIdSellector = (demandBgIdFromDB: number) => {
    createBgListJoinUnselect().map((demandBgInfos) => {
      if (demandBgIdFromDB === demandBgInfos.bgid) {
        console.log(demandBgInfos.areaName);
        setDemandBgNo(demandBgInfos.id);
        setDemandBgArea(demandBgInfos.areaId);
      }
    });
  };

  ////////需要BG

  //////電圧階級指定番号
  const [volLevelNo, setvolLevelNo] = useState<number>(0);
  const volLevels: string[] = ["0", "1", "2", "3"];

  const [volUnitNo, setvolUnitNo] = useState<number>(0);
  const createVolUnitList = () => {
    const volUnit: volUnitInfoSellect[] = [
      { name: languageContext.words.setting_unselected, id: 0 },
      { name: "kWh", id: 1 },
    ];
    return volUnit;
  };

  function SetVolLevel(id: number) {
    const tmpDemandInfo: setDemandInfo = Object.assign({}, demandListInfo);
    const tmpVolLevelInfo: string[] = volLevels;
    tmpDemandInfo.volLevel = Number(tmpVolLevelInfo[id]);
    setvolLevelNo(id);

    setDemandListInfo(tmpDemandInfo);
  }

  function SetUnitLevel(id: number) {
    const tmpDemandInfo: setDemandInfo = Object.assign({}, demandListInfo);
    const tmpVolUnitInfo: volUnitInfoSellect[] = createVolUnitList();
    tmpDemandInfo.contractPowerKind = tmpVolUnitInfo[id].id;
    setvolUnitNo(id);
    setDemandListInfo(tmpDemandInfo);
  }

  //////電圧階級指定番号ここまで

  // 初期化用
  const initializeDemandListInfo: setDemandInfo = {
    demandName: "",
    volLevel: 0,
    areaId: "00",
    supplyPointSpNo: "",
    bpId: 0,
    powerBpCode: "",
    powerBpName: "",
    contractPower: 0,
    contractPowerKind: 0,
    demandBgId: 0,
  };

  //初期化する
  useEffect(() => {
    setDemandListInfo(initializeDemandListInfo);
  }, []);

  useEffect(() => {
    if (toOpen === true) {
      if (mode == modalMode.editMode) {
        //1レコード取得
        getRecordDemandList(demandIdForEdit);
      } else {
        setDemandListInfo(initializeDemandListInfo);
        setPowerBpNo(0); //セレクター初期化
        setvolLevelNo(0);
        setvolUnitNo(0);
        setDemandBgNo(0);
        setDemandBgArea("");
      }
    } else {
      setDemandListInfo(initializeDemandListInfo);
      setPowerBpNo(0); //セレクター初期化
      setvolLevelNo(0);
      setvolUnitNo(0);
      setDemandBgNo(0);
      setDemandBgArea("");
      setInputError(initErrorInput);
    }
  }, [toOpen]);

  // 需要家1レコード取得
  const getRecordDemandList = (demandId: number) => {
    //console.log("需要家1レコード取得します");
    let responseData: setDemandInfo;
    api
      .get(API_URL.GET_RECORD_DEMAND_LIST_API, { params: { id: demandId } })
      .then((response) => {
        editflg.current = true;
        responseData = response.data.actualResult as setDemandInfo;
        setPowerBpInfoForEdit(responseData); //セレクターを合わせる
        setDemandBgIdSellector(responseData.demandBgId);
        setvolLevelNo(responseData.volLevel);
        setvolUnitNo(responseData.contractPowerKind);
        setDemandListInfo(responseData);
      })
      .catch((err) => {
        console.log(err);
        setDemandListInfo(initializeDemandListInfo);
      });
  };

  //発電事業者情報リストの先頭に未選択の選択子を追加した配列を返す
  const createBpListJoinUnselect = () => {
    const unselect = [
      {
        id: 0,
        bpid: 0,
        name: languageContext.words.setting_unselected,
        code: "",
      },
    ];
    return unselect.concat(powerBpInfos.current);
  };

  //需要BGリストの先頭に未選択の選択肢を追加した配列を返す
  const createBgListJoinUnselect = () => {
    const unselect = [
      {
        id: 0,
        bgid: 0,
        name: languageContext.words.setting_unselected,
        areaId: "00",
        areaName: "",
      },
    ];
    return unselect.concat(demandBgInfos.current);
  };

  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name = e.currentTarget.name;
    let value = e.currentTarget.value;

    const tmpDemandInfo: setDemandInfo = Object.assign({}, demandListInfo);
    switch (name) {
      case "demandName":
        tmpDemandInfo.demandName = value;
        break;
      case "volLevel":
        tmpDemandInfo.volLevel = isNaN(parseInt(value)) ? 0 : parseInt(value);
        break;
      case "supplyPointSpNo":
        tmpDemandInfo.supplyPointSpNo = value;
        break;
      case "powerBpCode":
        tmpDemandInfo.powerBpCode = value;
        break;
      case "powerBpName":
        tmpDemandInfo.powerBpName = value;
        break;
      case "contractPower":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 9999999999, 0)) {
          tmpDemandInfo.contractPower = parseInt(value);
        }
        // if (!isNaN(parseInt(value)) && 0 <= parseInt(value)) {
        //   tmpDemandInfo.contractPower = parseInt(value);
        // }
        // tmpDemandInfo.contractPower = isNaN(parseInt(value))
        //   ? 0
        //   : parseInt(value);
        break;
      case "contractPowerKind":
        tmpDemandInfo.contractPowerKind = isNaN(parseInt(value))
          ? 0
          : parseInt(value);
        break;
    }
    setDemandListInfo(tmpDemandInfo);
  }

  //テキストフィールドに正しい数値が入っているかチェック
  const isSafeNumInTextField = (
    value: string,
    maxNum: number,
    minNum: number
  ): boolean => {
    // //空文字チェック
    // if (value === "") {
    //   return true;
    // }
    //正しい範囲内の数値が入っているかチェック
    if (!isNaN(parseInt(value))) {
      const valueNum = parseInt(value);
      if (valueNum >= minNum && valueNum <= maxNum) {
        return true;
      }
    }
    return false;
  };

  const SaveClose = () => {
    //console.log("保存して閉じます");
    if (mode === modalMode.registerMode) {
      InsertRecord();
    } else {
      UpdateRecord();
    }
  };

  const InsertRecord = () => {
    //console.log("インサート");
    let error = false;

    error = isCorrectInfo();

    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else if (error === false) {
      api
        .post(API_URL.SET_DEMAND_LIST_API, demandListInfo)
        .then((res) => {
          if (res.data) {
            setJobClear(true);
            setDialogMessage(languageContext.words.demand_setting_added);
          } else {
            setDialogMessage(
              languageContext.words.demand_setting_err_message_1
            );
          }
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.demand_setting_failed_add);
          setDigOpen(true);
        });
    }
  };

  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (demandListInfo?.volLevel == 0) {
      error = true;
      tempInputErrors.volLevel = true;
    }
    if (demandListInfo?.demandName == "") {
      error = true;
      tempInputErrors.demandName = true;
    }
    if (demandListInfo?.areaId == "00") {
      error = true;
      tempInputErrors.areaId = true;
    }
    if (demandListInfo?.bpId == 0) {
      error = true;
      tempInputErrors.bpId = true;
    }
    if (demandListInfo?.powerBpCode == "") {
      error = true;
      tempInputErrors.powerBpCode = true;
    }
    if (demandListInfo?.powerBpName == "") {
      error = true;
      tempInputErrors.powerBpName = true;
    }
    if (demandListInfo?.supplyPointSpNo == "") {
      error = true;
      tempInputErrors.supplyPointSpNo = true;
    }
    if (demandListInfo?.contractPowerKind == 0) {
      error = true;
      tempInputErrors.volUnit = true;
    }
    if (demandListInfo?.demandBgId == 0) {
      error = true;
      tempInputErrors.demandBgId = true;
    }

    setInputError(tempInputErrors);
    return error;
  };

  //編集用にDBから出したデータの発電所業者コード、名前と一致しているものを
  //demandOneInfoからさがし、その情報をコンポーネントに表示
  const setPowerBpInfoForEdit = (demandOneInfo: setDemandInfo) => {
    createBpListJoinUnselect().map((powerBpInfo) => {
      if (
        demandOneInfo.powerBpName === powerBpInfo.name &&
        demandOneInfo.powerBpCode === powerBpInfo.code
      ) {
        setPowerBpNo(powerBpInfo.id);
      }
    });
  };

  function UpdateRecord() {
    //console.log("アップデート");
    let error = false;

    error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else if (error === false) {
      const updateDemandInfo = {
        demandId: demandIdForEdit,
        ...demandListInfo,
      } as updateDemandInfo;
      api
        .post(API_URL.UPDATE_RECORD_DEMAND_LIST_API, updateDemandInfo)
        .then((res) => {
          if (res.data) {
            setJobClear(true);
            setDialogMessage(languageContext.words.demand_setting_updated);
          } else {
            setDialogMessage(
              languageContext.words.demand_setting_err_message_1
            );
          }
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.demand_setting_failed_update);
          setDigOpen(true);
        });
    }
  }

  // 削除(論理削除)
  const Delete = (demandId: number | undefined) => {
    // 削除API呼出
    //console.log(demandListInfo);
    api
      .post(API_URL.DELETE_DEMAND_INFO, { demandId: demandId })
      .then((response) => {
        //console.log(`BG1件削除結果: ${response.data.result}`);
        setJobClear(true);
        setDialogMessage(languageContext.words.demand_setting_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.demand_setting_failed_delete);
        setDigOpen(true);
      });
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.demander_editting}
      open={toOpen}
      onAcceptLeft={() => SaveClose()}
      onAcceptRight={() => Delete(demandIdForEdit)}
      onClose={() => HandleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.registerMode
          ? NumOfButton.Single
          : NumOfButton.Double
      }
    >
      <Stack sx={{ height: "450px", width: "550px" }}>
        <CustomBoxBig>
          <CustomModalTypography>
            {languageContext.words.demander_name}
          </CustomModalTypography>
          <CustomTextFieldBig
            type="text"
            value={demandListInfo?.demandName}
            onChange={onChangeValue}
            name="demandName"
            InputProps={{
              inputProps: {
                maxLength: 300,
              },
            }}
            error={inputError.demandName}
            helperText={languageContext.words.setting_required}
          ></CustomTextFieldBig>
        </CustomBoxBig>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.business_name_etc}
            </CustomModalTypography>

            <CustomSelectListBig // 事業者名称等
              name="powerBpName"
              label={languageContext.words.none}
              value={PowerBpNo}
              options={createBpListJoinUnselect()}
              input={<OutlinedInput label="Name" />}
              onChange={setPowerBp}
              error={inputError.bpId}
            ></CustomSelectListBig>
          </CustomBoxSmall>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.contract_operator_Id}
            </CustomModalTypography>
            <CustomTextFieldSmall // 契約事業者識別(コード)
              type="text"
              value={demandListInfo?.powerBpCode}
              onChange={onChangeValue}
              name="powerBpCode"
              disabled={true}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
        </Stack>
        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.demand_bg}
            </CustomModalTypography>

            <CustomSelectListBig //需要BG
              name="demandBg"
              label={languageContext.words.none}
              value={demandBgNo}
              options={createBgListJoinUnselect()}
              input={<OutlinedInput label="Name" />}
              onChange={(e: number) => {
                setDemandBgId(e);
              }}
              error={inputError.demandBgId}
            ></CustomSelectListBig>
          </CustomBoxSmall>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.area}
            </CustomModalTypography>

            <CustomTextFieldSmall
              //key={"demandForecastData_CustomSelectList_customer"}
              name="areaId"
              //label={languageContext.words.all_areas}
              value={convertIdToAreaName(demandBgArea)}
              disabled={true}
              error={inputError.areaId}
            />
          </CustomBoxSmall>
        </Stack>
        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.voltage_class}
            </CustomModalTypography>

            <CustomSelectListSmall // 電圧階級
              name="volLevel"
              label={languageContext.words.none}
              value={volLevelNo}
              options={volLevelOptions(volLevels)}
              input={<OutlinedInput label="Name" />}
              onChange={SetVolLevel}
              error={inputError.volLevel}
            ></CustomSelectListSmall>
          </CustomBoxSmall>

          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.supply_point_id_number}
            </CustomModalTypography>

            <CustomTextFieldSmall // 供給地点特定番号
              type="text"
              value={demandListInfo?.supplyPointSpNo}
              onChange={onChangeValue}
              name="supplyPointSpNo"
              InputProps={{
                inputProps: {
                  maxLength: 22,
                },
              }}
              error={inputError.supplyPointSpNo}
              helperText={languageContext.words.setting_required}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
        </Stack>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.contracted_power}
            </CustomModalTypography>
            <CustomTextFieldSmall // 契約電力
              type="number"
              value={Number(demandListInfo?.contractPower).toFixed(0)}
              onFocus={(e) => {
                if (Number(demandListInfo?.contractPower) === 0) {
                  e.currentTarget.select();
                }
              }}
              onChange={onChangeValue}
              name="contractPower"
              inputProps={{
                step: 1,
                min: 0,
                max: 9999999999,
              }}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.contracted_power_unit}
            </CustomModalTypography>
            <CustomSelectListSmall // 契約電力単位
              name="contractPowerKind"
              label={languageContext.words.none}
              value={volUnitNo}
              options={createVolUnitList()}
              input={<OutlinedInput label="Name" />}
              onChange={SetUnitLevel}
              error={inputError.volUnit}
            ></CustomSelectListSmall>
          </CustomBoxSmall>
        </Stack>
      </Stack>
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetDemandList;
