import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DateContext } from "../../common/customComponents/GlobalDatePicker";
import { LanguageContext } from "../../common/localization/localization";
import { stepTotal } from "../../../types/jepx/BiddingScreen";

// 入札実行ボタンが押された時のモーダルダイアログに表示するコンポーネント
const DoneBid = (props: any) => {
  const { bidData, bpName } = props;
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  //日付のデータコンテキスト
  const selectedDate = useContext(DateContext);

  return (
    <Box sx={{ height: "250px", display: "flex" }}>
      <Stack direction={"column"} spacing={1.5}>
        <Typography gutterBottom>
          {languageContext.words.power_producer}：{bpName}
        </Typography>
        <Typography gutterBottom>
          {languageContext.words.area}：
          {bidData.area === undefined ? "" : bidData.area}
        </Typography>
        <Typography gutterBottom>
          {languageContext.words.bidding_date_and_time}：
          {languageContext.convertDateToLocalizedStringPoorAcu(
            selectedDate.pickDate as Date
          )}
        </Typography>
        <Typography gutterBottom>
          {languageContext.words.bid_price}：
          {bidData.bidPrice === undefined ? "" : bidData.bidPrice}
        </Typography>
        <Typography gutterBottom>
          {languageContext.words.bidding_volume}：
          {bidData.bidVolume === undefined ? "" : bidData.bidVolume}
        </Typography>
        {bidData.stepVolume.map((data: stepTotal, index: number) => {
          return (
            <Typography key={`${data.total} ${index}`}>
              {languageContext.words.step +
                data.step +
                `(${languageContext.words.daily_amount})` +
                `：${data.total.toFixed(2)} MWh/h`}
            </Typography>
          );
        })}
        <Typography gutterBottom>
          {languageContext.words.note_bid_execute}
        </Typography>
      </Stack>
    </Box>
  );
};

export default DoneBid;
