import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CONNECTION_ERROR_URL } from "./url";
import { ErrorMessageContext } from "../views/common/customComponents/ErrorMessageProvider";
import { ErrorTitleContext } from "../views/common/customComponents/ErrorTitleProvider";
import { LanguageContext } from "../views/common/localization/localization";

const option: AxiosRequestConfig = {
  baseURL: "_api/",
  headers: {
    "Content-Type": "application/json",
  },
};

//BEapi
//ログイン
const api = axios.create(option);

api.interceptors.request.use(
  (req) => {
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

//エラー画面遷移
//BEapi
const navigateApi = axios.create(option);

navigateApi.interceptors.request.use(
  (req) => {
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

//エラー自コンポーネント入れ替え
//BEapi
const componentApi = axios.create(option);

componentApi.interceptors.request.use(
  (req) => {
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

//ToDo
//エラーハンドリング
//リクエスト

//認証失敗時の処理で画面遷移する(reactのフックを使う)ためにカスタムフックでラップ
export const useAuthedApi = () => {
  const { words } = useContext(LanguageContext);
  const { setMessage } = useContext(ErrorMessageContext);
  const { setTitle } = useContext(ErrorTitleContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interceptor = navigateApi.interceptors.response.use(
      (res) => {
        console.log(res);
        return res;
      },
      (err) => {
        if (
          err.response.status === 502 ||
          (err.code === "ERR_NETWORK" && location.pathname !== "/")
        ) {
          setTitle("network_err");
          setMessage("network_err_message");
          navigate(CONNECTION_ERROR_URL);
        }
        if (err.response.status === 401 && location.pathname !== "/") {
          setTitle("session_err");
          setMessage("session_err_message");
          navigate(CONNECTION_ERROR_URL);
        }
        return Promise.reject(err);
      }
    );
    return () => navigateApi.interceptors.response.eject(interceptor);
  }, [navigate]);

  return navigateApi;
};

//認証失敗時の処理でエラー画面を表示する(reactのフックを使う)ためにカスタムフックでラップ
export const useAuthedModalApi = (
  setError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { words } = useContext(LanguageContext);
  const { setMessage } = useContext(ErrorMessageContext);
  const { setTitle } = useContext(ErrorTitleContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const interceptor = componentApi.interceptors.response.use(
      (res) => {
        console.log(res);
        return res;
      },
      (err) => {
        if (
          err.response.status === 502 ||
          (err.code === "ERR_NETWORK" && location.pathname !== "/")
        ) {
          setTitle("network_err");
          setMessage("network_err_message");
          setError(true);
        } else if (err.response.status === 401 && location.pathname !== "/") {
          setTitle("session_err");
          setMessage("session_err_message");
          setError(true);
        } else {
          setError(false);
        }
        return Promise.reject(err);
      }
    );
    return () => componentApi.interceptors.response.eject(interceptor);
  }, [navigate]);

  return componentApi;
};

export default api;
