import { useCallback, useContext, useMemo } from "react";
import { LanguageContext } from "../views/common/localization/localization";
import { POWER_SUPPLY_TYPE_ID } from "./constant";

export const usePowerSupplyTypeUtility = () => {
  const { words } = useContext(LanguageContext);

  const supplyTypeOptions = useMemo(
    () => [
      { id: POWER_SUPPLY_TYPE_ID.NONE, name: words.setting_unselected },
      {
        id: POWER_SUPPLY_TYPE_ID.UNADJUSTED,
        name: words.setting_power_supply_type_1,
      },
      {
        id: POWER_SUPPLY_TYPE_ID.ADJUSTMENT,
        name: words.setting_power_supply_type_2,
      },
    ],
    [words]
  );

  const convertIdToSupplyTypeName = useCallback(
    (id: string) => {
      const powerSupplyNames = [
        words.setting_power_supply_type_1,
        words.setting_power_supply_type_2,
      ];
      const index = parseInt(id) - 1;
      return powerSupplyNames[index] ? powerSupplyNames[index] : "undefined";
    },
    [words]
  );

  return { supplyTypeOptions, convertIdToSupplyTypeName };
};
