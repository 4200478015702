import { useCallback, useContext, useMemo } from "react";
import { LanguageContext } from "../views/common/localization/localization";
import { ROLE_ID } from "./constant";

export const useRoleUtility = () => {
  const { words } = useContext(LanguageContext);

  const roleNames = useMemo(
    () => [
      { id: ROLE_ID.ALL, name: words.role_all },
      { id: ROLE_ID.SYSTEM, name: words.role_system_administrator },
      { id: ROLE_ID.TENANT, name: words.role_tenant_administrator },
      { id: ROLE_ID.MULTI_AREA, name: words.role_multi_area_administrator },
      { id: ROLE_ID.SINGLE_AREA, name: words.role_area_plan_submitter },
      { id: ROLE_ID.POWER, name: words.role_power_plant },
      { id: ROLE_ID.DEMAND, name: words.role_demand },
      { id: ROLE_ID.FIT, name: words.role_fit_administrator },
    ],
    [words]
  );

  const roleOptions = useCallback(
    (roles: string[], unselectable = true) => {
      return roleNames.filter(
        (v) =>
          roles.find((e) => e === v.id) ||
          (v.id === ROLE_ID.ALL && unselectable)
      );
    },
    [roleNames]
  );

  const convertIdToRoleName = useCallback(
    (id: string) => {
      return roleNames.find((v) => v.id === id)?.name;
    },
    [roleNames]
  );

  return { roleOptions, convertIdToRoleName };
};
