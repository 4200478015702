import { Theme, createTheme } from "@mui/material/styles";
import { PaletteMode, TableCell } from "@mui/material";
import "../../theme.css";
import styled from "styled-components";
import { StyledTableCell } from "./styledComponents/styledTable";
import { borders } from "@mui/system";

//引数:パレットモード
//引数をもとにThemaを作成して返却するメソッド
export const getTheme = (mode: PaletteMode): Theme => {
  //Thema
  if (mode === "dark") {
    return createTheme({
      components: {

        // タブの背景色
        MuiTabs: {
          styleOverrides: {
            root: {
              backgroundColor: "#2E2E2E",
            },
          },
        },
        // 英語化対応:タブの文字列が勝手に大文字にならないよう抑制
        MuiTab: {
          styleOverrides: {
            textColorPrimary: {
              textTransform: "none",
            },
          },
        },
        
        //テーブルの設定
        MuiTable: {
          styleOverrides: {
            root: {
              border: "1px solid #808080",
            },
          },
        },        
        // テーブルセル
        MuiTableCell: {
          styleOverrides: {
            head: {
              backgroundColor: "#3E3E3E",
              color: "#ffffff",
              borderRight: "1px solid #808080",
              borderBottom: "1px solid #808080",
            },
            body: {
              backgroundColor: "#2E2E2E",
              color: "#ffffff",
              borderRight: "1px solid #808080",
              borderBottom: "1px solid #808080",
            },
          },
        },
        
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              backgroundColor: "#2E2E2E",
            },
          },
        },

        // 保存ボタンやカレンダーのボタンが同居している
        // 個別にカスタムコンポーネントを作らないと全部変更してしまう
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },

        //typography部の文字列を白文字に変更
        MuiTypography: {
          styleOverrides: {
            root: {
              color: "#ffffff",
            },
          },
        },

        MuiCard: {
          styleOverrides: {
            root: {
              backgroundColor: "#2e2e2e",
            },
          },
        },
       
      },

      palette: {
        mode: mode,
        primary: {
          main: "#90CAF9", // ボタン系の主な色
        },
        secondary: {
          main: "#bdbdbd", // ヘッダーで使っているボタン系の色
        },
        // divider: "#81d4fa",
        background: {
          paper: "#3e3e3e",
        },
        text: {
          // primary: "#616161",
          primary: "#ffffff",
        },
      },
    });


  //lightならば
  } else {
    return createTheme({
      components: {
      
        // タブの背景色
        MuiTabs: {
          styleOverrides: {
            root: {
              backgroundColor: "#ffffff",
            },
          },
        },
        // 英語化対応:文字列が勝手に大文字にならないよう抑制
        MuiTab: {
          styleOverrides: {
            textColorPrimary: {
              textTransform: "none",
            },
          },
        },

        MuiTable: {
          styleOverrides: {
            root: {
              border: "1px solid #d3d3d3",
              borderBottom: "1px solid #d3d3d3",
            },
          },
        },
        //styleTableコンポーネント類の形式オーバーライド
        MuiTableCell: {
          styleOverrides: {
            head: {
              backgroundColor: "#F5F5F5",
              color: "#121212",
              borderRight: "1px solid #d3d3d3",
              borderBottom: "1px solid #d3d3d3",
            },
            body: {
              backgroundColor: "#ffffff",
              color: "#121212",
              borderRight: "1px solid #d3d3d3",
              borderBottom: "1px solid #d3d3d3",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "none",
            },
          },
        },

        //typography部の文字列を黒文字に変更
        MuiTypography: {
          styleOverrides: {
            root: {
              color: "#000000",
            },
          },
        },

      },
      palette: {
        mode: mode,
        // primary: {
        //   main: "#CFD8DC ",
        // },
        secondary: {
          main: "#121212", // ヘッダーで使っているボタン系の色
        },
        background: {
          paper: "#ffffff",
        },
        // text: {
        //   primary: "#000000 ",
        // },
      },
    });
  }
};
