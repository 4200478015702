import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { ChartData, Chart as ChartJS } from "chart.js";
import { Chart } from "react-chartjs-2";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import zoomPlugin from "chartjs-plugin-zoom";
// import { tableData } from "./TestData";
import { TimeTable } from "./CreateTime";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../common/styledComponents/styledTable";
import {
  powerInbalanceInfo,
  powerInbalanceEria,
  powerInbalancePowerPlant,
  Inbalance,
  threshold,
} from "../../../../backend/types/power_transmission/PowerGenerationMonitoring";
import { ValueOptions } from "@mui/x-data-grid";
import { LanguageContext } from "../common/localization/localization";
import { AreaContext } from "../common/customComponents/CustomAreaContext";
import { LeavePageContext } from "../common/customComponents/CustomConfirmLeavePage";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { DateContext } from "../common/customComponents/GlobalDatePicker";
import { TimeContext } from "../common/globalMenu/LiveClock";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import {
  CustomModalProgress,
  CustomProgress,
} from "../common/customComponents/CustomProgress";
import { PaletteContext } from "../common/paletteMode";
import { POWER_FORECAST_URL } from "../../common/url";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { format } from "date-fns";
import { useColorUtility } from "../../common/color";

// チャートのデータセットの型
type datasetType = {
  type: "line" | "bar";
  label: string;
  borderColor?: string;
  // borderWidth: number;
  stepped?: "middle" | false;
  data: number[];
  yAxisID: string;
  categoryPercentage?: number;
  backgroundColor?: string;
  borderDash?: number[];
};

//30分刻みの時間の文字列の配列
const timeData = TimeTable();

const GET_POWER_MONITOR = "/getPowerGenerationMonitoring";
const GET_UPPER_LOWER = "/getUpperLower";
const AREA_LIST_URL = "/getAreaNameList";

//以下数行は上書きしたスタイルドコンポーネントが置いてある
const HeaderCell = styled(StyledTableHeaderLG)`
  && {
    text-align: center;
    padding: 5px;
    font-size: 14px;
  }
`;

const TableCell = styled(StyledTableCell)`
  && {
    text-align: right;
    padding: 10px;
    font-size: 14px;
  }
`;

const AreaHeader = styled(HeaderCell)`
  && {
    width: calc(100% - 10px);
  }
`;

const DivergenceCell = styled(AreaHeader)`
  && {
    //padding: 10px 35px;
  }
`;

//テーブルのヘッダーの色
const TableHeaderDateColor = [
  "rgba(0,100,200,0.75)",
  "rgba(255,100,0,0.75)",
  "rgba(0,255,255,0.75)",
  "rgba(0,200,0,0.75)",
  "rgba(150,0,255,0.75)",
  "rgba(255,200,200,0.75)",
  "rgba(0,0,255,0.75)",
  "rgba(0,100,200,0.75)",
  "rgba(255,100,0,0.75)",
  "rgba(0,255,255,0.75)",
  "rgba(0,200,0,0.75)",
  "rgba(150,0,255,0.75)",
  "rgba(255,200,200,0.75)",
  "rgba(0,0,255,0.75)",
  "rgba(0,100,200,0.75)",
  "rgba(255,100,0,0.75)",
  "rgba(0,255,255,0.75)",
  "rgba(0,200,0,0.75)",
  "rgba(150,0,255,0.75)",
  "rgba(255,200,200,0.75)",
  "rgba(0,0,255,0.75)",
];

const colors: { [key: string]: string } = {
  upperLimitBorderColor: "rgb(75,172,198)",
  lowerLimitBorderColor: "rgb(255,204,255)",
  UpupperLimitBroderColor: "rgb(188,226,232)",
  LowlowerLimitBorderColor: "rgb(240,230,140)",
  demandPlanBorderColor: "rgb(255,0,0)",
  chartDragBackgroundColor: "rgba(225,0,0,0.3)",
  tableHeadBackgroundColor: "rgb(220,230,241)",
  tableBodyBackgroundColor: "rgb(255,255,255)",
  divergenceNormalColor: "rgb(0,0,0)",
  DivergenceMinusColor: "rgb(0,0,255)",
};

//ボタン
const ButtonColor = () => {
  const { PaletteMode } = useContext(PaletteContext);
  return PaletteMode === "dark" ? "white" : "black";
};
const ButtonLink = styled(Button)`
  &.MuiButton-root {
    height: 100%;
    width: 100%;
    border-radius: 0px;
    color: ${ButtonColor};
    font-size: 14px;
  }
` as typeof Button;

//chart.jsでズーム機能を使えるように登録
ChartJS.register(zoomPlugin);

const initInbalanceInfo = (): powerInbalanceInfo => {
  return {
    targetDate: format(new Date(), "yyyy-MM-dd"),
    area: 0,
    ariaValue: {
      targetDate: format(new Date(), "yyyy-MM-dd"),
      area: 0,
      value: new Array<Inbalance>(48).fill({
        time: 0,
        actual: 0,
        plan: 0,
        deviationValue: 0,
        deviationRate: 0,
      }),
    },
    powerPlantValue: [],
  };
};

//発電インバランス画面
const PowerGenerationMonitoring = () => {
  //言語を切り替えるデータコンテキスト
  const languageContext = useContext(LanguageContext);
  const { areaId, setAreaId } = useContext(AreaContext);
  const leavePageContext = useContext(LeavePageContext);

  const api = useAuthedApi();

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);
  const { borderColor } = useColorUtility();
  //DBから取ってきたデータを入れるstate、chartの情報もテーブルの情報もすべて入っている。1つに入れすぎな気もする
  const [mappingData, setMappingData] = useState<powerInbalanceInfo>(
    initInbalanceInfo()
  );

  //上限下限上上限下下限
  const [alllimit, setAlllimit] = useState<threshold[]>(
    Array(48)
      .fill(true)
      .map((_) => {
        return {
          upper_limit: 0,
          lower_limit: 0,
          u_upper_limit: 0,
          l_lower_limit: 0,
        };
      })
  );

  //DBからデータを取得するまで画面描画を塞き止めるためのstate
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [isLoadingAreas, setIsLoadingAreas] = useState<boolean>(false);
  const [isLoadingInbalance, setIsLoadingInbalance] = useState<boolean>(false);
  const isLoading = isLoadingAreas || isLoadingInbalance;

  //エラー用のstate
  const [error, setError] = useState<any>();

  //グラフの最大高を決めるstate、DBから受け取った値の中でジャンル問わず一番大きな数値を格納して、グラフの天井を決める
  const [maxHeight, setMaxHeight] = useState<number>(0);

  const dateValue = useContext(DateContext);
  const timeValue = useContext(TimeContext);

  //エリア一覧
  const [areaList, setAreaList] = useState<string[]>([]);
  const { areaOptions } = useAreaUtility();
  const url = useLocation().search;
  const query = new URLSearchParams(url);

  // 画面自動更新
  const [time, setTime] = useState(new Date());

  // グラフのデータセット
  const [upperLimit, setUpperLimit] = useState<number[]>([]);
  const [lowerLimit, setLowerLimit] = useState<number[]>([]);
  const [u_upperLimit, setU_upperLimit] = useState<number[]>([]);
  const [l_lowerLimit, setL_lowerLimit] = useState<number[]>([]);
  const [planTotal, setPlanTotal] = useState<number[]>([]); //エリア合計発電計画
  const [inbalanceInfos, setInbalanceInfos] = useState<
    powerInbalancePowerPlant[]
  >([]); //発電所毎インバランス情報

  //画面遷移時にDBからデータを取ってくる
  useEffect(() => {
    initAreaList();
    leavePageContext.setBlockLeave(false);
  }, []);

  useEffect(() => {
    // 30で画面を更新
    if (areaList.length) {
      getData();

      const id = setInterval(() => {
        setTime(new Date());
        getData();
        //console.log("タイマー実行確認");
      }, 1800000);
      //30分⇒ 1800000
      return () => clearInterval(id);
    }
    setIsLoaded(true);
  }, [areaList, time, areaId]);

  const getData = async () => {
    console.log("axiosget");
    await axiosGet();
    // await getUpperLower();
  };

  // 実績と計画の乖離を取得
  const axiosGet = async () => {
    //console.log("axiosget");
    setIsLoadingInbalance(true);
    api
      .get(GET_POWER_MONITOR, { params: { areaId: areaId } })
      .then(async (res) => {
        const inbalanceInfos = res.data as powerInbalanceInfo;
        setPlanTotal(inbalanceInfos.ariaValue.value.map((data) => data.plan));
        setInbalanceInfos(inbalanceInfos.powerPlantValue);
        setMappingData(inbalanceInfos);
        await getUpperLower(inbalanceInfos as powerInbalanceInfo);
      })
      .catch((err) => {
        setError(error);
        //console.log("エラーで拾えてない");
      });
  };

  const getUpperLower = async (powerInbalanceData: powerInbalanceInfo) => {
    api
      .get(GET_UPPER_LOWER, { params: { areaId: areaId } })
      .then(async (res) => {
        const response = res.data as threshold[];
        //上限
        setUpperLimit(
          response.map(
            (value, index) =>
              Number(value.upper_limit) +
              Number(powerInbalanceData.ariaValue.value[index].plan)
          )
        );
        //下限
        setLowerLimit(
          response.map(
            (value, index) =>
              Number(powerInbalanceData.ariaValue.value[index].plan) -
              Number(value.upper_limit)
          )
        );
        //上上限
        setU_upperLimit(
          response.map(
            (value, index) =>
              Number(value.u_upper_limit) +
              Number(powerInbalanceData.ariaValue.value[index].plan)
          )
        );
        //下下限
        setL_lowerLimit(
          response.map(
            (value, index) =>
              Number(powerInbalanceData.ariaValue.value[index].plan) -
              Number(value.u_upper_limit)
          )
        );
        setMaxHeight(getMaxHeight(res.data as threshold[], powerInbalanceData));
        setIsLoaded(true);
        setIsLoadingInbalance(false);
      })
      .catch(() => {
        setError(error);
      });
  };

  // エリア名
  const initAreaList = () => {
    setIsLoadingAreas(true);
    api
      .get(AREA_LIST_URL, { params: null })
      .then((res) => {
        const Serch_area = query.get("areaId");
        setAreaList(res.data.map((v: any) => v.areaId));
        setIsLoadingAreas(false);
        if (Serch_area != null) {
          setAreaId(Serch_area);
        } else if (res.data.length != 0) {
          setAreaId(res.data[0].areaId);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  //chartの中身を作る関数、データセットは上から順に上限・下限・実績で、その下にあるfor文では、そのエリアに存在する需要家の数に応じてループしpush、積み上げ棒グラフのデータを作っている
  const chartData = useMemo(() => {
    const datasets: datasetType[] = [];

    //上限
    datasets.push({
      type: "line" as const,
      label: languageContext.words.upper_limit,
      borderColor: colors.upperLimitborderColor,
      stepped: "middle" as const,
      data: upperLimit,
      yAxisID: "yAxisForLimitValue",
      borderDash: [6, 4],
    });

    //下限
    datasets.push({
      type: "line" as const,
      label: languageContext.words.lower_limit,
      borderColor: colors.lowerLimitBorderColor,
      stepped: "middle" as const,
      data: lowerLimit,
      yAxisID: "yAxisForLimitValue",
      borderDash: [6, 4],
    });

    //上上限
    datasets.push({
      type: "line" as const,
      label: languageContext.words.upper_upper_limits,
      borderColor: colors.UpupperLimitBroderColor,
      stepped: "middle" as const,
      data: u_upperLimit,
      yAxisID: "yAxisForLimitValue",
      borderDash: [6, 4],
    });

    //下下限
    datasets.push({
      type: "line" as const,
      label: languageContext.words.lower_lower_limits,
      borderColor: colors.LowlowerLimitBorderColor,
      stepped: "middle" as const,
      data: l_lowerLimit,
      yAxisID: "yAxisForLimitValue",
      borderDash: [6, 4],
    });

    datasets.push({
      //エリア合計計画値
      type: "line" as const,
      label: languageContext.words.power_gen_plan,
      borderColor: "rgb(255,0,0)",
      stepped: "middle" as const,
      data: planTotal,
      yAxisID: "yAxisForPlanAndActualValue",
    });

    // 発電所毎実績データ
    inbalanceInfos.forEach((info, index) => {
      datasets.push({
        type: "bar" as const,
        label: info.powerPlant,
        backgroundColor: TableHeaderDateColor[index],
        data: info.deviation.map((v) => v.actual),
        yAxisID: "yAxisForPlanAndActualValue",
        categoryPercentage: 1.1,
      });
    });

    return { labels: timeData, datasets: datasets };
  }, [
    languageContext.words,
    planTotal,
    inbalanceInfos,
    upperLimit,
    lowerLimit,
    u_upperLimit,
    l_lowerLimit,
  ]);

  //chartのオプションを作る関数。↑のchart本体は分けてあるのにこちらが分かれていないのは統一性に欠けるので分離、y軸の基準増やしたくなったら増やしやすいし
  const chartOption = useMemo(() => {
    // グラフデフォルト値
    // ダークモード対応でボーダー線の色等を変える
    ChartJS.defaults.color = PaletteMode === "dark" ? "#999" : "#666";
    ChartJS.defaults.borderColor =
      PaletteMode === "dark"
        ? "rgba(255, 255, 255, 0.1)"
        : "rgba(0, 0, 0, 0.1)";
    const options = {
      plugins: {
        legend: {
          position: "top" as const,
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "xy" as const,
            modifierKey: "meta" as const,
            overScaleMode: undefined,
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            drag: {
              enabled: true,
              backgroundColor: "rgba(225,0,0,0.3)",
            },
            mode: "x" as const,
          },
          mode: "x",
          limits: {
            x: {
              min: 0,
              max: 47,
            },
            y: {
              min: 0,
              max: 2.0,
            },
          },
        },
      },
      responsiv: true,
      scales: {
        x: {
          stacked: true,
          max: 48,
          min: 0,
          grid: {
            display: false,
          },
        },
        yAxisForPlanAndActualValue: {
          beginAtZero: true,
          title: {
            display: true,
            text: languageContext.words.powerforecast_energy + "(kWh)",
          },
          stacked: true,
          max: maxHeight,
          min: 0,
        },
        yAxisForLimitValue: {
          position: "right" as const,
          beginAtZero: true,
          stacked: false,
          max: maxHeight,
          min: 0,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
      },
    };
    return options;
  }, [languageContext.words, maxHeight, PaletteMode]);

  //グラフの最大高を決める関数。これがないと実績値などが範囲外に飛び出て見えなくなるかもしれない
  const getMaxHeight = (
    powerInbalanceInfo: threshold[],
    mappingData: powerInbalanceInfo
  ) => {
    //上上限
    let graph_upper = 0;
    for (let i = 0; i < powerInbalanceInfo.length; i++) {
      if (
        graph_upper <
        Number(powerInbalanceInfo[i].u_upper_limit) +
          Number(mappingData.ariaValue.value[i].plan)
      ) {
        graph_upper =
          Number(powerInbalanceInfo[i].u_upper_limit) +
          Number(mappingData.ariaValue.value[i].plan);
      }
    }

    //計画
    let graph_plan = 0;
    for (let j = 0; j < mappingData.ariaValue.value.length; j++) {
      if (graph_plan < Number(mappingData.ariaValue.value[j].plan)) {
        graph_plan = Number(mappingData.ariaValue.value[j].plan);
      }
    }

    //実績
    let graph_actual = 0;
    for (let j = 0; j < mappingData.ariaValue.value.length; j++) {
      if (graph_actual < Number(mappingData.ariaValue.value[j].actual)) {
        graph_actual = Number(mappingData.ariaValue.value[j].actual);
      }
    }

    console.log("グラフ高さ", graph_upper, graph_plan, graph_actual);

    //計画値・実績値の最大値が0のとき、y軸の最大値をデフォルトで1000に設定。計画値・実績値が0の時でも計画値線が0で描画されるようにする。
    //エリア未選択の場合はy軸の最大値設定はしない。
    if (
      mappingData?.area !== 0 &&
      graph_upper === 0 &&
      graph_plan === 0 &&
      graph_actual === 0
    ) {
      return 1000;
    }

    //上上限、計画、実績の中からグラフの高さを決める
    return Math.round(Math.max(graph_upper, graph_plan, graph_actual) * 1.1);
  };

  // 画面遷移関数
  const navigate = useNavigate();
  function transitToOtherPage(url: string) {
    navigate(url);
  }

  //数値をカンマ区切りに変換
  const convertToLocaleString = (value: number) => {
    return Number(value).toLocaleString();
  };

  //発電所毎のテーブルのレンダー
  const powerPlantTableRender = () => {
    if (mappingData.powerPlantValue.length != 0) {
      return (
        <StyledTable
          stickyHeader
          aria-label="sticky table"
          key={"plantTable"}
          sx={{
            borderCollapse: "separate",
            height: "100%",
            width:
            mappingData.powerPlantValue.length > 1
              ? `calc(100% - 10px)`
              : "301px",
            borderTop: "none",
            borderBottom: "none",
          }}
        >
          <TableHead
            sx={{
              display: "block",
              background: "#ccccff",
              position: "sticky",
              top: 0,
              zIndex: 0,
              height: "105px",
              borderTop: borderColor,
            }}
          >
            <TableRow sx={{ display: "block" }}>
              <HeaderCell
                sx={{ display: "block" }}
                style={{
                  height: "calc(100%/3)",
                  width:
                    mappingData.powerPlantValue.length > 1
                      ? `calc(100% - 10px)`
                      : "301px",
                }}
                //colSpan={mappingData.powerPlantValue.length}
              >
                {languageContext.words.power_plant}
              </HeaderCell>
            </TableRow>
            <TableRow sx={{ display: "flex" }}>
              {mappingData.powerPlantValue.map((data, index) => (
                <HeaderCell
                  key={index}
                  sx={{ overflow: "hidden" }}
                  style={{
                    height: "calc(100% / 3)",
                    //width: `calc(100% / ${mappingData.powerPlantValue.length})`,
                    width:
                      mappingData.powerPlantValue.length > 1
                        ? "146.5px"
                        : "301px",
                  }}
                >
                  <ButtonLink
                    //component={Link}
                    //to={"/demandforecast"}
                    style={{
                      textTransform: "none",
                      padding: 0,
                      textDecoration: "underline",
                      display: "block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    disableFocusRipple
                    onClick={() => {
                      dateValue.setPickDate(time);
                      transitToOtherPage(
                        POWER_FORECAST_URL +
                          "?areaId=" +
                          ("00" + data.area).slice(-2) +
                          "&bgId=" +
                          data.powerbg +
                          "&plantId=" +
                          data.power
                      );
                    }}
                    title={data.powerPlant}
                  >
                    {data.powerPlant}
                  </ButtonLink>
                </HeaderCell>
              ))}
            </TableRow>
            <TableRow sx={{ display: "flex" }}>
              {mappingData.powerPlantValue.map((data, index) => (
                <HeaderCell
                  key={index}
                  sx={{
                    //width: `calc(100% / ${mappingData.powerPlantValue.length})`,
                    width:
                      mappingData.powerPlantValue.length > 1
                        ? "146.5px"
                        : "301px",
                    height: "calc(100%/3)",
                  }}
                >
                  {languageContext.words.deviation + "(kWh)"}
                </HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <ScrollSyncPane group="vertical">
            <TableBody
              sx={{
                display: "block",
                overflow: "scroll",
                "&::-webkit-scrollbar": {
                  width: "0px",
                  backgroundColor: "transparent",
                  height: "0px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#AAA",
                  borderRadius: "0px",
                },
                background: "transparent",
              }}
            >
              {mappingData.ariaValue.value.map((data, index) => {
                return (
                  <TableRow key={index} sx={{ display: "flex" }}>
                    {mappingData.powerPlantValue.map(
                      (powerPlantData, tableIndex) => {
                        let fountColor = "rgb(0,0,0)";
                        if (
                          0 < powerPlantData.deviation[index].deviationValue
                        ) {
                          fountColor = "rgb(0,0,255)";
                        } else if (
                          powerPlantData.deviation[index].deviationValue < 0
                        ) {
                          fountColor = "rgb(255,0,0)";
                        } else {
                          fountColor = "";
                        }
                        return (
                          <TableCell
                            key={"data" + index + "_" + tableIndex}
                            style={{
                              //width: `calc(100% / ${mappingData.powerPlantValue.length} - 21px)`,
                              width:
                                mappingData.powerPlantValue.length > 1
                                  ? "136.5px"
                                  : "291px",
                            }}
                          >
                            <label style={{ color: fountColor }}>
                              {powerPlantData.deviation[index].deviationValue}
                            </label>
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </ScrollSyncPane>
        </StyledTable>
      );
    }
  };

  //テーブルのレンダー
  const TabelRender = () => {
    return (
      <ScrollSync>
        <TableContainer
          sx={{
            width: "100%",
            height: "100%",
            display: "flexed",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor:
                PaletteMode === "dark" ? "#5e5e5e" : "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: PaletteMode === "dark" ? "white" : "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          <StyledTable
            sx={{
              position: "sticky",
              width: "65%",
              height: "100%",
              left: 0,
              zIndex: 1,
              borderTop: "none",
              borderBottom: "none",
            }}
          >
            <TableHead
              sx={{
                position: "sticky",
                display: "block",
                background: "rgb(220,230,241)",
                top: 0,
                zIndex: 1,
                height: "105px",
                borderTop: borderColor,
              }}
            >
              <TableRow
                sx={{
                  height: "105px",
                  width: "100%",
                  display: "flex",
                }}
              >
                <HeaderCell
                  style={{
                    width: "calc(20% - 12px)",
                    height: "calc(100% - 11px)",
                    lineHeight: "105px",
                  }}
                >
                  {languageContext.words.timeB}
                </HeaderCell>
                <TableRow
                  sx={{ display: "block" }}
                  style={{ width: "calc(80% + 1px)" }}
                >
                  <HeaderCell
                    sx={{
                      display: "block",
                    }}
                    style={{
                      width: "calc(100% - 11px)",
                      height: "calc(100% / 3 - 11px)",
                    }}
                  >
                    {languageContext.words.area}
                  </HeaderCell>
                  <TableRow
                    sx={{ height: "calc(100% / 3 * 2)", display: "flex" }}
                  >
                    <AreaHeader style={{ overflow: "hidden" }}>
                      <HeaderCell
                        sx={{
                          display: "block",
                        }}
                        style={{
                          border: "none",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        title={languageContext.words.power_gen_results}
                      >
                        {languageContext.words.power_gen_results}
                        <br />
                        (kWh)
                      </HeaderCell>
                    </AreaHeader>
                    <AreaHeader
                      sx={{ display: "block" }}
                      style={{ overflow: "hidden" }}
                    >
                      <HeaderCell
                        sx={{ display: "block" }}
                        style={{
                          border: "none",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        title={languageContext.words.power_gen_plan}
                      >
                        {languageContext.words.power_gen_plan}
                        <br />
                        (kWh)
                      </HeaderCell>
                    </AreaHeader>
                    <AreaHeader
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <HeaderCell
                        sx={{ display: "block" }}
                        style={{
                          border: "none",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        title={languageContext.words.deviation}
                      >
                        {languageContext.words.deviation}
                        <br />
                        (kWh)
                      </HeaderCell>
                    </AreaHeader>
                    <AreaHeader
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <HeaderCell
                        sx={{ display: "block" }}
                        style={{
                          border: "none",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        title={languageContext.words.deviation_ratio}
                      >
                        {languageContext.words.deviation_ratio}
                        <br />
                        (%)
                      </HeaderCell>
                    </AreaHeader>
                  </TableRow>
                </TableRow>
              </TableRow>
            </TableHead>
            <ScrollSyncPane group="vertical">
              <TableBody
                sx={{
                  display: "block",
                  background: "rgb(255,255,255)",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    width: "0px",
                    backgroundColor: "transparent",
                    height: "0px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#AAA",
                    borderRadius: "0px",
                  },
                }}
              >
                {mappingData.ariaValue.value.map((data, index) => {
                  let divergenceDataFountColor = "rgb(0,0,0)";
                  if (0 < mappingData.ariaValue.value[index].deviationValue) {
                    divergenceDataFountColor = "rgb(0,0,255)";
                  } else if (
                    mappingData.ariaValue.value[index].deviationValue < 0
                  ) {
                    divergenceDataFountColor = "rgb(255,0,0)";
                  } else {
                    divergenceDataFountColor = "";
                  }
                  let deviationRatioDataFountColor = "rgb(0,0,0)";
                  if (0 < mappingData.ariaValue.value[index].deviationRate) {
                    deviationRatioDataFountColor = "rgb(0,0,255)";
                  } else if (
                    mappingData.ariaValue.value[index].deviationRate < 0
                  ) {
                    deviationRatioDataFountColor = "rgb(255,0,0)";
                  } else {
                    deviationRatioDataFountColor = "";
                  }
                  return (
                    <TableRow key={index}>
                      <StyledTableCell
                        align="center"
                        sx={{
                          width: "107px",
                          fontSize: "14px",
                          padding: "5px",
                        }}
                      >
                        {timeData[index]}～{timeData[index + 1]}
                      </StyledTableCell>
                      <TableCell sx={{ width: "97px" }}>
                        {convertToLocaleString(data.actual)}
                        {/* 発電実績 */}
                      </TableCell>
                      <TableCell sx={{ width: "97px" }}>
                        {convertToLocaleString(data.plan)}
                        {/*発電計画 */}
                      </TableCell>
                      <TableCell sx={{ width: "97px" }}>
                        <label style={{ color: divergenceDataFountColor }}>
                          {convertToLocaleString(data.deviationValue)}
                          {/* エリア乖離 */}
                        </label>
                      </TableCell>
                      <TableCell sx={{ width: "98px" }}>
                        <label style={{ color: deviationRatioDataFountColor }}>
                          {data.deviationRate.toFixed(1)}
                        </label>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </ScrollSyncPane>
          </StyledTable>
          {powerPlantTableRender()}
        </TableContainer>
      </ScrollSync>
    );
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <CustomModalProgress open={true} />;
  }
  return (
    <div
      //ダークモード対応
      className={`cn-main-display ${PaletteMode === "dark" ? "dark" : "light"}`}
    >
      <Box sx={{ width: "100%", height: "5%", margin: "-8px 0px 10px 8px" }}>
        <CustomSelectList
          key={"demandMonitoring_CustomSelectList_area"}
          // label={languageContext.words.all_areas}
          value={areaId}
          // defaultValue={0}
          options={areaOptions(areaList)}
          onChange={setAreaId}
        />
      </Box>
      <Stack sx={{ width: "100%", height: "95%" }} spacing={1} direction="row">
        <Box sx={{ width: "50%", height: "100%" }}>
          <ChartRender data={chartData} options={chartOption} />
        </Box>
        <Box sx={{ width: "50%", height: "100%" }}>{TabelRender()}</Box>
      </Stack>
      <CustomModalProgress open={isLoading} />
    </div>
  );
};

//チャートのレンダー
// eslint-disable-next-line react/display-name
const ChartRender = (props: any) => {
  const { data, options } = props;
  const ref = React.useRef<ChartJS>(null);

  return (
    <Chart
      redraw
      type={"bar"}
      data={data}
      options={options}
      height={9}
      width={10}
      ref={ref}
      onDoubleClick={() => {
        if (ref && ref.current) {
          ref.current.resetZoom();
        }
      }}
    />
  );
};

export default PowerGenerationMonitoring;
