import React, { useContext, useEffect, useState } from "react";
import { Chart as ChartJS } from "chart.js";
import FormControl from "@mui/material/FormControl";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";

import { LanguageContext } from "../../common/localization/localization";
import axios from "axios";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import api from "../../../common/axios";
import { resReportDownload } from "../../../types/occto/BalancingPlan";
import CustomModal from "../../common/customComponents/CustomModal";
import { StyledButton } from "../../common/styledComponents/styledButton";
import { PaletteContext } from "../../common/paletteMode";
import { useAreaUtility } from "../../../common/area";
import { AREA_ID } from "../../../common/constant";
import { useAuthedApi } from "../../../common/axios";
import CustomDialog, { ButtonType } from "../../common/customComponents/CustomDialog";

ChartJS.register(zoomPlugin);
const GET_EVENT_LIST = "/getEventList";
const DOWNLOAD_EVENT_LIST = "/downloadEventCsv";

// お知らせ一覧
export interface notificationListType {
  eventDate: string;
  kind: string;
  targetDate: string;
  area: string;
  bg: string;
  message: string;
}

const StyledButtonRatherLarge = styled(StyledButton)`
  && {
    heigth: 24px;
    min-height: 30px;
    min-width: 150px;
    padding: 0px 0px;
    margin-top: 8px;
  }
`;

const AREA_LIST_URL = "/getAreaNameList";

// お知らせ一覧画面
const NotificationList = () => {
  //セレクトタブに表示するデータ

  //言語を切り替えるデータコンテキスト
  const languageContext = useContext(LanguageContext);

  //エリア一覧
  type searchInfo = { id: number; name: string };
  const [areaList, setAreaList] = useState<string[]>([]);
  const { areaOptions, convertIdToAreaName } = useAreaUtility();

  const [error, setError] = useState<any>();

  //お知らせのセレクトボックス
  const [notificationList, setnotificationList] = useState<
    notificationListType[]
  >([]);
  //ボタンのダイアログ
  const [digOpenDownroadButton, setDigOpenDownroadButton] = useState(false);
  //ダウンロードボタン
  const [downroadButtonActivity, setDownroadButtonActivity] =
    React.useState(true);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  //エリアのセレクトボックス
  const [area, setArea] = useState<string>(AREA_ID.NONE);

  // 通信エラーを監視
  const api = useAuthedApi();

  useEffect(() => {
    getNoftification();
    initAreaList();
  }, []);

  useEffect(() => {
    getNoftification();
  }, [area]);

  // お知らせの取得
  function getNoftification() {
    api
      .get(GET_EVENT_LIST, { params: { areaId: area } })
      .then((res) => {
        if (res.data.length != 0) {
          // お知らせの情報を取得
          setnotificationList(res.data);
        } else {
          // お知らせ情報なし
        }
      })
      .catch((error) => {
        // TODO エラー対応
      });
  }

  // エリア名
  const initAreaList = () => {
    api
      .get(AREA_LIST_URL, { params: null })
      .then((res) => {
        setAreaList(res.data.map((v: any) => v.areaId));
        setArea(AREA_ID.NONE); // エリア
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  //帳票ダウンロード
  const downloadReport = () => {
    api
      .get(DOWNLOAD_EVENT_LIST, { params: { areaId: area } })
      .then((res) => {
        const { reportName, reportData } = res.data as resReportDownload;
        console.log(reportData);
        //BOMを付与する（Excelでの文字化け対策）
        const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const file = new Blob(
          [bom, decodeURIComponent(escape(window.atob(reportData)))],
          {
            type: "text/csv",
          }
        );
        console.log(file);
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.download = reportName;
        link.href = url;
        link.click();

        URL.revokeObjectURL(url);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div
      className={`cn-main-display ${PaletteMode === "dark" ? "dark" : "light"}`}
    >
      <Stack sx={{ width: "100%", height: "10%" }} spacing={1} direction="row">
        <FormControl sx={{ minWidth: 200 }}>
          <CustomSelectList
            key={"demandForecastData_CustomSelectList_area"}
            label={languageContext.words.all_areas}
            value={area}
            defaultValue={0}
            options={areaOptions(areaList)}
            onChange={setArea}
          />
        </FormControl>

        <StyledButtonRatherLarge
          key={"BalancingPlan_DownroadButton"}
          variant="outlined"
          disabled={!downroadButtonActivity}
          onClick={() => setDigOpenDownroadButton(true)}
          sx={{ height: "42px" }}
        >
          {languageContext.words.download}
        </StyledButtonRatherLarge>
      </Stack>
      <TableContainer
        sx={{ minWidth: "100%", height: "90%", display: "flexed" }}
      >
        <StyledTable
          sx={{
            width: "100%",
            height: "100%",
            position: "sticky",
            left: 0,
            zIndex: 1,
            borderCollapse: "separate",
          }}
        >
          <TableHead
            sx={{
              background: "rgb(220,230,241)",
              position: "sticky",
              top: 0,
              zIndex: 1,
              height: "42px",
              padding: "0px",
            }}
          >
            <TableRow>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.date_and_time_of_occurrence}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.target_date}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.area}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.bg}
              </StyledTableHeaderLG>
              <StyledTableHeaderLG
                align="center"
                sx={{ height: "42px", padding: "0px" }}
              >
                {languageContext.words.message}
              </StyledTableHeaderLG>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{ background: "rgb(255,255,255)", overflowY: "scroll" }}
          >
            {notificationList.map((data, index) => {
              return (
                <TableRow key={index}>
                  <StyledTableCell
                    align="center"
                    sx={{ height: "42px", padding: "0px", paddingLeft: "8px" }}
                  >
                    {languageContext.convertDateToLocalizedString(
                      new Date(data.eventDate),
                      "HH:mm:ss"
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ height: "42px", padding: "0px", paddingLeft: "8px" }}
                  >
                    {languageContext.convertDateToLocalizedString(
                      new Date(data.targetDate),
                      "HH:mm:ss"
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ height: "42px", padding: "0px", paddingLeft: "8px" }}
                  >
                    {convertIdToAreaName(data.area)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ height: "42px", padding: "0px", paddingLeft: "8px" }}
                  >
                    {data.bg}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ height: "42px", padding: "0px", paddingLeft: "8px" }}
                  >
                    {data.message}
                  </StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <CustomDialog
        key={"BalancingPlan_DownroadButton_Modal"}
        title={languageContext.words.download}
        open={digOpenDownroadButton}
        onAccept={() => {
          downloadReport();
        }}
        onClose={() => setDigOpenDownroadButton(false)}
        buttonType={ButtonType.OkCancel}
      >
        {languageContext.words.note_download_selected_forms}
      </CustomDialog>
    </div>
  );
};

export default NotificationList;
