import React, { useContext } from "react";
import styled from "styled-components";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import { PaletteContext } from "../paletteMode";

// ヘッダーライトグレーのダークモード対応
const ReGetPaletteLG = () =>{
  const PaletteBase = useContext(PaletteContext);
  return PaletteBase.PaletteMode === "dark" ? "#3E3E3E" : "#F5F5F5"
}
// ヘッダーグレーのダークモード対応
const ReGetPaletteG = () =>{
  const PaletteBase = useContext(PaletteContext);
  return PaletteBase.PaletteMode === "dark" ? "#4E4E4E" : "#e0e0e0"
}

// テーブル
export const StyledTable = styled(Table)`
  && {
    background-color: #00000000;//#f5f5f5;
  }
`;

// ヘッダー ライトグレー
export const StyledTableHeaderLG = styled(TableCell)`
  && {
    background-color: ${ReGetPaletteLG};
    font-size:14px;
  }
`;

// ヘッダー グレー
export const StyledTableHeaderG = styled(TableCell)`
  && {
    background-color: ${ReGetPaletteG};
    font-size:14px;
  }
`;

// セル
export const StyledTableCell = styled(TableCell)`
  && {
    font-size:14px;
  }
`;

// セル
export const StyledTableCellLG = styled(TableCell)`
  && {
    background-color: #f5f5f5;
    font-size:14px;
  }
`;