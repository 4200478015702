import React, { createContext, useContext, useEffect, useState } from "react";
import { format } from "date-fns";
import { LanguageContext } from "../localization/localization";
import { io } from "socket.io-client";

type timeContextType = {
  time: Date;
  setTime: (pickDate: React.SetStateAction<Date>) => void;
};
export const TimeContext = createContext<timeContextType>({
  time: new Date(),
  setTime: (time) => null,
});

export const TimeContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [time, setTime] = useState<Date>(initTime);

  return (
    <TimeContext.Provider value={{ time, setTime }}>
      {children}
    </TimeContext.Provider>
  );
};

const Clock = () => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const { time, setTime } = useContext<timeContextType>(TimeContext);

  useEffect(() => {
    const socket = io();
    socket.on("globalTimer/", (gTime: string) => {
      setTime(new Date(gTime));
    });

    return () => {
      socket.disconnect();
    }
  },[]);

  return (
    <React.Fragment>
      {languageContext.convertDateToLocalizedStringGoodAcu(time)}
    </React.Fragment>
  );
};

//最小単位を分で扱うため切り捨てし、初期値とする
const initTime = () : Date => { 
  const date = new Date();
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export default Clock;
