import React, { ReactNode, createContext, useState } from "react";

type ErrMessageContextType = {
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  initMessageContext: () => void;
};

export const ErrorMessageContext = createContext<ErrMessageContextType>(
  {} as ErrMessageContextType
);

const ErrMessageContextProvider = ({ children }: { children: ReactNode }) => {
  const [message, setMessage] = useState("");
  const initMessageContext = () => {
    setMessage("");
  };
  return (
    <ErrorMessageContext.Provider
      value={{
        message: message,
        setMessage: setMessage,
        initMessageContext: initMessageContext,
      }}
    >
      {children}
    </ErrorMessageContext.Provider>
  );
};

export default ErrMessageContextProvider;
