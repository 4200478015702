import React, { useContext } from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CustomModal from "../../common/customComponents/CustomModal";
import TextField from "@mui/material/TextField";
import { CreateDataByDay } from "./0314_OneHourTestData";
import { TimeTable } from "./CreateTime";
import { StyledButton } from "../../common/styledComponents/styledButton";
import {
  StyledTable,
  StyledTableHeaderG,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import { LanguageContext } from "../../common/localization/localization";
import {
  BidingCell,
  BulkBid,
  ariaBitCell,
} from "../../../../../backend/types/jepx/OneHourAdvanceBidPlan";
import axios, { AxiosError } from "axios";
import { useAuthedApi } from "../../../common/axios";
import { PaletteContext } from "../../common/paletteMode";

// 入札制限
export interface bitLimmitReq {
  area: number;
  // target: number, // 売り or 買い
  dayLimitBit: bitLimitCells[];
}

// 1日の48コマ(制限)
export interface bitLimitCells {
  type: number;
  cells: bitLimitCell[];
}

// 1コマ(制限)
export interface bitLimitCell {
  upperPowerVolume: string;
  lowerPowerVolume: string;
  upperPowerPrice: string;
  lowerPowerPrice: string;
}

//30分刻みの時間の文字列の配列
const timeData = TimeTable();

//テーブルのヘッダー
const HeaderCellLG = styled(StyledTableHeaderLG)`
  && {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
  }
`;

//テーブルのヘッダー
const HeaderCellG = styled(StyledTableHeaderG)`
  && {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
    border-bottom: 1px solid lightgray;
  }
`;

//テーブルのヘッダー
const DataHeaderCellLG = styled(HeaderCellLG)`
  && {
    width: 74px;
  }
`;

//テーブルのヘッダー
const DataHeaderCellG = styled(HeaderCellG)`
  && {
    width: 74px;
  }
`;

//テーブルのセル
const TableCell = styled(StyledTableCell)`
  && {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
  }
`;

//74pxに合わせるテーブルのセル
const DataTableCell = styled(TableCell)`
  && {
    width: 75px;
    padding-right: 8px;
    box-sizing: border-box;
  }
`;

const BULK_BIDDING = "/bulkBidIntraday"; //一括入札実行
const POST_BULK_BID_DATA = "/postBulkBid"; //一括入札情報登録

//一括入札のテーブルのレンダー
const BulkBidTableRender = (props: any) => {
  const {
    TableDisabled,
    areaValue,
    tableColor,
    limit,
    inputBidAmount,
    inputBidPrice,
    BidAmountHandle,
    AmountFocusHandle,
    BidPriceHandle,
    PriceFocusHandle,
    setInputBidPrice,
    setInputBidAmount,
    dayBidPrice,
    dayBidValue,
    createToolTipText,
  } = props;
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const { PaletteMode } = useContext(PaletteContext);
  const tableDisabled = TableDisabled();
  const initialPrice: string[] = dayBidPrice;
  const initialAmount: string[] = dayBidValue;

  React.useEffect(() => {
    setInputBidPrice(initialPrice.map((value) => Number(value).toFixed(2)));
    setInputBidAmount(initialAmount.map((value) => Number(value).toFixed(2)));
  }, []);

  //小数点第二位までかつカンマ区切りに変換
  const ConvertToLocaleString = (value: number) => {
    return Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  //活性非活性とダークモード・ライトモードを見てテキストフィールドの色を返す
  const createTextFieldColor = (index: number) => {
    if (!tableDisabled[index] && tableColor[index] !== "") {
      return PaletteMode === "dark" ? "rgb(90,90,90)" : "rgb(200,200,200)";
    } else {
      return "";
    }
  };

  return (
    <TableContainer
      sx={{
        width: "100%",
        height: "95%",
      }}
    >
      <StyledTable
        sx={{
          width: "100%",
          height: "100%",
          borderCollapse: "separate",
        }}
      >
        <TableHead
          sx={{
            width: "100%",
            height: "auto",
            display: "block",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TableRow sx={{ display: "flex" }}>
            <HeaderCellLG
              sx={{
                width: "120px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {languageContext.words.timeB}
            </HeaderCellLG>
            <HeaderCellLG
              sx={{
                width: "41px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {languageContext.words.status_jokyo}
            </HeaderCellLG>
            <TableRow sx={{ width: "150px", display: "block" }}>
              <HeaderCellLG sx={{ width: "150px" }}>
                {languageContext.words.bidding}
              </HeaderCellLG>
              <TableRow sx={{ display: "flex", height: "83px" }}>
                <DataHeaderCellLG
                  sx={{
                    width: "calc(50%)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  title={
                    languageContext.words.bid_price +
                    " (" +
                    languageContext.words.yen_kwh +
                    ")"
                  }
                >
                  {languageContext.words.bid_price}
                  <br />({languageContext.words.yen_kwh})
                </DataHeaderCellLG>
                <DataHeaderCellLG
                  sx={{
                    width: "calc(50%)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  title={languageContext.words.bidding_volume + " (MWh/h)"}
                >
                  <Box
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {languageContext.words.bidding_volume}
                    <br />
                    (MWh/h)
                  </Box>
                </DataHeaderCellLG>
              </TableRow>
            </TableRow>
            <TableRow sx={{ width: "375px", display: "block" }}>
              <HeaderCellG sx={{ width: "375px" }}>
                {languageContext.words.entire_area}
              </HeaderCellG>
              <TableRow sx={{ width: "375px", display: "flex" }}>
                <DataHeaderCellG
                  sx={{
                    width: "calc(20%)",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  title={
                    languageContext.words.sell +
                    " " +
                    languageContext.words.lowest_price_sell +
                    " (" +
                    languageContext.words.yen_kwh +
                    ")"
                  }
                >
                  {languageContext.words.sell}
                  <br />
                  {languageContext.words.lowest_price_sell}
                  <br />({languageContext.words.yen_kwh})
                </DataHeaderCellG>
                <DataHeaderCellG
                  sx={{
                    width: "calc(20%)",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  title={
                    languageContext.words.buy +
                    " " +
                    languageContext.words.highest_price_buy +
                    " (" +
                    languageContext.words.yen_kwh +
                    ")"
                  }
                >
                  {languageContext.words.buy}
                  <br />
                  {languageContext.words.highest_price_buy}
                  <br />({languageContext.words.yen_kwh})
                </DataHeaderCellG>
                <DataHeaderCellG
                  sx={{
                    width: "calc(20%)",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  title={
                    languageContext.words.latest +
                    " " +
                    languageContext.words.contrac_price +
                    " (" +
                    languageContext.words.yen_kwh +
                    ")"
                  }
                >
                  {languageContext.words.latest}
                  <br />
                  {languageContext.words.contrac_price}
                  <br />({languageContext.words.yen_kwh})
                </DataHeaderCellG>
                <DataHeaderCellG
                  sx={{
                    width: "calc(20%)",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  title={
                    languageContext.words.average +
                    " " +
                    languageContext.words.contrac_price +
                    " (" +
                    languageContext.words.yen_kwh +
                    ")"
                  }
                >
                  {languageContext.words.average}
                  <br />
                  {languageContext.words.contrac_price}
                  <br />({languageContext.words.yen_kwh})
                </DataHeaderCellG>
                <DataHeaderCellG
                  sx={{
                    width: "calc(20%)",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                  title={
                    languageContext.words.spot +
                    " " +
                    languageContext.words.contrac_price +
                    " (" +
                    languageContext.words.yen_kwh +
                    ")"
                  }
                >
                  {languageContext.words.spot}
                  <br />
                  {languageContext.words.contrac_price}
                  <br />({languageContext.words.yen_kwh})
                </DataHeaderCellG>
              </TableRow>
            </TableRow>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            width: "100%",
            height: "545px",
            overflowX: "hidden",
            overflowY: "scroll",
            display: "block",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor: "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          {areaValue.map((data: ariaBitCell, index: number) => (
            <TableRow key={`${data.averageExecutionPrice + index}`}>
              <TableCell
                sx={{ width: "120px" }}
                style={{ backgroundColor: tableColor[index] }}
              >
                {timeData[index]}～{timeData[index + 1]}
              </TableCell>
              <TableCell
                sx={{ width: "41px" }}
                style={{ backgroundColor: tableColor[index] }}
              >
                {data.productStatus}
              </TableCell>
              <TableCell
                sx={{ width: "74px" }}
                style={{ backgroundColor: tableColor[index] }}
              >
                <TextField
                  title={createToolTipText(
                    limit?.dayLimitBit[inputBidAmount[index] < 0 ? 1 : 0].cells[
                      index
                    ].lowerPowerPrice,
                    limit?.dayLimitBit[inputBidAmount[index] < 0 ? 1 : 0].cells[
                      index
                    ].upperPowerPrice,
                    languageContext.words.yen_kwh
                  )}
                  type="number"
                  inputProps={{
                    step: "0.01",
                    max: limit.dayLimitBit[
                      Number(inputBidAmount[index]) < 0 ? 1 : 0
                    ].cells[index].upperPowerPrice,
                    // min: limit.dayLimitBit[
                    //   Number(inputBidAmount[index]) < 0 ? 1 : 0
                    // ].cells[index].lowerPowerPrice,
                    min: 0,
                    style: {
                      backgroundColor: createTextFieldColor(index),
                      padding: "0px",
                      textAlign: "right",
                      fontSize: "14px",
                    },
                  }}
                  disabled={!tableDisabled[index]}
                  value={inputBidPrice[index]}
                  onFocus={(e) => {
                    if (inputBidPrice[index] === "0.00") {
                      e.currentTarget.select();
                    }
                  }}
                  onBlur={(event) =>
                    PriceFocusHandle(
                      event.target.value,
                      limit.dayLimitBit[inputBidAmount[index] < 0 ? 1 : 0]
                        .cells[index].upperPowerPrice,
                      limit.dayLimitBit[
                        Number(inputBidAmount[index]) < 0 ? 1 : 0
                      ].cells[index].lowerPowerPrice,
                      index
                    )
                  }
                  onKeyDown={(event) => {
                    if (
                      event.key === "e" ||
                      event.key === "E" ||
                      event.key === "-"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(event) =>
                    BidPriceHandle(index, event.target.value)
                  }
                ></TextField>
              </TableCell>
              <TableCell
                sx={{ width: "74px" }}
                style={{ backgroundColor: tableColor[index] }}
              >
                <TextField
                  title={createToolTipText(
                    limit?.dayLimitBit[inputBidAmount[index] < 0 ? 1 : 0].cells[
                      index
                    ].lowerPowerVolume,
                    Number(
                      limit?.dayLimitBit[inputBidAmount[index] < 0 ? 1 : 0]
                        .cells[index].upperPowerVolume *
                        (inputBidAmount[index] < 0 ? -1 : 1)
                    ).toFixed(2),
                    "MWh/h"
                  )}
                  type="number"
                  inputProps={{
                    max: limit.dayLimitBit[0].cells[index].upperPowerVolume,
                    min:
                      limit.dayLimitBit[1].cells[index].upperPowerVolume * -1,
                    step: "0.1",
                    style: {
                      backgroundColor: createTextFieldColor(index),
                      padding: "0px",
                      textAlign: "right",
                      fontSize: "14px",
                    },
                  }}
                  disabled={!tableDisabled[index]}
                  value={inputBidAmount[index]}
                  onFocus={(e) => {
                    if (inputBidAmount[index] === "0.00") {
                      e.currentTarget.select();
                    }
                  }}
                  onBlur={(event) =>
                    AmountFocusHandle(
                      event.target.value,
                      limit.dayLimitBit[0].cells[index].upperPowerVolume,
                      limit.dayLimitBit[1].cells[index].upperPowerVolume * -1,
                      index
                    )
                  }
                  onKeyDown={(event) => {
                    if (event.key === "e" || event.key === "E") {
                      event.preventDefault();
                    }
                  }}
                  onChange={(event) =>
                    BidAmountHandle(index, event.target.value)
                  }
                ></TextField>
              </TableCell>
              <DataTableCell
                style={{
                  backgroundColor: tableColor[index],
                  textAlign: "right",
                }}
              >
                {ConvertToLocaleString(data.saleBitLowPrice)}
                {/* 売り最安値 */}
              </DataTableCell>
              <DataTableCell
                style={{
                  backgroundColor: tableColor[index],
                  textAlign: "right",
                }}
              >
                {ConvertToLocaleString(data.buyBitHighPrice)}
                {/* 買い最高値 */}
              </DataTableCell>
              <DataTableCell
                style={{
                  backgroundColor: tableColor[index],
                  textAlign: "right",
                }}
              >
                {ConvertToLocaleString(data.newExecutionPrice)}
                {/* 最新約定価格 */}
              </DataTableCell>
              <DataTableCell
                style={{
                  backgroundColor: tableColor[index],
                  textAlign: "right",
                }}
              >
                {ConvertToLocaleString(data.averageExecutionPrice)}
                {/* 平均約定価格 */}
              </DataTableCell>
              <DataTableCell
                style={{
                  backgroundColor: tableColor[index],
                  textAlign: "right",
                }}
              >
                {ConvertToLocaleString(data.averageExecutionPrice)}
              </DataTableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

//一括入札のモーダル
const BulkBit = (
  TableColor: (nowColor: string, OKColor: string, NGColor: string) => string[],
  open: boolean,
  HaldleClose: () => void,
  areaValue: ariaBitCell[],
  dayBid: BidingCell[],
  targetDate: string,
  areaId: string,
  limit: bitLimmitReq,
  getIntradayData: () => Promise<void>,
  setDigOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setDialogMessage: React.Dispatch<React.SetStateAction<string>>,
  salesData: string[],
  procurementData: string[],
  bidDisabled: boolean[],
  hidden: boolean,
  createToolTipText: (
    lowerEnd: string,
    upperEnd: string,
    unit: string
  ) => string
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const { PaletteMode } = useContext(PaletteContext);

  const api = useAuthedApi();

  const tableDisable = TableColor(
    "rgb(255,255,255)",
    "rgb(255,255,255)",
    "rgb(200,200,200)"
  );

  const tableColor = TableColor(
    "",
    "",
    PaletteMode === "dark" ? "rgb(90,90,90)" : "rgb(200,200,200)"
  );

  //テーブルの非活性
  const TableDisabled = () => {
    const tableDisabled = [];
    for (let i = 0; i < tableDisable.length; i++) {
      if (tableDisable[i] === "rgb(200,200,200)" || bidDisabled[i]) {
        tableDisabled[i] = false;
      } else {
        tableDisabled[i] = true;
      }
    }
    return tableDisabled;
  };

  //入札価格のテキストフィールド
  const [inputBidPrice, setInputBidPrice] = React.useState<string[]>(
    new Array(48).fill(true).map((_) => "0.00")
  );
  //入札量のテキストフィールド
  const [inputBidAmount, setInputBidAmount] = React.useState<string[]>(
    new Array(48).fill(true).map((_) => "0.00")
  );

  const executeFlag = React.useRef<boolean>(false);

  const dayBidPrice = dayBid.map((value) => value.bidPrice.toString());
  const dayBidValue = dayBid.map((value) => {
    return value.type === "1"
      ? (value.bidValue * -1).toString()
      : value.bidValue.toString();
  });

  const dispErrDialog = (index: number) => {
    const upperBuyAmount = Number(
      limit.dayLimitBit[0].cells[index].upperPowerVolume
    ).toFixed(2);
    const upperSellAmount = (
      Number(limit.dayLimitBit[1].cells[index].upperPowerVolume) * -1
    ).toFixed(2);
    const upperBuyPrice = Number(
      limit.dayLimitBit[0].cells[index].upperPowerPrice
    ).toFixed(2);
    const lowerBuyPrice = Number(
      limit.dayLimitBit[0].cells[index].lowerPowerPrice
    ).toFixed(2);
    const upperSellPrice = Number(
      limit.dayLimitBit[1].cells[index].upperPowerPrice
    ).toFixed(2);
    const lowerSellPrice = Number(
      limit.dayLimitBit[1].cells[index].lowerPowerPrice
    ).toFixed(2);
    setDialogMessage(
      languageContext.words.outside_limit_message +
        languageContext.words.range_specification +
        `\n` +
        languageContext.words.selling_price +
        `：${lowerSellPrice}～${upperSellPrice}` +
        languageContext.words.yen_kwh +
        `\n` +
        languageContext.words.buying_price +
        `：${lowerBuyPrice}～${upperBuyPrice}` +
        languageContext.words.yen_kwh +
        `\n` +
        languageContext.words.selling_volume +
        `：${"0.00"}～${upperSellAmount} MWh/h\n` +
        languageContext.words.buying_volume +
        `：${"0.00"}～${upperBuyAmount} MWh/h`
    );
    setDigOpen(true);
  };

  //入札価格のセレクトボックス
  const BidPriceHandle = (inputBoxNumber: number, inputValue: string) => {
    const tempPrice: string[] = inputBidPrice.map((value) => value);
    tempPrice[inputBoxNumber] = inputValue.replace(/-/g, "");
    setInputBidPrice(tempPrice);
  };

  const PriceFocusHandle = (
    inputValue: string,
    upper: number,
    lower: number,
    inputBoxNumber: number
  ) => {
    let dialogFlag = false;
    if (Number(inputValue) > upper && Number(inputValue) !== 0) {
      dialogFlag = true;
    } else if (Number(inputValue) < lower && Number(inputValue) !== 0) {
      dialogFlag = true;
    }
    processText(inputBoxNumber, inputBidPrice, setInputBidPrice);

    if (dialogFlag) {
      dispErrDialog(inputBoxNumber);
    }
  };
  //入札量のセレクトボックス
  const BidAmountHandle = (inputBoxNumber: number, inputedValue: string) => {
    const tempAmount: string[] = inputBidAmount.map((value) => value);
    tempAmount[inputBoxNumber] = inputedValue;
    setInputBidAmount(tempAmount);
  };

  const AmountFocusHandle = (
    inputedValue: string,
    buyUpper: number,
    salesUpper: number,
    inputBoxNumber: number
  ) => {
    let dialogFlag = false;

    const tempText = inputBidAmount.map((value) => value);
    tempText[inputBoxNumber] = (
      Math.floor(Number(inputBidAmount[inputBoxNumber]) * 10) / 10
    ).toFixed(2);
    setInputBidAmount(tempText);
    if (Number(inputedValue) > buyUpper && Number(inputedValue) !== 0) {
      dialogFlag = true;
    } else if (
      Number(inputedValue) < salesUpper &&
      Number(inputedValue) !== 0
    ) {
      dialogFlag = true;
    }

    if (dialogFlag) {
      dispErrDialog(inputBoxNumber);
    }
  };

  // フォーカスアウト時にテキストフィールドに表示している値をtoFixedする
  const processText = (
    index: number,
    text: string[],
    setText: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    const tempText = text.map((value) => value);
    tempText[index] = Number(text[index]).toFixed(2);
    setText(tempText);
  };

  // 入札制限範囲外の値が入力されていないか判定
  const isWhithinRangeValue = () => {
    const outRangePrice = inputBidPrice.find(
      (element, index) =>
        (Number(element) >
          Number(
            limit.dayLimitBit[Number(inputBidAmount[index]) < 0 ? 1 : 0].cells[
              index
            ].upperPowerPrice
          ) ||
          Number(element) <
            Number(
              limit.dayLimitBit[Number(inputBidAmount[index]) < 0 ? 1 : 0]
                .cells[index].lowerPowerPrice
            )) &&
        Number(element) !== 0
    );

    const outRangeAmount = inputBidAmount.find(
      (element, index) =>
        Number(element) * (Number(element) < 0 ? -1 : 1) >
        Number(
          limit.dayLimitBit[Number(element) < 0 ? 1 : 0].cells[index]
            .upperPowerVolume
        )
    );

    if (outRangePrice !== undefined || outRangeAmount !== undefined) {
      return false;
    }
    return true;
  };

  //一括入札実行時
  const executeBulkBid = async () => {
    executeFlag.current = true;
    const bulkBidData: BulkBid[] = []; //一括入札の値を全て持つ
    const disabled = TableDisabled();
    // console.log(inputBidPrice.length, inputBidPrice);
    // console.log(inputBidAmount.length, inputBidAmount);
    if (isWhithinRangeValue()) {
      for (let i = 0; i < inputBidPrice.length; i++) {
        if (
          inputBidPrice[i] != null &&
          inputBidAmount[i] != null &&
          Number(inputBidPrice[i]) !== 0 &&
          Number(inputBidAmount[i]) !== 0 &&
          disabled[i]
        ) {
          bulkBidData.push({
            targetDate: targetDate,
            areaId: areaId,
            buysellKind: Number(inputBidAmount[i]) < 0 ? 1 : 2,
            orderPrice: Number(inputBidPrice[i]),
            orderQu:
              Number(inputBidAmount[i]) < 0
                ? Number(inputBidAmount[i]) * -1
                : Number(inputBidAmount[i]),
            itemId: (i + 1).toString().padStart(2, "0"),
          });
        }
      }
      if (bulkBidData.length !== 0) {
        await postBulkBid(bulkBidData);
      }
      await getIntradayData();
    } else {
      setDialogMessage(
        languageContext.words.outside_bid_limit +
          "\n" +
          languageContext.words.reconsider_bidding
      );
      setDigOpen(true);
    }
  };

  //一時間前入札APIを呼び出す
  const bidIntraday = async (itemId: string[]) => {
    await api
      .get(BULK_BIDDING, {
        params: {
          targetDate: targetDate,
          areaId: areaId,
          itemIdArray: itemId,
        },
      })
      .then((res) => {
        setDialogMessage(languageContext.words.intraday_excute_bulk_bid);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 504) {
          setDialogMessage(
            languageContext.words.could_not_communicate_jepx
          );
        } else {
          setDialogMessage(languageContext.words.intraday_bulk_bid_failed);
        }
      });
    setDigOpen(true);
  };

  //DBに入札を一括登録
  const postBulkBid = async (bulkBidData: BulkBid[]) => {
    await api
      .post(POST_BULK_BID_DATA, { bulkBidData: bulkBidData })
      .then(async (res) => {
        const itemId: string[] = bulkBidData.map((item) => item.itemId);
        await bidIntraday(itemId);
      })
      .catch((err) => {
        setDialogMessage(languageContext.words.intraday_bulk_bid_failed);
        setDigOpen(true);
      });
  };

  //モーダルを閉じる
  const ModalCloseEvent = () => {
    if (isWhithinRangeValue() || executeFlag.current === false) {
      HaldleClose();
    }
    executeFlag.current = false;
  };

  // 複写
  const copySalesValue = () => {
    const temp = inputBidAmount.map((value) => value);
    for (let i = 0; i < 48; i++) {
      if (TableDisabled()[i]) {
        temp[i] =
          salesData[i] === undefined
            ? "0.00"
            : (Number(salesData[i]) * -1).toFixed(2);
      }
    }
    setInputBidAmount(temp);
  };

  const copyProcurementValue = () => {
    const temp = inputBidAmount.map((value) => value);
    for (let i = 0; i < 48; i++) {
      if (TableDisabled()[i]) {
        temp[i] =
          procurementData[i] === undefined
            ? "0.00"
            : Number(procurementData[i]).toFixed(2);
      }
    }
    setInputBidAmount(temp);
  };

  //一括入札
  return (
    <CustomModal
      hidden={hidden}
      title={languageContext.words.bulk_bidding}
      open={open}
      onAccept={() => executeBulkBid()}
      onClose={ModalCloseEvent}
      btnText={languageContext.words.bid_execution}
    >
      <Box sx={{ width: "700px", height: "700px" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            padding: "0px 0px 10px 0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "calc(100%)",
              width: "418px",
            }}
          >
            <label
              style={{
                color: "rgb(150, 150, 150)",
                height: "calc(50%)",
                fontSize: "14px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                alignItems: "center",
              }}
              title={"*" + languageContext.words.example_one_hour}
            >
              *{languageContext.words.example_one_hour}
            </label>
            <label
              style={{
                color: "rgb(150, 150, 150)",
                height: "calc(50%)",
                fontSize: "14px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                alignItems: "center",
              }}
              title={"*" + languageContext.words.example_spot}
            >
              *{languageContext.words.example_spot}
            </label>
          </Box>
          <StyledButton
            sx={{
              height: "45px",
              margin: "0px 10px",
            }}
            onClick={copySalesValue}
          >
            {languageContext.words.sales_plan_duplication}
          </StyledButton>
          <StyledButton
            sx={{
              height: "45px",
              margin: "0px 10px",
            }}
            onClick={copyProcurementValue}
          >
            {languageContext.words.procurement_plan_duplication}
          </StyledButton>
        </Box>
        <BulkBidTableRender
          TableDisabled={TableDisabled}
          areaValue={areaValue}
          tableColor={tableColor}
          limit={limit}
          inputBidAmount={inputBidAmount}
          inputBidPrice={inputBidPrice}
          BidAmountHandle={BidAmountHandle}
          AmountFocusHandle={AmountFocusHandle}
          PriceFocusHandle={PriceFocusHandle}
          BidPriceHandle={BidPriceHandle}
          setInputBidPrice={setInputBidPrice}
          setInputBidAmount={setInputBidAmount}
          dayBidPrice={dayBidPrice}
          dayBidValue={dayBidValue}
          createToolTipText={createToolTipText}
        />
      </Box>
    </CustomModal>
  );
};
export default BulkBit;
