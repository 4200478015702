import React, { useContext, useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import {
  Box,
  Button,
  Switch,
  Typography,
  FormControlLabel,
} from "@mui/material";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import SetAlarmList from "./SetAlarmList";
import { LanguageContext } from "../../common/localization/localization";
import { PaletteContext } from "../../common/paletteMode";
import {
  isBefore,
  addDays,
  subDays,
  addMonths,
  addYears,
  format,
  isAfter,
} from "date-fns";
import { tempAlarmType, alarmType } from "../../../types/system/AlarmList";
import {
  StyledMasterTableCell,
  StyledMasterTableContainer,
  StyledMasterTable,
  StyledMasterTableHeader,
  StyledTableHeaderBig,
  StyledTableHeaderSmall,
} from "../../common/customComponents/CustomMasterCompornent/CustomTableEx";
import {
  ModalButton,
  NewRegistrationButton,
} from "../../common/customComponents/CustomMasterCompornent/CustomMasterButton";
import { useAuthedApi } from "../../../common/axios";

//アラーム SELECT API
const GET_ARALMINFO_LIST_API = "/getAlarmSettingList";
const UPDATE_STATUS_API = "/updateStausAlarm";
const GET_VALID_ARALM_LIST_API = "/getValidAlarmSettingList";
const SET_SOCKETIO_ALARM_API = "/setSocketIoAlarm";

export enum modalMode {
  editMode,
  registerMode,
}

// 通知一覧画面
const AlarmList = () => {
  const languageContext = useContext(LanguageContext);

  //通信エラー
  const api = useAuthedApi();

  //繰り返しタブに表示するデータ
  const REPEAT = [
    languageContext.words.repeat, //-1
    languageContext.words.none,
    languageContext.words.daily,
    languageContext.words.weekly,
    languageContext.words.monthly,
    languageContext.words.annually,
    languageContext.words.end_of_month,
  ];
  //セレクトタブに表示するデータ
  const STATE = [
    languageContext.words.status_jotai,
    languageContext.words.invalid, //0:無効
    languageContext.words.valid, //1:有効
  ];

  //各プルダウンの中身をセットにして配列にしておく
  type searchInfo = { id: number; name: string };
  const repeatList: searchInfo[] = [];
  const stateList: searchInfo[] = [];

  const createListElement = (id: number, name: string) => {
    return { id, name };
  };

  for (let i = -1; i < REPEAT.length - 1; i++) {
    repeatList.push(createListElement(i, REPEAT[i + 1]));
  }
  for (let i = -1; i < STATE.length - 1; i++) {
    stateList.push(createListElement(i, STATE[i + 1]));
  }

  // アラーム設定モーダル
  const [openAlarmSetting, setOpenAlarmSetting] =
    React.useState<boolean>(false);
  // アラーム設定モーダル開く
  const HandleOpenAlarmSetting = () => setOpenAlarmSetting(true);
  // アラーム設定モーダル閉じる
  const HandleCloseAlarmSetting = () => setOpenAlarmSetting(false);
  // 編集/新規登録のモードを管理 0:編集 1:新規登録
  const [mode, setMode] = useState<modalMode>(modalMode.registerMode);
  // 編集ボタンを押した行のアラートID情報
  const [alarmIdForEdit, setAlarmIdForEdit] = useState<number>(0);

  //セレクトタブのState
  const [repeat, setRepeat] = useState(-1);
  const [state, setState] = useState(-1);

  const palletContextBase = useContext(PaletteContext);

  const [alarmInfo, setAlarmInfo] = useState<alarmType[]>([]);
  const [allAlarmInfo, setAllAlarmInfo] = useState<tempAlarmType[]>([]);

  //アラーム一覧をDBより取得
  function getAlarmInfoList() {
    //console.log("getAlarmInfoList");

    let responseData: never[] = [];
    const url = api
      .get(GET_ARALMINFO_LIST_API)
      .then((response) => {
        responseData = response.data.result;
        setAllAlarmInfo(responseData); //全てのアラーム設定保持
        filterTable(responseData);
      })
      .catch((err) => {
        console.log("getAlarmInfoList失敗:" + err);
        records([{ user_id: "test", role_id: 99 }] as never);
      });
  }

  // 画面遷移時とモーダルが閉じられたときに再描画処理
  useEffect(() => {
    if (!openAlarmSetting) {
      getAlarmInfoList();
    }
  }, [openAlarmSetting]);

  //選択種別・有効無効で絞り込み
  useEffect(() => {
    const alarmList = allAlarmInfo;
    filterTable(alarmList);
  }, [repeat, state]);

  // テーブルデータフィルタ
  function filterTable(tableData: tempAlarmType[]) {
    let alarmList = tableData;
    if (repeat != -1) {
      alarmList = tableData.filter((x) => x.repeat_kind == repeat);
    }
    if (state != -1) {
      alarmList = alarmList.filter((x) => x.alart_status == state);
    }
    records(alarmList);
  }

  // テーブルの列データ
  function records(responseData: tempAlarmType[]) {
    const alarmInfoList: alarmType[] = [];

    //DBから取ってきた情報を成型してalarmInfoListに入れる
    responseData.map((data) => {
      alarmInfoList.push({
        alarmName: data.alart_nm,
        alarmId: data.alart_id,
        time: data.hour.padStart(2, "0") + ":" + data.minute.padStart(2, "0"),
        next: nextTargetDate(data),
        repeat: data.repeat_kind.toString(),
        week_mon: data.week_mon,
        week_tue: data.week_tue,
        week_web: data.week_web,
        week_thu: data.week_thu,
        week_fri: data.week_fri,
        week_sat: data.week_sat,
        week_sun: data.week_sun,
        status: Number(data.alart_status),
        message: data.message,
      });
    });

    setAlarmInfo(alarmInfoList);
  }

  const convertRepeatKindtoName = (data: alarmType, index: number) => {
    let weekly = "";
    if(Number(data.repeat) === 2){
      if(Number(alarmInfo[index].week_mon) === 1){
        weekly += `${languageContext.words.mon},`
      }
      if(Number(alarmInfo[index].week_tue) === 1){
        weekly += `${languageContext.words.tue},`
      }
      if(Number(alarmInfo[index].week_web) === 1){
        weekly += `${languageContext.words.wed},`
      }
      if(Number(alarmInfo[index].week_thu) === 1){
        weekly += `${languageContext.words.thu},`
      }
      if(Number(alarmInfo[index].week_fri) === 1){
        weekly += `${languageContext.words.fri},`
      }
      if(Number(alarmInfo[index].week_sat) === 1){
        weekly += `${languageContext.words.sat},`
      }
      if(Number(alarmInfo[index].week_sun) === 1){
        weekly += `${languageContext.words.sun},`
      }
      weekly = `(${weekly.slice(0,-1)})`;

    }
    return weekly;
  }
  //次の通知日計算
  const nextTargetDate = (data: tempAlarmType) => {
    const repeatKind = Number(data.repeat_kind);
    const today = new Date();

    const year = data.year == null ? today.getFullYear() : Number(data.year);
    const month =
      data.month == null ? today.getMonth() : Number(data.month) - 1;
    const day = data.day == null ? today.getDate() : Number(data.day);
    const hour = Number(data.hour);
    const minute = Number(data.minute);
    let nextDate = new Date(year, month, day, hour, minute);
    switch (repeatKind) {
      case 0: {
        //なし
        if (isBefore(nextDate, today)) {
          // 次の送信日時が現在日付より過去なら空白返却
          return "";
        }
        break;
      }
      case 1: {
        //毎日
        if (isBefore(nextDate, today)) {
          // 次の送信日時が現在日付より過去なら１日プラスする
          nextDate = addDays(nextDate, 1);
        }
        break;
      }
      case 2: {
        //毎週
        const nowWeek = today.getDay();
        const targetweeks:number[] = [];
        if(Number(data.week_sun) === 1){
          targetweeks.push(0)
        }
        if(Number(data.week_mon) === 1){
          targetweeks.push(1)
        }
        if(Number(data.week_tue) === 1){
          targetweeks.push(2)
        }
        if(Number(data.week_web) === 1){
          targetweeks.push(3)
        }
        if(Number(data.week_thu) === 1){
          targetweeks.push(4)
        }
        if(Number(data.week_fri) === 1){
          targetweeks.push(5)
        }
        if(Number(data.week_sat) === 1){
          targetweeks.push(6)
        }
      
        let target = targetweeks.find((data) => isBefore(today, new Date(year, month, day + data - nowWeek, hour, minute)));// 今日の日付を比べて次の曜日を絞り込む
        if(target === undefined){// 見つからなかった場合
          target = targetweeks[0];
          const diff = target - nowWeek;
          nextDate = addDays(nextDate, 7 + diff);
        }
        else {
          const diff = target - nowWeek;
          nextDate = addDays(nextDate, diff);
        }

        break;
      }
      case 3: {
        //毎月
        let nextMonth = month;
        if (nextDate.getMonth() !== month) {
          // nextDateの月と次の送信月が違う場合、
          // nextDateが存在しない日付になっているので1月プラスして次の送信日時を作り直し
          nextMonth = nextMonth + 1;
          nextDate = new Date(year, nextMonth, day, hour, minute);
        }
        if (isBefore(nextDate, today)) {
          // 次の送信日時が現在日付より過去なら１月プラスする
          nextMonth = nextMonth + 1;
          nextDate = new Date(year, nextMonth, day, hour, minute);
        }
        break;
      }
      case 4: {
        //毎年
        if (nextDate.getMonth() !== month) {
          // nextDateの月と設定した月が違う場合、
          // nextDateが存在しない日付になっているので空白を返却（通知しない）
          return "";
        }
        if (isBefore(nextDate, today)) {
          // 次の送信日時が現在日付より過去なら１年プラスする
          nextDate = addYears(nextDate, 1);
        }
        break;
      }
      case 5: {
        //月末日
        //翌月の-1日が月末
        nextDate = new Date(year, month + 1, 1 - 1, hour, minute);
        //通知時間を超えたら翌月末
        if (isBefore(nextDate, today))
          nextDate = new Date(year, month + 2, 1 - 1);
        break;
      }
    }
    return format(nextDate, "yyyy/MM/dd");
  };

  //設定モーダルを新規登録モードで開く
  const handleOpenRegister = () => {
    setMode(modalMode.registerMode);
    setAlarmIdForEdit(0);
    HandleOpenAlarmSetting();
  };
  //設定モーダルを編集モードで開く
  const handleOpenEdit = (alarmId: number) => {
    setMode(modalMode.editMode);
    setAlarmIdForEdit(alarmId);
    HandleOpenAlarmSetting();
  };

  //対象のアラート設定の有効・無効を変更
  const statusChange = (alarmId: number, status: boolean) => {
    const statusNum: number = status ? 1 : 0;
    setAlarmInfo(
      alarmInfo.map((data) => {
        return alarmId == data.alarmId ? { ...data, status: statusNum } : data;
      })
    );
    setAllAlarmInfo(
      allAlarmInfo.map((data) => {
        return alarmId == data.alart_id
          ? { ...data, alart_status: statusNum }
          : data;
      })
    );

    // 更新処理
    api
      .get(UPDATE_STATUS_API, { params: { id: alarmId, status: statusNum } })
      .then((res) => {
        setSocketAlarm();
      })
      .catch((err) => {
        console.log("エラー発生");
      });
  };

  //画面側に通知するアラートを設定
  function setSocketAlarm() {
    api
      .get(GET_VALID_ARALM_LIST_API) // 通知定義取得
      .then((response) => {
        api.get(SET_SOCKETIO_ALARM_API, { params: { list: response.data } });
      }) //cron設定
      .catch((err) => {
        //console.log("失敗したよ");
      });
  }

  return (
    <div
      className={`cn-setting-display ${
        palletContextBase.PaletteMode === "dark" ? "dark" : "light"
      }`}
    >
      <Box sx={{ width: "100%", height: "20%", display: "flex" }}>
        <CustomSelectList
          label={repeatList[0].name}
          value={repeat}
          options={repeatList}
          onChange={(e: number) => setRepeat(e)}
        />
        <CustomSelectList
          label={stateList[0].name}
          value={state}
          options={stateList}
          onChange={(e: number) => setState(e)}
        />
        <NewRegistrationButton
          variant="outlined"
          onClick={() => handleOpenRegister()}
        >
          {languageContext.words.new_registration}
        </NewRegistrationButton>
        {SetAlarmList(
          openAlarmSetting,
          HandleCloseAlarmSetting,
          mode,
          alarmIdForEdit
        )}
      </Box>
      <StyledMasterTableContainer>
        <StyledMasterTable>
          <StyledMasterTableHeader>
            <TableRow>
              <StyledTableHeaderBig sx={{width: "22% !important"}}>
                {languageContext.words.alarm_name}
              </StyledTableHeaderBig>
              <StyledTableHeaderSmall sx={{width: "19% !important"}} >
                {languageContext.words.repeat}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.next_notification_date}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.timeB}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.status_jotai}
              </StyledTableHeaderSmall>
              <StyledTableHeaderBig sx = {{width: "52% !important"}}>
                {languageContext.words.message}
              </StyledTableHeaderBig>
            </TableRow>
          </StyledMasterTableHeader>
          <TableBody
            sx={{ background: "rgb(255,255,255)", overflowY: "scroll" }}
          >
            {alarmInfo.map((data, index) => (
              <TableRow key={index}>
                <StyledMasterTableCell sx={{ textAlign: "left !important" }}>
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                    <Typography
                      title={data.alarmName}
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {data.alarmName}
                    </Typography>
                    <ModalButton
                      variant="outlined"
                      onClick={() => handleOpenEdit(data.alarmId)}
                    >
                      {languageContext.words.edit}
                    </ModalButton>
                  </Box>
                </StyledMasterTableCell>
                <StyledMasterTableCell title={data.repeat}>
                  {data.repeat === "2" ?  repeatList.find((x) => x.id == Number(data.repeat))?.name + convertRepeatKindtoName(data, index) :
                  (repeatList.find((x) => x.id == Number(data.repeat))?.name ??  "")}
                </StyledMasterTableCell>
                <StyledMasterTableCell title={data.next}>
                  {data.next === ""
                    ? data.next
                    : languageContext.convertDateToLocalizedStringPoorAcu(
                        new Date(data.next)
                      )}
                </StyledMasterTableCell>
                <StyledMasterTableCell title={data.time}>
                  {data.time}
                </StyledMasterTableCell>
                <StyledMasterTableCell>
                  <Switch
                    onChange={(e) =>
                      statusChange(data.alarmId, e.target.checked)
                    }
                    checked={data.status == 1 ? true : false}
                  />
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  title={data.message}
                  sx={{ textAlign: "left !important" }}
                >
                  {data.message}
                </StyledMasterTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledMasterTable>
      </StyledMasterTableContainer>
    </div>
  );
};

export default AlarmList;
