import React, { useContext }  from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { PaletteContext } from "../paletteMode";

// タイトル部の遷移ボタンダークモード対応
const ReGetPaletteMenuBG = () =>{
  const {PaletteMode} = useContext(PaletteContext);
  return PaletteMode === "dark" ? "#3E3E3E" : "#ffffff"
}
const ReGetPaletteMenu = () =>{
  const {PaletteMode} = useContext(PaletteContext);
  return PaletteMode === "dark" ? "#90CAF9" : "#1976d2"
}

const GetPalleteButton = () => {
  const {PaletteMode} = useContext(PaletteContext);
  return PaletteMode === "dark" ? "#00000000" : "white"
}

export const StyledButton = styled(Button)`
  && {
    min-height: 24px;
    width: auto;
    //color: pink;
    border: 1px solid ;
    background-color: ${GetPalleteButton};
    font-size: 13px;
    padding 0px 10px;
  }
`;

export const StyledButtonMenu = styled(Button)`
  && {
    min-height: 26px;
    width: auto;
    background-color: ${ReGetPaletteMenuBG};
    border: 1px solid rgba(25, 118, 210, 0.5);
    color: ${ReGetPaletteMenu};
    font-size: 16px;
    padding 0px 15px;
  }
`;
