import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import "./css/UserList.css";

// 需要家一覧
import { Box, Typography } from "@mui/material";
import SetSupplyList from "./SetSupplyList";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import SetPartsSupplyList from "./SetPartsSupplyList";
import { LanguageContext } from "../common/localization/localization";
import { demandInfo } from "../../types/master/SupplyList";
import { resAreaList } from "../../types/common/Search";
import {
  ModalButton,
  NewRegistrationButton,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterButton";
import {
  StyledMasterTableCell,
  StyledMasterTableContainer,
  StyledMasterTable,
  StyledMasterTableHeader,
  StyledTableHeaderBig,
  StyledTableHeaderMid,
  StyledTableHeaderSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomTableEx";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useAreaUtility } from "../../common/area";
import { useVoltageLevelUtility } from "../../common/voltageLevel";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";

export enum modalMode {
  editMode,
  registerMode,
}

const SupplyList = () => {
  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const userRoleContext = useContext(RoleContext);

  //通信エラー
  const api = useAuthedApi();

  //エリアIDをエリア名に変換
  const { convertIdToAreaName } = useAreaUtility();
  //電圧階級IDを電圧会究明に変換
  const { convertIdToVolLevelName } = useVoltageLevelUtility();

  // アスペクト比
  const options = {
    maintainAspectRatio: false,
  };

  // showがtrueになったらモーダルが開かれる
  const [show, setShow] = useState(false);
  const [showParts, setShowParts] = useState(false);
  //需要家情報一覧をdemandInfoListに格納
  const [demandInfoList, setDemandInfoList] = useState<demandInfo[]>([
    {
      demandName: "test",
      demandId: 1,
      areaId: "test",
      volLevel: 2,
      contractPower: 3,
      contractPowerFlg: 4,
      contractPowerType: "test",
    },
  ]);

  // 編集/新規登録のモードを管理 0:編集 1:新規登録
  const [mode, setMode] = useState<modalMode>(modalMode.registerMode);

  // 編集:子モーダルボタンを押した行の需要家ID情報
  const [demandIdForEdit, setDemandIdForEdit] = useState<number>(0);
  // 子モーダルボタンを押した行の需要家名称
  const [targetDemandName, setTargetDemandName] = useState<string>("");
  // 子モーダルボタンを押した行の需要家のエリア
  const partSettingFlag = React.useRef<number>(0);
  //登録・編集ボタンの押下可能チェッカー
  const [enableEdit, setEnableEdit] = useState(false);

  // 設定モーダル開く
  const HandleOpen = () => setShow(true);
  // 設定モーダル閉じる
  const HandleClose = () => {
    setShow(false);
  };

  // 画面ロード時、編集可能ユーザーか確認
  useEffect(() => {
    checkEditRoll();
  }, []);

  //初回、モーダルが閉じたら更新
  useEffect(() => {
    if (show || showParts) return;
    requestDemandList();
  }, [show, showParts]);

  //設定モーダルを新規登録モードで開く
  const HandleOpenRegister = () => {
    setMode(modalMode.registerMode);
    setDemandIdForEdit(0);
    setShow(true);
  };

  //設定モーダルを編集モードで開く
  const HandleOpenEdit = (demandId: number) => {
    //console.log("編集モード通過");
    setMode(modalMode.editMode);
    setDemandIdForEdit(demandId);
    setShow(true);
  };

  // 設定モーダル開く
  const HandleOpenParts = (
    demandId: number,
    demandName: string,
    partSettingFlag: number
  ) => {
    setShowParts(true);
  };
  // 設定モーダル閉じる
  const HandleCloseParts = () => {
    setShowParts(false);
  };

  // 需要家一覧取得
  const requestDemandList = () => {
    let responseData: demandInfo[] = [];
    api
      .get(API_URL.DEMAND_LIST_API)
      .then((response) => {
        //console.log(response.data.actualResult);
        responseData = response.data.actualResult as demandInfo[];
        records(responseData);
      })
      .catch((err) => {
        records([
          {
            demandName: "",
            demandId: 0,
            areaId: "00",
            volLevel: 0,
            contractPower: 0,
            contractPowerFlg: 0,
            contractPowerType: "",
          },
        ] as never);
        console.log(err);
      });
  };

  // テーブルの列データ
  const records = (responseData: demandInfo[]) => {
    let DemandList: demandInfo[] = [];
    DemandList = responseData;

    if (DemandList === undefined) {
      return;
    }

    setDemandInfoList(DemandList);
  };

  // 編集可能ユーザか確認
  const checkEditRoll = () => {
    const userRoll = userRoleContext.role;
    api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: userRoll } })
      .then((response) => {
        const edit = response.data;
        // 管理者権限がある時のみ編集等の書き込みを有効にする
        if (edit == true) {
          setEnableEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const TableCustomLayout = styled(TableCell)(() => ({
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  }));

  //部分供給設定の有無
  const convertIdToPartialSupply = (id: number) => {
    const enableParts = [
      languageContext.words.demand_setting_partial_supply_none,
      languageContext.words.demand_setting_partial_supply_notice_type,
    ];

    return enableParts[id];
  };

  return (
    <div className="cn-setting-display">
      <Box
        sx={{
          width: "100%",
          height: "20%",
          display: "flex",
          paddingBottom: "17px",
        }}
      >
        <NewRegistrationButton
          style={{ display: !enableEdit ? "none" : undefined }}
          variant="outlined"
          onClick={() => HandleOpenRegister()}
        >
          {languageContext.words.new_registration}
        </NewRegistrationButton>
        {SetSupplyList(show, HandleClose, mode, demandIdForEdit)}
        {SetPartsSupplyList(
          showParts,
          HandleCloseParts,
          demandIdForEdit,
          targetDemandName,
          partSettingFlag.current
        )}
      </Box>
      <StyledMasterTableContainer>
        <StyledMasterTable>
          <StyledMasterTableHeader>
            <TableRow>
              <StyledTableHeaderBig>
                {languageContext.words.demander_name}
              </StyledTableHeaderBig>

              <StyledTableHeaderSmall>
                {languageContext.words.area}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.voltage_class_assigned_num}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.contracted_power}
              </StyledTableHeaderSmall>
              <StyledTableHeaderMid>
                {languageContext.words.partial_supply_presence_absence}
              </StyledTableHeaderMid>
              <StyledTableHeaderSmall>
                {languageContext.words.partial_supply_form}
              </StyledTableHeaderSmall>
            </TableRow>
          </StyledMasterTableHeader>
          <TableBody>
            {demandInfoList.map((demandInfo, index) => (
              <TableRow key={index}>
                <StyledMasterTableCell sx={{ textAlign: "left !important" }}>
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                    <Typography
                      title={demandInfo.demandName}
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {demandInfo.demandName}
                    </Typography>
                    <ModalButton
                      style={{ display: !enableEdit ? "none" : undefined }}
                      variant="outlined"
                      onClick={() => HandleOpenEdit(demandInfo.demandId)}
                    >
                      {languageContext.words.edit}
                    </ModalButton>
                  </Box>
                </StyledMasterTableCell>

                <StyledMasterTableCell title={demandInfo.areaId}>
                  {convertIdToAreaName(demandInfo.areaId)}
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  title={convertIdToVolLevelName(
                    (demandInfo.volLevel - 1).toString()
                  )}
                >
                  {convertIdToVolLevelName(demandInfo.volLevel.toString())}
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  title={demandInfo.contractPower.toString()}
                >
                  {Number(demandInfo.contractPower).toLocaleString()}
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  title={convertIdToPartialSupply(demandInfo.contractPowerFlg)}
                >
                  {convertIdToPartialSupply(demandInfo.contractPowerFlg)}
                </StyledMasterTableCell>
                <StyledMasterTableCell>
                  {demandInfo.contractPowerType}
                  <ModalButton
                    style={{ display: !enableEdit ? "none" : undefined }}
                    variant="outlined"
                    onClick={() => {
                      partSettingFlag.current = demandInfo.contractPowerFlg;
                      setTargetDemandName(demandInfo.demandName);
                      setDemandIdForEdit(demandInfo.demandId);
                      setShowParts(true);
                    }}
                  >
                    {languageContext.words.edit}
                  </ModalButton>
                </StyledMasterTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledMasterTable>
      </StyledMasterTableContainer>
    </div>
  );
};

export default SupplyList;
