import React, { ReactNode, useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomDialog, { ButtonType } from "./CustomDialog";
import { LanguageContext } from "../localization/localization";

//モーダルウィンドウもそろえた方がいいんじゃないか説
export enum NumOfButton {
  Single = "hidden",
  Double = "visible",
}

interface Props {
  children: ReactNode;
  title: string;
  btnTextLeft: string;
  btnTextRight: string;
  onAcceptLeft: () => void;
  onAcceptRight: () => void;
  onClose: () => void;
  open: boolean;
  btnType: NumOfButton;
  settingName?: string;
}

const CustomSettingModal = (props: Props) => {
  const {
    children,
    title,
    btnTextLeft,
    btnTextRight,
    onAcceptLeft,
    onAcceptRight,
    onClose,
    open,
    btnType,
    settingName,
  } = props;

  //言語コンテキスト
  const { words } = useContext(LanguageContext);
  const [digOpen, setDigOpen] = useState(false); //ポップアップopenClose管理
  const [deleteAccept, setDeleteAccept] = useState(false); //削除処理実行するかどうか
  //useEffectで削除処理実行
  useEffect(() => {
    if (deleteAccept === true) {
      setDeleteAccept(false);
      setDigOpen(false);
      onAcceptRight();
    }
  }, [deleteAccept]);

  console.log(btnType);

  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        //背景クリックで閉じるのを無効
        if (reason !== "backdropClick") onClose();
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        margin: "auto",
      }}
    >
      <Container sx={{ position: "relative" }} maxWidth={false}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={onClose}
            sx={{ position: "relative", right: "-50px" }}
          >
            <CloseIcon fontSize="large" sx={{ color: "white" }} />
          </IconButton>
        </Box>
        <Paper>
          <DialogTitle id="modal-title">{title}</DialogTitle>
          <Divider />
          <DialogContent
            id="modal-description"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "fit-content",
              margin: "auto",
              overflowY: "unset",
            }}
          >
            {children}
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              sx={{
                visibility: btnType,
                backgroundColor: "error.light",
                "&:hover": {
                  backgroundColor: "error.main",
                },
              }}
              onClick={() => {
                setDigOpen(true);
                //onAcceptRight();
              }}
            >
              {btnTextRight}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onAcceptLeft();
              }}
            >
              {btnTextLeft}
            </Button>
          </DialogActions>
        </Paper>
        <CustomDialog
          title={words.setting_warning}
          message={words.setting_delete_confirmation}
          buttonType={ButtonType.OkCancel}
          open={digOpen}
          onAccept={() => setDeleteAccept(true)}
          onClose={() => setDigOpen(false)}
        />
      </Container>
    </Modal>
  );
};

export default CustomSettingModal;
