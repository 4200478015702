import React, {
  useState,
  useContext,
  useEffect,
  createContext,
  createRef,
  useLayoutEffect,
} from "react";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderG,
  StyledTableHeaderLG,
} from "../../common/styledComponents/styledTable";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Checkbox from "@mui/material/Checkbox";
import RefreshIcon from "@mui/icons-material/Refresh";
import styled from "styled-components";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import {
  DateContext,
  GlobalDatePicker,
} from "../../common/customComponents/GlobalDatePicker";
import CustomDatePickerTryal from "../../common/customComponents/CustomDatePickerTryal";
import CustomModal from "../../common/customComponents/CustomModal";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import DoneBid from "./DoneBid";
import CancelBid from "./CancelBid";
import { format, isSameDay } from "date-fns/fp";
import { addDays, subDays } from "date-fns";
import axios, { AxiosError, AxiosInstance } from "axios";
import { dtl_marketing_data } from "@prisma/client";
import {
  LanguageContext,
  replaceValueWithKey,
} from "../../common/localization/localization";
import BiddingScreenMenu from "../../common/menuInfo/menuItemConponents/BiddingScreenMenu";
import {
  bidStatus,
  bitInfo,
  bitLimitCells,
  bitLimmitReq,
  previousBit,
  provideData,
  saleData,
  stepTotal,
  supplyData,
} from "../../../types/jepx/BiddingScreen";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import * as TestDatas from "./BiddingScreen_data";
import CustomDialog from "../../common/customComponents/CustomDialog";
import { ButtonType } from "../../common/customComponents/CustomDialog";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import BiddingScreenBidLimit from "../../common/menuInfo/menuItemConponents/BiddingScreenBidLimit";
import { RoleContext } from "../../common/customComponents/RoleProvider";
import { useAreaUtility } from "../../../common/area";
import { resAreaList } from "../../../types/common/Search";
import { PaletteContext } from "../../common/paletteMode";
import { Paper, TextField } from "@mui/material";
import { useAuthedApi } from "../../../common/axios";
import { AccessExpression } from "typescript";

const GET_ROLE_JUDGMENT_URL = "/getIsEditableRolePlan"; //ロールの操作権限判定
const GET_SPOT_BID_DATA = "/getBiddingScreenAddress"; //スポット入札情報取得
const GET_BID_LIMIT = "/getBiddingScreenBidLimitAddress"; //入札制限取得
const GET_SPOT_CONTRACT = "/spotContract"; //スポット約定結果取得
const GET_SPOT_CONTRACT_LIST = "/getBiddingScreenResultAddress"; //指定期間の約定結果取得
const POST_SALES_PLAN = "/insertSpotSalesPlan"; //スポット販売計画保存
const POST_PROCUREMENT_PLAN = "/insertProcurementPlan"; //スポット需要計画保存
const GET_AREA_NAME_LIST = "/getAreaNameList"; //エリアリスト取得
const POST_BID_DELETE = "/deleteBiddingScreenAddress"; //入札削除要求登録
const BID_DELETE = "/deleteSpotBid"; //入札取消
const POST_BID_DATA = "/postBiddingScreenAddress"; //スポット入札情報登録
const BID_SPOT = "/bidSpot"; //入札実行
const GET_SPOT_STATUS = "/getBiddingScreenBidStatusAddress"; //スポット入札状況取得
const GET_BP_NAME = "/getBpName"; //事業者名取得
const GET_SALES_PLAN = "/getSalesTotalAddress"; //販売計画値取得
const GET_PROCUREMENT_PLAN = "/getDemandTotalAddress"; //調達計画値取得

const times = [
  "00:00～00:30",
  "00:30～01:00",
  "01:00～01:30",
  "01:30～02:00",
  "02:00～02:30",
  "02:30～03:00",
  "03:00～03:30",
  "03:30～04:00",
  "04:00～04:30",
  "04:30～05:00",
  "05:00～05:30",
  "05:30～06:00",
  "06:00～06:30",
  "06:30～07:00",
  "07:00～07:30",
  "07:30～08:00",
  "08:00～08:30",
  "08:30～09:00",
  "09:00～09:30",
  "09:30～10:00",
  "10:00～10:30",
  "10:30～11:00",
  "11:00～11:30",
  "11:30～12:00",
  "12:00～12:30",
  "12:30～13:00",
  "13:00～13:30",
  "13:30～14:00",
  "14:00～14:30",
  "14:30～15:00",
  "15:00～15:30",
  "15:30～16:00",
  "16:00～16:30",
  "16:30～17:00",
  "17:00～17:30",
  "17:30～18:00",
  "18:00～18:30",
  "18:30～19:00",
  "19:00～19:30",
  "19:30～20:00",
  "20:00～20:30",
  "20:30～21:00",
  "21:00～21:30",
  "21:30～22:00",
  "22:00～22:30",
  "22:30～23:00",
  "23:00～23:30",
  "23:30～00:00",
];

const requestDateFormat = format("yyyy-MM-dd");
const formatDateYYYYMMdd = format("yyyy-MM-dd");

const tomorrow = new Date();
const yesterday = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
yesterday.setDate(yesterday.getDate() - 1);

const initialPreviousBits: previousBit[] = new Array(5).fill(true).map((_) => ({
  targetDate: new Date(),
  resultCells: {
    cells: new Array(48).fill(true).map((_) => ({
      result: -1,
      fixedPowerVolume: 0,
      fixedPowerPrice: 0,
    })),
  },
}));

//小数点第二位までかつカンマ区切りに変換
const convertToLocaleString = (value: number) => {
  return Number(value).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

// 約定結果データ初期値
const initialPreviousBitData: previousBit[] = initialPreviousBits.map(
  (value) => value
);

// // 約定結果データ//
const previousBitData: previousBit[] = initialPreviousBits.map(
  (value) => value
);

//右に表示する過去約定をいくつ表示させるかの変数
const numOfDisplayPastData = 5;

const StyledTableHeaderGBorder = styled(StyledTableHeaderG)`
  && {
    border-color: #cbc7c7;
  }
`;

const StyledSpotLeftTableHd = styled(StyledTableHeaderLG)`
  && {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const initioalBidInfos: bitInfo[] = [
  {
    targetDate: new Date(),
    area: 1,
    status: 0,
    ucTender: 1,
    blockNumber: 1, // 1-5まで
    bitDate: new Date(),
    tradeType: 1,
    tenderCount: 5, // 1-15札まで
    dayBit: new Array(5).fill(true).map((_) => ({
      // 15札分コマ分
      tender: 0,
      // 48コマ分
      cells: new Array(48).fill(true).map((_) => ({
        powerPrice: "0.00",
        powerVolume: "0.00",
      })),
    })),
  },
];
// 入札情報
// 1エリア分のデータを取得
// データが取れない場合1番目の要素のデータをデフォルトでセットする
const bitInfoData: bitInfo = initioalBidInfos[0];

// TODO: fksm テーブルのコンポーネントは別ファイルに切り出す
// 通常入札時の画面左側のテーブルコンポーネント
const SpotBidLeftTable = (
  setState: React.Dispatch<React.SetStateAction<bitInfo>>,
  spotBidInfo: bitInfo,
  setLimit: React.Dispatch<React.SetStateAction<bitLimmitReq>>,
  limit: bitLimmitReq,
  areaId: string,
  disable: boolean,
  setDialogMessage: React.Dispatch<React.SetStateAction<string>>,
  setDigOpen: React.Dispatch<React.SetStateAction<boolean>>,
  getBidStatus: () => Promise<void>,
  salesData: saleData,
  demandData: provideData,
  supplyData: supplyData,
  setSupplyData: React.Dispatch<React.SetStateAction<supplyData>>,
  getPlanData: () => void,
  createToolTipText: (
    lowerEnd: string,
    upperEnd: string,
    unit: string
  ) => string,
  api: AxiosInstance
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);

  const [spotBitInfo, setSpotBitInfo] = useState(bitInfoData);
  //日付のデータコンテキスト
  const selectedDate = useContext(DateContext);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  // 入札制限値
  const [bidLimit, setBidLimit] = useState<bitLimmitReq>();

  // 入札計画値入力時の処理を分岐させるフラグ
  const inputFlag = React.useRef<boolean>(false);

  const query = {
    targetDate: requestDateFormat(selectedDate.pickDate as Date),
    areaId: areaId,
  };

  const convertNumtoStrBidData = (bitInfoData: bitInfo) => {
    const bidData: bitInfo = {
      targetDate: bitInfoData.targetDate,
      area: bitInfoData.area,
      status: bitInfoData.status,
      ucTender: bitInfoData.ucTender,
      blockNumber: bitInfoData.blockNumber,
      bitDate: bitInfoData.bitDate,
      tradeType: bitInfoData.tradeType,
      tenderCount: bitInfoData.tenderCount,
      dayBit: bitInfoData.dayBit.map((value) => {
        return {
          tender: value.tender,
          cells: value.cells.map((item) => {
            return {
              powerPrice: Number(item.powerPrice).toFixed(2),
              powerVolume: (
                Math.floor(Number(item.powerVolume) * 10) / 10
              ).toFixed(2),
            };
          }),
        };
      }),
    };
    return bidData;
  };

  const getBidData = async () => {
    await api
      .get(GET_SPOT_BID_DATA, {
        params: query,
      })
      .then((response) => {
        const bitInfoData: bitInfo = response.data.responceBitInfo;
        setSpotBitInfo(convertNumtoStrBidData(bitInfoData));
        setState(convertNumtoStrBidData(bitInfoData));
      })
      .catch((err) => {
        console.log(err);
      });
    await getBidStatus();
  };

  //DBからaxios getしてくる
  useEffect(() => {
    if (areaId !== "" && areaId !== "00") {
      getBidData();
      getPlanData();
      GetBidLimit();
    }
  }, [selectedDate.pickDate, areaId]);

  const GetBidLimit = () => {
    api
      .get(GET_BID_LIMIT, {
        params: query,
      })
      .then((response) => {
        const limit: bitLimmitReq = response.data;
        setLimit(limit);
        setBidLimit(limit);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dispErrDialog = (index: number) => {
    const upperBuyAmount = Number(
      limit.dayLimitBit[0].cells[index].upperPowerVolume
    );
    const upperSellAmount =
      Number(limit.dayLimitBit[1].cells[index].upperPowerVolume) * -1;
    const upperBuyPrice = Number(
      limit.dayLimitBit[0].cells[index].upperPowerPrice
    );
    const lowerBuyPrice = Number(
      limit.dayLimitBit[0].cells[index].lowerPowerPrice
    );
    const upperSellPrice = Number(
      limit.dayLimitBit[1].cells[index].upperPowerPrice
    );
    const lowerSellPrice = Number(
      limit.dayLimitBit[1].cells[index].lowerPowerPrice
    );
    setDialogMessage(
      languageContext.words.outside_limit_message +
        " " +
        languageContext.words.range_specification +
        `\n` +
        languageContext.words.selling_price +
        `：${lowerSellPrice.toFixed(2)}～${upperSellPrice.toFixed(2)}` +
        languageContext.words.yen_kwh +
        `\n` +
        languageContext.words.buying_price +
        `：${lowerBuyPrice.toFixed(2)}～${upperBuyPrice.toFixed(2)}` +
        languageContext.words.yen_kwh +
        `\n` +
        languageContext.words.selling_volume +
        `：${"0.00"}～${upperSellAmount.toFixed(2)} MWh/h\n` +
        languageContext.words.buying_volume +
        `：${"0.00"}～${upperBuyAmount.toFixed(2)} MWh/h`
    );
    setDigOpen(true);
  };

  //テキストフィールドを打つとそこのステイトを変化させる関数
  const changeTfStateForPri = (
    inputedValue: string,
    colIndex: number,
    rowIndex: number
  ) => {
    //連想配列のコピーはObject.createでちゃんとしたコピーが可能
    const tempBitInfo: bitInfo = Object.assign({}, spotBitInfo);

    tempBitInfo.dayBit[colIndex].cells[rowIndex].powerPrice =
      inputedValue.replace(/-/g, "");

    setSpotBitInfo(tempBitInfo);
    setState(tempBitInfo);
  };

  const priceFocusHandle = (
    inputValue: string,
    upper: number,
    lower: number,
    rowIndex: number,
    colIndex: number
  ) => {
    const tempBitInfo: bitInfo = Object.assign({}, spotBitInfo);
    tempBitInfo.dayBit[colIndex].cells[rowIndex].powerPrice = Number(
      spotBitInfo.dayBit[colIndex].cells[rowIndex].powerPrice
    ).toFixed(2);
    setSpotBitInfo(tempBitInfo);
    setState(tempBitInfo);

    let dialogFlag = false;
    if (
      Number(inputValue) > upper &&
      Number(spotBitInfo.dayBit[colIndex].cells[rowIndex].powerPrice) !== 0
    ) {
      dialogFlag = true;
    } else if (
      Number(inputValue) < lower &&
      Number(spotBitInfo.dayBit[colIndex].cells[rowIndex].powerPrice) !== 0
    ) {
      dialogFlag = true;
    }

    if (dialogFlag) {
      dispErrDialog(rowIndex);
    }
  };

  //テキストフィールドを打つとそこのステイトを変化させる関数
  const changeTfStateForQu = (
    inputedValue: string,
    colIndex: number,
    rowIndex: number
  ) => {
    //連想配列のコピーはObject.createでちゃんとしたコピーが可能
    const tempBitInfo: bitInfo = Object.assign({}, spotBitInfo);
    tempBitInfo.dayBit[colIndex].cells[rowIndex].powerVolume = inputedValue;

    setSpotBitInfo(tempBitInfo);
    setState(tempBitInfo);
  };

  const amountFocusHandle = (
    inputedValue: string,
    // buyLower: number,
    // salesLower: number,
    buyUpper: number,
    salesUpper: number,
    rowIndex: number,
    colIndex: number
  ) => {
    const tempBitInfo: bitInfo = Object.assign({}, spotBitInfo);
    tempBitInfo.dayBit[colIndex].cells[rowIndex].powerVolume = (
      Math.floor(
        Number(spotBitInfo.dayBit[colIndex].cells[rowIndex].powerVolume) * 10
      ) / 10
    ).toFixed(2);
    setSpotBitInfo(tempBitInfo);
    setState(tempBitInfo);

    let dialogFlag = false;
    if (
      Number(inputedValue) > buyUpper &&
      Number(spotBitInfo.dayBit[colIndex].cells[rowIndex].powerVolume) !== 0
    ) {
      dialogFlag = true;
    } else if (
      Number(inputedValue) < salesUpper &&
      Number(spotBitInfo.dayBit[colIndex].cells[rowIndex].powerVolume) !== 0
    ) {
      dialogFlag = true;
    }

    if (dialogFlag) {
      dispErrDialog(rowIndex);
    }
  };

  // 入札上下限値の中央値を求める
  const colculateMedian = (buyLower: number, sellLower: number) => {
    const median = (buyLower + sellLower * -1) / 2;

    return median;
  };

  // 複写
  const copyValue = (index: number) => {
    const tempBitInfo: bitInfo = Object.assign({}, spotBitInfo);

    for (let i = 0; i < 48; i++) {
      if (index === 0) {
        tempBitInfo.dayBit[0].cells[i].powerVolume = (
          Math.floor(supplyData.cellVolumes[i] * 10) / 10
        ).toFixed(2);
      } else if (index === 1) {
        tempBitInfo.dayBit[0].cells[i].powerVolume = (
          (Math.floor(salesData.cellVolumes[i] * 10) / 10) *
          -1
        ).toFixed(2);
      } else if (index === 2) {
        tempBitInfo.dayBit[0].cells[i].powerVolume = (
          Math.floor(demandData.cellVolumes[i] * 10) / 10
        ).toFixed(2);
      }
    }

    // setSpotBitInfo(tempBitInfo);
    setState(tempBitInfo);
  };

  return (
    // <ScrollSync>
    <TableContainer
      sx={{
        display: "flex",
        position: "relative",
        padding: "0px",
        width: "50%",
        height: "97%",
        marginRight: "5px",
        overflowY: "hidden",
      }}
    >
      {/* 時刻～調達計画合計までのテーブル */}
      <StyledTable
        sx={{
          width: "40%",
          borderRight: "none",
          height: "100%",
        }}
      >
        <TableHead
          sx={{
            display: "block",
            position: "sticky",
            overflow: "auto",
          }}
        >
          <TableRow>
            <StyledSpotLeftTableHd
              rowSpan={2}
              sx={{
                minWidth: "101px",
                height: "123.89px",
                padding: "0px",
              }}
              align="center"
            >
              {languageContext.words.timeB}
            </StyledSpotLeftTableHd>

            <StyledSpotLeftTableHd
              sx={{
                maxWidth: "64.84px",
                minWidth: "64.84px",
                padding: "0px 5px",
                height: "80px",
              }}
              align="center"
              title={
                languageContext.mode === "en"
                  ? languageContext.words.self_consignment
                  : ""
              }
            >
              {languageContext.words.self_consignment}
              <br />
              {languageContext.words.total}
              <br />
              {"(MWh/h)"}
            </StyledSpotLeftTableHd>

            <StyledSpotLeftTableHd
              sx={{
                maxWidth: "64.84px",
                minWidth: "64.84px",
                padding: "0px 5px",
                height: "80px",
              }}
              align="center"
              title={
                languageContext.mode === "en"
                  ? languageContext.words.sales_plan
                  : ""
              }
            >
              {languageContext.words.sales_plan}
              <br />
              {languageContext.words.total}
              <br />
              {"(MWh/h)"}
            </StyledSpotLeftTableHd>

            <StyledSpotLeftTableHd
              sx={{
                maxWidth: "64.84px",
                minWidth: "64.84px",
                padding: "0px 5px",
                height: "80px",
              }}
              align="center"
              title={
                languageContext.mode === "en"
                  ? languageContext.words.procurement_plan
                  : ""
              }
            >
              {languageContext.words.procurement_plan}
              <br />
              {languageContext.words.total}
              <br />
              {"(MWh/h)"}
            </StyledSpotLeftTableHd>
          </TableRow>

          <TableRow>
            {Array(3)
              .fill(0)
              .map((value, index) => {
                return (
                  <StyledSpotLeftTableHd
                    key={"spotLeftTableCopyCell_" + index}
                    sx={{
                      maxWidth: "64.84px",
                      minWidth: "64.84px",
                      height: "35px",
                      padding: "0px",
                      paddingRight: "3px",
                      paddingLeft: "3px",
                    }}
                    align="center"
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        padding: "5px",
                        height: "28px",
                        fontSize: "13px",
                        backgroundColor:
                          PaletteMode === "dark" ? "#3e3e3e" : "white",
                      }}
                      key={"spotLeftTableCopyBtn_" + index}
                      onClick={() => copyValue(index)}
                      disabled={disable}
                    >
                      {languageContext.words.copy}
                    </Button>
                  </StyledSpotLeftTableHd>
                );
              })}
          </TableRow>

          <TableRow sx={{ height: "40px" }}>
            <StyledSpotLeftTableHd
              sx={{
                minWidth: "101px",
                padding: "0px",
              }}
              align="center"
            >
              {languageContext.words.daily_amount}
            </StyledSpotLeftTableHd>

            <StyledSpotLeftTableHd
              sx={{
                maxWidth: "64.84px",
                minWidth: "64.84px",
                padding: "0px 8px",
              }}
              align="right"
            >
              {/* 自己託送 */}
              {convertToLocaleString(supplyData.dayTotal)}
            </StyledSpotLeftTableHd>

            <StyledSpotLeftTableHd
              sx={{
                maxWidth: "64.84px",
                minWidth: "64.84px",
                padding: "0px 8px",
              }}
              align="right"
            >
              {/* 販売計画 */}
              {convertToLocaleString(salesData.dayTotal)}
            </StyledSpotLeftTableHd>

            <StyledSpotLeftTableHd
              sx={{
                maxWidth: "64.84px",
                minWidth: "64.84px",
                padding: "0px 8px",
              }}
              align="right"
            >
              {/* 調達計画 */}
              {convertToLocaleString(demandData.dayTotal)}
            </StyledSpotLeftTableHd>
          </TableRow>
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflowY: "scroll",
              height: "550px",
              width: "100%",
              "&::-webkit-scrollbar": {
                width: "0px",
                backgroundColor: "transparent",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#AAA",
                borderRadius: "0px",
              },
            }}
          >
            {Array(48)
              .fill(0)
              .map((row, index) => (
                <TableRow key={row} sx={{ height: "48.02px" }}>
                  <StyledTableCell
                    sx={{
                      minWidth: "101px",
                      padding: "0px",
                      backgroundColor:
                        disable === true
                          ? PaletteMode === "dark"
                            ? "rgb(90,90,90)"
                            : "rgb(200,200,200)"
                          : "",
                    }}
                    align="center"
                  >
                    {times[index]}
                  </StyledTableCell>

                  <StyledTableCell
                    sx={{
                      width: "64.84px",
                      padding: "0px 8px",
                      backgroundColor:
                        disable === true
                          ? PaletteMode === "dark"
                            ? "rgb(90,90,90)"
                            : "rgb(200,200,200)"
                          : "",
                    }}
                    align="right"
                  >
                    {convertToLocaleString(supplyData.cellVolumes[index])}
                  </StyledTableCell>

                  <StyledTableCell
                    sx={{
                      width: "64.84px",
                      padding: "0px 8px",
                      backgroundColor:
                        disable === true
                          ? PaletteMode === "dark"
                            ? "rgb(90,90,90)"
                            : "rgb(200,200,200)"
                          : "",
                    }}
                    align="right"
                  >
                    {convertToLocaleString(salesData.cellVolumes[index])}
                  </StyledTableCell>

                  <StyledTableCell
                    sx={{
                      width: "64.84px",
                      padding: "0px 8px",
                      backgroundColor:
                        disable === true
                          ? PaletteMode === "dark"
                            ? "rgb(90,90,90)"
                            : "rgb(200,200,200)"
                          : "",
                    }}
                    align="right"
                  >
                    {convertToLocaleString(demandData.cellVolumes[index])}
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>

      {/* 単位～最後までのテーブル */}
      <StyledTable
        stickyHeader={true}
        sx={{
          width: "60%",
        }}
      >
        <TableHead
          sx={{
            display: "block",
            position: "sticky",
            padding: "0px",
            width: "100%",
          }}
        >
          <TableRow sx={{ height: "164.7px" }}>
            <StyledTableHeaderLG
              sx={{
                padding: "0px 10px",
                maxWidth: "72px",
                minWidth: "72px",
                paddingTop: "0px",
                paddingBottom: "0px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              align="center"
              title={
                languageContext.mode === "en"
                  ? languageContext.words.combination
                  : ""
              }
            >
              {languageContext.words.combination}
            </StyledTableHeaderLG>
            {spotBitInfo.dayBit.map((col, index) => {
              return (
                <StyledTableHeaderLG
                  key={"spotStepList_" + index}
                  sx={{
                    width: "104px",
                    padding: "0px",
                  }}
                  align="center"
                >
                  {index + 1}
                </StyledTableHeaderLG>
              );
            })}
          </TableRow>
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflowY: "scroll",
              overflowX: "hidden",
              height: "550px",
              width: "100%",
              top: "0",
              "&::-webkit-scrollbar": {
                width: "10px",
                backgroundColor: "transparent",
                height: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#AAA",
                borderRadius: "10px",
              },
            }}
          >
            {Array(48)
              .fill(0)
              .map((row, rowIndex) => {
                return (
                  <>
                    <TableRow
                      key={"spotBidLeftTableBodyRowYen_" + rowIndex}
                      sx={{ height: "24px" }}
                    >
                      <StyledTableCell
                        key={"spotBidLeftTableBodyCellYen_" + rowIndex}
                        sx={{
                          left: 0,
                          padding: 0,
                          width: "92px",
                          minWidth: "91px",
                          backgroundColor:
                            disable === true
                              ? PaletteMode === "dark"
                                ? "rgb(90,90,90)"
                                : "rgb(200,200,200)"
                              : "",
                        }}
                        align="center"
                      >
                        {languageContext.words.yen_kwh}
                      </StyledTableCell>
                      {spotBitInfo.dayBit.map((col, colIndex) => {
                        return (
                          <StyledTableCell
                            key={`spotBidLeftTableBodyTxtCellYen_${rowIndex}_${colIndex}`}
                            align="center"
                            sx={{
                              padding: "0px",
                              width: colIndex === 4 ? "76.3px" : "86.3px",
                              backgroundColor:
                                disable === true
                                  ? PaletteMode === "dark"
                                    ? "rgb(90,90,90)"
                                    : "rgb(200,200,200)"
                                  : "",
                            }}
                          >
                            <TextField
                              title={createToolTipText(
                                Number(
                                  limit?.dayLimitBit[
                                    Number(
                                      spotBitInfo.dayBit[colIndex].cells[
                                        rowIndex
                                      ].powerVolume
                                    ) < 0
                                      ? 1
                                      : 0
                                  ].cells[rowIndex].lowerPowerPrice
                                ).toFixed(2),
                                Number(
                                  limit?.dayLimitBit[
                                    Number(
                                      spotBitInfo.dayBit[colIndex].cells[
                                        rowIndex
                                      ].powerVolume
                                    ) < 0
                                      ? 1
                                      : 0
                                  ].cells[rowIndex].upperPowerPrice
                                ).toFixed(2),
                                languageContext.words.yen_kwh
                              )}
                              key={`spotBidLeftTableBodyTxtBoxYen_${rowIndex}_${colIndex}`}
                              disabled={disable}
                              InputProps={{
                                inputProps: {
                                  tabIndex: colIndex + 1,
                                  min: 0,
                                  max: limit?.dayLimitBit[
                                    Number(
                                      spotBitInfo.dayBit[colIndex].cells[
                                        rowIndex
                                      ].powerVolume
                                    ) < 0
                                      ? 1
                                      : 0
                                  ].cells[rowIndex].upperPowerPrice,
                                  step: "0.01",
                                  style: {
                                    padding: "0px",
                                    width: colIndex !== 4 ? "80px" : "70px",
                                    height: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    textAlign: "right",
                                    backgroundColor:
                                      disable === true
                                        ? PaletteMode === "dark"
                                          ? "rgb(90,90,90)"
                                          : "rgb(200,200,200)"
                                        : "",
                                  },
                                },
                              }}
                              type="number"
                              value={
                                spotBitInfo.dayBit[colIndex].cells[rowIndex]
                                  .powerPrice
                              }
                              onFocus={(e) => {
                                if (
                                  spotBitInfo.dayBit[colIndex].cells[rowIndex]
                                    .powerPrice === "0.00"
                                ) {
                                  e.currentTarget.select();
                                }
                              }}
                              onBlur={(e) => {
                                priceFocusHandle(
                                  e.target.value,
                                  Number(
                                    limit?.dayLimitBit[
                                      Number(
                                        spotBitInfo.dayBit[colIndex].cells[
                                          rowIndex
                                        ].powerVolume
                                      ) < 0
                                        ? 1
                                        : 0
                                    ].cells[rowIndex].upperPowerPrice
                                  ),
                                  Number(
                                    limit?.dayLimitBit[
                                      Number(
                                        spotBitInfo.dayBit[colIndex].cells[
                                          rowIndex
                                        ].powerVolume
                                      ) < 0
                                        ? 1
                                        : 0
                                    ].cells[rowIndex].lowerPowerPrice
                                  ),
                                  rowIndex,
                                  colIndex
                                );
                              }}
                              onKeyDown={(event) => {
                                if (
                                  event.key === "e" ||
                                  event.key === "E" ||
                                  event.key === "-"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                changeTfStateForPri(
                                  e.target.value,
                                  colIndex,
                                  rowIndex
                                );
                                leavePageContext.setBlockLeave(true);
                              }}
                            />
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                    <TableRow
                      key={"spotBidLeftTableBodyRowUnit_" + rowIndex}
                      sx={{ height: "24px" }}
                    >
                      <StyledTableCell
                        key={"spotBidLeftTableBodyCellUnit_" + rowIndex}
                        sx={{
                          left: 0,
                          padding: 0,
                          width: "92px",
                          minWidth: "91px",
                          backgroundColor:
                            disable === true
                              ? PaletteMode === "dark"
                                ? "rgb(90,90,90)"
                                : "rgb(200,200,200)"
                              : "",
                        }}
                        align="center"
                      >
                        MWh/h
                      </StyledTableCell>
                      {spotBitInfo.dayBit.map((col, colIndex) => {
                        return (
                          <StyledTableCell
                            key={`spotBidLeftTableBodyTxtCellUnit_${rowIndex}_${colIndex}`}
                            align="center"
                            sx={{
                              padding: "0px",
                              width: colIndex === 4 ? "76.5px" : "86.3px",
                              backgroundColor:
                                disable === true
                                  ? PaletteMode === "dark"
                                    ? "rgb(90,90,90)"
                                    : "rgb(200,200,200)"
                                  : "",
                            }}
                          >
                            <TextField
                              title={createToolTipText(
                                Number(
                                  Number(
                                    limit?.dayLimitBit[
                                      Number(
                                        spotBitInfo.dayBit[colIndex].cells[
                                          rowIndex
                                        ].powerVolume
                                      ) < 0
                                        ? 1
                                        : 0
                                    ].cells[rowIndex].lowerPowerVolume
                                  ) *
                                    (Number(
                                      spotBitInfo.dayBit[colIndex].cells[
                                        rowIndex
                                      ].powerVolume
                                    ) < 0
                                      ? -1
                                      : 1)
                                ).toFixed(2),
                                Number(
                                  Number(
                                    limit?.dayLimitBit[
                                      Number(
                                        spotBitInfo.dayBit[colIndex].cells[
                                          rowIndex
                                        ].powerVolume
                                      ) < 0
                                        ? 1
                                        : 0
                                    ].cells[rowIndex].upperPowerVolume
                                  ) *
                                    (Number(
                                      spotBitInfo.dayBit[colIndex].cells[
                                        rowIndex
                                      ].powerVolume
                                    ) < 0
                                      ? -1
                                      : 1)
                                ).toFixed(2),
                                "MWh/h"
                              )}
                              key={`spotBidLeftTableBodyTxtBoxUnit_${rowIndex}_${colIndex}`}
                              disabled={disable}
                              InputProps={{
                                inputProps: {
                                  tabIndex: colIndex + 1,
                                  min:
                                    Number(
                                      limit?.dayLimitBit[1].cells[rowIndex]
                                        .upperPowerVolume
                                    ) * -1,
                                  max: limit?.dayLimitBit[0].cells[rowIndex]
                                    .upperPowerVolume,
                                  step: "0.1",
                                  style: {
                                    padding: "0px",
                                    width: colIndex !== 4 ? "80px" : "70px",
                                    height: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    textAlign: "right",
                                    backgroundColor:
                                      disable === true
                                        ? PaletteMode === "dark"
                                          ? "rgb(90,90,90)"
                                          : "rgb(200,200,200)"
                                        : "",
                                  },
                                },
                              }}
                              type="number"
                              value={
                                spotBitInfo.dayBit[colIndex].cells[rowIndex]
                                  .powerVolume
                              }
                              onFocus={(e) => {
                                if (
                                  spotBitInfo.dayBit[colIndex].cells[rowIndex]
                                    .powerVolume === "0.00"
                                ) {
                                  e.currentTarget.select();
                                }
                              }}
                              onBlur={(e) => {
                                amountFocusHandle(
                                  e.target.value,
                                  Number(
                                    limit.dayLimitBit[0].cells[rowIndex]
                                      .upperPowerVolume
                                  ),
                                  Number(
                                    limit.dayLimitBit[1].cells[rowIndex]
                                      .upperPowerVolume
                                  ) * -1,
                                  rowIndex,
                                  colIndex
                                );
                              }}
                              onKeyDown={(event) => {
                                if (event.key === "e" || event.key === "E") {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                changeTfStateForQu(
                                  e.target.value,
                                  colIndex,
                                  rowIndex
                                );
                                leavePageContext.setBlockLeave(true);
                              }}
                            />
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>
    </TableContainer>
    // </ScrollSync>
  );
};
// 通常入札時の画面右側のテーブルコンポーネント
const SpotBidRightTable = (
  areaId: string,
  ucTenderNum: number,
  getBidStatus: () => Promise<void>,
  setDialogMessage: React.Dispatch<React.SetStateAction<string>>,
  setDigOpen: React.Dispatch<React.SetStateAction<boolean>>,
  procurementFlag: React.MutableRefObject<boolean>,
  salesData: saleData,
  demandData: provideData,
  supplyData: supplyData,
  isDisableReflect: () => boolean,
  getPlanData: () => void,
  bidData: bitInfo,
  hidden: boolean,
  api: AxiosInstance
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  //日付のデータコンテキスト
  const selectedDate = useContext(DateContext);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);
  const [localDate, setLocalDate] = useState<Date | null>(tomorrow);

  //過去約定のuseState
  const [pastData, setpastData] = useState(previousBitData);

  //DateContextで選択している日付の約定結果
  const dateContractData = React.useRef<previousBit>({
    targetDate: selectedDate.pickDate as Date,
    resultCells: {
      cells: new Array(48).fill(true).map((_) => ({
        result: -1,
        fixedPowerVolume: 0,
        fixedPowerPrice: 0,
      })),
    },
  });

  //販売計画のテキストフィールド
  const sellingPlan = React.useRef<number[]>(
    salesData.cellVolumes.map((value) => value)
  );
  //調達計画のテキストフィールド
  const procurementPlan = React.useRef<number[]>(
    demandData.cellVolumes.map((value) => value)
  );

  useEffect(() => {
    if (areaId !== "" && areaId !== "00") {
      getDispContractData();
    }
  }, [localDate, areaId]);

  const getContract = async () => {
    await getContractData().then(async () => await getDispContractData());
  };

  // DBからaxios getしてくる
  const getContractData = async () => {
    for (let i = 0; i < 5; i++) {
      await api
        .get(GET_SPOT_CONTRACT, {
          params: {
            targetDate: formatDateYYYYMMdd(subDays(localDate as Date, i)),
            areaId: areaId,
            ucTenderNum: Number.isNaN(ucTenderNum) ? 0 : ucTenderNum,
          },
        })
        .then(async (response) => {
          //.log(response);
        })
        .catch((e :AxiosError) => {
          if (e.response?.status === 504){
            setDialogMessage(languageContext.words.could_not_communicate_jepx);
          } else {
            setDialogMessage(languageContext.words.could_not_obtain_latest_results);
          }
          setDigOpen(true); 
          console.log(e);
        });
    }
  };

  const getDispContractData = async () => {
    await api
      .get(GET_SPOT_CONTRACT_LIST, {
        params: {
          targetDate: formatDateYYYYMMdd(localDate as Date),
          areaId: areaId,
        },
      })
      .then((response) => {
        const receivedData: previousBit[] = response.data.pastData;
        setpastData(receivedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSelectDateContractData = async (mode: string) => {
    await api
      .get(GET_SPOT_CONTRACT_LIST, {
        params: {
          targetDate: formatDateYYYYMMdd(selectedDate.pickDate as Date),
          areaId: areaId,
          mode: mode,
        },
      })
      .then((response) => {
        const receivedData: previousBit[] = response.data.pastData;
        dateContractData.current = receivedData[0];
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //販売・調達計画保存
  const insertSalesValues = async () => {
    api
      .post(POST_SALES_PLAN, {
        areaId: areaId,
        targetDate: formatDateYYYYMMdd(selectedDate.pickDate as Date),
        values: sellingPlan.current as unknown as number[],
      })
      .then((res) => {
        // if (procurementFlag.current) {
        // insertProcurementValues();
        // } else {
        setDialogMessage(languageContext.words.contract_reflected);
        setDigOpen(true);
        // }
        getPlanData();
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.could_not_be_reflected);
        setDigOpen(true);
      });
  };

  const insertProcurementValues = async () => {
    api
      .post(POST_PROCUREMENT_PLAN, {
        areaId: areaId,
        targetDate: formatDateYYYYMMdd(selectedDate.pickDate as Date),
        values: procurementPlan.current as unknown as number[],
      })
      .then((res) => {
        setDialogMessage(
          languageContext.words.save_sales_plan_procurement_plan
        );
        setDigOpen(true);
        leavePageContext.setBlockLeave(false);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(
          languageContext.words.cannot_save_sales_plan_procurement_plan
        );
        setDigOpen(true);
      });
  };

  //売り約定量を抽出
  const extractSalesData = () => {
    sellingPlan.current = dateContractData.current.resultCells.cells.map(
      (values) => {
        if (values.fixedPowerVolume < 0) {
          return values.fixedPowerVolume * -1;
        } else {
          return 0;
        }
      }
    );
  };

  //買い約定量を抽出
  const extractProcurementData = () => {
    procurementPlan.current = dateContractData.current.resultCells.cells.map(
      (values) => {
        if (values.fixedPowerVolume > 0) {
          return values.fixedPowerVolume;
        } else {
          return 0;
        }
      }
    );
  };

  //約定量を抽出
  const extractContractAmount = async () => {
    await getSelectDateContractData("0").then((res) => {
      extractSalesData();
      extractProcurementData();
    });
  };

  //保存ボタンのイベント
  const SaveButtonEvent = async () => {
    // console.log("保存ボタン押された");
    await extractContractAmount();
    await insertSalesValues();
    // insertProcurementValues();
  };

  //約定できたかどうかの値をstringに変換する関数
  const convertNumToResultString = (resultNumber: number): string => {
    switch (resultNumber) {
      case 0: {
        return languageContext.words.unable_to_do;
      }
      case 1: {
        return languageContext.words.total_amount;
      }
      case 2: {
        return languageContext.words.part;
      }
      case 3: {
        return "";
      }
      default: {
        return "";
      }
    }
  };

  //この↓で使われるパーツ
  const SpotBidLeftTablesPartsA = (index: number): JSX.Element[] => {
    const SpotBidLeftTablesParts: JSX.Element[] = [];
    for (let i = 0; i < pastData.length; i++) {
      SpotBidLeftTablesParts.push(
        <>
          <StyledTableCell
            key={"spotBidRightTableBodyColResult1_" + index}
            rowSpan={2}
            sx={{
              minWidth: "60px",
              padding: "0px",
              color: setColorPreviousBit(
                convertNumToResultString(
                  pastData[i].resultCells.cells[index].result
                )
              ),
            }}
            style={{
              backgroundColor: setBackGroundColorPreviousBit(
                convertNumToResultString(
                  pastData[i].resultCells.cells[index].result
                )
              ),
            }}
            align="center"
          >
            {convertNumToResultString(
              pastData[i].resultCells.cells[index].result
            )}
          </StyledTableCell>
          <StyledTableCell
            key={"spotBidRightTableBodyColResultValueYen1_" + index}
            sx={{
              minWidth: i === 4 ? "92.95px" : "103.46px",
              width: i === 4 ? "96.69px" : "106.63px",
              padding: "0px 8px 0px 0px",
              boxSizing: "border-box",
            }}
            align="right"
          >
            {pastData[i].resultCells.cells[index].fixedPowerPrice.toFixed(2)}
            {/* 約定価格 */}
          </StyledTableCell>
        </>
      );
    }
    return SpotBidLeftTablesParts;
  };

  const SpotBidLeftTablesPartsB = (index: number): JSX.Element[] => {
    const SpotBidLeftTablesParts: JSX.Element[] = [];
    for (let i = 0; i < pastData.length; i++) {
      SpotBidLeftTablesParts.push(
        <StyledTableCell
          key={"spotBidRightTableBodyColResultValueMWh1_" + index}
          sx={{
            minWidth: i === 4 ? "93.25px" : "103.4px",
            width: i === 4 ? "96.69px" : "106.63px",
            padding: "0px 8px 0px 0px",
            boxSizing: "border-box",
          }}
          align="right"
        >
          {convertToLocaleString(
            pastData[i].resultCells.cells[index].fixedPowerVolume
          )}
          {/* 約定量 */}
        </StyledTableCell>
      );
    }
    return SpotBidLeftTablesParts;
  };

  // 約定結果の文字色を設定する
  const setColorPreviousBit = (result: string) => {
    if (result === languageContext.words.total_amount) {
      return "#4689EF";
    } else if (result === languageContext.words.unable_to_do) {
      return "#EE0000";
    }

    return "";
  };

  const setBackGroundColorPreviousBit = (result: string) => {
    if (result === languageContext.words.total_amount) {
      return PaletteMode === "dark" ? "#00EEFF3F" : "#D7EEFF";
    } else if (result === languageContext.words.unable_to_do) {
      return PaletteMode === "dark" ? "#b940473F" : "#FFD5EC";
    }

    return "";
  };

  //日付を出す関数
  const calcDisplayDate = (index: number) => {
    if (localDate !== null) {
      return languageContext.convertDateToLocalizedStringPoorAcu(
        subDays(localDate, index)
      );
    }
  };

  return (
    <TableContainer
      sx={{
        padding: "0px",
        height: "100%",
        marginLeft: "5px",
        width: "55%",
        overflowY: "scroll",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
          width: "10px",
          backgroundColor: "transparent",
          height: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#AAA",
          borderRadius: "10px",
        },
      }}
    >
      <StyledTable
        stickyHeader={true}
        sx={{
          borderRight: "none",
          height: "100%",
        }}
      >
        <TableHead
          sx={{
            display: "block",
            position: "sticky",
            overflow: "auto",
            width: "100%",
          }}
        >
          <TableRow sx={{ height: "20px" }}>
            <StyledTableHeaderGBorder
              rowSpan={4}
              sx={{
                minWidth: "100px",
                height: "151px",
                padding: "0px",
              }}
              align="center"
            >
              {languageContext.words.timeB}
            </StyledTableHeaderGBorder>
            <StyledTableHeaderGBorder
              colSpan={numOfDisplayPastData * 2}
              sx={{
                width: "790px",
                padding: "0px",
              }}
              align="center"
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ marginTop: "10px", fontSize: "14px" }}>
                  {languageContext.words.display_start_date}
                </Typography>
                <Box sx={{ marginTop: "0px", marginBottom: "0.4px" }}>
                  <CustomDatePickerTryal setValue={setLocalDate} />
                </Box>
                <Button
                  variant="outlined"
                  sx={{
                    marginRight: "5px",
                    padding: "5px",
                    marginTop: "5px",
                    height: "28px",
                    fontSize: "13px",
                    backgroundColor:
                      PaletteMode === "dark" ? "#3e3e3e" : "white",
                  }}
                  onClick={async () => {
                    await getContract();
                    await getBidStatus();
                  }}
                  disabled={hidden}
                >
                  <RefreshIcon sx={{ marginRight: "8px" }}></RefreshIcon>
                  {languageContext.words.latest_results}
                </Button>
                <Button
                  variant="outlined"
                  disabled={isDisableReflect() || hidden}
                  sx={{
                    backgroundColor:
                      PaletteMode === "dark" ? "#3e3e3e" : "white",
                    height: "28px",
                    margin: "5px",
                    fontSize: "13px",
                  }}
                  onClick={SaveButtonEvent}
                >
                  {languageContext.words.reflect_contract_amount}
                </Button>
              </Box>
            </StyledTableHeaderGBorder>
          </TableRow>
          <TableRow>
            {Array(numOfDisplayPastData)
              .fill(0)
              .map((col, index) => {
                return (
                  <StyledTableHeaderGBorder
                    key={"spotBidRightTableHeaderDate_" + index}
                    colSpan={2}
                    sx={{
                      position: "sticky",
                      padding: "0px",
                      top: 0,
                    }}
                    align="center"
                  >
                    {calcDisplayDate(index)}
                  </StyledTableHeaderGBorder>
                );
              })}
          </TableRow>
          <TableRow>
            {Array(numOfDisplayPastData)
              .fill(0)
              .map((col, index) => {
                return (
                  <>
                    <StyledTableHeaderGBorder
                      key={"spotBidRightTableHeaderResult_" + index}
                      rowSpan={2}
                      sx={{
                        position: "sticky",
                        padding: "0px",
                        top: 0,
                        minWidth: "60px",
                      }}
                      align="center"
                    >
                      {languageContext.words.result}
                    </StyledTableHeaderGBorder>
                    <StyledTableHeaderGBorder
                      key={"spotBidRightTableHeaderYakujoTanka_" + index}
                      sx={{
                        position: "sticky",
                        padding: "0px",
                        top: 0,
                        width: "145px",
                      }}
                      align="center"
                    >
                      {languageContext.words.contrac_price}
                      <br />({languageContext.words.yen_kwh})
                    </StyledTableHeaderGBorder>
                  </>
                );
              })}
          </TableRow>
          <TableRow>
            {Array(numOfDisplayPastData)
              .fill(0)
              .map((col, index) => {
                return (
                  <>
                    <StyledTableHeaderGBorder
                      key={"spotBidRightTableHeaderYakujo_" + index}
                      sx={{
                        position: "sticky",
                        padding: "0px",
                        top: 0,
                        width: "145px",
                      }}
                      align="center"
                    >
                      {languageContext.words.contract_quantity}
                      <br />
                      (MWh/h)
                    </StyledTableHeaderGBorder>
                  </>
                );
              })}
          </TableRow>
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflowY: "overlay",
              width: "100%",
              height: "550px",
              "&::-webkit-scrollbar": {
                width: "10px",
                backgroundColor: "transparent",
                height: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#AAA",
                borderRadius: "10px",
              },
            }}
          >
            {Array(48)
              .fill(0)
              .map((row, index) => {
                return (
                  // TODO: fksm 4日分べた書きしてるので一つにまとめる
                  <>
                    <TableRow
                      key={"spotBidRightTableBodyRowYenkWh_" + index}
                      sx={{ height: "24px" }}
                    >
                      <StyledTableCell
                        key={"spotBidRightTableBodyColTime_" + index}
                        rowSpan={2}
                        sx={{
                          minWidth: "100px",
                          height: "47px",
                          padding: "0px",
                        }}
                        align="center"
                      >
                        {times[index]}
                      </StyledTableCell>
                      {SpotBidLeftTablesPartsA(index)}
                    </TableRow>

                    <TableRow key={"spotBidRightTableBodyRowMWh_" + index}>
                      {SpotBidLeftTablesPartsB(index)}
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>
    </TableContainer>
  );
};
// ブロック入札時の画面左側のテーブルコンポーネント
const BlockBidLeftTable = () => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  // 入札量 入力欄のTextBoxの数
  const bidInputAreaCol = 5; // 5ブロック固定

  // 自己託送データ
  const supplyData: TestDatas.supplyData = TestDatas.supplys;
  // 調達計画データ
  const provideData: TestDatas.provideData = TestDatas.provides;
  // 販売計画データ
  const saleData: TestDatas.saleData = TestDatas.sales;

  // 入札情報
  // 1エリア分のデータを取得
  // データが取れない場合1番目の要素のデータをデフォルトでセットする
  const blockBitInfoData: TestDatas.bitInfo[] =
    TestDatas.blockBitInfos.filter((data) => data.area === 1) ??
    TestDatas.blockBitInfos[0];

  return (
    <ScrollSync>
      <TableContainer
        sx={{
          display: "flex",
          position: "relative",
          padding: "0px",
          width: "50%",
          height: "100%",
          marginRight: "10px",
          overflowY: "hidden",
        }}
      >
        {/* 時刻～調達計画合計までのテーブル */}
        <StyledTable
          stickyHeader={true}
          sx={{
            width: "40%",
            borderRight: "none",
            height: "100%",
          }}
        >
          <TableHead
            sx={{
              display: "block",
              position: "sticky",
              overflow: "auto",
            }}
          >
            <TableRow sx={{ height: "48px" }}>
              <StyledTableHeaderLG
                rowSpan={2}
                sx={{
                  width: "20%",
                  padding: "10px 5px",
                }}
                align="center"
              >
                {languageContext.words.timeB}
              </StyledTableHeaderLG>

              <StyledTableHeaderLG
                sx={{
                  width: "20%",
                  padding: "10px 5px",
                }}
                align="center"
              >
                {languageContext.words.self_consignment}
                <br />
                {languageContext.words.total}
                <br />
                {"(MWh/h)"}
              </StyledTableHeaderLG>

              <StyledTableHeaderLG
                sx={{
                  width: "20%",
                  padding: "10px 5px",
                }}
                align="center"
              >
                {languageContext.words.sales_plan}
                <br />
                {languageContext.words.total}
                <br />
                {"(MWh/h)"}
              </StyledTableHeaderLG>

              <StyledTableHeaderLG
                sx={{
                  width: "20%",
                  padding: "10px 10px",
                }}
                align="center"
              >
                {languageContext.words.procurement_plan}
                <br />
                {languageContext.words.total}
                <br />
                {"(MWh/h)"}
              </StyledTableHeaderLG>
            </TableRow>

            <TableRow sx={{ height: "35px" }}>
              {Array(3)
                .fill(0)
                .map((value, index) => {
                  return (
                    <StyledTableHeaderLG
                      key={"blockLeftTableCopyCell_" + index}
                      sx={{
                        width: "20%",
                        height: "35px",
                        padding: "3px",
                      }}
                      align="center"
                    >
                      <Button
                        variant="outlined"
                        sx={{
                          padding: "5px",
                          marginTop: "5px",
                          height: "28px",
                          fontSize: "13px",
                          backgroundColor: "white",
                        }}
                        key={"blockLeftTableCopyBtn_" + index}
                      >
                        {languageContext.words.copy}
                      </Button>
                    </StyledTableHeaderLG>
                  );
                })}
            </TableRow>

            <TableRow sx={{ height: "40px" }}>
              <StyledTableHeaderLG
                sx={{
                  width: "20%",
                  padding: "0px",
                }}
                align="center"
              >
                {languageContext.words.daily_amount}
              </StyledTableHeaderLG>

              <StyledTableHeaderLG
                sx={{
                  width: "20%",
                  padding: "5px",
                }}
                align="right"
              >
                {/* 自己託送 */}
                {supplyData.dayTotal}
              </StyledTableHeaderLG>

              <StyledTableHeaderLG
                sx={{
                  width: "20%",
                  padding: "5px",
                }}
                align="right"
              >
                {/* 販売計画 */}
                {saleData.dayTotal}
              </StyledTableHeaderLG>

              <StyledTableHeaderLG
                sx={{
                  width: "20%",
                  padding: "5px",
                }}
                align="right"
              >
                {/* 調達計画 */}
                {provideData.dayTotal}
              </StyledTableHeaderLG>
            </TableRow>
          </TableHead>
          <ScrollSyncPane group="vertical">
            <TableBody
              sx={{
                display: "block",
                overflowY: "auto",
                height: "550px",
                "&::-webkit-scrollbar": {
                  width: "0px",
                  backgroundColor: "transparent",
                  height: "0px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#AAA",
                  borderRadius: "0px",
                },
              }}
            >
              {Array(48)
                .fill(0)
                .map((row, index) => (
                  <TableRow
                    key={"blockLeftTableBodySumRow_" + index}
                    sx={{ height: "49px" }}
                  >
                    <StyledTableCell
                      key={"blockLeftTableBodySumCellTime_" + index}
                      sx={{
                        width: "20%",
                        padding: "0px",
                      }}
                      align="center"
                    >
                      {TestDatas.times[index]}
                    </StyledTableCell>

                    <StyledTableCell
                      key={"blockLeftTableBodySumCellMySent_" + index}
                      sx={{
                        width: "20%",
                        padding: "0px",
                      }}
                      align="right"
                    >
                      {convertToLocaleString(supplyData.cellVolumes[index])}
                    </StyledTableCell>

                    <StyledTableCell
                      key={"blockLeftTableBodySumCellSale_" + index}
                      sx={{
                        width: "20%",
                        padding: "0px",
                      }}
                      align="right"
                    >
                      {convertToLocaleString(saleData.cellVolumes[index])}
                    </StyledTableCell>

                    <StyledTableCell
                      key={"blockLeftTableBodySumCellProvide_" + index}
                      sx={{
                        width: "20%",
                        padding: "0px",
                      }}
                      align="right"
                    >
                      {convertToLocaleString(provideData.cellVolumes[index])}
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </ScrollSyncPane>
        </StyledTable>

        {/* 単位～最後までのテーブル */}
        <StyledTable
          stickyHeader={true}
          sx={{
            width: "60%",
            display: "block",
            position: "sticky",
            overflowY: "hidden",
            overflowX: "scroll",
            padding: "0px",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor: "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          <TableHead
            sx={{
              display: "block",
              position: "sticky",
              padding: "0px",
              width: "1316px",
            }}
          >
            <TableRow sx={{ height: "164.39px" }}>
              <StyledTableHeaderLG
                sx={{
                  width: "68px",
                }}
                align="center"
              >
                {languageContext.words.unit}
              </StyledTableHeaderLG>
              {Array(bidInputAreaCol)
                .fill(0)
                .map((col, index) => {
                  return (
                    <StyledTableHeaderLG
                      key={"blockStepList_" + index}
                      sx={{
                        width: "80px",
                        padding: "0px",
                      }}
                      align="center"
                    >
                      <Checkbox
                        key={"blockStepListCheck_" + index}
                        sx={{
                          "& .MuiSvgIcon-root": { fontSize: 17 },
                          padding: 0,
                        }}
                      />
                      {index + 1}
                    </StyledTableHeaderLG>
                  );
                })}
            </TableRow>
          </TableHead>
          <ScrollSyncPane group="vertical">
            <TableBody
              sx={{
                display: "block",
                overflowY: "auto",
                overflowX: "hidden",
                height: "550px",
                width: "1326px",
                top: "0",
                "&::-webkit-scrollbar": {
                  width: "10px",
                  backgroundColor: "transparent",
                  height: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#AAA",
                  borderRadius: "10px",
                },
              }}
            >
              {Array(48)
                .fill(0)
                .map((row, rowIndex) => {
                  return (
                    <>
                      <TableRow
                        key={"blockBidLeftTableBodyRow_" + rowIndex}
                        sx={{ height: "24px" }}
                      >
                        <StyledTableCell
                          key={"blockBidLeftTableBodyUnitYen_" + rowIndex}
                          sx={{
                            left: 0,
                            padding: 0,
                            width: "100px",
                          }}
                          align="center"
                        >
                          MWh/h
                        </StyledTableCell>
                        {Array(bidInputAreaCol)
                          .fill(0)
                          .map((col, colIndex) => {
                            return (
                              <StyledTableCell
                                key={"blockBidLeftTableBodyTxtCell_" + colIndex}
                                align="center"
                                sx={{
                                  padding: "0px",
                                  width: "80px",
                                }}
                              >
                                <input
                                  key={"spotBidLeftTableBodyTxtBox_" + colIndex}
                                  style={{
                                    padding: "0px",
                                    width: "70px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    textAlign: "right",
                                  }}
                                  min="0"
                                  max="10"
                                  step="0.1"
                                  type="number"
                                  value={blockBitInfoData[
                                    colIndex
                                  ].dayBit[0].cells[
                                    rowIndex
                                  ].powerVolume.toFixed(2)}
                                />
                              </StyledTableCell>
                            );
                          })}
                      </TableRow>

                      <TableRow
                        key={"spotBidLeftTableBodyRowYen_" + rowIndex}
                        sx={{ height: "24px" }}
                      >
                        <StyledTableCell
                          key={"spotBidLeftTableBodyCellYen_" + rowIndex}
                          sx={{
                            left: 0,
                            padding: 0,
                            width: "100px",
                          }}
                          align="center"
                        >
                          {languageContext.words.yen_kwh}
                        </StyledTableCell>
                        {Array(bidInputAreaCol)
                          .fill(0)
                          .map((col, colIndex) => {
                            return (
                              <StyledTableCell
                                key={`spotBidLeftTableBodyTxtCellYen_${rowIndex}_${colIndex}`}
                                align="center"
                                sx={{
                                  padding: "0px",
                                  width: "80px",
                                }}
                              >
                                <input
                                  key={`spotBidLeftTableBodyTxtBoxYen_${rowIndex}_${colIndex}`}
                                  style={{
                                    padding: "0px",
                                    width: "70px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    textAlign: "right",
                                  }}
                                  min="0"
                                  max="10"
                                  step="0.01"
                                  type="number"
                                  value={blockBitInfoData[
                                    colIndex
                                  ].dayBit[0].cells[
                                    rowIndex
                                  ].powerPrice.toFixed(2)}
                                />
                              </StyledTableCell>
                            );
                          })}
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
          </ScrollSyncPane>
        </StyledTable>
      </TableContainer>
    </ScrollSync>
  );
};

const BlockBidRightTable = () => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  const [localDate, setLocalDate] = useState<Date | null>(new Date());

  // 入札情報
  // 1エリア分のデータを取得
  // データが取れない場合1番目の要素のデータをデフォルトでセットする
  const blockBitInfoData: TestDatas.bitInfo[] =
    TestDatas.blockBitInfos.filter((data) => data.area === 1) ??
    TestDatas.blockBitInfos[0];

  return (
    <TableContainer
      sx={{
        display: "flex",
        padding: "0px",
        width: "50%",
        height: "100%",
        marginLeft: "10px",
        overflowY: "hidden",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
          width: "10px",
          backgroundColor: "transparent",
          height: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#AAA",
          borderRadius: "10px",
        },
      }}
    >
      <StyledTable stickyHeader>
        <TableHead sx={{ display: "block", position: "sticky" }}>
          <TableRow>
            <StyledTableHeaderGBorder
              rowSpan={4}
              align="center"
              sx={{ padding: "0px", height: "100px", width: "100px" }}
            >
              {languageContext.words.timeB}
            </StyledTableHeaderGBorder>
            <StyledTableHeaderGBorder
              colSpan={9}
              sx={{
                width: "790px",
                padding: "0px",
              }}
              align="center"
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ marginTop: "10px", fontSize: "14px" }}>
                  {languageContext.words.display_start_date}
                </Typography>
                <Box sx={{ marginTop: "0px", marginBottom: "0.4px" }}>
                  <CustomDatePickerTryal setValue={setLocalDate} />
                </Box>
                <Button
                  variant="outlined"
                  sx={{
                    padding: "5px",
                    marginTop: "5px",
                    height: "28px",
                    fontSize: "13px",
                    backgroundColor: "white",
                  }}
                >
                  <RefreshIcon sx={{ marginRight: "8px" }}></RefreshIcon>
                  {languageContext.words.latest_results}
                </Button>
              </Box>
            </StyledTableHeaderGBorder>
          </TableRow>
          <TableRow>
            <StyledTableHeaderGBorder
              colSpan={3}
              align="center"
              sx={{ padding: "0px", width: "300px" }}
            >
              22/12/22
            </StyledTableHeaderGBorder>
            <StyledTableHeaderGBorder
              colSpan={3}
              align="center"
              sx={{ padding: "0px", width: "300px" }}
            >
              22/12/21
            </StyledTableHeaderGBorder>
            <StyledTableHeaderGBorder
              colSpan={3}
              align="center"
              sx={{ padding: "0px", width: "300px" }}
            >
              22/12/20
            </StyledTableHeaderGBorder>
          </TableRow>
          <TableRow>
            {/* 日付毎に3札分ヘッダーを作る */}
            {Array(3)
              .fill(0)
              .map((day, dayIndex) => {
                return Array(3)
                  .fill(0)
                  .map((col, index) => {
                    return (
                      <StyledTableHeaderGBorder
                        key={"blockRightTableHeaderFuda_" + index}
                        sx={{ padding: "0px" }}
                        align="center"
                      >
                        {index + 1}
                      </StyledTableHeaderGBorder>
                    );
                  });
              })}
          </TableRow>
          <TableRow>
            {/* 日付毎に3札分ヘッダーを作る(約定量ラベル) */}
            {Array(3)
              .fill(0)
              .map(() => {
                return Array(3)
                  .fill(0)
                  .map((col, index) => {
                    return (
                      <StyledTableHeaderGBorder
                        key={"blockRightTableHeaderYakujoLabel_" + index}
                        sx={{ padding: "0px" }}
                        align="center"
                      >
                        {languageContext.words.contract_quantity}
                        <br />
                        (MWh/h)
                      </StyledTableHeaderGBorder>
                    );
                  });
              })}
          </TableRow>
          <TableRow>
            <StyledTableHeaderGBorder sx={{ padding: "0px" }} align="center">
              {languageContext.words.contrac_price}
            </StyledTableHeaderGBorder>
            {/* 日付毎に3札分ヘッダーを作る(約定価格) */}
            {Array(3)
              .fill(0)
              .map((day, dayIndex) => {
                return Array(3)
                  .fill(0)
                  .map((col, index) => {
                    return (
                      <StyledTableHeaderGBorder
                        key={"blockRightTableHeaderYakujoSum_" + index}
                        sx={{ textAlign: "right" }}
                      >
                        {/* TODO: fksm デフォルト10.0表示 展示会用 */}
                        {blockBitInfoData[dayIndex].dayBit[0].cells[0]
                          .powerPrice === 0
                          ? (10.0).toFixed(2)
                          : blockBitInfoData[
                              dayIndex
                            ].dayBit[0].cells[0].powerPrice.toFixed(2)}
                      </StyledTableHeaderGBorder>
                    );
                  });
              })}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            display: "block",
            // overflowY: "scroll",
            width: "1000px",
            height: "500px",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor: "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          {Array(48)
            .fill(0)
            .map((row, rowIndex) => {
              return (
                <TableRow
                  key={"blockBidRightTableBodyTime_" + rowIndex}
                  sx={{ height: "51px" }}
                >
                  <StyledTableCell
                    key={"blockBidRightTableBodyColTime_" + rowIndex}
                    sx={{
                      width: "100px",
                      padding: "0px",
                    }}
                    align="center"
                  >
                    {TestDatas.times[rowIndex]}
                  </StyledTableCell>
                  {Array(3)
                    .fill(0)
                    .map((day, dayIndex) => {
                      return Array(3)
                        .fill(0)
                        .map((col, colIndex) => {
                          return (
                            <StyledTableCell
                              key={
                                "blockBidRightTableBodyColTime_" +
                                dayIndex +
                                colIndex
                              }
                              sx={{
                                padding: "0px",
                                paddingRight: "5px",
                                width: "94px",
                                height: "26px",
                                borderRight: "0px",
                              }}
                              align="right"
                            >
                              {blockBitInfoData[colIndex].dayBit[0].cells[
                                rowIndex
                              ].powerVolume === 0
                                ? ""
                                : blockBitInfoData[colIndex].dayBit[0].cells[
                                    rowIndex
                                  ].powerVolume.toFixed(2)}
                            </StyledTableCell>
                          );
                        });
                    })}
                </TableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

// メインファンクションコンポーネント
const SpotBidding = () => {
  // 現在の時刻取得
  const { time } = useContext(TimeContext);

  const endTime = new Date(
    time.getFullYear(),
    time.getMonth(),
    time.getDate(),
    10
  );

  const endReflect = new Date(
    time.getFullYear(),
    time.getMonth(),
    time.getDate(),
    12
  );

  // 入札系のボタンのdisable管理
  // const [buttonDisable, setButtonDisable] = useState<boolean>(false);

  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  //日付のデータコンテキスト
  const selectedDate = useContext(DateContext);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);

  // 通信エラー
  const api = useAuthedApi();

  // 入札ボタンのdisabled管理
  const [bidBtnDisable, setBidBtnDisable] = useState<boolean>(true);
  // 取消ボタンのdisabled管理
  const [deleteBtnDisable, setDeleteBtnDisable] = useState<boolean>(true);

  // タブパネル State, handler
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  // モーダルOpenフラグ
  const [isDoneBidDialogOpen, setIsDoneBidDialogOpen] = useState(false);
  const [isCancelBidDialogOpen, setIsCancelBidDialogOpen] = useState(false);

  //こちらのコンポーネントにある入札実行ボタンで使うstate
  const [inputedDataForBid, setInputedDataForBid] =
    useState<bitInfo>(bitInfoData);

  const { areaId, setAreaId } = useContext(AreaContext);

  // 入札制限値
  const [bidLimit, setBidLimit] = useState<bitLimmitReq>({
    area: Number(areaId),
    dayLimitBit: new Array(2).fill(true).map((_, index) => {
      const data: bitLimitCells = {
        type: index + 1,
        cells: new Array(48).fill(true).map((_, index) => ({
          upperPowerVolume: "0.00",
          lowerPowerVolume: "0.00",
          upperPowerPrice: "0.00",
          lowerPowerPrice: "0.00",
        })),
      };
      return data;
    }),
  });

  const [bitInfoDataState, setBitInfoData] = useState<bidStatus>({
    targetDate: requestDateFormat(new Date()),
    area: "01",
    status: 0,
    ucTender: 0,
  });

  // 事業者名称
  const [bpName, setBpName] = useState<string>("");

  // 自己託送データ
  const [supplyData, setSupplyData] = useState<supplyData>({
    targetDate: new Date(),
    dayTotal: 0,
    cellVolumes: [
      0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
      0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
      0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
      0.0, 0.0, 0.0,
    ],
  });
  // 調達計画データ
  const [demandData, setDemandData] = useState<provideData>({
    targetDate: new Date(),
    dayTotal: 0,
    cellVolumes: new Array(48).fill(0),
  });
  // 販売計画データ
  const [salesData, setSalesData] = useState<saleData>({
    targetDate: new Date(),
    dayTotal: 0,
    cellVolumes: new Array(48).fill(0),
  });
  // DB編集系ボタンの表示・非表示
  const [hidden, setHidden] = useState<boolean>(true);
  // ログイン中ユーザのロール
  const roleContext = useContext(RoleContext);

  type searchInfo = { id: string; name: string };

  const dateFormat = format("yyyy-MM-dd");

  // 約定反映ボタンのdisable管理
  const isDisableReflect = () => {
    if (
      (time >= endReflect &&
        dateFormat(selectedDate.pickDate as Date) ===
          dateFormat(addDays(time, 1))) ||
      dateFormat(selectedDate.pickDate as Date) <= dateFormat(time) || 
      dateFormat(selectedDate.pickDate as Date) > dateFormat(addDays(time, 1))// 翌々日以降はボタンを非活性
    ) {
      return true;
    } else {
      return false;
    }
  };

  // 入札系のボタンのdisable管理
  const isDisableBid = (status: number) => {
    if (
      (time >= endTime &&
        dateFormat(selectedDate.pickDate as Date) ===
          dateFormat(addDays(time, 1))) ||
      dateFormat(selectedDate.pickDate as Date) <= dateFormat(time) ||
      status === 1 ||
      status === 2 ||
      status === 7
    ) {
      setBidBtnDisable(true);
    } else {
      setBidBtnDisable(false);
    }
  };

  // 入札取消ボタンのdisable管理
  const isDisableDelete = (status: number) => {
    if (
      (time >= endTime &&
        dateFormat(selectedDate.pickDate as Date) ===
          dateFormat(addDays(time, 1))) ||
      dateFormat(selectedDate.pickDate as Date) <= dateFormat(time) ||
      !(status === 1 || status === 7)
    ) {
      setDeleteBtnDisable(true);
    } else {
      setDeleteBtnDisable(false);
    }
  };

  // 一時保存ボタンのdisable管理
  const isDisableSave = () => {
    if (
      (time >= endTime &&
        dateFormat(selectedDate.pickDate as Date) ===
          dateFormat(addDays(time, 1))) ||
      dateFormat(selectedDate.pickDate as Date) <= dateFormat(time)
    ) {
      return true;
    } else {
      return false;
    }
  };

  //エリアリスト
  const [areaList, setAreaList] = React.useState<string[]>([]);

  const { areaOptions, convertIdToAreaName } = useAreaUtility();

  const [digOpen, setDigOpen] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");

  // 入札制限モーダルOpenフラグ
  const [isBidLimitDialogOpen, setIsBidLimitDialogOpen] = useState(false);

  //調達計画が存在するかのフラグ
  const procurementFlag = React.useRef<boolean>(false);

  // カスタムダイアログ表示後処理
  const acceptHandler = () => {
    // なし
  };

  // 入札値の取得とステータスの取得を順番に行う
  const getBidData = async () => {
    await getBidStatus();
    await getBidInfo();
  };

  useEffect(() => {
    dicideSaveButtonVisibility();
    getAreaList();
    leavePageContext.setBlockLeave(false);
    if (areaId !== "" && areaId !== "00") {
      getBidData();
    }
  }, []);

  // ログインユーザのロールによってDB編集系ボタンの表示・非表示を切り替える
  const dicideSaveButtonVisibility = () => {
    api
      .get(GET_ROLE_JUDGMENT_URL, { params: { userRole: roleContext.role } })
      .then((res) => {
        setHidden(res.data ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // エリアリストを取得
  const getAreaList = async () => {
    await api
      .get(GET_AREA_NAME_LIST)
      .then((res) => {
        setAreaList(res.data.map((v: any) => v.areaId));
        setAreaId(
          Number(areaId) === 0 || areaId === ""
            ? res.data[0].areaId.toString().padStart(2, "0")
            : areaId.padStart(2, "0")
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // エリアのセレクトリスト変更時
  const changeArea = (selectItem: number) => {
    setAreaId(selectItem.toString().padStart(2, "0"));
  };

  // エリアID

  // 入札価格の最大値取得
  const serchMaxPrice = () => {
    const array: number[] = [];
    inputedDataForBid?.dayBit.forEach((daybidCell) => {
      daybidCell.cells.forEach((cell) => {
        if (Number(cell.powerPrice) !== 0) {
          array.push(Number(cell.powerPrice));
        }
      });
    });

    if (array.length === 0) {
      array.push(0);
    }

    return Math.max(...array);
  };

  // 入札価格の最小値取得
  const serchMinPrice = () => {
    const array: number[] = [];
    inputedDataForBid?.dayBit.forEach((daybidCell) => {
      daybidCell.cells.forEach((cell) => {
        if (Number(cell.powerPrice) !== 0) {
          array.push(Number(cell.powerPrice));
        }
      });
    });

    if (array.length === 0) {
      array.push(0);
    }

    return Math.min(...array);
  };

  // 入札量の最大値取得
  const serchMaxBuyVolume = () => {
    const array: number[] = [];
    inputedDataForBid?.dayBit.forEach((daybidCell) => {
      daybidCell.cells.forEach((cell) => {
        if (
          Number(cell.powerVolume) !== 0 &&
          Number(cell.powerVolume) > 0 &&
          Number(cell.powerPrice) !== 0
        ) {
          array.push(Number(cell.powerVolume));
        }
      });
    });

    if (array.length === 0) {
      array.push(0);
    }

    return Math.abs(Math.max(...array));
  };

  // 入札量の最小値取得
  const serchMinBuyVolume = () => {
    const array: number[] = [];
    inputedDataForBid?.dayBit.forEach((daybidCell) => {
      daybidCell.cells.forEach((cell) => {
        if (
          Number(cell.powerVolume) !== 0 &&
          Number(cell.powerVolume) > 0 &&
          Number(cell.powerPrice) !== 0
        ) {
          array.push(Number(cell.powerVolume));
        }
      });
    });

    if (array.length === 0) {
      array.push(0);
    }

    return Math.abs(Math.min(...array));
  };

  // 入札量の最大値取得
  const serchMinSellVolume = () => {
    const array: number[] = [];
    inputedDataForBid?.dayBit.forEach((daybidCell) => {
      daybidCell.cells.forEach((cell) => {
        if (
          Number(cell.powerVolume) !== 0 &&
          Number(cell.powerVolume) < 0 &&
          Number(cell.powerPrice) !== 0
        ) {
          array.push(Number(cell.powerVolume));
        }
      });
    });

    if (array.length === 0) {
      array.push(0);
    }

    return Math.abs(Math.max(...array));
  };

  // 入札量の最小値取得
  const serchMaxSellVolume = () => {
    const array: number[] = [];
    inputedDataForBid?.dayBit.forEach((daybidCell) => {
      daybidCell.cells.forEach((cell) => {
        if (
          Number(cell.powerVolume) !== 0 &&
          Number(cell.powerVolume) < 0 &&
          Number(cell.powerPrice) !== 0
        ) {
          array.push(Number(cell.powerVolume));
        }
      });
    });

    if (array.length === 0) {
      array.push(0);
    }

    return Math.abs(Math.min(...array));
  };

  // 札ごとの入札量合計
  const stepTotal = () => {
    const stepTotal: stepTotal[] = [];
    inputedDataForBid?.dayBit.forEach((daybidCell, index) => {
      const array: number[] = [];
      daybidCell.cells.forEach((cell) => {
        if (Number(cell.powerVolume) !== 0 && Number(cell.powerPrice) !== 0) {
          array.push(Number(cell.powerVolume));
        }
      });

      if (array.length !== 0) {
        const sum = array.reduce((sum, element) => sum + element, 0);
        stepTotal.push({ step: index + 1, total: sum });
      }
    });

    return stepTotal;
  };

  // 入札実行モーダルに表示するデータ
  const bidModalData = {
    area: convertIdToAreaName(areaId),
    bidDate: new Date().toLocaleDateString(),
    bidPrice:
      `${serchMinPrice().toFixed(2)} ～ ${serchMaxPrice().toFixed(2)} ` +
      languageContext.words.yen_kwh,
    bidVolume: `${languageContext.words.sell} ${convertToLocaleString(
      Math.floor(serchMinSellVolume() * 10) / 10
    )} ～ ${convertToLocaleString(
      Math.floor(serchMaxSellVolume() * 10) / 10
    )} MWh/h / ${languageContext.words.buy} ${convertToLocaleString(
      Math.floor(serchMinBuyVolume() * 10) / 10
    )} ～ ${convertToLocaleString(
      Math.floor(serchMaxBuyVolume() * 10) / 10
    )} MWh/h`,
    stepVolume: stepTotal(),
  };

  const getBidInfo = async () => {
    await api
      .get(GET_SPOT_BID_DATA, {
        params: {
          targetDate: requestDateFormat(selectedDate.pickDate as Date),
          areaId: areaId,
        },
      })
      .then((response) => {
        const bitInfoData: bitInfo = response.data.responceBitInfo;
        setInputedDataForBid(bitInfoData);
      })
      .catch((err) => {
        console.log(err);
      });
    return "getBidInfo";
  };

  // 入札削除
  const deleteBidInfo = async () => {
    await api
      .post(POST_BID_DELETE, {
        targetDate: requestDateFormat(selectedDate.pickDate as Date),
        areaId: areaId,
      })
      .then((response) => {
        // console.log(response.data.pastData);
        // const receivedData:previousBit[] = response.data.pastData
      })
      .catch((err) => {
        console.log(err);
      });

    await api
      .get(BID_DELETE, {
        params: {
          targetDate: requestDateFormat(selectedDate.pickDate as Date),
          areaId: areaId,
        },
      })
      .then((response) => {
        setDialogMessage(languageContext.words.canceled_bid);
        setDigOpen(true);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 504) {
          setDialogMessage(
            languageContext.words.could_not_communicate_jepx
          );
        } else {
          setDialogMessage(languageContext.words.could_not_canceled_bid);
        }
        setDigOpen(true);
      });

    getBidStatus();
  };

  // 一時保存
  const savePlan = async (mode: number, tradeStatus?: number) => {
    await api
      .post(POST_BID_DATA, {
        data: inputedDataForBid,
        targetDate: requestDateFormat(selectedDate.pickDate as Date),
        areaId: areaId,
        tradeStatus: tradeStatus,
      })
      .then(async (response) => {
        if (mode === 0) {
          setDialogMessage(languageContext.words.temporarily_save);
          setDigOpen(true);
          leavePageContext.setBlockLeave(false);
        } else {
          leavePageContext.setBlockLeave(false);
          await executeBid();
        }
      })
      .catch((err) => {
        setDialogMessage(languageContext.words.faild_temp_save);
        setDigOpen(true);
      });
    return "postBidding";
  };

  //ツールチップに表示する文字列を作成
  const createToolTipText = (
    lowerEnd: string,
    upperEnd: string,
    unit: string
  ) => {
    if (languageContext.mode === "jp") {
      return (
        convertToLocaleString(Number(lowerEnd)) +
        "～" +
        convertToLocaleString(Number(upperEnd)) +
        unit +
        "の範囲で入力してください。"
      );
    } else {
      return (
        "Enter in the range " +
        convertToLocaleString(Number(lowerEnd)) +
        " to " +
        convertToLocaleString(Number(upperEnd)) +
        " " +
        unit
      );
    }
  };

  // 入札制限範囲外の値が入力されていないか判定
  const isWhithinRangeValue = () => {
    let outRangePrice = undefined;
    for (const element of inputedDataForBid.dayBit) {
      outRangePrice = element.cells.find(
        (value, index) =>
          (Number(value.powerPrice) >
            Number(
              bidLimit.dayLimitBit[Number(value.powerVolume) < 0 ? 1 : 0].cells[
                index
              ].upperPowerPrice
            ) ||
            Number(value.powerPrice) <
              Number(
                bidLimit.dayLimitBit[Number(value.powerVolume) < 0 ? 1 : 0]
                  .cells[index].lowerPowerPrice
              )) &&
          Number(value.powerPrice) !== 0
      );
      if (outRangePrice !== undefined) {
        break;
      }
    }

    let outRangeAmount = undefined;
    for (const element of inputedDataForBid.dayBit) {
      outRangeAmount = element.cells.find(
        (value, index) =>
          Number(value.powerVolume) * (Number(value.powerVolume) < 0 ? -1 : 1) >
          Number(
            bidLimit.dayLimitBit[Number(value.powerVolume) < 0 ? 1 : 0].cells[
              index
            ].upperPowerVolume
          )
      );
      if (outRangeAmount !== undefined) {
        break;
      }
    }

    if (outRangePrice !== undefined || outRangeAmount !== undefined) {
      setDialogMessage(
        languageContext.words.outside_bid_limit +
          "\n" +
          languageContext.words.reconsider_bidding
      );
      setDigOpen(true);
      return false;
    }
    return true;
  };

  // 入札実行モーダルで実行ボタン押下時
  const executeBid = async () => {
    await api
      .get(BID_SPOT, {
        params: {
          targetDate: requestDateFormat(selectedDate.pickDate as Date),
          areaId: areaId,
        },
      })
      .then((response) => {
        setDialogMessage(languageContext.words.execute_bidding);
        setDigOpen(true);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 504) {
          setDialogMessage(languageContext.words.could_not_communicate_jepx);
        } else {
          setDialogMessage(languageContext.words.unable_bid);
        }
        setDigOpen(true);
      });
  };

  // DBから入札番号、日付、ステータスを取得
  const getBidStatus = async () => {
    await api
      .get(GET_SPOT_STATUS, {
        params: {
          targetDate: requestDateFormat(selectedDate.pickDate as Date),
          areaId: areaId,
        },
      })
      .then((response) => {
        setBitInfoData(response.data.recordData);
        isDisableBid(response.data.recordData.status);
        isDisableDelete(response.data.recordData.status);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 事業者名称取得
  const getBpName = () => {
    api
      .get(GET_BP_NAME, { params: { areaId: areaId } })
      .then((res) => {
        setBpName(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPlanData = () => {
    api
      .get(GET_SALES_PLAN, {
        params: {
          targetDate: requestDateFormat(selectedDate.pickDate as Date),
          areaId: areaId,
        },
      })
      .then((response) => {
        const salesTotal: saleData = response.data.salesData;
        setSalesData(salesTotal);
      })
      .catch((err) => {
        console.log(err);
      });

    api
      .get(GET_PROCUREMENT_PLAN, {
        params: {
          targetDate: requestDateFormat(selectedDate.pickDate as Date),
          areaId: areaId,
        },
      })
      .then((response) => {
        if (response.data.cellVolumes.length !== 0) {
          procurementFlag.current = true;
          const procurementTotal: provideData = response.data;
          setDemandData(procurementTotal);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ScrollSync>
      <div
        //ダークモード対応
        className={`cn-main-display ${
          PaletteMode === "dark" ? "dark" : "light"
        }`}
      >
        <Box
          sx={{
            paddingLeft: "870px",
            marginTop: "-80px",
            marginBottom: "50px",
            marginRight: "-40px",
          }}
        >
          <BiddingScreenMenu
            setLimit={setBidLimit}
            limit={bidLimit}
            bidStatus={bitInfoDataState}
            areaId={areaId}
            getBidStatus={getBidStatus}
            getBidInfo={getBidInfo}
          />
        </Box>
        {/* // タブ */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
            <Tab label={languageContext.words.normal_bidding}></Tab>
            {/* <Tab label={languageContext.words.block_bidding}></Tab> */}
          </Tabs>
        </Box>

        {/* // リストやカレンダー類 */}
        <Box sx={{ display: "flex", width: "100%", height: "80px" }}>
          <Box sx={{ display: "flex", width: "50%" }}>
            <Box sx={{ marginTop: "12px" }}>
              <CustomSelectList
                label={""}
                value={areaId}
                options={areaOptions(areaList, false)}
                onChange={changeArea}
              />
            </Box>
            <GlobalDatePicker isDipsTodayBtn={true} />
          </Box>
          <Box sx={{ display: "flex", marginLeft: "auto" }}>
            <Button
              sx={{
                marginTop: "16px",
                marginLeft: "8px",
                width: "100px",
                height: "40px",
              }}
              variant="outlined"
              onClick={() => {
                setIsBidLimitDialogOpen(true);
              }}
            >
              {languageContext.words.bidding_limit}
            </Button>
            <Button
              sx={{
                marginTop: "16px",
                marginLeft: "8px",
                width: "100px",
                height: "40px",
                display: hidden ? "none" : undefined,
              }}
              variant="outlined"
              onClick={() => {
                if (isWhithinRangeValue()) {
                  savePlan(0, 0).then(() => {
                    getBidStatus();
                  });
                }
              }}
              disabled={bidBtnDisable || hidden}
            >
              {languageContext.words.save_temporarily}
            </Button>
            <Button
              sx={{
                marginTop: "16px",
                marginLeft: "8px",
                width: "100px",
                height: "40px",
                display: hidden ? "none" : undefined,
              }}
              variant="outlined"
              onClick={() => {
                setIsCancelBidDialogOpen(true);
                getBpName();
              }}
              disabled={deleteBtnDisable || hidden}
            >
              {languageContext.words.bid_cancellation}
            </Button>
            <Button
              sx={{
                marginTop: "16px",
                marginLeft: "8px",
                width: "100px",
                height: "40px",
                display: hidden ? "none" : undefined,
              }}
              variant="contained"
              onClick={() => {
                if (isWhithinRangeValue()) {
                  setIsDoneBidDialogOpen(true);
                  getBpName();
                }
              }}
              disabled={bidBtnDisable || hidden}
            >
              {languageContext.words.bid_execution}
            </Button>
          </Box>
        </Box>
        <Box sx={{ width: "100%", display: "flex", height: "30px" }}>
          <Typography sx={{ color: "GrayText" }}>
            *{languageContext.words.example_spot}
          </Typography>
        </Box>

        {/* // 通常入札 */}
        <TabPanel value={tabValue} index={0}>
          <Box sx={{ width: "100%", display: "flex" }}>
            {SpotBidLeftTable(
              setInputedDataForBid,
              inputedDataForBid as bitInfo,
              setBidLimit,
              bidLimit as bitLimmitReq,
              areaId,
              bidBtnDisable,
              setDialogMessage,
              setDigOpen,
              getBidStatus,
              salesData,
              demandData,
              supplyData,
              setSupplyData,
              getPlanData,
              createToolTipText,
              api
            )}
            {SpotBidRightTable(
              areaId,
              Number(inputedDataForBid?.ucTender),
              getBidStatus,
              setDialogMessage,
              setDigOpen,
              procurementFlag,
              salesData,
              demandData,
              supplyData,
              isDisableReflect,
              getPlanData,
              inputedDataForBid,
              hidden,
              api
            )}
          </Box>
        </TabPanel>

        {/* // ブロック入札 */}
        <TabPanel value={tabValue} index={1}>
          <Box sx={{ width: "100%", display: "flex" }}>
            {BlockBidLeftTable()}
            {BlockBidRightTable()}
          </Box>
        </TabPanel>

        {/* モーダル */}
        {/* 入札制限 */}
        <BiddingScreenBidLimit
          hidden={hidden}
          setLimit={setBidLimit}
          limit={bidLimit}
          areaId={areaId}
          isBidLimitDialogOpen={isBidLimitDialogOpen}
          setIsBidLimitDialogOpen={setIsBidLimitDialogOpen}
        />
        {/* 入札実行 */}
        <CustomDialog
          title={languageContext.words.spot_bidding}
          open={isDoneBidDialogOpen}
          onAccept={async () => {
            await savePlan(1).then(() => {
              getBidStatus();
            });
          }}
          onClose={() => setIsDoneBidDialogOpen(false)}
          buttonType={ButtonType.OkCancel}
        >
          <DoneBid bidData={bidModalData} bpName={bpName} />
        </CustomDialog>
        {/* 入札取消 */}
        <CustomDialog
          title={languageContext.words.cancellation}
          open={isCancelBidDialogOpen}
          onAccept={async () => {
            await deleteBidInfo();
            await getBidStatus();
          }}
          onClose={() => setIsCancelBidDialogOpen(false)}
          buttonType={ButtonType.OkCancel}
        >
          <CancelBid areaName={convertIdToAreaName(areaId)} bpName={bpName} />
        </CustomDialog>
        <CustomDialog
          title={languageContext.words.spot_bidding}
          message={dialogMessage}
          buttonType={ButtonType.OkOnly}
          open={digOpen}
          onAccept={acceptHandler}
          onClose={() => setDigOpen(false)}
        />
      </div>
    </ScrollSync>
  );
};

/**
 * タブパネルコンポーネント用インターフェース
 */
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
/**
 * タブパネルコンポーネント
 * @param props
 * @returns
 */
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default SpotBidding;
