import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import "./css/UserList.css";

// 発電所一覧
import Box from "@mui/material/Box";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import SetPowerPlantList from "./SetPowerPlantList";
import SetPowerLimit from "./SetPowerLimit";
import { LanguageContext } from "../common/localization/localization";
import { powerPlantInfo } from "../../types/master/PowerPlantList";
import { Typography } from "@mui/material";
import {
  StyledMasterTableCell,
  StyledMasterTableContainer,
  StyledMasterTable,
  StyledMasterTableHeader,
  StyledTableHeaderBig,
  StyledTableHeaderSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomTableEx";
import {
  ModalButton,
  NewRegistrationButton,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterButton";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { usePowerSupplyTypeUtility } from "../../common/powerSupplyType";
import { useAreaUtility } from "../../common/area";
import { usePowerPlantUtility } from "../../common/powerEnergy";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";

export enum modalMode {
  editMode,
  addMode,
}

//発電所種別
type powerPlantKind = {
  name: string; //種類
  id: number; //番号
};

//発電所種別
type generateWayKind = {
  name: string; //種類
  id: number; //番号
};

/////発電所種別番号
const plantKinds: powerPlantKind[] = [
  { name: "", id: 0 }, //dummy
  { name: "調整", id: 1 },
  { name: "非調整", id: 2 },
];

/////発電方法一式
const generateWays: generateWayKind[] = [
  { name: "", id: 0 }, //dummy
  { name: "火力", id: 1 },
  { name: "水力", id: 2 },
  { name: "太陽光", id: 3 },
  { name: "小水力", id: 4 },
  { name: "風力", id: 5 },
  { name: "バイナリ", id: 6 },
  { name: "バイオマス", id: 7 },
];

const PowerPlantList = () => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const userRoleContext = useContext(RoleContext);

  // 通信エラー
  const api = useAuthedApi();

  //電源種別IDからnameに変換
  const { convertIdToSupplyTypeName } = usePowerSupplyTypeUtility();
  //エリアIDからエリア名に変換
  const { convertIdToAreaName } = useAreaUtility();
  //発電方式IDを発電方式名に変換
  const { convertIdToEnergyName } = usePowerPlantUtility();

  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);

  const [show, setShow] = useState(false);
  const [showLimit, setShowLimit] = useState(false);
  const [powerPlant, setPowerPlant] = useState<powerPlantInfo[]>();
  //編集モードか、新規登録モードかを管理する 1:新規追加 2：編集
  const [mode, setMode] = useState<modalMode>(modalMode.addMode);

  //編集ボタンを押した行のPK(powerId)情報
  const [powerIdPK, setPowerIdPK] = useState<number>(0);
  //編集ボタンを押した行のエリアID情報
  const [areaId, setAreaId] = useState<string>("");

  // 設定モーダル開く
  const HandleOpen = () => setShow(true);
  // 設定モーダル閉じる
  const HandleClose = () => setShow(false);

  // 設定モーダル開く
  const HandleOpenLimit = () => setShowLimit(true);
  // 設定モーダル閉じる
  const HandleCloseLimit = () => setShowLimit(false);

  useEffect(() => {
    setPowerPlant([
      {
        powerId: 0,
        plantName: "",
        areaId: "",
        generationKindNum: 0,
        powerBgName: "",
        contrantPower: 0,
        powerKind: "0",
        rootCode: "",
        remarks: "",
      },
    ]);
    //ロール検査
    checkEditRoll();
  }, []); //demandBgs初期化

  // 表示（現状モーダル表示を管理するshowがfalseになったタイミングで描写）
  useEffect(() => {
    getPowerPlant();
  }, [show]);

  function getPowerPlant() {
    // 発電所一覧取得
    let responseData: powerPlantInfo[] = [];
    const url = api
      .get(API_URL.POWERPLANT_LIST)
      .then((response) => {
        //console.log(response.data.result);

        responseData = response.data.result as powerPlantInfo[];
        records(responseData);
      })
      .catch((err) => {
        records([
          {
            powerId: 0,
            plantName: "",
            areaName: "",
            generationKindNum: 0,
            powerBgName: "",
            contrantPower: 0,
            powerKind: "0",
            rootCode: "",
            remarks: "",
          },
        ] as never);
      });
  }

  // 編集可能ユーザか確認
  const checkEditRoll = () => {
    const userRoll = userRoleContext.role;
    api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: userRoll } })
      .then((response) => {
        const edit = response.data;
        // 管理者権限がある時のみ編集等の書き込みを有効にする
        if (edit == true) {
          setEnableEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // テーブルの列データ
  function records(responseData: powerPlantInfo[]) {
    let datas: powerPlantInfo[] = [];
    datas = responseData;

    if (datas === undefined) {
      return;
    }

    setPowerPlant(datas);
  }

  //設定モーダルを新規追加モードで開く
  const HandleOpenForAddNew = () => {
    //新規追加モードに
    setMode(modalMode.addMode);
    //bgCodePK内の情報を一応0で初期化
    setPowerIdPK(0);

    setShow(true);
  };

  //設定モーダルを編集モードで開く
  const HandleOpenForEdit = (powerId: number) => {
    //編集モードに
    setMode(modalMode.editMode);
    //culumnInfoに行の情報を設定
    setPowerIdPK(powerId);

    setShow(true);
  };

  //設定モーダルを編集モードで開く
  const OpenLimitModal = (powerId: number) => {
    // //編集モードに
    // setMode(modalMode.editMode);
    //culumnInfoに行の情報を設定
    setPowerIdPK(powerId);

    setShowLimit(true);
  };

  return (
    <div className="cn-setting-display">
      <Box
        sx={{
          width: "100%",
          height: "20%",
          display: "flex",
          paddingBottom: "17px",
        }}
      >
        <NewRegistrationButton
          style={{ display: !enableEdit ? "none" : undefined }}
          variant="outlined"
          onClick={() => HandleOpenForAddNew()}
        >
          {languageContext.words.new_registration}
        </NewRegistrationButton>
        {SetPowerPlantList(show, HandleClose, mode, powerIdPK)}
        {SetPowerLimit(showLimit, HandleCloseLimit, powerIdPK)}
      </Box>
      <StyledMasterTableContainer>
        <StyledMasterTable>
          <StyledMasterTableHeader>
            <TableRow>
              <StyledTableHeaderBig sx={{ width: "600px !important" }}>
                {languageContext.words.power_plant_name}
              </StyledTableHeaderBig>
              <StyledTableHeaderSmall>
                {languageContext.words.connection_area}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.power_supply_type}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.power_gen_method}
              </StyledTableHeaderSmall>
              <StyledTableHeaderBig>
                {languageContext.words.affiliation_power_bg}
              </StyledTableHeaderBig>
              <StyledTableHeaderSmall>
                {languageContext.words.contracted_power}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.grid_code}
              </StyledTableHeaderSmall>
              <StyledTableHeaderBig>
                {languageContext.words.remarks}
              </StyledTableHeaderBig>
            </TableRow>
          </StyledMasterTableHeader>
          <TableBody>
            {powerPlant?.map((plant) => (
              <TableRow key={plant.plantName}>
                <StyledMasterTableCell sx={{ textAlign: "left !important" }}>
                  <Box sx={{ width: "100%", display: "flex" ,alignItems: "center"}}>
                    <Typography
                      title={plant.plantName}
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {plant.plantName}
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <ModalButton
                        style={{ display: !enableEdit ? "none" : undefined }}
                        variant="outlined"
                        onClick={() => HandleOpenForEdit(plant.powerId)}
                      >
                        {languageContext.words.edit}
                      </ModalButton>
                      <ModalButton
                        variant="outlined"
                        onClick={() => OpenLimitModal(plant.powerId)}
                      >
                        {languageContext.words.threshold_setting}
                      </ModalButton>
                    </Box>
                  </Box>
                </StyledMasterTableCell>
                <StyledMasterTableCell title={plant.areaId}>
                  {convertIdToAreaName(plant.areaId)}
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  title={plantKinds[parseInt(plant.powerKind)].name}
                >
                  {convertIdToSupplyTypeName(plant.powerKind)}
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  title={generateWays[plant.generationKindNum].name}
                >
                  {convertIdToEnergyName(plant.generationKindNum.toString())}
                </StyledMasterTableCell>
                <StyledMasterTableCell
                   sx={{ textAlign: "left !important" }} 
                  title={plant.powerBgName}
                >
                  {plant.powerBgName}
                </StyledMasterTableCell>
                <StyledMasterTableCell title={plant.contrantPower.toString()}>
                  {Number(plant.contrantPower).toLocaleString()}
                </StyledMasterTableCell>
                <StyledMasterTableCell title={plant.rootCode}>
                  {plant.rootCode}
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  title={plant.remarks}
                  sx={{ textAlign: "left !important" }}
                >
                  {plant.remarks}
                </StyledMasterTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledMasterTable>
      </StyledMasterTableContainer>
    </div>
  );
};

export default PowerPlantList;
