import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { StyledButtonMenu } from "../../styledComponents/styledButton";
import { Typography } from "@mui/material";
import { format } from "date-fns";
import { LanguageContext } from "../../localization/localization";
import { AreaContext } from "../../customComponents/CustomAreaContext";
import {
  ConfirmLeavePage,
  LeavePageContext,
} from "../../customComponents/CustomConfirmLeavePage";
import { PaletteContext } from "../../paletteMode";
import {
  POWER_FORECAST_PLAN_URL,
} from "../../../../common/url";
// import { SpotLimitContext } from "../../../jepx/BiddingScreen/BiddingScreen";

// type bidLimitContextType = {
//   bitLimmitReqData: bitLimmitReq;
//   setBitLimmitReqData: (
//     bitLimmitReqData: React.SetStateAction<bitLimmitReq>
//   ) => void;
// };

// export const BidLimitContext = createContext<bidLimitContextType>({
//   bitLimmitReqData: { area: 0, target: 0, dayLimitBit: [] },
//   setBitLimmitReqData: (bitLimmitReqData) => null,
// });

// 発電計画画面 画面名右横のコンポーネント

const BiddingScreenMenu = (props: any) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const areaContext = useContext(AreaContext);
  const leavePageContext = useContext(LeavePageContext);
  const [digOpen, setDigOpen] = useState(false);
  const url = useRef<string | null>("");
  const navigate = useNavigate();

  const acceptHandler = () => {
    navigate(url.current as string);
  };

  // ダークモード対応
  const {PaletteMode} = useContext(PaletteContext);
  // propsでスポット主画面からステータスや入札時刻、入札制限値等を受け取る
  const { setLimit, limit, bidStatus, areaId, getBidStatus, getBidInfo } =
    props;

  function transitToOtherPage(url: string) {
    navigate(url);
  }
  // // モーダルOpenフラグ
  // const [isBidLimitDialogOpen, setIsBidLimitDialogOpen] = useState(false);

  // //日付のデータコンテキスト
  // const selectedDate = useContext(DateContext);

  // // const bidLimitContext = useContext(SpotLimitContext);

  // //表示するかしないかを決めるstate
  // const [isExistData, setIsExistData] = useState<boolean>(true);

  // const [bidLimit, setBidLimit] = useState<bitLimmitReq>(limit);

  // //日付を変えるとデータが変わるように
  // useEffect(() => {
  //   for (let i = 0; i < spotBitInfos.length; i++) {
  //     if (isSameDay(spotBitInfos[i].bitDate, selectedDate.pickDate as Date)) {
  //       setBitInfoData(spotBitInfos[i]);
  //       setIsExistData(true);
  //       return;
  //     }
  //   }
  //   setIsExistData(false);
  // }, [selectedDate]);

  // 設定した制限値をDBに登録
  // const postBidLimit = async () => {
  //   setLimit(bidLimit);
  //   await axios
  //     .post("_api/postBiddingScreenBidLimitAddress", {
  //       bidLimit: bidLimit,
  //       areaId: areaId,
  //     })
  //     .then((response) => {
  //       // console.log(response);
  //     });
  // };

  // const getBidLimit = async () => {
  //   await axios
  //     .get("_api/getBiddingScreenBidLimitAddress", {
  //       params: {
  //         areaId: areaId,
  //         targetDate: format(selectedDate.pickDate as Date, "yyyy-MM-dd"),
  //       },
  //     })
  //     .then((response) => {
  //       const bidLimit: bitLimmitReq = response.data;
  //       setBidLimit(bidLimit);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // ステータスを数値から文字に変換
  const convertNumToStatus = (statusNum: number) => {
    switch (statusNum) {
      case 0:
        return languageContext.words.waiting_for_bid;
      case 3:
        return languageContext.words.unplanned;
      case 1:
        return languageContext.words.waiting_for_contract;
      case 2:
        return languageContext.words.contracted;
      case 6:
        return languageContext.words.unsuccessful_bid;
      case 7:
        return languageContext.words.delete_failed;
      case 9:
        return languageContext.words.bid_canceled;
      default:
        return "";
    }
  };

  const getBidData = async () => {
    await getBidInfo();
    await getBidStatus();
  };

  useEffect(() => {
    if (areaId !== "" && areaId !== "00") {
      // useEffectでDBから入札制限データを取ってくる
      // getBidLimit();
      getBidData();
    }
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Typography
        sx={{
          width: "100px",
          border: "solid 1px lightgray",
          borderRight: "none",
          textAlign: "center",
          fontSize: "14px",
          paddingTop: "3px",
        }}
      >
        {languageContext.words.status}
      </Typography>
      <Typography
        sx={{
          width: "160px",
          border: "solid 1px lightgray",
          borderRight: "none",
          textAlign: "center",
          fontSize: "14px",
          backgroundColor: PaletteMode === "dark" ? "#2e2e2e" : "#ffffff",
          paddingTop: "3px",
        }}
      >
        {bidStatus.status === undefined
          ? ""
          : convertNumToStatus(bidStatus.status)}
      </Typography>
      <Typography
        sx={{
          width: "100px",
          border: "solid 1px lightgray",
          borderRight: "none",
          textAlign: "center",
          fontSize: "14px",
          paddingTop: "3px",
        }}
      >
        {languageContext.words.bid_number}
      </Typography>
      <Typography
        sx={{
          width: "160px",
          border: "solid 1px lightgray",
          borderRight: "none",
          textAlign: "center",
          fontSize: "14px",
          backgroundColor: PaletteMode === "dark" ? "#2e2e2e" : "#ffffff",
          paddingTop: "3px",
        }}
      >
        {bidStatus.ucTender === 0
          ? ""
          : bidStatus.ucTender === undefined
          ? ""
          : bidStatus.status !== 0 &&
            bidStatus.status !== 3 &&
            bidStatus.status !== 6
          ? bidStatus.ucTender //入札番号入札前から表示されている対応
          : ""}
      </Typography>
      <Typography
        sx={{
          width: "100px",
          border: "solid 1px lightgray",
          borderRight: "none",
          textAlign: "center",
          fontSize: "14px",
          paddingTop: "3px",
        }}
      >
        {languageContext.words.bidding_time_jikoku}
      </Typography>
      <Typography
        sx={{
          width: "150px",
          border: "solid 1px lightgray",
          textAlign: "center",
          fontSize: "14px",
          backgroundColor: PaletteMode === "dark" ? "#2e2e2e" : "#ffffff",
          paddingTop: "3px",
          marginRight: "15px",
        }}
      >
        {bidStatus.bitDate === undefined ||
        bidStatus.status === 0 ||
        bidStatus.status === 3 ||
        bidStatus.status === 6
          ? ""
          : languageContext.convertDateToLocalizedStringGoodAcu(
              new Date(bidStatus.bitDate)
            )}
      </Typography>
      <StyledButtonMenu
        onClick={() => {
          url.current = POWER_FORECAST_PLAN_URL;
          if (areaContext.areaId !== "") {
            url.current = `${POWER_FORECAST_PLAN_URL}?areaId=${areaContext.areaId}`;
          }

          if (leavePageContext.blockLeave) {
            setDigOpen(true);
          } else {
            navigate(url.current);
          }
        }}
      >
        {languageContext.words.powerforecastplan}
      </StyledButtonMenu>
      <ConfirmLeavePage
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      ></ConfirmLeavePage>
    </Box>
  );
};

export default BiddingScreenMenu;
