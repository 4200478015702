import { useContext, useMemo } from "react";
import { PaletteContext } from "../views/common/paletteMode";

export const useColorUtility = () => {
  const { PaletteMode } = useContext(PaletteContext);
  
  //テーブルボーダーカラー
  const borderColor = useMemo(
    () => (PaletteMode === "dark" ? "1px solid #808080" : "1px solid #d3d3d3"),
    [PaletteMode]
  );
  return {borderColor};
};
