import { useCallback, useContext } from "react";
import { LanguageContext } from "../views/common/localization/localization";
import { MANAGEMENT_TYPE } from "./constant";

export const useManagementTypeUtility = () => {
  const { words } = useContext(LanguageContext);

  const managementTypeOptions = useCallback(
    (types: string[]) => {
      const areaNames = [
        {
          id: MANAGEMENT_TYPE.OWN_COMPANY,
          name: words.setting_management_type_1,
        },
        {
          id: MANAGEMENT_TYPE.OTHER_POWER_PLANT,
          name: words.setting_management_type_2,
        },
        {
          id: MANAGEMENT_TYPE.OTHER_RETAIL,
          name: words.setting_management_type_3,
        },
        { id: MANAGEMENT_TYPE.JBU, name: words.setting_management_type_4 },
      ];

      return areaNames.filter((v) => types.find((e) => e === v.id));
    },
    [words]
  );

  const convertIdToManagementName = useCallback(
    (id: string) => {
      const typeNames = [
        words.setting_management_type_1,
        words.setting_management_type_2,
        words.setting_management_type_3,
        words.setting_management_type_4,
      ];
      const index = parseInt(id);
      return typeNames[index] ? typeNames[index] : "undefined";
    },
    [words]
  );

  return { managementTypeOptions, convertIdToManagementName };
};
