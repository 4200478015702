// システム定数
export interface reqStatusList {
  targetDate: Date;
  area: string;
}

export interface systemTimeInfo {
  spotTimeLimit: Date;
  balancingTimeLimit: Date;
}

export const systemTime: systemTimeInfo = {
  spotTimeLimit: new Date(new Date().toDateString() + " 10:00:00"),
  balancingTimeLimit: new Date(new Date().toDateString() + " 12:00:00"),
};

export interface statusListInfo {
  areaName: string;
  areaId: string;
  powerFixedTime: string;
  demandFixedTime: string;
  spotFixedTime: string;
  hourFixedTime: string;
  spotPlanCount: number;
  spotPlanBitCount: number;
  spotPlanStatus: number; // 0:未入札,1:約定待ち,2:約定済
  blockPlanCount: number;
  blockPlanBitCount: number;
  blockPlanStatus: boolean;
  hourPlanCountBeforeGC: number;
  hourPlanBitCountBeforeGC: number;
  hourPlanCountAfterGC: number;
  hourPlanBitCountAfterGC: number;
  hourPlanStatus: number; // 0:未入札,1:約定待ち,2:約定済
  BalancingPowerFixedTime: string;
  BalancingPowerStatus: number; // 0:未計画,1:計画済,2:提出済
  BalancingDemandFixedTime: string;
  BalancingDemandPlanStatus: number; // 0:未計画,1:計画済,2:提出済
  BalancingPartsFixedTime: string;
  BalancingPartsPlanStatus: number; // 0:未計画,1:計画済,2:提出済
  BalancingPowerUnsendTime: string;
  BalancingDemandUnsendTime: string;
  BalancingPartsUnsendTime: string;
}

export type areaStatusType = {
  [key: string]: string;
};
