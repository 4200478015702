//このファイルにひたすら翻訳用連想配列を書いていく

//typeも作っておく
export type language = {
  [key: string]: string;
};

//日本語版の文字がたくさん入っている連想配列
export const JpStrings: language = {
  "could_not_communicate_occto":"OCCTOと通信できませんでした。",
  "bp_submit_success": "計画を提出しました。",
  "bp_submit_failed" : "提出できなかった計画があります。設定と計画状況を確認してください。",
  "bp_submit_timeout": "タイムアウトにより、計画を提出できませんでした。",
  "bp_submit_error" : "計画を提出できませんでした。",
  "bp_confirm_error" : "計画を「確定済」にできませんでした。",
  "bp_resume_timeout" : "タイムアウトにより、送信応答を取得できませんでした。",
  "bp_resume_error" : "送信応答を取得できませんでした。",
  "bp_notification_timeout" : "タイムアウトにより、通告結果を取得できませんでした。",
  "bp_notification_error" : "通告結果を取得できませんでした。",
  "bp_resume_check" : "送信応答の取得を再開しますか？",
  "bp_notification_title" : "不整合通知反映",
  "bp_notification_check" : "不整合通知を計画に反映しますか？",
  "bp_unplaned_msg" : "計画が完了していません。",
  "bp_unplaned_hint" : "各計画の状態を保存すると、この画面に帳票が表示されます。",
  "bp_status_planning" : "計画作成",
  "bp_status_submitting" : "提出中",
  "bp_status_submitted" : "提出完了",
  "bp_status_recieved" : "受信済み",
  "bp_report_pgsp_next" : "当日・翌日発電販売計画",
  "bp_report_dpp_next" : "当日・翌日需要調達計画",
  "bp_report_pgsp_notice" : "発電販売計画不整合通知",
  "bp_report_dpp_notice" : "需要調達計画不整合通知",
  "bp_report_partial" : "当日・翌日部分供給計画",
  "bp_notice_reflect" : "反映",
  "dp_not_bg_setting" : "需要BGが0件です。設定より需要BGが設定されているかご確認ください。",
  "dp_error" : "サーバに接続できませんでした。時間を置いて再度お試しください。",
  "dp_check_power": "契約電力を超えた入力値が存在するため保存できません。",
  "dp_copy_error" : "発電計画が未計画のため複写できません。",
  "not_power_bg_setting" : "発電BGが設定されていません。発電BG設定から設定をしてください。",
  "not_demand_bg_setting" : "需要BGが設定されていません。需要BG設定から設定をしてください。",
  "not_demand_setting" : "需要家が設定されていません。需要家設定から設定をしてください。",
  "leave_page_defalt_msg" : "保存されていないデータがあります。ページを移動してもよろしいですか？",
  "date_chg_msg":"保存されていないデータがあります。日付を変更してもよろしいですか？",
  "last_update_info" :"保存日時 (最終保存者)",
  "energy_thermal" : "火力",
  "energy_hydro" : "水力",
  "energy_solar" : "太陽光",
  "energy_small_hydro" : "小水力",
  "energy_wind" : "風力",
  "energy_binary" : "バイナリ",
  "energy_biomass" : "バイオマス",
  "status_not_area_setting" : "エリアの設定が未設定です。",
  "note_pass_length_limit":"パスワードの長さは、12文字以上、16文字以内とし、数字および英字の大文字・小文字を組み合わせてください。",
  "example_one_hour":"凡例【公開中：〇】【受付中：◎】【取引終了または中止：✕】",
  "note_no_input_":"最大出力・プロタラは、未入力の場合は空白で登録されます。",
  "jepx_additional_user_spot_only_when_option_is_purchased":"【JEPX追加ユーザー】スポット専用（オプション購入時）",
  "note_display_when_saved":"各計画の状態を保存すると、この画面に帳票が表示されます。",
  "jepx_representative_user_for_spot_and_1_hour_trading":"【JEPX代表ユーザー】スポット・1時間前市場取引用",
  "example_spot":"入札量凡例【販売(売り)：マイナス値】【調達(買い)：プラス値】",
  " reflected_system_planned_values":"選択帳票の計画値をシステム計画値へ反映します。",
  "note_bid_cancel":"以上の情報で入札を取消しますか？",
  "note_bid_execute":"以上の情報で入札を実行しますか？",
  "updates_selected_form_confirmed":"選択した帳票を「確定済」に更新しますか？",
  "filter_by_user_id_name":"ユーザーID・ユーザー名称で絞り込み",
  "note_about_unit":"入力できる値の単位は全てkWhです。",
  "note_submit_notification":"選択した帳票を提出しますか？",
  "area_status_selected_num":"【選択中申込番号のエリア契約状況】",
  "congestion_notification":"(スポット・1時間前)混雑処理通知",
  "final_confirmation_time_demand_and_procurement_plan":"需要調達計画 最終送信・確定日時",
  "final_confirmation_time_power_gen_and_sales_plan":"発電販売計画 最終送信・確定日時",
  "final_confirmation_time_partial_supply_plan":"部分供給計画 最終送信・確定日時",
  "contract_id_2_for_power_gen_and_sales_plan":"発電・販売計画用 契約識別番号２",
  "cross_cut_partial_supply_load_following_supply":"横切り型部分供給(負荷追随供給)",
  "abcdefg_mega_solar_power_plant":"ABCDEFGメガソーラー発電所",
  "note_download_selected_forms":"選択した帳票をダウンロードしますか？",
  "power_plant_power_plant_power_plant_power_plant_power_plant":"発電所発電所発電所発電所発電所",
  "one_hour_external_other_bg_plus_exchanges":"1時間前（域外他BG＋取引所）",
  "spot_external_other_bg_plus_exchanges":"スポット（域外他BG＋取引所）",
  "priority_and_prorata_48_frame_setting":"優先順位・プロラタ48コマ設定",
  "plan_submitter_codeprovider_code":"計画提出者コード／事業者コード",
  "spot_contract_price":"スポット約定価格（円/kWh）",
  "plan_submission_deadline_is_approaching":"計画提出期限が近づいています。",
  "incorrect_login_information":"ログイン情報が間違っています。",
  "timeout_login_information":"通信に失敗しました。",
  "connection_and_transfer_supply_agreement_listsetting":"接続・振替供給契約一覧・設定",
  "gen_contractors_and_procurement_sources_listsetting":"発電契約者・調達先一覧・設定",
  "notified_partial_supply_base_supply":"通告型部分供給(ベース供給)",
  "abcdefg_tokyo_factory_inc":"（株）ABCDEFG東京工場",
  "get_latest_notification_results":"最新の通告結果を取得しますか？",
  "new_gen_contractors_and_procurement_sources_registration":"発電契約者・調達先新規登録",
  "block_bids_executed_planned_num":"ブロック入札実行数/計画数",
  "termination_of_transaction":"【取引終了または中止：×】",
  "average_contract_price":"平均約定価格（円/kWh）",
  "latest_contract_price":"最新約定価格（円/kWh）",
  "new_password_confirmation":"新しいパスワード(確認用)",
  "contract_id_to_following_load":"負荷追随分契約者識別番号",
  "base_contract_id":"ベース分契約者識別番号",
  "master_maintenance_operation_setting":"マスターメンテ・運用設定",
  "character_for_checking_planned_values":"計画値チェック用発電特性",
  "contracts_bids_num_before_gc":"約定数/入札数（GC前）",
  "sell_contracting_volume_mwhh":"売り約定量（MWh/h）",
  "buy_contracting_volume_mwhh":"買い約定量（MWh/h）",
  "power_gen_and_sales_plan_A":"当日・翌日発電・販売計画",
  "demand_procurement_plan":"当日・翌日需要・調達計画",
  "contracts_bids_num_after_gc":"約定数/入札数（GC済）",
  "read_the_latest_result":"最新の約定結果を読み込む",
  "//保留のため対象外とする。:string;":"保留のため対象外とする。",
  "constant_backup_only":"（常時バックアップのみ）",
  "sell_bidding_volume":"売り入札量（MWh/h）",
  "buy_bidding_volume":"買い入札量（MWh/h）",
  "plan_not_completed":"計画が完了していません。",
  "sell_lowest_price":"売り最安値（円/kWh）",
  "buy_lowest_price":"買い最高値（円/kWh）",
  "certificate_general_transmission_distribution":"【 一般送配電用証明書】",
  "connection_and_transfer_supply_agreement_editting":"接続・振替供給契約編集",
  "gen_contractors_and_procurement_sources_editting":"発電契約者・調達先編集",
  "the_next_day_partial_supply_notification_value":"(翌日)部分供給通告値",
  "the_day_partial_supply_notification_value":"(当日)部分供給通告値",
  "other_demand_bg_retail_power_receiving":"他需要BG（小売受電）",
  "xxxx_partial_supply_consumer":"XXXX部分供給需要家",
  "market_status_entire_area":"市場状況（エリア全体）",
  "entire_area":"エリア全体",
  "partial_supply_note_val":"(月間)部分供給通告値",
  "procurement_buy_value":"【調達（買い）：＋値】",
  "procurement_plan_mwhh":"調達計画（MWh/h）",
  "contract_price_yenkwh":"約定価格（円/kWh）",
  "num_of_normal_bid":"通常入札実行数/計画数",
  "keep_me_logged_in":"ログイン情報を記憶する",
  "sales_sell_value":"【販売（売り）：－値】",
  "bid_price_yenkwh":"入札価格（円/kWh）",
  "sales_plan_mwhh":"販売計画（MWh/h）",
  "plant_name_for_note_form":"通告帳票用 発電所名称",
  "jepx_representative_user":"【JEPX代表ユーザー】",
  "jepx_additional_user":"【JEPX追加ユーザー】",
  "procurement_src_list_setting":"調達先内訳一覧・設定",
  "the_next_day_demand_and_procurement_plan":"(翌日)需要調達計画",
  "sales_destinations_list_setting":"販売先内訳一覧・設定",
  "the_next_day_power_gen_and_sales_plan":"(翌日)発電販売計画",
  "monthly_demand_and_procurement_plan":"(月間)需要調達計画",
  "the_day_demand_and_procurement_plan":"(当日)需要調達計画",
  "supplier_code_following_load":"負荷追随分供給コード",
  "base_supplier_code":"ベース分供給コード",
  "weekly_demand_and_procurement_plan":"(週間)需要調達計画",
  "annual_demand_and_procurement_plan":"(年間)需要調達計画",
  "monthly_power_gen_and_sales_plan":"(月間)発電販売計画",
  "the_day_power_gen_and_sales_plan":"(当日)発電販売計画",
  "weekly_power_gen_and_sales_plan":"(週間)発電販売計画",
  "annual_power_gen_and_sales_plan":"(年間)発電販売計画",
  "demander_name_etc":"需要家名称事業場名等",
  "supplier_to_following_the_load":"負荷追随分供給者名称",
  "base_supplier":"ベース分供給者名称",
  "for_power_gen_and_sales_planB":"（発電・販売計画用）",
  "transmission_loss_ratio_info":"エリア送電損失率情報",
  "power_gen_imbalance_monitor":"発電インバランス監視",
  "nine_nine_nine_nine_yenkwh":"99.99円/kWh",
  "filter_by_power_plant_name":"発電所名称で絞り込み",
  "demand_imbalance_monitor":"需要インバランス監視",
  "reflects_congestion_note":"混雑処理通知計画反映",
  "contract_quantity_mwhh":"約定量（MWh/h）",
  "planned_daily_demand_kw":"需要計画日量（kW）",
  "bidding_volume_mwhh":"入札量（MWh/h）",
  "service_result_note":"サービス処理結果通知",
  "blank_requirements":"（終了日未定は空白）",
  "fit_administrator":"FITシステム管理者",
  "confirm_password":"パスワード（確認用）",
  "new_alarm_registration":"+ 通知新規登録",
  "certificate_wide_area_agencies":"【広域機関用証明書】",
  "spot_bidding_deadline_time":"スポット入札締切日時",
  "balancingplan_submitted_on_the_day":"同時同量計画提出日時",
  "demandMonitoring_title":"需要インバランス監視",
  "powergenerationmonitoring_title":"発電インバランス監視",
  "power_gen_imbalance_monitor_menu":"発電インバランス監視",
  "note_saved":"計画を保存しました。",
  "note_not_saved": "計画を保存できませんでした。",
  "save_check": "50kWh単位で入力されていない計画値があります。\n入札画面で補正される場合がありますが保存しますか？",
  "notified_partial_supply_setting":"通告型部分供給 設定",
  "one_hour_and_30_minutes_before_actual_supply_and_demand":"実需給01:30前",
  "new_procurement_sources_breakdown_registration":"調達先内訳新規登録",
  "new_sales_destinations_breakdown_registration":"販売先内訳新規登録",
  "sales_destinations_name_breakdown":"販売先名称（内訳）",
  "note_type_partial_supply_setting":"通告型部分供給設定",
  "specific_supply_and_demand_001":"特定供給需要001",
  "area_by_area_plan_submitter":"エリア毎計画提出者",
  "power_gen_bg_listsetting":"発電BG一覧・設定",
  "filter_by_demander_name":"需要家名で絞り込み",
  "gen_contractor_operator":"発電契約者／事業者",
  "plan_submitterprovider":"計画提出者／事業者",
  "demand_bg_listsetting":"需要BG一覧・設定",
  "contracted_power_mw":"契約電力 (MW)",
  "client_certificate":"クライアント証明書",
  "retailer_id_number":"小売事業者識別番号",
  "contracted_energy":"契約電力量（kW）",
  "alarm_listsetting":"通知一覧",
  "jepx_participant":"【JEPX参加者】",
  "precipitation_mmh":"降水量（mm/h）",
  "price_yenkwh":"価格（円/kWh）",
  "sell_mwhh":"売り（MWh/h）",
  "buy_mwhh":"買い（MWh/h）",
  "alarm_setting":"通知一覧・設定",
  "jepx_user_registration":"JEPXユーザー登録",
  "new_user_registration":"+ ユーザー新規登録",
  "supply_contract_setting_setting":"供給契約設定 設定",
  "area_transmission_loss_info":"エリア損失率一覧",
  "sales_destinations_group_list":"販売先グループ一覧",
  "sales_destinations_group_breakdown":"販売先グループ内訳",
  "reflection_time_of_contract_plan":"約定計画反映日時",
  "new_supply_contract_registration":"供給契約新規登録",
  "resume_send_response_acquisition":"送信応答取得再開",
  "sales_destinations_listsetting":"販売先一覧・設定",
  "monitoring_threshold_weekdays":"監視閾値",
  "power_gen_and_sales_plan_type":"発電販売計画種別",
  "new_power_gen_bg_registration":"発電BG新規登録",
  "monitoring_threshold_holiday":"監視閾値（休日）",
  "plan_value_cooperation_code":"計画値連携コード",
  "one_hour_ago_bidding_limit":"1時間前入札制限",
  "new_demand_bg_registration":"需要BG新規登録",
  "multi_area_administrator":"複数エリア管理者",
  "system_constant_setting":"システム定数設定",
  "power_plant_list_setting":"発電所一覧・設定",
  "internal_other_bg_001B":"域内他BG001",
  "supply_point_id_number":"供給地点特定番号",
  "event_note_on_or_off":"イベント通知有無",
  "created_last_updated":"作成最終更新日時",
  "demander_list_setting":"需要家一覧・設定",
  "plan_submitter_code":"計画提出者コード",
  "contracted_power_kw":"契約電力（kW）",
  "block_bidding_limit":"ブロック入札上限",
  "spot_bidding_limit":"スポット入札制限",
  "maximum_output_kw":"最大出力（kW）",
  "pannel_type":"パネル設置形態",
  "temperature_coe":"最大出力温度計数",
  "ac_output":"定格出力(AC)",
  "panel_tilt":"傾斜角",
  "panel_azimuth":"方位角",
  "panel_coe":"補正係数",
  "panel_power_decline":"経年発電量低下係数",
  "rated_output":"定格出力（kW）",
  "sun_pannel":"太陽光パネル",
  "weekly_plan_check":"週間計画チェック",
  "power_gen_energy":"発電量（kWh）",
  "user_list_setting":"ユーザー一覧・設定",
  "demand_kwh":"需要量（kWh）",
  "power_gen_contractor_code":"発電契約者コード",
  "new_connection_info_registration":"接続情報新規登録",
  "voltage_class_assigned_num":"電圧階級",
  "sales_dest_breakdown_setting":"販売先内訳 設定",
  "demand_monitoring_menu":"需要インバランス監視",
  "new_password":"新しいパスワード",
  "interconnection_supply_agreement_list":"接続供給契約一覧",
  "download_form":"帳票ダウンロード",
  "procurement_src_editting":"調達先内訳編集",
  "sales_destinationseditting":"販売先内訳編集",
  "new_sales_destinations_registration":"販売先新規登録",
  "upper_upper_and_lower_lower_limits":"上上限・下下限",
  "sales_destination_name":"販売先内訳名称",
  "procurement_src_name":"調達先内訳名称",
  "new_registration":"新規登録",
  "new_power_plant_registration":"発電所新規登録",
  "power_trading_combination":"計画連携組合せ",
  "power_src_specific_code":"電源特定コード",
  "extra_high_voltage_changed":"特高（変更後）",
  "automatic_setting_enabled":"自動化設定有効",
  "new_demander_registration":"需要家新規登録",
  "internal_other_bg_001_A":"域内他BG01",
  "auto_bid_deletion_time":"自動札削除有無",
  "contract_operator_type":"契約事業者種別",
  "bid_automatic_setting":"入札自動化設定",
  "bid_automation_failed":"自動化を開始できませんでした。",
  "reconsider_automatic_setting":"自動化設定、入札締切・削除設定の見直しを行ってください。",
  "bid_automatic_setting_saved":"入札自動化設定を保存しました。",
  "bid_automatic_setting_saved_failed":"入札自動化設定を保存できませんでした。",
  "automatic_delete_deadline_setting": "入札締切・削除設定",
  "automatic_delete_deadline_setting_saved": "入札締切・削除設定を保存しました。",
  "automatic_delete_deadline_setting_saved_failed": "入札締切・削除設定を保存できませんでした。",
  "auto_bid_deletion_off":"自動札削除なし",
  "todays_power_gen_plan":"本日の発電計画",
  "expiration_start_date":"有効期間開始日",
  "auto_bid_deletion_on":"自動札削除あり",
  "codes_in_parentheses":"（）内はコード",
  "contract_id_number_1":"契約識別番号１",
  "contract_id_number_2":"契約識別番号２",
  "high_voltage_changed":"高圧（変更後）",
  "system_administrator":"システム管理者",
  "gen_contractors_name":"発電契約者名称",
  "low_voltage_changed":"低圧（変更後）",
  "expiration_end_date":"有効期間終了日",
  "todays_demand_plan":"本日の需要計画",
  "operation_message":"操作メッセージ",
  "power_gen_bg_code":"発電BGコード",
  "all_power_gen_bg":"BG未選択",//"全ての発電BG",
  "plan_change_code":"計画変更コード",
  "power_gen_bg_001":"発電BG001",
  "exchanges_tokyo":"取引所（東京）",
  "demand_bg_code":"需要BGコード",
  "all_demand_bg":"BG未選択",//"全ての需要BG",
  "demand_bg_001":"需要BG001",
  "daily_amount_kwh":"日量（kWh）",
  "status_list":"ステータス一覧",
  "wind_speed":"風速（m/s）",
  "published":"【公開中：○】",
  "accepting":"【受付中：◎】",
  "mail_address":"メールアドレス",
  "generation_bg_setting":"発電BG 設定",
  "power_bg_setting":"発電BG 設定",
  "effective_date_start_date":"有効期限開始日",
  "expiration_date":"有効期限終了日",
  "demand_bg_setting":"需要BG 設定",
  "new_contractor_registration":"契約者新規登録",
  "generation_contractors_setting":"発電契約者設定",
  "contract_operator_Id":"契約事業者識別",
  "bidded_area":"入札済みエリア",
  "auto_bidding_off":"自動入札解除中",
  "demandforecastplan":"需要・調達計画",
  "powerforecastplan":"発電・販売計画",
  "account_setting":"アカウント設定",
  "get_error" : "データを取得できませんでした。",
  "saved":"保存しました。",
  "saved_dupicated":"同一計画値での保存はできません。",
  "saved_failed":"保存できませんでした。",
  "simultaneous_commensurate_plan":"同時同量計画",
  "in_house_managed_power_supply":"自社管理電源",
  "demand_and_procurement_plan":"需要調達計画",
  "power_gen_threshold_setting":"発電閾値設定",
  "other_company_power_supply":"他社管理電源",
  "balance_supply_and_demand":"需給バランス",
  "internal_self_consignment":"域内自己託送",
  "external_self_consignment":"域外自己託送",
  "application_number_title":"【申込番号】",
  "power_gen_and_sales_plan":"発電販売計画",
  "note_result_acquisition":"通告結果取得",
  "self_consignment_tokyo":"自己託送東京",
  "self_consignment_total":"自己託送合計",
  "total_procurement_plan":"調達計画合計",
  "details_of_bid_status":"入札状況詳細",
  "bidding_deadline_time":"入札締切時間",
  "contracted_power_unit":"契約電力単位",
  "facility_usage_period":"設備利用期間",
  "power_gen_bg_editting":"発電BG編集",
  "list_of_demand_plans":"需要計画一覧",
  "power_gen_plans_list":"発電計画一覧",
  "one_hour_ago_bidding":"1時間前入札",
  "affiliation_power_bg":"所属発電BG",
  "partial_supply_form":"部分供給形態",
  "system_forecasting":"システム予測",
  "demand_bg_editting":"需要BG編集",
  "balance_monitoring":"バランス監視",
  "notification_list":"お知らせ一覧",
  "power_gen_bg_name":"発電BG名称",
  "login_information":"ログイン情報",
  "all_power_plants":"発電所未選択",//"全ての発電所",
  "total_sales_plan":"販売計画合計",
  "pattern_setting":"パターン設定",
  "alarm_editting":"通知編集",
  "repeat_setting":"繰り返し設定",
  "demand_bg_name":"需要BG名称",
  "block_bidding":"ブロック入札",
  "supplier_area":"供給元エリア",
  "spot_bidding":"スポット入札",
  "alarm_status":"通知状態",
  "deviation_mw":"乖離（MW）",
  "user_editing":"ユーザー編集",
  "sender_code":"送信者コード",
  "bp_name":"事業者名称",
  "agency_code":"代理店コード",
  "client_code":"取引先コード",
  "all_demand":"需要家未選択",//"全ての需要家",
  "alarm_name":"通知名称",
  "login_name":"ログイン名称",
  "all_areas":"エリア未選択",//"全てのエリア",
  "all_rolls":"全てのロール",
  "user_name_nobashi":"ユーザー名称",
  "download":"ダウンロード",
  "tenant_id": "テナントID",
  "user_id":"ユーザーID",
  "upload":"アップロード",
  "transmission_loss_info":"送電ロス情報",
  "interconnection_Supply_Agreement":"接続供給契約",
  "event_notification":"イベント通知",
  "contract_id_number":"契約識別番号",
  "power_plant_setting":"発電所 設定",
  "partial_supply_presence_absence":"部分供給有無",
  "business_name_etc":"事業者名称等",
  "sales_destinations_setting":"販売先 設定",
  "unbid":"未入札",
  "unbid_area":"未入札エリア",
  "submitted_area":"提出済エリア",
  "not_submitted_area":"未提出エリア",
  "date_and_time_reflection_contract":"約定反映日時",
  "loss_ratio_switching":"損失率切替:",
  "automatic_price_adjustment":"自動価格調整",
  "automation_setting_enabled":"自動化設定有効",
  "demandforecast_list":"需要計画一覧",
  "powerforecast_list":"発電計画一覧",
  "biddingScreen_title":"スポット入札",
  "onehouradvancebidplan_title":"1時間前入札",
  "balancingplan":"同時同量計画",
  "notification_title":"お知らせ一覧",
  "planned_daily_demand":"需要計画日量",
  "power_gen_bg_list":"発電BG一覧",
  "demand_gen_bg_list":"需要BG一覧",
  "select_area":"エリアを選択",
  "intraregional_sales_destinations":"域内販売先",
  "affiliated_sales_destinations":"所属販売先",
  "non_adjustable_power_supply":"非調整電源",
  "sales_destinations_editting":"販売先編集",
  "date_of_change_reflection":"変更反映日",
  "certificate_registration":"証明書登録",
  "sales_destinations_type":"販売先種別",
  "sales_destinations_name":"販売先名称",
  "upper_and_lower_limits":"上限・下限",
  "delivery_date_of_power":"電力受渡日",
  "planned_demand_value":"需要計画値",
  "power_gen_contractor":"発電契約者",
  "supplier_information":"供給元情報",
  "power_receiving_area":"受電エリア",
  "procurement_src_type":"調達先種別",
  "power_plant_editting":"発電所編集",
  "ambient_temperature":"気温（℃）",
  "affiliated_proc_src":"所属調達先",
  "display_start_date":"表示開始日",
  "partial_contracted":"一部約定済",
  "planned_gen_value":"発電計画値",
  "sellable_quantity":"販売可能量",
  "internal_other_bg":"域内他BG",
  "automatic_setting":"自動化設定",
  "deviation_percent":"乖離（％）",
  "supply_start_date":"供給開始日",
  "demander_editting":"需要家編集",
  "participant_name":"参加者名称",
  "power_plant_name":"発電所名称",
  "power_plant_001":"発電所01",
  "supply_end_date":"供給終了日",
  "contractor_type":"契約者種別",
  "connection_area":"接続エリア",
  "manual_bidding":"手動入札中",
  "plan_reflected":"計画反映済",
  "participant_id":"参加者ID",
  "power_producer":"発電事業者",
  "biomass_power":"バイオマス",
  "contract_area":"契約エリア",
  "demander_name":"需要家名称",
  "auto_bidding":"自動入札中",
  "demander_01":"需要家01",
  "start_time_":"開始時刻：",
  "grid_code":"系統コード",
  "all_types":"全ての種別",
  "pattern_1":"パターン１",
  "guidance":"ガイダンス",
  "password":"パスワード",
  "yen_kwh":"円/kWh",
  "message":"メッセージ",
  "status":"ステータス",
  "ok" : "OK",
  "cancel":"キャンセル",
  "all_bg":"全てのBG",
  "next_notification_date":"次の通知日",
  "user_name":"ユーザー名称",
  "areas_of_responsibility":"担当エリア",
  "bg_code":"BGコード",
  "contract_Type":"契約タイプ",
  "powerforecast_energy":"発電電力量",
  "demandforecast_energy":"需要電力量",
  "loss_ratio_on":"損失率あり",
  "loss_ratio_off":"損失率なし",
  "log_out":"ログアウト",
  "user_list":"ユーザー一覧",
  "power_plant_list":"発電所一覧",
  "demander_list":"需要家一覧",
  "date_and_time_of_occurrence":"発生日時",
  "power_gen_reserve_capacity":"発電余力",
  "other_company_procurement":"他社調達",
  "execute_bid_cancellation":"取消実行",
  "shortage_of_procurement":"調達不足",
  "adjustment_amount_comma":"調整価格：",
  "adjustable_power_supply":"調整電源",
  "decision_date_and_time":"決定日時",
  "contract_status_jokyo":"約定状況",
  "bidding_date_and_time":"入札日",
  "waiting_for_contract":"約定待ち",
  "application_number_B":"申込番号",
  "bidding_time_jikoku":"入札時刻",
  "contract_unit_price":"約定単価",
  "weather_information":"気象情報",
  "in_house_management":"自社管理",
  "market_transactions":"市場取引",
  "bidding_time_jikan":"入札時間",
  "power_gen_forecast":"発電予測",
  "company_a_factory":"A社工場",
  "additional_period":"期間追加",
  "threshold_setting":"閾値設定",
  "power_supply_type":"電源種別",
  "power_gen_results":"発電実績",
  "reflection_status":"反映状態",
  "power_gen_method":"発電方式",
  "procurement_plan":"調達計画",
  "bid_cancellation":"入札取消",
  "bid_canceled":"入札取消済み",
  "adjustable_cycle":"調整周期",
  "contracted_power":"契約電力",
  "waiting_for_send":"送信待ち",
  "form_preparation":"帳票作成",
  "save_temporarily":"一時保存",
  "in_house_status":"自社状況",
  "waiting_for_bid":"入札待ち",
  "unsuccessful_bid":"入札受付失敗",
  "bulk_revocation":"一括取消",
  "management_type":"管理種別",
  "contract_status":"契約有無",
  "power_gen_plan":"発電計画",
  "normal_bidding":"通常入札",
  "market_details":"市場詳細",
  "plan_confirmed":"計画確定",
  "plan_submitted":"計画提出",
  "demand_results":"需要実績",
  "bidding_limit":"入札制限",
  "bid_execution":"入札実行",
  "contract_name":"契約名称",
  "voltage_class":"電圧階級",
  "power_plant_a":"発電所A",
  "past_results":"過去実績",
  "power_gen_bg":"発電BG",
  "one_hour_ago":"1時間前",
  "input_legend":"入力凡例",
  "bulk_bidding":"一括入札",
  "salebuy_type":"売買種別",
  "notification":"お知らせ",
  "infomation":"お知らせ",
  "demand_plan":"需要計画",
  "update_date":"更新日時",
  "postal_code":"郵便番号",
  "sales_plan":"販売計画",
  "bid_number":"入札番号",
  "start_time":"調整開始時間",
  "prefecture":"都道府県",
  "demand_bg":"需要BG",
  "save_date":"保存日時",
  "past_plan":"過去計画",
  "bid_price":"入札価格",
  "form_name":"帳票名称",
  "displayed":"表示件数",
  "no_change":"変更なし",
  "change_no":"変更番号",
  "bid_type":"売買種別",
  "priority":"優先順位",
  "new_bid":"新規入札",
  "no_type":"種別なし",
  "prorata":"プロラタ",
  "repeat":"繰り返し",
  "change":"変更あり",
  "login":"ログイン",
  "spot":"スポット",
  "bg01":"BG01",
  "file":"ファイル",
  "self_consignment":"自己託送",
  "contrac_price":"約定価格",
  "latest_results":"最新結果",
  "system":"システム",
  "group":"グループ",
  "auto_configuration":"自動設定",
  "bg_name":"BG名称",
  "to_be_confirmed":"確定まで",
  "expiration_of_a_term":"期限切れ",
  "demandforecast":"需要計画",
  "powerforecast_title":"発電計画",
  "step":"組合せ",
  "notification_setting":"通知設定",
  "log_in":"ログイン",
  "forecast_list":"計画一覧",
  "powerforecast":"発電計画",
  "submitting_plan":"計画提出",
  "excess_or_deficiency":"過不足",
  "number_of_divisions":"分割数",
  "status_jotai_colon":"状態：",
  "sales_destinations":"販売先",
  "contract_quantity":"約定量",
  "adjustment_amount":"調整価格",
  "hokkaido_region":"北海道",
  "procurement_src":"調達先",
  "not_implemented":"未実施",
  "before_bidding":"入札前",
  "under_contract":"約定中",
  "bidding_volume":"入札量",
  "business_sites":"事業所",
  "supply_power":"供給力",
  "unable_to_do":"出来ず",
  "created_from":"作成元",
  "unregistered":"未登録",
  "power_plant":"発電所",
  "solar_power":"太陽光",
  "implemented":"実施済",
  "target_date":"対象日",
  "reforecast":"再予測",
  "contracted":"約定済",
  "thirty_min":"30分",
  "kagoshima":"鹿児島",
  "set_value":"設定値",
  "exchanges":"取引所",
  "demander":"需要家",
  "received":"受付済",
  "updater":"更新者",
  "deleted":"削除済",
  "changed":"変更後",
  "cycle":"周期：",
  "area":"エリア",
  "sent":"送信済",
  "role":"ロール",
  "plan_value":"計画値",
  "results_jissekichi":"実績値",
  "able":"可能量",
  "user":"ユーザー",
  "bidded":"入札済",
  "submitted":"提出済",
  "not_submitted":"提出前",
  "deviation_ratio":"乖離率",
  "upper_upper_limits":"上上限",
  "lower_lower_limits":"下下限",
  "lowest_price_sell":"最安値",
  "highest_price_buy":"最高値",
  "notification_date":"通知日",
  "sales_plan_duplication":"販売計画複写",
  "procurement_plan_duplication":"調達計画複写",
  "delete_request":"取消要求",
  "delete_failed":"取消失敗",
  "theme":"テーマ",
  "light":"ライト",
  "dark":"ダーク",
  "hour_ago":"1時間前",
  "hydroelectric_power":"水力",
  "extra_high_voltage":"特高",
  "geothermal_power":"地熱",
  "hokuriku_region":"北陸",
  "chugoku_region":"中国",
  "shikoku_region":"四国",
  "wind_direction":"風向",
  "tohoku_region":"東北",
  "kansai_region":"関西",
  "kyushu_region":"九州",
  "tokyo_region":"東京",
  "kinki_region":"近畿",
  "chubu_region":"中部",
  "total_amount":"全量",
  "status_jokyo":"状況",
  "cancellation":"取消",
  "active_alarm":"警報",
  "high_voltage":"高圧",
  "status_jotai":"状態",
  "lower_limit":"下限",
  "upper_limit":"上限",
  "low_voltage":"低圧",
  "wind_power":"風力",
  "monitoring":"監視",
  "company_a":"A社",
  "breakdown":"内訳",
  "confirmed":"確定",
  "annually":"毎年",
  "weather":"天気",
  "receive":"受信",
  "remarks":"備考",
  "monthly":"毎月",
  "result":"結果",
  "delete":"削除",
  "weekly":"毎週",
  "today":"当日",
  "timeB":"時刻",
  "timeC":"日/時",
  "daily":"毎日",
  "save":"保存",
  "copy":"複写",
  "reflect_contract_amount": "約定量計画反映",
  "unit":"単位",
  "combination":"組合せ",
  "part":"部分",
  "sell":"売り",
  "bidding_time_zone":"入札時刻",
  "price_yen_kwh":"価格",
  "plan":"計画",
  "unplanned":"未計画",
  "fixed":"計画済",
  "type":"種別",
  "none":"なし",
  "edit":"編集",
  "buy":"買い",
  "bidding":"入札",
  "yes":"はい",
  "no":"いいえ",
  "off":"オフ",
  "all":"全て",
  "bg":"BG",
  "kw":"kW",
  "on":"オン",
  "total":"合計",
  "daily_amount":"日量",
  "deviation":"乖離",
  "results":"実績",
  "forecasting":"予測",
  "survice":"供給",
  "surviceForce":"供給力",
  "supply":"調達",
  "sale":"販売",
  "reserveForce":"発電余力",
  "list":"一覧",
  "registration":"登録",
  "okinawa_region":"沖縄",
  "latest":"最新",
  "average":"平均",
  "legend":"凡例",
  "demand":"需要",
  "notification_tuti":"通知",
  "setting":"設定",
  "setting_title":"設定",
  "change_simple":"パスワード変更",
  "forecast":"計画",
  "subtotal":"計",
  "tekito":"件",
  "time":"時",
  "fri":"金",
  "mon":"月",
  "wed":"水",
  "sat":"土",
  "sun":"日",
  "tue":"火",
  "min":"分",
  "thu":"木",
  "valid":"有効",
  "invalid":"無効",
  "month":"月",
  "end_of_month":"月末日",
  "day":"日",
  "power_bp_name":"発電事業者名称",
  "power_bp_code":"発電事業者コード",
  "new_power_kind":"発電事業者種別",
  "target_area":"管轄エリア",
  "new_power_bp":"発電事業者登録",
  "outside_limit_message":"入札制限範囲外です。",
  "range_specification":"以下の範囲で入力してください。",
  "selling_price":"売り価格",
  "buying_price":"買い価格",
  "buying_volume":"買い量",
  "selling_volume":"売り量",
  "could_not_communicate_jepx":"JEPXと通信できませんでした。",
  "contract_reflected":"約定量を計画値に反映しました。",
  "could_not_be_reflected":"約定量を計画値に反映できませんでした。",
  "save_sales_plan_procurement_plan":"販売計画・調達計画を保存しました。",
  "cannot_save_sales_plan_procurement_plan":"販売計画・調達計画の保存ができませんでした。",
  "canceled_bid":"入札を取消しました。",
  "could_not_canceled_bid_to_timeout":"タイムアウトにより、入札の取消ができませんでした。",
  "could_not_canceled_bid":"入札の取消ができませんでした。",
  "temporarily_save":"入札計画を一時保存しました。",
  "faild_temp_save":"入札計画を一時保存出来ませんでした。",
  "outside_bid_limit":"入札制限範囲外の値が入力されています。",
  "reconsider_bidding":"入札計画の見直しを行ってください。",
  "execute_bidding":"入札を実行しました。",
  "bid_failed_bid_to_timeout":"タイムアウトにより、入札できませんでした。",
  "could_not_obtain_latest_results":"最新結果を取得できませんでした。",
  "could_not_obtain_market_details":"市場詳細を取得できませんでした。",
  "unable_bid":"入札できませんでした。",
  "or_more":"以上",
  "or_less":"以下",
  "modal_close":"閉じる",
  "intraday_auto_setting_1":"実需給2時間前",
  "intraday_auto_setting_2":"実需給3時間前",
  "intraday_auto_setting_3":"実需給4時間前",
  "intraday_auto_setting_4":"実需給5時間前",
  "intraday_auto_setting_5":"実需給6時間前",
  "intraday_auto_setting_6":"実需給7時間前",
  "intraday_auto_setting_7":"実需給8時間前",
  "intraday_auto_setting_8":"実需給9時間前",
  "intraday_auto_setting_9":"実需給10時間前",
  "intraday_auto_setting_10":"実需給11時間前",
  "intraday_auto_setting_11":"実需給12時間前",
  "intraday_bid_failure":"入札失敗",
  "intraday_auto_deletion_enabled":"有効",
  "intraday_auto_deletion_unenabled":"無効",
  "intraday_auto_cycle_1":"10分",
  "intraday_auto_cycle_2":"20分",
  "intraday_auto_cycle_3":"30分",
  "intraday_auto_cycle_4":"40分",
  "intraday_auto_cycle_5":"50分",
  "intraday_auto_cycle_6":"60分",
  "intraday_auto_deletion_message":"締切時刻で自動削除されます。",
  "intraday_save_supply_plan":"販売計画を保存しました。",
  "intraday_save_failed_supply_plan":"販売計画を保存できませんでした。",
  "intraday_excute_bulk_bid":"一括入札を実行しました。",
  "intraday_bulk_bid_failed_to_timeout":"タイムアウトにより、一括入札できませんでした。",
  "intraday_bulk_bid_failed":"一括入札できませんでした。",
  "intraday_excute_bulk_cancellation":"一括取消を実行しました。",
  "intraday_bulk_cancellation_failed_to_timeout":"タイムアウトにより、一括取消できませんでした。",
  "intraday_bulk_cancellation_failed":"一括取消できませんでした。",
  "alarm_err_message_1":"通知名称もしくはメッセージが未入力です。",
  "alarm_err_message_2":"日時の入力が不適切です。",
  "alarm_err_message_3":"曜日を選択してください。",
  "alarm_saved_new_setting":"通知設定を新規追加しました。",
  "alarm_failed_save_new_setting":"通知設定を保存できませんでした。",
  "alarm_updated_setting":"通知設定を更新しました。",
  "alarm_failed_uppdate_setting":"通知設定を更新できませんでした。",
  "alarm_deleted_setting":"通知設定を削除しました。",
  "alarm_failed_delete_setting":"通知設定を削除できませんでした。",
  "loss_failed_obtain_information":"エリア情報の取得ができませんでした。",
  "loss_err_message_1":"損失率の値が異常、又は入力されていない場所があるため、保存処理を実行できません。",
  "loss_err_message_2":"変更反映日に過去の日付が入力されているエリアが存在します。過去の日付が入力されているエリアのデータは保存されませんが、このまま保存を行いますか？",
  "loss_err_message_3":"日付に不正な値が入力されているため、保存できません。",
  "loss_outside_limit_message" : "入力制限範囲外です。",
  "extra_high_voltage_range_limit" : "特高：0.1～6.0",
  "high_voltage_range_limit" : "高圧：1.0～8.0",
  "low_voltage_range_limit" : "低圧：3.0～12.0",
  "loss_saved_area_information":"エリア損失率情報を保存しました。",
  "loss_failed_save_area_infomation":"ネットワークエラーにより、保存できませんでした。",
  "setting_required":"*必須 ",
  "setting_available_characters_1":"*使用可能文字 半角英数字及び半角記号ハイフン(-)、アンダーバー(_) 8文字以上",
  "setting_available_characters_2":"半角英数字及び半角記号アットマーク(@)、ハイフン(-)、ピリオド(.)、アンダーバー(_)のみ",
  "setting_available_characters_3":"半角英数字大小含む 12文字以上 ユーザーIDと異なるもの",
  "setting_available_characters_4":"半角数字のみ",
  "setting_available_characters_5":"半角英数字のみ",
  "setting_available_characters_6":"英数字４桁",
  "setting_character_limit_1":"16文字以内",
  "role_all":"全てのロール",
  "role_system_administrator":"システム管理者",
  "role_tenant_administrator":"テナント管理者",
  "role_multi_area_administrator":"複数エリア管理者",
  "role_area_plan_submitter":"エリア毎計画提出者",
  "role_power_plant":"発電所",
  "role_demand":"需要家",
  "role_fit_administrator":"FITシステム管理者",
  "setting_change_selections_message":"保存されていないデータがあります。選択項目を変更してもよろしいですか？",
  "setting_failed_new_registration":"新規登録できませんでした。入力内容を確認してください。",
  "user_setting_err_role_area_plan_submitter":"エリア毎計画提出者は担当エリアを1つまで設定できます。事業者の管轄エリアを1つ選択してください。",
  "user_registered":"ユーザーを新規追加しました。",
  "user_failed_registration":"ユーザーを新規追加できませんでした。",
  "setting_failed_update":"更新できませんでした。入力内容を確認してください。",
  "user_updated":"ユーザー情報を更新しました。",
  "user_failed_update":"ユーザー情報を更新できませんでした。",
  "user_cannot_delete":"ログイン中のユーザのため、削除出来ません。",
  "user_failed_delete":"ユーザーを削除できませんでした。",
  "user_deleted":"ユーザーを削除しました。",
  "password_updated_success":"パスワードを更新しました。",
  "password_updated_failed":"パスワードを更新できませんでした。",
  "setting_power_supply_type_1":"非調整",
  "setting_power_supply_type_2":"調整",
  "setting_management_type_1":"自社",
  "setting_management_type_2":"他者発",
  "setting_management_type_3":"他社小",
  "setting_management_type_4":"JBU",
  "setting_unselected":"未選択",
  "power_bg_added":"発電BGを追加しました。",
  "power_bg_failed_add":"発電BGを追加できませんでした。",
  "power_bg_update":"発電BGの情報を更新しました。",
  "power_bg_failed_update":"発電BGの情報を更新できませんでした。",
  "power_bg_deleted":"発電BGを削除しました。",
  "power_bg_failed_delete":"発電BGを削除できませんでした。",
  "setting_bp_name":"事業者",
  "setting_bp_code":"事業者コード",
  "setting_confirm_entry":"入力内容を確認してください。",
  "demand_bg_added":"需要BGを新規追加しました。",
  "demand_bg_err_message_1":"需要BGは1エリアにつき、1つまで設定できます。重複している需要BGが存在します。",
  "demand_bg_failed_add":"需要BGを新規追加できませんでした。",
  "demand_bg_update":"需要BGを更新しました。",
  "demand_bg_failed_update":"需要BGを更新できませんでした。",
  "demand_bg_deleted":"需要BGを削除しました。",
  "demand_bg_failed_delete":"需要BGを削除できませんでした。",
  "power_contractor":"電源契約者",
  "power_contractor_common":"通常",
  "power_contractor_other_companies_procurement":"他社調達",
  "power_contractor_deleted":"発電契約者を削除しました。",
  "power_contractor_failed_delete":"発電契約者を削除できませんでした。",
  "power_contractor_added":"発電契約者を新規追加しました。",
  "power_contractor_err_message_1":"発電契約者は1エリアにつき、1つまで設定できます。重複している発電契約者が存在しています。",
  "power_contractor_failed_add":"発電契約者を新規追加できませんでした。",
  "power_contractor_updated":"発電契約者の情報を更新しました。",
  "power_contractor_failed_update":"発電契約者の情報を更新できませんでした。",
  "interconnection_deleted":"接続供給契約情報を削除しました。",
  "interconnection_failed_delete":"接続供給契約情報を削除できませんでした。",
  "interconnection_added":"接続供給契約情報を新規追加しました。",
  "interconnection_err_message_1":"接続供給契約は1エリアにつき、1つまで設定できます。重複している接続供給契約が存在します。",
  "interconnection_failed_add":"接続供給契約情報を新規追加できませんでした。",
  "interconnection_updated":"接続供給契約情報を更新しました。",
  "interconnection_failed_update":"接続供給契約情報を更新できませんでした。",
  "power_receive_point_number":"受電地点特定番号",
  "setting_latitude":"緯度",
  "setting_longitude":"経度",
  "panel_mounted_type_1":"屋根置き型",
  "panel_mounted_type_2":"屋根一体型",
  "panel_mounted_type_3":"架台設置型(裏面解放)",
  "panel_mounted_type_4":"架台設置型(裏面密閉)",
  "panel_type_1":"結晶系",
  "panel_type_2":"CIS系",
  "panel_type_3":"アモルファス系",
  "panel_type_4":"HIT系",
  "power_plant_setting_added":"発電所を新規追加しました。",
  "power_plant_setting_failed_add":"発電所を新規追加できませんでした。",
  "power_plant_setting_updated":"発電所情報を更新しました。",
  "power_plant_setting_failed_update":"発電所情報を更新できませんでした。",
  "power_plant_setting_deleted":"発電所を削除しました。",
  "power_plant_setting_failed_delete":"発電所を削除できませんでした。",
  "power_plant_setting_err_message_1":"上下限の設定値が上上限の設定値より大きくなっている箇所があります。",
  "power_plant_setting_threshold_updated":"閾値を更新しました。",
  "power_plant_setting_threshold_failed_update":"ネットワークエラーにより、閾値を更新できませんでした。",
  "demand_setting_partial_supply_none":"部分供給契約なし",
  "demand_setting_partial_supply_notice_type":"通告型部分供給(ベース)",
  "demand_setting_failed_delete":"需要家を削除できませんでした。",
  "demand_setting_deleted":"需要家を削除しました。",
  "demand_setting_failed_update":"需要家の情報を更新できませんでした。",
  "demand_setting_updated":"需要家の情報を更新しました。",
  "demand_setting_err_message_1":"需要家は1需要BGにつき、1つまで設定できます。重複している需要家が存在しています。",
  "demand_setting_failed_add":"需要家を新規追加できませんでした。",
  "demand_setting_added":"需要家を新規追加しました。",
  "demand_setting_partial_updated":"部分供給設定を更新しました。",
  "demand_setting_partial_failed_update":"部分供給設定を更新できませんでした。入力内容を確認してください。",
  "demand_setting_partial_cancellation":"部分供給設定を解除しました。",
  "demand_setting_partial_failed_cancellation":"部分供給設定を解除できませんでした。",
  "setting_warning":"警告",
  "setting_delete_confirmation":"削除処理を実行します。本当によろしいですか？",
  "sale_list_failed_delete":"販売先グループを削除できませんでした。",
  "sale_list_deleted":"販売先グループを削除しました。",
  "sale_list_failed_update":"販売先グループの情報を更新できませんでした。",
  "sale_list_updated":"販売先グループの情報を更新しました。",
  "sale_list_failed_add":"販売先グループを新規追加できませんでした。",
  "sale_list_added":"販売先グループを新規追加しました。",
  "sale_detail_added":"販売先グループ内訳を新規追加しました。",
  "sale_detail_failed_add":"販売先グループ内訳を新規追加できませんでした。",
  "sale_detail_updated":"販売先グループ内訳の情報を更新しました。",
  "sale_detail_failed_update":"販売先グループ内訳の情報を更新できませんでした。",
  "sale_detail_deleted":"販売先グループ内訳を削除しました。",
  "sale_detail_failed_delete":"販売先グループ内訳を削除できませんでした。",
  "file_select":"ファイル選択",
  "jepx_user_setting_message":"*JEPX追加ユーザーはID入力時必須項目となります",
  "jepx_user_setting_required":"*追加ユーザー登録の際は必須",
  "jepx_user_setting_err_message_1":"JEPX参加者情報、JEPX代表ユーザー情報、追加ユーザー情報いずれかに不足があります。パスワードや内容を確認してください。",
  "jepx_user_setting_err_message_2":"JEPX参加者情報、JEPX代表ユーザー情報に不足があります。パスワードや内容を確認してください。",
  "jepx_user_representative_register":"JEPX参加者情報とJEPX代表ユーザー情報を登録しました。",
  "jepx_user_failed_register":"入力情報を保存できませんでした。",
  "jepx_user_all_register":"JEPX参加者情報とJEPX代表ユーザー情報、追加ユーザー情報を登録しました。",
  "jepx_additional_user_failed_register":"JEPX参加者情報とJEPX代表ユーザー情報を登録しましたが、追加ユーザー情報の登録ができませんでした。",
  "certificate_failed_regidter_check_enter":"証明書情報を登録できませんでした。入力内容を確認してください。",
  "certificate_failed_regidter":"証明書情報を登録できませんでした。",
  "certificate_register":"証明書情報を登録しました。",
  "power_bp_setting_title":"発電事業者設定",
  "power_bp_setting_bp_kind_1":"一般",
  "power_bp_setting_bp_kind_2":"委託他",
  "power_bp_setting_new_registered":"発電事業者を新規登録しました。",
  "power_bp_setting_failed_register":"発電事業者を新規登録できませんでした。",
  "power_bp_setting_err_message_1":"発電事業者は1テナントにつき、1つまで設定できます。",
  "power_bp_setting_err_message_2":"管轄エリアを1つ以上選択してください。",
  "power_bp_setting_updated":"発電事業者の情報を更新しました。",
  "power_bp_setting_failed_update":"発電事業者の情報を更新できませんでした。",
  "power_bp_setting_deleted" : "発電事業者を削除しました。",
  "power_bp_setting_delete_failed" : "発電事業者を削除できませんでした。",
  "intraday_bid_delete":"入札取消",
  "network_err":"通信エラー",
  "network_err_message":"ネットワークエラーが発生しました。時間を置いて再度お試しください。",
  "session_err":"認証エラー",
  "session_err_message":"セッションが無効です。ログインからやり直してください。",
  "auto_bidding_start_check_msg" : "自動入札を開始しますか？",
  "auto_bidding_stop_check_msg" : "自動入札を停止しますか？",
  "auto_bidding_check_title" : "自動入札",
  "triple_component_all_areas" : "全てのエリア",
  "triple_component_all_bg" : "全てのBG",
  "triple_component_all_power_plants" : "全ての発電所",
  "triple_component_all_demand" : "全ての需要家",
  "try_to_get_again" : "再取得しますか？",
  "operation_err":"操作異常",
  "operation_err_message":"無効な操作が行われました。再度ログインからやり直してください。",
  "back_to_login":"ログイン画面に戻る",
};