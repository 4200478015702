import React, { useState, useEffect, useContext } from "react";
import "./css/SetPowerContractList.css";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { padding, width } from "@mui/system";
import axios from "axios";
import CustomModal from "../common/customComponents/CustomModal";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import CustomDatePickerTryal from "../common/customComponents/CustomDatePickerTryal";
import { LanguageContext } from "../common/localization/localization";
import { modalMode } from "./SaleList";
import { saleKindInfo, setSaleGroupInfo } from "../../types/master/SetSaleList";
import { resAreaList } from "../../types/common/Search";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import { addDays, format, subDays } from "date-fns";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import { CustomTextFieldBig } from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import CustomModalDatePicker from "../common/customComponents/CustomMasterCompornent/CustomMstDatePicker";
import { useAreaUtility } from "../../common/area";
import { useSaleKindUtility } from "../../common/saleKind";
import { useAuthedApi } from "../../common/axios";
import {API_URL } from "../../common/constant";

type role = {
  role_id: number;
  role_nm: string;
};

const CustomTextField = styled(TextField)(() => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "auto",
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
}));

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  groupName: boolean;
  saleKind: boolean;
  areaId: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  groupName: false,
  saleKind: false,
  areaId: false,
};

const SetPowerContractList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  saleGrIdPK: number
) => {
  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  // 通信エラー
  const api = useAuthedApi();

  //エリアリスト作成
  const { areaOptions } = useAreaUtility();
  //販売先種別リスト作成
  const { saleKindOptions } = useSaleKindUtility();

  type areaInfo = { id: string; name: string };
  type saleKindInfoSellect = { id: number; name: string; saleKindCode: string };
  //↑セレクター用）

  const [areaInfos, setAreaInfos] = useState<string[]>([]);
  const saleKindInfos = React.useRef<string[]>(["0"]);
  const [saleKindNo, setSaleKindNo] = useState<string>("0"); //saleKindセレクターの選ばれたIDを管理
  //saleDataの中にモーダル内の情報を管理してもらう
  //初期化用
  const [saleGrData, setSaleGrData] = useState<setSaleGroupInfo>();
  const initialSaleGroupInfo: setSaleGroupInfo = {
    groupId: 0, //グループID PK
    groupName: "", //販売先グループ名称
    areaId: "00", //エリアID
    saleKind: "", //販売先種別
    remarks: "", //備考
    startDate: format(new Date(), "yyyy/MM/dd"), //開始日
    finishDate: format(new Date(), "yyyy/MM/dd"), //終了日
  };
  //開始日
  const [startDate, setStartDate] = useState<Date>(new Date());
  //終了日
  const [finishDate, setFinishDate] = useState<Date>(addDays(new Date(), 1));
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する
  const [resetArea, setResetArea] = useState(true); //エリアを未選択状態にするかどうかのフラグ

  //console.log(saleGrData);

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化
  ////

  //開始datePicker
  useEffect(() => {
    const tmpInfo: setSaleGroupInfo = Object.assign({}, saleGrData);
    tmpInfo.startDate = format(startDate, "yyyy/MM/dd");
    setSaleGrData(tmpInfo);
  }, [startDate]);

  ////終了datePicker
  useEffect(() => {
    const tmpInfo: setSaleGroupInfo = Object.assign({}, saleGrData);
    tmpInfo.finishDate == null
      ? (tmpInfo.finishDate = "")
      : (tmpInfo.finishDate = format(finishDate, "yyyy/MM/dd"));
    setSaleGrData(tmpInfo);
  }, [finishDate]);

  useEffect(() => {
    if (toOpen === true) {
      if (mode == modalMode.editMode) {
        setResetArea(false);
        getDataLinkedSaleId(saleGrIdPK);
      } else {
        //ContractorCompornentChanged(contractorInfos.current[0]);
        setSaleGrData(initialSaleGroupInfo);
        setSaleKindNo("0");
        setStartDate(new Date());
        setFinishDate(addDays(new Date(), 1));
      }
    } else {
      setSaleGrData(initialSaleGroupInfo);
      setSaleKindNo("0");
      setStartDate(new Date());
      setFinishDate(addDays(new Date(), 1));
      setInputError(initErrorInput);
    }
  }, [toOpen]); //初期化

  /////////エリア関連
  //データベースからエリアデータ取得
  useEffect(() => {
    // エリア一覧取得
    //console.log("エリアデータ取得開始");
    getAreaMst();
    setResetArea(true);
  }, [saleKindNo]); //初期化

  const getAreaMst = () => {
    api
      .get(API_URL.GET_SALES_TYPE_AREA, {params: { saleKindNo: saleKindNo },})
      .then((response) => {
        //console.log("エリアマスタ取得開始");
        //console.log(response.data.result);              
        const tmpAreaInfo = response.data.map((data: any) => {
          return data.areaId;
        });

        setAreaInfos(tmpAreaInfo);

        //新規登録でモーダルを開いたとき、新規登録・編集で販売先種別を切り替えたときにエリアを未選択にリセットする
        if(resetArea === true && saleGrData !== undefined){
            const tmpInfo: setSaleGroupInfo = Object.assign({}, saleGrData);
            tmpInfo.areaId = "00";
            setSaleGrData(tmpInfo);
         }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertAreaData = (result: resAreaList[]) => {
    const retAreaData: string[] = [];
    result.map((data) => {
      retAreaData.push(data.areaId.toString());
    });
    return retAreaData;
  };

  function setAreaId(id: number) {
    //numberを文字列にしてコピーしたdemandListInfoのIDにセットする
    const areaIdString = ("00" + id).slice(-2).toString();
    const tmpAreaInfo: setSaleGroupInfo = Object.assign({}, saleGrData);
    tmpAreaInfo.areaId = areaIdString;
    
    setSaleGrData(tmpAreaInfo);
  }

  /////////エリア関連

  //////販売先種別関連
  const saleKindNames: saleKindInfo[] = [
    //{ name: "域内他BG", saleKindNo: "11" },
    { name: "域内自己託送", saleKindNo: "12" },
    //{ name: "域外自己託送", saleKindNo: "13" },
    { name: "スポット", saleKindNo: "21" },
    { name: "１時間前", saleKindNo: "31" },
  ];

  useEffect(() => {
    //販売先種別一覧取得
    //console.log("販売先種別取得開始");
    saleKindInfos.current = saleKindInfos.current.concat(
      convertSaleKindData(saleKindNames)
    );
  }, []); //初期化

  const convertSaleKindData = (saleKindNames: saleKindInfo[]): string[] => {
    const selectSaliKindData: string[] = [];
    saleKindNames.map((data) => {
      selectSaliKindData.push(data.saleKindNo);
    });
    return selectSaliKindData;
  };

  function setSaleKindId(id: number) {
    const tmpSaleInfo: setSaleGroupInfo = Object.assign({}, saleGrData);
    tmpSaleInfo.saleKind = id.toString();
    //console.log(tmpSaleInfo.saleKind);
    // setSelContractantCode(tmpContractInfo[e].code);
    // setSelContractantNo(tmpContractInfo[e].id);
    setSaleKindNo(id.toString());
    setSaleGrData(tmpSaleInfo);
  }

  //////販売先種別関連ここまで

  //テキストフィールドの中身が変化したら、contractInfosの中身をそのたびに変える
  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name: string = e.currentTarget.name;
    const value: string = e.currentTarget.value;

    const tmpInfo: setSaleGroupInfo = Object.assign({}, saleGrData);
    switch (name) {
      case "groupName":
        tmpInfo.groupName = value;
        break;
      case "remarks":
        tmpInfo.remarks = value;
        break;
      default:
        break;
    }
    setSaleGrData(tmpInfo);
  }

  //新規追加
  function AddDatas() {
    //console.log(saleGrData);
    let error = false;

    error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_new_registration);
      setDigOpen(true);
    } else if (error === false) {
      api
        .post(API_URL.ADD_SALEGROUP_INFO, saleGrData)
        .then((response) => {
          //console.log(response.data.dgm_role);
          setJobClear(true);
          setDialogMessage(languageContext.words.sale_list_added);
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.sale_list_failed_add);
          setDigOpen(true);
        });
    }
  }

  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (saleGrData?.groupName == "") {
      error = true;
      tempInputErrors.groupName = true;
    }
    if (saleGrData?.saleKind == "") {
      error = true;
      tempInputErrors.saleKind = true;
    }
    if (saleGrData?.areaId == "00") {
      error = true;
      tempInputErrors.areaId = true;
    }

    setInputError(tempInputErrors);
    return error;
  };

  // データ一行分取得
  const getDataLinkedSaleId = (saleGrId: number) => {
    //.log("1行データ取得開始");
    let responseData: setSaleGroupInfo;
    const url = api
      .get(API_URL.GET_ONE_SALEGR_DATA, {
        params: { saleGrId: saleGrId },
      })
      .then((response) => {
        //console.log(response.data.result);
        responseData = response.data.result as setSaleGroupInfo;

        //console.log("Help!!");
        //console.log(responseData);
        setSaleGrData(responseData);
        setSaleGrInfoForEdit(responseData.saleKind); //ここでは表示用データ更新
        console.log(responseData.startDate);
        setStartDate(new Date(responseData.startDate));
        setFinishDate(new Date(responseData.finishDate));
      })
      .catch((err) => {
        console.log(err);
        setSaleGrData(initialSaleGroupInfo);
      });
  };
  console.log(startDate);

  //編集用にDBから出したデータの販売先グループIDと一致しているものを
  //saleGroupsInfosからさがし、その情報をコンポーネントに表示
  const setSaleGrInfoForEdit = (saleGrKindCdFromDB: string) => {
    saleKindInfos.current.map((saleKindInfo) => {
      if (saleGrKindCdFromDB === saleKindInfo || saleKindInfo === "0") {
        // setSelContractantCode(contractorInfo.code);
        // setSelContractantNo(contractorInfo.id);
        setSaleKindNo(saleKindInfo);
      }
    });
  };

  //groupIdの値をもとにデータを更新する
  function UpdateDatas() {
    //console.log(saleGrData);
    let error = false;

    error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else if (error === false) {
      const url = api
        .post(API_URL.UPDATE_SALEGROUP_INFO, saleGrData)
        .then((response) => {
          //console.log(response.data.dgm_role);
          setJobClear(true);
          setDialogMessage(languageContext.words.sale_list_updated);
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.sale_list_failed_update);
          setDigOpen(true);
        });
    }
  }

  // 削除(論理削除)
  const Delete = (saleGrId: number | undefined) => {
    // 削除API呼出
    //.log(saleGrData);
    api
      .post(API_URL.DELETE_SALEGROUP_INFO, { saleGrId: saleGrId })
      .then((response) => {
        //console.log(`BG1件削除結果: ${response.data.result}`);
        setJobClear(true);
        setDialogMessage(languageContext.words.sale_list_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.sale_list_failed_delete);
        setDigOpen(true);
      });
  };

  //セーブして閉じる
  const DoModeJob = () => {
    //console.log("accept!!!!");
    if (mode == modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.sales_destinations_setting}
      open={toOpen}
      onAcceptLeft={() => DoModeJob()}
      onAcceptRight={() => Delete(saleGrData?.groupId)}
      onClose={() => HaldleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
      }
    >
      <Stack direction="column" sx={{ height: "360px", width: "550px" }}>
        <CustomBoxBig>
          <CustomModalTypography gutterBottom>
            {languageContext.words.sales_destinations_name}
          </CustomModalTypography>
          <CustomTextFieldBig
            type="text"
            value={saleGrData?.groupName}
            onChange={onChangeValue}
            name="groupName"
            inputProps={{
              maxLength: 64,
            }}
            error={inputError.groupName}
            helperText={languageContext.words.setting_required}
          ></CustomTextFieldBig>
        </CustomBoxBig>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.sales_destinations_type}
            </CustomModalTypography>
            <CustomSelectListSmall
              //key={"demandForecastData_CustomSelectList_customer"}
              name="saleKind"
              label={languageContext.words.all_areas}
              //defaultValue={0}
              value={saleKindNo}
              options={saleKindOptions(saleKindInfos.current)}
              onChange={(e: number) => {
                setSaleKindId(e);
              }}
              error={inputError.saleKind}
            />
          </CustomBoxSmall>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.connection_area}
            </CustomModalTypography>

            <CustomSelectListSmall
              //key={"demandForecastData_CustomSelectList_customer"}
              name="areaId"
              label={languageContext.words.all_areas}
              //defaultValue={0}
              value={saleGrData?.areaId}
              options={areaOptions(areaInfos)}
              onChange={(e: number) => {
                setAreaId(e);
              }}
              error={inputError.areaId}
            />
          </CustomBoxSmall>
        </Stack>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.effective_date_start_date}
            </CustomModalTypography>
            <CustomModalDatePicker
              setValue={setStartDate}
              value={startDate}
              maxDate={subDays(finishDate, 1)}
            />
          </CustomBoxSmall>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.expiration_date}
            </CustomModalTypography>
            <CustomModalDatePicker
              setValue={setFinishDate}
              value={finishDate}
              minDate={addDays(startDate, 1)}
            />
          </CustomBoxSmall>
        </Stack>

        <CustomBoxBig>
          <CustomModalTypography gutterBottom>
            {languageContext.words.remarks}
          </CustomModalTypography>
          <CustomTextFieldBig
            type="text"
            value={saleGrData?.remarks}
            onChange={onChangeValue}
            name="remarks"
            inputProps={{
              maxLength: 256,
            }}
          ></CustomTextFieldBig>
        </CustomBoxBig>
      </Stack>

      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetPowerContractList;
