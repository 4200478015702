import React, { useContext, useEffect } from "react";
import { AppBar } from "@mui/material";
import { Tabs } from "@mui/material";
import { Tab } from "@mui/material";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

import UserList from "./UserList"; // ユーザ一覧
import PowerPlantList from "./PowerPlantList"; // 発電所一覧
import SupplyList from "./SupplyList"; // 需要家一覧
import PowerContractList from "./PowerContractList"; // 発電契約者一覧
import ConnectContractList from "./ConnectContractList"; // 接続供給契約一覧
import SupplyBgList from "./SupplyBgList"; // 需要BG一覧
import PowerBbList from "./PowerBgList"; // 発電BG一覧
import SaleList from "./SaleList"; // 販売先グループ一覧
import SaleDetailList from "./SaleDetailList"; // 販売先グループ内訳
import AreaLossList from "./AreaLossList"; // エリアロス情報
import SetMarketUser from "./SetMarketUser"; // JEPXユーザ登録
import CertificateList from "./CertificateList"; // 証明書登録
import PowerBp from "./PowerBp"; // 事業者 

import "./css/Setting.css";
import { LanguageContext } from "../common/localization/localization";
import { PaletteContext } from "../common/paletteMode";
import AlarmList from "../system/Utility/AlarmList";
import { LeavePageContext } from "../common/customComponents/CustomConfirmLeavePage";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function selections(index: any) {
  return {
    id: `setting-tab-${index}`,
    "aria-controls": `setting-tabpanel-${index}`,
  };
}

const Setting = () => {
  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);

  // アスペクト比
  const options = {
    maintainAspectRatio: false,
  };

   useEffect(() => {
    leavePageContext.setBlockLeave(false);
  }, []);

  // ユーザ権限確認
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const palletContextBase = useContext(PaletteContext);
  return (
<div className={`cn-main-display ${palletContextBase.PaletteMode === "dark" ? "dark" : "light"}`}>
      <AppBar
        position="static"
        color="default"
        sx={{
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 1px 0px 0px rgb(0 0 0 / 20%)",
          top: 0,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="setting tabs"
          variant="scrollable"
          scrollButtons={true}
        >
          <Tab
            label={languageContext.words.alarm_listsetting}
            {...selections(0)}
          />
          <Tab
            label={languageContext.words.area_transmission_loss_info}
            {...selections(1)}
          />
          <Tab label={languageContext.words.user_list} 
            {...selections(2)} />
          <Tab
            label={languageContext.words.power_producer}
            {...selections(3)}
          />
          <Tab
            label={languageContext.words.power_gen_contractor}
            {...selections(4)}
          /> 
          <Tab
            label={languageContext.words.power_gen_bg_list}
            {...selections(5)}
          />
          <Tab
            label={languageContext.words.power_plant_list}
            {...selections(6)}
          />
          <Tab
            label={languageContext.words.demand_gen_bg_list}
            {...selections(7)}
          />
          <Tab
            label={languageContext.words.interconnection_supply_agreement_list}
            {...selections(8)}
          />
          <Tab 
            label={languageContext.words.demander_list} 
            {...selections(9)} 
          />
          <Tab
            label={languageContext.words.sales_destinations_group_list}
            {...selections(10)}
          />
          <Tab
            label={languageContext.words.sales_destinations_group_breakdown}
            {...selections(11)}
          />
          <Tab 
            label={languageContext.words.jepx_user_registration} 
            {...selections(12)} 
          />
          <Tab
            label={languageContext.words.certificate_registration}
            {...selections(13)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AlarmList />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <AreaLossList />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <UserList />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <PowerBp />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <PowerContractList />
      </TabPanel>

      <TabPanel value={value} index={5}>
        <PowerBbList />
      </TabPanel>
      
      <TabPanel value={value} index={6}>
        <PowerPlantList />
      </TabPanel>

      <TabPanel value={value} index={7}>
        <SupplyBgList />
      </TabPanel>

      <TabPanel value={value} index={8}>
        <ConnectContractList />
      </TabPanel>

      <TabPanel value={value} index={9}>
        <SupplyList />
      </TabPanel>

      <TabPanel value={value} index={10}>
        <SaleList />
      </TabPanel>

      <TabPanel value={value} index={11}>
        <SaleDetailList />        
      </TabPanel>

      <TabPanel value={value} index={12}>
        <SetMarketUser />
      </TabPanel>

      <TabPanel value={value} index={13}>
        <CertificateList />
      </TabPanel>
    </div>
  );
};

export default Setting;
