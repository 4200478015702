import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { ClipboardEvent } from "react";
import { useSearchParams } from "react-router-dom";
import { format, isBefore, isSameDay, subDays } from "date-fns";
import { ja } from "date-fns/locale";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../../common/styledComponents/styledTable";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { cellTime } from "../../../common/cellTime";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import Typography from "@mui/material/Typography";
import { StyledButton } from "../../common/styledComponents/styledButton";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import SaveDataButton from "../../common/customComponents/SaveDataButton";
import TableContainer from "@mui/material/TableContainer";
import TextField from "@mui/material/TextField";
import {
  DateContext,
  GlobalDatePicker,
} from "../../common/customComponents/GlobalDatePicker";
import { LanguageContext } from "../../common/localization/localization";
import { PaletteContext } from "../../common/paletteMode";
import * as CommonType from "../../../types/common/Search";
import * as PfpType from "../../../types/occto/PowerForecastPlan";
import axios from "axios";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import { pink } from "@mui/material/colors";
import { RoleContext } from "../../common/customComponents/RoleProvider";
import { useAreaUtility } from "../../../common/area";
import { usePowerPlantUtility } from "../../../common/powerEnergy";
import { AREA_ID } from "../../../common/constant";
import { useAuthedApi } from "../../../common/axios";
import { Stack } from "@mui/system";

// 販売タブタイプ
enum GROUPNUM {
  SELF, // 自己託送
  SPOT, // スポット
  ONE_HOUR, // 1時間前
}

// API URL
// エリア一覧
const AREA_LIST_URL = "/getAreaNameList";

// データ取得
const GET_PFP_TRAN_YRL = "/getPowerForecastPlanTran"; // 取引計画
const GET_PFP_POWER_URL = "/getPowerForecastPlanPower"; // 発電計画
const GET_PFP_SALE_URL = "/getPowerForecastPlanSale"; // 販売計画
const GET_PFP_PROVIDE_URL = "/getPowerForecastPlanProvide"; // 調達計画
const GET_PFP_HEADER_URL = "/getPowerForecastPlanHeader"; // ヘッダ

// データ登録
const POST_PFP_DATA_URL = "/postPowerForecastPlan";

// ロール判定
const GET_ROLE_JUDGMENT_URL = "/getIsEditableRolePlan";

// テーブル行数
const CELLCOUNT = 48;

/**
 * 発電・販売計画画面
 * @returns
 */
const PowerForecastPlan = () => {
  // 設定言語
  const languageContext = useContext(LanguageContext);
  // グローバルカレンダー
  const dateContext = useContext(DateContext);
  // カラーモード
  const palletContextBase = useContext(PaletteContext);
  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);
  // エリアコンテキスト
  const { areaId, setAreaId } = useContext(AreaContext);
  // サーバ時刻
  const timeContext = useContext(TimeContext);
  // ログイン中ユーザのロール
  const roleContext = useContext(RoleContext);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  const powerTable = 2;
  const salesTable = 3;
  const procurementTable = 4;

  // セレクトリスト
  const [areaList, setAreaList] = useState<string[]>([]); //エリア一覧
  const { areaOptions, convertIdToAreaName } = useAreaUtility();
  const { convertIdToEnergyName } = usePowerPlantUtility();

  // 計画総計テーブル表示
  const [summaryData, setSummaryData] = useState<PfpType.pfpSummary[]>([]);

  // 取引計画列情報
  const [tranPlanItems, setTranPlanItems] = useState<
    PfpType.transactionPlanList[]
  >([]);

  // タブ別テーブル表示情報
  // 発電計画
  const [pfpPowerItems, setPfpPowerItems] = useState<PfpType.powerPlanList[]>(
    []
  );

  // 販売計画
  // 自己託送
  const [pfpSaleSelfItems, setPfpSaleSelfItems] = useState<
    PfpType.saleSelfConsignmentList[]
  >([]);
  // スポット
  const [pfpSaleSpotItems, setPfpSaleSpotItems] = useState<
    PfpType.saleAreaList[]
  >([]);
  // 1時間前
  const [pfpSaleOneHourItems, setPfpSaleOneHourItems] = useState<
    PfpType.saleAreaList[]
  >([]);

  //調達計画
  // スポット
  const [pfpProvideSpotItems, setPfpProvideSpotItems] = useState<
    PfpType.provideAreaList[]
  >(null!);
  // １時間前
  const [pfpProvideOneHourItems, setPfpProvideOneHourItems] = useState<
    PfpType.provideAreaList[]
  >(null!);

  // 描画監視
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [digOpenOkOnly, setDigOpenOkOnly] = useState<boolean>(false);
  const [digMessageOkOnly, setDigMessageOkOnly] = useState<string>("");
  const [digOpenOkCancel, setDigOpenOkCancel] = useState<boolean>(false);
  const [digMessageOkCancel, setDigMessageOkCancel] = useState<string>("");

  const [tableType, setTabletype] = useState<number>(powerTable);

  // 保存ボタンの表示・非表示
  const [hidden, setHidden] = useState<boolean>(true);

  //どのタブが選ばれているかを表す状態state
  const [selectedTabs, setSelectedTabs] = useState<boolean[]>([
    false,
    false,
    false,
  ]);

  const [lastUpdateInfo, setLastUpdateInfo] = useState<{
    date: Date;
    user: string;
  }>();

  const jepxDispFlag = React.useRef(true);

  // URLパラメータ取得
  const [serchParams] = useSearchParams();

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  // 通信エラーを監視
  const api = useAuthedApi();

  // 初期表示情報セット
  useEffect(() => {
    // URLパラメタ取得
    const areaId = serchParams.get("areaId");
    initAreaList();
    dicideSaveButtonVisibility();
    setIsLoaded(true);
    leavePageContext.setBlockLeave(false);
  }, []);

  // 計画総計テーブル表示更新
  useEffect(() => {
    if (
      summaryData === null ||
      summaryData === undefined ||
      summaryData.length === 0
    ) {
      const tempList: PfpType.pfpSummary[] = new Array(CELLCOUNT)
        .fill(true)
        .map((_, index) => ({
          timeCd: `${cellTime[index]} ～ ${cellTime[index + 1]}`,
          supply: 0,
          power: 0,
          procurement: 0,
          sale: 0,
          excessOrDeficiency: 0,
          sparePower: 0,
          possibleBudget: 0,
          demandPlan: 0,
        }));
      setSummaryData(tempList);
    }
  }, []);

  // 各タブ計画値取得
  useEffect(() => {
    if (areaId !== AREA_ID.NONE) {
      getPlans();
    }
  }, [areaId, dateContext.pickDate]);

  // 計画総計テーブル表示更新
  useEffect(() => {
    ConstantDataTable();
  }, [
    pfpPowerItems,
    pfpSaleSelfItems,
    pfpSaleSpotItems,
    pfpSaleOneHourItems,
    pfpProvideSpotItems,
    pfpProvideOneHourItems,
    ignored,
  ]);

  // ログインユーザのロールによって保存ボタンの表示・非表示を切り替える
  const dicideSaveButtonVisibility = () => {
    api
      .get(GET_ROLE_JUDGMENT_URL, { params: { userRole: roleContext.role } })
      .then((res) => {
        setHidden(res.data ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //販売計画テキストフィールド50kWh単位丸め
  const updateBlurPowerPlan = (
    event: any,
    i: number,
    j: number,
    type: number
  ) => {
    if (type === GROUPNUM.SPOT) {
      pfpSaleSpotItems[i].valueList[j].value =
        Number(Math.floor(Number(event.target.value) / 50)) * 50;
      setPfpSaleSpotItems(pfpSaleSpotItems);
    } else if (type === GROUPNUM.ONE_HOUR) {
      // 一時間前
      pfpSaleOneHourItems[i].valueList[j].value =
        Number(Math.floor(Number(event.target.value) / 50)) * 50;
      setPfpSaleOneHourItems(pfpSaleOneHourItems);
    }
    forceUpdate();
  };
  //調達計画テキストフィールド50kWh単位丸め
  const updateBlurProviderPlan = (
    event: any,
    i: number,
    j: number,
    type: number
  ) => {
    if (type === GROUPNUM.SPOT) {
      pfpProvideSpotItems[i].valueList[j].value =
        Number(Math.floor(Number(event.target.value) / 50)) * 50;
      setPfpProvideSpotItems(pfpProvideSpotItems);
    } else if (type === GROUPNUM.ONE_HOUR) {
      // 一時間前
      pfpProvideOneHourItems[i].valueList[j].value =
        Number(Math.floor(Number(event.target.value) / 50)) * 50;
      setPfpProvideOneHourItems(pfpProvideOneHourItems);
    }
    forceUpdate();
  };

  // 販売計画テキストフィールド更新
  function updateSalePlan(event: any, i: number, j: number, type: number) {
    if (type === GROUPNUM.SELF) {
      if (pfpSaleSelfItems !== null || pfpSaleSelfItems !== undefined) {
        pfpSaleSelfItems[i].valueList[j].value =
          event.target.value === "" ? "0" : event.target.value;
      }
      // 自己託送
      setPfpSaleSelfItems(pfpSaleSelfItems);
    } else if (type === GROUPNUM.SPOT) {
      // スポット
      pfpSaleSpotItems[i].valueList[j].value =
        event.target.value === "" ? "0" : event.target.value;
      setPfpSaleSpotItems(pfpSaleSpotItems);
    } else if (type === GROUPNUM.ONE_HOUR) {
      // 一時間前
      pfpSaleOneHourItems[i].valueList[j].value =
        event.target.value === "" ? "0" : event.target.value;
      setPfpSaleOneHourItems(pfpSaleOneHourItems);
    }
    forceUpdate();
  }

  function updateProviderPlan(event: any, i: number, j: number, type: number) {
    if (type === GROUPNUM.SPOT) {
      pfpProvideSpotItems[i].valueList[j].value =
        event.target.value === "" ? "0" : event.target.value;
      setPfpProvideSpotItems(pfpProvideSpotItems);
    } else {
      pfpProvideOneHourItems[i].valueList[j].value =
        event.target.value === "" ? "0" : event.target.value;
      setPfpProvideOneHourItems(pfpProvideOneHourItems);
    }
    forceUpdate();
  }

  //タブが押された際に動く関数
  const decideTabsColor = (pushedTabsNumber: number) => {
    switch (pushedTabsNumber) {
      case 0: {
        setSelectedTabs([true, false, false]);
        break;
      }
      case 1: {
        setSelectedTabs([false, true, false]);
        break;
      }
      case 2: {
        setSelectedTabs([false, false, true]);
        break;
      }
    }
  };

  // 保存ボタン押下
  const OnAccept = () => {
    if (isDivfifty()) {
      execPfpPower();
    } else {
      setDigMessageOkCancel(languageContext.words.save_check);
      setDigOpenOkCancel(true);
    }
  };

  const isDivfifty = () => {
    let flagDivFifty = true;
    const cells = new Array(CELLCOUNT).fill(0);
    //販売計画のスポット計画に50kWhで割り切れない数があるかチェック
    cells.map((cell, index) => {
      pfpSaleSpotItems.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
      //販売計画の一時間前計画に50kWhで割り切れない数があるかチェック
      pfpSaleOneHourItems.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
      //調達計画の一スポット計画に50kWhで割り切れない数があるかチェック
      pfpProvideSpotItems.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
      //調達計画の一時間前計画に50kWhで割り切れない数があるかチェック
      pfpProvideOneHourItems.map((item, i) => {
        if (Number(item.valueList[index].value) % 50 != 0) {
          flagDivFifty = false;
        }
      });
    });

    return flagDivFifty;
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  //ヘッダーデータの生成
  // エリア名
  const initAreaList = () => {
    api
      .get(AREA_LIST_URL, { params: null })
      .then((res) => {
        const areaIds = res.data.map((v: any) => v.areaId);
        setAreaList(areaIds);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  // 取引計画取得
  const getPfpTran = () => {
    const requestParam: PfpType.reqPowerForecastPlanTransaction = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    //console.log(requestParam);
    api
      .get(GET_PFP_TRAN_YRL, { params: requestParam })
      .then((res) => {
        //console.log(res.data);
        if (res.data !== undefined || res.data !== null) {
          res.data.forEach((element: PfpType.transactionPlanList) => {
            if (element.supply === null) {
              element.supply = 0;
            }
            if (element.power === null) {
              element.power = 0;
            }
            if (element.procurement === null) {
              element.procurement = 0;
            }
            if (element.sale === null) {
              element.sale = 0;
            }
            if (element.excessOrDeficiency === null) {
              element.excessOrDeficiency = 0;
            }
            if (element.sparePower === null) {
              element.sparePower = 0;
            }
            if (element.possibleBudget === null) {
              element.possibleBudget = 0;
            }
          });
          if (res.data[0].transactionPlanList.length == 0) {
            const tempList: PfpType.resPowerForecastPlanTransaction = {
              areaId: areaId,
              targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
              transactionPlanList: new Array(CELLCOUNT)
                .fill(true)
                .map((_, index) => ({
                  timeCd: (index + 1).toString().padStart(2, "0"),
                  supply: 0,
                  power: 0,
                  procurement: 0,
                  sale: 0,
                  excessOrDeficiency: 0,
                  sparePower: 0,
                  possibleBudget: 0,
                })),
            };
            setTranPlanItems(tempList.transactionPlanList);
          } else {
            setTranPlanItems(res.data[0].transactionPlanList);
          }
        } else {
          const tempList: PfpType.resPowerForecastPlanTransaction = {
            areaId: areaId,
            targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
            transactionPlanList: new Array(CELLCOUNT)
              .fill(true)
              .map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                supply: 0,
                power: 0,
                procurement: 0,
                sale: 0,
                excessOrDeficiency: 0,
                sparePower: 0,
                possibleBudget: 0,
              })),
          };
          setTranPlanItems(tempList.transactionPlanList);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 計画ヘッダ取得
  const getPfpPowerHd = () => {
    const requestParam: PfpType.reqPowerForecastPlanPower = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_PFP_HEADER_URL, { params: requestParam })
      .then((res) => {
        if (
          res.data !== undefined &&
          res.data.lastUpdate !== undefined &&
          res.data.lastUpdateUser !== undefined &&
          res.data.lastUpdate !== null &&
          res.data.lastUpdateUser !== null
        ) {
          setLastUpdateInfo({
            date: new Date(res.data.lastUpdate),
            user: res.data.lastUpdateUser,
          });
        } else {
          setLastUpdateInfo(undefined);
        }
      })
      .catch((error) => {
        setLastUpdateInfo(undefined);
        console.log(error);
      });
  };

  // 発電計画取得
  const getPfpPower = () => {
    const requestParam: PfpType.reqPowerForecastPlanPower = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    //console.log(requestParam);
    api
      .get(GET_PFP_POWER_URL, { params: requestParam })
      .then((res) => {
        //console.log(res.data);
        if (res.data !== undefined || res.data.powerPlan.length !== 0) {
          res.data.powerPlan.forEach((element: PfpType.powerPlanList) => {
            if (
              element.valueList === undefined ||
              element.valueList.length === 0
            ) {
              element.valueList = new Array(CELLCOUNT)
                .fill(true)
                .map((_, index) => ({
                  timeCd: (index + 1).toString().padStart(2, "0"),
                  value: 0,
                }));
            }
          });
          setPfpPowerItems(res.data.powerPlan);
        } else {
          const tempList: PfpType.powerPlanList = {
            powerId: 0,
            powerName: "",
            powerCategory: "",
            maxPower: 0,
            valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
              timeCd: (index + 1).toString().padStart(2, "0"),
              value: 0,
            })),
          };
          setPfpPowerItems([tempList]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 販売計画取得
  const getPfpSale = () => {
    const requestParam: PfpType.reqPowerForecastPlanSale = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_PFP_SALE_URL, { params: requestParam })
      .then((res) => {
        // 自己託送
        if (res.data !== undefined) {
            res.data.selfConsignmentList.forEach(
              (element: PfpType.saleSelfConsignmentList) => {
                if (element.valueList.length === 0) {
                  element.valueList = new Array(CELLCOUNT)
                    .fill(true)
                    .map((_, index) => ({
                      timeCd: (index + 1).toString().padStart(2, "0"),
                      value: 0,
                    }));
                }
              }
            );
            setPfpSaleSelfItems(res.data.selfConsignmentList);
          // スポット
          if (
            res.data.spotList !== undefined &&
            res.data.spotList.length !== 0
          ) {
            jepxDispFlag.current = true;
            res.data.spotList.forEach((element: PfpType.saleAreaList) => {
              if (element.valueList.length === 0) {
                element.valueList = new Array(CELLCOUNT)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
            });
            setPfpSaleSpotItems(res.data.spotList);
          } else {
            jepxDispFlag.current = false;
            const tempList: PfpType.saleAreaList = {
              areaId: "",
              //saleId: 0,
              saleGroupId: 0,
              salePlanKind: "21",
              valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                value: 0,
              })),
            };
            setPfpSaleSpotItems([tempList]);
          }

          // 1時間前
          if (
            res.data.oneHourList !== undefined &&
            res.data.oneHourList.length !== 0
          ) {
            jepxDispFlag.current = true;
            res.data.oneHourList.forEach((element: PfpType.saleAreaList) => {
              if (element.valueList.length === 0) {
                element.valueList = new Array(CELLCOUNT)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
            });
            setPfpSaleOneHourItems(res.data.oneHourList);
          } else {
            jepxDispFlag.current = false;
            const tempList: PfpType.saleAreaList = {
              areaId: "",
              //saleId: 0,
              saleGroupId: 0,
              salePlanKind: "31",
              valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                value: 0,
              })),
            };
            setPfpSaleOneHourItems([tempList]);
          }
        } else {
          // const self: PfpType.saleSelfConsignmentList = {
          //   customerName: "",
          //   //saleId: 0,
          //   saleGroupId: 0,
          //   salePlanKind: "12",
          //   demandId: 0,
          //   valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
          //     timeCd: (index + 1).toString().padStart(2, "0"),
          //     value: 0,
          //   })),
          // };
          // setPfpSaleSelfItems([self]);
          jepxDispFlag.current = false;
          const spot: PfpType.saleAreaList = {
            areaId: "",
            //saleId: 0,
            saleGroupId: 0,
            salePlanKind: "21",
            valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
              timeCd: (index + 1).toString().padStart(2, "0"),
              value: 0,
            })),
          };
          setPfpSaleSpotItems([spot]);
          const onehour: PfpType.saleAreaList = {
            areaId: "",
            //saleId: 0,
            saleGroupId: 0,
            salePlanKind: "31",
            valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
              timeCd: (index + 1).toString().padStart(2, "0"),
              value: 0,
            })),
          };
          setPfpSaleOneHourItems([onehour]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 調達計画取得
  const getPfpProvide = () => {
    const requestParam: PfpType.reqPowerForecastPlanProvide = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    api
      .get(GET_PFP_PROVIDE_URL, { params: requestParam })
      .then((res) => {
        // スポット
        if (res.data !== undefined) {
          if (
            res.data.spotList !== undefined &&
            res.data.spotList.length !== 0
          ) {
            jepxDispFlag.current = true;
            res.data.spotList.forEach((element: PfpType.provideAreaList) => {
              if (element.valueList.length === 0) {
                element.valueList = new Array(CELLCOUNT)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
            });
            setPfpProvideSpotItems(res.data.spotList);
          } else {
            jepxDispFlag.current = false;
            const tempList: PfpType.provideAreaList = {
              areaId: "",
              saleGroupId: 0,
              salePlanKind: "21",
              saleId: 0,
              valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                value: 0,
              })),
            };
            setPfpProvideSpotItems([tempList]);
          }

          // 1時間前
          if (
            res.data.oneHourList !== undefined &&
            res.data.oneHourList.length !== 0
          ) {
            jepxDispFlag.current = true;
            res.data.oneHourList.forEach((element: PfpType.provideAreaList) => {
              if (element.valueList.length === 0) {
                element.valueList = new Array(CELLCOUNT)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
            });
            setPfpProvideOneHourItems(res.data.oneHourList);
          } else {
            jepxDispFlag.current = false;
            const tempList: PfpType.provideAreaList = {
              areaId: "",
              saleId: 0,
              saleGroupId: 0,
              salePlanKind: "31",
              valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                value: 0,
              })),
            };
            setPfpProvideOneHourItems([tempList]);
          }
        } else {
          jepxDispFlag.current = false;
          const spot: PfpType.provideAreaList = {
            areaId: "",
            saleGroupId: 0,
            salePlanKind: "21",
            saleId: 0,
            valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
              timeCd: (index + 1).toString().padStart(2, "0"),
              value: 0,
            })),
          };
          setPfpProvideSpotItems([spot]);
          const onehour: PfpType.provideAreaList = {
            areaId: "",
            saleId: 0,
            saleGroupId: 0,
            salePlanKind: "31",
            valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
              timeCd: (index + 1).toString().padStart(2, "0"),
              value: 0,
            })),
          };
          setPfpProvideOneHourItems([onehour]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 計画登録
  const execPfpPower = () => {
    let index = 0;
    // 発電計画セット
    const tempPowerList: PfpType.postPowerPlanList[] = [];
    for (let i = 0; i < pfpPowerItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        tempPowerList.push({
          powerId: pfpPowerItems[i].powerId,
          timeCd: pfpPowerItems[i].valueList[j].timeCd,
          powerQu: pfpPowerItems[i].valueList[j].value as number,
          dataChgCd: pfpPowerItems[i].valueList[j].dataChgCd,
        });
      }
    }

    // 販売計画セット
    const tempSaleList: PfpType.postSalePlanList[] = [];
    if (pfpSaleSelfItems !== null || pfpSaleSelfItems !== undefined) {
      for (let i = 0; i < pfpSaleSelfItems.length; i++) {
        for (let j = 0; j < CELLCOUNT; j++) {
          const tmp: PfpType.postSalePlanList = {
            //saleId: pfpSaleSelfItems[i].saleId,
            saleGroupId: pfpSaleSelfItems[i].saleGroupId,
            salePlanKind: pfpSaleSelfItems[i].salePlanKind,
            demandId: pfpSaleSelfItems[i].demandId,
            timeCd: pfpSaleSelfItems[i].valueList[j].timeCd,
            powerQu: pfpSaleSelfItems[i].valueList[j].value as number,
            dataChgCd: pfpSaleSelfItems[i].valueList[j].dataChgCd,
          };
          // 自己託送
          tempSaleList.push(tmp);
        }
      }
    }

    for (let i = 0; i < pfpSaleSpotItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        // スポット
        const tmp: PfpType.postSalePlanList = {
          //saleId: pfpSaleSpotItems[i].saleId,
          saleGroupId: pfpSaleSpotItems[i].saleGroupId,
          salePlanKind: pfpSaleSpotItems[i].salePlanKind,
          demandId: 0,
          timeCd: pfpSaleSpotItems[i].valueList[j].timeCd,
          powerQu: pfpSaleSpotItems[i].valueList[j].value as number,
          dataChgCd: pfpSaleSpotItems[i].valueList[j].dataChgCd,
        };
        tempSaleList.push(tmp);
      }
    }

    for (let i = 0; i < pfpSaleOneHourItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        // 1時間前
        const tmp: PfpType.postSalePlanList = {
          //saleId: pfpSaleOneHourItems[i].saleId,
          saleGroupId: pfpSaleOneHourItems[i].saleGroupId,
          salePlanKind: pfpSaleOneHourItems[i].salePlanKind,
          demandId: 0,
          timeCd: pfpSaleOneHourItems[i].valueList[j].timeCd,
          powerQu: pfpSaleOneHourItems[i].valueList[j].value as number,
          dataChgCd: pfpSaleOneHourItems[i].valueList[j].dataChgCd,
        };
        tempSaleList.push(tmp);
      }
    }

    // 調達計画セット
    index = 0;
    const tempProvideList: PfpType.postProvidePlanList[] = [];
    for (let i = 0; i < pfpProvideSpotItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        // スポット
        const tmp: PfpType.postProvidePlanList = {
          saleId: pfpProvideSpotItems[i].saleId,
          saleGroupId: pfpProvideSpotItems[i].saleGroupId,
          salePlanKind: pfpProvideSpotItems[i].salePlanKind,
          timeCd: pfpProvideSpotItems[i].valueList[j].timeCd,
          powerQu: pfpProvideSpotItems[i].valueList[j].value as number,
          dataChgCd: pfpProvideSpotItems[i].valueList[j].dataChgCd,
        };
        tempProvideList.push(tmp);
      }
    }

    for (let i = 0; i < pfpProvideOneHourItems.length; i++) {
      for (let j = 0; j < CELLCOUNT; j++) {
        // 1時間前
        const tmp: PfpType.postProvidePlanList = {
          saleId: pfpProvideOneHourItems[i].saleId,
          saleGroupId: pfpProvideOneHourItems[i].saleGroupId,
          salePlanKind: pfpProvideOneHourItems[i].salePlanKind,
          timeCd: pfpProvideOneHourItems[i].valueList[j].timeCd,
          powerQu: pfpProvideOneHourItems[i].valueList[j].value as number,
          dataChgCd: pfpProvideOneHourItems[i].valueList[j].dataChgCd,
        };
        tempProvideList.push(tmp);
      }
    }

    // 取引計画セット
    index = 0;
    const tempTransaction: PfpType.postTransactionPlanList[] = [];
    for (let i = 0; i < CELLCOUNT; i++) {
      const tmp: PfpType.postTransactionPlanList = {
        timeCd: (index + 1).toString().padStart(2, "0"),
        supply: summaryData[i].supply as number,
        power: summaryData[i].power as number,
        procurement: summaryData[i].procurement as number,
        sale: summaryData[i].sale as number,
        excessOrDeficiency: summaryData[i].excessOrDeficiency as number,
        sparePower: summaryData[i].sparePower as number,
        possibleBudget: summaryData[i].possibleBudget as number,
      };
      tempTransaction.push(tmp);
      index++;
    }

    const requestParam: PfpType.reqPostPowerForecastPlan = {
      areaId: areaId,
      targetDate: format(dateContext.pickDate as Date, "yyy/MM/dd"),
      powerPlanList: tempPowerList,
      salePlanList: tempSaleList,
      providePlanList: tempProvideList,
      transactionList: tempTransaction,
    };
    api
      .post(POST_PFP_DATA_URL, requestParam)
      .then((res) => {
        // setMessage(
        //   `保存日時 (最終保存者)：${res.data.date} (${res.data.user})`
        // ); // 保存日、保存者の表示
        setDigOpenOkOnly(true);
        if(res.data.duplicated === true) {
          setDigMessageOkOnly(languageContext.words.saved_dupicated);          
        } else {
          setDigMessageOkOnly(languageContext.words.note_saved);
        }

        leavePageContext.setBlockLeave(false);

        //不整合通知箇所取得用に再取得
        getPlans();
      })
      .catch((error) => {
        // setError(error);
        setDigMessageOkOnly(languageContext.words.note_not_saved);
        setDigOpenOkOnly(true);
        console.log(error);
      });
  };

  // テーブルヘッダ定義
  const TABLE_DEFINE = [
    { label: languageContext.words.surviceForce, key: "surviceForce" },
    { label: languageContext.words.powerforecast, key: "powerGeneration" },
    { label: languageContext.words.procurement_plan, key: "procurement_plan" },
    { label: languageContext.words.sales_plan, key: "sales_plan" },
    {
      label: languageContext.words.excess_or_deficiency,
      key: "deficiency",
    },
    { label: languageContext.words.reserveForce, key: "reserveForce" },
    {
      label: languageContext.words.sellable_quantity,
      key: "sellable_quantity",
    },
  ];

  //現在時刻をコマの番号に変換
  const convertTimeToCd = (time: Date) => {
    console.log(time.toLocaleTimeString().slice(0, -3).padStart(5, "0"));
    let timeCd = 0;
    cellTime.forEach((value, index) => {
      if (
        time.toLocaleTimeString().slice(0, -3).padStart(5, "0") >= value &&
        index !== 48
      ) {
        timeCd = index;
      }
    });
    return timeCd;
  };

  //saletype --スポットの時に当日だったら非活性にする用
  const isDisabled = (index: number, saletype: number) => {
    const timeCd = convertTimeToCd(timeContext.time);
    //スポット
    if (saletype == 1) {
      if (
        format(timeContext.time, "yyyy-MM-dd") >
          format(dateContext.pickDate as Date, "yyyy-MM-dd") ||
        format(timeContext.time, "yyyy-MM-dd") ===
          format(dateContext.pickDate as Date, "yyyy-MM-dd")
      ) {
        return true;
      }
      return false;
    } else {
      //スポット以外
      if (
        format(timeContext.time, "yyyy-MM-dd") >
          format(dateContext.pickDate as Date, "yyyy-MM-dd") ||
        (format(timeContext.time, "yyyy-MM-dd") ===
          format(dateContext.pickDate as Date, "yyyy-MM-dd") &&
          timeCd + 2 >= index)
      ) {
        return true;
      }
      return false;
    }
  };

  //不整合表示用フラグ
  const isMismatch = (chgCd?: string): boolean => {
    return Boolean(chgCd == "2");
  };

  const getPlans = () => {
    getPfpTran(); // 取引計画
    getPfpPowerHd(); // 発電ヘッダ
    getPfpPower(); // 発電計画
    getPfpSale(); // 販売計画
    getPfpProvide(); // 調達計画
  };

  /**
   * 自動計算部分のテーブルの構成
   * @returns
   */
  const ConstantDataTable = () => {
    if (
      pfpPowerItems === undefined ||
      pfpPowerItems === null ||
      pfpProvideSpotItems === undefined ||
      pfpProvideSpotItems === null ||
      pfpProvideOneHourItems === undefined ||
      pfpProvideOneHourItems === null ||
      pfpSaleSelfItems === undefined ||
      pfpSaleSelfItems === null ||
      pfpSaleSpotItems === undefined ||
      pfpSaleSpotItems === null ||
      pfpSaleOneHourItems === undefined ||
      pfpSaleOneHourItems === null ||
      tranPlanItems === undefined ||
      tranPlanItems === null ||
      tranPlanItems.length === 0
    ) {
      return;
    }

    const tempList: PfpType.pfpSummary[] = [];
    let tempExcessOrDeficiency: number;
    for (let index = 0; index < CELLCOUNT; index++) {
      // 過不足計算
      tempExcessOrDeficiency =
        pfpPowerItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) +
        pfpProvideSpotItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) +
        pfpProvideOneHourItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) -
        (pfpSaleSelfItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ) +
          pfpSaleSpotItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          pfpSaleOneHourItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ));

      const temp: PfpType.pfpSummary = {
        // 時刻
        timeCd: cellTime[index] + "～" + cellTime[index + 1],
        // 供給力
        // Σ発電計画 + Σ調達計画
        supply:
          pfpPowerItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          pfpProvideSpotItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          pfpProvideOneHourItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ),
        // 発電計画
        // Σ右表の発電計画値
        power: pfpPowerItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ),
        // 調達計画
        // Σ右表の調達計画値
        procurement:
          pfpProvideSpotItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          pfpProvideOneHourItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ),
        // 販売計画
        // Σエリア販売計画値 + Σ右表の販売計画値
        sale:
          pfpSaleSelfItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          pfpSaleSpotItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ) +
          pfpSaleOneHourItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ),
        // 過不足
        // Σ発電計画 + Σ調達計画 - Σ販売計画
        excessOrDeficiency: tempExcessOrDeficiency,
        // 発電余力
        // Σ最大発電可能量 - Σ発電計画
        sparePower:
          pfpPowerItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.maxPower)),
            0
          ) -
          pfpPowerItems.reduce(
            (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
            0
          ),
        // 販売可能量
        // INT（（供給力－販売計画の残量）／50）*50
        possibleBudget: Math.floor(tempExcessOrDeficiency / 50) * 50,
        // 需要計画(販売計画の自己託送より転写)
        demandPlan: pfpSaleSelfItems.reduce(
          (sum, i) => sum + Number(checkNumber(i.valueList[index].value)),
          0
        ),
      };
      tempList.push(temp);
    }
    setSummaryData(tempList);
  };

  const checkNumber = (val: number | undefined) => {
    if (val == undefined) {
      return 0;
    } else {
      return val;
    }
  };

  //線の色
  const changeBordercolor = () => {
    let borderColor : string;
    
    if(PaletteMode === "dark"){
      borderColor = "1px solid #808080";
    } else {
      borderColor =  "1px solid #d3d3d3";
    }

    return borderColor;
  }

  /**
   * 左側 テーブルの構成
   * @returns
   */
  const ConstantTableRender = () => {
    return (
      <StyledTable
        sx={{
          width: "50%",
          height: "100%",
          position: "sticky",
          left: 0,
          zIndex: 1,
          borderCollapse: "separate",
        }}
        style={{ borderRightWidth: "2px", borderTop: "none", borderBottom: "none" }}
      >
        <TableHead
          sx={{
            display: "block",
            //background: "rgb(220,230,241)",
            position: "sticky",
            top: 0,
            zIndex: 1,
            height: 138,
            borderTop: changeBordercolor(),
          }}
        >
          <TableRow 
            sx={{
              width: "100%",
              height: "100%",
              display:"flex"
            }}
          >
            <StyledTableHeaderLG
              align="center"
              sx={{ 
                width: "75px", 
                display:"flex", 
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {languageContext.words.timeB}
            </StyledTableHeaderLG>
            <TableRow sx={{display:"block",}}>
              <StyledTableHeaderLG 
                align="center" 
                sx={{display:"block"}}
                >
                {languageContext.words.power_gen_contractor}
              </StyledTableHeaderLG>
              <TableRow sx={{display: "flex"}}>
                {TABLE_DEFINE.map((data, index) => (
                <StyledTableHeaderLG
                  key={index + "constant"}
                  align="center"
                  sx={{ 
                    display: "flex",
                    padding: 0,
                    width: 92,
                    height: 80,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  title={data.label}
                >
                  <Box sx={{
                    overflow: "hidden", 
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}>
                  {data.label}
                  <br />
                  (kWh)
                  </Box>
                </StyledTableHeaderLG>
                ))}                
              </TableRow>
            </TableRow>
            <StyledTableHeaderLG
                align="center"
                sx=
                {{ 
                  width: "108px", 
                  display: "flex", 
                  alignItems:"center",
                  justifyContent:"center",
                }}
                style={{ borderRight: "none"}}
              >
                {languageContext.words.demand_plan}
                <br />
                (kWh)
              </StyledTableHeaderLG>
          </TableRow>
        </TableHead>
        <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "0px",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "0px",
              },
            }}
          >
            {summaryData.map((value, index) => (
              <TableRow 
                key={"powerForecastPlan_1" + index} 
                sx={{
                  display: "flex"
                }}
              >
                <StyledTableCell
                  key={"powerForecastData_2" + index + "_time"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 42,
                    width: 107,
                    padding: 0,
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.timeCd} {/* 時刻 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_3" + index + "_supply"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.supply.toLocaleString()} {/* 供給力 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_4" + index + "_power"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",                    
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.power.toLocaleString()} {/* 発電計画 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_5" + index + "_sale"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.procurement.toLocaleString()} {/* 調達計画 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_6" + index + "_provide"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.sale.toLocaleString()} {/* 販売計画 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_7" + index + "_unmatch"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.excessOrDeficiency.toLocaleString()} {/* 過不足 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_8" + index + "_reserve"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.sparePower.toLocaleString()} {/* 発電余力 */}
                </StyledTableCell>
                <StyledTableCell
                  key={"powerForecastData_9" + index + "_deal"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 84,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {value.possibleBudget.toLocaleString()} {/* 販売可能量 */}
                </StyledTableCell>
              
                <StyledTableCell
                  key={"powerForecastData_10" + index + "_demandPlan"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: 42,
                    width: 132,
                    padding: "0px 8px 0px 0px",
                    backgroundColor: isDisabled(index, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                  style={{ borderRight: "none" }}
                >
                  {value.demandPlan.toLocaleString()} {/* 需要計画 */}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </ScrollSyncPane>
      </StyledTable>
    );
  };

  /**
   * 右側 発電計画テーブル構成
   * @returns
   */
  const PowerTableRender = () => {
    // データ返ってくる順番が悪いとpfpPowerItems undefinedで落ちるので直したい
    return (
      <>
        {pfpPowerItems.length === 0 ||
        pfpPowerItems === undefined ||
        areaId === AREA_ID.NONE ? null : (
          <StyledTable
            sx={{
             // display: "block",
              width: "auto",
              height: "100%",
              position: "sticky",
              left: 0,
              zIndex: 0,
              borderCollapse: "separate",
            }}
            style={{ borderRight: "none", borderTop: "none", borderBottom: "none"}}
          >
            <TableHead
              sx={{
                display: "block",
                background: "rgb(220,230,241)",
                borderTop: changeBordercolor(),
                position: "sticky",
                top: 0,
                zIndex: 1,
                height: 138,
              }}
            >
              <TableRow>
                {pfpPowerItems.map((val, index) => {
                  return (
                    <>
                      <StyledTableHeaderLG
                        key={"powerData_1" + index + "_areaName"}
                        sx={{ height: 56, padding: 0, width: 124 }}
                        align="center"
                        // colSpan={pfpPowerItems.length}
                      >
                        {convertIdToEnergyName(val.powerCategory)}
                        {/* 電源名称 */}
                      </StyledTableHeaderLG>
                    </>
                  );
                })}
              </TableRow>
              <TableRow sx={{display:"flex"}}>
                {pfpPowerItems.map((val, index) => {
                  return (
                    <>
                      <StyledTableHeaderLG
                        key={"powerData_2" + index + "_areaName"}
                        sx={{
                          display: "flex", 
                          height: 80,
                          padding: 0, 
                          width: 124, 
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        title={val.powerName}
                        align="center"
                      >
                        <Box sx={{
                          overflow: "hidden", 
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}>
                        {val.powerName === undefined || val.powerName === ""
                          ? ""
                          : val.powerName}{" "}
                        <br />
                        (kWh)
                        {/* 発電所名称 */}
                        </Box>
                      </StyledTableHeaderLG>
                    </>
                  );
                })}
              </TableRow>
            </TableHead>
            {PowerDataTable()}
          </StyledTable>
        )}
      </>
    );
  };

  // 発電計画テキストフィールド更新
  function updatePowerPlan(event: any, index: number) {
    setPfpPowerItems(
      pfpPowerItems.map((item, i) => (index === i ? event.target.value : item))
    );
    forceUpdate();
  }

  //発電販売調達計画のテキストフィールド入力値6桁制限判定
  function isMaxLength(value: string) {
    if (value.length > 6) {
      return false;
    } else {
      return true;
    }
  }

  //テキストフィールドに想定しない値がぺーストされてしまわないように制限
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData.getData("text/plain");
    if (!pastedText) {
      return;
    }
    // text を正規表現で分割して配列にする
    const textArray = pastedText.split(/ |\u3000/);
    textArray.forEach((item) => {
      // 数字以外がマッチしたらペーストできない
      if (!item.match(/^[0-9]*$/)) {
        e.preventDefault();
      }
    });
  };

  // 発電計画データテーブル構成
  const PowerDataTable = () => {
    //console.log(pfpPowerItems);
    const cells = new Array(CELLCOUNT).fill(0);
    return (
      <>
      <ScrollSyncPane group="vertical">
          <TableBody
            sx={{
              display: "block",
              overflow: "scroll",
              "&::-webkit-scrollbar": {
                width: "0px",
                backgroundColor: "transparent",
                height: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#AAA",
                borderRadius: "10px",
              },
            }}
          >
            {cells.map((cell, i) => {
              return (
                <TableRow key={i + "powerData"}>
                  {pfpPowerItems.map((item, j) => {
                    return (
                      <StyledTableCell
                        key={"powerData_3" + j + "_areaName"}
                        sx={{
                          height: 42,
                          padding: "0px",
                          width: 124,
                          backgroundColor: isDisabled(i, 0)
                            ? PaletteMode === "dark"
                              ? "rgb(90,90,90)"
                              : "rgb(200,200,200)"
                            : "",
                        }}
                        align="center"
                      >
                        <TextField
                          name="power"
                          sx={{ margin: "2px" }}
                          InputProps={{
                            inputProps: {
                              min: 0,
                              style: {
                                padding: 0,
                                height: "30px",
                                textAlign: "right",
                                backgroundColor: isDisabled(i, 0)
                                  ? PaletteMode === "dark"
                                    ? "rgb(90,90,90)"
                                    : "rgb(200,200,200)"
                                  : "",
                              },
                            },
                          }}
                          error={isMismatch(item.valueList[i].dataChgCd)}
                          disabled={isDisabled(i, 0)}
                          type="number"
                          onFocus={(e) => {
                            if (Number(item.valueList[i].value) === 0) {
                              e.currentTarget.select();
                            }
                          }}
                          value={Math.floor(
                            Number(checkNumber(item.valueList[i].value))
                          ).toFixed(0)}
                          onKeyDown={(event) => {
                            if (event.key === "." || event.key === "-") {
                              event.preventDefault();
                            }
                          }}
                          onPaste={handlePaste} //負の値などのペースト制限
                          onChange={(e) => {
                            if (isMaxLength(e.target.value)) {
                              item.valueList[i].value = Math.floor(
                                Number(e.target.value)
                              );
                            }
                            setPfpPowerItems(pfpPowerItems);
                            // updatePowerPlan(e, j, item);
                            leavePageContext.setBlockLeave(true);
                            forceUpdate();
                          }}
                        />
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </ScrollSyncPane>
      </>
    );
  };

  /**
   * 右側 販売計画テーブル構成
   * @returns
   */
  const SalesTableRender = () => {
    const SALEGROUP = [
      languageContext.words.internal_self_consignment,
      languageContext.words.spot,
      languageContext.words.one_hour_ago,
    ];
    return (
      <Box sx={{ display: "flex", width: "auto", zIndex: "0" }}>
        {/* 域内自己託送 */}
        {pfpSaleSelfItems === null ||
        pfpSaleSelfItems === undefined ||
        pfpSaleSelfItems.length === 0 ||
        areaId === AREA_ID.NONE ? null : (
          <StyledTable
            stickyHeader
            sx={{
              padding: 0,
            }}
            style={{borderRight: jepxDispFlag.current === false ? "" : "none", width: "auto", borderTop: "none", borderBottom: "none"}}
          >
            <TableHead
              sx={{
                display: "block",
                background: "rgb(220,230,241)",
                position: "sticky",
                top: 0,
                zIndex: 1,
                height: 138,
                borderTop: changeBordercolor(),
              }}
            >
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 41,
                    width: 124,
                  }}
                  colSpan={pfpSaleSelfItems.length + 1}
                  style={{ borderRight: "none"}}
                >
                  {SALEGROUP[GROUPNUM.SELF]} {/* 域内自己託送 */}
                </StyledTableHeaderLG>
              </TableRow>
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 41,
                    width: 124,
                  }}
                  colSpan={pfpSaleSelfItems.length + 1}
                  style={{ borderRight: "none" }}
                >
                  {languageContext.words.self_consignment}
                </StyledTableHeaderLG>
              </TableRow>
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 53,
                    width: 124,
                  }}
                >
                  {languageContext.words.subtotal} {/* 計 */}
                  <br />
                  (kWh)
                </StyledTableHeaderLG>
                {pfpSaleSelfItems.map((item, index) => {
                  return (
                    <>
                      <StyledTableHeaderLG
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 0,
                          height: 53,
                          width: 124,
                        }}
                        style={{ 
                          borderRight: "none",
                        }}
                      >
                        <Box sx ={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}>
                        {item.customerName}
                        <br />
                        (kWh)
                        </Box>
                      </StyledTableHeaderLG>
                    </>
                  );
                })}
              </TableRow>
            </TableHead>
            {SalesDataTable(pfpSaleSelfItems, GROUPNUM.SELF)}
          </StyledTable>
        )}
        {/* スポット */}
        {jepxDispFlag.current === false ||
        pfpSaleSpotItems.length === 0 ||
        pfpSaleSpotItems === undefined ||
        areaId === AREA_ID.NONE ? null : (
          <StyledTable
            stickyHeader
            sx={{
              padding: 0,
            }}
            style={{ borderRight: "none", width: "auto", borderTop: "none", borderBottom: "none", }}
          >
            <TableHead
              sx={{
                display: "block",
                height: 138,
                position: "sticky",
                top: 0,
                zIndex: "1",
                borderTop: changeBordercolor(),
              }}
            >
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 41,
                    width: 124,
                  }}
                  colSpan={pfpSaleSpotItems.length + 1}
                  style={{ borderRight: "none" }}
                >
                  {SALEGROUP[GROUPNUM.SPOT]}
                </StyledTableHeaderLG>
              </TableRow>
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 41,
                    width: 124,
                  }}
                  colSpan={pfpSaleSpotItems.length + 1}
                  style={{ borderRight: "none" }}
                >
                  {languageContext.words.exchanges}
                </StyledTableHeaderLG>
              </TableRow>
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 53,
                    width: 124,
                  }}
                >
                  {languageContext.words.subtotal} {/* 計 */}
                  <br />
                  (kWh)
                </StyledTableHeaderLG>
                {pfpSaleSpotItems.map((item, index) => {
                  return (
                    <StyledTableHeaderLG
                      key={index + "salesSpot"}
                      sx={{
                        textAlign: "center",
                        padding: 0,
                        height: 53,
                        width: 124,
                      }}
                      style={{ borderRight: "none" }}
                    >
                      {convertIdToAreaName(item.areaId)}
                      <br />
                      (kWh)
                    </StyledTableHeaderLG>
                  );
                })}
              </TableRow>
            </TableHead>
            {SalesDataTable(pfpSaleSpotItems, GROUPNUM.SPOT)}
          </StyledTable>
        )}
        {/* 時間前 */}
        {jepxDispFlag.current === false ||
        pfpSaleOneHourItems.length === 0 ||
        pfpSaleOneHourItems === undefined ||
        areaId === AREA_ID.NONE ? null : (
          <StyledTable
            stickyHeader
            sx={{
              padding: 0,
            }}
            style={{ width: "auto", borderTop: "none", borderBottom: "none", }}
          >
            <TableHead
              sx={{
                display: "block",
                height: 138,
                position: "sticky",
                top: 0,
                zIndex: "1",
                borderTop: changeBordercolor(),
              }}
            >
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 41,
                    width: 124,
                    borderRight: "",
                  }}
                  colSpan={pfpSaleOneHourItems.length + 1}
                  style={{ borderRight: "none" }}
                >
                  {SALEGROUP[GROUPNUM.ONE_HOUR]}
                </StyledTableHeaderLG>
              </TableRow>
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 41,
                    width: 124,
                  }}
                  colSpan={pfpSaleOneHourItems.length + 1}
                  style={{ borderRight: "none" }}
                >
                  {languageContext.words.exchanges}
                </StyledTableHeaderLG>
              </TableRow>
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 53,
                    width: 124,
                  }}
                >
                  {languageContext.words.subtotal} {/* 計 */}
                  <br />
                  (kWh)
                </StyledTableHeaderLG>
                {pfpSaleOneHourItems.map((item, index) => {
                  return (
                    <StyledTableHeaderLG
                      key={index + "salesHour"}
                      sx={{
                        textAlign: "center",
                        padding: 0,
                        height: 53,
                        width: 124,
                      }}
                      style={{ borderRight: "none" }}
                    >
                      {convertIdToAreaName(item.areaId)}
                      <br />
                      (kWh)
                    </StyledTableHeaderLG>
                  );
                })}
              </TableRow>
            </TableHead>
            {SalesDataTable(pfpSaleOneHourItems, GROUPNUM.ONE_HOUR)}
          </StyledTable>
        )}
      </Box>
    );
  };

  // 販売計画データテーブル構成
  const SalesDataTable = (salesItems: any[], saleType: number) => {
    const cells = new Array(CELLCOUNT).fill(0);
    //console.log(salesItems);
    return (
      <ScrollSyncPane group="vertical">
        <TableBody
          sx={{
            display: "block",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "0px",
              backgroundColor: "transparent",
              height: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "0px",
            },
          }}
        >
          {cells.map((cell, row) => {
            return (
              <TableRow key={row}>
                {/* 計 */}
                <StyledTableCell
                  key={"salesData_1" + row + "_areaName"}
                  sx={{
                    height: 42,
                    padding: "0px 8px 0px 0px",
                    width: 116,
                    backgroundColor: isDisabled(row, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {Math.floor(
                    salesItems.reduce(
                      (sum, val) =>
                        sum + Number(checkNumber(val.valueList[row].value)),
                      0
                    )
                  ).toLocaleString()}
                </StyledTableCell>
                {salesItems.map((item, col) => {
                  {
                    /* 入力フィールド */
                  }
                  return (
                    <StyledTableCell
                      key={"salesData_2" + col + "_areaName"}
                      sx={{
                        height: 42,
                        padding: "0px",
                        width: 124,
                        backgroundColor: isDisabled(row, 0)
                          ? PaletteMode === "dark"
                            ? "rgb(90,90,90)"
                            : "rgb(200,200,200)"
                          : "",
                      }}
                      align="right"
                      style={{ borderRight: "none" }}
                    >
                      <TextField
                        name="sale"
                        sx={{ margin: "2px" }}
                        inputProps={{
                          style: {
                            padding: 0,
                            height: "30px",
                            textAlign: "right",
                            backgroundColor: isDisabled(row, 0)
                              ? PaletteMode === "dark"
                                ? "rgb(90,90,90)"
                                : "rgb(200,200,200)"
                              : "",
                          },
                          min: 0,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          step: saleType === GROUPNUM.SELF ? 1 : 50,
                        }}
                        error={isMismatch(item.valueList[row].dataChgCd)}
                        disabled={isDisabled(row, saleType)}
                        type="number"
                        onKeyDown={(event) => {
                          if (event.key === "." || event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                        onFocus={(e) => {
                          if (Number(item.valueList[row].value) === 0) {
                            e.currentTarget.select();
                          }
                        }}
                        onBlur={(e) => {
                          updateBlurPowerPlan(e, col, row, saleType);
                        }}
                        value={Math.floor(
                          checkNumber(item.valueList[row].value)
                        ).toFixed(0)}
                        onChange={(e) => {
                          if (isMaxLength(e.target.value)) {
                            updateSalePlan(e, col, row, saleType);
                          }
                          leavePageContext.setBlockLeave(true);
                        }}
                      />
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </ScrollSyncPane>
    );
  };

  // 調達計画テーブル構成
  const ProviderTableRender = () => {
    const PROVIDEGROUP = [
      languageContext.words.internal_other_bg,
      languageContext.words.spot,
      languageContext.words.one_hour_ago,
    ];
    return (
      <Box sx={{ display: "flex", width: "auto", zIndex: "0" }}>
        {/* スポット */}
        {jepxDispFlag.current === false ||
        pfpProvideSpotItems.length === 0 ||
        pfpProvideSpotItems === undefined ||
        areaId === AREA_ID.NONE ? null : (
          <StyledTable
            stickyHeader
            sx={{
              padding: 0,
            }}
            style={{ borderRight: "none", borderTop: "none", borderBottom: "none", }}
          >
            <TableHead
              sx={{
                display: "block",
                height: 138,
                position: "sticky",
                top: 0,
                zIndex: "1",
                borderTop: changeBordercolor(),
              }}
            >
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 41,
                    width: 124,
                  }}
                  colSpan={pfpProvideSpotItems.length + 1}
                  style={{ borderRight: "none" }}
                >
                  {PROVIDEGROUP[GROUPNUM.SPOT]} {/* スポット */}
                </StyledTableHeaderLG>
              </TableRow>
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 41,
                    width: 124,
                  }}
                  colSpan={pfpProvideSpotItems.length + 1}
                  style={{ borderRight: "none" }}
                >
                  {languageContext.words.exchanges} {/* スポット */}
                </StyledTableHeaderLG>
              </TableRow>
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 53,
                    width: 124,
                  }}
                >
                  {languageContext.words.subtotal} {/* 計 */}
                  <br />
                  (kWh)
                </StyledTableHeaderLG>
                {pfpProvideSpotItems.map((item, index) => {
                  return (
                    <StyledTableHeaderLG
                      key={index + "procurementSpot"}
                      sx={{
                        textAlign: "center",
                        padding: 0,
                        height: 53,
                        width: 124,
                      }}
                      style={{ borderRight: "none" }}
                    >
                      {convertIdToAreaName(item.areaId)}
                      <br />
                      (kWh)
                    </StyledTableHeaderLG>
                  );
                })}
              </TableRow>
            </TableHead>
            {ProviderDataTable(GROUPNUM.SPOT)}
          </StyledTable>
        )}
        {/* 時間前 */}
        {jepxDispFlag.current === false ||
        pfpProvideOneHourItems.length === 0 ||
        pfpProvideOneHourItems === undefined ||
        areaId === AREA_ID.NONE ? null : (
          <StyledTable
            stickyHeader
            sx={{
              padding: 0,
            }}
            style={{borderTop: "none", borderBottom:"none", }}
          >
            <TableHead
              sx={{
                display: "block",
                height: 138,
                position: "sticky",
                top: 0,
                zIndex: "1",
                borderTop: changeBordercolor(),
              }}
            >
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 41,
                    width: 124,
                  }}
                  colSpan={pfpProvideOneHourItems.length + 1}
                  style={{ borderRight: "none" }}
                >
                  {PROVIDEGROUP[GROUPNUM.ONE_HOUR]} {/* 1時間前 */}
                </StyledTableHeaderLG>
              </TableRow>
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 41,
                    width: 124,
                  }}
                  colSpan={pfpProvideOneHourItems.length + 1}
                  style={{ borderRight: "none" }}
                >
                  {languageContext.words.exchanges}
                </StyledTableHeaderLG>
              </TableRow>
              <TableRow>
                <StyledTableHeaderLG
                  sx={{
                    textAlign: "center",
                    padding: 0,
                    height: 53,
                    width: 124,
                  }}
                >
                  {languageContext.words.subtotal} {/* 計 */}
                  <br />
                  (kWh)
                </StyledTableHeaderLG>
                {pfpProvideOneHourItems.map((item, index) => {
                  return (
                    <StyledTableHeaderLG
                      key={index + "provideHour"}
                      sx={{
                        textAlign: "center",
                        padding: 0,
                        height: 53,
                        width: 124,
                      }}
                      style={{ borderRight: "none" }}
                    >
                      {convertIdToAreaName(item.areaId)}
                      <br />
                      (kWh)
                    </StyledTableHeaderLG>
                  );
                })}
              </TableRow>
            </TableHead>
            {ProviderDataTable(GROUPNUM.ONE_HOUR)}
          </StyledTable>
        )}
      </Box>
    );
  };

  // 調達計画データテーブル構成
  const ProviderDataTable = (providerType: number) => {
    const cells = new Array(CELLCOUNT).fill(0);
    let provideItems = new Array(CELLCOUNT);

    if (providerType === GROUPNUM.SPOT) {
      provideItems = pfpProvideSpotItems.slice();
    } else if (providerType === GROUPNUM.ONE_HOUR) {
      provideItems = pfpProvideOneHourItems.slice();
    }

    return (
      <ScrollSyncPane group="vertical">
        <TableBody
          sx={{
            display: "block",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "0px",
              backgroundColor: "transparent",
              height: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "0px",
            },
          }}
        >
          {cells.map((cell, row) => {
            return (
              <TableRow key={row + "procurementRow"}>
                {/* 計 */}
                <StyledTableCell
                  key={"procurement_1" + row + "_areaName"}
                  sx={{
                    height: 42,
                    padding: "0px 8px 0px 0px",
                    width: 116,
                    backgroundColor: isDisabled(row, 0)
                      ? PaletteMode === "dark"
                        ? "rgb(90,90,90)"
                        : "rgb(200,200,200)"
                      : "",
                  }}
                  align="right"
                >
                  {Math.floor(
                    provideItems.reduce(
                      (sum, val) =>
                        sum + Number(checkNumber(val.valueList[row].value)),
                      0
                    )
                  ).toLocaleString()}
                </StyledTableCell>
                {/* 入力フィールド */}
                {provideItems.map((item, col) => {
                  return (
                    <StyledTableCell
                      key={"procurement_2" + col + "_areaName"}
                      sx={{
                        height: 42,
                        padding: "0px",
                        width: 124,
                        backgroundColor: isDisabled(row, 0)
                          ? PaletteMode === "dark"
                            ? "rgb(90,90,90)"
                            : "rgb(200,200,200)"
                          : "",
                      }}
                      align="right"
                      style={{ borderRight: "none" }}
                    >
                      <TextField
                        name="provide"
                        sx={{ margin: "2px" }}
                        inputProps={{
                          style: {
                            padding: 0,
                            height: "30px",
                            textAlign: "right",
                            backgroundColor: isDisabled(row, 0)
                              ? PaletteMode === "dark"
                                ? "rgb(90,90,90)"
                                : "rgb(200,200,200)"
                              : "",
                          },
                          min: 0,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          step: 50,
                        }}
                        error={isMismatch(item.valueList[row].dataChgCd)}
                        disabled={isDisabled(row, providerType)}
                        type="number"
                        onKeyDown={(event) => {
                          if (event.key === "." || event.key === "-") {
                            event.preventDefault();
                          }
                        }}
                        onFocus={(e) => {
                          if (Number(item.valueList[row].value) === 0) {
                            e.currentTarget.select();
                          }
                        }}
                        onBlur={(e) => {
                          updateBlurProviderPlan(e, col, row, providerType);
                        }}
                        value={Math.floor(
                          Number(checkNumber(item.valueList[row].value))
                        ).toFixed(0)}
                        onChange={(e) => {
                          if (isMaxLength(e.target.value)) {
                            updateProviderPlan(e, col, row, providerType);
                          }
                          leavePageContext.setBlockLeave(true);
                        }}
                      />
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </ScrollSyncPane>
    );
  };

  const TableRender = (): JSX.Element => {
    if (tableType === powerTable) {
      return PowerTableRender();
    } else if (tableType === salesTable) {
      return SalesTableRender();
    } else {
      return ProviderTableRender();
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <div
      //ダークモード対応
      className={`cn-main-display ${PaletteMode === "dark" ? "dark" : "light"}`}
    >
      <Box sx={{ display: "flex", width: "100%", alignItems: "center"}}>
        <Box sx={{ display: "flex", width: "50%", alignItems: "center"}}>
          <Box sx={{ width: "200px", margin: "4px" }}>
            <CustomSelectList
              value={areaId}
              options={areaOptions(areaList, false)}
              onChange={setAreaId}
            />
          </Box>
          <GlobalDatePicker isDipsTodayBtn={true} />
          <ButtonGroup
            aria-label="outlined primary button group"
            sx={{
              marginLeft: "30px",
              height: "40px",
              whiteSpace: "nowrap",
            }}
          >
            <StyledButton
              onClick={() => {
                setTabletype(powerTable);
                decideTabsColor(0);
              }}
              style={{
                backgroundColor:
                  tableType === powerTable ? "rgba(25, 118, 210, 0.25)" : "",
              }}
            >
              {languageContext.words.power_gen_plan}
            </StyledButton>
            <StyledButton
              onClick={() => {
                setTabletype(salesTable);
                decideTabsColor(1);
              }}
              style={{
                backgroundColor:
                  tableType === salesTable ? "rgba(25, 118, 210, 0.25)" : "",
              }}
            >
              {languageContext.words.sales_plan}
            </StyledButton>
            <StyledButton
              onClick={() => {
                setTabletype(procurementTable);
                decideTabsColor(2);
              }}
              style={{
                backgroundColor:
                  tableType === procurementTable
                    ? "rgba(25, 118, 210, 0.25)"
                    : "",
              }}
            >
              {languageContext.words.procurement_plan}
            </StyledButton>
          </ButtonGroup>
        </Box>
        <Box 
          sx={{
            height: "40px",
            display: "flex",
            width: "50%",
            justifyContent: "flex-end",
            marginRight: "40px"
          }}
        >
          <Typography
            sx={{
              color: PaletteMode === "dark" ? "lightgray" : "gray",
              width: "660px",
              height: "40px",
              display: "flex",
              alignItems: "center"
            }}
            // align="right"
          >
            {lastUpdateInfo
              ? `${languageContext.words.last_update_info}
              : ${languageContext.convertDateToLocalizedString(
                lastUpdateInfo.date,
                "HH:mm:ss"
              )}
              (${lastUpdateInfo.user})`
              : ""}
          </Typography>
          <SaveDataButton
            hidden={hidden}
            disabled={
              areaId === AREA_ID.NONE ||
              hidden ||
              isBefore(
                new Date(format(dateContext.pickDate as Date, "yyyy/MM/dd")),
                new Date(format(timeContext.time as Date, "yyyy/MM/dd"))
              )
                ? true
                : false
            }
            message={languageContext.words.note_saved}
            onAccept={OnAccept}
          />
        </Box>
      </Box>
      <ScrollSync>
        <TableContainer
          sx={{
            display: "flexed",
            height: "90%",
            backgroundColor: "#00000000",
            width: "auto",
            overflow: "auto",
            margin: "10px",
            marginTop: "15px",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor:
                PaletteMode === "dark" ? "#5e5e5e" : "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: PaletteMode === "dark" ? "white" : "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          <Box sx={{ display: "flex", backgroundColor: "#00000000" }}>
            {ConstantTableRender()}
            {TableRender()}
          </Box>
        </TableContainer>
      </ScrollSync>
      <CustomDialog
        title={languageContext.words.powerforecastplan}
        message={digMessageOkOnly}
        buttonType={ButtonType.OkOnly}
        open={digOpenOkOnly}
        onAccept={acceptHandler}
        onClose={() => setDigOpenOkOnly(false)}
      />
      <CustomDialog
        title={languageContext.words.powerforecastplan}
        message={digMessageOkCancel}
        buttonType={ButtonType.OkCancel}
        open={digOpenOkCancel}
        onAccept={execPfpPower} //OK押下後の挙動
        onClose={() => setDigOpenOkCancel(false)}
      />
    </div>
  );
};

export default PowerForecastPlan;
