import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DateContext } from "../../common/customComponents/GlobalDatePicker";
import { format } from "date-fns/fp";
import { LanguageContext } from "../../common/localization/localization";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";

//時間表示用フォーマット
const formatDateYYYYMMdd = format("yyyy/MM/dd");
const formatDateMMddYYYY = format("MM/dd/yyyy");

// 入札取消ボタンが押された時のモーダルダイアログに表示するコンポーネント
const CancelBid = (props: any) => {
  const { areaName, bpName } = props;
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  //日付のデータコンテキスト
  const selectedDate = useContext(DateContext);

  return (
    <Box sx={{ width: "500px", height: "130px", display: "flex" }}>
      <Stack direction={"column"} spacing={1.5}>
        <Typography gutterBottom>
          {languageContext.words.power_producer}：{bpName}
        </Typography>
        <Typography gutterBottom>
          {languageContext.words.area}：{areaName}
        </Typography>
        <Typography gutterBottom>
          {languageContext.words.bidding_date_and_time}：
          {languageContext.convertDateToLocalizedStringPoorAcu(
            selectedDate.pickDate as Date
            // new Date()
          )}
        </Typography>
        <Typography gutterBottom>
          {languageContext.words.note_bid_cancel}
        </Typography>
      </Stack>
    </Box>
  );
};

export default CancelBid;
