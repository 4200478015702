import { Box, TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import styled from "styled-components";
import { LanguageContext } from "../../../common/localization/localization";

// マスタ大テキストフィールド
export const CustomTextFieldBig = styled(TextField)(() => {
  const languageContext = useContext(LanguageContext);
  return (
  {
  width: "542px",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
  ".MuiFormHelperText-root": {
    color: "red",
    overflow: languageContext.mode === "jp" ? "visible" : "hidden",
  },
  })});

// マスタ小テキストフィールド
export const CustomTextFieldSmall = styled(TextField)(() => ({
  width: "267px",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
  ".MuiFormHelperText-root": {
    color: "red",
  },
}));
