//１時間前入札のテスト用データ

//市場状態
type createMarketSituationData = {
  name: string; //データ名
  sell_bid_volume: number; //売り入札量
  sell_cheapest_price: number; //売り最安地
  buy_bid_volume: number; //買い入札量
  buy_cheapest_price: number; //買い最高値
  latest_execution_price: number; //最新約定価格
  average_execution_price: number; //平均約定価格
};

//自社状況
type createCompanySituationData = {
  name: string; //データ名
  sales_plan: number; //販売計画
  procurement_plan: number; //調達計画
  sell_bid_volume: number; //売り入札量
  sell_contract_amount: number; //売り約定量
  buy_bid_volume: number; //買い入札量
  buy_contract_amount: number; //買い約定量
};

//入札状況
type createBidData = {
  name: string; //データ名
  trading_type: string; //売買種別
  bid_amount: number; //入札量
  contract_amount: number; //約定量
  bid_price: number; //入札価格
  contract_price: number; //約定価格
  execution_status: string; //約定状況
};

//自動入札設定
type createAutomationSettingsData = {
  deadlineTime: string; //入札締め切り時間
  deleteTime: string; //自動札削除時間
  startTime: string; //自動札開始時間
  adjustmentCycle: string; //調整周期
  adjustmentAmount: number; //調整量
};

//1時間前入札制限設定
type createBidLimitData = {
  name: string; //データ名
  sellingPrice_min: number; //販売価格_下限
  sellingPrice_max: number; //販売価格_上限
  salesVolume_min: number; //販売量_下限
  salesVolume_max: number; //販売量_上限
  purchasePrice_min: number; //買取価格_下限
  purchasePrice_max: number; //買取価格_上限
  purchaseAmount_min: number; //買取量_下限
  purchaseAmount_max: number; //買取量_上限
};

//日ごとのデータ
export type CreateDataByDay = {
  date: string;
  market_situation_data: createMarketSituationData[]; //市場状態
  company_Situation_Data: createCompanySituationData[]; //自社状況
  bid_data: createBidData[]; //入札状況
};

//エリアごとのデータ
export type createAreaData = {
  areaName: string; //エリア名
  date_data: CreateDataByDay[]; //日ごとのデータ
  automationSettings_Data: createAutomationSettingsData; //自動入札設定
  bidLimit_data: createBidLimitData[]; //1時間前入札制限設定
};

//モック用テストデータ
export const testData: createAreaData[] = [
  {
    areaName: "東京",
    date_data: [
      {
        date: "2023/03/14",
        market_situation_data: [
          {
            name: "data1",
            sell_bid_volume: 20101600,
            sell_cheapest_price: 10.86,
            buy_bid_volume: 21956500,
            buy_cheapest_price: 17.13,
            latest_execution_price: 16.36,
            average_execution_price: 15.42,
          },
          {
            name: "data2",
            sell_bid_volume: 22433150,
            sell_cheapest_price: 13.44,
            buy_bid_volume: 22789050,
            buy_cheapest_price: 16.36,
            latest_execution_price: 16.16,
            average_execution_price: 15.64,
          },
          {
            name: "data3",
            sell_bid_volume: 22827500,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 22988050,
            buy_cheapest_price: 16.62,
            latest_execution_price: 16.52,
            average_execution_price: 14.34,
          },
          {
            name: "data4",
            sell_bid_volume: 23305600,
            sell_cheapest_price: 9.91,
            buy_bid_volume: 23235350,
            buy_cheapest_price: 16.7,
            latest_execution_price: 14.4,
            average_execution_price: 15.74,
          },
          {
            name: "data5",
            sell_bid_volume: 23372150,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 23483100,
            buy_cheapest_price: 17.63,
            latest_execution_price: 15.9,
            average_execution_price: 14.95,
          },
          {
            name: "data6",
            sell_bid_volume: 23320500,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 23742950,
            buy_cheapest_price: 17.6,
            latest_execution_price: 15.96,
            average_execution_price: 14.36,
          },
          {
            name: "data7",
            sell_bid_volume: 23348900,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 23748300,
            buy_cheapest_price: 18.83,
            latest_execution_price: 17.63,
            average_execution_price: 15.81,
          },
          {
            name: "data8",
            sell_bid_volume: 23533300,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 23671400,
            buy_cheapest_price: 18.73,
            latest_execution_price: 17.64,
            average_execution_price: 16.45,
          },
          {
            name: "data9",
            sell_bid_volume: 23644600,
            sell_cheapest_price: 9.86,
            buy_bid_volume: 23625850,
            buy_cheapest_price: 18.53,
            latest_execution_price: 17.81,
            average_execution_price: 16.38,
          },
          {
            name: "data10",
            sell_bid_volume: 23511800,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 23430300,
            buy_cheapest_price: 18.06,
            latest_execution_price: 16.55,
            average_execution_price: 15.81,
          },
          {
            name: "data11",
            sell_bid_volume: 23622700,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 23118300,
            buy_cheapest_price: 18.97,
            latest_execution_price: 16.37,
            average_execution_price: 15.78,
          },
          {
            name: "data12",
            sell_bid_volume: 23682250,
            sell_cheapest_price: 11.6,
            buy_bid_volume: 22872700,
            buy_cheapest_price: 19.0,
            latest_execution_price: 17.09,
            average_execution_price: 16.07,
          },
          {
            name: "data13",
            sell_bid_volume: 22561450,
            sell_cheapest_price: 11.6,
            buy_bid_volume: 21950450,
            buy_cheapest_price: 18.75,
            latest_execution_price: 17.0,
            average_execution_price: 15.62,
          },
          {
            name: "data14",
            sell_bid_volume: 22349800,
            sell_cheapest_price: 10.98,
            buy_bid_volume: 22414400,
            buy_cheapest_price: 18.4,
            latest_execution_price: 17.31,
            average_execution_price: 16.52,
          },
          {
            name: "data15",
            sell_bid_volume: 22260400,
            sell_cheapest_price: 11.82,
            buy_bid_volume: 22949700,
            buy_cheapest_price: 18.67,
            latest_execution_price: 17.54,
            average_execution_price: 17.29,
          },
          {
            name: "data16",
            sell_bid_volume: 22436400,
            sell_cheapest_price: 14.47,
            buy_bid_volume: 23680600,
            buy_cheapest_price: 18.72,
            latest_execution_price: 18.17,
            average_execution_price: 17.65,
          },
          {
            name: "data17",
            sell_bid_volume: 23076850,
            sell_cheapest_price: 7.31,
            buy_bid_volume: 24968000,
            buy_cheapest_price: 20.0,
            latest_execution_price: 19.1,
            average_execution_price: 18.18,
          },
          {
            name: "data18",
            sell_bid_volume: 23240850,
            sell_cheapest_price: 1.92,
            buy_bid_volume: 25307100,
            buy_cheapest_price: 20.0,
            latest_execution_price: 19.9,
            average_execution_price: 17.66,
          },
          {
            name: "data19",
            sell_bid_volume: 24199350,
            sell_cheapest_price: 17.72,
            buy_bid_volume: 25668750,
            buy_cheapest_price: 19.0,
            latest_execution_price: 18.22,
            average_execution_price: 18.27,
          },
          {
            name: "data20",
            sell_bid_volume: 25306350,
            sell_cheapest_price: 13.97,
            buy_bid_volume: 25617700,
            buy_cheapest_price: 17.64,
            latest_execution_price: 15.89,
            average_execution_price: 17.46,
          },
          {
            name: "data21",
            sell_bid_volume: 26044200,
            sell_cheapest_price: 13.36,
            buy_bid_volume: 25771350,
            buy_cheapest_price: 17.39,
            latest_execution_price: 17.18,
            average_execution_price: 16.63,
          },
          {
            name: "data22",
            sell_bid_volume: 27059150,
            sell_cheapest_price: 13.36,
            buy_bid_volume: 25640200,
            buy_cheapest_price: 17.2,
            latest_execution_price: 15.94,
            average_execution_price: 15.79,
          },
          {
            name: "data23",
            sell_bid_volume: 27737050,
            sell_cheapest_price: 13.0,
            buy_bid_volume: 25030000,
            buy_cheapest_price: 15.97,
            latest_execution_price: 15.97,
            average_execution_price: 15.5,
          },
          {
            name: "data24",
            sell_bid_volume: 28605800,
            sell_cheapest_price: 12.01,
            buy_bid_volume: 24721300,
            buy_cheapest_price: 15.22,
            latest_execution_price: 15.01,
            average_execution_price: 14.13,
          },
          {
            name: "data25",
            sell_bid_volume: 29053800,
            sell_cheapest_price: 6.25,
            buy_bid_volume: 24435450,
            buy_cheapest_price: 15.0,
            latest_execution_price: 13.49,
            average_execution_price: 13.74,
          },
          {
            name: "data26",
            sell_bid_volume: 29808600,
            sell_cheapest_price: 6.25,
            buy_bid_volume: 24038600,
            buy_cheapest_price: 15.0,
            latest_execution_price: 13.0,
            average_execution_price: 12.48,
          },
          {
            name: "data27",
            sell_bid_volume: 29641600,
            sell_cheapest_price: 6.25,
            buy_bid_volume: 24048950,
            buy_cheapest_price: 15.9,
            latest_execution_price: 13.71,
            average_execution_price: 13.26,
          },
          {
            name: "data28",
            sell_bid_volume: 29332650,
            sell_cheapest_price: 5.0,
            buy_bid_volume: 23742900,
            buy_cheapest_price: 15.59,
            latest_execution_price: 14.01,
            average_execution_price: 13.73,
          },
          {
            name: "data29",
            sell_bid_volume: 28750550,
            sell_cheapest_price: 6.25,
            buy_bid_volume: 23479200,
            buy_cheapest_price: 15.62,
            latest_execution_price: 15.2,
            average_execution_price: 14.11,
          },
          {
            name: "data30",
            sell_bid_volume: 28014000,
            sell_cheapest_price: 10.0,
            buy_bid_volume: 23192600,
            buy_cheapest_price: 15.52,
            latest_execution_price: 15.52,
            average_execution_price: 14.18,
          },
          {
            name: "data31",
            sell_bid_volume: 27150750,
            sell_cheapest_price: 6.25,
            buy_bid_volume: 22552200,
            buy_cheapest_price: 16.44,
            latest_execution_price: 13.81,
            average_execution_price: 13.35,
          },
          {
            name: "data32",
            sell_bid_volume: 25808050,
            sell_cheapest_price: 6.25,
            buy_bid_volume: 22256700,
            buy_cheapest_price: 17.12,
            latest_execution_price: 15.65,
            average_execution_price: 14.44,
          },
          {
            name: "data33",
            sell_bid_volume: 25057150,
            sell_cheapest_price: 10.01,
            buy_bid_volume: 22443400,
            buy_cheapest_price: 16.81,
            latest_execution_price: 16.81,
            average_execution_price: 16.0,
          },
          {
            name: "data34",
            sell_bid_volume: 23413850,
            sell_cheapest_price: 13.97,
            buy_bid_volume: 22242200,
            buy_cheapest_price: 17.89,
            latest_execution_price: 16.27,
            average_execution_price: 17.01,
          },
          {
            name: "data35",
            sell_bid_volume: 22343850,
            sell_cheapest_price: 14.0,
            buy_bid_volume: 22847450,
            buy_cheapest_price: 17.89,
            latest_execution_price: 17.87,
            average_execution_price: 17.38,
          },
          {
            name: "data36",
            sell_bid_volume: 21799300,
            sell_cheapest_price: 15.84,
            buy_bid_volume: 23602750,
            buy_cheapest_price: 19.64,
            latest_execution_price: 19.13,
            average_execution_price: 18.83,
          },
          {
            name: "data37",
            sell_bid_volume: 22619050,
            sell_cheapest_price: 17.0,
            buy_bid_volume: 24890400,
            buy_cheapest_price: 19.79,
            latest_execution_price: 17.75,
            average_execution_price: 18.32,
          },
          {
            name: "data38",
            sell_bid_volume: 22953300,
            sell_cheapest_price: 17.0,
            buy_bid_volume: 24827500,
            buy_cheapest_price: 18.5,
            latest_execution_price: 17.54,
            average_execution_price: 17.92,
          },
          {
            name: "data39",
            sell_bid_volume: 23301850,
            sell_cheapest_price: 16.29,
            buy_bid_volume: 24919800,
            buy_cheapest_price: 18.71,
            latest_execution_price: 17.24,
            average_execution_price: 17.82,
          },
          {
            name: "data40",
            sell_bid_volume: 23645400,
            sell_cheapest_price: 15.36,
            buy_bid_volume: 24929250,
            buy_cheapest_price: 18.41,
            latest_execution_price: 17.83,
            average_execution_price: 17.55,
          },
          {
            name: "data41",
            sell_bid_volume: 23607800,
            sell_cheapest_price: 14.5,
            buy_bid_volume: 24780550,
            buy_cheapest_price: 18.59,
            latest_execution_price: 17.78,
            average_execution_price: 17.37,
          },
          {
            name: "data42",
            sell_bid_volume: 23724450,
            sell_cheapest_price: 14.77,
            buy_bid_volume: 24670400,
            buy_cheapest_price: 18.68,
            latest_execution_price: 17.64,
            average_execution_price: 17.4,
          },
          {
            name: "data43",
            sell_bid_volume: 23779750,
            sell_cheapest_price: 11.6,
            buy_bid_volume: 24134300,
            buy_cheapest_price: 18.78,
            latest_execution_price: 17.1,
            average_execution_price: 16.02,
          },
          {
            name: "data44",
            sell_bid_volume: 23761800,
            sell_cheapest_price: 16.84,
            buy_bid_volume: 23708500,
            buy_cheapest_price: 17.49,
            latest_execution_price: 17.45,
            average_execution_price: 17.3,
          },
          {
            name: "data45",
            sell_bid_volume: 23332300,
            sell_cheapest_price: 12.46,
            buy_bid_volume: 23331800,
            buy_cheapest_price: 18.17,
            latest_execution_price: 17.45,
            average_execution_price: 17.36,
          },
          {
            name: "data46",
            sell_bid_volume: 23093500,
            sell_cheapest_price: 12.46,
            buy_bid_volume: 22769300,
            buy_cheapest_price: 17.08,
            latest_execution_price: 17.08,
            average_execution_price: 16.59,
          },
          {
            name: "data47",
            sell_bid_volume: 22969050,
            sell_cheapest_price: 13.19,
            buy_bid_volume: 22476100,
            buy_cheapest_price: 17.59,
            latest_execution_price: 16.65,
            average_execution_price: 16.64,
          },
          {
            name: "data48",
            sell_bid_volume: 22154400,
            sell_cheapest_price: 12.2,
            buy_bid_volume: 20894800,
            buy_cheapest_price: 17.59,
            latest_execution_price: 16.62,
            average_execution_price: 16.39,
          },
        ],
        company_Situation_Data: [
          {
            name: "data1",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data2",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data3",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data4",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data5",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data6",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data7",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data8",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data9",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data10",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data11",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data12",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data13",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data14",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data15",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data16",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data17",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data18",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data19",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data20",
            sales_plan: 0.3,
            procurement_plan: 0.0,
            sell_bid_volume: 0.3,
            sell_contract_amount: 0.3,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data21",
            sales_plan: 0.35,
            procurement_plan: 0.0,
            sell_bid_volume: 0.35,
            sell_contract_amount: 0.35,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data22",
            sales_plan: 0.25,
            procurement_plan: 0.0,
            sell_bid_volume: 0.25,
            sell_contract_amount: 0.25,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data23",
            sales_plan: 0.45,
            procurement_plan: 0.0,
            sell_bid_volume: 0.45,
            sell_contract_amount: 0.45,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data24",
            sales_plan: 0.75,
            procurement_plan: 0.0,
            sell_bid_volume: 0.75,
            sell_contract_amount: 0.75,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data25",
            sales_plan: 0.4,
            procurement_plan: 0.0,
            sell_bid_volume: 0.4,
            sell_contract_amount: 0.4,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data26",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data27",
            sales_plan: 0.25,
            procurement_plan: 0.0,
            sell_bid_volume: 0.25,
            sell_contract_amount: 0.25,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data28",
            sales_plan: 0.5,
            procurement_plan: 0.0,
            sell_bid_volume: 0.5,
            sell_contract_amount: 0.5,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data29",
            sales_plan: 0.15,
            procurement_plan: 0.0,
            sell_bid_volume: 0.15,
            sell_contract_amount: 0.15,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data30",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data31",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data32",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data33",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data34",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data35",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data36",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data37",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data38",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data39",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data40",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data41",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data42",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data43",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data44",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data45",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data46",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data47",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data48",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
        ],
        bid_data: [
          {
            name: "data1",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data2",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data3",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data4",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data5",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data6",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data7",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data8",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data9",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data10",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data11",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data12",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data13",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data14",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data15",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data16",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 18.0,
            contract_price: 18.07,
            execution_status: "約定中",
          },
          {
            name: "data17",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 18.0,
            contract_price: 18.33,
            execution_status: "約定中",
          },
          {
            name: "data18",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 17.0,
            contract_price: 17.87,
            execution_status: "約定中",
          },
          {
            name: "data19",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 18.0,
            contract_price: 18.79,
            execution_status: "約定中",
          },
          {
            name: "data20",
            trading_type: "売り",
            bid_amount: 0.3,
            contract_amount: 0.3,
            bid_price: 17.0,
            contract_price: 17.84,
            execution_status: "約定中",
          },
          {
            name: "data21",
            trading_type: "売り",
            bid_amount: 0.35,
            contract_amount: 0.35,
            bid_price: 17.0,
            contract_price: 17.13,
            execution_status: "約定中",
          },
          {
            name: "data22",
            trading_type: "売り",
            bid_amount: 0.25,
            contract_amount: 0.25,
            bid_price: 16.0,
            contract_price: 16.48,
            execution_status: "約定中",
          },
          {
            name: "data23",
            trading_type: "売り",
            bid_amount: 0.45,
            contract_amount: 0.45,
            bid_price: 16.0,
            contract_price: 16.27,
            execution_status: "約定中",
          },
          {
            name: "data24",
            trading_type: "売り",
            bid_amount: 0.75,
            contract_amount: 0.75,
            bid_price: 14.0,
            contract_price: 14.43,
            execution_status: "約定中",
          },
          {
            name: "data25",
            trading_type: "売り",
            bid_amount: 0.4,
            contract_amount: 0.4,
            bid_price: 14.0,
            contract_price: 14.17,
            execution_status: "約定中",
          },
          {
            name: "data26",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 12.0,
            contract_price: 12.59,
            execution_status: "約定中",
          },
          {
            name: "data27",
            trading_type: "売り",
            bid_amount: 0.25,
            contract_amount: 0.25,
            bid_price: 13.0,
            contract_price: 13.85,
            execution_status: "約定中",
          },
          {
            name: "data28",
            trading_type: "売り",
            bid_amount: 0.5,
            contract_amount: 0.5,
            bid_price: 13.0,
            contract_price: 13.92,
            execution_status: "約定中",
          },
          {
            name: "data29",
            trading_type: "売り",
            bid_amount: 0.15,
            contract_amount: 0.15,
            bid_price: 14.0,
            contract_price: 14.57,
            execution_status: "約定中",
          },
          {
            name: "data30",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 14.0,
            contract_price: 14.37,
            execution_status: "約定中",
          },
          {
            name: "data31",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 13.0,
            contract_price: 13.54,
            execution_status: "約定中",
          },
          {
            name: "data32",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 15.0,
            contract_price: 15.14,
            execution_status: "約定中",
          },
          {
            name: "data33",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 16.0,
            contract_price: 16.74,
            execution_status: "約定中",
          },
          {
            name: "data34",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 17.0,
            contract_price: 17.67,
            execution_status: "約定中",
          },
          {
            name: "data35",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 17.0,
            contract_price: 17.58,
            execution_status: "約定中",
          },
          {
            name: "data36",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data37",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data38",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data39",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data40",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data41",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data42",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data43",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data44",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data45",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data46",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data47",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data48",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
        ],
      },
      {
        date: "2023/03/15",
        market_situation_data: [
          {
            name: "data1",
            sell_bid_volume: 21983200,
            sell_cheapest_price: 13.96,
            buy_bid_volume: 22017150,
            buy_cheapest_price: 16.72,
            latest_execution_price: 13.96,
            average_execution_price: 15.87,
          },
          {
            name: "data2",
            sell_bid_volume: 23476850,
            sell_cheapest_price: 11.6,
            buy_bid_volume: 22140300,
            buy_cheapest_price: 16.0,
            latest_execution_price: 11.6,
            average_execution_price: 15.2,
          },
          {
            name: "data3",
            sell_bid_volume: 24289350,
            sell_cheapest_price: 13.26,
            buy_bid_volume: 22086000,
            buy_cheapest_price: 16.84,
            latest_execution_price: 14.3,
            average_execution_price: 15.53,
          },
          {
            name: "data4",
            sell_bid_volume: 24568150,
            sell_cheapest_price: 14.17,
            buy_bid_volume: 22140750,
            buy_cheapest_price: 17.7,
            latest_execution_price: 14.17,
            average_execution_price: 15.95,
          },
          {
            name: "data5",
            sell_bid_volume: 24748700,
            sell_cheapest_price: 14.17,
            buy_bid_volume: 22236100,
            buy_cheapest_price: 17.6,
            latest_execution_price: 17.6,
            average_execution_price: 16.22,
          },
          {
            name: "data6",
            sell_bid_volume: 24995900,
            sell_cheapest_price: 14.17,
            buy_bid_volume: 22213600,
            buy_cheapest_price: 17.6,
            latest_execution_price: 17.6,
            average_execution_price: 16.23,
          },
          {
            name: "data7",
            sell_bid_volume: 25049500,
            sell_cheapest_price: 14.03,
            buy_bid_volume: 22156700,
            buy_cheapest_price: 17.0,
            latest_execution_price: 15.5,
            average_execution_price: 16.01,
          },
          {
            name: "data8",
            sell_bid_volume: 25091950,
            sell_cheapest_price: 14.4,
            buy_bid_volume: 22117200,
            buy_cheapest_price: 17.75,
            latest_execution_price: 14.4,
            average_execution_price: 16.14,
          },
          {
            name: "data9",
            sell_bid_volume: 25014150,
            sell_cheapest_price: 14.37,
            buy_bid_volume: 22148000,
            buy_cheapest_price: 17.6,
            latest_execution_price: 16.79,
            average_execution_price: 16.14,
          },
          {
            name: "data10",
            sell_bid_volume: 24772150,
            sell_cheapest_price: 13.73,
            buy_bid_volume: 22283600,
            buy_cheapest_price: 18.0,
            latest_execution_price: 16.77,
            average_execution_price: 16.09,
          },
          {
            name: "data11",
            sell_bid_volume: 24114200,
            sell_cheapest_price: 12.46,
            buy_bid_volume: 22267850,
            buy_cheapest_price: 17.72,
            latest_execution_price: 15.71,
            average_execution_price: 16.64,
          },
          {
            name: "data12",
            sell_bid_volume: 23616150,
            sell_cheapest_price: 11.6,
            buy_bid_volume: 22790400,
            buy_cheapest_price: 18.0,
            latest_execution_price: 17.25,
            average_execution_price: 16.26,
          },
          {
            name: "data13",
            sell_bid_volume: 23139750,
            sell_cheapest_price: 13.01,
            buy_bid_volume: 23534350,
            buy_cheapest_price: 19.1,
            latest_execution_price: 17.81,
            average_execution_price: 17.4,
          },
          {
            name: "data14",
            sell_bid_volume: 22854300,
            sell_cheapest_price: 13.8,
            buy_bid_volume: 24360250,
            buy_cheapest_price: 20.0,
            latest_execution_price: 18.59,
            average_execution_price: 18.55,
          },
          {
            name: "data15",
            sell_bid_volume: 23439600,
            sell_cheapest_price: 17.01,
            buy_bid_volume: 25303300,
            buy_cheapest_price: 20.0,
            latest_execution_price: 18.68,
            average_execution_price: 18.87,
          },
          {
            name: "data16",
            sell_bid_volume: 24471450,
            sell_cheapest_price: 13.67,
            buy_bid_volume: 25444800,
            buy_cheapest_price: 19.25,
            latest_execution_price: 18.15,
            average_execution_price: 17.79,
          },
          {
            name: "data17",
            sell_bid_volume: 26692100,
            sell_cheapest_price: 16.82,
            buy_bid_volume: 26435700,
            buy_cheapest_price: 18.7,
            latest_execution_price: 17.67,
            average_execution_price: 17.66,
          },
          {
            name: "data18",
            sell_bid_volume: 28421550,
            sell_cheapest_price: 16.5,
            buy_bid_volume: 26714500,
            buy_cheapest_price: 18.62,
            latest_execution_price: 16.6,
            average_execution_price: 17.1,
          },
          {
            name: "data19",
            sell_bid_volume: 30091300,
            sell_cheapest_price: 15.5,
            buy_bid_volume: 27038700,
            buy_cheapest_price: 16.86,
            latest_execution_price: 15.75,
            average_execution_price: 16.1,
          },
          {
            name: "data20",
            sell_bid_volume: 32106300,
            sell_cheapest_price: 9.1,
            buy_bid_volume: 26276600,
            buy_cheapest_price: 17.19,
            latest_execution_price: 13.31,
            average_execution_price: 14.63,
          },
          {
            name: "data21",
            sell_bid_volume: 33830500,
            sell_cheapest_price: 1.1,
            buy_bid_volume: 26309150,
            buy_cheapest_price: 17.48,
            latest_execution_price: 14.79,
            average_execution_price: 13.7,
          },
          {
            name: "data22",
            sell_bid_volume: 35241050,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 26028950,
            buy_cheapest_price: 16.48,
            latest_execution_price: 11.68,
            average_execution_price: 10.79,
          },
          {
            name: "data23",
            sell_bid_volume: 36602250,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 25937000,
            buy_cheapest_price: 15.0,
            latest_execution_price: 9.65,
            average_execution_price: 10.31,
          },
          {
            name: "data24",
            sell_bid_volume: 37476300,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 25742400,
            buy_cheapest_price: 15.0,
            latest_execution_price: 3.0,
            average_execution_price: 7.82,
          },
          {
            name: "data25",
            sell_bid_volume: 37930000,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 24559650,
            buy_cheapest_price: 12.17,
            latest_execution_price: 12.17,
            average_execution_price: 4.1,
          },
          {
            name: "data26",
            sell_bid_volume: 38111450,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 24335050,
            buy_cheapest_price: 12.15,
            latest_execution_price: 12.05,
            average_execution_price: 4.09,
          },
          {
            name: "data27",
            sell_bid_volume: 37574550,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 25063350,
            buy_cheapest_price: 13.59,
            latest_execution_price: 6.49,
            average_execution_price: 5.24,
          },
          {
            name: "data28",
            sell_bid_volume: 36900350,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 25006800,
            buy_cheapest_price: 13.71,
            latest_execution_price: 10.56,
            average_execution_price: 5.82,
          },
          {
            name: "data29",
            sell_bid_volume: 35349150,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 24760450,
            buy_cheapest_price: 13.9,
            latest_execution_price: 12.0,
            average_execution_price: 6.58,
          },
          {
            name: "data30",
            sell_bid_volume: 33044650,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 24043500,
            buy_cheapest_price: 16.13,
            latest_execution_price: 0.01,
            average_execution_price: 8.13,
          },
          {
            name: "data31",
            sell_bid_volume: 31669050,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 22930200,
            buy_cheapest_price: 13.15,
            latest_execution_price: 5.11,
            average_execution_price: 6.98,
          },
          {
            name: "data32",
            sell_bid_volume: 29629700,
            sell_cheapest_price: 1.9,
            buy_bid_volume: 23074750,
            buy_cheapest_price: 16.19,
            latest_execution_price: 2.0,
            average_execution_price: 12.76,
          },
          {
            name: "data33",
            sell_bid_volume: 27237600,
            sell_cheapest_price: 5.0,
            buy_bid_volume: 23542400,
            buy_cheapest_price: 16.93,
            latest_execution_price: 15.23,
            average_execution_price: 12.54,
          },
          {
            name: "data34",
            sell_bid_volume: 25116700,
            sell_cheapest_price: 13.68,
            buy_bid_volume: 24196200,
            buy_cheapest_price: 18.4,
            latest_execution_price: 16.52,
            average_execution_price: 16.71,
          },
          {
            name: "data35",
            sell_bid_volume: 24363000,
            sell_cheapest_price: 17.21,
            buy_bid_volume: 24912150,
            buy_cheapest_price: 18.61,
            latest_execution_price: 17.51,
            average_execution_price: 17.54,
          },
          {
            name: "data36",
            sell_bid_volume: 23943950,
            sell_cheapest_price: 16.6,
            buy_bid_volume: 25609900,
            buy_cheapest_price: 19.7,
            latest_execution_price: 18.49,
            average_execution_price: 18.52,
          },
          {
            name: "data37",
            sell_bid_volume: 24266450,
            sell_cheapest_price: 17.93,
            buy_bid_volume: 25951950,
            buy_cheapest_price: 19.74,
            latest_execution_price: 18.53,
            average_execution_price: 18.59,
          },
          {
            name: "data38",
            sell_bid_volume: 24348700,
            sell_cheapest_price: 18.26,
            buy_bid_volume: 26064050,
            buy_cheapest_price: 19.78,
            latest_execution_price: 18.57,
            average_execution_price: 18.62,
          },
          {
            name: "data39",
            sell_bid_volume: 24344300,
            sell_cheapest_price: 17.62,
            buy_bid_volume: 25833800,
            buy_cheapest_price: 19.35,
            latest_execution_price: 18.14,
            average_execution_price: 18.19,
          },
          {
            name: "data40",
            sell_bid_volume: 24438750,
            sell_cheapest_price: 17.65,
            buy_bid_volume: 25715900,
            buy_cheapest_price: 19.35,
            latest_execution_price: 18.25,
            average_execution_price: 18.15,
          },
          {
            name: "data41",
            sell_bid_volume: 23996700,
            sell_cheapest_price: 17.83,
            buy_bid_volume: 25021650,
            buy_cheapest_price: 19.04,
            latest_execution_price: 17.94,
            average_execution_price: 17.88,
          },
          {
            name: "data42",
            sell_bid_volume: 24158150,
            sell_cheapest_price: 17.59,
            buy_bid_volume: 24692650,
            buy_cheapest_price: 18.79,
            latest_execution_price: 17.73,
            average_execution_price: 17.74,
          },
          {
            name: "data43",
            sell_bid_volume: 24192100,
            sell_cheapest_price: 17.42,
            buy_bid_volume: 24275750,
            buy_cheapest_price: 18.01,
            latest_execution_price: 17.42,
            average_execution_price: 17.62,
          },
          {
            name: "data44",
            sell_bid_volume: 24475600,
            sell_cheapest_price: 17.0,
            buy_bid_volume: 23984150,
            buy_cheapest_price: 17.8,
            latest_execution_price: 17.27,
            average_execution_price: 17.27,
          },
          {
            name: "data45",
            sell_bid_volume: 24164450,
            sell_cheapest_price: 12.69,
            buy_bid_volume: 23715700,
            buy_cheapest_price: 18.43,
            latest_execution_price: 17.23,
            average_execution_price: 16.68,
          },
          {
            name: "data46",
            sell_bid_volume: 24627350,
            sell_cheapest_price: 11.6,
            buy_bid_volume: 23365200,
            buy_cheapest_price: 17.18,
            latest_execution_price: 16.08,
            average_execution_price: 14.28,
          },
          {
            name: "data47",
            sell_bid_volume: 24130750,
            sell_cheapest_price: 11.6,
            buy_bid_volume: 22783350,
            buy_cheapest_price: 16.39,
            latest_execution_price: 15.9,
            average_execution_price: 13.93,
          },
          {
            name: "data48",
            sell_bid_volume: 24107900,
            sell_cheapest_price: 11.6,
            buy_bid_volume: 22072250,
            buy_cheapest_price: 17.02,
            latest_execution_price: 15.34,
            average_execution_price: 14.0,
          },
        ],
        company_Situation_Data: [
          {
            name: "data1",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data2",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data3",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data4",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data5",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data6",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data7",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data8",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data9",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data10",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data11",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data12",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data13",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data14",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data15",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data16",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data17",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data18",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data19",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data20",
            sales_plan: 0.3,
            procurement_plan: 0.0,
            sell_bid_volume: 0.3,
            sell_contract_amount: 0.3,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data21",
            sales_plan: 0.35,
            procurement_plan: 0.0,
            sell_bid_volume: 0.35,
            sell_contract_amount: 0.35,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data22",
            sales_plan: 0.25,
            procurement_plan: 0.0,
            sell_bid_volume: 0.25,
            sell_contract_amount: 0.25,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data23",
            sales_plan: 0.45,
            procurement_plan: 0.0,
            sell_bid_volume: 0.45,
            sell_contract_amount: 0.45,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data24",
            sales_plan: 0.75,
            procurement_plan: 0.0,
            sell_bid_volume: 0.75,
            sell_contract_amount: 0.75,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data25",
            sales_plan: 0.4,
            procurement_plan: 0.0,
            sell_bid_volume: 0.4,
            sell_contract_amount: 0.4,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data26",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data27",
            sales_plan: 0.25,
            procurement_plan: 0.0,
            sell_bid_volume: 0.25,
            sell_contract_amount: 0.25,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data28",
            sales_plan: 0.5,
            procurement_plan: 0.0,
            sell_bid_volume: 0.5,
            sell_contract_amount: 0.5,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data29",
            sales_plan: 0.15,
            procurement_plan: 0.0,
            sell_bid_volume: 0.15,
            sell_contract_amount: 0.15,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data30",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data31",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data32",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data33",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data34",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data35",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data36",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data37",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data38",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data39",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data40",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data41",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data42",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data43",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data44",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data45",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data46",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data47",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data48",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
        ],
        bid_data: [
          {
            name: "data1",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data2",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data3",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data4",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data5",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data6",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data7",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data8",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data9",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data10",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data11",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data12",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data13",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data14",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data15",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data16",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 18.0,
            contract_price: 18.07,
            execution_status: "約定中",
          },
          {
            name: "data17",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 18.0,
            contract_price: 18.33,
            execution_status: "約定中",
          },
          {
            name: "data18",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 17.0,
            contract_price: 17.87,
            execution_status: "約定中",
          },
          {
            name: "data19",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 18.0,
            contract_price: 18.79,
            execution_status: "約定中",
          },
          {
            name: "data20",
            trading_type: "売り",
            bid_amount: 0.3,
            contract_amount: 0.3,
            bid_price: 17.0,
            contract_price: 17.84,
            execution_status: "約定中",
          },
          {
            name: "data21",
            trading_type: "売り",
            bid_amount: 0.35,
            contract_amount: 0.35,
            bid_price: 17.0,
            contract_price: 17.13,
            execution_status: "約定中",
          },
          {
            name: "data22",
            trading_type: "売り",
            bid_amount: 0.25,
            contract_amount: 0.25,
            bid_price: 16.0,
            contract_price: 16.48,
            execution_status: "約定中",
          },
          {
            name: "data23",
            trading_type: "売り",
            bid_amount: 0.45,
            contract_amount: 0.45,
            bid_price: 16.0,
            contract_price: 16.27,
            execution_status: "約定中",
          },
          {
            name: "data24",
            trading_type: "売り",
            bid_amount: 0.75,
            contract_amount: 0.75,
            bid_price: 14.0,
            contract_price: 14.43,
            execution_status: "約定中",
          },
          {
            name: "data25",
            trading_type: "売り",
            bid_amount: 0.4,
            contract_amount: 0.4,
            bid_price: 14.0,
            contract_price: 14.17,
            execution_status: "約定中",
          },
          {
            name: "data26",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 12.0,
            contract_price: 12.59,
            execution_status: "約定中",
          },
          {
            name: "data27",
            trading_type: "売り",
            bid_amount: 0.25,
            contract_amount: 0.25,
            bid_price: 13.0,
            contract_price: 13.85,
            execution_status: "約定中",
          },
          {
            name: "data28",
            trading_type: "売り",
            bid_amount: 0.5,
            contract_amount: 0.5,
            bid_price: 13.0,
            contract_price: 13.92,
            execution_status: "約定中",
          },
          {
            name: "data29",
            trading_type: "売り",
            bid_amount: 0.15,
            contract_amount: 0.15,
            bid_price: 14.0,
            contract_price: 14.57,
            execution_status: "約定中",
          },
          {
            name: "data30",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 14.0,
            contract_price: 14.37,
            execution_status: "約定中",
          },
          {
            name: "data31",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 13.0,
            contract_price: 13.54,
            execution_status: "約定中",
          },
          {
            name: "data32",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 15.0,
            contract_price: 15.14,
            execution_status: "約定中",
          },
          {
            name: "data33",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 16.0,
            contract_price: 16.74,
            execution_status: "約定中",
          },
          {
            name: "data34",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 17.0,
            contract_price: 17.67,
            execution_status: "約定中",
          },
          {
            name: "data35",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 17.0,
            contract_price: 17.58,
            execution_status: "約定中",
          },
          {
            name: "data36",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data37",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data38",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data39",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data40",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data41",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data42",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data43",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data44",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data45",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data46",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data47",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data48",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
        ],
      },
      {
        date: "2023/03/16",
        market_situation_data: [
          {
            name: "data1",
            sell_bid_volume: 22113200,
            sell_cheapest_price: 9.0,
            buy_bid_volume: 21529000,
            buy_cheapest_price: 13.67,
            latest_execution_price: 13.4,
            average_execution_price: 13.05,
          },
          {
            name: "data2",
            sell_bid_volume: 23826050,
            sell_cheapest_price: 6.75,
            buy_bid_volume: 21778650,
            buy_cheapest_price: 13.57,
            latest_execution_price: 13.36,
            average_execution_price: 12.48,
          },
          {
            name: "data3",
            sell_bid_volume: 24728050,
            sell_cheapest_price: 6.75,
            buy_bid_volume: 21621100,
            buy_cheapest_price: 13.49,
            latest_execution_price: 13.32,
            average_execution_price: 12.41,
          },
          {
            name: "data4",
            sell_bid_volume: 25008400,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 21625750,
            buy_cheapest_price: 13.53,
            latest_execution_price: 13.19,
            average_execution_price: 12.43,
          },
          {
            name: "data5",
            sell_bid_volume: 25355550,
            sell_cheapest_price: 6.75,
            buy_bid_volume: 21654550,
            buy_cheapest_price: 13.6,
            latest_execution_price: 13.3,
            average_execution_price: 11.95,
          },
          {
            name: "data6",
            sell_bid_volume: 25369750,
            sell_cheapest_price: 6.75,
            buy_bid_volume: 21643750,
            buy_cheapest_price: 13.41,
            latest_execution_price: 13.3,
            average_execution_price: 12.14,
          },
          {
            name: "data7",
            sell_bid_volume: 25393000,
            sell_cheapest_price: 8.29,
            buy_bid_volume: 21628850,
            buy_cheapest_price: 13.71,
            latest_execution_price: 13.44,
            average_execution_price: 12.66,
          },
          {
            name: "data8",
            sell_bid_volume: 25482600,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 21685900,
            buy_cheapest_price: 13.7,
            latest_execution_price: 13.43,
            average_execution_price: 13.13,
          },
          {
            name: "data9",
            sell_bid_volume: 25435950,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 21750500,
            buy_cheapest_price: 13.95,
            latest_execution_price: 0.01,
            average_execution_price: 12.98,
          },
          {
            name: "data10",
            sell_bid_volume: 25136850,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 21837100,
            buy_cheapest_price: 14.09,
            latest_execution_price: 0.01,
            average_execution_price: 13.87,
          },
          {
            name: "data11",
            sell_bid_volume: 24566450,
            sell_cheapest_price: 14.1,
            buy_bid_volume: 21803900,
            buy_cheapest_price: 14.33,
            latest_execution_price: 14.23,
            average_execution_price: 14.13,
          },
          {
            name: "data12",
            sell_bid_volume: 23916900,
            sell_cheapest_price: 9.91,
            buy_bid_volume: 22026000,
            buy_cheapest_price: 15.2,
            latest_execution_price: 15.2,
            average_execution_price: 14.88,
          },
          {
            name: "data13",
            sell_bid_volume: 23505750,
            sell_cheapest_price: 14.51,
            buy_bid_volume: 22740850,
            buy_cheapest_price: 16.33,
            latest_execution_price: 15.8,
            average_execution_price: 15.68,
          },
          {
            name: "data14",
            sell_bid_volume: 23077100,
            sell_cheapest_price: 16.9,
            buy_bid_volume: 23523950,
            buy_cheapest_price: 19.15,
            latest_execution_price: 17.19,
            average_execution_price: 17.36,
          },
          {
            name: "data15",
            sell_bid_volume: 23452700,
            sell_cheapest_price: 15.8,
            buy_bid_volume: 24343500,
            buy_cheapest_price: 18.5,
            latest_execution_price: 15.8,
            average_execution_price: 17.52,
          },
          {
            name: "data16",
            sell_bid_volume: 24597250,
            sell_cheapest_price: 15.52,
            buy_bid_volume: 24893650,
            buy_cheapest_price: 17.27,
            latest_execution_price: 16.41,
            average_execution_price: 16.55,
          },
          {
            name: "data17",
            sell_bid_volume: 26306200,
            sell_cheapest_price: 13.57,
            buy_bid_volume: 26206850,
            buy_cheapest_price: 20.08,
            latest_execution_price: 20.08,
            average_execution_price: 15.73,
          },
          {
            name: "data18",
            sell_bid_volume: 28227000,
            sell_cheapest_price: 13.11,
            buy_bid_volume: 27148100,
            buy_cheapest_price: 20.5,
            latest_execution_price: 20.5,
            average_execution_price: 15.49,
          },
          {
            name: "data19",
            sell_bid_volume: 29950200,
            sell_cheapest_price: 10.81,
            buy_bid_volume: 28433200,
            buy_cheapest_price: 16.5,
            latest_execution_price: 15.78,
            average_execution_price: 15.79,
          },
          {
            name: "data20",
            sell_bid_volume: 30858450,
            sell_cheapest_price: 8.0,
            buy_bid_volume: 28037800,
            buy_cheapest_price: 16.6,
            latest_execution_price: 14.09,
            average_execution_price: 14.11,
          },
          {
            name: "data21",
            sell_bid_volume: 32223000,
            sell_cheapest_price: 6.35,
            buy_bid_volume: 28134450,
            buy_cheapest_price: 16.5,
            latest_execution_price: 14.1,
            average_execution_price: 14.12,
          },
          {
            name: "data22",
            sell_bid_volume: 33784600,
            sell_cheapest_price: 6.35,
            buy_bid_volume: 28049750,
            buy_cheapest_price: 14.01,
            latest_execution_price: 14.01,
            average_execution_price: 13.87,
          },
          {
            name: "data23",
            sell_bid_volume: 34670000,
            sell_cheapest_price: 2.2,
            buy_bid_volume: 27825350,
            buy_cheapest_price: 19.4,
            latest_execution_price: 12.24,
            average_execution_price: 12.91,
          },
          {
            name: "data24",
            sell_bid_volume: 34973350,
            sell_cheapest_price: 1.2,
            buy_bid_volume: 27663100,
            buy_cheapest_price: 16.0,
            latest_execution_price: 12.74,
            average_execution_price: 12.06,
          },
          {
            name: "data25",
            sell_bid_volume: 35089950,
            sell_cheapest_price: 0.11,
            buy_bid_volume: 27460850,
            buy_cheapest_price: 13.07,
            latest_execution_price: 12.46,
            average_execution_price: 8.26,
          },
          {
            name: "data26",
            sell_bid_volume: 35064350,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 27276800,
            buy_cheapest_price: 14.0,
            latest_execution_price: 5.15,
            average_execution_price: 7.61,
          },
          {
            name: "data27",
            sell_bid_volume: 34225800,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 27609550,
            buy_cheapest_price: 15.0,
            latest_execution_price: 11.58,
            average_execution_price: 12.43,
          },
          {
            name: "data28",
            sell_bid_volume: 33041800,
            sell_cheapest_price: 1.0,
            buy_bid_volume: 27313100,
            buy_cheapest_price: 16.09,
            latest_execution_price: 1.0,
            average_execution_price: 12.39,
          },
          {
            name: "data29",
            sell_bid_volume: 31785800,
            sell_cheapest_price: 2.0,
            buy_bid_volume: 26721500,
            buy_cheapest_price: 15.26,
            latest_execution_price: 12.9,
            average_execution_price: 13.56,
          },
          {
            name: "data30",
            sell_bid_volume: 30278050,
            sell_cheapest_price: 2.0,
            buy_bid_volume: 26309450,
            buy_cheapest_price: 16.0,
            latest_execution_price: 13.4,
            average_execution_price: 13.57,
          },
          {
            name: "data31",
            sell_bid_volume: 29213050,
            sell_cheapest_price: 5.0,
            buy_bid_volume: 25406150,
            buy_cheapest_price: 13.68,
            latest_execution_price: 13.47,
            average_execution_price: 13.33,
          },
          {
            name: "data32",
            sell_bid_volume: 27235750,
            sell_cheapest_price: 6.15,
            buy_bid_volume: 25438850,
            buy_cheapest_price: 18.52,
            latest_execution_price: 14.0,
            average_execution_price: 15.37,
          },
          {
            name: "data33",
            sell_bid_volume: 26047350,
            sell_cheapest_price: 6.15,
            buy_bid_volume: 25739650,
            buy_cheapest_price: 15.96,
            latest_execution_price: 15.86,
            average_execution_price: 15.63,
          },
          {
            name: "data34",
            sell_bid_volume: 24427450,
            sell_cheapest_price: 11.21,
            buy_bid_volume: 26282750,
            buy_cheapest_price: 17.54,
            latest_execution_price: 17.33,
            average_execution_price: 17.04,
          },
          {
            name: "data35",
            sell_bid_volume: 24182050,
            sell_cheapest_price: 15.8,
            buy_bid_volume: 27489800,
            buy_cheapest_price: 22.67,
            latest_execution_price: 15.8,
            average_execution_price: 18.54,
          },
          {
            name: "data36",
            sell_bid_volume: 23622200,
            sell_cheapest_price: 12.61,
            buy_bid_volume: 28226350,
            buy_cheapest_price: 23.15,
            latest_execution_price: 23.04,
            average_execution_price: 19.79,
          },
          {
            name: "data37",
            sell_bid_volume: 23409100,
            sell_cheapest_price: 17.4,
            buy_bid_volume: 28293850,
            buy_cheapest_price: 24.3,
            latest_execution_price: 24.0,
            average_execution_price: 20.21,
          },
          {
            name: "data38",
            sell_bid_volume: 23210800,
            sell_cheapest_price: 17.5,
            buy_bid_volume: 28037950,
            buy_cheapest_price: 22.44,
            latest_execution_price: 21.06,
            average_execution_price: 19.78,
          },
          {
            name: "data39",
            sell_bid_volume: 23175000,
            sell_cheapest_price: 17.7,
            buy_bid_volume: 27689550,
            buy_cheapest_price: 20.35,
            latest_execution_price: 19.35,
            average_execution_price: 19.11,
          },
          {
            name: "data40",
            sell_bid_volume: 23227650,
            sell_cheapest_price: 16.3,
            buy_bid_volume: 27422300,
            buy_cheapest_price: 19.96,
            latest_execution_price: 18.96,
            average_execution_price: 18.87,
          },
          {
            name: "data41",
            sell_bid_volume: 22912900,
            sell_cheapest_price: 17.0,
            buy_bid_volume: 26642300,
            buy_cheapest_price: 19.49,
            latest_execution_price: 18.39,
            average_execution_price: 18.73,
          },
          {
            name: "data42",
            sell_bid_volume: 23362700,
            sell_cheapest_price: 16.35,
            buy_bid_volume: 26448150,
            buy_cheapest_price: 18.95,
            latest_execution_price: 18.17,
            average_execution_price: 18.25,
          },
          {
            name: "data43",
            sell_bid_volume: 23360850,
            sell_cheapest_price: 16.22,
            buy_bid_volume: 25789700,
            buy_cheapest_price: 18.32,
            latest_execution_price: 18.11,
            average_execution_price: 17.89,
          },
          {
            name: "data44",
            sell_bid_volume: 23779500,
            sell_cheapest_price: 16.14,
            buy_bid_volume: 25372500,
            buy_cheapest_price: 17.84,
            latest_execution_price: 16.2,
            average_execution_price: 17.57,
          },
          {
            name: "data45",
            sell_bid_volume: 23795450,
            sell_cheapest_price: 13.53,
            buy_bid_volume: 24519100,
            buy_cheapest_price: 17.84,
            latest_execution_price: 16.21,
            average_execution_price: 17.14,
          },
          {
            name: "data46",
            sell_bid_volume: 23887650,
            sell_cheapest_price: 13.0,
            buy_bid_volume: 23999350,
            buy_cheapest_price: 17.82,
            latest_execution_price: 16.84,
            average_execution_price: 16.78,
          },
          {
            name: "data47",
            sell_bid_volume: 23426050,
            sell_cheapest_price: 13.11,
            buy_bid_volume: 23514700,
            buy_cheapest_price: 17.72,
            latest_execution_price: 17.72,
            average_execution_price: 15.98,
          },
          {
            name: "data48",
            sell_bid_volume: 23339950,
            sell_cheapest_price: 13.16,
            buy_bid_volume: 22726450,
            buy_cheapest_price: 17.5,
            latest_execution_price: 17.5,
            average_execution_price: 15.59,
          },
        ],
        company_Situation_Data: [
          {
            name: "data1",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data2",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data3",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data4",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data5",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data6",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data7",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data8",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data9",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data10",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data11",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data12",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data13",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data14",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data15",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data16",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data17",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data18",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data19",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data20",
            sales_plan: 0.3,
            procurement_plan: 0.0,
            sell_bid_volume: 0.3,
            sell_contract_amount: 0.3,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data21",
            sales_plan: 0.35,
            procurement_plan: 0.0,
            sell_bid_volume: 0.35,
            sell_contract_amount: 0.35,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data22",
            sales_plan: 0.25,
            procurement_plan: 0.0,
            sell_bid_volume: 0.25,
            sell_contract_amount: 0.25,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data23",
            sales_plan: 0.45,
            procurement_plan: 0.0,
            sell_bid_volume: 0.45,
            sell_contract_amount: 0.45,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data24",
            sales_plan: 0.75,
            procurement_plan: 0.0,
            sell_bid_volume: 0.75,
            sell_contract_amount: 0.75,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data25",
            sales_plan: 0.4,
            procurement_plan: 0.0,
            sell_bid_volume: 0.4,
            sell_contract_amount: 0.4,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data26",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data27",
            sales_plan: 0.25,
            procurement_plan: 0.0,
            sell_bid_volume: 0.25,
            sell_contract_amount: 0.25,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data28",
            sales_plan: 0.5,
            procurement_plan: 0.0,
            sell_bid_volume: 0.5,
            sell_contract_amount: 0.5,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data29",
            sales_plan: 0.15,
            procurement_plan: 0.0,
            sell_bid_volume: 0.15,
            sell_contract_amount: 0.15,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data30",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data31",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data32",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data33",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data34",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data35",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data36",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data37",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data38",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data39",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data40",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data41",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data42",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data43",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data44",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data45",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data46",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data47",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data48",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
        ],
        bid_data: [
          {
            name: "data1",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data2",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data3",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data4",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data5",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data6",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data7",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data8",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data9",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data10",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data11",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data12",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data13",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data14",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data15",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data16",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 18.0,
            contract_price: 18.07,
            execution_status: "約定中",
          },
          {
            name: "data17",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 18.0,
            contract_price: 18.33,
            execution_status: "約定中",
          },
          {
            name: "data18",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 17.0,
            contract_price: 17.87,
            execution_status: "約定中",
          },
          {
            name: "data19",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 18.0,
            contract_price: 18.79,
            execution_status: "約定中",
          },
          {
            name: "data20",
            trading_type: "売り",
            bid_amount: 0.3,
            contract_amount: 0.3,
            bid_price: 17.0,
            contract_price: 17.84,
            execution_status: "約定中",
          },
          {
            name: "data21",
            trading_type: "売り",
            bid_amount: 0.35,
            contract_amount: 0.35,
            bid_price: 17.0,
            contract_price: 17.13,
            execution_status: "約定中",
          },
          {
            name: "data22",
            trading_type: "売り",
            bid_amount: 0.25,
            contract_amount: 0.25,
            bid_price: 16.0,
            contract_price: 16.48,
            execution_status: "約定中",
          },
          {
            name: "data23",
            trading_type: "売り",
            bid_amount: 0.45,
            contract_amount: 0.45,
            bid_price: 16.0,
            contract_price: 16.27,
            execution_status: "約定中",
          },
          {
            name: "data24",
            trading_type: "売り",
            bid_amount: 0.75,
            contract_amount: 0.75,
            bid_price: 14.0,
            contract_price: 14.43,
            execution_status: "約定中",
          },
          {
            name: "data25",
            trading_type: "売り",
            bid_amount: 0.4,
            contract_amount: 0.4,
            bid_price: 14.0,
            contract_price: 14.17,
            execution_status: "約定中",
          },
          {
            name: "data26",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 12.0,
            contract_price: 12.59,
            execution_status: "約定中",
          },
          {
            name: "data27",
            trading_type: "売り",
            bid_amount: 0.25,
            contract_amount: 0.25,
            bid_price: 13.0,
            contract_price: 13.85,
            execution_status: "約定中",
          },
          {
            name: "data28",
            trading_type: "売り",
            bid_amount: 0.5,
            contract_amount: 0.5,
            bid_price: 13.0,
            contract_price: 13.92,
            execution_status: "約定中",
          },
          {
            name: "data29",
            trading_type: "売り",
            bid_amount: 0.15,
            contract_amount: 0.15,
            bid_price: 14.0,
            contract_price: 14.57,
            execution_status: "約定中",
          },
          {
            name: "data30",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 14.0,
            contract_price: 14.37,
            execution_status: "約定中",
          },
          {
            name: "data31",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 13.0,
            contract_price: 13.54,
            execution_status: "約定中",
          },
          {
            name: "data32",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 15.0,
            contract_price: 15.14,
            execution_status: "約定中",
          },
          {
            name: "data33",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 16.0,
            contract_price: 16.74,
            execution_status: "約定中",
          },
          {
            name: "data34",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 17.0,
            contract_price: 17.67,
            execution_status: "約定中",
          },
          {
            name: "data35",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 17.0,
            contract_price: 17.58,
            execution_status: "約定中",
          },
          {
            name: "data36",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data37",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data38",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data39",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data40",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data41",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data42",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data43",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data44",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data45",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data46",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data47",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data48",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
        ],
      },
      {
        date: "2023/03/17",
        market_situation_data: [
          {
            name: "data1",
            sell_bid_volume: 24263100,
            sell_cheapest_price: 11.5,
            buy_bid_volume: 20155700,
            buy_cheapest_price: 13.34,
            latest_execution_price: 13.34,
            average_execution_price: 12.71,
          },
          {
            name: "data2",
            sell_bid_volume: 25907100,
            sell_cheapest_price: 11.5,
            buy_bid_volume: 20431500,
            buy_cheapest_price: 13.0,
            latest_execution_price: 13.0,
            average_execution_price: 12.52,
          },
          {
            name: "data3",
            sell_bid_volume: 26499950,
            sell_cheapest_price: 11.45,
            buy_bid_volume: 20479700,
            buy_cheapest_price: 13.0,
            latest_execution_price: 13.0,
            average_execution_price: 12.25,
          },
          {
            name: "data4",
            sell_bid_volume: 26894150,
            sell_cheapest_price: 11.45,
            buy_bid_volume: 20654500,
            buy_cheapest_price: 13.08,
            latest_execution_price: 12.96,
            average_execution_price: 12.26,
          },
          {
            name: "data5",
            sell_bid_volume: 26868850,
            sell_cheapest_price: 11.45,
            buy_bid_volume: 20773000,
            buy_cheapest_price: 14.07,
            latest_execution_price: 14.07,
            average_execution_price: 12.58,
          },
          {
            name: "data6",
            sell_bid_volume: 26798000,
            sell_cheapest_price: 11.45,
            buy_bid_volume: 20818250,
            buy_cheapest_price: 14.17,
            latest_execution_price: 14.17,
            average_execution_price: 12.35,
          },
          {
            name: "data7",
            sell_bid_volume: 26788100,
            sell_cheapest_price: 12.33,
            buy_bid_volume: 21012000,
            buy_cheapest_price: 14.18,
            latest_execution_price: 14.18,
            average_execution_price: 13.06,
          },
          {
            name: "data8",
            sell_bid_volume: 26906200,
            sell_cheapest_price: 12.96,
            buy_bid_volume: 21202100,
            buy_cheapest_price: 14.0,
            latest_execution_price: 13.07,
            average_execution_price: 13.63,
          },
          {
            name: "data9",
            sell_bid_volume: 26771350,
            sell_cheapest_price: 12.94,
            buy_bid_volume: 21344200,
            buy_cheapest_price: 14.0,
            latest_execution_price: 13.0,
            average_execution_price: 13.63,
          },
          {
            name: "data10",
            sell_bid_volume: 26665350,
            sell_cheapest_price: 10.0,
            buy_bid_volume: 21517350,
            buy_cheapest_price: 14.0,
            latest_execution_price: 13.31,
            average_execution_price: 13.67,
          },
          {
            name: "data11",
            sell_bid_volume: 26466150,
            sell_cheapest_price: 13.38,
            buy_bid_volume: 21445800,
            buy_cheapest_price: 14.0,
            latest_execution_price: 13.49,
            average_execution_price: 13.84,
          },
          {
            name: "data12",
            sell_bid_volume: 26720500,
            sell_cheapest_price: 13.25,
            buy_bid_volume: 21592500,
            buy_cheapest_price: 14.0,
            latest_execution_price: 13.36,
            average_execution_price: 13.81,
          },
          {
            name: "data13",
            sell_bid_volume: 26656100,
            sell_cheapest_price: 14.0,
            buy_bid_volume: 22045400,
            buy_cheapest_price: 15.45,
            latest_execution_price: 14.0,
            average_execution_price: 14.81,
          },
          {
            name: "data14",
            sell_bid_volume: 26014000,
            sell_cheapest_price: 11.67,
            buy_bid_volume: 22481850,
            buy_cheapest_price: 18.03,
            latest_execution_price: 15.61,
            average_execution_price: 16.83,
          },
          {
            name: "data15",
            sell_bid_volume: 25898200,
            sell_cheapest_price: 16.4,
            buy_bid_volume: 22896250,
            buy_cheapest_price: 17.28,
            latest_execution_price: 16.4,
            average_execution_price: 16.54,
          },
          {
            name: "data16",
            sell_bid_volume: 26661950,
            sell_cheapest_price: 15.48,
            buy_bid_volume: 23048050,
            buy_cheapest_price: 15.69,
            latest_execution_price: 15.48,
            average_execution_price: 15.58,
          },
          {
            name: "data17",
            sell_bid_volume: 28039100,
            sell_cheapest_price: 14.83,
            buy_bid_volume: 24087700,
            buy_cheapest_price: 15.27,
            latest_execution_price: 15.16,
            average_execution_price: 15.2,
          },
          {
            name: "data18",
            sell_bid_volume: 29986050,
            sell_cheapest_price: 11.91,
            buy_bid_volume: 24092250,
            buy_cheapest_price: 13.83,
            latest_execution_price: 13.7,
            average_execution_price: 13.69,
          },
          {
            name: "data19",
            sell_bid_volume: 32216350,
            sell_cheapest_price: 5.51,
            buy_bid_volume: 25025550,
            buy_cheapest_price: 15.75,
            latest_execution_price: 15.05,
            average_execution_price: 14.07,
          },
          {
            name: "data20",
            sell_bid_volume: 33571750,
            sell_cheapest_price: 2.1,
            buy_bid_volume: 24552200,
            buy_cheapest_price: 15.0,
            latest_execution_price: 5.1,
            average_execution_price: 8.84,
          },
          {
            name: "data21",
            sell_bid_volume: 35656950,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 24520650,
            buy_cheapest_price: 17.27,
            latest_execution_price: 5.11,
            average_execution_price: 9.04,
          },
          {
            name: "data22",
            sell_bid_volume: 36966700,
            sell_cheapest_price: 0.11,
            buy_bid_volume: 24569650,
            buy_cheapest_price: 15.0,
            latest_execution_price: 2.0,
            average_execution_price: 8.18,
          },
          {
            name: "data23",
            sell_bid_volume: 37873800,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 24766450,
            buy_cheapest_price: 12.11,
            latest_execution_price: 0.01,
            average_execution_price: 6.64,
          },
          {
            name: "data24",
            sell_bid_volume: 38505650,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 24935600,
            buy_cheapest_price: 12.07,
            latest_execution_price: 0.01,
            average_execution_price: 9.27,
          },
          {
            name: "data25",
            sell_bid_volume: 39020800,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 24306700,
            buy_cheapest_price: 13.0,
            latest_execution_price: 5.36,
            average_execution_price: 9.18,
          },
          {
            name: "data26",
            sell_bid_volume: 38873650,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 24177300,
            buy_cheapest_price: 11.6,
            latest_execution_price: 11.4,
            average_execution_price: 7.26,
          },
          {
            name: "data27",
            sell_bid_volume: 37763700,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 24800950,
            buy_cheapest_price: 15.0,
            latest_execution_price: 11.11,
            average_execution_price: 11.35,
          },
          {
            name: "data28",
            sell_bid_volume: 36375750,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 24486950,
            buy_cheapest_price: 15.0,
            latest_execution_price: 9.89,
            average_execution_price: 11.52,
          },
          {
            name: "data29",
            sell_bid_volume: 34624050,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 23941700,
            buy_cheapest_price: 15.0,
            latest_execution_price: 11.19,
            average_execution_price: 10.95,
          },
          {
            name: "data30",
            sell_bid_volume: 33139400,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 23570250,
            buy_cheapest_price: 15.5,
            latest_execution_price: 13.2,
            average_execution_price: 10.79,
          },
          {
            name: "data31",
            sell_bid_volume: 30867100,
            sell_cheapest_price: 0.01,
            buy_bid_volume: 21996450,
            buy_cheapest_price: 15.0,
            latest_execution_price: 4.91,
            average_execution_price: 7.75,
          },
          {
            name: "data32",
            sell_bid_volume: 29482950,
            sell_cheapest_price: 0.1,
            buy_bid_volume: 22217250,
            buy_cheapest_price: 14.97,
            latest_execution_price: 0.1,
            average_execution_price: 8.85,
          },
          {
            name: "data33",
            sell_bid_volume: 27937300,
            sell_cheapest_price: 11.9,
            buy_bid_volume: 22457250,
            buy_cheapest_price: 18.0,
            latest_execution_price: 12.01,
            average_execution_price: 15.16,
          },
          {
            name: "data34",
            sell_bid_volume: 26036800,
            sell_cheapest_price: 13.37,
            buy_bid_volume: 23039800,
            buy_cheapest_price: 18.5,
            latest_execution_price: 14.6,
            average_execution_price: 16.44,
          },
          {
            name: "data35",
            sell_bid_volume: 24524300,
            sell_cheapest_price: 16.0,
            buy_bid_volume: 23238250,
            buy_cheapest_price: 18.74,
            latest_execution_price: 17.18,
            average_execution_price: 17.39,
          },
          {
            name: "data36",
            sell_bid_volume: 23515550,
            sell_cheapest_price: 16.0,
            buy_bid_volume: 24201150,
            buy_cheapest_price: 18.89,
            latest_execution_price: 17.37,
            average_execution_price: 17.36,
          },
          {
            name: "data37",
            sell_bid_volume: 23524450,
            sell_cheapest_price: 16.0,
            buy_bid_volume: 24521500,
            buy_cheapest_price: 17.74,
            latest_execution_price: 17.54,
            average_execution_price: 17.45,
          },
          {
            name: "data38",
            sell_bid_volume: 23682100,
            sell_cheapest_price: 16.67,
            buy_bid_volume: 24355500,
            buy_cheapest_price: 18.59,
            latest_execution_price: 17.52,
            average_execution_price: 17.58,
          },
          {
            name: "data39",
            sell_bid_volume: 24089450,
            sell_cheapest_price: 14.01,
            buy_bid_volume: 24380450,
            buy_cheapest_price: 19.06,
            latest_execution_price: 17.44,
            average_execution_price: 17.13,
          },
          {
            name: "data40",
            sell_bid_volume: 24507850,
            sell_cheapest_price: 14.96,
            buy_bid_volume: 24545950,
            buy_cheapest_price: 18.92,
            latest_execution_price: 17.32,
            average_execution_price: 17.09,
          },
          {
            name: "data41",
            sell_bid_volume: 24152100,
            sell_cheapest_price: 16.7,
            buy_bid_volume: 24147100,
            buy_cheapest_price: 18.3,
            latest_execution_price: 17.4,
            average_execution_price: 17.32,
          },
          {
            name: "data42",
            sell_bid_volume: 24357650,
            sell_cheapest_price: 16.0,
            buy_bid_volume: 24065300,
            buy_cheapest_price: 18.3,
            latest_execution_price: 16.88,
            average_execution_price: 17.07,
          },
          {
            name: "data43",
            sell_bid_volume: 24257750,
            sell_cheapest_price: 16.2,
            buy_bid_volume: 23864700,
            buy_cheapest_price: 17.51,
            latest_execution_price: 16.75,
            average_execution_price: 16.86,
          },
          {
            name: "data44",
            sell_bid_volume: 24689500,
            sell_cheapest_price: 15.7,
            buy_bid_volume: 23557100,
            buy_cheapest_price: 17.78,
            latest_execution_price: 17.78,
            average_execution_price: 16.07,
          },
          {
            name: "data45",
            sell_bid_volume: 24627900,
            sell_cheapest_price: 15.71,
            buy_bid_volume: 23237800,
            buy_cheapest_price: 16.48,
            latest_execution_price: 15.93,
            average_execution_price: 15.91,
          },
          {
            name: "data46",
            sell_bid_volume: 24698700,
            sell_cheapest_price: 14.73,
            buy_bid_volume: 22845950,
            buy_cheapest_price: 15.52,
            latest_execution_price: 15.2,
            average_execution_price: 15.03,
          },
          {
            name: "data47",
            sell_bid_volume: 23848000,
            sell_cheapest_price: 14.79,
            buy_bid_volume: 22313850,
            buy_cheapest_price: 15.76,
            latest_execution_price: 15.76,
            average_execution_price: 15.01,
          },
          {
            name: "data48",
            sell_bid_volume: 23825800,
            sell_cheapest_price: 13.83,
            buy_bid_volume: 21530800,
            buy_cheapest_price: 15.6,
            latest_execution_price: 14.04,
            average_execution_price: 14.36,
          },
        ],
        company_Situation_Data: [
          {
            name: "data1",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data2",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data3",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data4",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data5",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data6",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data7",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data8",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data9",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data10",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data11",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data12",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data13",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data14",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data15",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data16",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data17",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data18",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data19",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data20",
            sales_plan: 0.3,
            procurement_plan: 0.0,
            sell_bid_volume: 0.3,
            sell_contract_amount: 0.3,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data21",
            sales_plan: 0.35,
            procurement_plan: 0.0,
            sell_bid_volume: 0.35,
            sell_contract_amount: 0.35,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data22",
            sales_plan: 0.25,
            procurement_plan: 0.0,
            sell_bid_volume: 0.25,
            sell_contract_amount: 0.25,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data23",
            sales_plan: 0.45,
            procurement_plan: 0.0,
            sell_bid_volume: 0.45,
            sell_contract_amount: 0.45,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data24",
            sales_plan: 0.75,
            procurement_plan: 0.0,
            sell_bid_volume: 0.75,
            sell_contract_amount: 0.75,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data25",
            sales_plan: 0.4,
            procurement_plan: 0.0,
            sell_bid_volume: 0.4,
            sell_contract_amount: 0.4,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data26",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data27",
            sales_plan: 0.25,
            procurement_plan: 0.0,
            sell_bid_volume: 0.25,
            sell_contract_amount: 0.25,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data28",
            sales_plan: 0.5,
            procurement_plan: 0.0,
            sell_bid_volume: 0.5,
            sell_contract_amount: 0.5,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data29",
            sales_plan: 0.15,
            procurement_plan: 0.0,
            sell_bid_volume: 0.15,
            sell_contract_amount: 0.15,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data30",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data31",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data32",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data33",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data34",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data35",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data36",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data37",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data38",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data39",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data40",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data41",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data42",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data43",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data44",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data45",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data46",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data47",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data48",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
        ],
        bid_data: [
          {
            name: "data1",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data2",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data3",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data4",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data5",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data6",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data7",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data8",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data9",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data10",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data11",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data12",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data13",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data14",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data15",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data16",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 18.0,
            contract_price: 18.07,
            execution_status: "約定中",
          },
          {
            name: "data17",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 18.0,
            contract_price: 18.33,
            execution_status: "約定中",
          },
          {
            name: "data18",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 17.0,
            contract_price: 17.87,
            execution_status: "約定中",
          },
          {
            name: "data19",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 18.0,
            contract_price: 18.79,
            execution_status: "約定中",
          },
          {
            name: "data20",
            trading_type: "売り",
            bid_amount: 0.3,
            contract_amount: 0.3,
            bid_price: 17.0,
            contract_price: 17.84,
            execution_status: "約定中",
          },
          {
            name: "data21",
            trading_type: "売り",
            bid_amount: 0.35,
            contract_amount: 0.35,
            bid_price: 17.0,
            contract_price: 17.13,
            execution_status: "約定中",
          },
          {
            name: "data22",
            trading_type: "売り",
            bid_amount: 0.25,
            contract_amount: 0.25,
            bid_price: 16.0,
            contract_price: 16.48,
            execution_status: "約定中",
          },
          {
            name: "data23",
            trading_type: "売り",
            bid_amount: 0.45,
            contract_amount: 0.45,
            bid_price: 16.0,
            contract_price: 16.27,
            execution_status: "約定中",
          },
          {
            name: "data24",
            trading_type: "売り",
            bid_amount: 0.75,
            contract_amount: 0.75,
            bid_price: 14.0,
            contract_price: 14.43,
            execution_status: "約定中",
          },
          {
            name: "data25",
            trading_type: "売り",
            bid_amount: 0.4,
            contract_amount: 0.4,
            bid_price: 14.0,
            contract_price: 14.17,
            execution_status: "約定中",
          },
          {
            name: "data26",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 12.0,
            contract_price: 12.59,
            execution_status: "約定中",
          },
          {
            name: "data27",
            trading_type: "売り",
            bid_amount: 0.25,
            contract_amount: 0.25,
            bid_price: 13.0,
            contract_price: 13.85,
            execution_status: "約定中",
          },
          {
            name: "data28",
            trading_type: "売り",
            bid_amount: 0.5,
            contract_amount: 0.5,
            bid_price: 13.0,
            contract_price: 13.92,
            execution_status: "約定中",
          },
          {
            name: "data29",
            trading_type: "売り",
            bid_amount: 0.15,
            contract_amount: 0.15,
            bid_price: 14.0,
            contract_price: 14.57,
            execution_status: "約定中",
          },
          {
            name: "data30",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 14.0,
            contract_price: 14.37,
            execution_status: "約定中",
          },
          {
            name: "data31",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 13.0,
            contract_price: 13.54,
            execution_status: "約定中",
          },
          {
            name: "data32",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 15.0,
            contract_price: 15.14,
            execution_status: "約定中",
          },
          {
            name: "data33",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 16.0,
            contract_price: 16.74,
            execution_status: "約定中",
          },
          {
            name: "data34",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 17.0,
            contract_price: 17.67,
            execution_status: "約定中",
          },
          {
            name: "data35",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 17.0,
            contract_price: 17.58,
            execution_status: "約定中",
          },
          {
            name: "data36",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data37",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data38",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data39",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data40",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data41",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data42",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data43",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data44",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data45",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data46",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data47",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data48",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
        ],
      },
      {
        date: "2023/03/18",
        market_situation_data: [
          {
            name: "data1",
            sell_bid_volume: 20101600,
            sell_cheapest_price: 10.86,
            buy_bid_volume: 21956500,
            buy_cheapest_price: 17.13,
            latest_execution_price: 16.36,
            average_execution_price: 15.42,
          },
          {
            name: "data2",
            sell_bid_volume: 22433150,
            sell_cheapest_price: 13.44,
            buy_bid_volume: 22789050,
            buy_cheapest_price: 16.36,
            latest_execution_price: 16.16,
            average_execution_price: 15.64,
          },
          {
            name: "data3",
            sell_bid_volume: 22827500,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 22988050,
            buy_cheapest_price: 16.62,
            latest_execution_price: 16.52,
            average_execution_price: 14.34,
          },
          {
            name: "data4",
            sell_bid_volume: 23305600,
            sell_cheapest_price: 9.91,
            buy_bid_volume: 23235350,
            buy_cheapest_price: 16.7,
            latest_execution_price: 14.4,
            average_execution_price: 15.74,
          },
          {
            name: "data5",
            sell_bid_volume: 23372150,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 23483100,
            buy_cheapest_price: 17.63,
            latest_execution_price: 15.9,
            average_execution_price: 14.95,
          },
          {
            name: "data6",
            sell_bid_volume: 23320500,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 23742950,
            buy_cheapest_price: 17.6,
            latest_execution_price: 15.96,
            average_execution_price: 14.36,
          },
          {
            name: "data7",
            sell_bid_volume: 23348900,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 23748300,
            buy_cheapest_price: 18.83,
            latest_execution_price: 17.63,
            average_execution_price: 15.81,
          },
          {
            name: "data8",
            sell_bid_volume: 23533300,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 23671400,
            buy_cheapest_price: 18.73,
            latest_execution_price: 17.64,
            average_execution_price: 16.45,
          },
          {
            name: "data9",
            sell_bid_volume: 23644600,
            sell_cheapest_price: 9.86,
            buy_bid_volume: 23625850,
            buy_cheapest_price: 18.53,
            latest_execution_price: 17.81,
            average_execution_price: 16.38,
          },
          {
            name: "data10",
            sell_bid_volume: 23511800,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 23430300,
            buy_cheapest_price: 18.06,
            latest_execution_price: 16.55,
            average_execution_price: 15.81,
          },
          {
            name: "data11",
            sell_bid_volume: 23622700,
            sell_cheapest_price: 10.17,
            buy_bid_volume: 23118300,
            buy_cheapest_price: 18.97,
            latest_execution_price: 16.37,
            average_execution_price: 15.78,
          },
          {
            name: "data12",
            sell_bid_volume: 23682250,
            sell_cheapest_price: 11.6,
            buy_bid_volume: 22872700,
            buy_cheapest_price: 19.0,
            latest_execution_price: 17.09,
            average_execution_price: 16.07,
          },
          {
            name: "data13",
            sell_bid_volume: 22561450,
            sell_cheapest_price: 11.6,
            buy_bid_volume: 21950450,
            buy_cheapest_price: 18.75,
            latest_execution_price: 17.0,
            average_execution_price: 15.62,
          },
          {
            name: "data14",
            sell_bid_volume: 22349800,
            sell_cheapest_price: 10.98,
            buy_bid_volume: 22414400,
            buy_cheapest_price: 18.4,
            latest_execution_price: 17.31,
            average_execution_price: 16.52,
          },
          {
            name: "data15",
            sell_bid_volume: 22260400,
            sell_cheapest_price: 11.82,
            buy_bid_volume: 22949700,
            buy_cheapest_price: 18.67,
            latest_execution_price: 17.54,
            average_execution_price: 17.29,
          },
          {
            name: "data16",
            sell_bid_volume: 22436400,
            sell_cheapest_price: 14.47,
            buy_bid_volume: 23680600,
            buy_cheapest_price: 18.72,
            latest_execution_price: 18.17,
            average_execution_price: 17.65,
          },
          {
            name: "data17",
            sell_bid_volume: 23076850,
            sell_cheapest_price: 7.31,
            buy_bid_volume: 24968000,
            buy_cheapest_price: 20.0,
            latest_execution_price: 19.1,
            average_execution_price: 18.18,
          },
          {
            name: "data18",
            sell_bid_volume: 23240850,
            sell_cheapest_price: 1.92,
            buy_bid_volume: 25307100,
            buy_cheapest_price: 20.0,
            latest_execution_price: 19.9,
            average_execution_price: 17.66,
          },
          {
            name: "data19",
            sell_bid_volume: 24199350,
            sell_cheapest_price: 17.72,
            buy_bid_volume: 25668750,
            buy_cheapest_price: 19.0,
            latest_execution_price: 18.22,
            average_execution_price: 18.27,
          },
          {
            name: "data20",
            sell_bid_volume: 25306350,
            sell_cheapest_price: 13.97,
            buy_bid_volume: 25617700,
            buy_cheapest_price: 17.64,
            latest_execution_price: 15.89,
            average_execution_price: 17.46,
          },
          {
            name: "data21",
            sell_bid_volume: 26044200,
            sell_cheapest_price: 13.36,
            buy_bid_volume: 25771350,
            buy_cheapest_price: 17.39,
            latest_execution_price: 17.18,
            average_execution_price: 16.63,
          },
          {
            name: "data22",
            sell_bid_volume: 27059150,
            sell_cheapest_price: 13.36,
            buy_bid_volume: 25640200,
            buy_cheapest_price: 17.2,
            latest_execution_price: 15.94,
            average_execution_price: 15.79,
          },
          {
            name: "data23",
            sell_bid_volume: 27737050,
            sell_cheapest_price: 13.0,
            buy_bid_volume: 25030000,
            buy_cheapest_price: 15.97,
            latest_execution_price: 15.97,
            average_execution_price: 15.5,
          },
          {
            name: "data24",
            sell_bid_volume: 28605800,
            sell_cheapest_price: 12.01,
            buy_bid_volume: 24721300,
            buy_cheapest_price: 15.22,
            latest_execution_price: 15.01,
            average_execution_price: 14.13,
          },
          {
            name: "data25",
            sell_bid_volume: 29053800,
            sell_cheapest_price: 6.25,
            buy_bid_volume: 24435450,
            buy_cheapest_price: 15.0,
            latest_execution_price: 13.49,
            average_execution_price: 13.74,
          },
          {
            name: "data26",
            sell_bid_volume: 29808600,
            sell_cheapest_price: 6.25,
            buy_bid_volume: 24038600,
            buy_cheapest_price: 15.0,
            latest_execution_price: 13.0,
            average_execution_price: 12.48,
          },
          {
            name: "data27",
            sell_bid_volume: 29641600,
            sell_cheapest_price: 6.25,
            buy_bid_volume: 24048950,
            buy_cheapest_price: 15.9,
            latest_execution_price: 13.71,
            average_execution_price: 13.26,
          },
          {
            name: "data28",
            sell_bid_volume: 29332650,
            sell_cheapest_price: 5.0,
            buy_bid_volume: 23742900,
            buy_cheapest_price: 15.59,
            latest_execution_price: 14.01,
            average_execution_price: 13.73,
          },
          {
            name: "data29",
            sell_bid_volume: 28750550,
            sell_cheapest_price: 6.25,
            buy_bid_volume: 23479200,
            buy_cheapest_price: 15.62,
            latest_execution_price: 15.2,
            average_execution_price: 14.11,
          },
          {
            name: "data30",
            sell_bid_volume: 28014000,
            sell_cheapest_price: 10.0,
            buy_bid_volume: 23192600,
            buy_cheapest_price: 15.52,
            latest_execution_price: 15.52,
            average_execution_price: 14.18,
          },
          {
            name: "data31",
            sell_bid_volume: 27150750,
            sell_cheapest_price: 6.25,
            buy_bid_volume: 22552200,
            buy_cheapest_price: 16.44,
            latest_execution_price: 13.81,
            average_execution_price: 13.35,
          },
          {
            name: "data32",
            sell_bid_volume: 25808050,
            sell_cheapest_price: 6.25,
            buy_bid_volume: 22256700,
            buy_cheapest_price: 17.12,
            latest_execution_price: 15.65,
            average_execution_price: 14.44,
          },
          {
            name: "data33",
            sell_bid_volume: 25057150,
            sell_cheapest_price: 10.01,
            buy_bid_volume: 22443400,
            buy_cheapest_price: 16.81,
            latest_execution_price: 16.81,
            average_execution_price: 16.0,
          },
          {
            name: "data34",
            sell_bid_volume: 23413850,
            sell_cheapest_price: 13.97,
            buy_bid_volume: 22242200,
            buy_cheapest_price: 17.89,
            latest_execution_price: 16.27,
            average_execution_price: 17.01,
          },
          {
            name: "data35",
            sell_bid_volume: 22343850,
            sell_cheapest_price: 14.0,
            buy_bid_volume: 22847450,
            buy_cheapest_price: 17.89,
            latest_execution_price: 17.87,
            average_execution_price: 17.38,
          },
          {
            name: "data36",
            sell_bid_volume: 21799300,
            sell_cheapest_price: 15.84,
            buy_bid_volume: 23602750,
            buy_cheapest_price: 19.64,
            latest_execution_price: 19.13,
            average_execution_price: 18.83,
          },
          {
            name: "data37",
            sell_bid_volume: 22619050,
            sell_cheapest_price: 17.0,
            buy_bid_volume: 24890400,
            buy_cheapest_price: 19.79,
            latest_execution_price: 17.75,
            average_execution_price: 18.32,
          },
          {
            name: "data38",
            sell_bid_volume: 22953300,
            sell_cheapest_price: 17.0,
            buy_bid_volume: 24827500,
            buy_cheapest_price: 18.5,
            latest_execution_price: 17.54,
            average_execution_price: 17.92,
          },
          {
            name: "data39",
            sell_bid_volume: 23301850,
            sell_cheapest_price: 16.29,
            buy_bid_volume: 24919800,
            buy_cheapest_price: 18.71,
            latest_execution_price: 17.24,
            average_execution_price: 17.82,
          },
          {
            name: "data40",
            sell_bid_volume: 23645400,
            sell_cheapest_price: 15.36,
            buy_bid_volume: 24929250,
            buy_cheapest_price: 18.41,
            latest_execution_price: 17.83,
            average_execution_price: 17.55,
          },
          {
            name: "data41",
            sell_bid_volume: 23607800,
            sell_cheapest_price: 14.5,
            buy_bid_volume: 24780550,
            buy_cheapest_price: 18.59,
            latest_execution_price: 17.78,
            average_execution_price: 17.37,
          },
          {
            name: "data42",
            sell_bid_volume: 23724450,
            sell_cheapest_price: 14.77,
            buy_bid_volume: 24670400,
            buy_cheapest_price: 18.68,
            latest_execution_price: 17.64,
            average_execution_price: 17.4,
          },
          {
            name: "data43",
            sell_bid_volume: 23779750,
            sell_cheapest_price: 11.6,
            buy_bid_volume: 24134300,
            buy_cheapest_price: 18.78,
            latest_execution_price: 17.1,
            average_execution_price: 16.02,
          },
          {
            name: "data44",
            sell_bid_volume: 23761800,
            sell_cheapest_price: 16.84,
            buy_bid_volume: 23708500,
            buy_cheapest_price: 17.49,
            latest_execution_price: 17.45,
            average_execution_price: 17.3,
          },
          {
            name: "data45",
            sell_bid_volume: 23332300,
            sell_cheapest_price: 12.46,
            buy_bid_volume: 23331800,
            buy_cheapest_price: 18.17,
            latest_execution_price: 17.45,
            average_execution_price: 17.36,
          },
          {
            name: "data46",
            sell_bid_volume: 23093500,
            sell_cheapest_price: 12.46,
            buy_bid_volume: 22769300,
            buy_cheapest_price: 17.08,
            latest_execution_price: 17.08,
            average_execution_price: 16.59,
          },
          {
            name: "data47",
            sell_bid_volume: 22969050,
            sell_cheapest_price: 13.19,
            buy_bid_volume: 22476100,
            buy_cheapest_price: 17.59,
            latest_execution_price: 16.65,
            average_execution_price: 16.64,
          },
          {
            name: "data48",
            sell_bid_volume: 22154400,
            sell_cheapest_price: 12.2,
            buy_bid_volume: 20894800,
            buy_cheapest_price: 17.59,
            latest_execution_price: 16.62,
            average_execution_price: 16.39,
          },
        ],
        company_Situation_Data: [
          {
            name: "data1",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data2",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data3",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data4",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data5",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data6",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data7",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data8",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data9",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data10",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data11",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data12",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data13",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data14",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data15",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data16",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data17",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data18",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data19",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data20",
            sales_plan: 0.3,
            procurement_plan: 0.0,
            sell_bid_volume: 0.3,
            sell_contract_amount: 0.3,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data21",
            sales_plan: 0.35,
            procurement_plan: 0.0,
            sell_bid_volume: 0.35,
            sell_contract_amount: 0.35,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data22",
            sales_plan: 0.25,
            procurement_plan: 0.0,
            sell_bid_volume: 0.25,
            sell_contract_amount: 0.25,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data23",
            sales_plan: 0.45,
            procurement_plan: 0.0,
            sell_bid_volume: 0.45,
            sell_contract_amount: 0.45,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data24",
            sales_plan: 0.75,
            procurement_plan: 0.0,
            sell_bid_volume: 0.75,
            sell_contract_amount: 0.75,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data25",
            sales_plan: 0.4,
            procurement_plan: 0.0,
            sell_bid_volume: 0.4,
            sell_contract_amount: 0.4,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data26",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data27",
            sales_plan: 0.25,
            procurement_plan: 0.0,
            sell_bid_volume: 0.25,
            sell_contract_amount: 0.25,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data28",
            sales_plan: 0.5,
            procurement_plan: 0.0,
            sell_bid_volume: 0.5,
            sell_contract_amount: 0.5,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data29",
            sales_plan: 0.15,
            procurement_plan: 0.0,
            sell_bid_volume: 0.15,
            sell_contract_amount: 0.15,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data30",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data31",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data32",
            sales_plan: 0.2,
            procurement_plan: 0.0,
            sell_bid_volume: 0.2,
            sell_contract_amount: 0.2,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data33",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data34",
            sales_plan: 0.1,
            procurement_plan: 0.0,
            sell_bid_volume: 0.1,
            sell_contract_amount: 0.1,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data35",
            sales_plan: 0.05,
            procurement_plan: 0.0,
            sell_bid_volume: 0.05,
            sell_contract_amount: 0.05,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data36",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data37",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data38",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data39",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data40",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data41",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data42",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data43",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data44",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data45",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data46",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data47",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
          {
            name: "data48",
            sales_plan: 0.0,
            procurement_plan: 0.0,
            sell_bid_volume: 0.0,
            sell_contract_amount: 0.0,
            buy_bid_volume: 0.0,
            buy_contract_amount: 0.0,
          },
        ],
        bid_data: [
          {
            name: "data1",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data2",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data3",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data4",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data5",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data6",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data7",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data8",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data9",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data10",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data11",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data12",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data13",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data14",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data15",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "",
          },
          {
            name: "data16",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 18.0,
            contract_price: 18.07,
            execution_status: "約定中",
          },
          {
            name: "data17",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 18.0,
            contract_price: 18.33,
            execution_status: "約定中",
          },
          {
            name: "data18",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 17.0,
            contract_price: 17.87,
            execution_status: "約定中",
          },
          {
            name: "data19",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 18.0,
            contract_price: 18.79,
            execution_status: "約定中",
          },
          {
            name: "data20",
            trading_type: "売り",
            bid_amount: 0.3,
            contract_amount: 0.3,
            bid_price: 17.0,
            contract_price: 17.84,
            execution_status: "約定中",
          },
          {
            name: "data21",
            trading_type: "売り",
            bid_amount: 0.35,
            contract_amount: 0.35,
            bid_price: 17.0,
            contract_price: 17.13,
            execution_status: "約定中",
          },
          {
            name: "data22",
            trading_type: "売り",
            bid_amount: 0.25,
            contract_amount: 0.25,
            bid_price: 16.0,
            contract_price: 16.48,
            execution_status: "約定中",
          },
          {
            name: "data23",
            trading_type: "売り",
            bid_amount: 0.45,
            contract_amount: 0.45,
            bid_price: 16.0,
            contract_price: 16.27,
            execution_status: "約定中",
          },
          {
            name: "data24",
            trading_type: "売り",
            bid_amount: 0.75,
            contract_amount: 0.75,
            bid_price: 14.0,
            contract_price: 14.43,
            execution_status: "約定中",
          },
          {
            name: "data25",
            trading_type: "売り",
            bid_amount: 0.4,
            contract_amount: 0.4,
            bid_price: 14.0,
            contract_price: 14.17,
            execution_status: "約定中",
          },
          {
            name: "data26",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 12.0,
            contract_price: 12.59,
            execution_status: "約定中",
          },
          {
            name: "data27",
            trading_type: "売り",
            bid_amount: 0.25,
            contract_amount: 0.25,
            bid_price: 13.0,
            contract_price: 13.85,
            execution_status: "約定中",
          },
          {
            name: "data28",
            trading_type: "売り",
            bid_amount: 0.5,
            contract_amount: 0.5,
            bid_price: 13.0,
            contract_price: 13.92,
            execution_status: "約定中",
          },
          {
            name: "data29",
            trading_type: "売り",
            bid_amount: 0.15,
            contract_amount: 0.15,
            bid_price: 14.0,
            contract_price: 14.57,
            execution_status: "約定中",
          },
          {
            name: "data30",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 14.0,
            contract_price: 14.37,
            execution_status: "約定中",
          },
          {
            name: "data31",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 13.0,
            contract_price: 13.54,
            execution_status: "約定中",
          },
          {
            name: "data32",
            trading_type: "売り",
            bid_amount: 0.2,
            contract_amount: 0.2,
            bid_price: 15.0,
            contract_price: 15.14,
            execution_status: "約定中",
          },
          {
            name: "data33",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 16.0,
            contract_price: 16.74,
            execution_status: "約定中",
          },
          {
            name: "data34",
            trading_type: "売り",
            bid_amount: 0.1,
            contract_amount: 0.1,
            bid_price: 17.0,
            contract_price: 17.67,
            execution_status: "約定中",
          },
          {
            name: "data35",
            trading_type: "売り",
            bid_amount: 0.05,
            contract_amount: 0.05,
            bid_price: 17.0,
            contract_price: 17.58,
            execution_status: "約定中",
          },
          {
            name: "data36",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data37",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data38",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data39",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data40",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data41",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data42",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data43",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data44",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data45",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data46",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data47",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
          {
            name: "data48",
            trading_type: "売り",
            bid_amount: 0.0,
            contract_amount: 0.0,
            bid_price: 0.0,
            contract_price: 0.0,
            execution_status: "約定中",
          },
        ],
      },
    ],
    automationSettings_Data: {
      deadlineTime: "実需給1時間前",
      deleteTime: "なし",
      startTime: "実需給1時間前",
      adjustmentCycle: "10分",
      adjustmentAmount: 0.01,
    },
    bidLimit_data: [
      {
        name: "data1",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data2",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data3",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data4",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data5",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data6",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data7",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data8",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data9",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data10",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data11",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data12",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data13",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data14",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data15",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data16",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data17",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data18",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data19",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data20",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data21",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data22",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data23",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data24",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data25",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data26",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data27",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data28",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data29",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data30",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data31",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data32",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data33",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data34",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data35",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data36",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data37",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data38",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data39",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data40",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data41",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data42",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data43",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data44",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data45",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data46",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data47",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
      {
        name: "data48",
        sellingPrice_min: 0,
        sellingPrice_max: 999,
        salesVolume_min: 0,
        salesVolume_max: 999,
        purchasePrice_min: 0,
        purchasePrice_max: 999,
        purchaseAmount_min: 0,
        purchaseAmount_max: 999,
      },
    ],
  },
];

//MarketDetailsで使うデータ
type createData = {
  name: string;
  sell: number;
  price: number;
  buy: number;
};

type createMarketDetailsData = {
  name: string;
  datas: createData[];
};

export const TestMarketDetailsData: createMarketDetailsData[] = [
  {
    name: "table1",
    datas: [
      { name: "data1", sell: 12.26, price: 0, buy: 0 },
      { name: "data2", sell: 10.29, price: 17.31, buy: 0 },
      { name: "data3", sell: 24.44, price: 16.59, buy: 0 },
      { name: "data4", sell: 4.15, price: 15.87, buy: 0 },
      { name: "data5", sell: 4.89, price: 15.16, buy: 0 },
      { name: "data6", sell: 5.34, price: 14.44, buy: 0 },
      { name: "data7", sell: 0, price: 13.72, buy: 0.08 },
      { name: "data8", sell: 0, price: 13.01, buy: 7.64 },
      { name: "data9", sell: 0, price: 12.29, buy: 13.64 },
      { name: "data10", sell: 0, price: 11.58, buy: 8.95 },
      { name: "data11", sell: 0, price: 10.86, buy: 26.9 },
      { name: "data12", sell: 0, price: 0, buy: 42.93 },
    ],
  },
  {
    name: "table2",
    datas: [
      { name: "data1", sell: 35.75, price: 0, buy: 0 },
      { name: "data2", sell: 9.49, price: 16.36, buy: 0 },
      { name: "data3", sell: 2.86, price: 16.04, buy: 0 },
      { name: "data4", sell: 34.79, price: 15.71, buy: 0 },
      { name: "data5", sell: 9.39, price: 15.39, buy: 0 },
      { name: "data6", sell: 5.59, price: 15.06, buy: 0 },
      { name: "data7", sell: 0, price: 14.74, buy: 11.97 },
      { name: "data8", sell: 0, price: 14.41, buy: 18.73 },
      { name: "data9", sell: 0, price: 14.09, buy: 38.69 },
      { name: "data10", sell: 0, price: 13.76, buy: 2.03 },
      { name: "data11", sell: 0, price: 13.44, buy: 28.71 },
      { name: "data12", sell: 0, price: 0, buy: 43.6 },
    ],
  },
  {
    name: "table3",
    datas: [
      { name: "data1", sell: 39.39, price: 0, buy: 0 },
      { name: "data2", sell: 14.94, price: 16.75, buy: 0 },
      { name: "data3", sell: 2.15, price: 16.02, buy: 0 },
      { name: "data4", sell: 21.45, price: 15.29, buy: 0 },
      { name: "data5", sell: 2.5, price: 14.56, buy: 0 },
      { name: "data6", sell: 3.2, price: 13.83, buy: 0 },
      { name: "data7", sell: 0, price: 13.1, buy: 16.37 },
      { name: "data8", sell: 0, price: 12.36, buy: 8.8 },
      { name: "data9", sell: 0, price: 11.63, buy: 15.16 },
      { name: "data10", sell: 0, price: 10.9, buy: 0.15 },
      { name: "data11", sell: 0, price: 10.17, buy: 14.53 },
      { name: "data12", sell: 0, price: 0, buy: 11.36 },
    ],
  },
  {
    name: "table4",
    datas: [
      { name: "data1", sell: 30.93, price: 0, buy: 0 },
      { name: "data2", sell: 19.71, price: 17.07, buy: 0 },
      { name: "data3", sell: 19.85, price: 16.28, buy: 0 },
      { name: "data4", sell: 20.74, price: 15.48, buy: 0 },
      { name: "data5", sell: 9.46, price: 14.68, buy: 0 },
      { name: "data6", sell: 4.67, price: 13.89, buy: 0 },
      { name: "data7", sell: 0, price: 13.09, buy: 15.87 },
      { name: "data8", sell: 0, price: 12.3, buy: 3.47 },
      { name: "data9", sell: 0, price: 11.5, buy: 50.69 },
      { name: "data10", sell: 0, price: 10.71, buy: 12.66 },
      { name: "data11", sell: 0, price: 9.91, buy: 13.07 },
      { name: "data12", sell: 0, price: 0, buy: 25.67 },
    ],
  },
  {
    name: "table5",
    datas: [
      { name: "data1", sell: 43.55, price: 0, buy: 0 },
      { name: "data2", sell: 13.18, price: 17.87, buy: 0 },
      { name: "data3", sell: 11.83, price: 17.02, buy: 0 },
      { name: "data4", sell: 30.09, price: 16.16, buy: 0 },
      { name: "data5", sell: 6.33, price: 15.31, buy: 0 },
      { name: "data6", sell: 2.0, price: 14.45, buy: 0 },
      { name: "data7", sell: 0, price: 13.59, buy: 5.61 },
      { name: "data8", sell: 0, price: 12.74, buy: 17.91 },
      { name: "data9", sell: 0, price: 11.88, buy: 46.45 },
      { name: "data10", sell: 0, price: 11.03, buy: 6.49 },
      { name: "data11", sell: 0, price: 10.17, buy: 14.75 },
      { name: "data12", sell: 0, price: 0, buy: 32.32 },
    ],
  },
  {
    name: "table6",
    datas: [
      { name: "data1", sell: 32.76, price: 0, buy: 0 },
      { name: "data2", sell: 19.17, price: 17.61, buy: 0 },
      { name: "data3", sell: 1.64, price: 16.78, buy: 0 },
      { name: "data4", sell: 25.1, price: 15.96, buy: 0 },
      { name: "data5", sell: 8.83, price: 15.13, buy: 0 },
      { name: "data6", sell: 2.74, price: 14.3, buy: 0 },
      { name: "data7", sell: 0, price: 13.48, buy: 8.34 },
      { name: "data8", sell: 0, price: 12.65, buy: 9.43 },
      { name: "data9", sell: 0, price: 11.82, buy: 28.17 },
      { name: "data10", sell: 0, price: 11.0, buy: 10.72 },
      { name: "data11", sell: 0, price: 10.17, buy: 14.98 },
      { name: "data12", sell: 0, price: 0, buy: 33.27 },
    ],
  },
  {
    name: "table7",
    datas: [
      { name: "data1", sell: 10.23, price: 0, buy: 0 },
      { name: "data2", sell: 19.35, price: 19.17, buy: 0 },
      { name: "data3", sell: 31.67, price: 18.17, buy: 0 },
      { name: "data4", sell: 21.71, price: 17.17, buy: 0 },
      { name: "data5", sell: 0.04, price: 16.17, buy: 0 },
      { name: "data6", sell: 0.12, price: 15.17, buy: 0 },
      { name: "data7", sell: 0, price: 14.17, buy: 4.59 },
      { name: "data8", sell: 0, price: 13.17, buy: 12.78 },
      { name: "data9", sell: 0, price: 12.17, buy: 5.76 },
      { name: "data10", sell: 0, price: 11.17, buy: 2.56 },
      { name: "data11", sell: 0, price: 10.17, buy: 23.97 },
      { name: "data12", sell: 0, price: 0, buy: 6.55 },
    ],
  },
  {
    name: "table8",
    datas: [
      { name: "data1", sell: 16.6, price: 0, buy: 0 },
      { name: "data2", sell: 18.58, price: 18.75, buy: 0 },
      { name: "data3", sell: 6.51, price: 17.8, buy: 0 },
      { name: "data4", sell: 15.58, price: 16.84, buy: 0 },
      { name: "data5", sell: 8.25, price: 15.89, buy: 0 },
      { name: "data6", sell: 5.92, price: 14.94, buy: 0 },
      { name: "data7", sell: 0, price: 13.98, buy: 16.35 },
      { name: "data8", sell: 0, price: 13.03, buy: 18.79 },
      { name: "data9", sell: 0, price: 12.08, buy: 39.5 },
      { name: "data10", sell: 0, price: 11.12, buy: 2.87 },
      { name: "data11", sell: 0, price: 10.17, buy: 18.18 },
      { name: "data12", sell: 0, price: 0, buy: 22.82 },
    ],
  },
  {
    name: "table9",
    datas: [
      { name: "data1", sell: 5.45, price: 0, buy: 0 },
      { name: "data2", sell: 8.82, price: 18.62, buy: 0 },
      { name: "data3", sell: 35.94, price: 17.65, buy: 0 },
      { name: "data4", sell: 5.6, price: 16.68, buy: 0 },
      { name: "data5", sell: 8.04, price: 15.7, buy: 0 },
      { name: "data6", sell: 4.43, price: 14.73, buy: 0 },
      { name: "data7", sell: 0, price: 13.75, buy: 8.6 },
      { name: "data8", sell: 0, price: 12.78, buy: 16.71 },
      { name: "data9", sell: 0, price: 11.81, buy: 17.87 },
      { name: "data10", sell: 0, price: 10.83, buy: 10.37 },
      { name: "data11", sell: 0, price: 9.86, buy: 12.28 },
      { name: "data12", sell: 0, price: 0, buy: 15.28 },
    ],
  },
  {
    name: "table10",
    datas: [
      { name: "data1", sell: 47.42, price: 0, buy: 0 },
      { name: "data2", sell: 14.19, price: 18.43, buy: 0 },
      { name: "data3", sell: 22.64, price: 17.51, buy: 0 },
      { name: "data4", sell: 19.31, price: 16.59, buy: 0 },
      { name: "data5", sell: 5.2, price: 15.68, buy: 0 },
      { name: "data6", sell: 1.67, price: 14.76, buy: 0 },
      { name: "data7", sell: 0, price: 13.84, buy: 8.43 },
      { name: "data8", sell: 0, price: 12.92, buy: 2.51 },
      { name: "data9", sell: 0, price: 12.01, buy: 51.75 },
      { name: "data10", sell: 0, price: 11.09, buy: 4.08 },
      { name: "data11", sell: 0, price: 10.17, buy: 4.97 },
      { name: "data12", sell: 0, price: 0, buy: 11.65 },
    ],
  },
  {
    name: "table11",
    datas: [
      { name: "data1", sell: 45.37, price: 0, buy: 0 },
      { name: "data2", sell: 15.75, price: 19.08, buy: 0 },
      { name: "data3", sell: 26.84, price: 18.09, buy: 0 },
      { name: "data4", sell: 28.54, price: 17.1, buy: 0 },
      { name: "data5", sell: 4.23, price: 16.11, buy: 0 },
      { name: "data6", sell: 3.44, price: 15.12, buy: 0 },
      { name: "data7", sell: 0, price: 14.13, buy: 2.96 },
      { name: "data8", sell: 0, price: 13.14, buy: 7.76 },
      { name: "data9", sell: 0, price: 12.15, buy: 51.73 },
      { name: "data10", sell: 0, price: 11.16, buy: 0.7 },
      { name: "data11", sell: 0, price: 10.17, buy: 21.36 },
      { name: "data12", sell: 0, price: 0, buy: 11.08 },
    ],
  },
  {
    name: "table12",
    datas: [
      { name: "data1", sell: 5.39, price: 0, buy: 0 },
      { name: "data2", sell: 9.29, price: 19.05, buy: 0 },
      { name: "data3", sell: 21.03, price: 18.22, buy: 0 },
      { name: "data4", sell: 33.82, price: 17.39, buy: 0 },
      { name: "data5", sell: 2.29, price: 16.57, buy: 0 },
      { name: "data6", sell: 2.92, price: 15.74, buy: 0 },
      { name: "data7", sell: 0, price: 14.91, buy: 14.29 },
      { name: "data8", sell: 0, price: 14.08, buy: 7.42 },
      { name: "data9", sell: 0, price: 13.26, buy: 13.24 },
      { name: "data10", sell: 0, price: 12.43, buy: 6.59 },
      { name: "data11", sell: 0, price: 11.6, buy: 28.26 },
      { name: "data12", sell: 0, price: 0, buy: 48.59 },
    ],
  },
  {
    name: "table13",
    datas: [
      { name: "data1", sell: 23.5, price: 0, buy: 0 },
      { name: "data2", sell: 14.92, price: 19.0, buy: 0 },
      { name: "data3", sell: 12.83, price: 18.18, buy: 0 },
      { name: "data4", sell: 20.0, price: 17.35, buy: 0 },
      { name: "data5", sell: 1.07, price: 16.53, buy: 0 },
      { name: "data6", sell: 4.55, price: 15.71, buy: 0 },
      { name: "data7", sell: 0, price: 14.89, buy: 14.1 },
      { name: "data8", sell: 0, price: 14.07, buy: 0.58 },
      { name: "data9", sell: 0, price: 13.24, buy: 6.45 },
      { name: "data10", sell: 0, price: 12.42, buy: 0.2 },
      { name: "data11", sell: 0, price: 11.6, buy: 12.1 },
      { name: "data12", sell: 0, price: 0, buy: 14.85 },
    ],
  },
  {
    name: "table14",
    datas: [
      { name: "data1", sell: 5.23, price: 0, buy: 0 },
      { name: "data2", sell: 2.11, price: 18.53, buy: 0 },
      { name: "data3", sell: 20.13, price: 17.69, buy: 0 },
      { name: "data4", sell: 15.84, price: 16.85, buy: 0 },
      { name: "data5", sell: 8.76, price: 16.01, buy: 0 },
      { name: "data6", sell: 2.93, price: 15.17, buy: 0 },
      { name: "data7", sell: 0, price: 14.33, buy: 6.01 },
      { name: "data8", sell: 0, price: 13.5, buy: 16.29 },
      { name: "data9", sell: 0, price: 12.66, buy: 40.55 },
      { name: "data10", sell: 0, price: 11.82, buy: 8.35 },
      { name: "data11", sell: 0, price: 10.98, buy: 4.21 },
      { name: "data12", sell: 0, price: 0, buy: 43.49 },
    ],
  },
  {
    name: "table15",
    datas: [
      { name: "data1", sell: 18.88, price: 0, buy: 0 },
      { name: "data2", sell: 14.02, price: 19.11, buy: 0 },
      { name: "data3", sell: 10.02, price: 18.3, buy: 0 },
      { name: "data4", sell: 2.52, price: 17.49, buy: 0 },
      { name: "data5", sell: 4.9, price: 16.68, buy: 0 },
      { name: "data6", sell: 3.2, price: 15.87, buy: 0 },
      { name: "data7", sell: 0, price: 15.06, buy: 3.71 },
      { name: "data8", sell: 0, price: 14.25, buy: 11.81 },
      { name: "data9", sell: 0, price: 13.44, buy: 38.73 },
      { name: "data10", sell: 0, price: 12.63, buy: 1.08 },
      { name: "data11", sell: 0, price: 11.82, buy: 34.88 },
      { name: "data12", sell: 0, price: 0, buy: 11.01 },
    ],
  },
  {
    name: "table16",
    datas: [
      { name: "data1", sell: 20.68, price: 0, buy: 0 },
      { name: "data2", sell: 2.0, price: 18.82, buy: 0 },
      { name: "data3", sell: 37.98, price: 18.34, buy: 0 },
      { name: "data4", sell: 31.73, price: 17.85, buy: 0 },
      { name: "data5", sell: 0.51, price: 17.37, buy: 0 },
      { name: "data6", sell: 3.2, price: 16.89, buy: 0 },
      { name: "data7", sell: 0, price: 16.4, buy: 2.93 },
      { name: "data8", sell: 0, price: 15.92, buy: 1.62 },
      { name: "data9", sell: 0, price: 15.44, buy: 4.38 },
      { name: "data10", sell: 0, price: 14.95, buy: 9.5 },
      { name: "data11", sell: 0, price: 14.47, buy: 16.83 },
      { name: "data12", sell: 0, price: 0, buy: 40.84 },
    ],
  },
  {
    name: "table17",
    datas: [
      { name: "data1", sell: 13.53, price: 0, buy: 0 },
      { name: "data2", sell: 9.09, price: 20.16, buy: 0 },
      { name: "data3", sell: 28.21, price: 18.73, buy: 0 },
      { name: "data4", sell: 0.21, price: 17.3, buy: 0 },
      { name: "data5", sell: 3.25, price: 15.88, buy: 0 },
      { name: "data6", sell: 3.57, price: 14.45, buy: 0 },
      { name: "data7", sell: 0, price: 13.02, buy: 7.66 },
      { name: "data8", sell: 0, price: 11.59, buy: 8.59 },
      { name: "data9", sell: 0, price: 10.17, buy: 42.18 },
      { name: "data10", sell: 0, price: 8.74, buy: 6.9 },
      { name: "data11", sell: 0, price: 7.31, buy: 23.21 },
      { name: "data12", sell: 0, price: 0, buy: 37.43 },
    ],
  },
  {
    name: "table18",
    datas: [
      { name: "data1", sell: 46.59, price: 0, buy: 0 },
      { name: "data2", sell: 17.58, price: 20.04, buy: 0 },
      { name: "data3", sell: 9.74, price: 18.03, buy: 0 },
      { name: "data4", sell: 1.5, price: 16.02, buy: 0 },
      { name: "data5", sell: 4.29, price: 14.0, buy: 0 },
      { name: "data6", sell: 5.26, price: 11.99, buy: 0 },
      { name: "data7", sell: 0, price: 9.98, buy: 8.57 },
      { name: "data8", sell: 0, price: 7.96, buy: 13.6 },
      { name: "data9", sell: 0, price: 5.95, buy: 35.22 },
      { name: "data10", sell: 0, price: 3.93, buy: 3.24 },
      { name: "data11", sell: 0, price: 1.92, buy: 2.26 },
      { name: "data12", sell: 0, price: 0, buy: 42.29 },
    ],
  },
  {
    name: "table19",
    datas: [
      { name: "data1", sell: 4.92, price: 0, buy: 0 },
      { name: "data2", sell: 14.01, price: 19.13, buy: 0 },
      { name: "data3", sell: 7.88, price: 18.97, buy: 0 },
      { name: "data4", sell: 32.18, price: 18.82, buy: 0 },
      { name: "data5", sell: 3.94, price: 18.66, buy: 0 },
      { name: "data6", sell: 5.0, price: 18.5, buy: 0 },
      { name: "data7", sell: 0, price: 18.35, buy: 13.6 },
      { name: "data8", sell: 0, price: 18.19, buy: 1.18 },
      { name: "data9", sell: 0, price: 18.03, buy: 3.42 },
      { name: "data10", sell: 0, price: 17.88, buy: 3.41 },
      { name: "data11", sell: 0, price: 17.72, buy: 23.1 },
      { name: "data12", sell: 0, price: 0, buy: 14.84 },
    ],
  },
  {
    name: "table20",
    datas: [
      { name: "data1", sell: 18.6, price: 0, buy: 0 },
      { name: "data2", sell: 1.77, price: 17.91, buy: 0 },
      { name: "data3", sell: 0.57, price: 17.47, buy: 0 },
      { name: "data4", sell: 26.25, price: 17.03, buy: 0 },
      { name: "data5", sell: 6.39, price: 16.6, buy: 0 },
      { name: "data6", sell: 3.33, price: 16.16, buy: 0 },
      { name: "data7", sell: 0, price: 15.72, buy: 9.7 },
      { name: "data8", sell: 0, price: 15.28, buy: 3.34 },
      { name: "data9", sell: 0, price: 14.85, buy: 47.04 },
      { name: "data10", sell: 0, price: 14.41, buy: 10.48 },
      { name: "data11", sell: 0, price: 13.97, buy: 0.57 },
      { name: "data12", sell: 0, price: 0, buy: 6.76 },
    ],
  },
  {
    name: "table21",
    datas: [
      { name: "data1", sell: 20.31, price: 0, buy: 0 },
      { name: "data2", sell: 17.33, price: 17.77, buy: 0 },
      { name: "data3", sell: 34.86, price: 17.28, buy: 0 },
      { name: "data4", sell: 18.08, price: 16.79, buy: 0 },
      { name: "data5", sell: 0.43, price: 16.3, buy: 0 },
      { name: "data6", sell: 5.15, price: 15.81, buy: 0 },
      { name: "data7", sell: 0, price: 15.32, buy: 1.78 },
      { name: "data8", sell: 0, price: 14.83, buy: 0.86 },
      { name: "data9", sell: 0, price: 14.34, buy: 39.48 },
      { name: "data10", sell: 0, price: 13.85, buy: 7.11 },
      { name: "data11", sell: 0, price: 13.36, buy: 33.1 },
      { name: "data12", sell: 0, price: 0, buy: 10.16 },
    ],
  },
  {
    name: "table22",
    datas: [
      { name: "data1", sell: 2.43, price: 0, buy: 0 },
      { name: "data2", sell: 16.57, price: 17.48, buy: 0 },
      { name: "data3", sell: 11.85, price: 17.02, buy: 0 },
      { name: "data4", sell: 24.81, price: 16.56, buy: 0 },
      { name: "data5", sell: 6.27, price: 16.11, buy: 0 },
      { name: "data6", sell: 0.53, price: 15.65, buy: 0 },
      { name: "data7", sell: 0, price: 15.19, buy: 13.19 },
      { name: "data8", sell: 0, price: 14.73, buy: 21.92 },
      { name: "data9", sell: 0, price: 14.28, buy: 8.85 },
      { name: "data10", sell: 0, price: 13.82, buy: 9.36 },
      { name: "data11", sell: 0, price: 13.36, buy: 4.34 },
      { name: "data12", sell: 0, price: 0, buy: 60.22 },
    ],
  },
  {
    name: "table23",
    datas: [
      { name: "data1", sell: 27.99, price: 0, buy: 0 },
      { name: "data2", sell: 14.37, price: 16.26, buy: 0 },
      { name: "data3", sell: 12.63, price: 15.9, buy: 0 },
      { name: "data4", sell: 4.57, price: 15.54, buy: 0 },
      { name: "data5", sell: 1.4, price: 15.17, buy: 0 },
      { name: "data6", sell: 3.26, price: 14.81, buy: 0 },
      { name: "data7", sell: 0, price: 14.45, buy: 7.32 },
      { name: "data8", sell: 0, price: 14.09, buy: 14.04 },
      { name: "data9", sell: 0, price: 13.72, buy: 4.84 },
      { name: "data10", sell: 0, price: 13.36, buy: 12.33 },
      { name: "data11", sell: 0, price: 13, buy: 4.96 },
      { name: "data12", sell: 0, price: 0, buy: 32.85 },
    ],
  },
  {
    name: "table24",
    datas: [
      { name: "data1", sell: 41.8, price: 0, buy: 0 },
      { name: "data2", sell: 2.48, price: 15.41, buy: 0 },
      { name: "data3", sell: 33.31, price: 15.03, buy: 0 },
      { name: "data4", sell: 21.39, price: 14.65, buy: 0 },
      { name: "data5", sell: 6.04, price: 14.27, buy: 0 },
      { name: "data6", sell: 1.64, price: 13.9, buy: 0 },
      { name: "data7", sell: 0, price: 13.52, buy: 12.44 },
      { name: "data8", sell: 0, price: 13.14, buy: 6.43 },
      { name: "data9", sell: 0, price: 12.76, buy: 41.26 },
      { name: "data10", sell: 0, price: 12.39, buy: 7.77 },
      { name: "data11", sell: 0, price: 12.01, buy: 15.8 },
      { name: "data12", sell: 0, price: 0, buy: 34.54 },
    ],
  },
  {
    name: "table25",
    datas: [
      { name: "data1", sell: 29.38, price: 0, buy: 0 },
      { name: "data2", sell: 1.94, price: 15.18, buy: 0 },
      { name: "data3", sell: 15.52, price: 14.19, buy: 0 },
      { name: "data4", sell: 0.9, price: 13.2, buy: 0 },
      { name: "data5", sell: 8.1, price: 12.2, buy: 0 },
      { name: "data6", sell: 1.85, price: 11.21, buy: 0 },
      { name: "data7", sell: 0, price: 10.22, buy: 7.16 },
      { name: "data8", sell: 0, price: 9.23, buy: 4.94 },
      { name: "data9", sell: 0, price: 8.23, buy: 10.58 },
      { name: "data10", sell: 0, price: 7.24, buy: 11.33 },
      { name: "data11", sell: 0, price: 6.25, buy: 30.17 },
      { name: "data12", sell: 0, price: 0, buy: 11.2 },
    ],
  },
  {
    name: "table26",
    datas: [
      { name: "data1", sell: 5.38, price: 0, buy: 0 },
      { name: "data2", sell: 15.42, price: 15.08, buy: 0 },
      { name: "data3", sell: 28.11, price: 14.1, buy: 0 },
      { name: "data4", sell: 17.4, price: 13.12, buy: 0 },
      { name: "data5", sell: 1.96, price: 12.14, buy: 0 },
      { name: "data6", sell: 5.2, price: 11.16, buy: 0 },
      { name: "data7", sell: 0, price: 10.18, buy: 0.73 },
      { name: "data8", sell: 0, price: 9.19, buy: 19.57 },
      { name: "data9", sell: 0, price: 8.21, buy: 13.4 },
      { name: "data10", sell: 0, price: 7.23, buy: 7.02 },
      { name: "data11", sell: 0, price: 6.25, buy: 5.56 },
      { name: "data12", sell: 0, price: 0, buy: 32.02 },
    ],
  },
  {
    name: "table27",
    datas: [
      { name: "data1", sell: 38.36, price: 0, buy: 0 },
      { name: "data2", sell: 0.11, price: 16.0, buy: 0 },
      { name: "data3", sell: 32.36, price: 14.92, buy: 0 },
      { name: "data4", sell: 9.33, price: 13.83, buy: 0 },
      { name: "data5", sell: 8.67, price: 12.75, buy: 0 },
      { name: "data6", sell: 0.26, price: 11.67, buy: 0 },
      { name: "data7", sell: 0, price: 10.58, buy: 12.47 },
      { name: "data8", sell: 0, price: 9.5, buy: 6.94 },
      { name: "data9", sell: 0, price: 8.42, buy: 45.31 },
      { name: "data10", sell: 0, price: 7.33, buy: 6.53 },
      { name: "data11", sell: 0, price: 6.25, buy: 8.33 },
      { name: "data12", sell: 0, price: 0, buy: 36.62 },
    ],
  },
  {
    name: "table28",
    datas: [
      { name: "data1", sell: 2.33, price: 0, buy: 0 },
      { name: "data2", sell: 17.88, price: 15.7, buy: 0 },
      { name: "data3", sell: 32.32, price: 14.51, buy: 0 },
      { name: "data4", sell: 13.94, price: 13.32, buy: 0 },
      { name: "data5", sell: 9.86, price: 12.13, buy: 0 },
      { name: "data6", sell: 1.46, price: 10.95, buy: 0 },
      { name: "data7", sell: 0, price: 9.76, buy: 12.12 },
      { name: "data8", sell: 0, price: 8.57, buy: 8.96 },
      { name: "data9", sell: 0, price: 7.38, buy: 24.17 },
      { name: "data10", sell: 0, price: 6.19, buy: 11.28 },
      { name: "data11", sell: 0, price: 5, buy: 15.67 },
      { name: "data12", sell: 0, price: 0, buy: 14.64 },
    ],
  },
  {
    name: "table29",
    datas: [
      { name: "data1", sell: 6.15, price: 0, buy: 0 },
      { name: "data2", sell: 9.3, price: 15.87, buy: 0 },
      { name: "data3", sell: 20.5, price: 14.8, buy: 0 },
      { name: "data4", sell: 5.23, price: 13.73, buy: 0 },
      { name: "data5", sell: 3.35, price: 12.66, buy: 0 },
      { name: "data6", sell: 1.09, price: 11.6, buy: 0 },
      { name: "data7", sell: 0, price: 10.53, buy: 10.44 },
      { name: "data8", sell: 0, price: 9.46, buy: 20.58 },
      { name: "data9", sell: 0, price: 8.39, buy: 9.53 },
      { name: "data10", sell: 0, price: 7.32, buy: 5.92 },
      { name: "data11", sell: 0, price: 6.25, buy: 13.36 },
      { name: "data12", sell: 0, price: 0, buy: 56.78 },
    ],
  },
  {
    name: "table30",
    datas: [
      { name: "data1", sell: 34.56, price: 0, buy: 0 },
      { name: "data2", sell: 10.31, price: 15.57, buy: 0 },
      { name: "data3", sell: 15.69, price: 14.95, buy: 0 },
      { name: "data4", sell: 1.02, price: 14.33, buy: 0 },
      { name: "data5", sell: 0.33, price: 13.71, buy: 0 },
      { name: "data6", sell: 1.52, price: 13.1, buy: 0 },
      { name: "data7", sell: 0, price: 12.48, buy: 8.39 },
      { name: "data8", sell: 0, price: 11.86, buy: 20.58 },
      { name: "data9", sell: 0, price: 11.24, buy: 3.98 },
      { name: "data10", sell: 0, price: 10.62, buy: 11.74 },
      { name: "data11", sell: 0, price: 10, buy: 5.65 },
      { name: "data12", sell: 0, price: 0, buy: 38.66 },
    ],
  },
  {
    name: "table31",
    datas: [
      { name: "data1", sell: 40.15, price: 0, buy: 0 },
      { name: "data2", sell: 5.15, price: 16.5, buy: 0 },
      { name: "data3", sell: 21.68, price: 15.36, buy: 0 },
      { name: "data4", sell: 24.58, price: 14.22, buy: 0 },
      { name: "data5", sell: 2.23, price: 13.08, buy: 0 },
      { name: "data6", sell: 2.46, price: 11.94, buy: 0 },
      { name: "data7", sell: 0, price: 10.8, buy: 12.73 },
      { name: "data8", sell: 0, price: 9.67, buy: 13.15 },
      { name: "data9", sell: 0, price: 8.53, buy: 27.28 },
      { name: "data10", sell: 0, price: 7.39, buy: 2.76 },
      { name: "data11", sell: 0, price: 6.25, buy: 27.6 },
      { name: "data12", sell: 0, price: 0, buy: 45.83 },
    ],
  },
  {
    name: "table32",
    datas: [
      { name: "data1", sell: 30.67, price: 0, buy: 0 },
      { name: "data2", sell: 7.41, price: 17.54, buy: 0 },
      { name: "data3", sell: 10.53, price: 16.28, buy: 0 },
      { name: "data4", sell: 13.25, price: 15.03, buy: 0 },
      { name: "data5", sell: 2.2, price: 13.77, buy: 0 },
      { name: "data6", sell: 1.32, price: 12.52, buy: 0 },
      { name: "data7", sell: 0, price: 11.27, buy: 13.97 },
      { name: "data8", sell: 0, price: 10.01, buy: 12.66 },
      { name: "data9", sell: 0, price: 8.76, buy: 24.33 },
      { name: "data10", sell: 0, price: 7.5, buy: 1.5 },
      { name: "data11", sell: 0, price: 6.25, buy: 23.65 },
      { name: "data12", sell: 0, price: 0, buy: 59.05 },
    ],
  },
  {
    name: "table33",
    datas: [
      { name: "data1", sell: 33.57, price: 0, buy: 0 },
      { name: "data2", sell: 14.32, price: 17.04, buy: 0 },
      { name: "data3", sell: 4.18, price: 16.26, buy: 0 },
      { name: "data4", sell: 34.84, price: 15.48, buy: 0 },
      { name: "data5", sell: 1.8, price: 14.69, buy: 0 },
      { name: "data6", sell: 5.2, price: 13.91, buy: 0 },
      { name: "data7", sell: 0, price: 13.13, buy: 12.94 },
      { name: "data8", sell: 0, price: 12.35, buy: 2.91 },
      { name: "data9", sell: 0, price: 11.57, buy: 21.93 },
      { name: "data10", sell: 0, price: 10.79, buy: 4.92 },
      { name: "data11", sell: 0, price: 10.01, buy: 25.76 },
      { name: "data12", sell: 0, price: 0, buy: 1.67 },
    ],
  },
  {
    name: "table34",
    datas: [
      { name: "data1", sell: 27.67, price: 0, buy: 0 },
      { name: "data2", sell: 19.92, price: 17.95, buy: 0 },
      { name: "data3", sell: 1.52, price: 17.51, buy: 0 },
      { name: "data4", sell: 34.05, price: 17.07, buy: 0 },
      { name: "data5", sell: 9.2, price: 16.62, buy: 0 },
      { name: "data6", sell: 2.04, price: 16.18, buy: 0 },
      { name: "data7", sell: 0, price: 15.74, buy: 9.1 },
      { name: "data8", sell: 0, price: 15.3, buy: 10.97 },
      { name: "data9", sell: 0, price: 14.85, buy: 23.99 },
      { name: "data10", sell: 0, price: 14.41, buy: 12.8 },
      { name: "data11", sell: 0, price: 13.97, buy: 34.73 },
      { name: "data12", sell: 0, price: 0, buy: 39.5 },
    ],
  },
  {
    name: "table35",
    datas: [
      { name: "data1", sell: 32.94, price: 0, buy: 0 },
      { name: "data2", sell: 7.9, price: 18.07, buy: 0 },
      { name: "data3", sell: 26.62, price: 17.62, buy: 0 },
      { name: "data4", sell: 29.15, price: 17.17, buy: 0 },
      { name: "data5", sell: 4.2, price: 16.71, buy: 0 },
      { name: "data6", sell: 0.63, price: 16.26, buy: 0 },
      { name: "data7", sell: 0, price: 15.81, buy: 7.68 },
      { name: "data8", sell: 0, price: 15.36, buy: 0.91 },
      { name: "data9", sell: 0, price: 14.9, buy: 45.37 },
      { name: "data10", sell: 0, price: 14.45, buy: 0.34 },
      { name: "data11", sell: 0, price: 14, buy: 31.77 },
      { name: "data12", sell: 0, price: 0, buy: 32.67 },
    ],
  },
  {
    name: "table36",
    datas: [
      { name: "data1", sell: 35.8, price: 0, buy: 0 },
      { name: "data2", sell: 0.25, price: 19.99, buy: 0 },
      { name: "data3", sell: 3.52, price: 19.53, buy: 0 },
      { name: "data4", sell: 8.22, price: 19.07, buy: 0 },
      { name: "data5", sell: 0.64, price: 18.61, buy: 0 },
      { name: "data6", sell: 4.6, price: 18.15, buy: 0 },
      { name: "data7", sell: 0, price: 17.69, buy: 17.56 },
      { name: "data8", sell: 0, price: 17.22, buy: 14.05 },
      { name: "data9", sell: 0, price: 16.76, buy: 33.57 },
      { name: "data10", sell: 0, price: 16.3, buy: 7.82 },
      { name: "data11", sell: 0, price: 15.84, buy: 0.23 },
      { name: "data12", sell: 0, price: 0, buy: 25.26 },
    ],
  },
  {
    name: "table37",
    datas: [
      { name: "data1", sell: 44.92, price: 0, buy: 0 },
      { name: "data2", sell: 5.95, price: 19.98, buy: 0 },
      { name: "data3", sell: 10.67, price: 19.65, buy: 0 },
      { name: "data4", sell: 2.59, price: 19.32, buy: 0 },
      { name: "data5", sell: 9.21, price: 18.99, buy: 0 },
      { name: "data6", sell: 3.92, price: 18.66, buy: 0 },
      { name: "data7", sell: 0, price: 18.32, buy: 13.34 },
      { name: "data8", sell: 0, price: 17.99, buy: 18.87 },
      { name: "data9", sell: 0, price: 17.66, buy: 26.53 },
      { name: "data10", sell: 0, price: 17.33, buy: 0.11 },
      { name: "data11", sell: 0, price: 17, buy: 30.1 },
      { name: "data12", sell: 0, price: 0, buy: 59.93 },
    ],
  },
  {
    name: "table38",
    datas: [
      { name: "data1", sell: 19.52, price: 0, buy: 0 },
      { name: "data2", sell: 11.66, price: 18.52, buy: 0 },
      { name: "data3", sell: 39.95, price: 18.35, buy: 0 },
      { name: "data4", sell: 13.45, price: 18.19, buy: 0 },
      { name: "data5", sell: 0.51, price: 18.02, buy: 0 },
      { name: "data6", sell: 5.01, price: 17.85, buy: 0 },
      { name: "data7", sell: 0, price: 17.68, buy: 7.66 },
      { name: "data8", sell: 0, price: 17.51, buy: 15.59 },
      { name: "data9", sell: 0, price: 17.34, buy: 20.35 },
      { name: "data10", sell: 0, price: 17.17, buy: 10.4 },
      { name: "data11", sell: 0, price: 17, buy: 14.5 },
      { name: "data12", sell: 0, price: 0, buy: 19.91 },
    ],
  },
  {
    name: "table39",
    datas: [
      { name: "data1", sell: 12.49, price: 0, buy: 0 },
      { name: "data2", sell: 16.23, price: 18.77, buy: 0 },
      { name: "data3", sell: 1.14, price: 18.49, buy: 0 },
      { name: "data4", sell: 16.23, price: 18.22, buy: 0 },
      { name: "data5", sell: 6.8, price: 17.94, buy: 0 },
      { name: "data6", sell: 4.8, price: 17.67, buy: 0 },
      { name: "data7", sell: 0, price: 17.39, buy: 16.36 },
      { name: "data8", sell: 0, price: 17.12, buy: 16.31 },
      { name: "data9", sell: 0, price: 16.84, buy: 48.91 },
      { name: "data10", sell: 0, price: 16.57, buy: 5.65 },
      { name: "data11", sell: 0, price: 16.29, buy: 32.93 },
      { name: "data12", sell: 0, price: 0, buy: 10.54 },
    ],
  },
  {
    name: "table40",
    datas: [
      { name: "data1", sell: 14.65, price: 0, buy: 0 },
      { name: "data2", sell: 10.97, price: 18.82, buy: 0 },
      { name: "data3", sell: 5.09, price: 18.44, buy: 0 },
      { name: "data4", sell: 26.88, price: 18.05, buy: 0 },
      { name: "data5", sell: 2.34, price: 17.67, buy: 0 },
      { name: "data6", sell: 0.28, price: 17.28, buy: 0 },
      { name: "data7", sell: 0, price: 16.9, buy: 11.43 },
      { name: "data8", sell: 0, price: 16.51, buy: 3.21 },
      { name: "data9", sell: 0, price: 16.13, buy: 7.84 },
      { name: "data10", sell: 0, price: 15.74, buy: 5.84 },
      { name: "data11", sell: 0, price: 15.36, buy: 8.01 },
      { name: "data12", sell: 0, price: 0, buy: 13.14 },
    ],
  },
  {
    name: "table41",
    datas: [
      { name: "data1", sell: 12.44, price: 0, buy: 0 },
      { name: "data2", sell: 8.14, price: 18.62, buy: 0 },
      { name: "data3", sell: 12.05, price: 18.17, buy: 0 },
      { name: "data4", sell: 34.08, price: 17.71, buy: 0 },
      { name: "data5", sell: 0.91, price: 17.25, buy: 0 },
      { name: "data6", sell: 1.37, price: 16.79, buy: 0 },
      { name: "data7", sell: 0, price: 16.33, buy: 9.67 },
      { name: "data8", sell: 0, price: 15.87, buy: 1.6 },
      { name: "data9", sell: 0, price: 15.42, buy: 34.61 },
      { name: "data10", sell: 0, price: 14.96, buy: 11.73 },
      { name: "data11", sell: 0, price: 14.5, buy: 29.99 },
      { name: "data12", sell: 0, price: 0, buy: 8.17 },
    ],
  },
  {
    name: "table42",
    datas: [
      { name: "data1", sell: 19.52, price: 0, buy: 0 },
      { name: "data2", sell: 2.42, price: 18.76, buy: 0 },
      { name: "data3", sell: 37.7, price: 18.32, buy: 0 },
      { name: "data4", sell: 21.94, price: 17.87, buy: 0 },
      { name: "data5", sell: 3.66, price: 17.43, buy: 0 },
      { name: "data6", sell: 4.12, price: 16.99, buy: 0 },
      { name: "data7", sell: 0, price: 16.54, buy: 5.8 },
      { name: "data8", sell: 0, price: 16.1, buy: 18.2 },
      { name: "data9", sell: 0, price: 15.66, buy: 13.09 },
      { name: "data10", sell: 0, price: 15.21, buy: 3.05 },
      { name: "data11", sell: 0, price: 14.77, buy: 22.64 },
      { name: "data12", sell: 0, price: 0, buy: 47.61 },
    ],
  },
  {
    name: "table43",
    datas: [
      { name: "data1", sell: 21.42, price: 0, buy: 0 },
      { name: "data2", sell: 1.61, price: 19.2, buy: 0 },
      { name: "data3", sell: 13.98, price: 18.36, buy: 0 },
      { name: "data4", sell: 7.82, price: 17.51, buy: 0 },
      { name: "data5", sell: 9.2, price: 16.67, buy: 0 },
      { name: "data6", sell: 5.1, price: 15.82, buy: 0 },
      { name: "data7", sell: 0, price: 14.98, buy: 2.81 },
      { name: "data8", sell: 0, price: 14.13, buy: 8.53 },
      { name: "data9", sell: 0, price: 13.29, buy: 33.66 },
      { name: "data10", sell: 0, price: 12.44, buy: 12.5 },
      { name: "data11", sell: 0, price: 11.6, buy: 10.67 },
      { name: "data12", sell: 0, price: 0, buy: 1.52 },
    ],
  },
  {
    name: "table44",
    datas: [
      { name: "data1", sell: 28.53, price: 0, buy: 0 },
      { name: "data2", sell: 3.49, price: 17.61, buy: 0 },
      { name: "data3", sell: 6.86, price: 17.53, buy: 0 },
      { name: "data4", sell: 33.32, price: 17.44, buy: 0 },
      { name: "data5", sell: 1.21, price: 17.36, buy: 0 },
      { name: "data6", sell: 5.22, price: 17.27, buy: 0 },
      { name: "data7", sell: 0, price: 17.18, buy: 6.48 },
      { name: "data8", sell: 0, price: 17.1, buy: 3.81 },
      { name: "data9", sell: 0, price: 17.01, buy: 12.35 },
      { name: "data10", sell: 0, price: 16.93, buy: 5.73 },
      { name: "data11", sell: 0, price: 16.84, buy: 0.39 },
      { name: "data12", sell: 0, price: 0, buy: 36.25 },
    ],
  },
  {
    name: "table45",
    datas: [
      { name: "data1", sell: 5.12, price: 0, buy: 0 },
      { name: "data2", sell: 6.57, price: 18.38, buy: 0 },
      { name: "data3", sell: 9.54, price: 17.72, buy: 0 },
      { name: "data4", sell: 13.68, price: 17.06, buy: 0 },
      { name: "data5", sell: 1.95, price: 16.4, buy: 0 },
      { name: "data6", sell: 1.5, price: 15.75, buy: 0 },
      { name: "data7", sell: 0, price: 15.09, buy: 4.64 },
      { name: "data8", sell: 0, price: 14.43, buy: 18.41 },
      { name: "data9", sell: 0, price: 13.77, buy: 37.72 },
      { name: "data10", sell: 0, price: 13.12, buy: 2.16 },
      { name: "data11", sell: 0, price: 12.46, buy: 28.39 },
      { name: "data12", sell: 0, price: 0, buy: 56.03 },
    ],
  },
  {
    name: "table46",
    datas: [
      { name: "data1", sell: 33.67, price: 0, buy: 0 },
      { name: "data2", sell: 11.01, price: 17.12, buy: 0 },
      { name: "data3", sell: 36.61, price: 16.6, buy: 0 },
      { name: "data4", sell: 21.65, price: 16.08, buy: 0 },
      { name: "data5", sell: 2.52, price: 15.57, buy: 0 },
      { name: "data6", sell: 0.03, price: 15.05, buy: 0 },
      { name: "data7", sell: 0, price: 14.53, buy: 0.77 },
      { name: "data8", sell: 0, price: 14.01, buy: 19.14 },
      { name: "data9", sell: 0, price: 13.5, buy: 31.22 },
      { name: "data10", sell: 0, price: 12.98, buy: 5.87 },
      { name: "data11", sell: 0, price: 12.46, buy: 14.37 },
      { name: "data12", sell: 0, price: 0, buy: 20.55 },
    ],
  },
  {
    name: "table47",
    datas: [
      { name: "data1", sell: 19.03, price: 0, buy: 0 },
      { name: "data2", sell: 9.19, price: 17.84, buy: 0 },
      { name: "data3", sell: 8.8, price: 17.33, buy: 0 },
      { name: "data4", sell: 15.99, price: 16.81, buy: 0 },
      { name: "data5", sell: 6.94, price: 16.29, buy: 0 },
      { name: "data6", sell: 4.41, price: 15.78, buy: 0 },
      { name: "data7", sell: 0, price: 15.26, buy: 5.74 },
      { name: "data8", sell: 0, price: 14.74, buy: 1.1 },
      { name: "data9", sell: 0, price: 14.22, buy: 37.35 },
      { name: "data10", sell: 0, price: 13.71, buy: 8.23 },
      { name: "data11", sell: 0, price: 13.19, buy: 1.81 },
      { name: "data12", sell: 0, price: 0, buy: 33.39 },
    ],
  },
  {
    name: "table48",
    datas: [
      { name: "data1", sell: 6.11, price: 0, buy: 0 },
      { name: "data2", sell: 9.45, price: 17.82, buy: 0 },
      { name: "data3", sell: 27.43, price: 17.2, buy: 0 },
      { name: "data4", sell: 26.7, price: 16.57, buy: 0 },
      { name: "data5", sell: 5.97, price: 15.95, buy: 0 },
      { name: "data6", sell: 4.55, price: 15.32, buy: 0 },
      { name: "data7", sell: 0, price: 14.7, buy: 4.79 },
      { name: "data8", sell: 0, price: 14.07, buy: 8.12 },
      { name: "data9", sell: 0, price: 13.45, buy: 33.4 },
      { name: "data10", sell: 0, price: 12.82, buy: 3.58 },
      { name: "data11", sell: 0, price: 12.2, buy: 11.53 },
      { name: "data12", sell: 0, price: 0, buy: 29.01 },
    ],
  },
];
