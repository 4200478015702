import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import "./css/UserList.css";

// 発電契約者
import { Box, Typography } from "@mui/material";
import SetPowerContractList from "./SetPowerContractList";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import { LanguageContext } from "../common/localization/localization";
import { powerContractorInfo } from "../../types/master/PowerContractList";
import {
  ModalButton,
  NewRegistrationButton,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterButton";
import {
  StyledMasterTableCell,
  StyledMasterTableContainer,
  StyledMasterTable,
  StyledMasterTableHeader,
  StyledTableHeaderBig,
  StyledTableHeaderSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomTableEx";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";

export enum modalMode {
  editMode,
  addMode,
}

const PowerContractList = () => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const userRoleContext = useContext(RoleContext);
  // 通信エラー
  const api = useAuthedApi();
  //エリア名作成
  const { convertIdToAreaName } = useAreaUtility();

  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);
  //showがtrueになった時、モーダルが開かれる
  const [show, setShow] = useState(false);

  //DBから取得したデータをpowerContractInfoに格納して描画
  const [powerContractInfo, setPowerContractInfo] =
    useState<powerContractorInfo[]>();

  //編集モードか、新規登録モードかを管理する 1:新規追加 2：編集
  const [mode, setMode] = useState<modalMode>(modalMode.addMode);

  //編集ボタンを押した行の発電契約者ID情報
  const [contractIdForEdit, setContractIdForEdit] = useState<number>(0);

  //powerContractInfo初期化
  useEffect(() => {
    setPowerContractInfo([
      {
        contractId: 0,
        contractorName: "",
        contractorKindNum: 0,
        areaId: "",
        remarks: "",
        contractCode: "",
      },
    ]);
    //ロール検査
    checkEditRoll();
  }, []);

  // 全件表示（現状モーダル表示を管理するshowがfalseになったタイミングで描写）
  useEffect(() => {
    //再レンダリングのため2回loop
    for (let i = 0; i < 2; i++) {
      requestPowerContractInfo();
    }
  }, [show]);

  // 設定モーダル開く
  const HandleOpen = () => setShow(true);
  // 設定モーダル閉じる
  const HandleClose = () => setShow(false);

  //設定モーダルを新規追加モードで開く
  const HandleOpenForAddNew = () => {
    //新規追加モードに
    setMode(modalMode.addMode);
    //bgCodePK内の情報を一応0で初期化
    setContractIdForEdit(0);

    setShow(true);
  };

  //設定モーダルを編集モードで開く
  const HandleOpenForEdit = (contractId: number) => {
    //編集モードに
    setMode(modalMode.editMode);
    //行のPK値を設定
    setContractIdForEdit(contractId);

    setShow(true);
  };

  //データベースから発電契約者一覧取得
  const requestPowerContractInfo = () => {
    // 発電契約者一覧取得
    //console.log("発電契約者一覧取得開始");
    let responseData: powerContractorInfo[] = [];
    api
      .get(API_URL.GET_POWERCONTRACTOR_LIST)
      .then((response) => {
        //console.log(response.data.result);
        responseData = response.data.result as powerContractorInfo[];
        dispDatas(responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 編集可能ユーザか確認
  const checkEditRoll = () => {
    const userRoll = userRoleContext.role;
    api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: userRoll } })
      .then((response) => {
        const edit = response.data;
        // 管理者権限がある時のみ編集等の書き込みを有効にする
        if (edit == true) {
          setEnableEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 取ってきたデータが空であったり、異常なものでないかチェックしてから表示
  const dispDatas = (responseData: powerContractorInfo[]) => {
    let powerContractList: powerContractorInfo[] = [];
    //const datas: any[] = [];
    //取ってきたデータがPowerBGInfo型のものかチェック
    try {
      powerContractList = responseData;
      //console.log(powerContractList);
    } catch (error) {
      console.log(error);
    }

    if (powerContractList === undefined) {
      return;
    }

    setPowerContractInfo(powerContractList);
  };

  //電源種別番号で取ってきているため、名前に変換する
  const convertKindNumToKindName = (kindNo: number): string => {
    let kindName = "";

    //console.log(powerContractInfo);

    switch (kindNo) {
      case 1:
        kindName = languageContext.words.power_contractor_common;
        break;
      case 2:
        kindName =
          languageContext.words.power_contractor_other_companies_procurement;
        break;
      default:
        break;
    }
    //console.log(kindName);
    return kindName;
  };

  const TableCustomLayout = styled(TableCell)(() => ({
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  }));

  return (
    <div className="cn-setting-display">
      <Box
        sx={{
          width: "100%",
          height: "20%",
          display: "flex",
          paddingBottom: "17px",
        }}
      >
        <NewRegistrationButton
          style={{ display: !enableEdit ? "none" : undefined }}
          variant="outlined"
          onClick={() => HandleOpenForAddNew()}
        >
          {languageContext.words.new_registration}
        </NewRegistrationButton>
        {SetPowerContractList(show, HandleClose, mode, contractIdForEdit)}
      </Box>
      <StyledMasterTableContainer>
        <StyledMasterTable>
          <StyledMasterTableHeader>
            <TableRow>
              <StyledTableHeaderBig>
                {languageContext.words.power_gen_contractor}
              </StyledTableHeaderBig>
              <StyledTableHeaderSmall>
                {languageContext.words.plan_submitter_code}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.power_contractor}
              </StyledTableHeaderSmall>
              <StyledTableHeaderSmall>
                {languageContext.words.connection_area}
              </StyledTableHeaderSmall>

              <StyledTableHeaderBig>
                {languageContext.words.remarks}
              </StyledTableHeaderBig>
            </TableRow>
          </StyledMasterTableHeader>
          <TableBody>
            {powerContractInfo?.map((contractor) => (
              <TableRow key={contractor.contractId}>
                <StyledMasterTableCell sx={{ textAlign: "left !important" }}>
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                    <Typography
                      title={contractor.contractorName}
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {contractor.contractorName}
                    </Typography>
                    <ModalButton
                      style={{ display: !enableEdit ? "none" : undefined }}
                      sx={{ marginLeft: "auto" }}
                      variant="outlined"
                      onClick={() => HandleOpenForEdit(contractor.contractId)}
                    >
                      {languageContext.words.edit}
                    </ModalButton>
                  </Box>
                </StyledMasterTableCell>
                <StyledMasterTableCell title={contractor.contractCode}>
                  {contractor.contractCode}
                </StyledMasterTableCell>
                <StyledMasterTableCell
                  title={convertKindNumToKindName(contractor.contractorKindNum)}
                >
                  {convertKindNumToKindName(contractor.contractorKindNum)}
                </StyledMasterTableCell>
                <StyledMasterTableCell title={contractor.areaId}>
                  {convertIdToAreaName(contractor.areaId)}
                </StyledMasterTableCell>

                <StyledMasterTableCell
                  title={contractor.remarks}
                  sx={{ textAlign: "left !important" }}
                >
                  {contractor.remarks}
                </StyledMasterTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledMasterTable>
      </StyledMasterTableContainer>
    </div>
  );
};

export default PowerContractList;
