import React, { useContext } from "react";
import Box from "@mui/material/Box";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Typography } from "@mui/material";
import { PaletteContext } from "../../common/paletteMode";
import { ErrorTitleContext } from "../../common/customComponents/ErrorTitleProvider";
import { ErrorMessageContext } from "../../common/customComponents/ErrorMessageProvider";
import { LanguageContext } from "../../common/localization/localization";

const ConnectionError = () => {
  // 言語コンテキスト
  const { words } = useContext(LanguageContext);
  const { title } = useContext(ErrorTitleContext);
  const { message } = useContext(ErrorMessageContext);
  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

    return (
      <div
        key={"network_error_div"}
        // ダークモード対応
        className={`cn-main-display ${PaletteMode === "dark" ? "dark" : "light"}`}
      >
        <Box
          sx={{
            height: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <ErrorOutlineIcon
            sx={{ fontSize: "96px", margin: "8px" }}
            color="error"
          />
          <Typography sx={{ fontWeight: "bold" }} variant="h5" margin="8px">
            {words[title]}
          </Typography>
          <Typography margin="8px">{words[message]}</Typography>
        </Box>
      </div>
    );
};
export default ConnectionError;
