import React, { useState, useEffect, useContext } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { PickersDay } from "@mui/x-date-pickers";
import { addDays, getMonth, getYear, parse, subDays } from "date-fns";
import ja from "date-fns/locale/ja";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { LanguageContext } from "../localization/localization";
import { PaletteContext } from "../paletteMode";

const CustomDatePicker = (props?: any) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  // ダークモード対応
  const palletContextBase = useContext(PaletteContext);

  const { setValue, value, maxDate, minDate, enableEdit } = props;
  const [date, setDate] = useState<Date | undefined>(
    value === undefined ? addDays(new Date(), 1) : value
  );

  useEffect(() => {
    setDate(value === undefined ? addDays(new Date(), 1) : value);
  }, [value]);

  const isAddBtnDisabled = () => {
    let disabled = false;
    if (enableEdit == true || enableEdit == undefined) {
      if (maxDate !== undefined) {
        if (date?.toLocaleDateString() === maxDate.toLocaleDateString()) {
          disabled = true;
        }
      }
    } else if (enableEdit == false) {
      disabled = true;
    }
    return disabled;
  };

  const isSubBtnDisabled = () => {
    let disabled = false;
    if (enableEdit == true || enableEdit == undefined) {
      if (minDate !== undefined) {
        if (date?.toLocaleDateString() === minDate.toLocaleDateString()) {
          disabled = true;
        }
      }
    } else if (enableEdit == false) {
      disabled = true;
    }
    return disabled;
  };

  return (
    <Stack
      direction="row"
      width="fit-content"
      alignItems="center"
      sx={{ margin: 0 }}
      spacing={0.5}
    >
      <Button
        sx={{
          color: `${
            palletContextBase.PaletteMode === "dark" ? "white" : "black"
          }`,
          backgroundColor: "#00000000",
          height: "40px",
          minWidth: "25px",
          margin: 0,
          padding: 0,
        }}
        disabled={isSubBtnDisabled()}
        onClick={() => {
          const tempSub =
            date === null ? addDays(new Date(), 1) : (date as Date);
          setDate(subDays(tempSub, 1));
          setValue(subDays(tempSub, 1));
        }}
      >
        <ArrowLeftIcon />
      </Button>
      <Box
        sx={{
          display: "flex",
          width: "160px",
          borderRadius: "5px",
          backgroundColor: "white",
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={languageContext.mode === "jp" ? ja : undefined}
          dateFormats={
            languageContext.mode === "jp"
              ? { monthAndYear: "yyyy年 MM月" }
              : undefined
          }
        >
          <DatePicker
            readOnly={enableEdit === false ? true : false}
            minDate={minDate}
            maxDate={maxDate}
            value={date}
            onChange={(e) => {
              setDate(e);
              setValue(e);
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                sx={{
                  width: "100%",
                }}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  placeholder:
                    languageContext.mode === "jp" ? "yyyy/MM/dd" : "MM/dd/yyyy",
                }}
              />
            )}
            renderDay={(day, selectedDays, pickersDayProps) => (
              <PickersDay
                {...pickersDayProps}
                sx={{ backgroundColor: "inherit" }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Button
        sx={{
          color: `${
            palletContextBase.PaletteMode === "dark" ? "white" : "black"
          }`,
          backgroundColor: "#00000000",
          height: "40px",
          minWidth: "25px",
          margin: 0,
          padding: 0,
        }}
        disabled={isAddBtnDisabled()}
        onClick={() => {
          const tempAdd =
            date === null ? addDays(new Date(), 1) : (date as Date);
          setDate(addDays(tempAdd, 1));
          setValue(addDays(tempAdd, 1));
        }}
      >
        <ArrowRightIcon />
      </Button>
    </Stack>
  );
};

export default CustomDatePicker;
