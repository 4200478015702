import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import "./css/SetUserList.css";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import { LanguageContext } from "../common/localization/localization";
import { modalMode } from "./PowerBp";
import { powerBpInfo } from "../../types/master/PowerBp";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import CloseIcon from "@mui/icons-material/Close";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";

type role = {
  role_id: string;
  role_nm: string;
};

const CustomTextField = styled(TextField)(() => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "auto",
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
}));

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  bpName: boolean;
  bpCode: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  bpName: false,
  bpCode: false,
};

//引数で編集/新規登録 を判断する。編集の場合情報もらう
const SetPowerBp = (
  toOpen: boolean,
  HandleClose: () => void,
  mode: modalMode,
  bpId: number
) => {
  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  // 通信エラー
  const api = useAuthedApi();

  //事業者種別のリスト
  const BpKind = useMemo(
    () => [
      { id: 0, name: languageContext.words.power_bp_setting_bp_kind_1 },
      { id: 1, name: languageContext.words.power_bp_setting_bp_kind_2 },
    ],
    [languageContext.words]
  );

  ///.log("編集 or 新規登録開始 引数情報⇒", toOpen, mode);

  type searchInfo = { id: string; name: string };
  const [powerBpInfo, setPowerBpInfo] = useState<powerBpInfo>();
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する
  // 初期化用
  const initializeUserListInfo: powerBpInfo = {
    bpId: 0,
    bpKind: 0,
    bpName: "",
    bpCode: "",
    targetArea: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  };

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HandleClose();
    }
  }, [digOpen]); //初期化
  ////

  //初期化する
  useEffect(() => {
    setPowerBpInfo(initializeUserListInfo);
  }, []);

  useEffect(() => {
    if (toOpen === true) {
      if (mode === modalMode.editMode) {
        getRecordUserList();
      } else {
        setPowerBpInfo(initializeUserListInfo);
      }
    } else {
      setPowerBpInfo(initializeUserListInfo);
      setInputError(initErrorInput);
    }
  }, [toOpen]);

  // 事業者レコード取得 (編集時に発火)
  const getRecordUserList = () => {
    //console.log("事業者1レコード取得します");
    let responseData: powerBpInfo;
    api
      .get(API_URL.GET_BP_DETAIL, { params: { bpId } })
      .then((response) => {
        responseData = {
          bpId: response.data.bpId,
          bpKind: response.data.bpKind,
          bpName: response.data.bpName,
          bpCode: response.data.bpCode,
          targetArea: response.data.targetArea,
        } as powerBpInfo;

        setPowerBpInfo(responseData);
      })
      .catch((err) => {
        console.log(err);
        setPowerBpInfo(initializeUserListInfo);
      });
  };

  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    const tmp: powerBpInfo = Object.assign({}, powerBpInfo);

    switch (name) {
      case "bpKind":
        tmp.bpKind = parseInt(value);
        break;
      case "bpName":
        tmp.bpName = value;
        break;
      case "bpCode":
        tmp.bpCode = value;
        break;
      case "eria01":
        if (parseInt(value) == 0) {
          tmp.targetArea[0] = 1;
          // checkBox.check1 = true;
        } else if (parseInt(value) == 1) {
          tmp.targetArea[0] = 0;
          // checkBox.check1 = false;
        }
        break;
      case "eria02":
        if (parseInt(value) == 0) {
          tmp.targetArea[1] = 1;
          // checkBox.check2 = true;
        } else if (parseInt(value) == 1) {
          tmp.targetArea[1] = 0;
          // checkBox.check2 = false;
        }
        break;
      case "eria03":
        if (parseInt(value) == 0) {
          tmp.targetArea[2] = 1;
          // checkBox.check3 = true;
        } else if (parseInt(value) == 1) {
          tmp.targetArea[2] = 0;
          // checkBox.check3 = false;
        }
        break;
      case "eria04":
        if (parseInt(value) == 0) {
          tmp.targetArea[3] = 1;
          // checkBox.check4 = true;
        } else if (parseInt(value) == 1) {
          tmp.targetArea[3] = 0;
          // checkBox.check4 = false;
        }
        break;
      case "eria05":
        if (parseInt(value) == 0) {
          tmp.targetArea[4] = 1;
          // checkBox.check5 = true;
        } else if (parseInt(value) == 1) {
          tmp.targetArea[4] = 0;
          // checkBox.check5 = false;
        }
        break;
      case "eria06":
        if (parseInt(value) == 0) {
          tmp.targetArea[5] = 1;
          // checkBox.check6 = true;
        } else if (parseInt(value) == 1) {
          tmp.targetArea[5] = 0;
          // checkBox.check6 = false;
        }
        break;
      case "eria07":
        if (parseInt(value) == 0) {
          tmp.targetArea[6] = 1;
          // checkBox.check7 = true;
        } else if (parseInt(value) == 1) {
          tmp.targetArea[6] = 0;
          // checkBox.check7 = false;
        }
        break;
      case "eria08":
        if (parseInt(value) == 0) {
          tmp.targetArea[7] = 1;
          // checkBox.check8 = true;
        } else if (parseInt(value) == 1) {
          tmp.targetArea[7] = 0;
          // checkBox.check8 = false;
        }
        break;
      case "eria09":
        if (parseInt(value) == 0) {
          tmp.targetArea[8] = 1;
          // checkBox.check9 = true;
        } else if (parseInt(value) == 1) {
          tmp.targetArea[8] = 0;
          // checkBox.check9 = false;
        }
        break;
      case "eria10":
        if (parseInt(value) == 0) {
          tmp.targetArea[9] = 1;
          // checkBox.check10 = true;
        } else if (parseInt(value) == 1) {
          tmp.targetArea[9] = 0;
          // checkBox.check10 = false;
        }
        break;
    }

    setPowerBpInfo(tmp);
  }

  //カスタムセレクトリストはonchangeに数値を返すようになっているので
  //別で用意
  function onChangeBpKind(id: number) {
    const tmp: powerBpInfo = Object.assign({}, powerBpInfo);
    tmp.bpKind = id;
    setPowerBpInfo(tmp);
  }

  //発電事業者削除
  const CheckPowerBpDelete = (id: number) => {
    api
      .post(API_URL.DELETE_BP, powerBpInfo)
      .then((res) => {
        setJobClear(true);
        setDialogMessage(languageContext.words.power_bp_setting_deleted);
        console.log(res.data);
      })
      .catch((err) => {
        setDialogMessage(languageContext.words.power_bp_setting_delete_failed);
        console.log(err);
      });
    setDigOpen(true);
  };

  const SaveClose = () => {
    //console.log("保存して閉じます");
    if (isCorrectInfo()) {
      if (mode === modalMode.addMode) {
        InsertRecord();
      } else {
        UpdateRecord();
      }
    } else {
      setDialogMessage(languageContext.words.setting_failed_new_registration);
      setDigOpen(true);
    }
  };

  //正しい情報かどうか検査
  const isCorrectInfo = (): boolean => {
    let error = true;

    const tempInputErrors = Object.assign({}, initErrorInput);
    if (powerBpInfo?.bpName == "") {
      error = false;
      tempInputErrors.bpName = true;
    }
    if (powerBpInfo?.bpCode == "" || powerBpInfo?.bpCode.length != 4) {
      error = false;
      tempInputErrors.bpCode = true;
    }
    setInputError(tempInputErrors);
    return error;
  };

  //新規登録
  const InsertRecord = () => {
    api
      .post(API_URL.INSERT_BP, powerBpInfo)
      .then((res) => {
        if (res.data.isSuccess) {
          setJobClear(true);
          setDialogMessage(
            languageContext.words.power_bp_setting_new_registered
          );
        } else if (res.data.isDuplicated) {
          setDialogMessage(
            languageContext.words.power_bp_setting_err_message_1
          );
        } else if (res.data.isAllAreaEmpty) {
          setDialogMessage(
            languageContext.words.power_bp_setting_err_message_2
          );
        }
        setDigOpen(true);
      })
      .catch((err) => {
        setDialogMessage(
          languageContext.words.power_bp_setting_failed_register
        );
        setDigOpen(true);
        console.log(err);
      });
  };

  const UpdateRecord = () => {
    api
      .post(API_URL.UPDATE_BP, powerBpInfo)
      .then((res) => {
        if (res.data.isSuccess) {
          setJobClear(res.data.isSuccess);
          setDialogMessage(languageContext.words.power_bp_setting_updated);
        } else if (res.data.isAllAreaEmpty) {
          setDialogMessage(
            languageContext.words.power_bp_setting_err_message_2
          );
        }
        setDigOpen(true);
      })
      .catch((err) => {
        setDialogMessage(languageContext.words.power_bp_setting_failed_update);
        setDigOpen(true);
        console.log(err);
      });
  };

  const acceptHandler = () => {
    // 特になし
  };

  return (
    <>
      <CustomSettingModal
        title={languageContext.words.power_bp_setting_title}
        open={toOpen}
        onAcceptLeft={() => SaveClose()}
        onAcceptRight={() => CheckPowerBpDelete(bpId)}
        onClose={() => HandleClose()}
        btnTextLeft={languageContext.words.registration}
        btnTextRight={languageContext.words.delete}
        btnType={
          mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
        }
      >
        <Stack direction="column" sx={{ height: "370px", width: "550px" }}>
          <CustomBoxBig>
            <CustomModalTypography>
              {languageContext.words.power_bp_name}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="text"
              value={powerBpInfo?.bpName}
              onChange={onChangeValue}
              name="bpName"
              disabled={mode === modalMode.addMode ? false : true}
              inputProps={{
                maxLength: 64,
              }}
              error={inputError.bpName}
              helperText={languageContext.words.setting_required}
            ></CustomTextFieldBig>
          </CustomBoxBig>

          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.power_bp_code}
            </CustomModalTypography>
            <CustomTextFieldSmall
              type="text"
              value={powerBpInfo?.bpCode}
              onChange={onChangeValue}
              name="bpCode"
              inputProps={{
                maxLength: 4,
              }}
              FormHelperTextProps={{
                sx: {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
                title:
                  languageContext.words.setting_required +
                  languageContext.words.setting_available_characters_6,
              }}
              error={inputError.bpCode}
              helperText={
                languageContext.words.setting_required +
                languageContext.words.setting_available_characters_6
              }
            ></CustomTextFieldSmall>
          </CustomBoxSmall>

          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.new_power_kind}
            </CustomModalTypography>
            <CustomSelectListSmall
              value={powerBpInfo?.bpKind}
              options={BpKind}
              onChange={onChangeBpKind}
              name="bpKind"
            ></CustomSelectListSmall>
          </CustomBoxSmall>

          <FormGroup>
            <Grid container spacing={2} columns={10}>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={powerBpInfo?.targetArea[0]}
                      checked={powerBpInfo?.targetArea[0] == 1}
                      onChange={onChangeValue}
                      name="eria01"
                    />
                  }
                  label={languageContext.words.hokkaido_region}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={powerBpInfo?.targetArea[1]}
                      checked={powerBpInfo?.targetArea[1] == 1}
                      onChange={onChangeValue}
                      name="eria02"
                    />
                  }
                  label={languageContext.words.tohoku_region}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={powerBpInfo?.targetArea[2]}
                      checked={powerBpInfo?.targetArea[2] == 1}
                      onChange={onChangeValue}
                      name="eria03"
                    />
                  }
                  label={languageContext.words.tokyo_region}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={powerBpInfo?.targetArea[3]}
                      checked={powerBpInfo?.targetArea[3] == 1}
                      onChange={onChangeValue}
                      name="eria04"
                    />
                  }
                  label={languageContext.words.chubu_region}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={powerBpInfo?.targetArea[4]}
                      checked={powerBpInfo?.targetArea[4] == 1}
                      onChange={onChangeValue}
                      name="eria05"
                    />
                  }
                  label={languageContext.words.hokuriku_region}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={powerBpInfo?.targetArea[5]}
                      checked={powerBpInfo?.targetArea[5] == 1}
                      onChange={onChangeValue}
                      name="eria06"
                    />
                  }
                  label={languageContext.words.kansai_region}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={powerBpInfo?.targetArea[6]}
                      checked={powerBpInfo?.targetArea[6] == 1}
                      onChange={onChangeValue}
                      name="eria07"
                    />
                  }
                  label={languageContext.words.chugoku_region}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={powerBpInfo?.targetArea[7]}
                      checked={powerBpInfo?.targetArea[7] == 1}
                      onChange={onChangeValue}
                      name="eria08"
                    />
                  }
                  label={languageContext.words.shikoku_region}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={powerBpInfo?.targetArea[8]}
                      checked={powerBpInfo?.targetArea[8] == 1}
                      onChange={onChangeValue}
                      name="eria09"
                    />
                  }
                  label={languageContext.words.kyushu_region}
                />
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={powerBpInfo?.targetArea[9]}
                      checked={powerBpInfo?.targetArea[9] == 1}
                      onChange={onChangeValue}
                      name="eria10"
                    />
                  }
                  label={languageContext.words.okinawa_region}
                />
              </Grid>
            </Grid>
          </FormGroup>
          <Grid item xs={4}></Grid>
        </Stack>
      </CustomSettingModal>
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </>
  );
};

export default SetPowerBp;
