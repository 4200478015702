// typesを使用したデータを配置する場所

export const areaNames = [
];

export const times = [
  "00:00～00:30",
  "00:30～01:00",
  "01:00～01:30",
  "01:30～02:00",
  "02:00～02:30",
  "02:30～03:00",
  "03:00～03:30",
  "03:30～04:00",
  "04:00～04:30",
  "04:30～05:00",
  "05:00～05:30",
  "05:30～06:00",
  "06:00～06:30",
  "06:30～07:00",
  "07:00～07:30",
  "07:30～08:00",
  "08:00～08:30",
  "08:30～09:00",
  "09:00～09:30",
  "09:30～10:00",
  "10:00～10:30",
  "10:30～11:00",
  "11:00～11:30",
  "11:30～12:00",
  "12:00～12:30",
  "12:30～13:00",
  "13:00～13:30",
  "13:30～14:00",
  "14:00～14:30",
  "14:30～15:00",
  "15:00～15:30",
  "15:30～16:00",
  "16:00～16:30",
  "16:30～17:00",
  "17:00～17:30",
  "17:30～18:00",
  "18:00～18:30",
  "18:30～19:00",
  "19:00～19:30",
  "19:30～20:00",
  "20:00～20:30",
  "20:30～21:00",
  "21:00～21:30",
  "21:30～22:00",
  "22:00～22:30",
  "22:30～23:00",
  "23:00～23:30",
  "23:30～00:00",
];

// ======================データ型に合わせたテストデータ======================

// スポット入札登録
export interface reqBit {
  targetDate: Date;
  area: number;
}

export interface bitInfo {
  targetDate: Date;
  area: number;
  status: number;
  ucTender: number;
  blockNumber: number;
  bitDate: Date;
  tradeType: number;
  tenderCount: number;
  dayBit: dayBitCell[];
  id:string
}

// 1日の48コマ
export interface dayBitCell {
  tender: number;
  cells: bitCell[]; // 48コマ
}

// 1コマ
export interface bitCell {
  powerPrice: number;
  powerVolume: number;
}

// 入札制限
export interface bitLimmitReq {
  area: number;
  // target: number; // 売り or 買い
  dayLimitBit: bitLimitCells[];
}

// 1日の48コマ(制限)
export interface bitLimitCells {
  type: number;
  cells: bitLimitCell[];
}

// 1コマ(制限)
export interface bitLimitCell {
  upperPowerVolume: string;
  lowerPowerVolume: string;
  upperPowerPrice: string;
  lowerPowerPrice: string;
}

export interface reqPreviosBit {
  targetDate: Date;
  area: number;
}

// 約定情報
export interface previousBit {
  targetDate: Date;
  resultCells: dayBitResultCell;
}

// 1日の48コマ
export interface dayBitResultCell {
  // cells: bitCell[]; // 48コマ
  cells: bitResultCell[]; // 48コマ
}

// 1コマ(約定結果)
export interface bitResultCell {
  result: string;
  fixedPowerVolume: number;
  fixedPowerPrice: number;
}

// ---------------------- 登録用 ----------------------
// スポット入札登録
export interface reqBitRegist {
  targetDate: Date;
  area: number;
  ucTender: number;
  blockNumber: number; // 通常の場合は0固定
  tradeType: number;
  tenderCount: number;
  dayBit: dayBitCell[];
  submitDate: Date;
  submitUser: string;
}

// 1日の48コマ
export interface dayBitCell {
  tender: number;
  cells: bitCell[];
}

// 1コマ
export interface bitCell {
  powerVolume: number;
  powerPrice: number;
}

export type resBitRegist = {
  result: boolean;
  message: string;
};

// ---------------------- 計画データ ----------------------
// 計画取得
export interface reqTargetPowerPlan {
  targetDate: Date;
  area: number;
}

// 調達計画
export interface provideData {
  targetDate: Date;
  dayTotal: number;
  cellVolumes: number[];
}

// 販売計画
export interface saleData {
  targetDate: Date;
  dayTotal: number;
  cellVolumes: number[];
}

// 自己託送計画
export interface supplyData {
  targetDate: Date;
  dayTotal: number;
  cellVolumes: number[];
}

// //入札制限のデータ
// const bottomSellLimit: bitLimitCells = {
//   type: 0,
//   cells: [
//     { powerVolume: 0.00, powerPrice: 10.00 },
//     { powerVolume: 0.00, powerPrice: 10.00 },
//     { powerVolume: 0.00, powerPrice: 10.00 },
//     { powerVolume: 0.00, powerPrice: 10.00 },
//     { powerVolume: 0.00, powerPrice: 10.00 },
//     { powerVolume: 0.00, powerPrice: 10.00 },
//     { powerVolume: 0.00, powerPrice: 11.00 },
//     { powerVolume: 0.00, powerPrice: 11.00 },
//     { powerVolume: 0.00, powerPrice: 12.00 },
//     { powerVolume: 0.00, powerPrice: 12.00 },
//     { powerVolume: 0.00, powerPrice: 11.00 },
//     { powerVolume: 0.00, powerPrice: 11.00 },
//     { powerVolume: 0.00, powerPrice: 12.00 },
//     { powerVolume: 0.00, powerPrice: 12.00 },
//     { powerVolume: 0.20, powerPrice: 13.00 },
//     { powerVolume: 0.40, powerPrice: 13.00 },
//     { powerVolume: 0.60, powerPrice: 14.00 },
//     { powerVolume: 0.85, powerPrice: 12.00 },
//     { powerVolume: 1.05, powerPrice: 14.00 },
//     { powerVolume: 1.20, powerPrice: 13.00 },
//     { powerVolume: 1.30, powerPrice: 12.00  },
//     { powerVolume: 1.40, powerPrice: 11.00  },
//     { powerVolume: 1.45, powerPrice: 11.00  },
//     { powerVolume: 1.50, powerPrice: 10.00  },
//     { powerVolume: 1.50, powerPrice: 10.00  },
//     { powerVolume: 1.50, powerPrice: 10.00  },
//     { powerVolume: 1.50, powerPrice: 11.00  },
//     { powerVolume: 1.45, powerPrice: 11.00  },
//     { powerVolume: 1.35, powerPrice: 10.00  },
//     { powerVolume: 1.20, powerPrice: 10.00  },
//     { powerVolume: 1.00, powerPrice: 10.00  },
//     { powerVolume: 0.80, powerPrice: 10.00  },
//     { powerVolume: 0.60, powerPrice: 11.00  },
//     { powerVolume: 0.40, powerPrice: 12.00  },
//     { powerVolume: 0.25, powerPrice: 13.00  },
//     { powerVolume: 0.13, powerPrice: 14.00  },
//     { powerVolume: 0.00, powerPrice: 15.00  },
//     { powerVolume: 0.00, powerPrice: 15.00  },
//     { powerVolume: 0.00, powerPrice: 15.00  },
//     { powerVolume: 0.00, powerPrice: 15.00  },
//     { powerVolume: 0.00, powerPrice: 15.00  },
//     { powerVolume: 0.00, powerPrice: 14.00  },
//     { powerVolume: 0.00, powerPrice: 14.00  },
//     { powerVolume: 0.00, powerPrice: 14.00  },
//     { powerVolume: 0.00, powerPrice: 13.00  },
//     { powerVolume: 0.00, powerPrice: 12.00  },
//     { powerVolume: 0.00, powerPrice: 11.00  },
//     { powerVolume: 0.00, powerPrice: 10.00  },
//   ],
// };
// const TopSellLimit: bitLimitCells = {
//   type: 1,
//   cells: [
//     { powerVolume: 0.00, powerPrice: 19.00  },
//     { powerVolume: 0.00, powerPrice: 17.00  },
//     { powerVolume: 0.00, powerPrice: 17.00  },
//     { powerVolume: 0.00, powerPrice: 17.00  },
//     { powerVolume: 0.00, powerPrice: 17.00  },
//     { powerVolume: 0.00, powerPrice: 17.00  },
//     { powerVolume: 0.00, powerPrice: 18.00  },
//     { powerVolume: 0.00, powerPrice: 17.00  },
//     { powerVolume: 0.00, powerPrice: 17.00  },
//     { powerVolume: 0.00, powerPrice: 17.00  },
//     { powerVolume: 0.00, powerPrice: 17.00  },
//     { powerVolume: 0.00, powerPrice: 18.00  },
//     { powerVolume: 0.00, powerPrice: 19.00  },
//     { powerVolume: 0.00, powerPrice: 20.00  },
//     { powerVolume: 2.00, powerPrice: 20.00  },
//     { powerVolume: 4.00, powerPrice: 20.00  },
//     { powerVolume: 6.00, powerPrice: 21.00  },
//     { powerVolume: 8.50, powerPrice: 21.00  },
//     { powerVolume: 10.50, powerPrice: 20.00  },
//     { powerVolume: 12.00, powerPrice: 19.00  },
//     { powerVolume: 13.00, powerPrice: 19.00  },
//     { powerVolume: 14.00, powerPrice: 18.00  },
//     { powerVolume: 14.50, powerPrice: 17.00  },
//     { powerVolume: 15.00, powerPrice: 17.00  },
//     { powerVolume: 15.00, powerPrice: 16.00  },
//     { powerVolume: 15.00, powerPrice: 14.00  },
//     { powerVolume: 15.00, powerPrice: 15.00  },
//     { powerVolume: 14.50, powerPrice: 15.00  },
//     { powerVolume: 13.50, powerPrice: 16.00  },
//     { powerVolume: 12.00, powerPrice: 16.00  },
//     { powerVolume: 10.00, powerPrice: 16.00  },
//     { powerVolume: 8.00, powerPrice: 17.00  },
//     { powerVolume: 6.00, powerPrice: 17.00  },
//     { powerVolume: 4.00, powerPrice: 19.00  },
//     { powerVolume: 2.50, powerPrice: 20.00  },
//     { powerVolume: 1.25, powerPrice: 25.00  },
//     { powerVolume: 0.00, powerPrice: 26.00  },
//     { powerVolume: 0.00, powerPrice: 24.00  },
//     { powerVolume: 0.00, powerPrice: 22.00  },
//     { powerVolume: 0.00, powerPrice: 21.00  },
//     { powerVolume: 0.00, powerPrice: 21.00  },
//     { powerVolume: 0.00, powerPrice: 20.00  },
//     { powerVolume: 0.00, powerPrice: 20.00  },
//     { powerVolume: 0.00, powerPrice: 19.00  },
//     { powerVolume: 0.00, powerPrice: 19.00  },
//     { powerVolume: 0.00, powerPrice: 19.00  },
//     { powerVolume: 0.00, powerPrice: 18.00  },
//     { powerVolume: 0.00, powerPrice: 17.00  },
//   ],
// };

// const bottomBuyLimit: bitLimitCells = {
//   type: 2,
//   cells: [
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//   ],
// };
// const TopBuyLimit: bitLimitCells = {
//   type: 3,
//   cells: [
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//     { powerVolume: 0, powerPrice: 0 },
//   ],
// };

// export const matomeArray:bitLimmitReq = {area:1,dayLimitBit:[bottomSellLimit,TopSellLimit,bottomBuyLimit,TopBuyLimit]}

// TESTDATA 48コマ分の自己託送データ
export const supplys: supplyData = {
  targetDate: new Date("2023/03/14"),
  dayTotal: 0,
  cellVolumes: [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0,
  ],
};

// TESTDATA 48コマ分の販売計画データ
export const sales: saleData = {
  targetDate: new Date("2023/03/14"),
  dayTotal: 88.85,
  cellVolumes: [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.2,
    0.7, 0.9, 1.45, 3.0, 5.15, 7.15, 6.65, 6.25, 8.3, 8.75, 7.0, 7.95, 8.45,
    6.15, 3.5, 2.45, 2.25, 1.65, 0.7, 0.25, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
  ],
};

// TESTDATA 48コマ分の調達計画データ
export const provides: provideData = {
  targetDate: new Date("2023/03/14"),
  dayTotal: 0,
  cellVolumes: [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0,
  ],
};

//========== 通常入札 ==========
// TESTDATA 48コマ分の入札計画データ
export const spotBitInfos: bitInfo[] = [
  {
    targetDate: new Date("2023-03-14T11:20:00.886Z"),
    area: 1,
    status: 0,
    ucTender: 1,
    blockNumber: 1, // 1-5まで
    bitDate: new Date("2023-03-14T11:20:00.886Z"),
    tradeType: 1,
    tenderCount: 5, // 1-15札まで
    dayBit: [
      // 15札分コマ分
      {
        tender: 1,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 16.0,
            powerVolume: -0.2,
          },
          {
            powerPrice: 15.0,
            powerVolume: -0.7,
          },
          {
            powerPrice: 13.50,
            powerVolume: -0.9,
          },
          {
            powerPrice: 13.20,
            powerVolume: -1.45,
          },
          {
            powerPrice: 14.0,
            powerVolume: -3.0,
          },
          {
            powerPrice: 13.0,
            powerVolume: -5.15,
          },
          {
            powerPrice: 12.0,
            powerVolume: -7.15,
          },
          {
            powerPrice: 12.0,
            powerVolume: -6.65,
          },
          {
            powerPrice: 11.7,
            powerVolume: -6.25,
          },
          {
            powerPrice: 11.5,
            powerVolume: -8.3,
          },
          {
            powerPrice: 11.0,
            powerVolume: -8.75,
          },
          {
            powerPrice: 10.9,
            powerVolume: -7.0,
          },
          {
            powerPrice: 12.0,
            powerVolume: -7.95,
          },
          {
            powerPrice: 12.0,
            powerVolume: -8.45,
          },
          {
            powerPrice: 12.1,
            powerVolume: -6.15,
          },
          {
            powerPrice: 13.1,
            powerVolume: -3.5,
          },
          {
            powerPrice: 11.0,
            powerVolume: -2.45,
          },
          {
            powerPrice: 10.0,
            powerVolume: -2.25,
          },
          {
            powerPrice: 15.51,
            powerVolume: -1.65,
          },
          {
            powerPrice: 16.25,
            powerVolume: -0.7,
          },
          {
            powerPrice: 17.0,
            powerVolume: -0.25,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 5,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 2,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 3,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 4,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 5,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
    ],
    id:"122052608A00106"
  },
  {
    targetDate: new Date("2023-03-15T11:20:00.886Z"),
    area: 1,
    status: 0,
    ucTender: 1,
    blockNumber: 1, // 1-5まで
    bitDate: new Date("2023-03-15T11:20:00.886Z"),
    tradeType: 1,
    tenderCount: 5, // 1-15札まで
    dayBit: [
      // 15札分コマ分
      {
        tender: 1,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.2,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.7,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.9,
          },
          {
            powerPrice: 0.0,
            powerVolume: -1.45,
          },
          {
            powerPrice: 0.0,
            powerVolume: -3.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: -5.15,
          },
          {
            powerPrice: 0.0,
            powerVolume: -7.15,
          },
          {
            powerPrice: 0.0,
            powerVolume: -6.65,
          },
          {
            powerPrice: 0.0,
            powerVolume: -6.25,
          },
          {
            powerPrice: 0.0,
            powerVolume: -8.3,
          },
          {
            powerPrice: 0.0,
            powerVolume: -8.75,
          },
          {
            powerPrice: 0.0,
            powerVolume: -7.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: -7.95,
          },
          {
            powerPrice: 0.0,
            powerVolume: -8.45,
          },
          {
            powerPrice: 0.0,
            powerVolume: -6.15,
          },
          {
            powerPrice: 0.0,
            powerVolume: -3.5,
          },
          {
            powerPrice: 0.0,
            powerVolume: -2.45,
          },
          {
            powerPrice: 0.0,
            powerVolume: -2.25,
          },
          {
            powerPrice: 0.0,
            powerVolume: -1.65,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.7,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.25,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 5,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 2,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 3,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 4,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 5,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
    ],
    id:"132072608B00101"
  },
  {
    targetDate: new Date("2023-03-16T11:20:00.886Z"),
    area: 1,
    status: 0,
    ucTender: 1,
    blockNumber: 1, // 1-5まで
    bitDate: new Date("2023-03-16T11:20:00.886Z"),
    tradeType: 1,
    tenderCount: 5, // 1-15札まで
    dayBit: [
      // 15札分コマ分
      {
        tender: 1,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.2,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.7,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.9,
          },
          {
            powerPrice: 0.0,
            powerVolume: -1.45,
          },
          {
            powerPrice: 0.0,
            powerVolume: -3.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: -5.15,
          },
          {
            powerPrice: 0.0,
            powerVolume: -7.15,
          },
          {
            powerPrice: 0.0,
            powerVolume: -6.65,
          },
          {
            powerPrice: 0.0,
            powerVolume: -6.25,
          },
          {
            powerPrice: 0.0,
            powerVolume: -8.3,
          },
          {
            powerPrice: 0.0,
            powerVolume: -8.75,
          },
          {
            powerPrice: 0.0,
            powerVolume: -7.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: -7.95,
          },
          {
            powerPrice: 0.0,
            powerVolume: -8.45,
          },
          {
            powerPrice: 0.0,
            powerVolume: -6.15,
          },
          {
            powerPrice: 0.0,
            powerVolume: -3.5,
          },
          {
            powerPrice: 0.0,
            powerVolume: -2.45,
          },
          {
            powerPrice: 0.0,
            powerVolume: -2.25,
          },
          {
            powerPrice: 0.0,
            powerVolume: -1.65,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.7,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.25,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 5,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 2,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 3,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 4,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 5,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
    ],
    id:"112024607C00201"
  },
  {
    targetDate: new Date("2023-03-17T11:20:00.886Z"),
    area: 1,
    status: 0,
    ucTender: 1,
    blockNumber: 1, // 1-5まで
    bitDate: new Date("2023-03-17T11:20:00.886Z"),
    tradeType: 1,
    tenderCount: 5, // 1-15札まで
    dayBit: [
      // 15札分コマ分
      {
        tender: 1,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.2,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.7,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.9,
          },
          {
            powerPrice: 0.0,
            powerVolume: -1.45,
          },
          {
            powerPrice: 0.0,
            powerVolume: -3.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: -5.15,
          },
          {
            powerPrice: 0.0,
            powerVolume: -7.15,
          },
          {
            powerPrice: 0.0,
            powerVolume: -6.65,
          },
          {
            powerPrice: 0.0,
            powerVolume: -6.25,
          },
          {
            powerPrice: 0.0,
            powerVolume: -8.3,
          },
          {
            powerPrice: 0.0,
            powerVolume: -8.75,
          },
          {
            powerPrice: 0.0,
            powerVolume: -7.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: -7.95,
          },
          {
            powerPrice: 0.0,
            powerVolume: -8.45,
          },
          {
            powerPrice: 0.0,
            powerVolume: -6.15,
          },
          {
            powerPrice: 0.0,
            powerVolume: -3.5,
          },
          {
            powerPrice: 0.0,
            powerVolume: -2.45,
          },
          {
            powerPrice: 0.0,
            powerVolume: -2.25,
          },
          {
            powerPrice: 0.0,
            powerVolume: -1.65,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.7,
          },
          {
            powerPrice: 0.0,
            powerVolume: -0.25,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 5,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 2,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 3,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 4,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
      {
        tender: 5,
        // 48コマ分
        cells: [
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
          {
            powerPrice: 0.0,
            powerVolume: 0.0,
          },
        ],
      },
    ],
    id:"143022509D00304"
  },
];

//本番では使えないtype
export type tempBit={name:string; area:string; priceRange:string; volumeRange:string; stepTotal:number; bitDate:Date}
export const tempBitInfo:tempBit[] = [
{
  name:"富士電機",
  area:"東京",
  priceRange:"12.00～19.00",
  volumeRange:"0.20～8.75",
  stepTotal:88.85,
  bitDate: new Date("2023-03-14T11:20:00.886Z"),
},
{
  name:"富士電機",
  area:"東京",
  priceRange:"12.00～24.00",
  volumeRange:"0.15～4.80",
  stepTotal:61.8,
  bitDate: new Date("2023-03-15T11:20:00.886Z"),
},
{
  name:"富士電機",
  area:"東京",
  priceRange:"12.00～20.00",
  volumeRange:"0.40～9.15",
  stepTotal:98.55,
  bitDate: new Date("2023-03-16T11:20:00.886Z"),
},
{
  name:"富士電機",
  area:"東京",
  priceRange:"9.00～17.00",
  volumeRange:"0.15～3.20",
  stepTotal:32.3,
  bitDate: new Date("2023-03-17T11:20:00.886Z"),
},
{
  name:"富士電機",
  area:"東京",
  priceRange:"9.00～17.00",
  volumeRange:"0.15～3.20",
  stepTotal:32.3,
  bitDate: new Date("2023-03-18T11:20:00.886Z"),
},
]

// TESTDATA 約定結果
export const previousBits: previousBit[] = [
  {
    targetDate: new Date("2023/03/14"),
    resultCells: {
      cells: [
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 11.08,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 11.08,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 11.45,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 11.45,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 11.45,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 11.45,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 12.43,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 12.96,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.05,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.21,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.48,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.35,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 14.0,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.61,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.2,
          fixedPowerPrice: 16.5,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.7,
          fixedPowerPrice: 15.59,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.9,
          fixedPowerPrice: 15.27,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.45,
          fixedPowerPrice: 13.7,
        },
        {
          result: "全量",
          fixedPowerVolume: -3.0,
          fixedPowerPrice: 15.65,
        },
        {
          result: "全量",
          fixedPowerVolume: -5.15,
          fixedPowerPrice: 14.9,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.15,
          fixedPowerPrice: 13.0,
        },
        {
          result: "全量",
          fixedPowerVolume: -6.65,
          fixedPowerPrice: 12.52,
        },
        {
          result: "全量",
          fixedPowerVolume: -6.25,
          fixedPowerPrice: 12.01,
        },
        {
          result: "全量",
          fixedPowerVolume: -8.3,
          fixedPowerPrice: 11.97,
        },
        {
          result: "全量",
          fixedPowerVolume: -8.75,
          fixedPowerPrice: 11.5,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.0,
          fixedPowerPrice: 11.5,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.95,
          fixedPowerPrice: 12.07,
        },
        {
          result: "全量",
          fixedPowerVolume: -8.45,
          fixedPowerPrice: 12.61,
        },
        {
          result: "全量",
          fixedPowerVolume: -6.15,
          fixedPowerPrice: 12.79,
        },
        {
          result: "全量",
          fixedPowerVolume: -3.5,
          fixedPowerPrice: 13.31,
        },
        {
          result: "全量",
          fixedPowerVolume: -2.45,
          fixedPowerPrice: 12.52,
        },
        {
          result: "全量",
          fixedPowerVolume: -2.25,
          fixedPowerPrice: 13.91,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.65,
          fixedPowerPrice: 15.52,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.7,
          fixedPowerPrice: 16.29,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.25,
          fixedPowerPrice: 17.29,
        },
        {
          result: "全量",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.48,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.54,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.55,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.54,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.43,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.3,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.98,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.86,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.0,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.82,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.0,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 14.9,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.94,
        },
      ],
    },
  },
  {
    targetDate: new Date("2023/03/15"),
    resultCells: {
      cells: [
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.51,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.08,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.19,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.05,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.14,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.2,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.3,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.23,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.03,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.01,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 12.92,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 12.82,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.03,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.5,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.35,
          fixedPowerPrice: 14.64,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.05,
          fixedPowerPrice: 14.13,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.95,
          fixedPowerPrice: 15.0,
        },
        {
          result: "全量",
          fixedPowerVolume: -3.1,
          fixedPowerPrice: 15.5,
        },
        {
          result: "全量",
          fixedPowerVolume: -4.25,
          fixedPowerPrice: 15.52,
        },
        {
          result: "全量",
          fixedPowerVolume: -4.95,
          fixedPowerPrice: 15.52,
        },
        {
          result: "全量",
          fixedPowerVolume: -6.25,
          fixedPowerPrice: 15.59,
        },
        {
          result: "全量",
          fixedPowerVolume: -6.85,
          fixedPowerPrice: 15.0,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.95,
          fixedPowerPrice: 13.97,
        },
        {
          result: "全量",
          fixedPowerVolume: -9.75,
          fixedPowerPrice: 13.92,
        },
        {
          result: "全量",
          fixedPowerVolume: -9.55,
          fixedPowerPrice: 13.22,
        },
        {
          result: "全量",
          fixedPowerVolume: -9.85,
          fixedPowerPrice: 12.39,
        },
        {
          result: "全量",
          fixedPowerVolume: -9.7,
          fixedPowerPrice: 12.21,
        },
        {
          result: "全量",
          fixedPowerVolume: -9.15,
          fixedPowerPrice: 12.14,
        },
        {
          result: "全量",
          fixedPowerVolume: -8.4,
          fixedPowerPrice: 11.39,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.25,
          fixedPowerPrice: 11.08,
        },
        {
          result: "全量",
          fixedPowerVolume: -5.95,
          fixedPowerPrice: 11.39,
        },
        {
          result: "全量",
          fixedPowerVolume: -4.35,
          fixedPowerPrice: 11.45,
        },
        {
          result: "全量",
          fixedPowerVolume: -2.9,
          fixedPowerPrice: 12.84,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.15,
          fixedPowerPrice: 13.23,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.35,
          fixedPowerPrice: 14.0,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.05,
          fixedPowerPrice: 15.88,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.0,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.06,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.0,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.46,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.5,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.84,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.82,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.0,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 14.01,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.23,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 12.84,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 11.45,
        },
      ],
    },
  },
  {
    targetDate: new Date("2023/03/16"),
    resultCells: {
      cells: [
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.04,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.66,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.59,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.56,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.48,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.5,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.36,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.36,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.0,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.17,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.5,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.54,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.85,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.3,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.2,
          fixedPowerPrice: 17.3,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.65,
          fixedPowerPrice: 17.29,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.35,
          fixedPowerPrice: 17.33,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.9,
          fixedPowerPrice: 17.0,
        },
        {
          result: "全量",
          fixedPowerVolume: -2.35,
          fixedPowerPrice: 16.71,
        },
        {
          result: "全量",
          fixedPowerVolume: -3.3,
          fixedPowerPrice: 15.37,
        },
        {
          result: "全量",
          fixedPowerVolume: -4.9,
          fixedPowerPrice: 14.01,
        },
        {
          result: "全量",
          fixedPowerVolume: -6.5,
          fixedPowerPrice: 13.61,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.5,
          fixedPowerPrice: 12.49,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.5,
          fixedPowerPrice: 11.87,
        },
        {
          result: "全量",
          fixedPowerVolume: -8.2,
          fixedPowerPrice: 11.08,
        },
        {
          result: "全量",
          fixedPowerVolume: -6.6,
          fixedPowerPrice: 9.9,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.65,
          fixedPowerPrice: 11.08,
        },
        {
          result: "全量",
          fixedPowerVolume: -5.9,
          fixedPowerPrice: 11.76,
        },
        {
          result: "全量",
          fixedPowerVolume: -5.15,
          fixedPowerPrice: 12.75,
        },
        {
          result: "全量",
          fixedPowerVolume: -3.85,
          fixedPowerPrice: 14.0,
        },
        {
          result: "全量",
          fixedPowerVolume: -4.0,
          fixedPowerPrice: 13.99,
        },
        {
          result: "全量",
          fixedPowerVolume: -2.2,
          fixedPowerPrice: 13.99,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.95,
          fixedPowerPrice: 14.0,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.25,
          fixedPowerPrice: 15.53,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.6,
          fixedPowerPrice: 16.45,
        },
        {
          result: "全量",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.49,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.53,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.56,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.46,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.29,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.52,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.73,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.86,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.54,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.52,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.52,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 14.12,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 13.66,
        },
      ],
    },
  },
  {
    targetDate: new Date("2023/03/17"),
    resultCells: {
      cells: [
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.54,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.23,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.02,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.0,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.86,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.71,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.66,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.66,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.83,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.88,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.11,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.14,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 19.87,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 20.48,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.55,
          fixedPowerPrice: 20.94,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.45,
          fixedPowerPrice: 20.86,
        },
        {
          result: "全量",
          fixedPowerVolume: -3.45,
          fixedPowerPrice: 20.28,
        },
        {
          result: "全量",
          fixedPowerVolume: -5.45,
          fixedPowerPrice: 19.32,
        },
        {
          result: "全量",
          fixedPowerVolume: -4.8,
          fixedPowerPrice: 20.07,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.65,
          fixedPowerPrice: 20.07,
        },
        {
          result: "全量",
          fixedPowerVolume: -5.6,
          fixedPowerPrice: 15.88,
        },
        {
          result: "全量",
          fixedPowerVolume: -8.95,
          fixedPowerPrice: 15.42,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.85,
          fixedPowerPrice: 14.72,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.75,
          fixedPowerPrice: 14.9,
        },
        {
          result: "全量",
          fixedPowerVolume: -9.65,
          fixedPowerPrice: 12.5,
        },
        {
          result: "全量",
          fixedPowerVolume: -9.4,
          fixedPowerPrice: 12.4,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.75,
          fixedPowerPrice: 13.69,
        },
        {
          result: "全量",
          fixedPowerVolume: -7.3,
          fixedPowerPrice: 14.63,
        },
        {
          result: "全量",
          fixedPowerVolume: -6.3,
          fixedPowerPrice: 14.83,
        },
        {
          result: "全量",
          fixedPowerVolume: -4.15,
          fixedPowerPrice: 15.44,
        },
        {
          result: "全量",
          fixedPowerVolume: -5.0,
          fixedPowerPrice: 13.97,
        },
        {
          result: "全量",
          fixedPowerVolume: -4.55,
          fixedPowerPrice: 15.88,
        },
        {
          result: "全量",
          fixedPowerVolume: -2.5,
          fixedPowerPrice: 16.17,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.45,
          fixedPowerPrice: 19.32,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.5,
          fixedPowerPrice: 20.28,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.05,
          fixedPowerPrice: 20.48,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 20.31,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 20.28,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 20.31,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 20.27,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 20.26,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 19.95,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 19.56,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 18.56,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 18.56,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.26,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.97,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 15.59,
        },
      ],
    },
  },
  {
    targetDate: new Date("2023/03/18"),
    resultCells: {
      cells: [
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.64,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.23,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.41,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.32,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.11,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.0,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.06,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.06,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.16,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.23,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.91,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 19.32,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 20.27,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 21.62,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.1,
          fixedPowerPrice: 21.79,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.45,
          fixedPowerPrice: 20.86,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.0,
          fixedPowerPrice: 20.3,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.25,
          fixedPowerPrice: 19.47,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.7,
          fixedPowerPrice: 19.32,
        },
        {
          result: "全量",
          fixedPowerVolume: -2.1,
          fixedPowerPrice: 17.46,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.15,
          fixedPowerPrice: 16.31,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.15,
          fixedPowerPrice: 15.57,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.15,
          fixedPowerPrice: 15.48,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.7,
          fixedPowerPrice: 15.3,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.7,
          fixedPowerPrice: 12.4,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.85,
          fixedPowerPrice: 12.4,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.55,
          fixedPowerPrice: 15.3,
        },
        {
          result: "全量",
          fixedPowerVolume: -2.5,
          fixedPowerPrice: 15.51,
        },
        {
          result: "全量",
          fixedPowerVolume: -3.35,
          fixedPowerPrice: 15.0,
        },
        {
          result: "全量",
          fixedPowerVolume: -3.7,
          fixedPowerPrice: 15.89,
        },
        {
          result: "全量",
          fixedPowerVolume: -2.2,
          fixedPowerPrice: 15.77,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.85,
          fixedPowerPrice: 16.59,
        },
        {
          result: "全量",
          fixedPowerVolume: -1.2,
          fixedPowerPrice: 19.32,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.95,
          fixedPowerPrice: 20.39,
        },
        {
          result: "全量",
          fixedPowerVolume: -0.4,
          fixedPowerPrice: 21.51,
        },
        {
          result: "全量",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 24.3,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 21.79,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 21.79,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 20.69,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 20.55,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 20.36,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 20.28,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 19.32,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 19.13,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 19.34,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 19.0,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 17.41,
        },
        {
          result: "",
          fixedPowerVolume: 0.0,
          fixedPowerPrice: 16.51,
        },
      ],
    },
  },
];

//========== ブロック入札 ==========
// TODO: fksm 3日分同じデータを使って画面では表示している
export const blockBitInfos: bitInfo[] = [
  {
    targetDate: new Date("2023/03/14"),
    area: 1,
    status: 0,
    ucTender: 1,
    blockNumber: 1, // 1-5まで
    bitDate: new Date("2023/03/14"),
    tradeType: 1,
    tenderCount: 1, // 1-15札まで
    dayBit: [
      // 15札分コマ分
      {
        tender: 1,
        // 48コマ分
        cells: [
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
        ],
      },
    ],
    id:""
  },
  {
    targetDate: new Date("2023/03/14"),
    area: 1,
    status: 0,
    ucTender: 1,
    blockNumber: 2, // 1-5まで
    bitDate: new Date("2023/03/14"),
    tradeType: 1,
    tenderCount: 1, // 1-15札まで
    dayBit: [
      // 15札分コマ分
      {
        tender: 1,
        // 48コマ分
        cells: [
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
        ],
      },
    ],
    id:""
  },
  {
    targetDate: new Date("2023/03/14"),
    area: 1,
    status: 0,
    ucTender: 1,
    blockNumber: 3, // 1-5まで
    bitDate: new Date("2023/03/14"),
    tradeType: 1,
    tenderCount: 1, // 1-15札まで
    dayBit: [
      // 15札分コマ分
      {
        tender: 1,
        // 48コマ分
        cells: [
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
        ],
      },
    ],
    id:""
  },
  {
    targetDate: new Date("2023/03/14"),
    area: 1,
    status: 0,
    ucTender: 1,
    blockNumber: 4, // 1-5まで
    bitDate: new Date("2023/03/14"),
    tradeType: 1,
    tenderCount: 1, // 1-15札まで
    dayBit: [
      // 15札分コマ分
      {
        tender: 1,
        // 48コマ分
        cells: [
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
        ],
      },
    ],
    id:""
  },
  {
    targetDate: new Date("2023/03/14"),
    area: 1,
    status: 0,
    ucTender: 1,
    blockNumber: 5, // 1-5まで
    bitDate: new Date("2023/03/14"),
    tradeType: 1,
    tenderCount: 1, // 1-15札まで
    dayBit: [
      // 15札分コマ分
      {
        tender: 1,
        // 48コマ分
        cells: [
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 5,
            powerPrice: 10.0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
          {
            powerVolume: 0,
            powerPrice: 0,
          },
        ],
      },
    ],
    id:""
  },
];

// export interface bitLimmitReq {
//   eria: number;
//   target: number; // 売り or 買い
//   dayLimitBit: bitLimitCells[];
// }
