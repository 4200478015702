import React, { useState, useEffect, useContext, useMemo, useRef } from "react";
import "./css/SetPowerContractList.css";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { padding, width } from "@mui/system";
import axios from "axios";
import CustomModal from "../common/customComponents/CustomModal";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import CustomDatePickerTryal from "../common/customComponents/CustomDatePickerTryal";
import { LanguageContext } from "../common/localization/localization";
import {
  resSaleGroupList,
  saleDetailSetInfo,
} from "../../types/master/SetSaleDetailList";
import { modalMode } from "./SaleDetailList";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import { resAreaList, resPowerList } from "../../types/common/Search";
import { addDays, format, subDays } from "date-fns";

import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import CustomSelectListBig from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorBig";
import CustomModalDatePicker from "../common/customComponents/CustomMasterCompornent/CustomMstDatePicker";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";

type Props = {
  userId: string;
  propsShowUserCreateDialog: any;
};

const CustomTextField = styled(TextField)(() => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "auto",
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
}));
type selectInfo = { id: string; name: string };
type saleGrInfo = {
  id: number;
  name: string;
  saleGrId: number;
  saleKind: string;
  areaId: string;
  areaName: string;
};
type powerInfo = { id: number; powerId: number; name: string };
type demandInfo = { id: number; demandId: number; name: string };

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  areaId: boolean;
  buyerName: boolean;
  buyerGroupId: boolean;
  demand: boolean;
  power: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  areaId: false,
  buyerName: false,
  buyerGroupId: false,
  demand: false,
  power: false,
};

const SetSaleDetailList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  connectNumberEdit: number
) => {
  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  // 通信エラー
  const api = useAuthedApi();

  //エリアIDをエリア名に変換
  const { convertIdToAreaName } = useAreaUtility();

  const [powerInfos, setPowerInfos] = useState<powerInfo[]>([]);
  const powerPlantOption = useMemo(
    () =>
      [
        { id: 0, powerId: 0, name: languageContext.words.setting_unselected },
      ].concat(powerInfos),
    [languageContext.words, powerInfos]
  );
  const [demandInfos, setDemandInfos] = useState<demandInfo[]>([]);
  const demandOption = useMemo(
    () =>
      [
        { id: 0, demandId: 0, name: languageContext.words.setting_unselected },
      ].concat(demandInfos),
    [languageContext.words, demandInfos]
  );
  const saleGroupsInfos = React.useRef<saleGrInfo[]>([]);

  const [saleDetailInfo, setSaleDetailInfo] = useState<saleDetailSetInfo>();
  const [saleGrNo, setSaleGrNo] = useState<number>(0);
  const [selContractantArea, setSelContractantArea] = useState<string>(""); //エリア
  const [selectedPower, setSelectedPower] = useState<number>();
  const [selectedDemand, setSelectedDemand] = useState<number>();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [finishDate, setFinishDate] = useState<Date>(addDays(new Date(), 1));
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる

  const powerId = useRef<number>(0);
  const demandId = useRef<number>(0);
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する

  const firstflg = React.useRef<boolean>(true); //初期表示時にエリアのオンチェンジで発電需要をセットするためのフラグ

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化
  ////

  //初期化用
  const initSaleDetailInfo: saleDetailSetInfo = {
    saleId: 0,
    buyerName: "",
    areaId: "00",
    buyerGroupId: 0,
    power: 0,
    demand: 0,
    remarks: "",
    startDate: format(new Date(), "yyyy/MM/dd"),
    finishDate: format(new Date(), "yyyy/MM/dd"),
  };

  useEffect(() => {
    getSaleGroupMst();
    setSaleDetailInfo(initSaleDetailInfo);
  }, []); //初期化

  //開始datePicker
  useEffect(() => {
    const tmpInfo: saleDetailSetInfo = Object.assign({}, saleDetailInfo);
    tmpInfo.startDate = format(startDate, "yyyyMMdd");
    setSaleDetailInfo(tmpInfo);
  }, [startDate]);

  ////終了datePicker
  useEffect(() => {
    const tmpInfo: saleDetailSetInfo = Object.assign({}, saleDetailInfo);
    tmpInfo.finishDate == null
      ? (tmpInfo.finishDate = "")
      : (tmpInfo.finishDate = format(finishDate, "yyyyMMdd"));
    setSaleDetailInfo(tmpInfo);
  }, [finishDate]);

  //ダイアログ開いたときの初期表示
  useEffect(() => {
    if (toOpen === true) {
      if (mode == modalMode.editMode) {
        //編集
        getDataLinkedBgCode(connectNumberEdit);
      } else {
        //新規
        setSaleDetailInfo(initSaleDetailInfo);
        setStartDate(new Date());
        setFinishDate(addDays(new Date(), 1));
        setSelectedPower(0);
        setSelectedDemand(0);
        setSaleGrNo(0);
        powerId.current = 0;
        demandId.current = 0;
        setSelContractantArea("");
      }
    } else {
      //ダイアログクローズ
      setSaleDetailInfo(initSaleDetailInfo);
      setStartDate(new Date());
      setFinishDate(addDays(new Date(), 1));
      setSelectedPower(0);
      setSelectedDemand(0);
      setSaleGrNo(0);
      powerId.current = 0;
      demandId.current = 0;
      firstflg.current = true;
      setInputError(initErrorInput);
      setSelContractantArea("");
    }
  }, [toOpen]); //初期化

  console.log(powerInfos);
  function getPower(areaId: string) {
    // 発電所取得
    api
      .get(API_URL.GET_AREA_POWER, { params: { areaId, powerBgId: null } })
      .then((res) => {
        setPowerInfos(
          res.data.map((v: resPowerList, index: number) => {
            return { id: index + 1, powerId: v.powerId, name: v.powerName };
          })
        );
      })
      .catch((err) => {
        // エラー処理
      });
  }

  function getDemand(areaId: string) {
    // 需要家取得
    api
      .get(API_URL.GET_AREA_DEMAND, { params: { areaId, demandBg: 0 } })
      .then((response) => {
        setDemandInfos(
          response.data.map((v: any, index: number) => {
            return {
              id: index + 1,
              demandId: v.demand_id,
              name: v.demand_nm,
            };
          })
        );
      })
      .catch((err) => {
        // エラー処理
      });
  }

  ////////所属販売先関連

  //データベースから発電契約者データ一覧取得

  const getSaleGroupMst = () => {
    api
      .get(API_URL.GET_SALE_GR_MST)
      .then((response) => {
        //console.log("販売先GRデータマスタ取得開始");
        //console.log(response.data.result);
        saleGroupsInfos.current = convertSaleGroupDatas(
          response.data.result as resSaleGroupList[]
        );
        //setSelContractantCode(contractorInfos.current[0].code)
        //setSelContractantName(contractorInfos.current[0].name)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //console.log(saleGroupsInfos);

  const convertSaleGroupDatas = (result: resSaleGroupList[]) => {
    const retSaleGroupData: saleGrInfo[] = [];
    result.map((data, index) => {
      const contractorData: saleGrInfo = {
        id: 0,
        name: "",
        saleGrId: 0,
        saleKind: "",
        areaId: "",
        areaName: "",
      };
      contractorData.id = index + 1; //0要素にはデフォルトのものが入るため
      contractorData.name = data.name;
      contractorData.saleGrId = data.saleGrId;
      contractorData.saleKind = data.saleKind;
      contractorData.areaId = data.areaId;
      contractorData.areaName = data.areaName;

      retSaleGroupData.push(contractorData);
      //console.log("販売先グループ変換1データ");
      //console.log(JSON.stringify(contractorData));
    });
    // console.log("販売先グループ変換後データ");
    //console.log(JSON.stringify(retSaleGroupData));
    return retSaleGroupData;
  };

  //セレクターから選択された販売先のGRIDをsaleDetailInfoに入れる
  const setSaleGroupId = (e: number) => {
    const tmpSaleDetailInfo: saleDetailSetInfo = Object.assign(
      {},
      saleDetailInfo
    );
    console.log(e);
    const tmpsaleGroupsInfo: saleGrInfo[] = createSalesListJoinUnserected();

    if (tmpSaleDetailInfo.buyerGroupId != tmpsaleGroupsInfo[e].saleGrId)
      tmpSaleDetailInfo.buyerGroupId = tmpsaleGroupsInfo[e].saleGrId;
    tmpSaleDetailInfo.areaId = tmpsaleGroupsInfo[e].areaId;
    // setSelContractantNo(tmpContractInfo[e].id);
    //SaleGrCompornentChanged(tmpsaleGroupsInfo[e]);
    powerId.current = 0;
    demandId.current = 0;
    if (e != 0 && tmpsaleGroupsInfo[e].saleKind == "12") {
      getPower(tmpsaleGroupsInfo[e].areaId);
      getDemand(tmpsaleGroupsInfo[e].areaId);
    } else {
      setPowerInfos([]);
      setDemandInfos([]);
      tmpSaleDetailInfo.demand = 0;
      tmpSaleDetailInfo.power = 0;
      setSelectedDemand(0);
      setSelectedPower(0);
    }
    setSaleDetailInfo(tmpSaleDetailInfo);
    setSelContractantArea(tmpsaleGroupsInfo[e].areaId); //追加
    setSaleGrNo(tmpsaleGroupsInfo[e].id);
  };

  //発電契約者部分のコンポーネント変更（セレクトリストとフィールド）
  const SaleGrCompornentChanged = (saleGrInfo: saleGrInfo) => {
    setSaleGrNo(saleGrInfo.id);
  };

  //編集用にDBから出したデータの販売先グループIDと一致しているものを
  //saleGroupsInfosからさがし、その情報をコンポーネントに表示
  const setSaleGrInfoForEdit = (saleGrIdFromDB: number) => {
    createSalesListJoinUnserected().map((saleGroupsInfo) => {
      if (saleGrIdFromDB === saleGroupsInfo.saleGrId) {
        // setSelContractantCode(contractorInfo.code);
        // setSelContractantNo(contractorInfo.id);
        SaleGrCompornentChanged(saleGroupsInfo);
      }
    });
  };

  ////////所属販売先

  //所属販売先のリストの先頭に未選択の選択肢を追加した配列を返す
  const createSalesListJoinUnserected = () => {
    const unselect = [
      {
        id: 0,
        name: languageContext.words.setting_unselected,
        saleGrId: 0,
        saleKind: "",
        areaId: "",
        areaName: "",
      },
    ];
    return unselect.concat(saleGroupsInfos.current);
  };

  const convertAreaData = (result: resAreaList[]) => {
    const retAreaData: selectInfo[] = [];
    result.map((data) => {
      const areaData: selectInfo = { id: "", name: "" };
      areaData.id = data.areaId.toString();
      areaData.name = data.areaName;
      retAreaData.push(areaData);
      //console.log("エリア変換1データ");
      //console.log(JSON.stringify(areaData));
    });
    //console.log("エリア変換後データ");
    //console.log(JSON.stringify(retAreaData));
    return retAreaData;
  };

  function setAreaId(id: number) {
    //numberを文字列にしてコピーしたsaleDetailInfoのIDにセットする
    const areaIdString = ("00" + id).slice(-2).toString();
    const tmpAreaInfo: saleDetailSetInfo = Object.assign({}, saleDetailInfo);
    tmpAreaInfo.areaId = areaIdString;

    setSaleDetailInfo(tmpAreaInfo);

    getPower(areaIdString);
    getDemand(areaIdString);
  }
  ////////エリア関連

  // 描画している内容を保存する
  const AddDatas = () => {
    //console.log(saleDetailInfo);
    let error = false;

    error = isCorrectInfo();

    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_new_registration);
      setDigOpen(true);
    } else if (error === false) {
      const url = api
        .post(API_URL.ADD_SALEDETAIL_INFO, saleDetailInfo)
        .then((response) => {
          //console.log(response.data.result);
          setJobClear(true);
          setDialogMessage(languageContext.words.sale_detail_added);
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.sale_detail_failed_add);
          setDigOpen(true);
        });
    }
  };

  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (saleDetailInfo?.areaId == "00") {
      error = true;
      tempInputErrors.areaId = true;
    }
    if (saleDetailInfo?.buyerName == "") {
      error = true;
      tempInputErrors.buyerName = true;
    }
    if (saleDetailInfo?.buyerGroupId == 0) {
      error = true;
      tempInputErrors.buyerGroupId = true;
    }

    //自己託送の時発電所か需要家未選択はダメ
    if (
      createSalesListJoinUnserected().find((v) => v.id === saleGrNo)
        ?.saleKind === "12" &&
      (saleDetailInfo?.demand == 0 || saleDetailInfo?.power == 0)
    ) {
      if (saleDetailInfo?.demand == 0) {
        tempInputErrors.demand = true;
      }
      if (saleDetailInfo?.power == 0) {
        tempInputErrors.power = true;
      }
      error = true;
    }

    setInputError(tempInputErrors);
    return error;
  };

  // 販売先グループ内訳データ一行分取得
  const getDataLinkedBgCode = (saleId: number) => {
    //console.log("1行データ取得開始");
    let responseData: saleDetailSetInfo;
    const url = api
      .get(API_URL.GET_ONE_SALEDETAIL_DATA, {
        params: { saleId: saleId },
      })
      .then((response) => {
        // console.log(response.data.result);
        responseData = response.data.result as saleDetailSetInfo;

        //console.log("Help!!");
        // console.log(responseData);
        setSaleDetailInfo(responseData);
        setSaleGrInfoForEdit(responseData.buyerGroupId); //ここでは表示用データ更新

        const startDtStr = convertCorrectDateStr(
          responseData.startDate as string
        );
        const finishDtStr = convertCorrectDateStr(
          responseData.finishDate as string
        );

        setSelContractantArea(responseData.areaId);

        setStartDate(new Date(startDtStr));
        setFinishDate(new Date(finishDtStr));
        //settempDemand(responseData.demand);
        powerId.current = responseData.power;
        demandId.current = responseData.demand;
        //自己託送だったら発電需要とってくる
        getPower(responseData.areaId);
        getDemand(responseData.areaId);
      })
      .catch((err) => {
        console.log(err);
        setSaleDetailInfo(initSaleDetailInfo);
      });

    //setStartDate(new Date("2023/07/07"));
  };

  useEffect(() => {
    if (powerInfos.length) {
      powerInfos.map((plant) => {
        if (powerId.current === plant.powerId) {
          setSelectedPower(plant.id);
          //settempPower(plant.id);
        }
      });
    }
  }, [powerInfos]);

  useEffect(() => {
    if (demandInfos.length) {
      demandInfos.map((demand) => {
        if (demandId.current === demand.demandId) {
          setSelectedDemand(demand.id);
          //settempDemand(demand.id);
        }
      });
    }
  }, [demandInfos]);

  //yyyymmddをyyyy/mm/ddに変換
  const convertCorrectDateStr = (str: string) => {
    let res = str.slice(0, 4) + "/" + str.slice(4);
    res = res.slice(0, 7) + "/" + res.slice(7);
    return res;
  };

  //編集用にDBから出した発電所、需要家データと一致しているものを
  //セレクターからさがし、その情報をコンポーネントに表示
  const setDemandAndPlantForEdit = (DBplant: number, DBdemand: number) => {
    powerInfos.map((plant) => {
      if (DBplant === plant.powerId) {
        setSelectedPower(plant.id);
        powerId.current = plant.id;
      }
    });

    demandInfos.map((demand) => {
      if (DBdemand === demand.demandId) {
        setSelectedDemand(demand.id);
        demandId.current = demand.id;
      }
    });
  };

  //BGコードの値をもとにデータを更新する
  function UpdateDatas() {
    //console.log(saleDetailInfo);
    let error = false;

    error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else if (error === false) {
      const url = api
        .post(API_URL.UPDATE_SALEDETAIL_INFO, saleDetailInfo)
        .then((response) => {
          //console.log(response.data.result);
          setJobClear(true);
          setDialogMessage(languageContext.words.sale_detail_updated);
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.sale_detail_failed_update);
          setDigOpen(true);
        });
    }
  }

  //テキストフィールドの中身が変化したら、contractInfosの中身をそのたびに変える
  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name: string = e.currentTarget.name;
    const value: string = e.currentTarget.value;

    const tmpInfo: saleDetailSetInfo = Object.assign({}, saleDetailInfo);
    switch (name) {
      case "buyerName":
        tmpInfo.buyerName = value;
        break;
      case "remarks":
        tmpInfo.remarks = value;
        break;
      case "power":
        tmpInfo.power = parseInt(value);
        break;
      case "demand":
        tmpInfo.demand = parseInt(value);
        break;
      default:
        break;
    }
    setSaleDetailInfo(tmpInfo);
  }

  function onPowerChangeValue(e: number) {
    const tmpInfo: saleDetailSetInfo = Object.assign({}, saleDetailInfo);
    const selected = powerPlantOption.find((v) => v.id === e);
    tmpInfo.power = Number(selected?.powerId);
    setSaleDetailInfo(tmpInfo);
    setSelectedPower(selected?.id);
  }

  function onDemandChangeValue(e: number) {
    const tmpInfo: saleDetailSetInfo = Object.assign({}, saleDetailInfo);
    const selected = demandOption.find((v) => v.id === e);
    tmpInfo.demand = Number(selected?.demandId);
    setSaleDetailInfo(tmpInfo);
    setSelectedDemand(selected?.id);
  }

  // 削除(論理削除)
  const Delete = (saleId: number | undefined) => {
    // 削除API呼出
    //.log(saleDetailInfo);
    api
      .post(API_URL.DELETE_SALEDETAIL_INFO, { saleId: saleId })
      .then((response) => {
        //console.log(`BG1件削除結果: ${response.data.result}`);
        setJobClear(true);
        setDialogMessage(languageContext.words.sale_detail_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.sale_detail_failed_delete);
        setDigOpen(true);
      });
  };

  const DoModeJob = () => {
    if (mode == modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };
  console.log(saleDetailInfo);
  return (
    <CustomSettingModal
      title={languageContext.words.sales_dest_breakdown_setting}
      open={toOpen}
      onAcceptLeft={() => DoModeJob()}
      onAcceptRight={() => Delete(saleDetailInfo?.saleId)}
      onClose={() => HaldleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
      }
    >
      <Stack direction="column" sx={{ height: "540px", width: "550px" }}>
        <CustomBoxBig>
          <CustomModalTypography gutterBottom>
            {languageContext.words.sales_destination_name}
          </CustomModalTypography>

          <CustomTextFieldBig
            //販売先内訳名称
            type="text"
            value={saleDetailInfo?.buyerName}
            onChange={onChangeValue}
            name="buyerName"
            inputProps={{
              maxLength: 64,
            }}
            error={inputError.buyerName}
            helperText={languageContext.words.setting_required}
          ></CustomTextFieldBig>
        </CustomBoxBig>
        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.affiliated_sales_destinations}
            </CustomModalTypography>

            <CustomSelectListBig
              //key={"demandForecastData_CustomSelectList_customer"}
              name="saleGrNo"
              //defaultValue={0}
              value={saleGrNo}
              options={createSalesListJoinUnserected()}
              onChange={(e: number) => {
                if (e == saleGrNo) {
                  return;
                }

                setSaleGroupId(e);
              }}
              error={inputError.buyerGroupId}
            />
          </CustomBoxSmall>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.connection_area}
            </CustomModalTypography>

            <CustomTextFieldSmall
              //key={"demandForecastData_CustomSelectList_customer"}
              name="areaId"
              //label={languageContext.words.all_areas}
              value={convertIdToAreaName(selContractantArea)}
              disabled={true}
              error={inputError.areaId}
            />

            {/* <CustomSelectListSmall
            //key={"demandForecastData_CustomSelectList_customer"}
            name="areaId"
            label={languageContext.words.all_areas}
            //defaultValue={0}
            value={saleDetailInfo?.areaId}
            options={areaInfos.current}
            onChange={(e: number) => {
              if (
                ("00" + e).slice(-2) != saleDetailInfo?.areaId ||
                (firstflg.current == true && saleDetailInfo?.areaId != "00")
              ) {
                firstflg.current = false;
                setAreaId(e);
                setSelectedPower(0);
                setSelectedDemand(0);
              }
            }}
            error={inputError.areaId}
          /> */}
          </CustomBoxSmall>
        </Stack>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall></CustomBoxSmall>

          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.power_plant}
            </CustomModalTypography>
            <CustomSelectListBig
              value={selectedPower}
              options={powerPlantOption}
              onChange={onPowerChangeValue}
              disabled={
                createSalesListJoinUnserected().find((v) => v.id === saleGrNo)
                  ?.saleKind !== "12"
              }
              error={
                (createSalesListJoinUnserected().find((v) => v.id === saleGrNo)
                  ?.saleKind !==
                  "12") ===
                true
                  ? undefined
                  : inputError.power
              }
            />
          </CustomBoxSmall>
        </Stack>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>{/* 空領域 */}</CustomBoxSmall>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.demander}
            </CustomModalTypography>
            <CustomSelectListBig
              //name="demand"
              //label={"demandList"}
              value={selectedDemand}
              options={demandOption}
              onChange={onDemandChangeValue}
              disabled={
                createSalesListJoinUnserected().find((v) => v.id === saleGrNo)
                  ?.saleKind !== "12"
              }
              error={
                (createSalesListJoinUnserected().find((v) => v.id === saleGrNo)
                  ?.saleKind !==
                  "12") ===
                true
                  ? undefined
                  : inputError.demand
              }
            />
          </CustomBoxSmall>
        </Stack>

        <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.effective_date_start_date}
            </CustomModalTypography>
            <CustomModalDatePicker
              setValue={setStartDate}
              value={startDate}
              maxDate={subDays(finishDate, 1)}
            />
          </CustomBoxSmall>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.expiration_date}
            </CustomModalTypography>
            <CustomModalDatePicker
              setValue={setFinishDate}
              value={finishDate}
              minDate={addDays(startDate, 1)}
            />
          </CustomBoxSmall>
        </Stack>

        <CustomBoxBig>
          <CustomModalTypography gutterBottom>
            {languageContext.words.remarks}
          </CustomModalTypography>
          <CustomTextFieldBig
            //備考
            type="text"
            value={saleDetailInfo?.remarks}
            onChange={onChangeValue}
            name="remarks"
            inputProps={{
              maxLength: 256,
            }}
          ></CustomTextFieldBig>
        </CustomBoxBig>
      </Stack>

      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetSaleDetailList;
