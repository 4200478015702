import React, { useState, useRef, useContext, createContext, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LoginImage from "./LoginImage";
import Company from "./company";
import {
  Alert,
  Chip,
  Divider,
  Grid,
  Snackbar,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import api from "../../../common/axios";
import { LoginInfo } from "../../../types/system/Login";
import { LanguageContext } from "../../common/localization/localization";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import axios from "axios";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import { getTheme } from "../../common/changeTheme";
import { PaletteContext } from "../../common/paletteMode";
import { RoleContext } from "../../common/customComponents/RoleProvider";
import { PLAN_STATUS_LIST_URL } from "../../../common/url";
import { AuthContext } from "../../../AuthProvider";
import { ErrOperationContext } from "./ErrOperationProvider";

type authType = {
  userID: string;
  userPASS: string;
};


const UserID = {
  id: "admin",
};

const UserPass = {
  pass: "admin",
};

//ApiURL
const LOGIN_URL = "/login";
const GET_USER_ROLE = "/getUserRole";
const GET_GLOBAL_INFO = "_api/getGlobalInfo";
const AUTHENTICATION = "/authentication";

const Login = () => {
  const [loginInfo, setAuthInfo] = useState<LoginInfo>({
    tenantCode: "",
    userId: "",
    password: "",
  });
  const tenantIDRef = useRef<HTMLInputElement>(null!);
  const userIDRef = useRef<HTMLInputElement>(null!);
  const userPASSRef = useRef<HTMLInputElement>(null!);
  const buttonRef = useRef<HTMLButtonElement>(null!);
  const [digOpen, setDigOpen] = useState(false);
  const [globalInfo, setGlobalInfo] = useState<string[]>([""]);
  const leavePageContext = useContext(LeavePageContext);
  const roleContext = useContext(RoleContext);
  const [msgLoginError, setMsgLoginError] = useState("");
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);
  const [session, setSession] = useState<boolean>();
  const { errOperation, setErrOperation } = useContext(ErrOperationContext);

  const getSession = () => {
    api.get(AUTHENTICATION)
    .then((res) => {
      setSession(true);
    })
    .catch((err) => {
      setSession(false);
    });
  }
  React.useEffect(() => {
    if(session === true) {
      api.post("/logout");
    }
  }, [session]);

  React.useEffect(() => {
    if(errOperation === true) {
      setMsgLoginError(languageContext.words.operation_err_message);
      setDigOpen(true);
    }
    setErrOperation(false);
  }, [errOperation]);

  React.useEffect(() => {
    getSession();
    getGlobalInfo();
    leavePageContext.setBlockLeave(false);
  }, []);

  //言語を切り替えるデータコンテキスト
  const languageContext = useContext(LanguageContext);

  const handleToNextPage = () => {
    navigate(".." + PLAN_STATUS_LIST_URL);
  };

  const handleClick = () => {
    api
      .post(LOGIN_URL, loginInfo)
      .then((res) => {
        setAuth("login");
        getUserRole();
      })
      .catch((err) => {
        displayErrorDialog(err);
      });
  };

  // ユーザのロール取得
  const getUserRole = async () => {
    await api
      .get(GET_USER_ROLE)
      .then((res) => {
        roleContext.setRole(res.data);
        handleToNextPage();
      })
      .catch((err) => {
        displayErrorDialog(err);
      });
  };

  // お知らせ取得
  const getGlobalInfo = () => {
    axios
      .get(GET_GLOBAL_INFO)
      .then((res) => {
        // console.log("globalInfo", res.data);
        setGlobalInfo(res.data);
      })
      .catch((err) => {
        displayErrorDialog(err);
      });
  };

  //エラー発生時にエラーメッセージを設定して表示
  const displayErrorDialog = (err: any) => {
    //エラーコードによってエラーメッセージを切り替える
    if(digOpen === true) {
      setDigOpen(false);
    }

    switch (err.response?.status) {
      case 401: //入力ミス時
        setMsgLoginError(languageContext.words.incorrect_login_information);
        break;
      case 500: //通信異常時
        setMsgLoginError(languageContext.words.timeout_login_information);
        break;
      default:
        setMsgLoginError(languageContext.words.timeout_login_information);
        break;
    }
    setDigOpen(true);
  };

  return (
    <>
      <Box sx={{ textAlign: "center", padding: "10px" }}>
        <Company />
      </Box>
      {/*ここにお知らせ*/}
      <TableContainer
        sx={{
          width: "100%",
          maxWidth: 340,
          maxHeight: 200,
          bgcolor: "inherit",
          border: "3px solid LightSteelBlue",
          marginLeft: "41%",
          marginBottom: "1%",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: "10px",
            backgroundColor: "transparent",
            height: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#AAA",
            borderRadius: "10px",
          },
        }}
      >
        <TableHead
          sx={{
            position: "sticky",
            top: 0,
            padding: "0px",
            zIndex: 1,
            display: "flex",
            backgroundColor: "inherit",
            alignItems: "center",
          }}
        >
          <InfoOutlinedIcon
            sx={{ color: "CornflowerBlue", marginTop: "4px" }}
          />
          <Typography sx={{ marginTop: "6px", marginLeft: "5px" }}>
            {languageContext.words.infomation}
          </Typography>
        </TableHead>
        {globalInfo.map((message, index) => {
          return (
            <>
              <Box key={`${message} ${index}`} sx={{ my: 3, mx: 2 }}>
                <Typography variant="body2">{message}</Typography>
              </Box>
              <Divider variant="middle" />
            </>
          );
        })}
      </TableContainer>
      <Card sx={{ maxWidth: 345, margin: "auto" }}>
        <CardContent>
          <Stack spacing={2} sx={{ alignItems: "center" }}>
            <Box>
              <LoginImage />
            </Box>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={digOpen}
              onClose={() => setDigOpen(false)}
            >
              <Alert
                variant="filled"
                onClose={() => setDigOpen(false)}
                severity="error"
                sx={{ width: "100%" }}
              >
                {msgLoginError}
              </Alert>
            </Snackbar>
            <TextField
              name="tenantID"
              label={languageContext.words.tenant_id}
              variant="outlined"
              autoFocus
              value={loginInfo.tenantCode}
              size="small"
              inputRef={tenantIDRef}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  userIDRef.current.focus();
                }
              }}
              onChange={(e) =>
                setAuthInfo({ ...loginInfo, tenantCode: e.target.value })
              }
            ></TextField>
            <TextField
              name="txtID"
              label={languageContext.words.user_id}
              variant="outlined"
              value={loginInfo.userId}
              size="small"
              inputRef={userIDRef}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  userPASSRef.current.focus();
                }
              }}
              onChange={(e) =>
                setAuthInfo({ ...loginInfo, userId: e.target.value })
              }
            ></TextField>
            <TextField
              name="txtPASS"
              label={languageContext.words.password}
              variant="outlined"
              type="password"
              value={loginInfo.password}
              onChange={(e) =>
                setAuthInfo({ ...loginInfo, password: e.target.value })
              }
              size="small"
              inputRef={userPASSRef}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  buttonRef.current.click();
                }
              }}
            ></TextField>
            <Button
              sx={{ width: 230 }}
              color="primary"
              size="small"
              variant="contained"
              ref={buttonRef}
              onClick={() => handleClick()}
            >
              {languageContext.words.log_in}
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <Box
        sx={{
          position: "absolute",
          right: "24px",
          bottom: "24px",
        }}
      >
        {process.env.REACT_APP_VER}
      </Box>
    </>
  );
};

export default Login;
