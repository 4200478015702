import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import "./css/UserList.css";

// ユーザ一覧
import SetUserList from "./SetUserList";
import { Box, Typography } from "@mui/material";
import SetSaleList from "./SetSaleList";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import { LanguageContext } from "../common/localization/localization";
import { saleGroupInfo } from "../../types/master/SaleList";
import {
  ModalButton,
  NewRegistrationButton,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterButton";
import {
  StyledMasterTableCell,
  StyledMasterTableContainer,
  StyledMasterTable,
  StyledMasterTableHeader,
  StyledTableHeaderBig,
  StyledTableHeaderSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomTableEx";
import { RoleContext } from "../common/customComponents/RoleProvider";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";

export enum modalMode {
  editMode,
  addMode,
}

const SaleList = () => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);
  //ログイン中ユーザーのロールコンテキスト
  const userRoleContext = useContext(RoleContext);

  // 通信エラー
  const api = useAuthedApi();

  //エリアIDをエリア名に変換
  const { convertIdToAreaName } = useAreaUtility();

  // アスペクト比
  const options = {
    maintainAspectRatio: false,
  };

  //ユーザーが編集権限を持っているかを格納
  const [enableEdit, setEnableEdit] = useState(false);
  //showがtrueになった時、モーダルが開かれる
  const [show, setShow] = useState(false);
  //DBから取得したデータをsaleGrInfoに格納して描画
  const [saleGrInfos, setSaleGrInfo] = useState<saleGroupInfo[]>();

  //編集モードか、新規登録モードかを管理する 1:新規追加 2：編集
  const [mode, setMode] = useState<modalMode>(modalMode.addMode);

  //編集ボタンを押した行のグループID情報
  const [groupIdForEdit, setGroupIdForEdit] = useState<number>(0);

  //saleGrInfo初期化
  useEffect(() => {
    //ロール検査
    checkEditRoll();
    setSaleGrInfo([
      {
        groupId: 0,
        groupName: "",
        areaId: "",
        saleKind: "",
        remarks: "",
      },
    ]);
  }, []);

  // 全件表示（現状モーダル表示を管理するshowがfalseになったタイミングで描写）
  useEffect(() => {
    for (let i = 0; i < 2; i++) {
      requestSaleGrInfo();
    }
  }, [show]);

  // 設定モーダル開く
  const HandleOpen = () => setShow(true);
  // 設定モーダル閉じる
  const HandleClose = () => setShow(false);

  //設定モーダルを新規追加モードで開く
  const HandleOpenForAddNew = () => {
    //新規追加モードに
    setMode(modalMode.addMode);
    //bgCodePK内の情報を一応0で初期化
    setGroupIdForEdit(0);

    setShow(true);
  };

  //設定モーダルを編集モードで開く
  const HandleOpenForEdit = (groupId: number) => {
    //編集モードに
    setMode(modalMode.editMode);
    //culumnInfoに行の情報を設定
    setGroupIdForEdit(groupId);

    setShow(true);
  };

  //データベースから発電BGデータ取得
  const requestSaleGrInfo = () => {
    // 発電BG一覧取得
    //console.log("発電BGデータ取得開始");
    let responseData: saleGroupInfo[] = [];
    api
      .get(API_URL.GET_SALEGROUP_LIST)
      .then((response) => {
        //console.log(response.data.result);
        responseData = response.data.result as saleGroupInfo[];
        dispDatas(responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 編集可能ユーザか確認
  const checkEditRoll = () => {
    const userRoll = userRoleContext.role;
    api
      .get(API_URL.CHECK_EDIT_ROLE, { params: { userRoll: userRoll } })
      .then((response) => {
        const edit = response.data;
        // 管理者権限がある時のみ編集等の書き込みを有効にする
        if (edit == true) {
          setEnableEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 取ってきたデータが空であったり、異常なものでないかチェックしてから表示
  const dispDatas = (responseData: saleGroupInfo[]) => {
    let saleGroupList: saleGroupInfo[] = [];
    //const datas: any[] = [];
    //取ってきたデータがPowerBGInfo型のものかチェック
    try {
      saleGroupList = responseData;
      //console.log(saleGroupList);
    } catch (error) {
      console.log(error);
    }

    if (saleGroupList === undefined) {
      return;
    }

    setSaleGrInfo(saleGroupList);
  };

  //販売種別コードで取ってきているため、名前に変換する
  const convertKindCdToKindName = (kindCode: string): string => {
    let kindName = "";

    switch (kindCode) {
      case "0":
        kindName = "-";
        break;
      case "11":
        kindName = languageContext.words.internal_other_bg;
        break;
      case "12":
        kindName = languageContext.words.internal_self_consignment;
        break;
      case "13":
        kindName = languageContext.words.external_self_consignment;
        break;
      case "21":
        kindName = languageContext.words.spot;
        break;
      case "31":
        kindName = languageContext.words.hour_ago;
        break;
      default:
        break;
    }
    return kindName;
  };

  return (
    <div className="cn-setting-display">
      <Box
        sx={{
          width: "100%",
          height: "20%",
          display: "flex",
          paddingBottom: "17px",
        }}
      >
        <NewRegistrationButton
          style={{ display: !enableEdit ? "none" : undefined }}
          variant="outlined"
          onClick={() => HandleOpenForAddNew()}
        >
          {languageContext.words.new_registration}
        </NewRegistrationButton>
        {SetSaleList(show, HandleClose, mode, groupIdForEdit)}
      </Box>
      <StyledMasterTableContainer>
        <StyledMasterTable>
          <StyledMasterTableHeader>
            <TableRow>
              <StyledTableHeaderBig>
                {languageContext.words.sales_destinations_name}
              </StyledTableHeaderBig>

              <StyledTableHeaderSmall>
                {languageContext.words.sales_destinations_type}
              </StyledTableHeaderSmall>

              <StyledTableHeaderSmall>
                {languageContext.words.supplier_area}
              </StyledTableHeaderSmall>

              <StyledTableHeaderBig>
                {languageContext.words.remarks}
              </StyledTableHeaderBig>
            </TableRow>
          </StyledMasterTableHeader>
          <TableBody>
            {saleGrInfos?.map((saleGrInfo) => (
              <TableRow key={saleGrInfo.groupId}>
                <StyledMasterTableCell sx={{ textAlign: "left !important" }}>
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                    <Typography
                      title={saleGrInfo.groupName}
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                    >
                      {saleGrInfo.groupName}
                    </Typography>
                    <ModalButton
                      style={{ display: !enableEdit ? "none" : undefined }}
                      variant="outlined"
                      onClick={() => HandleOpenForEdit(saleGrInfo.groupId)}
                    >
                      {languageContext.words.edit}
                    </ModalButton>
                  </Box>
                </StyledMasterTableCell>

                <StyledMasterTableCell
                  title={convertKindCdToKindName(saleGrInfo.saleKind)}
                >
                  {convertKindCdToKindName(saleGrInfo.saleKind)}
                </StyledMasterTableCell>

                <StyledMasterTableCell
                  title={convertIdToAreaName(saleGrInfo.areaId)}
                >
                  {convertIdToAreaName(saleGrInfo.areaId)}
                </StyledMasterTableCell>

                <StyledMasterTableCell
                  title={saleGrInfo.remarks}
                  sx={{ textAlign: "left !important" }}
                >
                  {saleGrInfo.remarks}
                </StyledMasterTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledMasterTable>
      </StyledMasterTableContainer>
    </div>
  );
};

export default SaleList;
