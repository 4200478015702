import React, { useContext, useEffect, useState } from "react";
import { Chart as ChartJS } from "chart.js";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";

import { Box, Button, styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../common/localization/localization";
import axios from "axios";
import { PaletteContext } from "../../common/paletteMode";
import { NOTIFICATION_URL } from "../../../common/url";
import { AREA_ID } from "../../../common/constant";
import { useAreaUtility } from "../../../common/area";
import { ConnectionErrorDisplay } from "./ConnectionErrorComponent";
import { useAuthedModalApi } from "../../../common/axios";

//a要素ボタン
const ButtonLink = styled(Button)`
  &.MuiButton-root {
    height: 100%;
    border-radius: 0px;
    color: black;
    font-size: 14px;
  }
` as typeof Button;

const GET_EVENT_LIST = "/getEventList";

// お知らせ一覧
export interface notificationListType {
  eventDate: string;
  kind: string;
  targetDate: string;
  area: string;
  bg: string;
  message: string;
}

interface notificationProps {
  closeHandler: () => void;
}

// お知らせ一覧画面
const SubNotificationList = (props: notificationProps) => {
  const { closeHandler } = props;
  //お知らせのセレクトボックス
  const [notificationList, setnotificationList] = useState<
    notificationListType[]
  >([]);
  const [error, setError] = useState<boolean>(false);

  const languageContext = useContext(LanguageContext);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  //エリアのセレクトボックス
  const [area, setArea] = useState(AREA_ID.NONE);
  const { convertIdToAreaName } = useAreaUtility();

  const api = useAuthedModalApi(setError);

  useEffect(() => {
    getNoftification();
  }, []);

  // お知らせの取得
  function getNoftification() {
    api
      .get(GET_EVENT_LIST, { params: { areaId: area } })
      .then((res) => {
        setError(false);
        if (res.data.length != 0) {
          // お知らせの情報を取得
          setnotificationList(res.data);
        } else {
          // お知らせ情報なし
        }
      })
      .catch((err) => {
        // TODO エラー対応
      });
  }

  return (
    <div
      className={`cn-sub-display ${PaletteMode === "dark" ? "dark" : "light"}`}
    >
      <Box
        sx={{
          width: "100%",
          height: "8%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ color: "gray", marginY: "auto", paddingLeft: "8px" }}>
          {languageContext.words.notification}({notificationList.length})
        </Typography>
        <ButtonLink
          component={Link}
          to={NOTIFICATION_URL}
          sx={{
            WebkitTextFillColor: PaletteMode === "dark" ? "#ffffff" : "#000000",
          }}
          onClick={closeHandler}
        >
          {languageContext.words.notification_list}
        </ButtonLink>
      </Box>
      {error ? (
        <ConnectionErrorDisplay />
      ) : (
        <TableContainer
          sx={{
            minWidth: "100%",
            height: "92%",
            display: "flexed",
            "&::-webkit-scrollbar": {
              width: "10px",
              backgroundColor: "transparent",
              height: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#AAA",
              borderRadius: "10px",
            },
          }}
        >
          <StyledTable
            sx={{
              width: "100%",
              height: "100%",
              position: "sticky",
              left: 0,
              zIndex: 1,
              borderCollapse: "separate",
            }}
          >
            <TableHead
              sx={{
                background: "rgb(220,230,241)",
                position: "sticky",
                top: 0,
                zIndex: 1,
                height: 30,
              }}
            >
              <TableRow>
                <StyledTableHeaderLG
                  align="center"
                  sx={{ height: "30px", padding: "0px" }}
                >
                  {languageContext.words.date_and_time_of_occurrence}
                </StyledTableHeaderLG>
                <StyledTableHeaderLG
                  align="center"
                  sx={{ height: "30px", padding: "0px" }}
                >
                  {languageContext.words.target_date}
                </StyledTableHeaderLG>
                <StyledTableHeaderLG
                  align="center"
                  sx={{ height: "30px", padding: "0px" }}
                >
                  {languageContext.words.area}
                </StyledTableHeaderLG>
                <StyledTableHeaderLG
                  align="center"
                  sx={{ height: "30px", padding: "0px" }}
                >
                  {languageContext.words.bg}
                </StyledTableHeaderLG>
                <StyledTableHeaderLG
                  align="center"
                  sx={{ height: "30px", padding: "0px" }}
                >
                  {languageContext.words.message}
                </StyledTableHeaderLG>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{ background: "rgb(255,255,255)", overflowY: "scroll" }}
            >
              {notificationList.map((data, index) => (
                <TableRow key={index}>
                  <StyledTableCell
                    align="center"
                    sx={{ height: "30px", padding: "0px" }}
                  >
                    {languageContext.convertDateToLocalizedString(
                      new Date(data.eventDate),
                      "HH:mm:ss"
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ height: "30px", padding: "0px" }}
                  >
                    {languageContext.convertDateToLocalizedString(
                      new Date(data.targetDate),
                      "HH:mm:ss"
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ height: "30px", padding: "0px" }}
                  >
                    {convertIdToAreaName(data.area)}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ height: "30px", padding: "0px" }}
                  >
                    {data.bg}
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ height: "30px", padding: "0px", paddingLeft: "8px" }}
                  >
                    {data.message}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      )}
    </div>
  );
};

export default SubNotificationList;
