import React from "react";

/**
 * id リスト
 * 01	北海道
 * 02	東北
 * 03	東京
 * 04	中部
 * 05	北陸
 * 06	関西
 * 07	中国
 * 08	四国
 * 09	九州
 * 10	沖縄
 */

const JapanMap = (color01:string, color02:string, color03:string, color04:string, color05:string, color06:string, color07:string, color08:string, color09:string, color10:string) => {
  return (
    <React.Fragment>
      <svg
        width="576"
        height="325"
        viewBox="0 0 576 325"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M179.594 168.809L182.263 182.81C182.281 182.916 182.298 183.031 182.298 183.146L179.629 169.144C179.629 169.03 179.611 168.915 179.594 168.809Z"
          fill="#CFD8DC"
        />
        <path
          d="M179.629 169.145L182.298 183.146C182.298 183.279 182.298 183.42 182.272 183.553L179.603 169.551C179.629 169.41 179.638 169.277 179.629 169.145Z"
          fill="#CFD8DC"
        />
        <path
          d="M179.593 169.551L182.263 183.552C182.254 183.605 182.245 183.658 182.227 183.711L179.558 169.71C179.567 169.657 179.585 169.604 179.593 169.551Z"
          fill="#90A4AE"
        />
        <path
          d="M81.9173 207.816L84.5866 221.818C83.0575 221.712 81.9527 220.855 81.7406 219.75L79.0713 205.748C79.2834 206.853 80.3883 207.71 81.9173 207.816Z"
          fill="#CFD8DC"
        />
        <path
          d="M97.5707 208.656L100.24 222.649L84.5779 221.809L81.9175 207.816L97.5707 208.656Z"
          fill="#CFD8DC"
        />
        <path
          d="M100.39 210.725L103.059 224.726C102.847 223.621 101.751 222.764 100.24 222.658L97.5703 208.656C99.0817 208.762 100.178 209.62 100.39 210.725Z"
          fill="#CFD8DC"
        />
        <path
          d="M91.7018 261.764L94.3711 275.765C92.8685 275.659 91.7725 274.802 91.5604 273.706L88.8911 259.704C89.1032 260.8 90.1992 261.658 91.7018 261.764Z"
          fill="#CFD8DC"
        />
        <path
          d="M179.567 169.71L182.227 183.712L159.45 277.621L156.781 263.619L179.567 169.71Z"
          fill="#90A4AE"
        />
        <path
          d="M152.68 266.104L155.34 280.097L94.3714 275.765L91.7021 261.764L152.68 266.104Z"
          fill="#CFD8DC"
        />
        <path
          d="M176.783 166.74C178.683 166.873 179.929 168.208 179.567 169.71L156.781 263.62C156.419 265.122 154.58 266.236 152.68 266.095L91.7107 261.755C89.8104 261.622 88.5641 260.287 88.9265 258.785L100.373 211.618C100.735 210.115 99.4799 208.78 97.5795 208.648L81.9174 207.808C79.9906 207.667 78.7267 206.341 79.0979 204.829L89.1828 163.266C89.5452 161.764 91.3837 160.65 93.2928 160.791L176.783 166.731V166.74Z"
          fill={color09}
          id="kyushu"
          className="kyushu"
        />
        <path
          d="M156.781 263.619L159.45 277.621C159.415 277.771 159.362 277.921 159.291 278.063L156.622 264.061C156.683 263.92 156.736 263.769 156.781 263.619Z"
          fill="#90A4AE"
        />
        <path
          d="M156.622 264.07L159.291 278.072C159.149 278.381 158.937 278.673 158.681 278.929L156.012 264.928C156.268 264.671 156.48 264.38 156.622 264.07Z"
          fill="#90A4AE"
        />
        <path
          d="M156.012 264.928L158.681 278.929C158.505 279.097 158.31 279.256 158.098 279.398L155.429 265.396C155.641 265.255 155.835 265.105 156.012 264.928Z"
          fill="#90A4AE"
        />
        <path
          d="M155.428 265.396L158.098 279.398C157.956 279.495 157.806 279.575 157.647 279.654L154.978 265.653C155.137 265.573 155.287 265.494 155.428 265.396Z"
          fill="#CFD8DC"
        />
        <path
          d="M154.987 265.652L157.656 279.654C157.523 279.716 157.382 279.778 157.241 279.831L154.571 265.829C154.713 265.776 154.854 265.714 154.987 265.652Z"
          fill="#CFD8DC"
        />
        <path
          d="M154.571 265.829L157.24 279.831C157.108 279.884 156.975 279.919 156.834 279.963L154.165 265.962C154.306 265.926 154.439 265.882 154.571 265.829Z"
          fill="#CFD8DC"
        />
        <path
          d="M154.164 265.953L156.834 279.955C156.692 279.99 156.551 280.025 156.41 280.043L153.74 266.041C153.882 266.015 154.023 265.988 154.164 265.953Z"
          fill="#CFD8DC"
        />
        <path
          d="M153.74 266.051L156.41 280.052C156.259 280.079 156.1 280.097 155.95 280.105L153.281 266.104C153.44 266.095 153.59 266.077 153.74 266.051Z"
          fill="#CFD8DC"
        />
        <path
          d="M153.28 266.104L155.95 280.105C155.764 280.114 155.579 280.114 155.393 280.105L152.724 266.104C152.909 266.112 153.095 266.112 153.28 266.104Z"
          fill="#CFD8DC"
        />
        <path
          d="M152.724 266.104L155.393 280.105C155.393 280.105 155.358 280.105 155.349 280.105L152.68 266.104C152.68 266.104 152.715 266.104 152.724 266.104Z"
          fill="#CFD8DC"
        />
        <path
          d="M263.765 174.793L266.434 188.795C266.452 188.901 266.469 189.016 266.469 189.13L263.8 175.129C263.8 175.014 263.782 174.899 263.765 174.793Z"
          fill="#CFD8DC"
        />
        <path
          d="M263.799 175.129L266.469 189.13C266.469 189.263 266.469 189.404 266.442 189.537L263.773 175.536C263.799 175.394 263.808 175.261 263.799 175.129Z"
          fill="#CFD8DC"
        />
        <path
          d="M263.764 175.535L266.433 189.537C266.424 189.59 266.415 189.643 266.398 189.696L263.729 175.694C263.737 175.641 263.755 175.588 263.764 175.535Z"
          fill="#90A4AE"
        />
        <path
          d="M180 224.452L182.669 238.454C181.167 238.348 180.071 237.49 179.859 236.394L177.189 222.393C177.402 223.489 178.498 224.346 180 224.452Z"
          fill="#CFD8DC"
        />
        <path
          d="M263.729 175.694L266.398 189.696L254.015 240.752L251.346 226.751L263.729 175.694Z"
          fill="#90A4AE"
        />
        <path
          d="M247.245 229.234L249.905 243.236L182.669 238.454L180 224.452L247.245 229.234Z"
          fill="#CFD8DC"
        />
        <path
          d="M260.953 172.733C262.854 172.865 264.1 174.2 263.738 175.703L251.355 226.759C250.992 228.27 249.154 229.375 247.253 229.243L180.018 224.461C178.117 224.328 176.871 222.993 177.234 221.491L189.617 170.434C189.979 168.932 191.817 167.818 193.718 167.959L260.953 172.741V172.733Z"
          fill={color07}
          id="chugoku"
          className="chugoku"
        />
        <path
          d="M251.346 226.75L254.015 240.752C253.98 240.902 253.926 241.052 253.856 241.202L251.187 227.201C251.257 227.059 251.301 226.909 251.346 226.75Z"
          fill="#90A4AE"
        />
        <path
          d="M251.187 227.201L253.856 241.203C253.715 241.521 253.503 241.804 253.246 242.069L250.577 228.067C250.833 227.811 251.046 227.519 251.187 227.201Z"
          fill="#90A4AE"
        />
        <path
          d="M250.577 228.067L253.246 242.069C253.069 242.237 252.875 242.396 252.662 242.537L249.993 228.536C250.205 228.394 250.4 228.244 250.577 228.067Z"
          fill="#90A4AE"
        />
        <path
          d="M249.994 228.527L252.663 242.529C252.522 242.626 252.371 242.706 252.212 242.785L249.543 228.784C249.702 228.704 249.852 228.625 249.994 228.527Z"
          fill="#CFD8DC"
        />
        <path
          d="M249.552 228.783L252.221 242.785C252.088 242.847 251.947 242.909 251.806 242.962L249.136 228.96C249.278 228.907 249.419 228.845 249.552 228.783Z"
          fill="#CFD8DC"
        />
        <path
          d="M249.136 228.96L251.805 242.962C251.673 243.015 251.54 243.05 251.399 243.094L248.729 229.093C248.871 229.057 249.003 229.013 249.136 228.96Z"
          fill="#CFD8DC"
        />
        <path
          d="M248.729 229.093L251.399 243.094C251.257 243.13 251.116 243.165 250.974 243.183L248.305 229.181C248.447 229.155 248.588 229.128 248.729 229.093Z"
          fill="#CFD8DC"
        />
        <path
          d="M248.305 229.182L250.975 243.183C250.824 243.21 250.674 243.227 250.515 243.236L247.846 229.235C248.005 229.226 248.155 229.208 248.305 229.182Z"
          fill="#CFD8DC"
        />
        <path
          d="M247.846 229.234L250.515 243.236C250.33 243.245 250.144 243.245 249.958 243.236L247.289 229.234C247.475 229.243 247.66 229.243 247.846 229.234Z"
          fill="#CFD8DC"
        />
        <path
          d="M247.289 229.243L249.958 243.245C249.958 243.245 249.923 243.245 249.914 243.245L247.245 229.243C247.245 229.243 247.28 229.243 247.289 229.243Z"
          fill="#CFD8DC"
        />
        <path
          d="M247.183 240.451L249.852 254.453C249.87 254.559 249.887 254.674 249.887 254.789L247.218 240.787C247.218 240.672 247.2 240.557 247.183 240.451Z"
          fill="#CFD8DC"
        />
        <path
          d="M247.218 240.796L249.887 254.797C249.887 254.93 249.887 255.071 249.861 255.204L247.191 241.203C247.218 241.061 247.227 240.928 247.218 240.796Z"
          fill="#CFD8DC"
        />
        <path
          d="M247.183 241.203L249.852 255.205C249.843 255.258 249.834 255.311 249.817 255.364L247.147 241.362C247.156 241.309 247.174 241.256 247.183 241.203Z"
          fill="#90A4AE"
        />
        <path
          d="M169.606 267.306L172.275 281.307C170.772 281.201 169.676 280.344 169.464 279.248L166.795 265.246C167.007 266.342 168.103 267.2 169.606 267.306Z"
          fill="#CFD8DC"
        />
        <path
          d="M247.157 241.362L249.817 255.364L242.976 283.57L240.315 269.569L247.157 241.362Z"
          fill="#90A4AE"
        />
        <path
          d="M236.196 272.044L238.866 286.045L172.275 281.307L169.606 267.306L236.196 272.044Z"
          fill="#CFD8DC"
        />
        <path
          d="M244.363 238.392C246.272 238.525 247.518 239.86 247.156 241.362L240.315 269.56C239.952 271.063 238.114 272.176 236.205 272.035L169.614 267.297C167.714 267.164 166.468 265.83 166.83 264.327L173.671 236.129C174.034 234.618 175.872 233.513 177.773 233.646L244.363 238.383V238.392Z"
          fill={color08}
          id="sikoku"
          className="sikoku"
        />
        <path
          d="M240.315 269.568L242.985 283.57C242.949 283.72 242.896 283.87 242.826 284.012L240.156 270.01C240.218 269.869 240.271 269.719 240.315 269.568Z"
          fill="#90A4AE"
        />
        <path
          d="M240.156 270.01L242.826 284.011C242.684 284.321 242.472 284.612 242.216 284.869L239.546 270.867C239.803 270.611 240.015 270.319 240.156 270.01Z"
          fill="#90A4AE"
        />
        <path
          d="M239.546 270.867L242.216 284.869C242.048 285.037 241.844 285.196 241.632 285.337L238.963 271.336C239.175 271.194 239.369 271.044 239.546 270.867Z"
          fill="#90A4AE"
        />
        <path
          d="M238.963 271.336L241.632 285.337C241.491 285.435 241.341 285.514 241.181 285.594L238.512 271.592C238.671 271.513 238.822 271.433 238.963 271.336Z"
          fill="#CFD8DC"
        />
        <path
          d="M238.521 271.593L241.19 285.594C241.058 285.656 240.916 285.718 240.775 285.771L238.105 271.77C238.247 271.717 238.388 271.655 238.521 271.593Z"
          fill="#CFD8DC"
        />
        <path
          d="M238.106 271.77L240.775 285.771C240.643 285.824 240.51 285.859 240.369 285.904L237.699 271.902C237.841 271.867 237.973 271.823 238.106 271.77Z"
          fill="#CFD8DC"
        />
        <path
          d="M237.699 271.901L240.368 285.903C240.227 285.938 240.086 285.974 239.944 285.991L237.275 271.99C237.416 271.963 237.558 271.937 237.699 271.901Z"
          fill="#CFD8DC"
        />
        <path
          d="M237.275 271.99L239.944 285.992C239.794 286.018 239.635 286.036 239.485 286.045L236.815 272.043C236.975 272.034 237.125 272.017 237.275 271.99Z"
          fill="#CFD8DC"
        />
        <path
          d="M236.806 272.043L239.476 286.045C239.29 286.053 239.104 286.053 238.91 286.045L236.241 272.043C236.426 272.052 236.621 272.052 236.806 272.043Z"
          fill="#CFD8DC"
        />
        <path
          d="M236.249 272.043L238.919 286.045C238.919 286.045 238.883 286.045 238.874 286.045L236.205 272.043C236.205 272.043 236.24 272.043 236.249 272.043Z"
          fill="#CFD8DC"
        />
        <path
          d="M573.294 9.54102L575.963 23.5426C575.981 23.6486 575.999 23.7635 575.999 23.8785L573.329 9.87691C573.329 9.762 573.312 9.64709 573.294 9.54102Z"
          fill="#CFD8DC"
        />
        <path
          d="M573.329 9.87695L575.999 23.8785C575.999 24.0111 575.999 24.1525 575.972 24.2851L573.303 10.2836C573.329 10.1421 573.338 10.0095 573.329 9.87695Z"
          fill="#CFD8DC"
        />
        <path
          d="M573.303 10.2832L575.972 24.2848C575.963 24.3378 575.954 24.3908 575.937 24.4439L573.268 10.4423C573.276 10.3893 573.294 10.3362 573.303 10.2832Z"
          fill="#90A4AE"
        />
        <path
          d="M465.409 73.1133L468.079 87.1148C468.079 87.1148 468.043 87.1148 468.035 87.1148L465.365 73.1133C465.365 73.1133 465.401 73.1133 465.409 73.1133Z"
          fill="#CFD8DC"
        />
        <path
          d="M465.365 73.1043L468.034 87.1058C467.849 87.097 467.663 87.097 467.477 87.1058L464.808 73.1043C464.994 73.0954 465.179 73.0954 465.365 73.1043Z"
          fill="#CFD8DC"
        />
        <path
          d="M464.808 73.1133L467.478 87.1148C467.318 87.1237 467.168 87.1414 467.018 87.1679L464.349 73.1663C464.499 73.1398 464.658 73.1221 464.808 73.1133Z"
          fill="#CFD8DC"
        />
        <path
          d="M464.349 73.166L467.018 87.1676C466.876 87.1941 466.735 87.2206 466.594 87.2559L463.924 73.2544C464.066 73.219 464.207 73.1837 464.349 73.166Z"
          fill="#CFD8DC"
        />
        <path
          d="M463.924 73.2549L466.593 87.2564C466.452 87.2918 466.319 87.336 466.187 87.389L463.518 73.3875C463.65 73.3344 463.783 73.2991 463.924 73.2549Z"
          fill="#CFD8DC"
        />
        <path
          d="M463.518 73.3877L466.187 87.3892C466.046 87.4423 465.904 87.5041 465.772 87.566L463.103 73.5645C463.235 73.5026 463.377 73.4407 463.518 73.3877Z"
          fill="#CFD8DC"
        />
        <path
          d="M463.102 73.5645L465.771 87.566C465.612 87.6456 465.462 87.7251 465.321 87.8223L462.651 73.8208C462.793 73.7236 462.943 73.644 463.102 73.5645Z"
          fill="#CFD8DC"
        />
        <path
          d="M462.652 73.8203L465.321 87.8219C465.109 87.9633 464.914 88.1136 464.738 88.2904L462.068 74.2888C462.245 74.1208 462.44 73.9617 462.652 73.8203Z"
          fill="#90A4AE"
        />
        <path
          d="M462.077 74.2803L464.746 88.2818C464.49 88.5382 464.278 88.8299 464.136 89.1392L461.467 75.1377C461.608 74.8283 461.82 74.5366 462.077 74.2803Z"
          fill="#90A4AE"
        />
        <path
          d="M461.458 75.1465L464.128 89.148C464.066 89.2895 464.013 89.4397 463.969 89.59L461.299 75.5885C461.335 75.4382 461.388 75.2879 461.458 75.1465Z"
          fill="#90A4AE"
        />
        <path
          d="M425.211 85.9307L427.88 99.9322C426.378 99.8261 425.282 98.9687 425.07 97.8726L422.4 83.8711C422.613 84.9672 423.709 85.8246 425.211 85.9307Z"
          fill="#CFD8DC"
        />
        <path
          d="M461.308 75.5889L463.977 89.5904L461.556 99.5612L458.886 85.5597L461.308 75.5889Z"
          fill="#90A4AE"
        />
        <path
          d="M454.776 88.0344L457.446 102.036L427.88 99.9322L425.211 85.9307L454.776 88.0344Z"
          fill="#CFD8DC"
        />
        <path
          d="M458.886 85.5596L461.555 99.5611C461.52 99.7114 461.467 99.8617 461.396 100.003L458.727 86.0015C458.789 85.8601 458.842 85.7098 458.886 85.5596Z"
          fill="#90A4AE"
        />
        <path
          d="M458.736 86.002L461.405 100.004C461.264 100.313 461.052 100.605 460.795 100.861L458.126 86.8594C458.382 86.603 458.594 86.3113 458.736 86.002Z"
          fill="#90A4AE"
        />
        <path
          d="M458.126 86.8594L460.796 100.861C460.628 101.029 460.424 101.188 460.212 101.329L457.543 87.3279C457.755 87.1864 457.95 87.0362 458.126 86.8594Z"
          fill="#90A4AE"
        />
        <path
          d="M457.543 87.3271L460.212 101.329C460.07 101.426 459.92 101.505 459.761 101.585L457.092 87.5835C457.251 87.5039 457.401 87.4244 457.543 87.3271Z"
          fill="#CFD8DC"
        />
        <path
          d="M457.101 87.584L459.77 101.586C459.638 101.647 459.496 101.709 459.355 101.762L456.686 87.7608C456.827 87.7077 456.968 87.6459 457.101 87.584Z"
          fill="#CFD8DC"
        />
        <path
          d="M456.685 87.7607L459.355 101.762C459.222 101.815 459.09 101.851 458.948 101.895L456.279 87.8933C456.42 87.858 456.553 87.8138 456.685 87.7607Z"
          fill="#CFD8DC"
        />
        <path
          d="M456.27 87.8926L458.939 101.894C458.798 101.929 458.656 101.965 458.515 101.983L455.846 87.981C455.987 87.9545 456.129 87.9279 456.27 87.8926Z"
          fill="#CFD8DC"
        />
        <path
          d="M455.846 87.9814L458.515 101.983C458.365 102.01 458.206 102.027 458.056 102.036L455.386 88.0345C455.545 88.0256 455.696 88.008 455.846 87.9814Z"
          fill="#CFD8DC"
        />
        <path
          d="M455.386 88.0352L458.055 102.037C457.87 102.046 457.684 102.046 457.49 102.037L454.82 88.0352C455.006 88.044 455.2 88.044 455.386 88.0352Z"
          fill="#CFD8DC"
        />
        <path
          d="M454.821 88.0352L457.49 102.037C457.49 102.037 457.454 102.037 457.446 102.037L454.776 88.0352C454.776 88.0352 454.812 88.0352 454.821 88.0352Z"
          fill="#CFD8DC"
        />
        <path
          d="M446.256 95.752L448.925 109.754C448.943 109.86 448.96 109.974 448.96 110.089L446.291 96.0879C446.291 95.9729 446.274 95.858 446.256 95.752Z"
          fill="#CFD8DC"
        />
        <path
          d="M446.291 96.0869L448.96 110.088C448.96 110.221 448.96 110.362 448.934 110.495L446.265 96.4935C446.291 96.3521 446.3 96.2195 446.291 96.0869Z"
          fill="#CFD8DC"
        />
        <path
          d="M446.265 96.4941L448.934 110.496C448.925 110.549 448.916 110.602 448.899 110.655L446.229 96.6532C446.238 96.6002 446.256 96.5472 446.265 96.4941Z"
          fill="#90A4AE"
        />
        <path
          d="M573.267 10.4424L575.936 24.4439L559.815 90.8629L557.154 76.8614L573.267 10.4424Z"
          fill="#90A4AE"
        />
        <path
          d="M553.044 79.345L555.714 93.3466L468.078 87.1148L465.409 73.1133L553.044 79.345Z"
          fill="#CFD8DC"
        />
        <path
          d="M557.154 76.8613L559.823 90.8629C559.788 91.0131 559.735 91.1634 559.664 91.3137L556.995 77.3121C557.066 77.1707 557.11 77.0204 557.154 76.8613Z"
          fill="#90A4AE"
        />
        <path
          d="M556.996 77.3125L559.665 91.3141C559.523 91.6323 559.311 91.9151 559.055 92.1803L556.386 78.1788C556.642 77.9224 556.854 77.6307 556.996 77.3125Z"
          fill="#90A4AE"
        />
        <path
          d="M556.377 78.1787L559.046 92.1803C558.869 92.3482 558.675 92.5073 558.463 92.6487L555.793 78.6472C556.006 78.5058 556.2 78.3555 556.377 78.1787Z"
          fill="#90A4AE"
        />
        <path
          d="M555.802 78.6387L558.472 92.6402C558.33 92.7375 558.18 92.817 558.021 92.8966L555.352 78.895C555.511 78.8155 555.661 78.7359 555.802 78.6387Z"
          fill="#CFD8DC"
        />
        <path
          d="M555.351 78.8945L558.021 92.8961C557.888 92.958 557.747 93.0198 557.605 93.0729L554.936 79.0713C555.077 79.0183 555.219 78.9564 555.351 78.8945Z"
          fill="#CFD8DC"
        />
        <path
          d="M554.936 79.0713L557.605 93.0728C557.473 93.1259 557.34 93.1612 557.199 93.2054L554.529 79.2039C554.671 79.1685 554.803 79.1243 554.936 79.0713Z"
          fill="#CFD8DC"
        />
        <path
          d="M554.529 79.2031L557.199 93.2047C557.057 93.24 556.916 93.2754 556.774 93.2931L554.105 79.2915C554.246 79.265 554.388 79.2385 554.529 79.2031Z"
          fill="#CFD8DC"
        />
        <path
          d="M554.114 79.292L556.783 93.2935C556.633 93.3201 556.483 93.3377 556.324 93.3466L553.654 79.345C553.813 79.3362 553.964 79.3185 554.114 79.292Z"
          fill="#CFD8DC"
        />
        <path
          d="M553.654 79.3457L556.324 93.3472C556.138 93.3561 555.953 93.3561 555.767 93.3472L553.098 79.3457C553.283 79.3545 553.469 79.3545 553.654 79.3457Z"
          fill="#CFD8DC"
        />
        <path
          d="M553.089 79.3545L555.758 93.356C555.758 93.356 555.723 93.356 555.714 93.356L553.044 79.3545C553.044 79.3545 553.08 79.3545 553.089 79.3545Z"
          fill="#CFD8DC"
        />
        <path
          d="M570.483 7.48137C572.384 7.61396 573.63 8.94871 573.267 10.4514L557.154 76.8704C556.792 78.3819 554.954 79.4868 553.053 79.3542L465.418 73.1225C463.518 72.9899 461.679 74.0948 461.317 75.5975L458.895 85.5683C458.533 87.071 456.694 88.1847 454.785 88.0433L425.22 85.9396C423.32 85.807 422.073 84.4722 422.436 82.9695L428.623 57.4856C428.985 55.9741 430.824 54.8692 432.724 55.0018L444.78 55.8592C446.68 55.9918 448.519 54.8869 448.881 53.3842L461.229 2.48713C461.591 0.984441 463.429 -0.129318 465.339 0.0121114L570.483 7.49021V7.48137Z"
          fill={color01}
          id="hokkaido"
          className="hokkaido"
        />
        <path
          d="M446.23 96.6533L448.899 110.655L447.502 116.418L444.833 102.417L446.23 96.6533Z"
          fill="#90A4AE"
        />
        <path
          d="M444.833 102.416L447.502 116.418C447.494 116.471 447.476 116.524 447.467 116.577L444.798 102.575C444.807 102.522 444.816 102.469 444.833 102.416Z"
          fill="#90A4AE"
        />
        <path
          d="M444.798 102.575L447.467 116.577C447.44 116.718 447.431 116.851 447.44 116.983L444.771 102.982C444.771 102.849 444.771 102.708 444.798 102.575Z"
          fill="#CFD8DC"
        />
        <path
          d="M444.771 102.99L447.44 116.992C447.44 117.107 447.458 117.222 447.476 117.328L444.806 103.326C444.789 103.22 444.771 103.105 444.771 102.99Z"
          fill="#CFD8DC"
        />
        <path
          d="M465.179 99.8525L467.849 113.854C466.346 113.748 465.25 112.891 465.038 111.795L462.369 97.793C462.581 98.889 463.677 99.7465 465.179 99.8525Z"
          fill="#CFD8DC"
        />
        <path
          d="M497.838 92.958L500.508 106.96C500.525 107.066 500.543 107.181 500.543 107.295L497.874 93.2939C497.874 93.179 497.856 93.0641 497.838 92.958Z"
          fill="#CFD8DC"
        />
        <path
          d="M497.874 93.2939L500.543 107.296C500.543 107.428 500.543 107.57 500.516 107.702L497.847 93.7006C497.874 93.5591 497.882 93.4265 497.874 93.2939Z"
          fill="#CFD8DC"
        />
        <path
          d="M497.838 93.709L500.507 107.711C500.499 107.764 500.49 107.817 500.472 107.87L497.803 93.8681C497.812 93.8151 497.829 93.762 497.838 93.709Z"
          fill="#90A4AE"
        />
        <path
          d="M467.955 100.047L470.624 114.049L467.848 113.845L465.179 99.8525L467.955 100.047Z"
          fill="#CFD8DC"
        />
        <path
          d="M470.765 102.106L473.435 116.108C473.222 115.012 472.126 114.155 470.624 114.048L467.955 100.047C469.457 100.153 470.553 101.01 470.765 102.106Z"
          fill="#CFD8DC"
        />
        <path
          d="M364.684 140.001L367.353 154.003C367.371 154.109 367.388 154.223 367.388 154.338L364.719 140.337C364.719 140.222 364.701 140.107 364.684 140.001Z"
          fill="#CFD8DC"
        />
        <path
          d="M364.719 140.337L367.388 154.338C367.388 154.471 367.388 154.612 367.362 154.745L364.692 140.744C364.719 140.602 364.728 140.47 364.719 140.337Z"
          fill="#CFD8DC"
        />
        <path
          d="M364.693 140.744L367.362 154.746C367.353 154.799 367.344 154.852 367.326 154.905L364.657 140.903C364.666 140.85 364.684 140.797 364.693 140.744Z"
          fill="#90A4AE"
        />
        <path
          d="M364.657 140.902L367.327 154.904L366.876 156.751L364.207 142.759L364.657 140.902Z"
          fill="#90A4AE"
        />
        <path
          d="M364.207 142.759L366.877 156.76C366.868 156.813 366.85 156.866 366.841 156.919L364.172 142.918C364.181 142.865 364.19 142.812 364.207 142.759Z"
          fill="#90A4AE"
        />
        <path
          d="M364.18 142.918L366.849 156.92C366.823 157.061 366.814 157.194 366.823 157.326L364.153 143.325C364.153 143.192 364.153 143.051 364.18 142.918Z"
          fill="#CFD8DC"
        />
        <path
          d="M364.145 143.324L366.814 157.326C366.814 157.441 366.831 157.556 366.849 157.662L364.18 143.66C364.162 143.554 364.145 143.439 364.145 143.324Z"
          fill="#CFD8DC"
        />
        <path
          d="M380.028 148.514L382.697 162.515C382.715 162.621 382.732 162.736 382.732 162.851L380.063 148.85C380.063 148.735 380.046 148.62 380.028 148.514Z"
          fill="#CFD8DC"
        />
        <path
          d="M380.063 148.85L382.732 162.851C382.732 162.984 382.732 163.125 382.706 163.258L380.037 149.256C380.063 149.115 380.072 148.982 380.063 148.85Z"
          fill="#CFD8DC"
        />
        <path
          d="M380.028 149.265L382.697 163.266C382.688 163.319 382.679 163.372 382.661 163.425L379.992 149.424C380.001 149.371 380.019 149.318 380.028 149.265Z"
          fill="#90A4AE"
        />
        <path
          d="M351.929 158.484L354.599 172.486C354.599 172.486 354.563 172.486 354.555 172.486L351.885 158.484C351.885 158.484 351.921 158.484 351.929 158.484Z"
          fill="#CFD8DC"
        />
        <path
          d="M351.885 158.484L354.554 172.486C354.369 172.477 354.174 172.477 353.989 172.486L351.319 158.484C351.505 158.475 351.691 158.475 351.885 158.484Z"
          fill="#CFD8DC"
        />
        <path
          d="M351.32 158.484L353.989 172.486C353.83 172.495 353.68 172.512 353.53 172.539L350.86 158.537C351.011 158.511 351.17 158.493 351.32 158.484Z"
          fill="#CFD8DC"
        />
        <path
          d="M350.86 158.537L353.529 172.539C353.388 172.565 353.246 172.592 353.105 172.627L350.436 158.626C350.577 158.59 350.718 158.555 350.86 158.537Z"
          fill="#CFD8DC"
        />
        <path
          d="M350.436 158.635L353.105 172.636C352.964 172.672 352.831 172.716 352.699 172.769L350.029 158.767C350.162 158.714 350.294 158.679 350.436 158.635Z"
          fill="#CFD8DC"
        />
        <path
          d="M350.029 158.758L352.698 172.759C352.557 172.812 352.416 172.874 352.283 172.936L349.614 158.935C349.746 158.873 349.888 158.811 350.029 158.758Z"
          fill="#CFD8DC"
        />
        <path
          d="M349.614 158.943L352.283 172.945C352.124 173.024 351.974 173.104 351.832 173.201L349.163 159.2C349.305 159.111 349.455 159.023 349.614 158.943Z"
          fill="#CFD8DC"
        />
        <path
          d="M349.163 159.191L351.832 173.193C351.62 173.334 351.426 173.485 351.249 173.661L348.58 159.66C348.756 159.492 348.951 159.333 349.163 159.191Z"
          fill="#90A4AE"
        />
        <path
          d="M348.589 159.66L351.258 173.662C351.002 173.918 350.79 174.21 350.648 174.519L347.979 160.518C348.12 160.199 348.333 159.916 348.589 159.66Z"
          fill="#90A4AE"
        />
        <path
          d="M347.97 160.526L350.639 174.528C350.568 174.669 350.524 174.82 350.48 174.979L347.811 160.977C347.846 160.827 347.899 160.677 347.97 160.526Z"
          fill="#90A4AE"
        />
        <path
          d="M379.993 149.424L382.662 163.425L380.682 171.575L378.021 157.574L379.993 149.424Z"
          fill="#90A4AE"
        />
        <path
          d="M373.903 160.049L376.572 174.05L354.599 172.486L351.93 158.484L373.903 160.049Z"
          fill="#CFD8DC"
        />
        <path
          d="M378.021 157.574L380.691 171.576C380.655 171.726 380.602 171.876 380.532 172.018L377.862 158.016C377.924 157.875 377.977 157.724 378.021 157.574Z"
          fill="#90A4AE"
        />
        <path
          d="M377.862 158.016L380.532 172.017C380.39 172.327 380.178 172.618 379.922 172.875L377.252 158.873C377.509 158.617 377.721 158.325 377.862 158.016Z"
          fill="#90A4AE"
        />
        <path
          d="M377.252 158.882L379.922 172.883C379.754 173.051 379.55 173.21 379.338 173.352L376.669 159.35C376.881 159.209 377.076 159.059 377.252 158.882Z"
          fill="#90A4AE"
        />
        <path
          d="M376.669 159.342L379.338 173.343C379.197 173.441 379.047 173.52 378.888 173.6L376.218 159.598C376.377 159.519 376.528 159.439 376.669 159.342Z"
          fill="#CFD8DC"
        />
        <path
          d="M376.227 159.598L378.896 173.599C378.764 173.661 378.622 173.723 378.481 173.776L375.812 159.774C375.953 159.721 376.094 159.66 376.227 159.598Z"
          fill="#CFD8DC"
        />
        <path
          d="M375.812 159.774L378.481 173.776C378.349 173.829 378.216 173.864 378.075 173.909L375.405 159.907C375.547 159.872 375.679 159.827 375.812 159.774Z"
          fill="#CFD8DC"
        />
        <path
          d="M375.405 159.907L378.074 173.909C377.933 173.944 377.792 173.979 377.65 173.997L374.981 159.996C375.122 159.969 375.264 159.943 375.405 159.907Z"
          fill="#CFD8DC"
        />
        <path
          d="M374.981 159.996L377.65 173.998C377.5 174.024 377.341 174.042 377.191 174.051L374.521 160.049C374.681 160.04 374.831 160.023 374.981 159.996Z"
          fill="#CFD8DC"
        />
        <path
          d="M374.512 160.058L377.182 174.059C376.996 174.068 376.811 174.068 376.616 174.059L373.947 160.058C374.132 160.066 374.327 160.066 374.512 160.058Z"
          fill="#CFD8DC"
        />
        <path
          d="M373.955 160.058L376.625 174.059C376.625 174.059 376.589 174.059 376.58 174.059L373.911 160.058C373.911 160.058 373.946 160.058 373.955 160.058Z"
          fill="#CFD8DC"
        />
        <path
          d="M385.11 160.854L387.779 174.855C386.268 174.749 385.172 173.892 384.96 172.795L382.291 158.794C382.503 159.89 383.599 160.747 385.11 160.854Z"
          fill="#CFD8DC"
        />
        <path
          d="M399.747 161.888L402.416 175.889L387.779 174.846L385.11 160.854L399.747 161.888Z"
          fill="#CFD8DC"
        />
        <path
          d="M316.867 178.576L319.536 192.578C319.554 192.684 319.572 192.799 319.572 192.914L316.903 178.912C316.903 178.797 316.885 178.682 316.867 178.576Z"
          fill="#CFD8DC"
        />
        <path
          d="M316.902 178.912L319.571 192.914C319.571 193.046 319.571 193.188 319.545 193.32L316.875 179.319C316.902 179.177 316.911 179.045 316.902 178.912Z"
          fill="#CFD8DC"
        />
        <path
          d="M316.875 179.318L319.544 193.32C319.536 193.373 319.527 193.426 319.509 193.479L316.84 179.477C316.849 179.424 316.866 179.371 316.875 179.318Z"
          fill="#90A4AE"
        />
        <path
          d="M402.567 163.956L405.236 177.958C405.024 176.862 403.928 176.004 402.416 175.898L399.747 161.896C401.258 162.003 402.354 162.86 402.567 163.956Z"
          fill="#CFD8DC"
        />
        <path
          d="M397.387 167.05L400.056 181.051C400.074 181.157 400.092 181.272 400.092 181.387L397.423 167.386C397.423 167.271 397.405 167.156 397.387 167.05Z"
          fill="#CFD8DC"
        />
        <path
          d="M397.422 167.386L400.091 181.387C400.091 181.52 400.091 181.661 400.065 181.794L397.396 167.792C397.422 167.651 397.431 167.518 397.422 167.386Z"
          fill="#CFD8DC"
        />
        <path
          d="M397.396 167.792L400.065 181.794C400.056 181.847 400.047 181.9 400.03 181.953L397.36 167.951C397.369 167.898 397.387 167.845 397.396 167.792Z"
          fill="#90A4AE"
        />
        <path
          d="M404.095 172.707L406.765 186.709C405.262 186.602 404.166 185.745 403.954 184.649L401.285 170.647C401.497 171.744 402.593 172.601 404.095 172.707Z"
          fill="#CFD8DC"
        />
        <path
          d="M316.84 179.478L319.509 193.479L316.849 204.431L314.18 190.43L316.84 179.478Z"
          fill="#90A4AE"
        />
        <path
          d="M314.18 190.43L316.849 204.431L316.681 205.112L314.021 191.11L314.18 190.43Z"
          fill="#90A4AE"
        />
        <path
          d="M314.021 191.11L316.69 205.112C316.681 205.165 316.663 205.218 316.655 205.271L313.985 191.269C313.994 191.216 314.003 191.163 314.021 191.11Z"
          fill="#90A4AE"
        />
        <path
          d="M313.985 191.278L316.655 205.28C316.628 205.421 316.619 205.554 316.628 205.686L313.959 191.685C313.959 191.552 313.959 191.411 313.985 191.278Z"
          fill="#CFD8DC"
        />
        <path
          d="M313.959 191.685L316.628 205.686C316.628 205.801 316.646 205.916 316.664 206.022L313.994 192.02C313.977 191.914 313.959 191.799 313.959 191.685Z"
          fill="#CFD8DC"
        />
        <path
          d="M321.94 190.986L324.609 204.988C323.107 204.882 322.011 204.024 321.799 202.928L319.129 188.927C319.342 190.023 320.438 190.88 321.94 190.986Z"
          fill="#CFD8DC"
        />
        <path
          d="M347.811 160.968L350.48 174.969L343.559 203.556L340.899 189.554L347.811 160.968Z"
          fill="#90A4AE"
        />
        <path
          d="M336.789 192.038L339.458 206.04L324.609 204.988L321.94 190.986L336.789 192.038Z"
          fill="#CFD8DC"
        />
        <path
          d="M340.899 189.555L343.569 203.556C343.533 203.707 343.48 203.857 343.409 204.007L340.74 190.006C340.811 189.864 340.855 189.714 340.899 189.555Z"
          fill="#90A4AE"
        />
        <path
          d="M340.74 190.006L343.409 204.007C343.268 204.326 343.055 204.608 342.799 204.874L340.13 190.872C340.386 190.616 340.598 190.324 340.74 190.006Z"
          fill="#90A4AE"
        />
        <path
          d="M340.121 190.871L342.791 204.873C342.614 205.041 342.42 205.2 342.207 205.341L339.538 191.34C339.75 191.198 339.945 191.048 340.121 190.871Z"
          fill="#90A4AE"
        />
        <path
          d="M339.546 191.331L342.216 205.333C342.074 205.43 341.924 205.509 341.765 205.589L339.096 191.587C339.255 191.508 339.405 191.428 339.546 191.331Z"
          fill="#CFD8DC"
        />
        <path
          d="M339.096 191.588L341.765 205.589C341.633 205.651 341.491 205.713 341.35 205.766L338.681 191.765C338.822 191.712 338.964 191.65 339.096 191.588Z"
          fill="#CFD8DC"
        />
        <path
          d="M338.68 191.764L341.35 205.765C341.217 205.818 341.085 205.854 340.943 205.898L338.274 191.896C338.415 191.861 338.548 191.817 338.68 191.764Z"
          fill="#CFD8DC"
        />
        <path
          d="M338.274 191.896L340.943 205.898C340.802 205.933 340.66 205.969 340.519 205.986L337.85 191.985C337.991 191.958 338.132 191.932 338.274 191.896Z"
          fill="#CFD8DC"
        />
        <path
          d="M337.859 191.985L340.528 205.987C340.378 206.013 340.227 206.031 340.068 206.04L337.399 192.038C337.558 192.03 337.708 192.012 337.859 191.985Z"
          fill="#CFD8DC"
        />
        <path
          d="M337.399 192.038L340.068 206.04C339.882 206.048 339.697 206.048 339.511 206.04L336.842 192.038C337.027 192.047 337.213 192.047 337.399 192.038Z"
          fill="#CFD8DC"
        />
        <path
          d="M336.833 192.047L339.503 206.048C339.503 206.048 339.467 206.048 339.458 206.048L336.789 192.047C336.789 192.047 336.824 192.047 336.833 192.047Z"
          fill="#CFD8DC"
        />
        <path
          d="M361.873 137.933C363.773 138.065 365.02 139.4 364.657 140.903L364.207 142.75C363.844 144.262 365.09 145.588 367 145.72L377.208 146.445C379.117 146.578 380.364 147.912 380.001 149.415L378.021 157.565C377.659 159.068 375.821 160.182 373.911 160.04L351.93 158.476C350.02 158.343 348.182 159.448 347.82 160.959L340.899 189.546C340.537 191.057 338.698 192.162 336.798 192.03L321.949 190.969C320.049 190.836 318.802 189.502 319.165 187.999L321.162 179.77C321.525 178.267 323.363 177.153 325.263 177.295L326.174 177.356C328.083 177.489 329.921 176.384 330.284 174.881L338.972 139.064C339.335 137.562 341.173 136.448 343.073 136.589L361.882 137.924L361.873 137.933Z"
          fill={color05}
          id="hokuriku"
          className="hokuriku"
        />
        <path
          d="M322.1 196.316L324.769 210.318C324.787 210.424 324.804 210.539 324.804 210.654L322.135 196.652C322.135 196.537 322.117 196.422 322.1 196.316Z"
          fill="#CFD8DC"
        />
        <path
          d="M322.135 196.652L324.804 210.654C324.804 210.787 324.804 210.928 324.778 211.061L322.108 197.059C322.135 196.918 322.144 196.785 322.135 196.652Z"
          fill="#CFD8DC"
        />
        <path
          d="M322.108 197.068L324.777 211.07C324.769 211.123 324.76 211.176 324.742 211.229L322.073 197.227C322.082 197.174 322.099 197.121 322.108 197.068Z"
          fill="#90A4AE"
        />
        <path
          d="M497.812 93.8682L500.472 107.87L480.744 189.192L478.075 175.199L497.812 93.8682Z"
          fill="#90A4AE"
        />
        <path
          d="M473.965 177.684L476.634 191.676L406.764 186.709L404.095 172.707L473.965 177.684Z"
          fill="#CFD8DC"
        />
        <path
          d="M478.075 175.199L480.744 189.201C480.709 189.351 480.656 189.501 480.585 189.652L477.916 175.65C477.987 175.509 478.031 175.358 478.075 175.199Z"
          fill="#90A4AE"
        />
        <path
          d="M477.925 175.642L480.594 189.643C480.453 189.961 480.241 190.244 479.984 190.501L477.315 176.499C477.571 176.243 477.783 175.951 477.925 175.642Z"
          fill="#90A4AE"
        />
        <path
          d="M477.306 176.508L479.975 190.509C479.799 190.677 479.604 190.836 479.392 190.978L476.723 176.976C476.935 176.835 477.129 176.685 477.306 176.508Z"
          fill="#90A4AE"
        />
        <path
          d="M476.732 176.977L479.401 190.978C479.259 191.067 479.109 191.155 478.95 191.234L476.281 177.233C476.44 177.153 476.59 177.074 476.732 176.977Z"
          fill="#CFD8DC"
        />
        <path
          d="M476.281 177.224L478.95 191.225C478.817 191.287 478.676 191.349 478.534 191.402L475.865 177.4C476.007 177.347 476.148 177.286 476.281 177.224Z"
          fill="#CFD8DC"
        />
        <path
          d="M475.866 177.409L478.535 191.411C478.402 191.464 478.27 191.499 478.128 191.543L475.459 177.542C475.6 177.506 475.733 177.462 475.866 177.409Z"
          fill="#CFD8DC"
        />
        <path
          d="M475.458 177.533L478.128 191.535C477.986 191.57 477.845 191.605 477.703 191.623L475.034 177.622C475.176 177.595 475.317 177.569 475.458 177.533Z"
          fill="#CFD8DC"
        />
        <path
          d="M475.034 177.63L477.704 191.631C477.553 191.658 477.394 191.676 477.244 191.684L474.575 177.683C474.734 177.674 474.884 177.656 475.034 177.63Z"
          fill="#CFD8DC"
        />
        <path
          d="M474.575 177.684L477.244 191.685C477.059 191.694 476.873 191.694 476.679 191.685L474.009 177.684C474.195 177.692 474.389 177.692 474.575 177.684Z"
          fill="#CFD8DC"
        />
        <path
          d="M474.009 177.684L476.678 191.685C476.678 191.685 476.643 191.685 476.634 191.685L473.965 177.684C473.965 177.684 474 177.684 474.009 177.684Z"
          fill="#CFD8DC"
        />
        <path
          d="M495.018 90.8987C496.927 91.0313 498.174 92.366 497.811 93.8687L478.083 175.191C477.721 176.702 475.883 177.807 473.973 177.675L404.104 172.707C402.204 172.574 400.957 171.24 401.32 169.737L402.336 165.547L402.539 164.866C402.902 163.355 401.656 162.029 399.746 161.896L385.11 160.853C383.2 160.721 381.954 159.386 382.317 157.883L384.296 149.734C384.659 148.231 386.497 147.117 388.406 147.258L403.061 148.302C404.97 148.434 406.808 147.329 407.171 145.818L419.554 94.7615C419.916 93.2588 421.755 92.1451 423.655 92.2865L443.427 93.692C445.327 93.8245 446.574 95.1593 446.211 96.662L444.815 102.425C444.452 103.928 445.699 105.254 447.599 105.395L451.196 105.652L466.319 106.73C468.22 106.863 470.058 105.758 470.42 104.255L470.721 103.026C471.083 101.515 469.837 100.189 467.937 100.056L465.161 99.8618C463.261 99.7292 462.015 98.3945 462.377 96.8918L463.685 91.4556C464.048 89.9441 465.886 88.8391 467.795 88.9717L494.992 90.9075L495.018 90.8987Z"
          fill={color02}
          id="tohoku"
          className="tohoku"
        />
        <path
          d="M258.443 230.03L261.112 244.032C259.61 243.926 258.514 243.068 258.302 241.972L255.632 227.971C255.844 229.067 256.94 229.924 258.443 230.03Z"
          fill="#CFD8DC"
        />
        <path
          d="M271.1 230.932L273.761 244.933L261.104 244.032L258.443 230.03L271.1 230.932Z"
          fill="#CFD8DC"
        />
        <path
          d="M273.902 232.991L276.571 246.993C276.359 245.897 275.263 245.039 273.761 244.933L271.091 230.932C272.594 231.038 273.69 231.895 273.902 232.991Z"
          fill="#CFD8DC"
        />
        <path
          d="M342.198 230.083L344.867 244.084L343.312 243.978L340.643 229.977L342.198 230.083Z"
          fill="#CFD8DC"
        />
        <path
          d="M340.643 229.977L343.312 243.978C343.312 243.978 343.277 243.978 343.268 243.978L340.599 229.977C340.599 229.977 340.634 229.977 340.643 229.977Z"
          fill="#CFD8DC"
        />
        <path
          d="M340.598 229.977L343.268 243.979C343.082 243.97 342.896 243.97 342.711 243.979L340.042 229.977C340.227 229.968 340.413 229.968 340.598 229.977Z"
          fill="#CFD8DC"
        />
        <path
          d="M340.033 229.977L342.702 243.978C342.543 243.987 342.393 244.005 342.243 244.031L339.573 230.03C339.723 230.003 339.874 229.985 340.033 229.977Z"
          fill="#CFD8DC"
        />
        <path
          d="M339.573 230.03L342.242 244.032C342.101 244.058 341.96 244.085 341.818 244.12L339.149 230.119C339.29 230.083 339.432 230.048 339.573 230.03Z"
          fill="#CFD8DC"
        />
        <path
          d="M339.158 230.118L341.827 244.12C341.685 244.155 341.553 244.199 341.42 244.252L338.751 230.251C338.884 230.198 339.016 230.162 339.158 230.118Z"
          fill="#CFD8DC"
        />
        <path
          d="M338.751 230.251L341.421 244.253C341.279 244.306 341.138 244.367 341.005 244.429L338.336 230.428C338.469 230.366 338.61 230.304 338.751 230.251Z"
          fill="#CFD8DC"
        />
        <path
          d="M338.336 230.428L341.005 244.429C340.846 244.509 340.695 244.588 340.554 244.686L337.885 230.684C338.026 230.587 338.176 230.507 338.336 230.428Z"
          fill="#CFD8DC"
        />
        <path
          d="M337.885 230.684L340.554 244.685C340.342 244.827 340.148 244.977 339.971 245.154L337.302 231.152C337.479 230.984 337.673 230.825 337.885 230.684Z"
          fill="#90A4AE"
        />
        <path
          d="M337.311 231.152L339.98 245.154C339.724 245.41 339.512 245.702 339.37 246.02L336.701 232.019C336.843 231.7 337.055 231.418 337.311 231.152Z"
          fill="#90A4AE"
        />
        <path
          d="M336.692 232.01L339.361 246.011C339.29 246.153 339.246 246.303 339.202 246.462L336.533 232.461C336.568 232.31 336.621 232.16 336.692 232.01Z"
          fill="#90A4AE"
        />
        <path
          d="M345.018 232.151L347.687 246.153C347.475 245.057 346.379 244.199 344.868 244.093L342.198 230.092C343.71 230.198 344.806 231.055 345.018 232.151Z"
          fill="#CFD8DC"
        />
        <path
          d="M336.541 232.461L339.202 246.462L337.611 253.03L334.941 239.029L336.541 232.461Z"
          fill="#90A4AE"
        />
        <path
          d="M272.594 253.675L275.263 267.676C273.752 267.57 272.656 266.713 272.444 265.617L269.774 251.615C269.987 252.711 271.083 253.569 272.594 253.675Z"
          fill="#CFD8DC"
        />
        <path
          d="M346.839 239.877L349.508 253.879C348.005 253.772 346.909 252.915 346.697 251.819L344.028 237.817C344.24 238.913 345.336 239.771 346.839 239.877Z"
          fill="#CFD8DC"
        />
        <path
          d="M397.36 167.96L400.029 181.953L382.679 253.463L380.01 239.462L397.36 167.96Z"
          fill="#90A4AE"
        />
        <path
          d="M322.073 197.228L324.742 211.229L311.104 267.447L308.435 253.446L322.073 197.228Z"
          fill="#90A4AE"
        />
        <path
          d="M375.909 241.945L378.57 255.947L349.508 253.878L346.839 239.877L375.909 241.945Z"
          fill="#CFD8DC"
        />
        <path
          d="M304.316 255.93L306.985 269.931L275.263 267.669L272.594 253.676L304.316 255.93Z"
          fill="#CFD8DC"
        />
        <path
          d="M380.01 239.462L382.679 253.463C382.644 253.614 382.591 253.764 382.52 253.914L379.851 239.913C379.922 239.771 379.966 239.621 380.01 239.462Z"
          fill="#90A4AE"
        />
        <path
          d="M379.851 239.912L382.52 253.914C382.379 254.232 382.167 254.515 381.91 254.78L379.241 240.778C379.498 240.522 379.71 240.23 379.851 239.912Z"
          fill="#90A4AE"
        />
        <path
          d="M379.241 240.77L381.91 254.771C381.734 254.939 381.539 255.098 381.327 255.24L378.658 241.238C378.87 241.097 379.064 240.946 379.241 240.77Z"
          fill="#90A4AE"
        />
        <path
          d="M378.658 241.238L381.327 255.24C381.186 255.337 381.035 255.417 380.876 255.496L378.207 241.495C378.366 241.415 378.516 241.336 378.658 241.238Z"
          fill="#CFD8DC"
        />
        <path
          d="M378.216 241.494L380.885 255.496C380.753 255.558 380.611 255.619 380.47 255.672L377.801 241.671C377.942 241.618 378.084 241.556 378.216 241.494Z"
          fill="#CFD8DC"
        />
        <path
          d="M377.801 241.672L380.47 255.673C380.337 255.726 380.205 255.762 380.063 255.806L377.394 241.804C377.535 241.769 377.668 241.725 377.801 241.672Z"
          fill="#CFD8DC"
        />
        <path
          d="M377.394 241.804L380.063 255.805C379.922 255.841 379.78 255.876 379.639 255.894L376.97 241.892C377.111 241.866 377.253 241.839 377.394 241.804Z"
          fill="#CFD8DC"
        />
        <path
          d="M376.969 241.893L379.639 255.894C379.488 255.921 379.338 255.938 379.179 255.947L376.51 241.946C376.669 241.937 376.819 241.919 376.969 241.893Z"
          fill="#CFD8DC"
        />
        <path
          d="M376.51 241.945L379.179 255.947C378.994 255.956 378.808 255.956 378.622 255.947L375.953 241.945C376.139 241.954 376.324 241.954 376.51 241.945Z"
          fill="#CFD8DC"
        />
        <path
          d="M375.953 241.945L378.623 255.947C378.623 255.947 378.587 255.947 378.578 255.947L375.909 241.945C375.909 241.945 375.945 241.945 375.953 241.945Z"
          fill="#CFD8DC"
        />
        <path
          d="M308.434 253.445L311.104 267.447C311.068 267.597 311.015 267.747 310.945 267.898L308.275 253.896C308.346 253.755 308.39 253.604 308.434 253.445Z"
          fill="#90A4AE"
        />
        <path
          d="M308.275 253.896L310.945 267.898C310.803 268.216 310.591 268.499 310.335 268.755L307.666 254.754C307.922 254.498 308.134 254.206 308.275 253.896Z"
          fill="#90A4AE"
        />
        <path
          d="M307.665 254.754L310.335 268.755C310.158 268.923 309.963 269.083 309.751 269.224L307.082 255.222C307.294 255.081 307.489 254.931 307.665 254.754Z"
          fill="#90A4AE"
        />
        <path
          d="M307.082 255.223L309.751 269.224C309.61 269.313 309.46 269.401 309.301 269.481L306.631 255.479C306.79 255.399 306.941 255.32 307.082 255.223Z"
          fill="#CFD8DC"
        />
        <path
          d="M306.64 255.479L309.309 269.48C309.177 269.542 309.035 269.604 308.894 269.657L306.225 255.655C306.366 255.602 306.507 255.54 306.64 255.479Z"
          fill="#CFD8DC"
        />
        <path
          d="M306.225 255.655L308.894 269.657C308.762 269.71 308.629 269.745 308.488 269.789L305.818 255.788C305.96 255.753 306.092 255.708 306.225 255.655Z"
          fill="#CFD8DC"
        />
        <path
          d="M305.818 255.787L308.487 269.789C308.346 269.824 308.204 269.859 308.063 269.877L305.394 255.875C305.535 255.849 305.676 255.822 305.818 255.787Z"
          fill="#CFD8DC"
        />
        <path
          d="M305.394 255.876L308.063 269.878C307.913 269.904 307.754 269.922 307.604 269.931L304.935 255.929C305.094 255.92 305.244 255.902 305.394 255.876Z"
          fill="#CFD8DC"
        />
        <path
          d="M304.926 255.93L307.595 269.931C307.409 269.94 307.224 269.94 307.029 269.931L304.36 255.93C304.545 255.939 304.74 255.939 304.926 255.93Z"
          fill="#CFD8DC"
        />
        <path
          d="M304.368 255.93L307.038 269.931C307.038 269.931 307.002 269.931 306.993 269.931L304.324 255.93C304.324 255.93 304.36 255.93 304.368 255.93Z"
          fill="#CFD8DC"
        />
        <path
          d="M314.056 176.508C315.956 176.64 317.202 177.975 316.84 179.478L314.18 190.43L314.012 191.11C313.649 192.613 314.896 193.939 316.796 194.08L317.662 194.142L319.28 194.257C321.189 194.39 322.435 195.724 322.073 197.227L308.435 253.445C308.072 254.957 306.234 256.062 304.325 255.929L272.594 253.675C270.685 253.543 269.438 252.208 269.801 250.705L273.875 233.91C274.238 232.408 272.992 231.082 271.091 230.94L258.434 230.039C256.534 229.906 255.288 228.571 255.65 227.069L268.033 176.013C268.395 174.51 270.234 173.396 272.134 173.538L314.047 176.516L314.056 176.508Z"
          fill={color06}
          id="kansai"
          className="kansai"
        />
        <path
          d="M315.523 256.726L318.192 270.727C316.681 270.621 315.585 269.764 315.373 268.668L312.704 254.666C312.916 255.762 314.012 256.62 315.523 256.726Z"
          fill="#CFD8DC"
        />
        <path
          d="M334.942 239.028L337.611 253.03L333.793 269.047L331.124 255.045L334.942 239.028Z"
          fill="#90A4AE"
        />
        <path
          d="M327.023 257.547L329.683 271.539L318.193 270.726L315.523 256.725L327.023 257.547Z"
          fill="#CFD8DC"
        />
        <path
          d="M394.576 164.99C396.476 165.123 397.723 166.457 397.36 167.96L380.01 239.462C379.648 240.973 377.809 242.078 375.909 241.945L346.838 239.877C344.938 239.744 343.692 238.41 344.054 236.907L344.991 233.053C345.354 231.542 344.107 230.216 342.198 230.083L340.643 229.968C338.742 229.836 336.904 230.94 336.541 232.452L334.95 239.02L331.132 255.037C330.779 256.548 328.931 257.671 327.022 257.529L315.532 256.707C313.623 256.575 312.377 255.24 312.739 253.737L326.377 197.519C326.739 196.007 328.578 194.902 330.487 195.035L339.405 195.671L340.263 195.733C342.172 195.866 344.01 194.761 344.372 193.258L351.311 164.663C351.673 163.16 353.512 162.046 355.412 162.188L376.519 163.691L380.823 164L394.567 164.981L394.576 164.99Z"
          fill={color04}
          id="chubu"
          className="chubu"
        />
        <path
          d="M331.123 255.045L333.793 269.046C333.757 269.206 333.704 269.365 333.634 269.515L330.964 255.513C331.035 255.363 331.088 255.204 331.123 255.045Z"
          fill="#90A4AE"
        />
        <path
          d="M330.964 255.514L333.634 269.515C333.492 269.825 333.28 270.116 333.024 270.364L330.354 256.362C330.611 256.106 330.823 255.823 330.964 255.514Z"
          fill="#90A4AE"
        />
        <path
          d="M330.355 256.371L333.024 270.373C332.847 270.541 332.653 270.7 332.441 270.841L329.771 256.84C329.984 256.698 330.178 256.548 330.355 256.371Z"
          fill="#90A4AE"
        />
        <path
          d="M329.78 256.84L332.449 270.841C332.308 270.939 332.157 271.018 331.998 271.098L329.329 257.096C329.488 257.017 329.638 256.937 329.78 256.84Z"
          fill="#CFD8DC"
        />
        <path
          d="M329.329 257.096L331.999 271.097C331.866 271.159 331.725 271.221 331.583 271.274L328.914 257.273C329.055 257.219 329.197 257.158 329.329 257.096Z"
          fill="#CFD8DC"
        />
        <path
          d="M328.913 257.273L331.583 271.275C331.45 271.328 331.318 271.363 331.176 271.408L328.507 257.406C328.648 257.371 328.781 257.326 328.913 257.273Z"
          fill="#CFD8DC"
        />
        <path
          d="M328.507 257.396L331.177 271.398C331.035 271.433 330.894 271.469 330.752 271.486L328.083 257.485C328.224 257.458 328.366 257.432 328.507 257.396Z"
          fill="#CFD8DC"
        />
        <path
          d="M328.083 257.494L330.752 271.496C330.602 271.522 330.443 271.54 330.292 271.549L327.623 257.547C327.782 257.538 327.932 257.521 328.083 257.494Z"
          fill="#CFD8DC"
        />
        <path
          d="M327.623 257.547L330.293 271.548C330.107 271.557 329.921 271.557 329.736 271.548L327.066 257.547C327.252 257.556 327.438 257.556 327.623 257.547Z"
          fill="#CFD8DC"
        />
        <path
          d="M327.067 257.547L329.736 271.548C329.736 271.548 329.701 271.548 329.692 271.548L327.022 257.547C327.022 257.547 327.058 257.547 327.067 257.547Z"
          fill="#CFD8DC"
        />
        <path
          d="M2.84683 299.675L5.5161 313.676C4.01353 313.57 2.91754 312.713 2.70541 311.617L0.0361328 297.615C0.248261 298.711 1.34425 299.569 2.84683 299.675Z"
          fill="#CFD8DC"
        />
        <path
          d="M59.0698 289.006L61.7391 303.007C61.7568 303.113 61.7745 303.228 61.7745 303.343L59.1052 289.342C59.1052 289.227 59.0875 289.112 59.0698 289.006Z"
          fill="#CFD8DC"
        />
        <path
          d="M59.1046 289.351L61.7739 303.352C61.7739 303.485 61.7739 303.626 61.7474 303.759L59.0781 289.757C59.1046 289.616 59.1135 289.483 59.1046 289.351Z"
          fill="#CFD8DC"
        />
        <path
          d="M59.0783 289.758L61.7476 303.759C61.7388 303.812 61.7299 303.865 61.7122 303.918L59.043 289.917C59.0518 289.864 59.0695 289.811 59.0783 289.758Z"
          fill="#90A4AE"
        />
        <path
          d="M59.0431 289.917L61.7123 303.919L59.0872 314.72L56.418 300.719L59.0431 289.917Z"
          fill="#90A4AE"
        />
        <path
          d="M52.3078 303.202L54.9771 317.194L5.51595 313.676L2.84668 299.675L52.3078 303.202Z"
          fill="#CFD8DC"
        />
        <path
          d="M56.25 286.946C58.1592 287.079 59.4054 288.413 59.043 289.916L56.4179 300.718C56.0556 302.22 54.2171 303.334 52.308 303.193L2.84684 299.675C0.94653 299.542 -0.29972 298.207 0.0626646 296.705L2.68775 285.903C3.05013 284.391 4.88857 283.286 6.78888 283.419L56.25 286.937V286.946Z"
          fill={color10}
          id="okinawa"
          className="okinawa"
        />
        <path
          d="M56.4179 300.719L59.0872 314.72C59.0518 314.871 58.9988 315.021 58.9281 315.162L56.2588 301.161C56.3207 301.019 56.3737 300.869 56.4179 300.719Z"
          fill="#90A4AE"
        />
        <path
          d="M56.2681 301.169L58.9373 315.17C58.7959 315.48 58.5838 315.772 58.3275 316.028L55.6582 302.026C55.9145 301.77 56.1267 301.478 56.2681 301.169Z"
          fill="#90A4AE"
        />
        <path
          d="M55.6576 302.026L58.3268 316.028C58.1589 316.196 57.9556 316.355 57.7435 316.496L55.0742 302.495C55.2863 302.353 55.4808 302.203 55.6576 302.026Z"
          fill="#90A4AE"
        />
        <path
          d="M55.0748 302.495L57.7441 316.497C57.6027 316.594 57.4524 316.673 57.2933 316.753L54.624 302.751C54.7831 302.672 54.9334 302.592 55.0748 302.495Z"
          fill="#CFD8DC"
        />
        <path
          d="M54.6327 302.742L57.302 316.744C57.1694 316.806 57.028 316.867 56.8866 316.921L54.2173 302.919C54.3587 302.866 54.5001 302.804 54.6327 302.742Z"
          fill="#CFD8DC"
        />
        <path
          d="M54.2171 302.928L56.8864 316.929C56.7538 316.982 56.6212 317.018 56.4798 317.062L53.8105 303.06C53.952 303.025 54.0845 302.981 54.2171 302.928Z"
          fill="#CFD8DC"
        />
        <path
          d="M53.8022 303.052L56.4715 317.053C56.33 317.089 56.1886 317.124 56.0472 317.142L53.3779 303.14C53.5193 303.114 53.6608 303.087 53.8022 303.052Z"
          fill="#CFD8DC"
        />
        <path
          d="M53.3864 303.149L56.0556 317.151C55.9054 317.177 55.7463 317.195 55.596 317.204L52.9268 303.202C53.0859 303.194 53.2361 303.176 53.3864 303.149Z"
          fill="#CFD8DC"
        />
        <path
          d="M52.9182 303.202L55.5875 317.204C55.4019 317.213 55.2163 317.213 55.0218 317.204L52.3525 303.202C52.5382 303.211 52.7326 303.211 52.9182 303.202Z"
          fill="#CFD8DC"
        />
        <path
          d="M52.3611 303.202L55.0304 317.204C55.0304 317.204 54.995 317.204 54.9862 317.204L52.3169 303.202C52.3169 303.202 52.3523 303.202 52.3611 303.202Z"
          fill="#CFD8DC"
        />
        <path
          d="M475.954 183.146L478.623 197.148C478.641 197.254 478.659 197.369 478.659 197.484L475.989 183.482C475.989 183.367 475.972 183.253 475.954 183.146Z"
          fill="#CFD8DC"
        />
        <path
          d="M475.989 183.482L478.658 197.484C478.658 197.617 478.658 197.758 478.632 197.891L475.962 183.889C475.989 183.748 475.998 183.615 475.989 183.482Z"
          fill="#CFD8DC"
        />
        <path
          d="M475.963 183.889L478.632 197.89C478.623 197.943 478.614 197.996 478.596 198.049L475.927 184.048C475.936 183.995 475.954 183.942 475.963 183.889Z"
          fill="#90A4AE"
        />
        <path
          d="M427.703 227.219L430.373 241.22C430.373 241.22 430.337 241.22 430.328 241.22L427.659 227.219C427.659 227.219 427.695 227.219 427.703 227.219Z"
          fill="#CFD8DC"
        />
        <path
          d="M427.659 227.22L430.329 241.221C430.143 241.212 429.949 241.212 429.763 241.221L427.094 227.22C427.279 227.211 427.465 227.211 427.659 227.22Z"
          fill="#CFD8DC"
        />
        <path
          d="M427.093 227.219L429.763 241.22C429.604 241.229 429.453 241.247 429.303 241.273L426.634 227.272C426.784 227.245 426.943 227.228 427.093 227.219Z"
          fill="#CFD8DC"
        />
        <path
          d="M426.634 227.272L429.303 241.274C429.162 241.301 429.02 241.327 428.879 241.362L426.209 227.361C426.351 227.326 426.492 227.29 426.634 227.272Z"
          fill="#CFD8DC"
        />
        <path
          d="M426.21 227.369L428.879 241.371C428.738 241.406 428.605 241.45 428.472 241.503L425.803 227.502C425.936 227.449 426.068 227.413 426.21 227.369Z"
          fill="#CFD8DC"
        />
        <path
          d="M425.803 227.493L428.472 241.495C428.331 241.548 428.19 241.61 428.057 241.672L425.388 227.67C425.52 227.608 425.662 227.546 425.803 227.493Z"
          fill="#CFD8DC"
        />
        <path
          d="M425.388 227.67L428.057 241.671C427.898 241.751 427.748 241.831 427.606 241.928L424.937 227.926C425.078 227.829 425.229 227.749 425.388 227.67Z"
          fill="#CFD8DC"
        />
        <path
          d="M424.937 227.926L427.606 241.927C427.394 242.069 427.2 242.219 427.023 242.396L424.354 228.394C424.521 228.226 424.725 228.067 424.937 227.926Z"
          fill="#90A4AE"
        />
        <path
          d="M424.362 228.395L427.032 242.396C426.775 242.652 426.563 242.944 426.422 243.253L423.752 229.252C423.894 228.943 424.106 228.651 424.362 228.395Z"
          fill="#90A4AE"
        />
        <path
          d="M423.752 229.252L426.422 243.253C426.36 243.395 426.307 243.545 426.263 243.695L423.593 229.694C423.629 229.544 423.682 229.393 423.752 229.252Z"
          fill="#90A4AE"
        />
        <path
          d="M438.071 227.961L440.74 241.963L430.372 241.22L427.703 227.219L438.071 227.961Z"
          fill="#CFD8DC"
        />
        <path
          d="M440.891 230.02L443.56 244.022C443.348 242.926 442.252 242.069 440.741 241.962L438.071 227.961C439.583 228.067 440.679 228.924 440.891 230.02Z"
          fill="#CFD8DC"
        />
        <path
          d="M387.108 242.741L389.777 256.743C388.274 256.637 387.178 255.779 386.966 254.683L384.297 240.682C384.509 241.778 385.605 242.635 387.108 242.741Z"
          fill="#CFD8DC"
        />
        <path
          d="M423.593 229.693L426.263 243.695L423.196 256.344L420.526 242.342L423.593 229.693Z"
          fill="#90A4AE"
        />
        <path
          d="M416.425 244.818L419.086 258.82L389.768 256.743L387.107 242.741L416.425 244.818Z"
          fill="#CFD8DC"
        />
        <path
          d="M420.526 242.343L423.196 256.344C423.16 256.495 423.107 256.645 423.036 256.786L420.367 242.785C420.429 242.643 420.482 242.493 420.526 242.343Z"
          fill="#90A4AE"
        />
        <path
          d="M420.368 242.794L423.037 256.795C422.896 257.105 422.683 257.397 422.427 257.653L419.758 243.651C420.014 243.395 420.226 243.103 420.368 242.794Z"
          fill="#90A4AE"
        />
        <path
          d="M419.757 243.651L422.426 257.653C422.25 257.821 422.055 257.98 421.843 258.121L419.174 244.12C419.386 243.978 419.58 243.828 419.757 243.651Z"
          fill="#90A4AE"
        />
        <path
          d="M419.174 244.12L421.844 258.122C421.702 258.219 421.552 258.298 421.393 258.378L418.724 244.376C418.883 244.297 419.033 244.217 419.174 244.12Z"
          fill="#CFD8DC"
        />
        <path
          d="M418.732 244.367L421.402 258.369C421.269 258.431 421.128 258.492 420.986 258.546L418.317 244.544C418.458 244.491 418.6 244.429 418.732 244.367Z"
          fill="#CFD8DC"
        />
        <path
          d="M418.317 244.553L420.986 258.554C420.853 258.607 420.721 258.643 420.579 258.687L417.91 244.685C418.052 244.65 418.184 244.606 418.317 244.553Z"
          fill="#CFD8DC"
        />
        <path
          d="M417.91 244.677L420.579 258.678C420.438 258.714 420.297 258.749 420.155 258.767L417.486 244.765C417.627 244.739 417.769 244.712 417.91 244.677Z"
          fill="#CFD8DC"
        />
        <path
          d="M417.486 244.773L420.155 258.775C420.005 258.802 419.846 258.819 419.696 258.828L417.026 244.826C417.185 244.818 417.336 244.8 417.486 244.773Z"
          fill="#CFD8DC"
        />
        <path
          d="M417.027 244.827L419.696 258.829C419.51 258.838 419.325 258.838 419.139 258.829L416.47 244.827C416.655 244.836 416.841 244.836 417.027 244.827Z"
          fill="#CFD8DC"
        />
        <path
          d="M416.469 244.827L419.139 258.829C419.139 258.829 419.103 258.829 419.095 258.829L416.425 244.827C416.425 244.827 416.461 244.827 416.469 244.827Z"
          fill="#CFD8DC"
        />
        <path
          d="M439.193 252.252L441.863 266.254C440.36 266.147 439.264 265.29 439.052 264.194L436.383 250.192C436.595 251.288 437.691 252.146 439.193 252.252Z"
          fill="#CFD8DC"
        />
        <path
          d="M475.927 184.057L478.596 198.058L462.369 264.946L459.699 250.944L475.927 184.057Z"
          fill="#90A4AE"
        />
        <path
          d="M455.589 253.419L458.258 267.42L441.863 266.253L439.193 252.252L455.589 253.419Z"
          fill="#CFD8DC"
        />
        <path
          d="M459.699 250.943L462.368 264.945C462.333 265.095 462.28 265.245 462.209 265.387L459.54 251.385C459.602 251.244 459.655 251.094 459.699 250.943Z"
          fill="#90A4AE"
        />
        <path
          d="M459.54 251.386L462.209 265.387C462.068 265.697 461.856 265.988 461.599 266.245L458.93 252.243C459.186 251.987 459.399 251.695 459.54 251.386Z"
          fill="#90A4AE"
        />
        <path
          d="M458.93 252.243L461.6 266.245C461.432 266.413 461.229 266.572 461.016 266.713L458.347 252.712C458.559 252.57 458.754 252.42 458.93 252.243Z"
          fill="#90A4AE"
        />
        <path
          d="M458.356 252.712L461.025 266.713C460.884 266.811 460.734 266.89 460.575 266.97L457.905 252.968C458.064 252.889 458.215 252.809 458.356 252.712Z"
          fill="#CFD8DC"
        />
        <path
          d="M457.905 252.968L460.574 266.969C460.442 267.031 460.3 267.093 460.159 267.146L457.49 253.145C457.631 253.092 457.773 253.03 457.905 252.968Z"
          fill="#CFD8DC"
        />
        <path
          d="M457.49 253.145L460.159 267.146C460.026 267.199 459.894 267.234 459.752 267.279L457.083 253.277C457.224 253.242 457.357 253.198 457.49 253.145Z"
          fill="#CFD8DC"
        />
        <path
          d="M457.083 253.277L459.752 267.279C459.611 267.314 459.469 267.35 459.328 267.367L456.659 253.366C456.8 253.339 456.942 253.313 457.083 253.277Z"
          fill="#CFD8DC"
        />
        <path
          d="M456.659 253.365L459.328 267.367C459.178 267.393 459.019 267.411 458.868 267.42L456.199 253.418C456.358 253.409 456.509 253.392 456.659 253.365Z"
          fill="#CFD8DC"
        />
        <path
          d="M456.199 253.419L458.869 267.42C458.683 267.429 458.498 267.429 458.303 267.42L455.634 253.419C455.819 253.428 456.014 253.428 456.199 253.419Z"
          fill="#CFD8DC"
        />
        <path
          d="M455.634 253.428L458.303 267.429C458.303 267.429 458.267 267.429 458.259 267.429L455.589 253.428C455.589 253.428 455.625 253.428 455.634 253.428Z"
          fill="#CFD8DC"
        />
        <path
          d="M473.143 181.077C475.052 181.21 476.298 182.545 475.936 184.047L459.708 250.935C459.346 252.437 457.507 253.551 455.598 253.41L439.203 252.243C437.302 252.11 436.056 250.776 436.418 249.273L440.873 230.914C441.235 229.411 439.989 228.085 438.08 227.944L427.712 227.21C425.803 227.077 423.965 228.182 423.602 229.685L420.535 242.334C420.173 243.837 418.335 244.95 416.434 244.809L387.116 242.732C385.216 242.599 383.97 241.264 384.332 239.762L399.172 178.584C399.535 177.073 401.373 175.968 403.273 176.101L473.143 181.068V181.077Z"
          fill={color03}
          id="tokyo"
          className="tokyo"
        />
        <line
          x1="1"
          y1="-1"
          x2="142.949"
          y2="-1"
          transform="matrix(0.669274 0.743015 -0.742962 0.669333 20.3301 218.044)"
          stroke="black"
          strokeOpacity="0.12"
          strokeWidth="2"
          strokeLinecap="round"
          strokeDasharray="2 6"
        />
      </svg>
    </React.Fragment>
  );
};

export default JapanMap;
