import React from "react";
import DemandForecastMenu from "./menuItemConponents/DemandForecastMenu";
import PowerForecastMenu from "./menuItemConponents/PowerForecastMenu";
import DemandMonitoringMenu from "./menuItemConponents/DemandMonitoringMenu";
import PowerGenerationMonitoringMenu from "./menuItemConponents/PowerGenerationMonitoringMenu";
import DemandForecastPlanMenu from "./menuItemConponents/DemandForecastPlanMenu";
import PowerForecastPlanMenu from "./menuItemConponents/PowerForecastPlanMenu";
import BiddingScreenMenu from "./menuItemConponents/BiddingScreenMenu";
import OneHourMenu from "./menuItemConponents/OneHourMenu";
import {
  DEMAND_FORECAST_LIST_URL,
  DEMAND_FORECAST_URL,
  DEMAND_FORECAST_PLAN_URL,
  DEMAND_MONITORING_URL,
  POWER_FORECAST_URL,
  POWER_FORECAST_PLAN_URL,
  POWER_GENERATION_MONITORING_URL,
  INTRADAY_BIDDING_URL,
  BALANCING_PLAN_URL,
  PLAN_STATUS_LIST_URL,
  POWER_FORECAST_LIST_URL,
} from "../../../common/url"

type Props = {
  location: string;
};

type InfoTypes = {
  [key: string]: JSX.Element;
};

const Infos: InfoTypes = {
  "/": <></>,
  [PLAN_STATUS_LIST_URL]: <></>,
  [DEMAND_FORECAST_LIST_URL]: <></>,
  [DEMAND_FORECAST_URL]: <DemandForecastMenu></DemandForecastMenu>,
  [DEMAND_FORECAST_PLAN_URL]: <DemandForecastPlanMenu></DemandForecastPlanMenu>,
  [POWER_FORECAST_LIST_URL]: <></>,
  [DEMAND_MONITORING_URL]: <DemandMonitoringMenu></DemandMonitoringMenu>,
  [POWER_FORECAST_URL]: <PowerForecastMenu></PowerForecastMenu>,
  [POWER_FORECAST_PLAN_URL]: <PowerForecastPlanMenu></PowerForecastPlanMenu>,
  [POWER_GENERATION_MONITORING_URL]: (
    <PowerGenerationMonitoringMenu></PowerGenerationMonitoringMenu>
  ),
  // "/biddingScreen": <BiddingScreenMenu></BiddingScreenMenu>,
  [INTRADAY_BIDDING_URL]: <OneHourMenu></OneHourMenu>,
  [BALANCING_PLAN_URL]: <></>,
};

// ロケーションから画面毎のメニューコンポーネントを返却する
// TODO: fksm menuコンポーネントに引数を渡す考慮が必要
const MenuInfo = (props: Props) => {
  return Infos[props.location];
};

export default MenuInfo;
