import React, { useState, useEffect, useContext } from "react";
import "./css/SetPowerBgList.css";
import {
  Box,
  Stack,
  styled,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import CustomModal from "../common/customComponents/CustomModal";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import CustomDatePickerTryal from "../common/customComponents/CustomDatePickerTryal";
import {
  powerBGInfoSet,
  resContractorInfo,
} from "../../types/master/SetPowerBgList";
import { modalMode } from "./PowerBgList";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import { LanguageContext } from "../common/localization/localization";
import { resAreaList } from "../../types/common/Search";
import CustomDatePicker from "../common/customComponents/CustomDatePicker";
//import { format } from "path";
import { addDays, format, subDays } from "date-fns";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import CustomModalDatePicker from "../common/customComponents/CustomMasterCompornent/CustomMstDatePicker";
import CustomSelectListBig from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorBig";
import { useAreaUtility } from "../../common/area";
import { usePowerSupplyTypeUtility } from "../../common/powerSupplyType";
import { useManagementTypeUtility } from "../../common/managementType";
import { API_URL, MANAGEMENT_TYPE, POWER_SUPPLY_TYPE_ID } from "../../common/constant";
import { useAuthedApi } from "../../common/axios";

const CustomTextField = styled(TextField)(() => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "auto",
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
}));

//初期化用
const initialPowerBGInfo: powerBGInfoSet = {
  bgId: 0,
  bgName: "",
  bgCode: "",
  manageKind: 0,
  areaId: "00",
  //contractantName: "",
  //contractantCode: "",
  contractantID: 0,
  powerKind: 0,
  contractId1: "",
  applicatioNumber: 0,
  remarks: "",
  startDate: format(new Date(), "yyyy/MM/dd"),
  finishDate: format(new Date(), "yyyy/MM/dd"),
};

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  bgName: boolean;
  bgCode: boolean;
  manageKind: boolean;
  areaId: boolean;
  contractantID: boolean;
  contractId1: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  bgName: false,
  bgCode: false,
  manageKind: false,
  areaId: false,
  contractantID: false,
  contractId1: false,
};

const SetPowerBgList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  bgIdPK: number
) => {
  type contractorInfo = {
    id: number;
    name: string;
    code: string;
    contractorId: number;
    contractor_areaId: string;
    contractor_areaName: string;
  };
  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  // 通信エラー
  const api = useAuthedApi();

  const { convertIdToAreaName } = useAreaUtility();
  const { supplyTypeOptions } = usePowerSupplyTypeUtility();
  const { managementTypeOptions } = useManagementTypeUtility();

  const contractorInfos = React.useRef<contractorInfo[]>([
    {
      id: 0,
      name: "",
      code: "",
      contractorId: 0,
      contractor_areaId: "00",
      contractor_areaName: "",
    },
  ]);
  const [manageType, setManageType] = useState<string>("1");
  const [powerBGInfo, setPowerBGInfo] =
    useState<powerBGInfoSet>(initialPowerBGInfo);
  const [selContractantCode, setSelContractantCode] = useState<string>("");
  const [selContractantNo, setSelContractantNo] = useState<number>(0);
  const [selContractantArea, setSelContractantArea] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [finishDate, setFinishDate] = useState<Date>(addDays(new Date(), 1));
  const [manegeKindNo, setManegeKindNo] = useState<number>(0); //saleKindセレクターの選ばれたIDを管理
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する
  const manegeInfos: string[] = [
    MANAGEMENT_TYPE.OWN_COMPANY,
    MANAGEMENT_TYPE.OTHER_POWER_PLANT,
    MANAGEMENT_TYPE.OTHER_RETAIL,
    MANAGEMENT_TYPE.JBU,
  ];
  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化
  ////

  //データベースから発電契約者データ一覧取得
  useEffect(() => {
    getContractorMst();
  }, []); //初期化

  const getContractorMst = () => {
    api
      .get(API_URL.GET_CONTRACTOR_MST)
      .then((response) => {
        //console.log("発電契約者データマスタ取得開始");
        //console.log(response.data.result);
        contractorInfos.current = contractorInfos.current.concat(
          convertContractorData(response.data.result as resContractorInfo[])
        );
        //setSelContractantCode(contractorInfos.current[0].code)
        //setSelContractantName(contractorInfos.current[0].name)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //発電契約者名のリストを作成
  const createPowerGenerationContractor = () => {
    const contractorData = contractorInfos.current.map((v) => {
      return v.name === ""
        ? { id: 0, name: languageContext.words.setting_unselected }
        : { id: v.id, name: v.name };
    });

    return contractorData;
  };

  //console.log(powerBGInfo);

  const convertContractorData = (result: resContractorInfo[]) => {
    const retContractorData: contractorInfo[] = [];
    result.map((data, index) => {
      const contractorData: contractorInfo = {
        id: 0,
        name: "",
        code: "",
        contractorId: 0,
        contractor_areaId: "00",
        contractor_areaName: "",
      };
      contractorData.id = index + 1; //0要素にはデフォルトのものが入るため
      contractorData.name = data.name;
      contractorData.code = data.code;
      contractorData.contractorId = data.contractorId;
      contractorData.contractor_areaId = data.contractor_areaId;
      contractorData.contractor_areaName = data.contractor_areaName;

      retContractorData.push(contractorData);
      //console.log("発電契約者変換1データ");
      //console.log(JSON.stringify(contractorData));
    });
    //console.log("発電契約者変換後データ");
    //console.log(JSON.stringify(retContractorData));
    return retContractorData;
  };

  const setContractor = (e: number) => {
    const tmpPowerBGInfo: powerBGInfoSet = Object.assign({}, powerBGInfo);
    const tmpContractInfo: contractorInfo[] = contractorInfos.current;
    //console.log(e);
    tmpPowerBGInfo.areaId = tmpContractInfo[e].contractor_areaId;
    tmpPowerBGInfo.contractantID = tmpContractInfo[e].contractorId;
    // setSelContractantCode(tmpContractInfo[e].code);
    // setSelContractantNo(tmpContractInfo[e].id);
    setPowerBGInfo(tmpPowerBGInfo);
    ContractorCompornentChanged(tmpContractInfo[e]);
  };

  //発電契約者部分のコンポーネント変更（セレクトリストとフィールド）
  const ContractorCompornentChanged = (contractorInfo: contractorInfo) => {
    setSelContractantCode(contractorInfo.code);
    setSelContractantNo(contractorInfo.id);
    setSelContractantArea(contractorInfo.contractor_areaId);
  };

  useEffect(() => {
    setManageType(("00" + powerBGInfo?.manageKind).slice(-2));
  }, [powerBGInfo?.manageKind]); //初期化

  // useEffect(() => {
  //   setPowerBGInfo(initialPowerBGInfo);
  // }, []); //初期化

  useEffect(() => {
    if (toOpen === true) {
      if (mode == modalMode.editMode) {
        getDataLinkedBgCode(bgIdPK);
      } else {
        ContractorCompornentChanged(contractorInfos.current[0]);
        setManegeKindNo(0);
        setPowerBGInfo(initialPowerBGInfo);
        setStartDate(new Date());
        setFinishDate(addDays(new Date(), 1));
      }
    } else {
      ContractorCompornentChanged(contractorInfos.current[0]);
      setPowerBGInfo(initialPowerBGInfo);
      setManegeKindNo(0);
      setStartDate(new Date());
      setFinishDate(addDays(new Date(), 1));
      setInputError(initErrorInput);
    }
  }, [toOpen]); //初期化

  // 管理種別リスト作成

  function setManegeKindId(id: number) {
    //numberを文字列にしてコピーしたdemandListInfoのIDにセットする
    const manegeId = id;
    const tmpManegeInfo: powerBGInfoSet = Object.assign({}, powerBGInfo);
    tmpManegeInfo.manageKind = manegeId;
    //console.log(tmpManegeInfo.manageKind);
    setManegeKindNo(id);
    setPowerBGInfo(tmpManegeInfo);
  }

  // 発電BGデータ一行分取得
  const getDataLinkedBgCode = (bgId: number) => {
    //console.log("発電BG1行データ取得開始");
    let responseData: powerBGInfoSet[] = [];
    api
      .get(API_URL.GET_ONE_POWERBGDATA, { params: { bgId: bgId } })
      .then((response) => {
        //console.log(response.data.result);
        responseData = response.data.result as powerBGInfoSet[];
        if (responseData.length > 0) {
          //console.log("BGデータ1取得");
          //console.log(responseData[0]);
          setPowerBGInfo(responseData[0]); //ここでID含むいろいろ更新
          setContractInfoForEdit(responseData[0].contractantID); //ここでは表示用データ（コード、名称などを更新）
          setManegeKindNo(responseData[0].manageKind);
          setStartDate(new Date(responseData[0].startDate));
          setFinishDate(new Date(responseData[0].finishDate));
        } else {
          //console.log("BGデータ0件");
          //console.log(responseData.length);
          setPowerBGInfo(initialPowerBGInfo);
        }
      })
      .catch((err) => {
        console.log(err);
        setPowerBGInfo(initialPowerBGInfo);
      });
  };

  const setContractInfoForEdit = (contractantID: number) => {
    contractorInfos.current.map((contractorInfo) => {
      if (contractantID === contractorInfo.contractorId) {
        ContractorCompornentChanged(contractorInfo);
      }
    });
  };

  const onChangeValue = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name: string = e.currentTarget.name;
    const value: string = e.currentTarget.value;

    const tmpPowerBGInfo: powerBGInfoSet = Object.assign({}, powerBGInfo);
    switch (name) {
      case "bgName":
        tmpPowerBGInfo.bgName = value;
        break;
      case "bgCode":
        tmpPowerBGInfo.bgCode = value;
        break;
      // case "contractantName":
      //   tmpPowerBGInfo.contractantName = value;
      //   break;
      // case "contractantId":
      //   tmpPowerBGInfo.contractantCode = value;
      //   break;
      case "applicatioNumber":
        tmpPowerBGInfo.applicatioNumber = isNaN(parseInt(value))
          ? 0
          : parseInt(value);
        break;
      case "remarks":
        tmpPowerBGInfo.remarks = value;
        break;
      case "contractId1":
        tmpPowerBGInfo.contractId1 = value;
        break;
      case "powerKind":
        tmpPowerBGInfo.powerKind = isNaN(parseInt(value)) ? 0 : parseInt(value);
        break;
      default:
        break;
    }
    setPowerBGInfo(tmpPowerBGInfo);
  };

  function onPowerKindChangeValue(e: number) {
    const tmp: powerBGInfoSet = Object.assign({}, powerBGInfo);
    tmp.powerKind = e;
    setPowerBGInfo(tmp);
  }

  //新規追加
  function AddDatas() {
    //console.log(powerBGInfo);
    let error = false;

    error = isCorrectInfo();

    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_new_registration);
      setDigOpen(true);
    } else if (error === false) {
      api
        .post(API_URL.ADD_POWERBG_INFO, powerBGInfo)
        .then((response) => {
          //console.log(response.data.dgm_role);
          setJobClear(true);
          setDialogMessage(languageContext.words.power_bg_added);
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.power_bg_failed_add);
          setDigOpen(true);
        });
    }
  }

  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (powerBGInfo?.bgName == "") {
      error = true;
      tempInputErrors.bgName = true;
    }
    if (powerBGInfo?.bgCode == "") {
      error = true;
      tempInputErrors.bgCode = true;
    }
    if (powerBGInfo?.areaId == "00") {
      error = true;
      tempInputErrors.areaId = true;
    }
    if (powerBGInfo?.contractantID == 0) {
      error = true;
      tempInputErrors.contractantID = true;
    }
    if (powerBGInfo?.contractId1 == "") {
      error = true;
      tempInputErrors.contractId1 = true;
    }

    // if (
    //   powerBGInfo?.bgName == "" ||
    //   powerBGInfo?.bgCode == "" ||
    //   powerBGInfo?.areaId == "00" ||
    //   powerBGInfo?.contractantID == 0 ||
    //   powerBGInfo?.contractId1 == ""
    // ) {
    //   error = true;
    // }
    setInputError(tempInputErrors);
    return error;
  };

  //BGコードの値をもとにデータを更新する
  function UpdateDatas() {
    let error = false;

    error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else if (error === false) {
      const url = api
        .post(API_URL.UPDATE_POWERBG_INFO, powerBGInfo)
        .then((response) => {
          //console.log(response.data.dgm_role);
          setJobClear(true);
          setDialogMessage(languageContext.words.power_bg_update);
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.power_bg_failed_update);
          setDigOpen(true);
        });
    }
  }

  // BG削除(論理削除)
  const Delete = (bgId: number | undefined) => {
    // 削除API呼出
    //console.log(powerBGInfo);
    api
      .post(API_URL.DELETE_POWERBG_INFO, { bgId: bgId })
      .then((response) => {
        //console.log(`BG1件削除結果: ${response.data.result}`);
        setJobClear(true);
        setDialogMessage(languageContext.words.power_bg_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.power_bg_failed_delete);
        setDigOpen(true);
      });
  };

  //開始datePicker
  useEffect(() => {
    const tmpInfo: powerBGInfoSet = Object.assign({}, powerBGInfo);
    tmpInfo.startDate = format(startDate, "yyyy/MM/dd");
    setPowerBGInfo(tmpInfo);
  }, [startDate]);

  ////終了datePicker
  useEffect(() => {
    const tmpInfo: powerBGInfoSet = Object.assign({}, powerBGInfo);
    tmpInfo.finishDate =
      finishDate == null ? "" : format(finishDate, "yyyy/MM/dd");
    setPowerBGInfo(tmpInfo);
  }, [finishDate]);

  //セーブして閉じる
  const SaveAndClose = () => {
    //console.log("accept!!!!");
    if (mode == modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.generation_bg_setting}
      open={toOpen}
      // onAcceptLeft={() => UserSave()}
      onAcceptLeft={() => SaveAndClose()}
      onAcceptRight={() => Delete(powerBGInfo?.bgId)}
      onClose={() => HaldleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
      }
    >
      <Stack direction="row" sx={{ height: "630px", width: "550px" }}>
        <Stack direction="column" spacing={0}>
          <CustomBoxBig>
            <CustomModalTypography gutterBottom>
              {languageContext.words.power_gen_bg_name}
            </CustomModalTypography>
            <CustomTextFieldBig
              helperText={languageContext.words.setting_required}
              type="text"
              value={powerBGInfo?.bgName}
              onChange={onChangeValue}
              name={"bgName"}
              inputProps={{
                maxLength: 64,
              }}
              error={inputError.bgName}
            ></CustomTextFieldBig>
          </CustomBoxBig>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.bg_code}
              </CustomModalTypography>
              <CustomTextFieldSmall
                helperText={languageContext.words.setting_required}
                type="text"
                value={powerBGInfo?.bgCode}
                onChange={onChangeValue}
                name="bgCode"
                inputProps={{
                  maxLength: 5,
                }}
                error={inputError.bgCode}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.management_type}
              </CustomModalTypography>
              <CustomSelectListSmall
                name="manegeId"
                label={""}
                //defaultValue={0}
                value={manegeKindNo.toString().padStart(2, "0")}
                options={managementTypeOptions(manegeInfos)}
                onChange={(e: number) => {
                  setManegeKindId(e);
                }}
              />
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.power_gen_contractor}
              </CustomModalTypography>
              <CustomSelectListBig
                //key={"demandForecastData_CustomSelectList_customer"}
                name="manegeId"
                label={languageContext.words.all_areas}
                //defaultValue={0}
                value={selContractantNo}
                options={createPowerGenerationContractor()}
                onChange={(e: number) => {
                  setContractor(e);
                }}
                error={inputError.contractantID}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.connection_area}
              </CustomModalTypography>
              <CustomTextFieldSmall
                //key={"demandForecastData_CustomSelectList_customer"}
                name="areaId"
                //label={languageContext.words.all_areas}
                value={convertIdToAreaName(selContractantArea)}
                disabled={true}
                error={inputError.areaId}
              />
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>{/* 空領域 */}</CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.power_gen_contractor_code}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="text"
                value={selContractantCode}
                onChange={onChangeValue}
                name="contractantId"
                disabled={true}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.power_supply_type}
              </CustomModalTypography>
              <CustomSelectListSmall
                name="powerKind"
                label={"powerList"}
                value={powerBGInfo.powerKind}
                options={supplyTypeOptions}
                onChange={onPowerKindChangeValue}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.contract_id_number_1}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="text"
                value={powerBGInfo?.contractId1}
                onChange={onChangeValue}
                name="contractId1"
                inputProps={{
                  maxLength: 20,
                }}
                error={inputError.contractId1}
                helperText={languageContext.words.setting_required}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.effective_date_start_date}
              </CustomModalTypography>
              <CustomModalDatePicker
                setValue={setStartDate}
                value={startDate}
                maxDate={subDays(finishDate, 1)}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.expiration_date}
              </CustomModalTypography>
              <CustomModalDatePicker
                setValue={setFinishDate}
                value={finishDate}
                minDate={addDays(startDate, 1)}
              />
            </CustomBoxSmall>
          </Stack>

          <CustomBoxBig>
            <CustomModalTypography gutterBottom>
              {languageContext.words.remarks}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="text"
              value={powerBGInfo?.remarks}
              onChange={onChangeValue}
              name="remarks"
              inputProps={{
                maxLength: 256,
              }}
            ></CustomTextFieldBig>
          </CustomBoxBig>
        </Stack>
      </Stack>
      {/* <Stack
        direction="row"
        spacing={1}
        sx={{ marginBottom: "40px", marginTop: "240px", marginLeft: "20px" }}
      >
        <Box>
          <Typography gutterBottom>
            {languageContext.words.effective_date_start_date}
          </Typography>
          <CustomDatePickerTryal
            setValue={setStartDate}
            value={startDate}
            maxDate={subDays(finishDate, 1)}
          />
        </Box>
        <Box>
          <Typography gutterBottom>
            {languageContext.words.expiration_date}
          </Typography>
          <CustomDatePickerTryal
            setValue={setFinishDate}
            value={finishDate}
            minDate={addDays(startDate, 1)}
          />
        </Box>
        <Button
          sx={{
            marginLeft: "auto",
            width: "120px",
            height: "40px",
          }}
          variant="outlined"
        >
          {languageContext.words.additional_period}
        </Button>
      </Stack> */}

      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetPowerBgList;
