import React, { Outlet, Navigate, useNavigate } from "react-router-dom";
import { ReactNode, useContext } from "react";
import { AuthContext } from "./AuthProvider";
import { useAuthedApi } from "./common/axios";
import { ErrOperationContext } from "./views/system/Login/ErrOperationProvider";

//OutletのパターンはURLを検討する必要があるので利用しない
/*const PrivateRoute = () => {
  const auth = true;
  return auth ? <Outlet /> : <Navigate to="/" replace />;
};*/

const PrivateRoute = ({children}:{children :ReactNode}) => {
  const {auth, setAuth } = useContext(AuthContext);   
  const { setErrOperation } = useContext(ErrOperationContext);

  const navigate = useNavigate();
  const authedApi = useAuthedApi();

  window.addEventListener('popstate', (e) => {
    authedApi.post("/logout").then((res) => {
      navigate("/");
    });
    setAuth("");
  });

  if(auth !== "") {
    return <>{children}</>
  } else {
    setErrOperation(true);
    return <Navigate to="/" replace  />
  }
};

export default PrivateRoute;
