import React, { useState, useEffect, useContext } from "react";
import "./css/SetSupplyBgList.css";
import { Box, Button, Stack, styled, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import axios from "axios";
import CustomModal from "../common/customComponents/CustomModal";
import { LanguageContext } from "../common/localization/localization";
import { setDemandBGInfo } from "../../types/master/SupplyBgList";
import { modalMode } from "./SupplyBgList";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import { resAreaList } from "../../types/common/Search";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import SetPowerBgList from "./SetPowerBgList";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListBig from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorBig";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import { useAreaUtility } from "../../common/area";
import { useAuthedApi } from "../../common/axios";
import { API_URL } from "../../common/constant";

const CustomTextField = styled(TextField)(() => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "auto",
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
}));

type areaInfo = { id: string; name: string };
type powerBpInfo = {
  id: number;
  bpid: number;
  name: string;
  code: string;
};

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  bgName: boolean;
  bgCode: boolean;

  areaId: boolean;
  bp_id: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  bgName: false,
  bgCode: false,

  areaId: false,
  bp_id: false,
};

//引数で編集か新規追加かを判断。編集の場合、書かれている情報も持ってくる
const SetSupplyBgList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  bgIDForEdit: number
) => {
  
  //初期化用
  const initialDemandBGInfo: setDemandBGInfo = {
    bgId: 0,
    bgName: "",
    bgCode: "",
    bp_id: 0,
    areaId: "00",
    remarks: "",
  };

  //const [area, setArea] = useState("未選択");
  const [demandBGInfo, setdemandBGInfo] = useState<setDemandBGInfo>(initialDemandBGInfo);

  // 通信エラー
  const api = useAuthedApi();

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる

  const { areaOptions } = useAreaUtility();

  // エリアマスタデータのref
  const [areaInfos, setareaInfos] = useState<string[]>(["00"]);
  const bpInfos = React.useRef<powerBpInfo[]>([
    { id: 0, name: "未選択", bpid: 0, code: "" },
  ]);
  const [bpCode, setBpCode] = useState<string>("");
  const [bpIndex, setBpIndex] = useState<number>(0);

  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する

  //言語データ切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  const editflg = React.useRef<boolean>(false); //編集用データ取得した直後かを判別するフラグ

  const setAreaId = (id: number) => {
    //numberを文字列にしてコピーしたdemandListInfoのIDにセットする

    const areaIdString = ("00" + id).slice(-2).toString();
    const tmpAreaInfo: setDemandBGInfo = Object.assign({}, demandBGInfo);
    tmpAreaInfo.areaId = areaIdString;

    setdemandBGInfo(tmpAreaInfo);
  };



  useEffect(() => {
    getPowerBp();
    setdemandBGInfo(initialDemandBGInfo);
  }, []); //初期化

  useEffect(() => {
    if (toOpen === true) {
      bpInfos.current[0] = {
        id: 0,
        name: languageContext.words.setting_unselected,
        bpid: 0,
        code: "",
      };
      if (mode == modalMode.editMode) {
        getDataLinkedBgCode(bgIDForEdit);
      } else {
        setdemandBGInfo(initialDemandBGInfo);
        setBpCode("");
        setBpIndex(0);
      }
    } else {
      setareaInfos(["00"]);
      setdemandBGInfo(initialDemandBGInfo);
      setBpCode("");
      setBpIndex(0);
      setInputError(initErrorInput);
    }
  }, [toOpen]); //初期化

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化
  ////

  ////発電事業者変化したときエリアプルダウンの中身再取得
  useEffect(() => {
    //再取得時、選択してたエリアを「未選択」に初期化する
    //ただし、編集用データ取得直後は初期化しない
    if (editflg.current == false) {
      const tmpPowerBGInfo: setDemandBGInfo = Object.assign({}, demandBGInfo);
      tmpPowerBGInfo.areaId = "00";
      setdemandBGInfo(tmpPowerBGInfo);
    } else {
      editflg.current = false;
    }

    setareaInfos(["00"]);
    getAreaMst(bpIndex);
  }, [bpIndex]); //初期化
  ////

  const getAreaMst = (bpIndex: number) => {
    api
      .get(API_URL.GET_DEMAND_AREA_LIST, {
        params: { bpId: bpInfos.current[bpIndex].bpid },
      })
      .then((response) => {
        setareaInfos(convertAreaData(response.data as resAreaList[]));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertAreaData = (result: resAreaList[]) => {
    const retAreaData: string[] = ["00"];
    result.map((data) => {
      let areaData = "";
      areaData = data.areaId.toString();
      retAreaData.push(areaData);
      //console.log("エリア変換1データ");
      //console.log(JSON.stringify(areaData));
    });
    //console.log("エリア変換後データ");
    //console.log(JSON.stringify(retAreaData));
    return retAreaData;
  };

  //発電事業者取得
  const getPowerBp = () => {
    api.get(API_URL.GET_POWER_BP).then((res) => {
      bpInfos.current = bpInfos.current.concat(
        res.data.map((v: any, index: number): powerBpInfo => {
          return {
            id: index + 1,
            bpid: v.bpid,
            name: v.name,
            code: v.code,
          };
        })
      );
      //console.log(bpInfos.current);
    });
  };

  // 発電BGデータ一行分取得
  const getDataLinkedBgCode = (bgID: number) => {
    //console.log("発電BG1行データ取得開始");
    let responseData: setDemandBGInfo;
    api
      .get(API_URL.GET_ONE_DEMANDBGDATA, { params: { bgID: bgID } })
      .then((response) => {
        editflg.current = true;
        responseData = response.data.result as setDemandBGInfo;
        //console.log(responseData);
        setdemandBGInfo(responseData);
        const bp = bpInfos.current.find((v) => v.bpid === responseData.bp_id);
        setBpCode(bp?.code ? bp.code : "");
        setBpIndex(bp?.id ? bp.id : 0);
      })
      .catch((err) => {
        console.log(err);
        setdemandBGInfo(initialDemandBGInfo);
      });
  };

  const onChangeValue = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const name: string = e.currentTarget.name;
    const value: string = e.currentTarget.value;

    const tmpPowerBGInfo: setDemandBGInfo = Object.assign({}, demandBGInfo);
    switch (name) {
      case "bgName":
        tmpPowerBGInfo.bgName = value;
        break;
      case "bgCode":
        tmpPowerBGInfo.bgCode = value;
        break;
      case "senderCode":
        tmpPowerBGInfo.bp_id = isNaN(parseInt(value)) ? 0 : parseInt(value);
        break;
      case "remarks":
        tmpPowerBGInfo.remarks = value;
        break;
      default:
        break;
    }
    setdemandBGInfo(tmpPowerBGInfo);
  };

  const onChangePowerBp = (id: number) => {
    const tmp: setDemandBGInfo = Object.assign({}, demandBGInfo);
    const tempBpInfo = bpInfos.current as powerBpInfo[];
    tmp.bp_id = tempBpInfo[id].bpid;
    setBpCode(tempBpInfo[id].code);
    setBpIndex(id);
    setdemandBGInfo(tmp);
  };

  //セーブして内容描画して閉じる
  const DoModeJob = () => {
    if (mode == modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // BG新規追加
  const AddDatas = () => {
    //console.log("需要BG新規追加", demandBGInfo);

    const error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_confirm_entry);
      setDigOpen(true);
    } else if (error === false) {
      api
        .post(API_URL.SET_DEMANDBG_LIST_API, demandBGInfo)
        .then((response) => {
          if (response.data) {
            setJobClear(true);
            setDialogMessage(languageContext.words.demand_bg_added);
            setDigOpen(true);
          } else {
            setDialogMessage(languageContext.words.demand_bg_err_message_1);
            setDigOpen(true);
          }
        })
        .catch((err) => {
          //setJobClear(false);
          setDialogMessage(languageContext.words.demand_bg_failed_add);
          setDigOpen(true);
        });
    }
  };

  const showSuccessDialog = async () => {
    setDialogMessage("需要BGを新規追加しました。");
    setDigOpen(true);
  };

  // BG更新
  const UpdateDatas = () => {
    //console.log("需要BG更新", demandBGInfo);

    const error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_confirm_entry);
      setDigOpen(true);
    } else if (error === false) {
      api
        .post(API_URL.UPDATE_DEMANDBG_INFO, demandBGInfo)
        .then((response) => {
          if (response.data) {
            setJobClear(true);
            setDialogMessage(languageContext.words.demand_bg_update);
            setDigOpen(true);
          } else {
            setDialogMessage(languageContext.words.demand_bg_err_message_1);
            setDigOpen(true);
          }
        })
        .catch((err) => {
          setDialogMessage(languageContext.words.demand_bg_failed_update);
          setDigOpen(true);
          console.log(err);
        });
    }
  };

  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (demandBGInfo?.areaId == "00") {
      error = true;
      tempInputErrors.areaId = true;
    }
    if (demandBGInfo?.bgCode == "") {
      error = true;
      tempInputErrors.bgCode = true;
    }
    if (demandBGInfo?.bgName == "") {
      error = true;
      tempInputErrors.bgName = true;
    }
    if (demandBGInfo?.bp_id == 0) {
      error = true;
      tempInputErrors.bp_id = true;
    }

    setInputError(tempInputErrors);
    return error;
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  // BG削除(論理削除)
  const Delete = (bgId: number | undefined) => {
    // 削除API呼出
    //console.log("論理削除開始(フロント)", demandBGInfo);
    api
      .post(API_URL.DELETE_DEMANDBG_INFO, { bgId: bgId })
      .then((response) => {
        //console.log(`BG1件削除結果: ${response.data.result}`);
        setJobClear(true);
        setDialogMessage(languageContext.words.demand_bg_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.demand_bg_failed_delete);
        setDigOpen(true);
      });
  };

  return (
    <>
      <CustomSettingModal
        title={languageContext.words.demand_bg_setting}
        open={toOpen}
        // onAcceptLeft={() => UserSave()}
        onAcceptLeft={() => DoModeJob()}
        onAcceptRight={() => Delete(demandBGInfo?.bgId)}
        onClose={() => HaldleClose()}
        btnTextLeft={languageContext.words.registration}
        btnTextRight={languageContext.words.delete}
        btnType={
          mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
        }
      >
        <Stack sx={{ height: "450px", width: "550px" }}>
          <CustomBoxBig>
            <CustomModalTypography>
              {languageContext.words.demand_bg_name}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="text"
              value={demandBGInfo?.bgName}
              onChange={onChangeValue}
              name="bgName"
              inputProps={{
                maxLength: 64,
              }}
              error={inputError.bgName}
              helperText={languageContext.words.setting_required}
            ></CustomTextFieldBig>
          </CustomBoxBig>

          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.demand_bg_code}
            </CustomModalTypography>
            <CustomTextFieldSmall
              type="text"
              value={demandBGInfo?.bgCode}
              onChange={onChangeValue}
              name="bgCode"
              inputProps={{
                maxLength: 5,
              }}
              error={inputError.bgCode}
              helperText={languageContext.words.setting_required}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.setting_bp_name}
              </CustomModalTypography>
              <CustomSelectListBig
                value={bpIndex}
                options={bpInfos.current}
                onChange={(e: number) => {
                  onChangePowerBp(e);
                }}
                error={inputError.bp_id}
              ></CustomSelectListBig>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.setting_bp_code}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="text"
                value={bpCode}
                onChange={onChangeValue}
                disabled={true}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall></CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.area}
              </CustomModalTypography>
              <CustomSelectListSmall
                name="areaId"
                label={languageContext.words.all_areas}
                value={demandBGInfo?.areaId}
                options={areaOptions(areaInfos, true)}
                onChange={(e: number) => {
                  if (("00" + e).slice(-2).toString() == demandBGInfo?.areaId) {
                    return;
                  }
                  setAreaId(e);
                }}
                error={inputError.areaId}
              />
            </CustomBoxSmall>
          </Stack>
          <CustomBoxBig>
            <CustomModalTypography gutterBottom>
              {languageContext.words.remarks}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="text"
              value={demandBGInfo?.remarks}
              onChange={onChangeValue}
              name="remarks"
              inputProps={{
                maxLength: 256,
              }}
            ></CustomTextFieldBig>
          </CustomBoxBig>
        </Stack>
      </CustomSettingModal>
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </>
  );
};

export default SetSupplyBgList;
