import React from "react";
import { createContext, useState } from "react";
import { PaletteMode } from "@mui/material";


export const palletColorKey="PaletteKey";

//どのモードを使うか選ぶ際の型定義
type PaletteType = {
  PaletteMode: PaletteMode;
  setPaletteMode: (themaMode: React.SetStateAction<PaletteMode>) => void;
};


//これを色んな画面のコンポーネントで参照して、今が何モードなのかを感知する
export const PaletteContext = createContext<PaletteType>({
  PaletteMode: 'light',
  setPaletteMode: (ThemaMode) => null,
});


//App.tsxで使用したいページ間をこのコンポーネントで挟み込む、最初はlightモード
export const ModeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  //監視対象PaletteModeを初期化
  const [PaletteMode, setPaletteMode] = useState<PaletteMode>(getInitialPalette());
  return (
    <PaletteContext.Provider value={{ PaletteMode: PaletteMode, setPaletteMode: setPaletteMode }}>
      {children}
    </PaletteContext.Provider>
  );
}

//初期パレットを取ってくる
export const getInitialPalette=():PaletteMode=>{

  //localStorage.removeItem("paletteMode");
  //ローカルストレージからパレットデータを取ってこれるか試す
  const InitialPalette:string|null = localStorage.getItem(palletColorKey);
  if(InitialPalette=="light"||InitialPalette=="dark"){
  return InitialPalette;
  }else{
    //nullだったりうまく読み込めない時はlightモードスタート
    return "light";
  }
}







  


