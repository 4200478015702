import React, { ReactNode, createContext, useState } from "react";

type RoleContextType = {
  role: string;
  setRole: React.Dispatch<React.SetStateAction<string>>;
  initRoleContext: () => void;
};

export const RoleContext = createContext<RoleContextType>(
  {} as RoleContextType
);

const RoleContextProvider = ({ children }: { children: ReactNode }) => {
  const [role, setRole] = useState("");
  const initRoleContext = () => {
    setRole("");
  };
  return (
    <RoleContext.Provider
      value={{ role: role, setRole: setRole, initRoleContext: initRoleContext }}
    >
      {children}
    </RoleContext.Provider>
  );
};

export default RoleContextProvider;
