import React, { useContext } from "react";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CustomModal from "../../common/customComponents/CustomModal";
import TextField from "@mui/material/TextField";
import { createAreaData } from "./0314_OneHourTestData";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import Grid from "@mui/material/Grid";
import { display } from "@mui/system";
import { LanguageContext } from "../../common/localization/localization";
import axios from "axios";
import { createAutomationSettingsListData } from "./OneHourAdvanceBidPlan";
import { DateContext } from "../../common/customComponents/GlobalDatePicker";
import { format } from "date-fns";
import { useAuthedApi } from "../../../common/axios";
import CustomDialog, {ButtonType} from "../../common/customComponents/CustomDialog";

//アイテムグリッド
const ItemGrid = styled(Grid)`
  && {
    width: 100%;
    font-size: 14px;
  }
`;

//内容を表示するグリッド
const TitleGrid = styled(ItemGrid)`
  && {
    color: rgb(150, 150, 150);
  }
`;

//内容を表示するグリッド
const SelectListGrid = styled(ItemGrid)`
  && {
    margin: -8px;
  }
`;

const POST_AUTO_SETTING = "/postAutoSetting"; //自動化設定登録
const GET_AUTO_SETTING = "/getAutoSetting"; //自動化設定取得
const AUTO_BIDDING = "/autoBidding"; //自動価格調整開始

//自動入札設定のモーダル
const AutomationSettings = (
  AutomationSettingsList: createAutomationSettingsListData,
  open: boolean,
  HaldleClose: () => void,
  areaId: string,
  setSwitchActivity: React.Dispatch<React.SetStateAction<boolean>>,
  startTime: string,
  deleteTime: string,
  priceAdjustment: string,
  deadlineTime: string,
  adjustmentCycle: string,
  adjustmentAmount: number,
  setDeadline: React.Dispatch<React.SetStateAction<string>>,
  setPriceAdjustment: React.Dispatch<React.SetStateAction<string>>,
  setDeleteTime: React.Dispatch<React.SetStateAction<string>>,
  setStartTime: React.Dispatch<React.SetStateAction<string>>,
  setAdjustmentCycle: React.Dispatch<React.SetStateAction<string>>,
  setAdjustmentAmount: React.Dispatch<React.SetStateAction<number>>,
  hidden: boolean
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const dateValue = useContext(DateContext);

  const api = useAuthedApi();

  //自動価格調整のセレクトボックス
  const [modalPriceAdjustment, setModalPriceAdjustment] =
    React.useState<string>(priceAdjustment);
  //入札締め切り時間のセレクトボックス
  const [modalDeadline, setModalDeadline] =
    React.useState<string>(deadlineTime);
  //自動札削除のセレクトボックス
  const [modalAutoDelete, setModalAutoDelete] =
    React.useState<string>(deleteTime);
  //開始時間のセレクトボックス
  const [modalStartTime, setModalStartTime] = React.useState<string>(startTime);
  //調整周期のセレクトボックス
  const [modalAdjustmentCycle, setModalAdjustmentCycle] =
    React.useState<string>(adjustmentCycle);
  //調整量のテキストフィールド
  // const [adjustmentAmount, setAdjustmentAmount] = React.useState<string>("");
  const [modalAdjustmentAmount, setModalAdjustmentAmount] =
    React.useState<string>(adjustmentAmount.toFixed(2));

  //開始時間のセレクトボックス
  const StartTimeHandleChange = (selectItem: number) => {
    setModalStartTime(selectItem.toString().padStart(2, "0"));
  };
  //調整周期のセレクトボックス
  const AdjustmentCycleHandleChange = (selectItem: number) => {
    setModalAdjustmentCycle(selectItem.toString().padStart(2, "0"));
  };
  //調整量のボックス
  const AjustmentAmountHandleInput = (inputValue: string) => {
    setModalAdjustmentAmount(inputValue.replace(/-/g, ""));
  };

  // フォーカスアウト時にテキストフィールドに表示している値をtoFixedする
  const processText = (
    text: string,
    setText: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const val = Number(text);
    const temp = val > 9.99 ? "9.99" : val.toFixed(2);
    setText(temp);
  };

  //ダイアログ
  const [dialogMessage, setDialogMessage] = React.useState<string>("");
  const [digOpen, setDigOpen] = React.useState<boolean>(false);
  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  //DBに設定内容を登録する
  const postSetting = async () => {
      await api
      .post(POST_AUTO_SETTING, {
        areaId: areaId,
        startTime: modalStartTime,
        deleteTime: modalAutoDelete,
        priceAdjust: modalPriceAdjustment,
        bidDeadline: modalDeadline,
        adjustCycle: modalAdjustmentCycle,
        adjustValue: Number(modalAdjustmentAmount),
        
      })
      .then((res) => {
        setDeadline(modalDeadline);
        setPriceAdjustment(modalPriceAdjustment);
        setDeleteTime(modalAutoDelete);
        setStartTime(modalStartTime);
        setAdjustmentCycle(modalAdjustmentCycle);
        setAdjustmentAmount(Number(modalAdjustmentAmount));
        setDialogMessage(languageContext.words.bid_automatic_setting_saved);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.bid_automatic_setting_saved_failed);
        setDigOpen(true);
      });
  };

  //DBから設定内容を取得する
  const getSetting = async () => {
    await api
      .get(GET_AUTO_SETTING, {
        params: { areaId: areaId },
      })
      .then((res) => {
        setDeadline(Number(res.data.bidDeadline).toString().padStart(2, "0"));
        setPriceAdjustment(
          Number(res.data.priceAdjust).toString().padStart(2, "0")
        );
        setDeleteTime(Number(res.data.deleteTime).toString().padStart(2, "0"));
        setStartTime(Number(res.data.startTime).toString().padStart(2, "0"));
        setAdjustmentCycle(
          Number(res.data.adjustCycle).toString().padStart(2, "0")
        );
        setAdjustmentAmount(Number(res.data.adjustValue));
        setModalDeadline(
          Number(res.data.bidDeadline).toString().padStart(2, "0")
        );
        setModalPriceAdjustment(
          Number(res.data.priceAdjust).toString().padStart(2, "0")
        );
        setModalAutoDelete(
          Number(res.data.deleteTime).toString().padStart(2, "0")
        );
        setModalStartTime(
          Number(res.data.startTime).toString().padStart(2, "0")
        );
        setModalAdjustmentCycle(
          Number(res.data.adjustCycle).toString().padStart(2, "0")
        );
        setModalAdjustmentAmount(Number(res.data.adjustValue).toFixed(2));
      })
      .catch((err) => console.log(err));
  };

  const switchHandle = async () => {
    if (areaId !== "" && areaId !== "00") {
      await getSetting();
    }
    if (priceAdjustment === "00") {
      setSwitchActivity(true);
    } else {
      setSwitchActivity(false);
    }
  };

  const autoBidding = async () => {
    await api
      .post(AUTO_BIDDING, {
        areaId: areaId,
        targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    // return "autoBidding";
  };

  React.useEffect(() => {
    if (areaId !== "" && areaId !== "00") {
      getSetting();
    }
  }, [open]);

  //保存ボタン
  const SaveButtonEvent = async () => {
    if (areaId !== "" && areaId !== "00") {
      await postSetting();
      await autoBidding();
      await switchHandle();
    }
    return "a";
  };

  //モーダルを閉じる処理
  const ModalCloseEvent = () => {
    HaldleClose();
  };

  return (
    <>
      <CustomModal
        hidden={hidden}
        title={languageContext.words.bid_automatic_setting}
        open={open}
        onAccept={async () => await SaveButtonEvent()}
        onClose={ModalCloseEvent}
        btnText={languageContext.words.save}
      >
        <Box sx={{ height: "130px", width: "430px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6.5}>
              <Grid container>
                <TitleGrid item>{languageContext.words.start_time}</TitleGrid>
                <SelectListGrid item>
                  <CustomSelectList
                    label={languageContext.words.none}
                    value={Number(startTime)}
                    options={AutomationSettingsList.startTime.map(
                      (listData) => listData
                    )}
                    onChange={(value: number) => StartTimeHandleChange(value)}
                  />
                </SelectListGrid>
              </Grid>
            </Grid>
            <Grid item xs={5.5}>
              <Grid container>
                <TitleGrid item>
                  {languageContext.words.adjustable_cycle}
                </TitleGrid>
                <SelectListGrid item>
                  <CustomSelectList
                    label={"10" + languageContext.words.min}
                    value={Number(adjustmentCycle)}
                    options={AutomationSettingsList.adjustmentCycle.map(
                      (listData) => listData
                    )}
                    onChange={(value: number) =>
                      AdjustmentCycleHandleChange(value)
                    }
                  />
                </SelectListGrid>
              </Grid>
            </Grid>
            <Grid item xs={6.5}>
              <Grid container>
                <TitleGrid item>
                  {languageContext.words.adjustment_amount}
                </TitleGrid>
                <Grid item sx={{ display: "flex" }}>
                  <TextField
                    type="number"
                    onChange={(event) =>
                      AjustmentAmountHandleInput(event.target.value)
                    }
                    onFocus={(e) => {
                      if (modalAdjustmentAmount === "0.00") {
                        e.currentTarget.select();
                      }
                    }}
                    onBlur={(e) => {
                      processText(
                        modalAdjustmentAmount,
                        setModalAdjustmentAmount
                      );
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === "e" ||
                        event.key === "E" ||
                        event.key === "-"
                      ) {
                        event.preventDefault();
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 9.99,
                        step: "0.01",
                        style: { padding: "8.5px 14px", textAlign: "right" },
                      },
                    }}
                    value={modalAdjustmentAmount}
                    sx={{ width: "160px" }}
                  ></TextField>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "rgb(150, 150, 150)",
                      fontSize: "14px",
                    }}
                  >
                    {languageContext.words.yen_kwh}
                  </label>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </CustomModal>
      <CustomDialog
      title={""}
      message={dialogMessage}
      buttonType={ButtonType.OkOnly}
      open={digOpen}
      onAccept={acceptHandler}
      onClose={() => setDigOpen(false)}
      />
    </>
  );
};
export default AutomationSettings;
