import React, { useContext, useEffect, useState } from "react";
import { Chart as ChartJS } from "chart.js";
import Stack from "@mui/material/Stack";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import CustomModalViewOnly from "../../common/customComponents/CustomModalViewOnly";
import { LanguageContext } from "../../common/localization/localization";
import axios from "axios";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { CustomBoxBig } from "../../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../../common/customComponents/CustomMasterCompornent/CustomTypography";
import { CustomTextFieldBig } from "../../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import { ConnectionErrorDisplay } from "../Utility/ConnectionErrorComponent";
import { useAuthedModalApi } from "../../../common/axios";
import { ErrorMessageContext } from "../../common/customComponents/ErrorMessageProvider";
import { ErrorTitleContext } from "../../common/customComponents/ErrorTitleProvider";

ChartJS.register(zoomPlugin);
const GET_ACCOUNT_INFO = "_api/getAccountInfo";
const SET_ACCOUNT_INFO = "_api/setPassword";

const CustomTextField = styled(TextField)(() => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "auto",
  width: "230px",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
}));

// アカウント情報
export interface notificationListType {
  userName: string;
  userId: string;
  mail: string;
}

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  password: boolean;
  conPassword: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  password: false,
  conPassword: false,
};

// アカウント設定画面
const SetAccountInfo = (props: any) => {
  const { toOpen, HandleClose } = props;
  const { setMessage } = useContext(ErrorMessageContext);
  const { setTitle } = useContext(ErrorTitleContext);
  const [isPassDigOpen, setIsPassDigOpen] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する
  const [error, setError] = useState<boolean>(false);

  // 初期化
  const initAccount: notificationListType = {
    userName: "",
    userId: "",
    mail: "",
  };

  const [accountInfo, setAccountInfo] =
    useState<notificationListType>(initAccount);

  //言語を切り替えるデータコンテキスト
  const languageContext = useContext(LanguageContext);

  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HandleClose();
    }
  }, [digOpen]); //初期化

  useEffect(() => {
    // 開閉で必ず更新する
    if (toOpen === false) {
      setIsPassDigOpen(false);
    } else {
      getAccountInfo();
    }
    console.log("開閉状態" + toOpen);
  }, [toOpen]);

  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    switch (name) {
      case "password":
        setNewPassword(value);
        break;
      case "passwordConfirm":
        setPasswordConfirm(value);
        break;
    }
  }

  // アカウント情報の取得
  function getAccountInfo() {
    // console.log("アカウント情報の取得")
    axios
      .get(GET_ACCOUNT_INFO)
      .then((res) => {
        // アカウント情報を取得
        setError(false);
        setAccountInfo(res.data);
      })
      .catch((err) => {
        if (err.code === "ERR_NETWORK") {
          setTitle("network_err");
          setMessage("network_err_message");
          setError(true);
        } else if (err.response.status === 401) {
          setTitle("session_err");
          setMessage("session_err_message");
          setError(true);
        }
      });
  }

  // アカウント情報の取得
  function setPassword() {
    axios
      .post(SET_ACCOUNT_INFO, newPassword)
      .then((res) => {
        if (res.data.length != 0) {
          // アカウント情報を取得
          setAccountInfo(res.data[0]);
        } else {
          // アカウント情報なし
        }
      })
      .catch((error) => {
        // TODO エラー対応
      });
  }

  const SaveClose = () => {
    // パスワードの変更
    const tempInputErrors = Object.assign({}, initErrorInput);
    const safePasswordStr = /^[a-zA-Z\d]+$/;
    const safeUpperStr = /[A-Z]/;
    const safelowerStr = /[a-z]/;
    const safeNumberStr = /[\d]/;
    let error = false;
    if (
      newPassword == "" ||
      newPassword.length < 12 ||
      newPassword == accountInfo.userId ||
      newPassword != passwordConfirm ||
      !safeUpperStr.test(newPassword) ||
      !safelowerStr.test(newPassword) ||
      !safeNumberStr.test(newPassword) ||
      !safePasswordStr.test(newPassword)
    ) {
      error = true;
      tempInputErrors.password = true;
      tempInputErrors.conPassword = true;
      setInputError(tempInputErrors);
    }

    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else if (error === false) {
      axios
        .post(SET_ACCOUNT_INFO, { password: newPassword })
        .then((response) => {
          //console.log("アップデートしました。", response.data.result);
          setError(false);
          setJobClear(true);
          setDialogMessage(languageContext.words.password_updated_success);
          setDigOpen(true);
        })
        .catch((err) => {
          if (err.code === "ERR_NETWORK") {
            setTitle("network_err");
            setMessage("network_err_message");
            setError(true);
          } else if (err.response.status === 401) {
            setTitle("session_err");
            setMessage("session_err_message");
            setError(true);
          } else {
            setError(false);
            setDialogMessage(languageContext.words.password_updated_failed);
            setDigOpen(true);
          }
        });
    }
  };
  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomModalViewOnly
      title={languageContext.words.account_setting}
      open={toOpen}
      onClose={() => HandleClose()}
      btnText="OK"
    >
      {error ? (
        <ConnectionErrorDisplay />
      ) : (
        <Stack
          sx={{ width: "550px", height: "417px" }}
          spacing={0}
          direction="column"
        >
          <Box height={"58.13px"}>
            <Typography sx={{ paddingTop: "8px", fontSize: "15px" }}>
              {languageContext.words.user_name}
            </Typography>
            <Typography sx={{ color: "gray", paddingTop: "8px" }}>
              {accountInfo.userName}
            </Typography>
          </Box>
          <Box height={"58.13px"}>
            <Typography sx={{ paddingTop: "8px" }}>
              {languageContext.words.user_id}
            </Typography>
            <Typography sx={{ color: "gray", paddingTop: "8px" }}>
              {accountInfo.userId}
            </Typography>
          </Box>
          <Box height={"58.13px"}>
            <Typography sx={{ paddingTop: "8px" }}>
              {languageContext.words.mail_address}
            </Typography>
            <Typography sx={{ color: "gray", paddingTop: "8px" }}>
              {accountInfo.mail}
            </Typography>
          </Box>
          <Box
            height={"36.5px"}
            style={{ display: isPassDigOpen == true ? "none" : undefined }}
          >
            <Button
              style={{ display: isPassDigOpen == true ? "none" : undefined }}
              onClick={() => setIsPassDigOpen(true)}
            >
              {languageContext.words.change_simple}
            </Button>
          </Box>
          <CustomBoxBig
            sx={{
              display: isPassDigOpen != true ? "none" : undefined,
              paddingTop: "8px",
            }}
          >
            <CustomModalTypography
              style={{
                display: isPassDigOpen != true ? "none" : undefined,
              }}
            >
              {languageContext.words.new_password}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="password"
              style={{
                display: isPassDigOpen != true ? "none" : undefined,
              }}
              value={newPassword}
              onChange={onChangeValue}
              name="password"
              inputProps={{
                maxLength: 64,
              }}
              error={inputError.password}
              helperText={
                languageContext.words.setting_required +
                languageContext.words.setting_available_characters_3
              }
              FormHelperTextProps={{
                sx: {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
                title:
                  languageContext.words.setting_required +
                  languageContext.words.setting_available_characters_3,
              }}
            ></CustomTextFieldBig>
          </CustomBoxBig>
          <CustomBoxBig
            sx={{ display: isPassDigOpen != true ? "none" : undefined }}
          >
            <CustomModalTypography
              style={{
                display: isPassDigOpen != true ? "none" : undefined,
              }}
            >
              {languageContext.words.new_password_confirmation}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="password"
              name="passwordConfirm"
              style={{
                display: isPassDigOpen != true ? "none" : undefined,
              }}
              value={passwordConfirm}
              onChange={onChangeValue}
              error={inputError.conPassword}
              helperText={languageContext.words.setting_required}
            ></CustomTextFieldBig>
          </CustomBoxBig>
          <div>
            <Button
              style={{
                display: isPassDigOpen != true ? "none" : undefined,
              }}
              onClick={() => SaveClose()}
            >
              {languageContext.words.change_simple}
            </Button>
            <Button
              style={{
                display: isPassDigOpen != true ? "none" : undefined,
              }}
              onClick={() => setIsPassDigOpen(false)}
            >
              {languageContext.words.cancel}
            </Button>
          </div>
        </Stack>
      )}
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomModalViewOnly>
  );
};

export default SetAccountInfo;
