import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Box from "@mui/material/Box";
import CustomModal from "../../common/customComponents/CustomModal";
import TextField from "@mui/material/TextField";
import { createAreaData } from "./0314_OneHourTestData";
import { CreateDataByDay } from "./0314_OneHourTestData";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import { DateContext } from "../../common/customComponents/GlobalDatePicker";
import Grid from "@mui/material/Grid";
import { LanguageContext } from "../../common/localization/localization";
import { BidingCell } from "../../../../../backend/types/jepx/OneHourAdvanceBidPlan";
import axios, { AxiosError } from "axios";
import { TimeTable } from "./CreateTime";
import { PaletteContext } from "../../common/paletteMode";
import { useAuthedApi } from "../../../common/axios";

//エリアと入札日のグリッドのコンテナ
const GetGridConStyle = () => {
  const { PaletteMode } = useContext(PaletteContext);
  return PaletteMode === "dark" ? "#2e2e2e" : "rgb(245, 245, 245)";
};
const GridContainer = styled(Grid)`
  && {
    padding: 10px;
    border-radius: 5px;
    background-color: ${GetGridConStyle};
  }
`;

//販売種別、入札価格、入札量のグリッドのコンテナ
const InputGridContainer = styled(Grid)`
  && {
    border-radius: 5px;
  }
`;

//アイテムグリッド
const ItemGrid = styled(Grid)`
  && {
    width: 100%;
    font-size: 14px;
  }
`;

//内容を表示するグリッド
const TitleGrid = styled(ItemGrid)`
  && {
    color: rgb(150, 150, 150);
  }
`;

//内容を表示するグリッド
const ContentGrid = styled(ItemGrid)`
  && {
    font-weight: 700;
  }
`;

const POST_INTRADAY_BID = "/postIndividualBid"; //入札値登録
const INTRADAY_BIDDING = "/bidIntraday"; //入札実行

// 入札制限
export interface bitLimmitReq {
  area: number;
  // target: number, // 売り or 買い
  dayLimitBit: bitLimitCells[];
}

// 1日の48コマ(制限)
export interface bitLimitCells {
  type: number;
  cells: bitLimitCell[];
}

// 1コマ(制限)
export interface bitLimitCell {
  upperPowerVolume: string;
  lowerPowerVolume: string;
  upperPowerPrice: string;
  lowerPowerPrice: string;
}

//個別入札のモーダル
const Bid = (
  open: boolean,
  HaldleClose: () => void,
  clickButtonNumber: number,
  limit: bitLimmitReq,
  areaName: string,
  areaId: string,
  targetDate: string,
  setDigOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setDialogMessage: React.Dispatch<React.SetStateAction<string>>,
  getIntradayData: () => Promise<void>,
  dayBid: BidingCell[],
  createToolTipText: (
    lowerEnd: string,
    upperEnd: string,
    unit: string
  ) => string
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  const api = useAuthedApi();

  //30分刻みの時間の文字列の配列
  const timeData = TimeTable();

  //販売種別のセレクトボックス
  const [tradingType, setTradingType] = React.useState<string>("");
  const [tradingTypeId, setTradingTypeId] = React.useState<number>(1);
  //入札価格のテキストフィールド
  const [inputBidPrice, setInputBidPrice] = React.useState<string>(
    // limit?.dayLimitBit[1].cells[clickButtonNumber].lowerPowerPrice
    dayBid[clickButtonNumber].bidPrice.toFixed(2)
  );
  // let holdBidPriceValue: number;
  //入札量のテキストフィールド
  const [inputBidAmount, setInputBidAmount] = React.useState<string>(
    // limit?.dayLimitBit[1].cells[clickButtonNumber].lowerPowerVolume
    dayBid[clickButtonNumber].bidValue.toFixed(2)
  );
  // let holdBidAmountValue: number;

  //入札価格のテキストフィールドの最低入力値
  const [minInputBidPrice, setMinInputBidPrice] = React.useState<number>(
    Number(limit?.dayLimitBit[1].cells[clickButtonNumber].lowerPowerPrice)
  );
  //入札価格のテキストフィールドの最大入力値
  const [maxInputBidPrice, setMaxInputBidPrice] = React.useState<number>(
    Number(limit?.dayLimitBit[1].cells[clickButtonNumber].upperPowerPrice)
  );
  //入札量のテキストフィールドの最低入力値
  const [minInputBidAmount, setMinInputBidAmount] = React.useState<number>(
    Number(limit?.dayLimitBit[1].cells[clickButtonNumber].lowerPowerVolume)
  );
  //入札量のテキストフィールドの最大入力値
  const [maxInputBidAmount, setMaxInputBidAmount] = React.useState<number>(
    Number(limit?.dayLimitBit[1].cells[clickButtonNumber].upperPowerVolume)
  );

  //入札値が制限内か保持する
  const whithinRangeValue = React.useRef<boolean>(true);
  //入札実行下かのフラグ
  const executeFlag = React.useRef<boolean>(false);

  useEffect(() => {
    resetData(1);
  }, [open]);

  const resetData = (tradingTypeId: number) => {
    if (tradingTypeId === 1) {
      setInputBidPrice(
        // limit?.dayLimitBit[1].cells[clickButtonNumber].lowerPowerPrice
        dayBid[clickButtonNumber].bidPrice.toFixed(2)
      );
      setInputBidAmount(
        // limit?.dayLimitBit[1].cells[clickButtonNumber].lowerPowerVolume
        dayBid[clickButtonNumber].bidValue.toFixed(2)
      );
      setTradingType(languageContext.words.sell);
      setMinInputBidPrice(
        Number(limit?.dayLimitBit[1].cells[clickButtonNumber].lowerPowerPrice)
      );
      setMaxInputBidPrice(
        Number(limit?.dayLimitBit[1].cells[clickButtonNumber].upperPowerPrice)
      );
      setMinInputBidAmount(
        Number(limit?.dayLimitBit[1].cells[clickButtonNumber].lowerPowerVolume)
      );
      setMaxInputBidAmount(
        Number(limit?.dayLimitBit[1].cells[clickButtonNumber].upperPowerVolume)
      );
    } else {
      setInputBidPrice(
        // limit?.dayLimitBit[0].cells[clickButtonNumber].lowerPowerPrice
        dayBid[clickButtonNumber].bidPrice.toFixed(2)
      );
      setInputBidAmount(
        // limit?.dayLimitBit[0].cells[clickButtonNumber].lowerPowerVolume
        dayBid[clickButtonNumber].bidValue.toFixed(2)
      );
      setTradingType(languageContext.words.buy);
      setMinInputBidPrice(
        Number(limit?.dayLimitBit[0].cells[clickButtonNumber].lowerPowerPrice)
      );
      setMaxInputBidPrice(
        Number(limit?.dayLimitBit[0].cells[clickButtonNumber].upperPowerPrice)
      );
      setMinInputBidAmount(
        Number(limit?.dayLimitBit[0].cells[clickButtonNumber].lowerPowerVolume)
      );
      setMaxInputBidAmount(
        Number(limit?.dayLimitBit[0].cells[clickButtonNumber].upperPowerVolume)
      );
    }
  };

  //販売種別のセレクトボックス
  const TradingTypeHandleChange = (selectItem: number) => {
    setTradingTypeId(selectItem);
    resetData(selectItem);
  };

  //入札価格のセレクトボックス
  const BidPriceHandle = (inputValue: string) => {
    setInputBidPrice(inputValue.replace(/-/g, ""));
  };

  const dispDialog = () => {
    setDialogMessage(
      languageContext.words.outside_bid_limit +
        "\n" +
        languageContext.words.reconsider_bidding
    );
    setDigOpen(true);
  };

  const handleOutBidPrice = (
    inputValue: string,
    lower: number,
    upper: number
  ) => {
    processText(inputBidPrice, setInputBidPrice);
    if (lower > Number(inputValue) && Number(inputValue) !== 0) {
      whithinRangeValue.current = false;
      dispDialog();
    } else if (upper < Number(inputValue) && Number(inputValue) !== 0) {
      whithinRangeValue.current = false;
      dispDialog();
    } else {
      whithinRangeValue.current = true;
    }
  };

  //入札量のセレクトボックス
  const BidAmountHandle = (inputValue: string) => {
    setInputBidAmount(inputValue.replace(/-/g, ""));
  };

  const handleOutBidAmount = (
    inputValue: string,
    lower: number,
    upper: number
  ) => {
    let tempText = inputBidAmount;
    tempText = (Math.floor(Number(tempText) * 10) / 10).toFixed(2);
    setInputBidAmount(tempText);
    if (lower > Number(inputValue) && Number(inputValue) !== 0) {
      whithinRangeValue.current = false;
      dispDialog();
    } else if (upper < Number(inputValue) && Number(inputValue) !== 0) {
      whithinRangeValue.current = false;
      dispDialog();
    } else {
      whithinRangeValue.current = true;
    }
  };

  // フォーカスアウト時にテキストフィールドに表示している値をtoFixedする
  const processText = (
    text: string,
    setText: React.Dispatch<React.SetStateAction<string>>
  ) => {
    let tempText = text;
    tempText = Number(text).toFixed(2);
    setText(tempText);
  };

  //datePecker
  const dateValue = useContext(DateContext);

  //入札日の出力とnullチェックを行う
  const CreateBidDate = () => {
    let bidDate = "Date get error";
    if (dateValue != null) {
      bidDate = languageContext.convertDateToLocalizedStringPoorAcu(
        dateValue.pickDate as Date
      ) as string;
    }
    return bidDate;
  };

  //売買種別IDを作成
  const createBuySellNumber = (type: string) => {
    let typeNum = 1;
    if (type === languageContext.words.sell) {
      typeNum = 1;
    } else if (type === languageContext.words.buy) {
      typeNum = 2;
    }

    return typeNum;
  };

  //DBに入札情報を登録する
  const postIndividualBid = async () => {
    await api
      .post(POST_INTRADAY_BID, {
        targetDate: targetDate,
        areaId: areaId,
        itemId: (clickButtonNumber + 1).toString().padStart(2, "0"),
        buysellKind: createBuySellNumber(tradingType),
        orderPrice: inputBidPrice,
        orderQu: inputBidAmount,
      })
      .then((res) => {
        // console.log(res);
        bidIntraday();
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.unable_bid);
        setDigOpen(true);
      });
    return "postBidData";
  };

  //一時間前入札APIを呼び出す
  const bidIntraday = async () => {
    await api
      .get(INTRADAY_BIDDING, {
        params: {
          targetDate: targetDate,
          areaId: areaId,
          itemId: (clickButtonNumber + 1).toString().padStart(2, "0"),
        },
      })
      .then((res) => {
        setDialogMessage(languageContext.words.execute_bidding);
        setDigOpen(true);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 504) {
          setDialogMessage(languageContext.words.could_not_communicate_jepx);
        } else {
          setDialogMessage(languageContext.words.unable_bid);
        }
        setDigOpen(true);
      });
    await getIntradayData();
  };

  //モーダルを閉じる処理
  const ModalCloseEvent = () => {
    if (whithinRangeValue.current || executeFlag.current === false) {
      HaldleClose();
    } else {
      dispDialog();
    }
    executeFlag.current = false;
  };

  //入札実行ボタン
  const BidButtonEvent = async () => {
    executeFlag.current = true;
    if (
      whithinRangeValue.current &&
      Number(inputBidAmount) !== 0 &&
      Number(inputBidPrice) !== 0
    ) {
      await postIndividualBid();
    } else {
      dispDialog();
    }
    // bidIntraday(); // todo {kushibiki} axios.thenの中でAPIの呼び出しを行わないとエラーで情報を登録出来ていない場合でもAPIが不通に呼ばれてしまうただ、普通にthenの中に書いてもAPIの呼び出しが行われていないような挙動になる
  };

  //入札
  return (
    <CustomModal
      title={languageContext.words.new_bid}
      open={open}
      onAccept={() => BidButtonEvent()}
      onClose={ModalCloseEvent}
      btnText={languageContext.words.bid_execution}
    >
      <Box sx={{ height: "150px", width: "600px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <GridContainer container>
              <TitleGrid item>{languageContext.words.area}</TitleGrid>
              <ContentGrid item>{areaName}</ContentGrid>
            </GridContainer>
          </Grid>
          <Grid item xs={3} sx={{ alignItems: "center" }}>
            <GridContainer container>
              <TitleGrid item>
                {languageContext.words.bidding_date_and_time}
              </TitleGrid>
              <ContentGrid item>{CreateBidDate()}</ContentGrid>
            </GridContainer>
          </Grid>
          <Grid item xs={3}>
            <GridContainer container>
              <TitleGrid item>
                {languageContext.words.bidding_time_zone}
              </TitleGrid>
              <ContentGrid item>
                {timeData[clickButtonNumber] +
                  "～" +
                  timeData[clickButtonNumber + 1]}
              </ContentGrid>
            </GridContainer>
          </Grid>
          <Grid item xs={4} sx={{ alignItems: "center" }}>
            <InputGridContainer container>
              <TitleGrid item>{languageContext.words.salebuy_type}</TitleGrid>
              <Grid item sx={{ margin: "-8px" }}>
                <CustomSelectList
                  label={languageContext.words.salebuy_type}
                  value={tradingTypeId}
                  options={[
                    { id: 1, name: languageContext.words.sell },
                    { id: 2, name: languageContext.words.buy },
                  ]}
                  onChange={TradingTypeHandleChange}
                />
              </Grid>
            </InputGridContainer>
          </Grid>
          <Grid item xs={4} sx={{ alignItems: "center" }}>
            <InputGridContainer container>
              <TitleGrid item>
                {languageContext.words.bid_price}(
                {languageContext.words.yen_kwh})
              </TitleGrid>
              <Grid item>
                <TextField
                  title={createToolTipText(
                    limit?.dayLimitBit[
                      tradingType === languageContext.words.sell ? 1 : 0
                    ].cells[clickButtonNumber].lowerPowerPrice,
                    limit?.dayLimitBit[
                      tradingType === languageContext.words.sell ? 1 : 0
                    ].cells[clickButtonNumber].upperPowerPrice,
                    languageContext.words.yen_kwh
                  )}
                  type="number"
                  sx={{ width: "180px" }}
                  InputProps={{
                    inputProps: {
                      step: "0.01",
                      min: minInputBidPrice,
                      max: maxInputBidPrice,
                      style: { padding: "8.5px 14px", textAlign: "right" },
                    },
                  }}
                  value={inputBidPrice}
                  onFocus={(e) => {
                    if (inputBidPrice === "0.00") {
                      e.currentTarget.select();
                    }
                  }}
                  onBlur={(event) =>
                    handleOutBidPrice(
                      event.target.value,
                      minInputBidPrice,
                      maxInputBidPrice
                    )
                  }
                  onKeyDown={(event) => {
                    if (
                      event.key === "e" ||
                      event.key === "E" ||
                      event.key === "-"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(event) => BidPriceHandle(event.target.value)}
                ></TextField>
              </Grid>
            </InputGridContainer>
          </Grid>
          <Grid item xs={4} sx={{ alignItems: "center" }}>
            <InputGridContainer container>
              <TitleGrid item>
                {languageContext.words.bidding_volume}(MWh/h)
              </TitleGrid>
              <Grid item>
                <TextField
                  title={createToolTipText(
                    limit?.dayLimitBit[
                      tradingType === languageContext.words.sell ? 1 : 0
                    ].cells[clickButtonNumber].lowerPowerVolume,
                    limit?.dayLimitBit[
                      tradingType === languageContext.words.sell ? 1 : 0
                    ].cells[clickButtonNumber].upperPowerVolume,
                    "MWh/h"
                  )}
                  type="number"
                  sx={{
                    width: "180px",
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: maxInputBidAmount,
                      step: "0.1",
                      style: { padding: "8.5px 14px", textAlign: "right" },
                    },
                  }}
                  value={inputBidAmount}
                  onFocus={(e) => {
                    if (inputBidAmount === "0.00") {
                      e.currentTarget.select();
                    }
                  }}
                  onBlur={(event) =>
                    handleOutBidAmount(event.target.value, 0, maxInputBidAmount)
                  }
                  onKeyDown={(event) => {
                    if (
                      event.key === "e" ||
                      event.key === "E" ||
                      event.key === "-"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(event) => BidAmountHandle(event.target.value)}
                ></TextField>
              </Grid>
            </InputGridContainer>
          </Grid>
        </Grid>
      </Box>
    </CustomModal>
  );
};
export default Bid;
