import React, { useState, useContext, createContext, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addDays, parse, subDays, format, isValid } from "date-fns";
import ja from "date-fns/locale/ja";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Popover from "@mui/material/Popover";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { StyledButton } from "../../common/styledComponents/styledButton";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Typography from "@mui/material/Typography";
import { LanguageContext } from "../localization/localization";
import { PaletteContext } from "../paletteMode";
import {
  ConfirmLeavePage,
  LeavePageContext,
  LeavePageContextType,
} from "./CustomConfirmLeavePage";
import { PickersDay } from "@mui/x-date-pickers";

type dataContextType = {
  pickDate: Date | null;
  setPickDate: (pickDate: React.SetStateAction<Date | null>) => void;
};
export const DateContext = createContext<dataContextType>({
  pickDate: addDays(new Date(), 1),
  setPickDate: (pickDate) => null,
});

export const DateContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [pickDate, setPickDate] = useState<Date | null>(addDays(new Date(), 1));

  return (
    <DateContext.Provider value={{ pickDate, setPickDate }}>
      {children}
    </DateContext.Provider>
  );
};

// グローバルDatePicker
export const GlobalDatePicker = (props: any) => {
  const { isDipsTodayBtn, maxDate, minDate } = props;

  const languageContext = useContext(LanguageContext);
  const { PaletteMode } = useContext(PaletteContext);
  const { blockLeave, setBlockLeave } =
    useContext<LeavePageContextType>(LeavePageContext);
  const { pickDate, setPickDate } = useContext<dataContextType>(DateContext);

  const [digOpen, setDigOpen] = useState(false);
  const [changeDate, setChangeDate] = useState<Date>(pickDate as Date);

  useEffect(() => {
    if (pickDate) {
      setPickDate(pickDate);
    } else {
      setPickDate(new Date());
    }
  }, [pickDate, setPickDate]);

  const handleChange = (newValue: Date | null) => {
    if (isValid(newValue)) {
      if (blockLeave) {
        setDigOpen(true);
      } else {
        setChangeDate(newValue as Date);
        setPickDate(newValue as Date);
        setBlockLeave(false);
      }
    }
  };

  const acceptHandler = () => {
    setPickDate(changeDate);
    setBlockLeave(false);
  };

  const isAddBtnDisabled = () => {
    let disabled = false;
    if (maxDate !== undefined) {
      if (pickDate?.toLocaleDateString() === maxDate.toLocaleDateString()) {
        disabled = true;
      }
    }
    return disabled;
  };

  const isSubBtnDisabled = () => {
    let disabled = false;
    if (minDate !== undefined) {
      if (pickDate?.toLocaleDateString() === minDate.toLocaleDateString()) {
        disabled = true;
      }
    }
    return disabled;
  };

  return (
    <>
      <Stack
        direction="row"
        width="fit-content"
        alignItems="center"
        sx={{ margin: "0px" }}
        spacing={0.5}
      >
        <Button
          sx={{
            color: `${PaletteMode === "dark" ? "white" : "black"}`,
            height: "40px",
            minWidth: "25px",
            margin: "0px",
            padding: "0px",
          }}
          disabled={isSubBtnDisabled()}
          onClick={() => {
            const tempSub =
              pickDate === null ? addDays(new Date(), 1) : (pickDate as Date);
            setChangeDate(subDays(tempSub, 1));
            if (blockLeave) {
              setDigOpen(true);
            } else {
              setPickDate(subDays(tempSub, 1));
              setBlockLeave(false);
            }
          }}
        >
          <ArrowLeftIcon />
        </Button>
        <Box
          sx={{
            display: "flex",
            width: "160px",
            borderRadius: "5px",
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={languageContext.mode === "jp" ? ja : undefined}
            dateFormats={
              languageContext.mode === "jp"
                ? { monthAndYear: "yyyy年 MM月" }
                : undefined
            }
          >
            <DatePicker
              minDate={minDate}
              maxDate={maxDate}
              value={pickDate}
              onChange={handleChange}
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{
                    width: "100%",
                  }}
                  {...params}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              renderDay={(day, selectedDays, pickersDayProps) => (
                <PickersDay
                  {...pickersDayProps}
                  sx={{ backgroundColor: "inherit" }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Button
          sx={{
            color: `${PaletteMode === "dark" ? "white" : "black"}`,
            height: "40px",
            minWidth: "25px",
            margin: "0px",
            padding: "0px",
            variant: "outline",
          }}
          disabled={isAddBtnDisabled()}
          onClick={() => {
            const tempAdd =
              pickDate === null ? addDays(new Date(), 1) : (pickDate as Date);
            setChangeDate(addDays(tempAdd, 1));
            if (blockLeave) {
              setDigOpen(true);
            } else {
              setPickDate(addDays(tempAdd, 1));
              setBlockLeave(false);
            }
          }}
        >
          <ArrowRightIcon />
        </Button>
        {"　"}
        <StyledButton
          sx={{
            height: "40px",
            minWidth: "40px",
            display: isDipsTodayBtn ? "inline" : "none",
          }}
          onClick={() => {
            setChangeDate(new Date());
            if (blockLeave) {
              setDigOpen(true);
            } else {
              setPickDate(new Date());
              setBlockLeave(false);
            }
          }}
        >
          {languageContext.words.today}
        </StyledButton>
      </Stack>
      <ConfirmLeavePage
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
        message={languageContext.words.date_chg_msg}
      ></ConfirmLeavePage>
    </>
  );
};

// ステータス一覧画面専用
export const GlobalSkeletonDatePicker = () => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  const { pickDate, setPickDate } = useContext<dataContextType>(DateContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const { PaletteMode } = useContext(PaletteContext);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (newValue: Date | null) => {
    setPickDate(newValue as Date);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (pickDate) {
      setPickDate(pickDate);
    } else {
      setPickDate(new Date());
    }
  }, [pickDate, setPickDate]);

  return (
    <Stack
      direction="row"
      width="fit-content"
      alignItems="center"
      sx={{ margin: "0px" }}
      spacing={0.5}
    >
      <Button
        sx={{
          color: `${PaletteMode === "dark" ? "white" : "black"}`,
          height: "40px",
          minWidth: "25px",
          margin: "0px",
          padding: "0px",
        }}
        onClick={() => {
          const tempSub =
            pickDate === null ? addDays(new Date(), 1) : (pickDate as Date);
          setPickDate(subDays(tempSub, 1));
        }}
      >
        <KeyboardArrowLeftIcon />
      </Button>
      <Typography variant="h5">
        {languageContext.mode === "jp"
          ? format(pickDate as Date, "yyyy/MM/dd")
          : format(pickDate as Date, "MM/dd/yyyy")}
      </Typography>
      <Button
        sx={{
          color: `${PaletteMode === "dark" ? "white" : "black"}`,
          height: "40px",
          minWidth: "25px",
          margin: "0px",
          padding: "0px",
        }}
        onClick={() => {
          const tempAdd =
            pickDate === null ? addDays(new Date(), 1) : (pickDate as Date);
          setPickDate(addDays(tempAdd, 1));
        }}
      >
        <KeyboardArrowRightIcon />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={languageContext.mode === "jp" ? ja : undefined}
          dateFormats={
            languageContext.mode === "jp"
              ? { monthAndYear: "yyyy年 MM月" }
              : undefined
          }
        >
          <StaticDatePicker
            value={pickDate}
            onChange={handleChange}
            displayStaticWrapperAs="desktop"
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Popover>
      {"　"}
      <StyledButton
        sx={{ height: "40px", minWidth: "40px" }}
        onClick={handleClick}
      >
        <CalendarTodayIcon />
      </StyledButton>
      {"　"}
      <StyledButton
        sx={{ height: "40px", minWidth: "40px" }}
        onClick={() => setPickDate(new Date())}
      >
        {languageContext.words.today}
      </StyledButton>
    </Stack>
  );
};
