import { useCallback, useContext, useMemo } from "react";
import { LanguageContext } from "../views/common/localization/localization";
import { SALE_KIND } from "./constant";

export const useSaleKindUtility = () => {
  const { words } = useContext(LanguageContext);

  const saleKindNames = useMemo(
    () => [
      {
        id: SALE_KIND.NONE,
        name: words.setting_unselected,
      },
      {
        id: SALE_KIND.SELF_CONSIGMENT,
        name: words.internal_self_consignment,
      },
      { id: SALE_KIND.SPOT, name: words.spot },
      { id: SALE_KIND.INTRADAY, name: words.hour_ago },
    ],
    [words]
  );

  const saleKindOptions = useCallback(
    (saleKinds: string[], unselectable = true) => {
      return saleKindNames.filter(
        (v) =>
          saleKinds.find((e) => e === v.id) ||
          (v.id === SALE_KIND.NONE && unselectable)
      );
    },
    [saleKindNames]
  );

  const convertIdToSaleKindName = useCallback(
    (id: string) => {
      return saleKindNames.find((v) => v.id === id)?.name;
    },
    [saleKindNames]
  );

  return { saleKindOptions, convertIdToSaleKindName };
};
