import React, { createContext, useState } from "react";

type AreaContextType = {
  areaId: string;
  setAreaId: (areaId: React.SetStateAction<string>) => void;
  initAreaContext: () => void;
};

export const AreaContext = createContext<AreaContextType>({
  areaId: "",
  setAreaId: (areaId) => null,
  initAreaContext: () => null,
});

export const AreaContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [areaId, setAreaId] = useState<string>("00");

  const initAreaContext = () => {
    setAreaId("00");
  };

  return (
    <AreaContext.Provider value={{ areaId, setAreaId, initAreaContext }}>
      {children}
    </AreaContext.Provider>
  );
};
