import React, { useContext, useRef, useState } from "react";
import Button from "@mui/material/Button";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import styled from "styled-components";
import { StyledButton } from "../styledComponents/styledButton";
import { LanguageContext } from "../localization/localization";

interface Props {
  message: string;
  onAccept: () => void;
}

const DownloadReportButton = (props: Props) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  const { message, onAccept } = props;
  const [digOpen, setDigOpen] = useState(false);

  //ファイルをダウンロードする挙動のみ実装
  const acceptHandler = () => {
    //DBからファイルをもらう
    const downloadData = new Blob(["hello", " ", "world"], {
      type: "text/plain",
    });

    onAccept();
    //一時的にリンクとa要素を生成してダウンロードする
    //複数ファイル貰うパターンはzipする
    const url = URL.createObjectURL(downloadData);
    const link = document.createElement("a");
    link.download = "download.txt";
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
  };

  return (
    <React.Fragment>
      <CustomDialog
        title={languageContext.words.download_form}
        message={message}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
      <StyledButton variant="outlined" onClick={() => setDigOpen(true)}>
        {languageContext.words.download}
      </StyledButton>
    </React.Fragment>
  );
};

//移行前実装済みの保存ボタン用デフォルト引数
//移行後削除
DownloadReportButton.defaultProps = {
  message: "作成中",
  onAccept: () => console.log("onAccept"),
};

export default DownloadReportButton;
