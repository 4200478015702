import { useCallback, useContext } from "react";
import { LanguageContext } from "../views/common/localization/localization";
import { POWER_ENERGY_KIND } from "./constant";

export const usePowerPlantUtility = () => {
  const { words } = useContext(LanguageContext);

  const energyOptions = useCallback(
    (areas: string[]) => {
      const energies = [
        { id: POWER_ENERGY_KIND.NONE, name: words.setting_unselected },
        { id: POWER_ENERGY_KIND.THERMAL, name: words.energy_thermal },
        { id: POWER_ENERGY_KIND.HYDRO, name: words.energy_hydro },
        { id: POWER_ENERGY_KIND.SOLAR, name: words.energy_solar },
        { id: POWER_ENERGY_KIND.SMALL_HYDRO, name: words.energy_small_hydro },
        { id: POWER_ENERGY_KIND.WIND, name: words.energy_wind },
        { id: POWER_ENERGY_KIND.BINARY, name: words.energy_binary },
        { id: POWER_ENERGY_KIND.BIOMASS, name: words.energy_biomass },
      ];

      return energies.filter(
        (v) => areas.find((e) => e === v.id) || v.id === POWER_ENERGY_KIND.NONE
      );
    },
    [words]
  );

  const convertIdToEnergyName = useCallback(
    (id: string) => {
      const energyNames = [
        words.energy_thermal,
        words.energy_hydro,
        words.energy_solar,
        words.energy_small_hydro,
        words.energy_wind,
        words.energy_binary,
        words.energy_biomass,
      ];
      const index = parseInt(id) - 1;
      return energyNames[index] ? energyNames[index] : "undefined";
    },
    [words]
  );

  return {
    energyOptions,
    convertIdToEnergyName,
  };
};
