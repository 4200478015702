import React, { useState, useEffect, useContext } from "react";
import "./css/SetPowerPlantList.css";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { padding, width } from "@mui/system";
import axios from "axios";
import CustomModal from "../common/customComponents/CustomModal";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import CustomDatePickerTryal from "../common/customComponents/CustomDatePickerTryal";
import { LanguageContext } from "../common/localization/localization";
import {
  resPannelDownwardTypeList,
  resPannelTypeList,
  resPowerBgInfo,
  setPowerPlantInfo as setPowerPlant,
  setProrataData,
} from "../../types/master/SetPowerPlantList";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import { addDays, format, subDays } from "date-fns";
import { resAreaList } from "../../types/common/Search";
import { TimeTable } from "../jepx/OneHourAdvanceBid/CreateTime";
import { modalMode } from "./PowerPlantList";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import { tempBitInfo } from "../jepx/BiddingScreen/BiddingScreen_data";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import CustomSelectListBig from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorBig";
import CustomModalDatePicker from "../common/customComponents/CustomMasterCompornent/CustomMstDatePicker";
import { useAreaUtility } from "../../common/area";
import { usePowerSupplyTypeUtility } from "../../common/powerSupplyType";
import { usePowerPlantUtility } from "../../common/powerEnergy";
import {
  API_URL,
  POWER_ENERGY_KIND,
  POWER_SUPPLY_TYPE_ID,
} from "../../common/constant";
import { useAuthedApi } from "../../common/axios";

//30分刻みの時間の文字列の配列
const timeData = TimeTable();

type powerInfo = {
  powerId: string;
  powerName: string;
  area: number;
  powerBg: string;
  constarctPower: number;
  maxPower: number;
  minPower: number;
  plantCode: number;
  planPowerName: string;
  contractCode2: number;
  latitude: string;
  longitude: string;
  powerTargetNumber: number;
  predictCoefficient: number;
  acOutput: number;
  panelTilt: number;
  panelAzimuth: number;
  panelPowerDecline: number;
};

const CustomTextField = styled(TextField)(() => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "auto",
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
}));

//発電方式
const powerEnergyKind = [
  POWER_ENERGY_KIND.NONE,
  POWER_ENERGY_KIND.THERMAL,
  POWER_ENERGY_KIND.HYDRO,
  POWER_ENERGY_KIND.SOLAR,
  POWER_ENERGY_KIND.SMALL_HYDRO,
  POWER_ENERGY_KIND.WIND,
  POWER_ENERGY_KIND.BINARY,
  POWER_ENERGY_KIND.BIOMASS,
];

const initProrataList: setProrataData[] = [];
for (let i = 0; i < 48; i++) {
  initProrataList.push({ priority: 0, prorateNum: 0 });
} //初期化用

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  plantName: boolean;
  areaId: boolean;
  powerBgId: boolean;
  generationKindNum: boolean;
  rootCode: boolean;
  powerPointNumber: boolean;
  pannelType: boolean;
  sunPannel: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  plantName: false,
  areaId: false,
  powerBgId: false,
  generationKindNum: false,
  rootCode: false,
  powerPointNumber: false,
  pannelType: false,
  sunPannel: false,
};

const SetPowerPlantList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  plantIdEdit: number
) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  // 通信エラー
  const api = useAuthedApi();

  //エリアIDをエリア名に変換
  const { convertIdToAreaName } = useAreaUtility();
  //電源種別IDと電源種別nameのリスト
  const { supplyTypeOptions } = usePowerSupplyTypeUtility();
  //発電方式のIDとnameのリスト
  const { energyOptions } = usePowerPlantUtility();

  type areaInfo = { id: string; name: string };

  //発電所種別
  type powerPlantKind = {
    name: string; //種類
    id: number; //番号
  };

  //発電所種別
  type generateWayKind = {
    name: string; //種類
    id: number; //番号
  };
  type powerBgInfo = {
    id: number;
    name: string;
    powerBgId: number;
    areaId: string;
    areaName: string;
  };
  type pannelInfo = { id: number; name: string };

  const areaInfos = React.useRef<areaInfo[]>([
    { id: "00", name: "未選択" }, //初期表示用
  ]);
  const plantKindInfos = React.useRef<powerPlantKind[]>([
    { id: 0, name: "未選択" }, //初期表示用
  ]);

  const generateWayInfos = React.useRef<powerPlantKind[]>([
    { id: 0, name: "未選択" }, //初期表示用
  ]);

  const powerBgInfos = React.useRef<powerBgInfo[]>([
    { id: 0, name: "", powerBgId: 0, areaId: "00", areaName: "" },
  ]);

  const pannelTypeInfos = React.useRef<pannelInfo[]>([
    { id: 0, name: "未選択" }, //初期表示用
  ]);

  const sunPannelInfos = React.useRef<pannelInfo[]>([
    { id: 0, name: "未選択" }, //初期表示用
  ]);

  //初期化用
  const initInfo: setPowerPlant = {
    powerId: 0, //PK
    plantName: "", //発電所名称
    areaId: "00", //エリア
    generationKindNum: 0, //発電方式
    powerBgId: 0, //所属発電BGId
    contrantPower: 0, //契約電力
    rootCode: "", //系統コード
    rootPlantName: "", //系統発電 所名称
    rootPlantCode2: "", //契約識別番号２
    remarks: "", //備考
    maxPower: 0, //最大出力
    powerCoefficient: "0", //発電係数
    powerPointNumber: "",
    pannelType: 0, // モジュール温度上昇にかかわるパネルタイプ
    temperatureCoe: 0, // 最大温度係数
    ratedOutput: "0.0000", // 定格出力
    downwardPannel: 0, // 太陽光パネル
    latitude: "0.000000",
    longitude: "0.000000",
    settingFlag: 0, //優先順位・プロラタ48コマ設定
    prorate: 0, //プロラタ
    priority: 0, //優先順位
    prorataList: initProrataList, //プロラタデータリスト
    startDate: format(new Date(), "yyyy/MM/dd"),
    finishDate: format(new Date(), "yyyy/MM/dd"),
    powerKind: 0,
    acOutput: "0.0000", // 定格出力(AC),
    panelTilt: 0,
    panelAzimuth: 0,
    panelPowerDecline: 0,
    panelCoe:0,
  };

  const [powerBgNo, setPowerBgNo] = useState<number>(0);
  const [powerPlantInfo, setPowerPlantInfo] = useState<setPowerPlant>(initInfo);

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [finishDate, setFinishDate] = useState<Date>(addDays(new Date(), 1));
  const [selectedValue, setSelectedValue] = useState("b");
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する
  const [selPowerBgArea, setSelPowerBgArea] = useState<string>("");

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化
  ////

  //開始datePicker
  useEffect(() => {
    const tmpInfo: setPowerPlant = Object.assign({}, powerPlantInfo);
    tmpInfo.startDate = format(startDate, "yyyy/MM/dd");
    setPowerPlantInfo(tmpInfo);
  }, [startDate]);

  ////終了datePicker
  useEffect(() => {
    const tmpInfo: setPowerPlant = Object.assign({}, powerPlantInfo);
    tmpInfo.finishDate = format(finishDate, "yyyy/MM/dd");
    setPowerPlantInfo(tmpInfo);
  }, [finishDate]);

  //ラジオボタン
  useEffect(() => {
    const tmpInfo: setPowerPlant = Object.assign({}, powerPlantInfo);
    tmpInfo.settingFlag = selectedValue === "a" ? 1 : 0;
    setPowerPlantInfo(tmpInfo);
  }, [selectedValue]);

  useEffect(() => {
    if (toOpen === true) {
      powerBgInfos.current[0] = {
        id: 0,
        name: languageContext.words.setting_unselected,
        powerBgId: 0,
        areaId: "00",
        areaName: "",
      };
      if (mode === modalMode.editMode) {
        getDataLinkedPlantId(plantIdEdit);
      } else {
        setPowerBgNo(0); //セレクターを初期値に
        setSelPowerBgArea("");
        setPowerPlantInfo(initInfo);
        setgenerateWaysNo(0);
        setplantKindNo(0);
        setStartDate(new Date());
        setFinishDate(addDays(new Date(), 1));
      }
    } else {
      setPowerBgNo(0); //セレクターを初期値に
      setSelPowerBgArea("");
      setPowerPlantInfo(initInfo);
      setPowerPlantInfo(initInfo);
      setplantKindNo(0);
      setgenerateWaysNo(0);
      setStartDate(new Date());
      setFinishDate(addDays(new Date(), 1));
      setInputError(initErrorInput);
    }
  }, [toOpen]); //初期化

  /////////エリア関連
  //データベースからエリアデータ取得
  useEffect(() => {
    // エリア一覧取得
    //console.log("エリアデータ取得開始");
    getAreaMst();
  }, []); //初期化

  const getAreaMst = () => {
    api
      .get(API_URL.GET_AREA_NAME)
      .then((response) => {
        //console.log("エリアマスタ取得開始");
        //console.log(response.data.result);
        areaInfos.current = areaInfos.current.concat(
          convertAreaData(response.data as resAreaList[])
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertAreaData = (result: resAreaList[]) => {
    const retAreaData: areaInfo[] = [];
    result.map((data) => {
      const areaData: areaInfo = { id: "", name: "" };
      areaData.id = data.areaId.toString();
      areaData.name = data.areaName;
      retAreaData.push(areaData);
      //console.log("エリア変換1データ");
      //console.log(JSON.stringify(areaData));
    });
    //console.log("エリア変換後データ");
    //console.log(JSON.stringify(retAreaData));
    return retAreaData;
  };

  function setAreaId(id: number) {
    //numberを文字列にしてコピーしたdemandListInfoのIDにセットする
    const areaIdString = ("00" + id).slice(-2).toString();
    const tmpAreaInfo: setPowerPlant = Object.assign({}, powerPlantInfo);
    tmpAreaInfo.areaId = areaIdString;

    setPowerPlantInfo(tmpAreaInfo);
  }

  /////////エリア関連

  function setPannelType(id: number) {
    //numberを文字列にしてコピーしたdemandListInfoのIDにセットする
    const tmpAreaInfo: setPowerPlant = Object.assign({}, powerPlantInfo);
    tmpAreaInfo.pannelType = id;

    setPowerPlantInfo(tmpAreaInfo);
  }

  function setSunPannelType(id: number) {
    //numberを文字列にしてコピーしたdemandListInfoのIDにセットする
    const tmpAreaInfo: setPowerPlant = Object.assign({}, powerPlantInfo);
    tmpAreaInfo.downwardPannel = id;

    setPowerPlantInfo(tmpAreaInfo);
  }

  /////発電所種別番号
  const [plantKindNo, setplantKindNo] = useState<number>(0);

  function SetPlantKinds(id: number) {
    const tmpContractorInfo: setPowerPlant = Object.assign({}, powerPlantInfo);
    tmpContractorInfo.powerKind = Number(Object.values(POWER_SUPPLY_TYPE_ID)[id]);
    setplantKindNo(id);
    setPowerPlantInfo(tmpContractorInfo);
  }

  //////発電所種別番号ここまで

  /////発電方法一式
  const [generateWaysNo, setgenerateWaysNo] = useState<number>(0);

  function SetPowerKind(id: number) {
    const tmpContractorInfo: setPowerPlant = Object.assign({}, powerPlantInfo);
    tmpContractorInfo.generationKindNum = Number(powerEnergyKind[id]);
    setgenerateWaysNo(id);
    // setSelContractantCode(tmpContractInfo[e].code);
    // setSelContractantNo(tmpContractInfo[e].id);

    setPowerPlantInfo(tmpContractorInfo);
  }

  /////発電方法一式

  ////////所属発電BG関連

  //データベースから所属発電BGデータ一覧取得
  useEffect(() => {
    // 所属発電BGデータ一覧取得
    getPowerBgMst();
    getPannelRiseType();
    getPannelDownwardType();
  }, []); //初期化

  // 太陽光パネル設置形態取得
  const getPannelRiseType = () => {
    api
      .get(API_URL.GET_PANNEL_RISE)
      .then((response) => {
        // console.log("太陽光パネル設置形態取得開始");
        const data = response.data as resPannelTypeList[];

        const pannel: pannelInfo[] = [];
        data.forEach((element) => {
          const el: pannelInfo = {
            id: element.id,
            name: element.type,
          };
          pannel.push(el);
        });

        pannelTypeInfos.current = pannel;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 太陽光パネル取得
  const getPannelDownwardType = () => {
    api
      .get(API_URL.GET_PANNEL_DOWNWARD)
      .then((response) => {
        // console.log("太陽光パネル取得開始");
        const data = response.data as resPannelDownwardTypeList[];
        const pannel: pannelInfo[] = [];
        data.forEach((element) => {
          const el: pannelInfo = {
            id: element.id,
            name: element.panel_type,
          };
          pannel.push(el);
        });

        sunPannelInfos.current = pannel;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPowerBgMst = () => {
    api
      .get(API_URL.GET_POWERBG_MST)
      .then((response) => {
        // console.log("所属発電BGマスタ取得開始");
        //console.log(response.data.result);
        powerBgInfos.current = powerBgInfos.current.concat(
          convertPowerBgSelecterDatas(response.data.result as resPowerBgInfo[])
        );
        //setSelContractantCode(contractorInfos.current[0].code)
        //setSelContractantName(contractorInfos.current[0].name)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(powerPlantInfo);

  const convertPowerBgSelecterDatas = (result: resPowerBgInfo[]) => {
    const retPowerBgData: powerBgInfo[] = [];
    result.map((data, index) => {
      const contractorData: powerBgInfo = {
        id: 0,
        name: "",
        powerBgId: 0,
        areaId: "00",
        areaName: "",
      };
      contractorData.id = index + 1; //0要素にはデフォルトのものが入るため
      contractorData.name = data.name;
      contractorData.powerBgId = data.powerBgId;
      contractorData.areaId = data.areaId;
      contractorData.areaName = data.areaName;

      retPowerBgData.push(contractorData);
      //console.log("変換1データ");
      //console.log(JSON.stringify(contractorData));
    });
    //console.log("変換後データ");
    //console.log(JSON.stringify(retPowerBgData));
    return retPowerBgData;
  };

  //セレクターから選択された所属発電BGのIDをsaleDetailInfoに入れる
  const setPowerBgId = (e: number) => {
    const tmpPowerPlantInfo: setPowerPlant = Object.assign({}, powerPlantInfo);
    const tmpBgInfo: powerBgInfo[] = powerBgInfos.current;
    //console.log(e);
    tmpPowerPlantInfo.powerBgId = tmpBgInfo[e].powerBgId;
    tmpPowerPlantInfo.areaId = tmpBgInfo[e].areaId;

    setSelPowerBgArea(tmpBgInfo[e].areaId);
    PowerBgCompornentChanged(tmpBgInfo[e]);
    setPowerPlantInfo(tmpPowerPlantInfo);
  };

  //発電契約者部分のコンポーネント変更（セレクトリストとフィールド）
  const PowerBgCompornentChanged = (saleGrInfo: powerBgInfo) => {
    setPowerBgNo(saleGrInfo.id);
  };

  //編集用にDBから出したデータの所属発電BGのIDと一致しているものを
  //saleGroupsInfoからさがし、その情報をコンポーネントに表示
  const setPowerBgInfoForEdit = (powerBgIdFromDB: number) => {
    powerBgInfos.current.map((powerBgInfos) => {
      if (powerBgIdFromDB === powerBgInfos.powerBgId) {
        // setSelContractantCode(contractorInfo.code);
        // setSelContractantNo(contractorInfo.id);
        PowerBgCompornentChanged(powerBgInfos);
      }
    });
  };

  ////////所属発電BG

  const initProratalList: setProrataData = {
    priority: 1,
    prorateNum: 1,
  };

  //太陽光パネルの設置方式リストを作成
  const createPanelInstallationList = () => {
    const panelInstallation = [
      { id: 0, name: languageContext.words.panel_mounted_type_1 },
      { id: 1, name: languageContext.words.panel_mounted_type_2 },
      { id: 2, name: languageContext.words.panel_mounted_type_3 },
      { id: 3, name: languageContext.words.panel_mounted_type_4 },
    ];
    return panelInstallation;
  };

  //太陽光パネルの種類のリストを作成
  const createPanelKindList = () => {
    const panelKind = [
      { id: 0, name: languageContext.words.panel_type_1 },
      { id: 1, name: languageContext.words.panel_type_2 },
      { id: 2, name: languageContext.words.panel_type_3 },
      { id: 3, name: languageContext.words.panel_type_4 },
    ];
    return panelKind;
  };

  //テキストフィールドの中身が変化したら、powerContractorInfoの中身をそのたびに変える
  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name: string = e.currentTarget.name;
    let value: string = e.currentTarget.value;
    // let pos: number = 0;
    // if(e.currentTarget.selectionStart != null) {
    //   pos = e.currentTarget.selectionStart;
    // }

    const tmpInfo: setPowerPlant = Object.assign({}, powerPlantInfo);
    switch (name) {
      case "plantName":
        tmpInfo.plantName = value;
        break;
      case "contrantPower":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 9999999999, 0)) {
          tmpInfo.contrantPower = parseInt(value);
        }

        break;
      case "rootCode":
        tmpInfo.rootCode = value;
        break;
      case "rootPlantName":
        tmpInfo.rootPlantName = value;
        break;
      case "rootPlantCode2":
        tmpInfo.rootPlantCode2 = value;
        break;
      case "remarks":
        tmpInfo.remarks = value;
        break;
      case "maxPower":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 9999999999, 0)) {
          tmpInfo.maxPower = parseInt(value);
        }

        //tmpInfo.maxPower = isNaN(parseInt(value)) ? 0 : parseInt(value);
        break;
      case "powerCoefficient":
        if (value === "") {
          value = "0";
        }
        // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
        if (isSafeNumInTextField(value, 9999999999.9999999999, 0)) {
          tmpInfo.powerCoefficient = value;
        }
        //tmpInfo.powerCoefficient = isNaN(parseFloat(value)) ? "0" : value;
        break;
      case "powerPointNumber":
        if (value === "") {
          value = "0";
        }
        tmpInfo.powerPointNumber = value;
        break;
      case "latitude":
        if (value === "") {
          value = "0";
        } else if (value.match(/^\.\d*$/)) {
          value = value.replace(".", "0.");
        } else if (value.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          value = value.replace("0", "");
        }
        if (
          isSafeNumInTextField(value, 90, -90) &&
          !value.match(/^[-]?\d*(\.\d\d\d\d\d\d\d+)+/)
        ) {
          tmpInfo.latitude = value;
        }
        break;
      case "longitude":
        if (value === "") {
          value = "0";
        } else if (value.match(/^\.\d*$/)) {
          value = value.replace(".", "0.");
        } else if (value.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          value = value.replace("0", "");
        }
        if (
          isSafeNumInTextField(value, 180, -180) &&
          !value.match(/^[-]?\d*(\.\d\d\d\d\d\d\d+)+/)
        ) {
          tmpInfo.longitude = value;
        }
        break;
      case "prorate":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 9, 0)) {
          tmpInfo.prorate = parseInt(value);
        }
        break;
      case "priority":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 99, 0)) {
          tmpInfo.priority = parseInt(value);
        }

        break;
      case "ac_output":
        if (value === "") {
          value = "0";
        } else if (value.match(/^\.\d*$/)) {
          value = value.replace(".", "0.");
        } else if (value.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          value = value.replace("0", "");
        }
        if (
          isSafeNumInTextField(value, 999999, 0) &&
          !value.match(/^\d*(\.\d\d\d\d\d+)+/)
        ) {
          tmpInfo.acOutput = value;
        }
        break;

      case "panel_tilt":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 999999, 0)) {
          tmpInfo.panelTilt = parseFloat(value);
        }
        //tmpInfo.latitude = isNaN(parseFloat(value)) ? "" : value;
        break;

      case "panel_azimuth":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 999999, 0)) {
          tmpInfo.panelAzimuth = parseFloat(value);
        }
        //tmpInfo.latitude = isNaN(parseFloat(value)) ? "" : value;
        break;

      case "panel_power_decline":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 999999, 0)) {
          tmpInfo.panelPowerDecline = parseFloat(value);
        }
        //tmpInfo.latitude = isNaN(parseFloat(value)) ? "" : value;
        break;

      case "panel_coe":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 999999, 0)) {
          tmpInfo.panelCoe = parseFloat(value);
        }
        //tmpInfo.latitude = isNaN(parseFloat(value)) ? "" : value;
        break;

      case "rated_output":
        if (value === "") {
          value = "0";
        } else if (value.match(/^\.\d*$/)) {
          value = value.replace(".", "0.");
        } else if (value.match(/^[-]?[0]+[0-9]+(\.[0-9]*)*$/)) {
          value = value.replace("0", "");
        }
        if (
          isSafeNumInTextField(value, 999999, 0) &&
          !value.match(/^\d*(\.\d\d\d\d\d+)+/)
        ) {
          tmpInfo.ratedOutput = value;
        }
        //tmpInfo.latitude = isNaN(parseFloat(value)) ? "" : value;
        break;

      default:
        break;
    }
    setPowerPlantInfo(tmpInfo);
  }

  //テキストフィールドに正しい数値チェック
  const isSafeNumInTextField = (
    value: string,
    maxNum: number,
    minNum: number
  ): boolean => {
    //正しい範囲内の数値が入っているかチェック
    if (!isNaN(parseInt(value))) {
      const valueNum = parseFloat(value);
      if (valueNum >= minNum && valueNum <= maxNum) {
        return true;
      }
    }
    return false;
  };

  //テキストフィールドの中身が変化(prorataの部分)
  function onChangeProrataFieldValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) {
    let value: string = e.currentTarget.value;
    const name: string = e.currentTarget.name;

    const tmpInfo: setPowerPlant = Object.assign({}, powerPlantInfo);
    //tmpInfo.prorataList = Object.assign({}, powerPlantInfo.prorataList);
    const tempArr: setProrataData[] = [];
    for (let i = 0; i < 48; i++) {
      tempArr.push({
        priority: tmpInfo.prorataList[i].priority,
        prorateNum: tmpInfo.prorataList[i].prorateNum,
      });
    }
    switch (name) {
      case "priority":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 99, 0)) {
          tempArr[index].priority = parseInt(value);
        }
        break;
      case "prorate":
        if (value === "") {
          value = "0";
        }
        if (isSafeNumInTextField(value, 9, 0)) {
          tempArr[index].prorateNum = parseInt(value);
        }

        break;
      default:
        break;
    }

    tmpInfo.prorataList = tempArr;
    setPowerPlantInfo(tmpInfo);
  }

  //新規追加
  function AddDatas() {
    //console.log(powerPlantInfo);
    let error = false;

    error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_confirm_entry);
      setDigOpen(true);
    } else if (error === false) {
      api
        .post(API_URL.ADD_POWERPLANT_INFO, powerPlantInfo)
        .then((response) => {
          //console.log(response.data.dgm_role);
          setJobClear(true);
          setDialogMessage(languageContext.words.power_plant_setting_added);
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(
            languageContext.words.power_plant_setting_failed_add
          );
          setDigOpen(true);
        });
    }
  }

  const isCorrectInfo = (): boolean => {
    let error = false;
    const tempInputErrors = Object.assign({}, initErrorInput);
    if (powerPlantInfo?.plantName == "") {
      error = true;
      tempInputErrors.plantName = true;
    }
    if (powerPlantInfo?.areaId == "00") {
      error = true;
      tempInputErrors.areaId = true;
    }
    if (powerPlantInfo?.powerBgId == 0) {
      error = true;
      tempInputErrors.powerBgId = true;
    }
    if (powerPlantInfo?.generationKindNum == 0) {
      error = true;
      tempInputErrors.generationKindNum = true;
    }
    if (powerPlantInfo?.rootCode == "") {
      error = true;
      tempInputErrors.rootCode = true;
    }
    if (powerPlantInfo?.powerPointNumber == "") {
      error = true;
      tempInputErrors.powerPointNumber = true;
    }

    if (powerPlantInfo?.pannelType == 0) {
      error = true;
      tempInputErrors.pannelType = true;
    }

    if (powerPlantInfo?.downwardPannel == 0) {
      error = true;
      tempInputErrors.sunPannel = true;
    }

    setInputError(tempInputErrors);
    return error;
  };

  // 発電所データ一行分取得
  const getDataLinkedPlantId = (plantIdEdit: number) => {
    //console.log("1行データ取得開始");
    let responseData: setPowerPlant;
    const url = api
      .get(API_URL.GET_ONE_POWERPLANT_DATA, {
        params: { plantIdEdit: plantIdEdit },
      })
      .then((response) => {
        //console.log(response.data.result);
        responseData = response.data.result as setPowerPlant;

        //.log(responseData);
        if (
          responseData.powerKind == null ||
          responseData.powerKind == undefined
        ) {
          responseData.powerKind = 0;
        }
        //ラジオボタン部分
        if (responseData.settingFlag == 1) {
          setSelectedValue("a");
        } else {
          setSelectedValue("b");
        }

        //DBで小数点以下の0の情報が欠損しているため取得した緯度経度を小数第6位まで0でパディング
        responseData.latitude = Number(responseData.latitude).toFixed(6);
        responseData.longitude = Number(responseData.longitude).toFixed(6);
        //DBで小数点以下の0の情報が欠損しているため取得した定格出力を小数第4位まで0でパディング
        responseData.ratedOutput = Number(responseData.ratedOutput).toFixed(4);

        setPowerPlantInfo(responseData);
        setgenerateWaysNo(responseData.generationKindNum);
        setplantKindNo(responseData.powerKind);
        setPowerIdSellector(responseData.powerBgId); //ここでは表示用データ更新
        setStartDate(new Date(responseData.startDate));
        setFinishDate(new Date(responseData.finishDate));
      })
      .catch((err) => {
        console.log(err);
        setPowerPlantInfo(initInfo);
      });
  };

  //編集用にDBから出したデータのpowerBgIDと一致しているものを
  //powerBgInfosからさがし、その情報をコンポーネントに表示
  const setPowerIdSellector = (powerBgIdFromDB: number) => {
    powerBgInfos.current.map((powerBgInfo) => {
      if (powerBgIdFromDB === powerBgInfo.powerBgId) {
        console.log(powerBgInfo.areaName);
        setPowerBgNo(powerBgInfo.id);
        setSelPowerBgArea(powerBgInfo.areaId);
      }
    });
  };

  //plantIdの値をもとにデータを更新する
  function UpdateDatas() {
    //console.log(powerPlantInfo);
    let error = false;

    error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_confirm_entry);
      setDigOpen(true);
    } else if (error === false) {
      const url = api
        .post(API_URL.UPDATE_POWERPLANT_INFO, powerPlantInfo)
        .then((response) => {
          console.log(response.data.result);
          setJobClear(true);
          setDialogMessage(languageContext.words.power_plant_setting_updated);
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(
            languageContext.words.power_plant_setting_failed_update
          );
          setDigOpen(true);
        });
    }
  }

  // フォーカスアウト時にテキストフィールドに表示している値をtoFixedする
  const processText = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    text: setPowerPlant,
    setText: React.Dispatch<React.SetStateAction<setPowerPlant>>
  ) => {
    const data = e.currentTarget.value;
    const name = e.currentTarget.name;
    const tempText: setPowerPlant = structuredClone(text);
    switch (name) {
      case "latitude":
        tempText.latitude = Number(data).toFixed(6);
        break;
      case "longitude":
        tempText.longitude = Number(data).toFixed(6);
        break;
      case "rated_output":
        tempText.ratedOutput = Number(data).toFixed(4);
        break;
      case "ac_output":
        tempText.acOutput = Number(data).toFixed(4);
        break;
      case "panel_power_decline":
        tempText.panelPowerDecline = Math.floor(Number(data)* 100) /100 ;
        break;
      case "panel_coe":
        tempText.panelCoe = Math.floor(Number(data)* 100) /100 ;
        break;
      default:
        break;
    }
    setText(tempText);
  };

  // 削除(論理削除)
  const Delete = (powerId: number | undefined) => {
    // 削除API呼出
    console.log(powerPlantInfo);
    api
      .post(API_URL.DELETE_POWERPLANT_INFO, { powerId: powerId })
      .then((response) => {
        console.log(`発電所1件削除結果: ${response.data.result}`);
        setJobClear(true);
        setDialogMessage(languageContext.words.power_plant_setting_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(
          languageContext.words.power_plant_setting_failed_delete
        );
        setDigOpen(true);
      });
  };
  const DoModeJob = () => {
    if (mode == modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  return (
    <CustomSettingModal
      title={languageContext.words.power_plant_list_setting}
      open={toOpen}
      onAcceptLeft={() => DoModeJob()}
      onAcceptRight={() => Delete(powerPlantInfo?.powerId)}
      onClose={() => HaldleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
      }
    >
      <Stack
        spacing={0}
        direction={"row"}
        sx={{ height: "630px", width: "1106px" }}
      >
        <Box sx={{ height: "630px", width: "550px" }}>
          <CustomBoxBig>
            <CustomModalTypography gutterBottom>
              {languageContext.words.power_plant_name}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="text"
              value={powerPlantInfo?.plantName}
              onChange={onChangeValue}
              name="plantName"
              inputProps={{
                maxLength: 64,
              }}
              error={inputError.plantName}
              helperText={languageContext.words.setting_required}
            ></CustomTextFieldBig>
          </CustomBoxBig>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.affiliation_power_bg}
              </CustomModalTypography>

              <CustomSelectListBig
                //key={"demandForecastData_CustomSelectList_customer"}
                name="powerBg"
                label={languageContext.words.all_areas}
                //defaultValue={0}
                value={powerBgNo}
                options={powerBgInfos.current}
                onChange={(e: number) => {
                  setPowerBgId(e);
                }}
                error={inputError.powerBgId}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.connection_area}
              </CustomModalTypography>

              <CustomTextFieldSmall
                //key={"demandForecastData_CustomSelectList_customer"}
                name="areaId"
                //label={languageContext.words.all_areas}
                value={convertIdToAreaName(selPowerBgArea)}
                disabled={true}
                error={inputError.areaId}
              />
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.power_supply_type}
              </CustomModalTypography>

              <CustomSelectListSmall
                //key={"demandForecastData_CustomSelectList_customer"}
                name="powerPlantKind"
                label={languageContext.words.all_areas}
                //defaultValue={0}
                value={plantKindNo}
                options={supplyTypeOptions}
                onChange={(e: number) => {
                  SetPlantKinds(e);
                }}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.power_gen_method}
              </CustomModalTypography>

              <CustomSelectListSmall
                name="generationWay"
                value={generateWaysNo}
                options={energyOptions(powerEnergyKind)}
                onChange={(e: number) => {
                  SetPowerKind(e);
                }}
                error={inputError.generationKindNum}
              />
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.contracted_power +
                  "(" +
                  languageContext.words.kw +
                  ")"}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="number"
                value={Number(powerPlantInfo?.contrantPower).toFixed(0)}
                onFocus={(e) => {
                  if (Number(powerPlantInfo?.contrantPower) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onChange={onChangeValue}
                name="contrantPower"
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 9999999999,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.grid_code}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="text"
                value={powerPlantInfo.rootCode}
                onChange={onChangeValue}
                name="rootCode"
                inputProps={{
                  maxLength: 5,
                }}
                error={inputError.rootCode}
                helperText={languageContext.words.setting_required}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <CustomBoxBig>
            <CustomModalTypography gutterBottom>
              {languageContext.words.plant_name_for_note_form}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="text"
              value={powerPlantInfo.rootPlantName}
              onChange={onChangeValue}
              name="rootPlantName"
              inputProps={{
                maxLength: 64,
              }}
            ></CustomTextFieldBig>
          </CustomBoxBig>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.power_receive_point_number}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="text"
                value={powerPlantInfo.powerPointNumber}
                onChange={onChangeValue}
                name="powerPointNumber"
                inputProps={{
                  maxLength: 22,
                }}
                error={inputError.powerPointNumber}
                helperText={languageContext.words.setting_required}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {
                  languageContext.words
                    .contract_id_2_for_power_gen_and_sales_plan
                }
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="text"
                value={powerPlantInfo.rootPlantCode2}
                onChange={onChangeValue}
                name="rootPlantCode2"
                inputProps={{
                  maxLength: 20,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>
          <CustomBoxBig>
            <CustomModalTypography gutterBottom>
              {languageContext.words.remarks}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="text"
              value={powerPlantInfo.remarks}
              onChange={onChangeValue}
              name="remarks"
              inputProps={{
                maxLength: 256,
              }}
            ></CustomTextFieldBig>
          </CustomBoxBig>
        </Box>
        <Divider orientation="vertical" />
        <Box
          sx={{
            height: "630px",
            width: "550px",
            marginLeft: "8px",
          }}
        >
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.setting_latitude}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo.latitude}
                onFocus={(e) => {
                  if (Number(powerPlantInfo.latitude) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, powerPlantInfo, setPowerPlantInfo);
                }}
                onKeyDown={(event) => {
                  if (event.key === "e" || event.key === "E") {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                name="latitude"
                inputProps={{
                  step: 0.000001,
                  min: -90,
                  max: 90,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.setting_longitude}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo.longitude}
                onFocus={(e) => {
                  if (Number(powerPlantInfo.longitude) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, powerPlantInfo, setPowerPlantInfo);
                }}
                onKeyDown={(event) => {
                  if (event.key === "e" || event.key === "E") {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                name="longitude"
                inputProps={{
                  step: 0.000001,
                  min: -180,
                  max: 180,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.panel_tilt}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo.panelTilt}
                onFocus={(e) => {
                  if (Number(powerPlantInfo.panelTilt) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onChange={onChangeValue}
                name="panel_tilt"
                inputProps={{
                  step: 1,
                  min: 0,
                  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                  max: 999999,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.panel_azimuth}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="number"
                value={Number(powerPlantInfo.panelAzimuth).toFixed(0)}
                onFocus={(e) => {
                  if (Number(powerPlantInfo.panelAzimuth) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onChange={onChangeValue}
                name="panel_azimuth"
                inputProps={{
                  step: 1,
                  min: 0,
                  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                  max: 999999,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.maximum_output_kw}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="number"
                value={Number(powerPlantInfo.maxPower).toFixed(0)}
                onFocus={(e) => {
                  if (Number(powerPlantInfo.maxPower) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onChange={onChangeValue}
                name="maxPower"
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 9999999999,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>

          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.rated_output}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo.ratedOutput}
                onFocus={(e) => {
                  if (Number(powerPlantInfo.ratedOutput) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, powerPlantInfo, setPowerPlantInfo);
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "e" ||
                    event.key === "E" ||
                    event.key === "-"
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                name="rated_output"
                inputProps={{
                  step: 0.001,
                  min: 0,
                  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                  max: 999999.999,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.ac_output}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo.acOutput}
                onFocus={(e) => {
                  if (Number(powerPlantInfo.acOutput) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, powerPlantInfo, setPowerPlantInfo);
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "e" ||
                    event.key === "E" ||
                    event.key === "-"
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                name="ac_output"
                inputProps={{
                  step: 0.001,
                  min: 0,
                  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                  max: 999999.999,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.panel_power_decline}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo.panelPowerDecline}
                onFocus={(e) => {
                  if (Number(powerPlantInfo.panelPowerDecline) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, powerPlantInfo, setPowerPlantInfo);
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "e" ||
                    event.key === "E" ||
                    event.key === "-"
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                name="panel_power_decline"
                inputProps={{
                  step: 0.01,
                  min: 0,
                  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                  max: 999.99,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.panel_coe}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="number"
                value={powerPlantInfo.panelCoe}
                onFocus={(e) => {
                  if (Number(powerPlantInfo.panelCoe) === 0) {
                    e.currentTarget.select();
                  }
                }}
                onBlur={(e) => {
                  processText(e, powerPlantInfo, setPowerPlantInfo);
                }}
                onKeyDown={(event) => {
                  if (
                    event.key === "e" ||
                    event.key === "E" ||
                    event.key === "-"
                  ) {
                    event.preventDefault();
                  }
                }}
                onChange={onChangeValue}
                name="panel_coe"
                inputProps={{
                  step: 0.01,
                  min: 0,
                  // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
                  max: 999.99,
                }}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
          <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.pannel_type}
              </CustomModalTypography>
              <CustomSelectListSmall
                //key={"demandForecastData_CustomSelectList_customer"}
                name="pannelType"
                //defaultValue={0}
                value={powerPlantInfo.pannelType}
                options={pannelTypeInfos.current}
                onChange={(e: number) => {
                  setPannelType(e); // データの更新
                }}
                error={inputError.pannelType}
              />
            </CustomBoxSmall>

          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.sun_pannel}
            </CustomModalTypography>
            <CustomSelectListSmall
              name="sunPannel"
              value={powerPlantInfo.downwardPannel}
              options={sunPannelInfos.current}
              sx={{ width: "320px" }}
              onChange={(e: number) => {
                setSunPannelType(e); // データの更新
              }}
              error={inputError.sunPannel}
            />
          </CustomBoxSmall>
          </Stack>

          {/* 有効期限 */}
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.effective_date_start_date}
              </CustomModalTypography>
              <CustomModalDatePicker
                setValue={setStartDate}
                value={startDate}
                maxDate={subDays(finishDate, 1)}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.expiration_date}
              </CustomModalTypography>
              <CustomModalDatePicker
                setValue={setFinishDate}
                value={finishDate}
                minDate={addDays(startDate, 1)}
              />
            </CustomBoxSmall>
          </Stack>
        </Box>
      </Stack>
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetPowerPlantList;
