import React, { useContext } from "react";
import Box from "@mui/material/Box";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Typography } from "@mui/material";
import { ErrorTitleContext } from "../../common/customComponents/ErrorTitleProvider";
import { ErrorMessageContext } from "../../common/customComponents/ErrorMessageProvider";
import { LanguageContext } from "../../common/localization/localization";

export const ConnectionErrorDisplay = () => {
  // 言語コンテキスト
  const { words } = useContext(LanguageContext);
  const { title } = useContext(ErrorTitleContext);
  const { message } = useContext(ErrorMessageContext);

  return (
    <Box
      sx={{
        height: "417px",
        minWidth: "538px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <ErrorOutlineIcon
        sx={{ fontSize: "96px", margin: "8px" }}
        color="error"
      />
      <Typography sx={{ fontWeight: "bold" }} variant="h5" margin="8px">
        {words[title]}
      </Typography>
      <Typography margin="8px">{words[message]}</Typography>
    </Box>
  );
};
