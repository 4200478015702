export const CELLCOUNT = 48;
export const PLAN_MAX_VALUE = 1999.9;

// apiのパス
export const INTRADAY_DATA_URL = "/oneHourAdvanceBidPlan";
export const GET_PFP_POWER_URL = "/getPowerForecastPlanPower"; // 発電計画
export const GET_PFP_SALE_URL = "/getPowerForecastPlanSale"; // 販売計画
export const GET_PFP_PROVIDE_URL = "/getPowerForecastPlanProvide"; // 調達計画
export const GET_ROLE_JUDGMENT_URL = "/getIsEditableRolePlan"; // ロールの操作権限判定
export const GET_INTRADAY_PRODUCT_URL = "/getIntradayProduct"; //１時間前商品一覧
export const POST_INTRADAY_CONTRACT_URL = "/contractData"; //約定済み商品のステータス変更
export const UPDATE_AFTER_GC_STATUS = "/updateAfterGCStatus"; //GC後の商品のステータスを変更
export const GET_BID_LIMIT = "/getBidRequestLimit"; //入札制限を取得
export const GET_SALES_DATA = "/getSalesData"; //１時間前販売計画を取得
export const GET_PROCUREMENT_DATA = "/getProcurementData"; //１時間前調達計画を取得
export const AUTO_BIDDING = "/autoBidding"; //自動価格調整開始
export const POST_AUTO_SETTING = "/postAutoSetting"; //自動価格調整設定登録
export const GET_AUTO_SETTING = "/getAutoSetting"; //自動価格調整設定取得
export const GET_AREA_NAME_LIST = "/getAreaNameList"; //エリア名リスト取得
export const POST_SALES_PLAN = "/insertSalesPlan"; //販売計画登録
export const POST_PROCUREMENT_PLAN = "/insertProcurementPlan"; //調達計画登録

// 約定状況を表す
export const CONTRACT_STATUS = {
  waitingBid: "0",
  waitingContract: "1",
  contracted: "2",
  cancellationReq: "3",
  canceled: "4",
  bidFailure: "5",
  cancellationFailure: "6",
  couldNot: "7",
};
