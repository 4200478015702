import React, { useState, useEffect, useContext, useMemo, useLayoutEffect } from "react";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import { StyledButton } from "../../common/styledComponents/styledButton";
import CustomSelectList,{ CustomMultipleSelectList } from "../../common/customComponents/CustomSelectList";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, styled } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import {
  DateContext,
  GlobalDatePicker,
} from "../../common/customComponents/GlobalDatePicker";
import { LanguageContext } from "../../common/localization/localization";
import { PaletteContext } from "../../common/paletteMode";
// import ログイン用Context
import "./PowerForecastList.css";
import { ThemeProvider } from "styled-components";
import * as CommonType from "../../../types/common/Search";
import {
  reqPowerForecastList,
  resPowerForecastList,
} from "../../../types/occto/PowerForecastList";
import { areIntervalsOverlapping, format, parse } from "date-fns";
import { parseISO } from "date-fns";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import CustomDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import {
  POWER_FORECAST_URL,
  POWER_FORECAST_PLAN_URL,
} from "../../../common/url";
import { useAreaUtility } from "../../../common/area";
import { AREA_ID } from "../../../common/constant";
import { usePowerPlantUtility } from "../../../common/powerEnergy";
import { useAuthedApi } from "../../../common/axios";
import { powerDemandInfo } from "../../common/customComponents/CustomSelectList"
import { type } from "os";

export interface searchInfo { id: number; name: string };

// 共通コンポーネントカスタマイズ
// ヘッダー ライトグレー
const PowerForecastListStyledTableHeaderLG = styled(StyledTableHeaderLG)`
  && {
    height: 42px;
    padding: 0px;
  }
`;
// セル
const PowerForecastListStyledTableCell = styled(StyledTableCell)`
  && {
    height: 42px;
    padding: 0px 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 0;
  }
`;

// API URL
// エリア一覧
const AREA_LIST_URL = "/getAreaNameList";
// 発電BG一覧
const POWER_BG_LIST_URL = "/getPowerBgNameList";
// 発電所一覧
const POWER_PLANT_LIST_URL = "/getPowerNameList";
// 発電計画一覧
const GET_POWER_FORCAST_LIST_URL = "/getPowerForecastList";

const GET_POWER_FORECAST_INFO_URL = "/getPowerForecastInfoList";
export interface searchInfo { id: number; name: string };

/**
 * 発電計画一覧画面
 * @returns
 */
const PowerForecastList = () => {
  // 設定言語
  const languageContext = useContext(LanguageContext);
  // グローバルカレンダー
  const dateContext = useContext(DateContext);
  // カラーモード
  const palletContextBase = useContext(PaletteContext);
  // 変更フラグ
  const leavePageContext = useContext(LeavePageContext);
  // エリア
  const {setAreaId} = useContext(AreaContext);
  const api = useAuthedApi();

  // セレクトリスト
  const [selectArea, setSelectArea] = useState<string>(AREA_ID.NONE); //エリア
  const [selectPowerBg, setSelectPowerBg] = useState<number>(0); // 発電BG
  const [selectPowerPlant, setSelectPowerPlant] = useState<number>(0); //発電所

  // URLパラメータ取得
  const [serchParams] = useSearchParams();

  const [allPowerPlantList, setAllPowerPlantList] = useState<powerDemandInfo[]>([]);

  //エリア一覧
  const [areaList, setAreaList] = useState<string[]>([]);
  const { areaOptions, convertIdToAreaName } = useAreaUtility();

  // 発電BG一覧
  const [powerBgList, setPowerBgList] = useState<searchInfo[]>([]);
  const powerBgOptions = useMemo(
    () =>
      [{ id: 0, name: languageContext.words.all_power_gen_bg }].concat(
        powerBgList
      ),
    [languageContext.words, powerBgList]
  );

  //発電所一覧
  const [powerPlantList, setPowerPlantList] = useState<searchInfo[]>([]);
  const powerPlantOptions = useMemo(
    () =>
      [{ id: 0, name: languageContext.words.all_power_plants }].concat(
        powerPlantList
      ),
    [languageContext.words, powerPlantList]
  );

  // 発電計画一覧
  const [powerForecastListItems, setpowerForecastListItems] = useState<
    resPowerForecastList[]
  >([]);

  const { convertIdToEnergyName } = usePowerPlantUtility();

  // 描画監視
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  //ダイアログ
  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  // エリア変更時
  useEffect(() => {
    // //プルダウンリセット
    // setPowerBgList([]);
    // setPowerPlantList([]);

    // //選択状態リセット
    // setSelectPowerBg(0);
    // setSelectPowerPlant(0);

    // // 発電BG取得
    // if (selectArea != AREA_ID.NONE) {
    //   initPowerBgList();
    // }

    // 発電計画一覧取得
    if (
      (selectArea == AREA_ID.NONE &&
        selectPowerBg != 0 &&
        selectPowerPlant != 0) ||
      (selectArea == AREA_ID.NONE &&
        selectPowerBg != 0 &&
        selectPowerPlant == 0)
    ) {
      //なにもしない
    } else {
       if(areaList.length){
        const tmpAreaList = selectArea === "00" ? areaList.filter((e) => e !== "00") : areaList.filter((e) => e === selectArea);
        getPowerForcastListData(tmpAreaList, selectPowerBg, selectPowerPlant);
      }
    }
  }, [
    //selectArea, areaList, selectPowerBg, selectPowerPlant, 
    dateContext, areaList]);

  // // BG変更時
  // useEffect(() => {
  //   //プルダウンリセット
  //   setPowerPlantList([]);

  //   //選択状態リセット
  //   setSelectPowerPlant(0);

  //   // 発電家一覧
  //   if (selectPowerBg != 0) {
  //     initPowerPlantList();
  //   }

  //   // 発電計画一覧取得
  //   if(areaList.length){
  //     getPowerForcastListData();
  //   }
   
  // }, [selectPowerBg]);

  // // 発電家変更時
  // useEffect(() => {
  //   // 発電計画一覧取得
  //   if(areaList.length){
  //     getPowerForcastListData();
  //   }
    
  // }, [areaList, selectPowerPlant, dateContext]);

  // useLayoutEffect(() => {

  //   //発電所情報一括取得
  //   getPowerPlantList();

  // },[]);

  // 初期表示情報セット
  useEffect(() => {
    // エリアID
/*     const areaId = serchParams.get("areaId");
    if (areaId != null && !Number.isNaN(areaId) && parseInt(areaId) != 0) {
      setSelectArea(areaId);
    }
    // 発電BGID
    const bgId = serchParams.get("bgId");
    if (bgId != null && !Number.isNaN(bgId) && parseInt(bgId) != 0) {
      setSelectPowerBg(parseInt(bgId));
    }
    // 発電所ID
    const plantId = serchParams.get("plantId");
    if (plantId != null && !Number.isNaN(plantId) && parseInt(plantId) != 0) {
      setSelectPowerPlant(parseInt(plantId));
    } */
    // // エリア一覧取得
    // initAreaList();

    //発電所情報一括取得
    getPowerPlantList();
    // 初期描画完了フラグセット
    setIsLoaded(true);
    leavePageContext.setBlockLeave(false);
  }, []);

  // エリア名
  const initAreaList = () => {
    api
      .get(AREA_LIST_URL, { params: null })
      .then((res) => {
        setAreaList(res.data.map((v: any) => v.areaId));
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  // 発電BG
  const initPowerBgList = () => {
    api
      .get(POWER_BG_LIST_URL, {
        params: { areaId: selectArea },
      })
      .then((res) => {
        if (res.data.length != 0) {
          setSelectPowerBg(0); // 発電BG
        } else {
          if (selectArea != AREA_ID.NONE) {
            setDialogMessage(languageContext.words.not_power_bg_setting);
            setDigOpen(true);
          }
        }
        setPowerBgList(
          res.data.map((v: any) => {
            return { id: v.powerBgId, name: v.powerBgName };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  // 発電所
  const initPowerPlantList = () => {
    api
      .get(POWER_PLANT_LIST_URL, {
        params: {
          areaId: selectArea,
          powerBgId: selectPowerBg,
        },
      })
      .then((res) => {
        setSelectPowerPlant(0); // 発電所
        setPowerPlantList(
          res.data.map((v: any) => {
            return { id: v.powerId, name: v.powerName };
          })
        );
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  //発電所情報一括取得
  //3連コンポーネント対応用
  const getPowerPlantList = () => {
    api
      .get(GET_POWER_FORECAST_INFO_URL)
      .then((res) => {
        setAllPowerPlantList(res.data.map((v: any) => {
          return {
            areaId: v.areaId,
            bgId: v.bgId,
            bgName: v.bgName,
            powerDemandId: v.powerId,
            powerDemandName: v.powerName,
          };
        }));
      })
      .catch((error) => {
        console.log(error);
        setError(error);        
      })

  };

  // 発電計画一覧
  const getPowerForcastListData = (areaIds:string[], powerBgId: number, powerId: number) => {
    const requestParam: reqPowerForecastList = {
      areaIds: areaIds,
        // selectArea === "00"
        //   ? areaList.filter((e) => e !== "00")
        //   : areaList.filter((e) => e === selectArea),
      powerBgId: powerBgId,
        //selectPowerBg,
      powerId: powerId,
        //selectPowerPlant,
      date: format(dateContext.pickDate as Date, "yyy/MM/dd"),
    };
    //console.log("発電計画一覧を取得する際のリクエスト", requestParam);
    api
      .get(GET_POWER_FORCAST_LIST_URL, { params: requestParam })
      .then((res) => {
        //console.log("成功");
        //console.log("取得した計画一覧", res.data);
        setpowerForecastListItems(res.data);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
      });
  };

  // 画面遷移関数
  const navigate = useNavigate();
  function transitToOtherPage(url: string) {
    navigate(url);
  }

  //ページレンダリング
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  }
  return (
    <div
      key={"powerForecastData_div"}
      className={`cn-main-display ${
        palletContextBase.PaletteMode === "dark" ? "dark" : "light"
      }`}
    >
      <Box
        key={"powerForecastData_Box_SelectLists"}
        sx={{ marginBottom: 2, display: "flex" }}
      >
        <CustomMultipleSelectList
          powerFg={true}
          list={allPowerPlantList}
          areaId={selectArea}
          bgId={selectPowerBg}
          powerDemandId={selectPowerPlant}
          handleValueChangeAreaList={setAreaList}
          handleValueChangeArea={setSelectArea}
          handleValueChangeBG={setSelectPowerBg}
          handleValueChangePowerDemand={setSelectPowerPlant}
          getData={getPowerForcastListData}
        >
        </CustomMultipleSelectList>
        {/* <CustomSelectList
          label={languageContext.words.all_areas}
          value={selectArea}
          options={
            //{id: "03", name: "東京"}
            areaOptions(areaList)
          }
          onChange={setSelectArea}
        />

        <CustomSelectList
          key={"powerForecastData_CustomSelectList_area"}
          label={languageContext.words.all_power_gen_bg}
          value={selectPowerBg}
          options={powerBgOptions}
          onChange={setSelectPowerBg}
        />

        <CustomSelectList
          key={"powerForecastData_CustomSelectList_demandBg"}
          label={languageContext.words.all_power_plants}
          value={selectPowerPlant}
          options={powerPlantOptions}
          onChange={setSelectPowerPlant}
        />  */}
        <GlobalDatePicker
          key={"powerForecastData_GlobalDatePicker"}
          isDipsTodayBtn={true}
        />
      </Box>

      <TableContainer
        key={"powerForecastData_TableContainer"}
        sx={{
          width: "100%",
          height: "90%",
          "&::-webkit-scrollbar": {
            width: "10px",
            backgroundColor: "transparent",
            height: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#AAA",
            borderRadius: "10px",
          },
        }}
      >
        <StyledTable
          key={"powerForecastData_StyledTable"}
          sx={{ width: "100%" }}
          stickyHeader
        >
          <TableHead key={"powerForecastData_Header"}>
            <TableRow>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_area"}
                sx={{ width: "8%" }}
                align="center"
              >
                {languageContext.words.area}
              </PowerForecastListStyledTableHeaderLG>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_plant"}
                sx={{ width: "19%" }}
                align="center"
              >
                {languageContext.words.power_plant}
              </PowerForecastListStyledTableHeaderLG>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_method"}
                sx={{ width: "10%" }}
                align="center"
              >
                {languageContext.words.power_gen_method}
              </PowerForecastListStyledTableHeaderLG>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_bg"}
                sx={{ width: "19%" }}
                align="center"
              >
                {languageContext.words.power_gen_bg}
              </PowerForecastListStyledTableHeaderLG>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_createDate"}
                sx={{ width: "8%" }}
                align="center"
              >
                {languageContext.words.save_date}
              </PowerForecastListStyledTableHeaderLG>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_user"}
                sx={{ width: "12%" }}
                align="center"
              >
                {languageContext.words.updater}
              </PowerForecastListStyledTableHeaderLG>
              <PowerForecastListStyledTableHeaderLG
                key={"powerForecastData_Header_Buttons"}
                sx={{ width: "22%" }}
                align="center"
              ></PowerForecastListStyledTableHeaderLG>
            </TableRow>
          </TableHead>

          <TableBody>
            {powerForecastListItems.map((row, index) => (
              <TableRow key={"powerForecastData_Row" + index}>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_areaName"}
                  align="center"
                  title={convertIdToAreaName(row.areaId)}
                >
                  {convertIdToAreaName(row.areaId)}
                </PowerForecastListStyledTableCell>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_plantName"}
                  align="left"
                  title={row.powerName}
                >
                  {row.powerName}
                </PowerForecastListStyledTableCell>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_solar"}
                  align="center"
                  title={convertIdToEnergyName(row.powerCategory)}
                >
                  {convertIdToEnergyName(row.powerCategory)}
                </PowerForecastListStyledTableCell>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_bgName"}
                  align="left"
                  title={row.powerBgName}
                >
                  {row.powerBgName}
                </PowerForecastListStyledTableCell>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_createDate"}
                  align="center"
                  title={row.saveDate
                    ? languageContext.convertDateToLocalizedString(
                        new Date(row.saveDate),
                        "HH:mm:ss"
                      )
                    : ""}
                >
                  {/*row.saveDate !== null && row.saveDate !== undefined
                    ? row.saveDate.toLocaleString()
            : ""*/}
                  {row.saveDate
                    ? languageContext.convertDateToLocalizedString(
                        new Date(row.saveDate),
                        "HH:mm:ss"
                      )
                    : ""}
                </PowerForecastListStyledTableCell>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_user"}
                  align="center"
                  title={row.saveUser}
                >
                  {row.saveUser}
                </PowerForecastListStyledTableCell>
                <PowerForecastListStyledTableCell
                  key={"powerForecastData_" + index + "_buttonCell"}
                  align="center"
                >
                  <StyledButton
                    key={
                      "powerForecastData_" +
                      index +
                      "_transitToPowerForecastButton"
                    }
                    sx={{ marginRight: 1.5 }}
                    style={{
                      padding: 5,
                    }}
                    onClick={() => {
                      setAreaId(row.areaId);
                      transitToOtherPage(
                        POWER_FORECAST_URL +
                          "?areaId=" +
                          row.areaId +
                          "&bgId=" +
                          row.powerBgId +
                          "&plantId=" +
                          row.powerId
                      );
                    }}
                  >
                    {languageContext.words.powerforecast}
                  </StyledButton>
                  <StyledButton
                    key={
                      "powerForecastData_" +
                      index +
                      "_transitToPowerForecastPlanButton"
                    }
                    style={{
                      padding: 5,
                    }}
                    onClick={() => {
                      setAreaId(row.areaId)
                      transitToOtherPage(
                        POWER_FORECAST_PLAN_URL + "?areaId=" + row.areaId
                      );
                    }}
                  >
                    {languageContext.words.power_gen_and_sales_plan}
                  </StyledButton>
                </PowerForecastListStyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <CustomDialog
        title={languageContext.words.power_gen_plan}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </div>
  );
};

export default PowerForecastList;
