import React, { useState, useEffect, useContext } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { addDays, getMonth, getYear, parse, subDays } from "date-fns";
import ja from "date-fns/locale/ja";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { LanguageContext } from "../../localization/localization";
import styled from "styled-components";
import { PickersDay } from "@mui/x-date-pickers";

import { PaletteContext } from "../../paletteMode";


const CustomTextField = styled(TextField)(() => ({
  height: "43px",
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
}));

const CustomModalDatePicker = (props?: any) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);

  const {PaletteMode} = useContext(PaletteContext);

  const { setValue, value, maxDate, minDate } = props;
  const [date, setDate] = useState<Date | null>(
    value === undefined ? addDays(new Date(), 1) : value
  );

  useEffect(() => {
    setDate(value === undefined ? addDays(new Date(), 1) : value);
  }, [value]);

  const isAddBtnDisabled = () => {
    let disabled = false;
    if (maxDate !== undefined) {
      if (date?.toLocaleDateString() === maxDate.toLocaleDateString()) {
        disabled = true;
      }
    }
    return disabled;
  };

  const isSubBtnDisabled = () => {
    let disabled = false;
    if (minDate !== undefined) {
      if (date?.toLocaleDateString() === minDate.toLocaleDateString()) {
        disabled = true;
      }
    }
    return disabled;
  };

  return (
    <Stack
      direction="row"
      width="fit-content"
      alignItems="center"
      sx={{ margin: 0 }}
      spacing={0.5}
    >
      <Button
        sx={{
          color: `${
            PaletteMode === "dark" ? "white" : "black"
          }`,
          height: "43px",
          minWidth: "25px",
          margin: 0,
          padding: 0,
        }}
        disabled={isSubBtnDisabled()}
        onClick={() => {
          const tempSub =
            date === null ? addDays(new Date(), 1) : (date as Date);
          setDate(subDays(tempSub, 1));
          setValue(subDays(tempSub, 1));
        }}
      >
        <ArrowLeftIcon />
      </Button>
      <Box
        sx={{
          display: "flex",
          width: "160px",
          borderRadius: "5px",
          backgroundColor: "white",
          marginBottom: "25px",
          height: "43px",
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={languageContext.mode === "jp" ? ja : undefined}
          dateFormats={
            languageContext.mode === "jp"
              ? { monthAndYear: "yyyy年 MM月" }
              : undefined
          }
        >
          <DatePicker
            minDate={minDate}
            maxDate={maxDate}
            value={date}
            onChange={(e) => {
              setDate(e);
              setValue(e);
            }}
            renderInput={(params) => (
              <CustomTextField
                size="small"
                sx={{
                  width: "100%",
                  height: "43px",
                  "& .MuiInputBase-input": {
                    caretColor: "transparent",
                  },
                }}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                  placeholder: "yyyy/mm/dd",
                  height: "43px !important",
                }}
              />
            )}
            renderDay={(day, selectedDays, pickersDayProps) => (
              <PickersDay {...pickersDayProps} sx={{ backgroundColor: "inherit" }} />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Button
        sx={{
          color: `${
            PaletteMode === "dark" ? "white" : "black"
          }`,
          height: "43px",
          minWidth: "25px",
          margin: 0,
          padding: 0,
        }}
        disabled={isAddBtnDisabled()}
        onClick={() => {
          const tempAdd =
            date === null ? addDays(new Date(), 1) : (date as Date);
          setDate(addDays(tempAdd, 1));
          setValue(addDays(tempAdd, 1));
        }}
      >
        <ArrowRightIcon />
      </Button>
    </Stack>
  );
};

export default CustomModalDatePicker;
