import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import styled from "styled-components";
import Badge from "@mui/material/Badge";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Divider from "@mui/material/Divider";
import CustomDialog, { ButtonType } from "../customComponents/CustomDialog";
import Clock from "./LiveClock";
import DropDownLink from "../customComponents/DropDownLink";
import Button from "@mui/material/Button";
import SubAlarm from "../../system/Utility/SubAlarm";
import SubNotificationList from "../../system/Utility/SubNotificationList";
import AccountInfo from "../../system/Common/AccountInfo";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PlayForWorkOutlinedIcon from "@mui/icons-material/PlayForWorkOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Backdrop from "@mui/material/Backdrop";
import { LanguageContext } from "../localization/localization";
import { io } from "socket.io-client";
import { tempAlarmType } from "../../../types/system/AlarmList";
import axios from "axios";
import { url } from "inspector";
import {
  LeavePageContext,
  ConfirmLeavePage,
} from "../customComponents/CustomConfirmLeavePage";
import {
  PLAN_STATUS_LIST_URL,
  DEMAND_FORECAST_LIST_URL,
  POWER_FORECAST_LIST_URL,
  DEMAND_MONITORING_URL,
  POWER_GENERATION_MONITORING_URL,
  SPOT_BIDDING_URL,
  INTRADAY_BIDDING_URL,
  BALANCING_PLAN_URL,
  SETTING_URL,
} from "../../../common/url";
import { useAuthedApi } from "../../../common/axios";
import { PaletteContext } from "../paletteMode";

const LinksPosition = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  text-align: right;
`;

const StyledClock = styled(Box)`
  height: 100%;
  width: auto;
  display: grid;
  font-size: 12px;
  place-items: center;
  margin-right: 10px;
  padding-right: 10px;
`;

const StyledBox = styled(Box)`
  height: 100%;
  width: 48px;
  display: grid;
  font-size: 8px;
  place-items: center;
`;

//a要素ボタン
const ButtonLink = styled(Button)`
  &.MuiButton-root {
    height: 100%;
    border-radius: 0px;
    font-size: 14px;
    z-index: 1299;
  }
  &.MuiButton-root:hover,
  &.Mui-focusVisible {
    color: white;
    background-color: #1976d2;
  }
` as typeof Button;

//ヘッダーアイコンボタン
const IconButton = styled(Button)`
  &.MuiButton-root {
    height: 100%;
    width: 48px;
    border-radius: 0px;
    display: grid;
    place-items: center;

    font-size: 8px;
    z-index: 1299;
  }
  &.MuiButton-root:hover,
  &.Mui-focusVisible {
    color: white;
    background-color: #1976d2;
  }
`;

const GET_VALID_ARALM_LIST_API = "/getValidAlarmSettingList";
const SET_SOCKETIO_ALERT_API = "/setSocketIoAlarm";
const GET_TENANTID_API = "/getTenantId";
const GET_EVENT_COUNT_API = "/getEventCount";

const HeaderContents = () => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const { PaletteMode } = useContext(PaletteContext);

  const [digOpen, setDigOpen] = useState(false);
  const [message, setMessage] = useState("");

  const api = useAuthedApi();

  const navigate = useNavigate();

  useEffect(() => {
    setAlarmToSocket();
  }, []);

  //通知定義から通知を設定
  function setAlarmToSocket() {
    console.log("setSocket");
    api
      .get(GET_VALID_ARALM_LIST_API) // 通知定義取得
      .then((response) => {
        api.get(SET_SOCKETIO_ALERT_API, { params: { list: response.data } });
      }) //cron設定
      .catch((err) => {
        console.log("失敗したよ");
      });
  }

  useEffect(() => {
    getAlarmToSocket();
  }, []);

  //ソケット通信で通知を取得
  function getAlarmToSocket() {
    api
      .get(GET_TENANTID_API) //テナントID取得
      .then((response) => {
        const tenantId = response.data;
        //ソケット通信でのイベント受信設定
        const socket = io();
        socket.on("alarm/" + tenantId, (alarmDef: tempAlarmType) => {
          setDigOpen(true);
          setMessage(alarmDef.message);
        });
      })
      .catch((err) => {
        console.log("失敗したよ");
      });
  }

  const Menues = [
    {
      text: languageContext.words.forecast_list,
      items: [
        {
          text: languageContext.words.powerforecast_list,
          to: POWER_FORECAST_LIST_URL,
        },
        {
          text: languageContext.words.demandforecast_list,
          to: DEMAND_FORECAST_LIST_URL,
        },
      ],
      icon: <ListAltOutlinedIcon />,
    },
    {
      text: languageContext.words.monitoring,
      items: [
        {
          text: languageContext.words.power_gen_imbalance_monitor,
          to: POWER_GENERATION_MONITORING_URL,
        },
        {
          text: languageContext.words.demand_imbalance_monitor,
          to: DEMAND_MONITORING_URL,
        },
      ],
      icon: <VisibilityOutlinedIcon />,
    },
    {
      text: languageContext.words.bidding,
      items: [
        { text: languageContext.words.spot_bidding, to: SPOT_BIDDING_URL },
        {
          text: languageContext.words.one_hour_ago_bidding,
          to: INTRADAY_BIDDING_URL,
        },
      ],
      icon: <SellOutlinedIcon />,
    },
  ];

  const [isInfoDigOpen, setIsInfoDigOpen] = useState(false);
  const [isAlartDigOpen, setIsAlartDigOpen] = useState(false);
  const [isUserDigOpen, setIsUserDigOpen] = useState(false);
  const [pushedBtn, setPushedBtn] = useState<HTMLButtonElement | null>(null);
  const [noticeCount, setNoticeCount] = useState<number>(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const infoBtnRef = React.useRef<HTMLButtonElement>(null);
  const alartBtnRef = React.useRef<HTMLButtonElement>(null);
  const userBtnRef = React.useRef<HTMLButtonElement>(null);
  const url = React.useRef<string | null>("");
  const { blockLeave, setBlockLeave } = useContext(LeavePageContext);

  // 設定モーダル閉じる
  const HandleClose = () => setIsUserDigOpen(false);

  React.useEffect(() => {
    api
      .get(GET_EVENT_COUNT_API)
      .then((res) => {
        console.log("res.data.count", res.data);
        setNoticeCount(res.data === null ? 0 : res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  React.useEffect(() => {
    if (infoBtnRef.current === pushedBtn) {
      setIsInfoDigOpen(true);
      setIsAlartDigOpen(false);
      setIsUserDigOpen(false);
    } else if (alartBtnRef.current === pushedBtn) {
      setIsAlartDigOpen(true);
      setIsInfoDigOpen(false);
      setIsUserDigOpen(false);
    } else if (userBtnRef.current === pushedBtn) {
      setIsUserDigOpen(true);
      setIsInfoDigOpen(false);
      setIsAlartDigOpen(false);
    } else {
      setIsInfoDigOpen(false);
      setIsAlartDigOpen(false);
      setIsUserDigOpen(false);
    }
  }, [pushedBtn]);

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget !== pushedBtn) {
      setPushedBtn(e.currentTarget);
    } else {
      setPushedBtn(null);
    }
  };

  const acceptHandler = () => {
    navigate(url.current as string);
  };

  const closeSubNotification = () => {
    setIsInfoDigOpen(false);
    setPushedBtn(null);
  };

  const closeSubAlarm = () => {
    setIsAlartDigOpen(false);
    setPushedBtn(null);
  };

  return (
    <>
      <LinksPosition>
        <React.Fragment>
          <CustomDialog
            title={languageContext.words.notification_tuti}
            message={message}
            buttonType={ButtonType.OkOnly}
            open={digOpen}
            onAccept={() => {
              /**/
            }} //OK押下で何もしない
            onClose={() => setDigOpen(false)}
          />
        </React.Fragment>
        <Typography sx={{ position: "absolute", left: "15px" }}>
          {message}
        </Typography>
        <StyledClock>
          <Clock />
        </StyledClock>
        <Divider orientation="vertical" />
        {/* TODO: fksm モック用にステータス一覧は画像にする */}
        {/*
      <ButtonLink component={Link} to={"/status"} disableFocusRipple>
        {languageContext.languageMain.status_list}
      </ButtonLink>
      */}
        <ButtonLink
          color="secondary"
          component={Link}
          to={PLAN_STATUS_LIST_URL}
          startIcon={<HomeOutlinedIcon />}
          disableFocusRipple
          onClick={(e) => {
            url.current = PLAN_STATUS_LIST_URL;
            if (blockLeave) {
              e.preventDefault();
              setConfirmOpen(true);
            } else {
              navigate(PLAN_STATUS_LIST_URL);
            }
          }}
        >
          {languageContext.words.status_list}
        </ButtonLink>
        {Menues.map((menu, index) => {
          return (
            <DropDownLink
              pushedEl={pushedBtn}
              setPushedEl={setPushedBtn}
              key={index}
              text={menu.text}
              icon={menu.icon}
              subNav={menu.items}
            />
          );
        })}
        <ButtonLink
          color="secondary"
          component={Link}
          to={BALANCING_PLAN_URL}
          startIcon={<PlayForWorkOutlinedIcon />}
          disableFocusRipple
          onClick={(e) => {
            url.current = BALANCING_PLAN_URL;
            if (blockLeave) {
              e.preventDefault();
              setConfirmOpen(true);
            } else {
              navigate(BALANCING_PLAN_URL);
            }
          }}
        >
          {languageContext.words.submitting_plan}
        </ButtonLink>
        <ButtonLink
          color="secondary"
          component={Link}
          to={SETTING_URL}
          startIcon={<SettingsOutlinedIcon />}
          disableFocusRipple
          onClick={(e) => {
            url.current = SETTING_URL;
            if (blockLeave) {
              e.preventDefault();
              setConfirmOpen(true);
            } else {
              navigate(SETTING_URL);
            }
          }}
        >
          {languageContext.words.setting}
        </ButtonLink>
        <Divider orientation="vertical" />
        <IconButton
          ref={infoBtnRef}
          onClick={clickHandler}
          disableFocusRipple
          color="secondary"
        >
          <Badge
            badgeContent={noticeCount}
            color="error"
            sx={{
              ".MuiBadge-badge": { top: "4px" },
            }}
          >
            <MailOutlineOutlinedIcon sx={{ gridRow: 1 }} />
          </Badge>
          {languageContext.words.notification}
        </IconButton>

        <IconButton
          ref={alartBtnRef}
          onClick={clickHandler}
          disableFocusRipple
          color="secondary"
        >
          <NotificationImportantOutlinedIcon sx={{ gridRow: 1 }} />
          {languageContext.words.notification_tuti}
        </IconButton>
        <IconButton
          ref={userBtnRef}
          onClick={clickHandler}
          disableFocusRipple
          color="secondary"
        >
          <AccountCircleOutlinedIcon sx={{ gridRow: 1 }} />
          {languageContext.words.user}
        </IconButton>
        <Divider orientation="vertical" />
        <StyledBox sx={{ paddingX: 0 }}>
          <img
            src={
              PaletteMode === "dark"
                ? `${process.env.PUBLIC_URL}/logo-dark.jpg`
                : `${process.env.PUBLIC_URL}/logo178.png`
            }
            alt="ロゴ画像"
            height="80%"
            width="80%"
          />
        </StyledBox>

        {/*ToDo:Popper系メニュー共通化*/}
        {/*お知らせ*/}
        <Popper
          open={isInfoDigOpen}
          anchorEl={pushedBtn}
          placement={"bottom-end"}
          sx={{ zIndex: 1300 }}
        >
          <ClickAwayListener onClickAway={closeSubNotification}>
            <Paper>
              <SubNotificationList closeHandler={closeSubNotification} />
            </Paper>
          </ClickAwayListener>
        </Popper>
        {/*{languageContext.languageMain.active_alarm}*/}
        <Popper
          open={isAlartDigOpen}
          anchorEl={pushedBtn}
          placement={"bottom-end"}
          sx={{ zIndex: 1300 }}
        >
          <ClickAwayListener onClickAway={closeSubAlarm}>
            <Paper>
              <SubAlarm closeHandler={closeSubAlarm} />
            </Paper>
          </ClickAwayListener>
        </Popper>
        {/*{languageContext.languageMain.user}*/}
        <Popper
          open={isUserDigOpen}
          anchorEl={pushedBtn}
          placement={"bottom-end"}
          sx={{ zIndex: 1300 }}
        >
          <ClickAwayListener
            onClickAway={() => {
              setIsUserDigOpen(false);
              setPushedBtn(null);
            }}
          >
            <Paper>
              <AccountInfo HaldleClose={HandleClose} />
            </Paper>
          </ClickAwayListener>
        </Popper>
      </LinksPosition>
      <ConfirmLeavePage
        open={confirmOpen}
        onAccept={acceptHandler}
        onClose={() => {
          setConfirmOpen(false);
        }}
      />
    </>
  );
};

export default HeaderContents;
