import React, { ReactNode, createContext, useState } from "react";

//Todo:認証情報の定義
type Auth = {
  token?: string;
  roles?: string[];
};

type AuthContextType = {
  auth: string;
  setAuth: React.Dispatch<React.SetStateAction<string>>;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState("");
  
  //URL直接叩く対策なので必要になったら
  /*const [auth, setAuth] = useState(() => {
    const auth = localStorage.getItem("auth");
    return null !== auth ? auth : ""
  });*/
  
  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
