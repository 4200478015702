import React, { useContext, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { StyledButtonMenu } from "../../styledComponents/styledButton";
import { LanguageContext } from "../../localization/localization";
import { AreaContext } from "../../customComponents/CustomAreaContext";
import {
  ConfirmLeavePage,
  LeavePageContext,
} from "../../customComponents/CustomConfirmLeavePage";
import {
  POWER_FORECAST_PLAN_URL,
} from "../../../../common/url";

const OneHourMenu = (props: any) => {
  //言語切り替え用データコンテキスト
  const languageContext = useContext(LanguageContext);
  const areaContext = useContext(AreaContext);
  const leavePageContext = useContext(LeavePageContext);
  const [digOpen, setDigOpen] = useState(false);
  const url = useRef<string | null>("");
  const navigate = useNavigate();

  const acceptHandler = () => {
    navigate(url.current as string);
  };

  // propsで1時間前入札画面からステータスや入札時刻、入札制限値等を受け取る
  const { areaId, getOneHourStatus, getOneHourInfo } = props;

  function transitToOtherPage(url: string) {
    navigate(url);
  }

  // ステータスを数値から文字に変換
  const convertNumToStatus = (statusNum: number) => {
    switch (statusNum) {
      case 0:
        return "入札待ち";
        break;
      case 3:
        return "未計画";
        break;
      case 1:
        return "約定待ち";
        break;
      case 2:
        return "約定済み";
        break;
      case 6:
        return "入札受付失敗";
        break;
      case 7:
        return "取消失敗";
        break;
      case 9:
        return "入札取消済み";
        break;
      default:
        return "";
        break;
    }
  };

  return (
    <Box>
      <StyledButtonMenu
        onClick={() => {
          url.current = POWER_FORECAST_PLAN_URL;
          if (areaContext.areaId !== "") {
            url.current = `${POWER_FORECAST_PLAN_URL}?areaId=${areaContext.areaId}`;
          }

          if (leavePageContext.blockLeave) {
            setDigOpen(true);
          } else {
            navigate(url.current);
          }
        }}
      >
        {languageContext.words.powerforecastplan}
      </StyledButtonMenu>

      <ConfirmLeavePage
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      ></ConfirmLeavePage>
    </Box>
  );
};

export default OneHourMenu;
