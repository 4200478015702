import React, { useState, useEffect, useContext } from "react";
import "./css/SetPowerContractList.css";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { padding, width } from "@mui/system";
import axios from "axios";
import CustomModal from "../common/customComponents/CustomModal";
import {
  StyledTable,
  StyledTableCell,
  StyledTableHeaderLG,
} from "../common/styledComponents/styledTable";
import CustomDatePickerTryal from "../common/customComponents/CustomDatePickerTryal";
import { LanguageContext } from "../common/localization/localization";
import { modalMode } from "./PowerContractList";
import { resAreaList } from "../../types/common/Search";
import { setPowerContractorInfo } from "../../types/master/SetPowerContractList";
import { addDays, format, subDays } from "date-fns";
import CustomSelectList from "../common/customComponents/CustomSelectList";
import CustomDialog, {
  ButtonType,
} from "../common/customComponents/CustomDialog";
import CustomSettingModal, {
  NumOfButton,
} from "../common/customComponents/CustomSettingModal";
import {
  CustomBoxBig,
  CustomBoxSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomBox";
import { CustomModalTypography } from "../common/customComponents/CustomMasterCompornent/CustomTypography";
import {
  CustomTextFieldBig,
  CustomTextFieldSmall,
} from "../common/customComponents/CustomMasterCompornent/CustomMasterTextField";
import CustomSelectListBig from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorBig";
import CustomSelectListSmall from "../common/customComponents/CustomMasterCompornent/CustomMasterSelectorSmall";
import CustomModalDatePicker from "../common/customComponents/CustomMasterCompornent/CustomMstDatePicker";
import { API_URL } from "../../common/constant";
import { useAreaUtility } from "../../common/area";
import { LanguageVariant } from "typescript";
import { useAuthedApi } from "../../common/axios";

const CustomTextField = styled(TextField)(() => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  height: "auto",
  width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "10px 10px",
  },
}));

type Props = {
  userId: string;
  propsShowUserCreateDialog: any;
};

type UserInfo = {
  UserId: string;
  UserName: string;
  role: number;
  mailAddress: string;
  password: string;
  eria01: boolean;
  eria02: boolean;
  eria03: boolean;
  eria04: boolean;
  eria05: boolean;
  eria06: boolean;
  eria07: boolean;
  eria08: boolean;
  eria09: boolean;
};

type role = {
  role_id: number;
  role_nm: string;
};

//入力部分（キー）がエラー状態かどうかを管理するための連想配列
type ErrorInput = {
  contractorName: boolean;
  powerContractCode: boolean;

  areaId: boolean;
  bpId: boolean;
  powerKind: boolean;
};

//ErrorInput連想配列の初期化用
const initErrorInput: ErrorInput = {
  contractorName: false,
  powerContractCode: false,

  areaId: false,
  bpId: false,
  powerKind: false,
};

const SetPowerContractList = (
  toOpen: boolean,
  HaldleClose: () => void,
  mode: modalMode,
  contractIdPK: number
) => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);

  // 通信エラー
  const api = useAuthedApi();

  //エリアリスト作成
  const { areaOptions } = useAreaUtility();

  type areaInfo = { id: string; name: string };
  //電源契約番号
  type powerKindInfo = {
    name: string; //種類
    id: number; //番号
  };

  type powerBpInfo = {
    id: number;
    bpid: number;
    name: string;
    code: string;
  };
  //初期化用
  const initInfo: setPowerContractorInfo = {
    contractId: 0,
    contractorName: "",
    powerContractCode: "",
    bpId: 0,
    powerKind: 0,
    areaId: "00",
    remarks: "",
    startDate: format(new Date(), "yyyy/MM/dd"),
    finishDate: format(new Date(), "yyyy/MM/dd"),
  };

  const [areaIdList, setAreaIdList] = useState<string[]>(["00"]);
  const powerKindInfos = React.useRef<powerKindInfo[]>([
    { id: 0, name: "未選択" }, //初期表示用
  ]);
  const powerBps = React.useRef<powerBpInfo[]>([
    {
      id: 0,
      name: languageContext.words.setting_unselected,
      bpid: 0,
      code: "",
    },
  ]);
  const editflg = React.useRef<boolean>(false); //編集用データ取得した直後かを判別するフラグ

  const [bpCode, setBpCode] = useState<string>("");
  const [bpIndex, setBpIndex] = useState<number>(0);
  const [area, setArea] = useState("未選択");
  const [userRoles, setUserRole] = useState<role[]>();
  const [powerContractorInfo, setPowerContractorInfo] =
    useState<setPowerContractorInfo>(initInfo);
  const [startDate, setStartDate] = useState<Date>(new Date());

  const [finishDate, setFinishDate] = useState<Date>(addDays(new Date(), 1));

  const [dialogMessage, setDialogMessage] = useState("");
  const [digOpen, setDigOpen] = useState(false);
  const [jobClear, setJobClear] = useState(false); //成功した（trueになった）ときモーダルをとじる
  const [inputError, setInputError] = useState<ErrorInput>(initErrorInput); //各必須入力項目にエラー属性を付与するか否かを管理する

  ////成功したときだけモーダル閉じる
  useEffect(() => {
    if (digOpen === false && jobClear === true) {
      setJobClear(false);
      HaldleClose();
    }
  }, [digOpen]); //初期化
  ////

  useEffect(() => {
    getPowerBp();

    powerKindInfos.current = powerKindInfos.current.concat(volLevels);
  }, []); //初期化

  useEffect(() => {
    if (toOpen === true) {
      powerBps.current[0] = {
        id: 0,
        name: languageContext.words.setting_unselected,
        bpid: 0,
        code: "",
      };

      if (mode == modalMode.editMode) {
        getDataLinkedContractId(contractIdPK);
      } else {
        setPowerContractorInfo(initInfo);
        setBpIndex(0);
        setBpCode("");
        setStartDate(new Date());
        setFinishDate(addDays(new Date(), 1));
      }
    } else {
      setAreaIdList(["00"]);
      setPowerContractorInfo(initInfo);
      setBpIndex(0);
      setBpCode("");
      setStartDate(new Date());
      setFinishDate(addDays(new Date(), 1));
      setInputError(initErrorInput);
    }
  }, [toOpen]); //初期化

  //開始datePicker
  useEffect(() => {
    const tmpInfo: setPowerContractorInfo = Object.assign(
      {},
      powerContractorInfo
    );
    tmpInfo.startDate = format(startDate, "yyyy/MM/dd");
    setPowerContractorInfo(tmpInfo);
  }, [startDate]);

  ////終了datePicker
  useEffect(() => {
    const tmpInfo: setPowerContractorInfo = Object.assign(
      {},
      powerContractorInfo
    );
    tmpInfo.finishDate == null
      ? (tmpInfo.finishDate = "")
      : (tmpInfo.finishDate = format(finishDate, "yyyy/MM/dd"));
    setPowerContractorInfo(tmpInfo);
  }, [finishDate]);

  ////発電事業者変化したときエリアプルダウンの中身再取得
  useEffect(() => {
    //再取得時、選択してたエリアを「未選択」に初期化する
    //ただし、編集用データ取得直後は初期化しない
    if (editflg.current == false) {
      const tmpInfo: setPowerContractorInfo = Object.assign(
        {},
        powerContractorInfo
      );
      tmpInfo.areaId = "00";
      setPowerContractorInfo(tmpInfo);
    } else {
      editflg.current = false;
    }

    setAreaIdList(["00"]);
    getAreaMst(bpIndex);
  }, [bpIndex]); //初期化
  ////

  /////////エリア関連

  const getAreaMst = (bpIndex: number) => {
    api
      .get(API_URL.GET_BP_AREA, {
        params: { bpId: powerBps.current[bpIndex].bpid },
      })
      .then((response) => {
        setAreaIdList(convertAreaData(response.data as resAreaList[]));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const convertAreaData = (result: resAreaList[]) => {
    const retAreaData: string[] = ["00"];
    result.map((data) => {
      const areaData: areaInfo = { id: "", name: "" };
      areaData.id = data.areaId.toString();
      retAreaData.push(areaData.id);
    });
    return retAreaData;
  };

  function setAreaId(id: number) {
    //numberを文字列にしてコピーしたdemandListInfoのIDにセットする
    const areaIdString = ("00" + id).slice(-2).toString();
    const tmpAreaInfo: setPowerContractorInfo = Object.assign(
      {},
      powerContractorInfo
    );
    tmpAreaInfo.areaId = areaIdString;

    setPowerContractorInfo(tmpAreaInfo);
  }

  /////////エリア関連

  //事業者取得
  const getPowerBp = () => {
    api.get(API_URL.GET_POWER_BP).then((res) => {
      powerBps.current = powerBps.current.concat(
        res.data.map((v: any, index: number): powerBpInfo => {
          return {
            id: index + 1,
            bpid: v.bpid,
            name: v.name,
            code: v.code,
          };
        })
      );
      //console.log(powerBps.current);
    });
  };

  const onChangePowerBp = (id: number) => {
    const tmp: setPowerContractorInfo = Object.assign({}, powerContractorInfo);
    const tempBpInfo = powerBps.current as powerBpInfo[];
    tmp.bpId = tempBpInfo[id].bpid;
    setBpCode(tempBpInfo[id].code);
    setBpIndex(id);
    setPowerContractorInfo(tmp);
  };

  /////契約種別番号
  const volLevels: powerKindInfo[] = [
    { name: "通常", id: 1 },
    { name: "他社調達", id: 2 },
  ];

  function SetPowerKind(id: number) {
    const tmpContractorInfo: setPowerContractorInfo = Object.assign(
      {},
      powerContractorInfo
    );
    const tmpPowerKindInfos: powerKindInfo[] = powerKindInfos.current;
    tmpContractorInfo.powerKind = tmpPowerKindInfos[id].id;
    setPowerContractorInfo(tmpContractorInfo);
  }

  //////契約種別指定番号ここまで

  //テキストフィールドの中身が変化したら、powerContractorInfoの中身をそのたびに変える
  function onChangeValue(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    const name: string = e.currentTarget.name;
    const value: string = e.currentTarget.value;

    const tmpInfo: setPowerContractorInfo = Object.assign(
      {},
      powerContractorInfo
    );
    switch (name) {
      case "contractorName":
        tmpInfo.contractorName = value;
        break;
      case "remarks":
        tmpInfo.remarks = value;
        break;
      case "powerContractCode":
        tmpInfo.powerContractCode = value;
        break;
      default:
        break;
    }
    setPowerContractorInfo(tmpInfo);
  }

  // 削除(論理削除)
  const Delete = (contractorId: number | undefined) => {
    // 削除API呼出
    //console.log(powerContractorInfo);
    api
      .post(API_URL.DELETE_POWERCONTRACTOR_INFO, { contractorId: contractorId })
      .then((response) => {
        //console.log(`BG1件削除結果: ${response.data.result}`);
        setJobClear(true);
        setDialogMessage(languageContext.words.power_contractor_deleted);
        setDigOpen(true);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(languageContext.words.power_contractor_failed_delete);
        setDigOpen(true);
      });
  };

  const DoModeJob = () => {
    if (mode == modalMode.addMode) {
      AddDatas();
    } else {
      UpdateDatas();
    }
  };

  // 描画している内容を保存する
  const AddDatas = () => {
    //console.log(powerContractorInfo);
    let error = false;

    error = isCorrectInfo();

    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_new_registration);
      setDigOpen(true);
    } else if (error === false) {
      const url = api
        .post(API_URL.ADD_POWERCONTRACTOR_INFO, powerContractorInfo)
        .then((res) => {
          if (res.data) {
            setJobClear(true);
            setDialogMessage(languageContext.words.power_contractor_added);
          } else {
            setDialogMessage(
              languageContext.words.power_contractor_err_message_1
            );
          }
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(languageContext.words.power_contractor_failed_add);
          setDigOpen(true);
        });
    }
  };
  //console.log(powerContractorInfo);
  //正しい情報かどうか検査
  const isCorrectInfo = (): boolean => {
    let error = false;

    const tempInputErrors = Object.assign({}, initErrorInput);
    if (powerContractorInfo?.contractorName == "") {
      error = true;
      tempInputErrors.contractorName = true;
    }
    if (powerContractorInfo?.powerContractCode == "") {
      error = true;
      tempInputErrors.powerContractCode = true;
    }
    if (powerContractorInfo?.areaId == "00") {
      error = true;
      tempInputErrors.areaId = true;
    }
    if (powerContractorInfo?.bpId == 0) {
      error = true;
      tempInputErrors.bpId = true;
    }
    if (powerContractorInfo?.powerKind == 0) {
      error = true;
      tempInputErrors.powerKind = true;
    }

    setInputError(tempInputErrors);
    return error;
  };
  // 発電事業者1レコード取得
  const getDataLinkedContractId = (contractorId: number) => {
    //console.log("発電事業者1レコード取得します");
    let responseData: setPowerContractorInfo;
    api
      .get(API_URL.GET_RECORD_CONTRACTOR, {
        params: { contractorId: contractorId },
      })
      .then((response) => {
        editflg.current = true;
        responseData = response.data.result as setPowerContractorInfo;
        //setPowerBpInfoForEdit(responseData); //セレクターを合わせる
        //console.log(responseData);
        setPowerContractorInfo(responseData);
        const bp = powerBps.current.find((v) => v.bpid === responseData.bpId);
        setBpCode(bp?.code ? bp.code : "");
        setBpIndex(bp?.id ? bp.id : 0);
        setStartDate(new Date(responseData.startDate));
        setFinishDate(new Date(responseData.finishDate));
      })
      .catch((err) => {
        console.log(err);
        setPowerContractorInfo(initInfo);
      });
  };

  //データを更新する
  function UpdateDatas() {
    //console.log(powerContractorInfo);
    let error = false;

    error = isCorrectInfo();
    if (error === true) {
      setDialogMessage(languageContext.words.setting_failed_update);
      setDigOpen(true);
    } else if (error === false) {
      const url = api
        .post(API_URL.UPDATE_POWERCONTRACTOR_INFO, powerContractorInfo)
        .then((res) => {
          setDigOpen(true);
          if (res.data) {
            setJobClear(true);
            setDialogMessage(languageContext.words.power_contractor_updated);
          } else {
            setDialogMessage(
              languageContext.words.power_contractor_err_message_1
            );
          }
          setDigOpen(true);
        })
        .catch((err) => {
          console.log(err);
          setDialogMessage(
            languageContext.words.power_contractor_failed_update
          );
          setDigOpen(true);
        });
    }
  }

  // 電源契約者のリスト作成
  const createPowerContractorList = () => {
    return [
      { id: 0, name: languageContext.words.setting_unselected },
      { id: 1, name: languageContext.words.power_contractor_common },
      {
        id: 2,
        name: languageContext.words
          .power_contractor_other_companies_procurement,
      },
    ];
  };

  // メッセージダイアログOK後の挙動
  const acceptHandler = () => {
    // 特になし
  };

  //console.log(powerContractorInfo);

  return (
    <CustomSettingModal
      title={languageContext.words.generation_contractors_setting}
      open={toOpen}
      onAcceptLeft={() => DoModeJob()}
      onAcceptRight={() => Delete(powerContractorInfo?.contractId)}
      onClose={() => HaldleClose()}
      btnTextLeft={languageContext.words.registration}
      btnTextRight={languageContext.words.delete}
      btnType={
        mode === modalMode.addMode ? NumOfButton.Single : NumOfButton.Double
      }
    >
      <Stack sx={{ height: "630px", width: "550px" }}>
        <Stack direction="column" spacing={0}>
          <CustomBoxBig>
            <CustomModalTypography gutterBottom>
              {languageContext.words.gen_contractors_name}
            </CustomModalTypography>
            <CustomTextFieldBig
              type="text"
              value={powerContractorInfo?.contractorName}
              onChange={onChangeValue}
              name="contractorName"
              inputProps={{
                maxLength: 64,
              }}
              error={inputError.contractorName}
              helperText={languageContext.words.setting_required}
            ></CustomTextFieldBig>
          </CustomBoxBig>

          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.plan_submitter_code}
            </CustomModalTypography>
            <CustomTextFieldSmall
              type="text"
              value={powerContractorInfo?.powerContractCode}
              onChange={onChangeValue}
              name="powerContractCode"
              inputProps={{
                maxLength: 5,
              }}
              error={inputError.powerContractCode}
              helperText={languageContext.words.setting_required}
            ></CustomTextFieldSmall>
          </CustomBoxSmall>
          <Stack />

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.power_producer}
              </CustomModalTypography>
              <CustomSelectListBig
                value={bpIndex}
                options={powerBps.current}
                onChange={(e: number) => {
                  onChangePowerBp(e);
                }}
                error={inputError.bpId}
              ></CustomSelectListBig>
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.power_bp_code}
              </CustomModalTypography>
              <CustomTextFieldSmall
                type="text"
                value={bpCode}
                onChange={onChangeValue}
                disabled={true}
              ></CustomTextFieldSmall>
            </CustomBoxSmall>
          </Stack>

          <Stack direction="row" spacing={0}>
            <CustomBoxSmall></CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.connection_area}
              </CustomModalTypography>

              <CustomSelectListSmall // エリア
                name="volLevel"
                label={languageContext.words.none}
                value={powerContractorInfo?.areaId}
                options={areaOptions(areaIdList)}
                input={<OutlinedInput label="Name" />}
                onChange={(e: number) => {
                  if (
                    ("00" + e).slice(-2).toString() ==
                    powerContractorInfo?.areaId
                  ) {
                    return;
                  }
                  setAreaId(e);
                }}
                error={inputError.areaId}
              ></CustomSelectListSmall>
            </CustomBoxSmall>
          </Stack>
          <CustomBoxSmall>
            <CustomModalTypography gutterBottom>
              {languageContext.words.power_contractor}
            </CustomModalTypography>

            <CustomSelectListSmall // 電源契約種別
              name="volLevel"
              label={languageContext.words.none}
              value={powerContractorInfo?.powerKind}
              options={createPowerContractorList()}
              input={<OutlinedInput label="Name" />}
              onChange={SetPowerKind}
              error={inputError.powerKind}
            ></CustomSelectListSmall>
          </CustomBoxSmall>
          <Stack direction="row" spacing={0}>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.effective_date_start_date}
              </CustomModalTypography>
              <CustomModalDatePicker
                setValue={setStartDate}
                value={startDate}
                maxDate={subDays(finishDate, 1)}
              />
            </CustomBoxSmall>
            <CustomBoxSmall>
              <CustomModalTypography gutterBottom>
                {languageContext.words.expiration_date}
              </CustomModalTypography>
              <CustomModalDatePicker
                setValue={setFinishDate}
                value={finishDate}
                minDate={addDays(startDate, 1)}
              />
            </CustomBoxSmall>
          </Stack>
          <Stack direction="row" spacing={0}>
            <CustomBoxBig>
              <CustomModalTypography gutterBottom>
                {languageContext.words.remarks}
              </CustomModalTypography>
              <CustomTextFieldBig
                type="text"
                value={powerContractorInfo?.remarks}
                onChange={onChangeValue}
                name="remarks"
                inputProps={{
                  maxLength: 256,
                }}
              ></CustomTextFieldBig>
            </CustomBoxBig>
          </Stack>
        </Stack>
      </Stack>
      <CustomDialog
        title={""}
        message={dialogMessage}
        buttonType={ButtonType.OkOnly}
        open={digOpen}
        onAccept={acceptHandler}
        onClose={() => setDigOpen(false)}
      />
    </CustomSettingModal>
  );
};

export default SetPowerContractList;
