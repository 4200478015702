import React, { useContext, useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import MarketDetails from "./MarketDetails";
import BulkUndo from "./BulkUndo";
import BulkBit from "./BulkBit";
import Bid from "./Bid";
import BidDelete from "./BidDelete";
import AutomationSettings from "./AutomationSettings";
import BidLimitSetting from "./BidLimitSetting";
// import { areaData } from "./TestData";
import { testData } from "./0314_OneHourTestData";
import CommonDialog, {
  ButtonType,
} from "../../common/customComponents/CustomDialog";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { TimeTable } from "./CreateTime";
import CustomSelectList from "../../common/customComponents/CustomSelectList";
import { StyledButton } from "../../common/styledComponents/styledButton";
import {
  StyledTable,
  StyledTableHeaderLG,
  StyledTableCell,
} from "../../common/styledComponents/styledTable";
import {
  DateContext,
  GlobalDatePicker,
} from "../../common/customComponents/GlobalDatePicker";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import SaveDataButton from "../../common/customComponents/SaveDataButton";
import { addDays, format, subMinutes } from "date-fns";
import { resHourBid } from "../../../../../backend/types/jepx/OneHourAdvanceBidPlan";
import { LanguageContext } from "../../common/localization/localization";
import { AreaContext } from "../../common/customComponents/CustomAreaContext";
import CustomDialog from "../../common/customComponents/CustomDialog";
import { io } from "socket.io-client";
import { LeavePageContext } from "../../common/customComponents/CustomConfirmLeavePage";
import { TimeContext } from "../../common/globalMenu/LiveClock";
import * as PfpType from "../../../types/occto/PowerForecastPlan";
import AutoDeleteSettings from "./AutoDeleteSetting";
import { Table, Typography } from "@mui/material";
import OneHourMenu from "../../common/menuInfo/menuItemConponents/OneHourMenu";
import { RoleContext } from "../../common/customComponents/RoleProvider";
import { PaletteContext } from "../../common/paletteMode";
import { useAreaUtility } from "../../../common/area";
import {
  AUTO_BIDDING,
  CELLCOUNT,
  CONTRACT_STATUS,
  GET_AREA_NAME_LIST,
  GET_AUTO_SETTING,
  GET_BID_LIMIT,
  GET_INTRADAY_PRODUCT_URL,
  GET_PFP_POWER_URL,
  GET_PFP_PROVIDE_URL,
  GET_PFP_SALE_URL,
  GET_PROCUREMENT_DATA,
  GET_ROLE_JUDGMENT_URL,
  GET_SALES_DATA,
  INTRADAY_DATA_URL,
  PLAN_MAX_VALUE,
  POST_AUTO_SETTING,
  POST_INTRADAY_CONTRACT_URL,
  POST_PROCUREMENT_PLAN,
  POST_SALES_PLAN,
  UPDATE_AFTER_GC_STATUS,
} from "./IntradayConstant";
import { updatePostfix } from "typescript";
import { useAuthedApi } from "../../../common/axios";

//30分刻みの時間の文字列の配列
const timeData = TimeTable();

//テーブルのヘッダー
const HeaderCell = styled(StyledTableHeaderLG)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 4px 0px;
    font-size: 14px;
  }
`;

//数値のヘッダー
const AmountHedderCell = styled(HeaderCell)`
  && {
    width: 74px;
    height: 75px;
    white-space: nowrap;
  }
`;

//テーブルのセル
const TableCell = styled(StyledTableCell)`
  && {
    text-align: center;
    padding: 5px 0px;
    font-size: 14px;
    height: 25px;
  }
`;

//74pxに合わせるテーブルのセル
const AmountTableCell = styled(TableCell)`
  && {
    width: 75px;
    text-align: right;
    padding-right: 8px;
    box-sizing: border-box;
  }
`;

// 入札制限
export interface bitLimmitReq {
  area: number;
  // target: number, // 売り or 買い
  dayLimitBit: bitLimitCells[];
}

// 1日の48コマ(制限)
export interface bitLimitCells {
  type: number;
  cells: bitLimitCell[];
}

// 1コマ(制限)
export interface bitLimitCell {
  upperPowerVolume: string;
  lowerPowerVolume: string;
  upperPowerPrice: string;
  lowerPowerPrice: string;
}

type searchInfo = { id: number; name: string };

export type createAutomationSettingsListData = {
  deadlineTime: searchInfo[];
  priceAdjustment: searchInfo[];
  deleteTime: searchInfo[];
  startTime: searchInfo[];
  adjustmentCycle: searchInfo[];
};

//1時間前入札
const IntradayBidding = () => {
  //言語切り替え用のデータコンテキスト
  const languageContext = useContext(LanguageContext);

  const { areaId, setAreaId } = useContext(AreaContext);

  const leavePageContext = useContext(LeavePageContext);
  // ログイン中ユーザのロール
  const roleContext = useContext(RoleContext);

  // ダークモード対応
  const { PaletteMode } = useContext(PaletteContext);

  const api = useAuthedApi();

  //自動入札設定の選択肢
  const AutomationSettingsList: createAutomationSettingsListData = {
    deadlineTime: [
      { id: 0, name: languageContext.words.intraday_auto_setting_1 },
      { id: 1, name: languageContext.words.intraday_auto_setting_2 },
      { id: 2, name: languageContext.words.intraday_auto_setting_3 },
      { id: 3, name: languageContext.words.intraday_auto_setting_4 },
      { id: 4, name: languageContext.words.intraday_auto_setting_5 },
      { id: 5, name: languageContext.words.intraday_auto_setting_6 },
      { id: 6, name: languageContext.words.intraday_auto_setting_7 },
      { id: 7, name: languageContext.words.intraday_auto_setting_8 },
      { id: 8, name: languageContext.words.intraday_auto_setting_9 },
      { id: 9, name: languageContext.words.intraday_auto_setting_10 },
      { id: 10, name: languageContext.words.intraday_auto_setting_11 },
    ],
    priceAdjustment: [
      { id: 0, name: languageContext.words.intraday_auto_deletion_enabled },
      { id: 1, name: languageContext.words.intraday_auto_deletion_unenabled },
    ],
    deleteTime: [
      { id: 0, name: languageContext.words.intraday_auto_deletion_enabled },
      { id: 1, name: languageContext.words.intraday_auto_deletion_unenabled },
    ],
    startTime: [
      { id: 0, name: languageContext.words.intraday_auto_setting_1 },
      { id: 1, name: languageContext.words.intraday_auto_setting_2 },
      { id: 2, name: languageContext.words.intraday_auto_setting_3 },
      { id: 3, name: languageContext.words.intraday_auto_setting_4 },
      { id: 4, name: languageContext.words.intraday_auto_setting_5 },
      { id: 5, name: languageContext.words.intraday_auto_setting_6 },
      { id: 6, name: languageContext.words.intraday_auto_setting_7 },
      { id: 7, name: languageContext.words.intraday_auto_setting_8 },
      { id: 8, name: languageContext.words.intraday_auto_setting_9 },
      { id: 9, name: languageContext.words.intraday_auto_setting_10 },
      { id: 10, name: languageContext.words.intraday_auto_setting_11 },
    ],
    adjustmentCycle: [
      { id: 0, name: languageContext.words.intraday_auto_cycle_1 },
      { id: 1, name: languageContext.words.intraday_auto_cycle_2 },
      { id: 2, name: languageContext.words.intraday_auto_cycle_3 },
      { id: 3, name: languageContext.words.intraday_auto_cycle_4 },
      { id: 4, name: languageContext.words.intraday_auto_cycle_5 },
      { id: 5, name: languageContext.words.intraday_auto_cycle_6 },
    ],
  };

  const changeFlag = React.useRef<boolean>(false);

  const { time } = useContext(TimeContext);

  const startDate = React.useRef<Date>(
    new Date(time.getFullYear(), time.getMonth(), time.getDate(), 17)
  );

  //エリアリスト
  const [areaList, setAreaList] = React.useState<string[]>([]);

  const { areaOptions, convertIdToAreaName } = useAreaUtility();

  //自動化設定ボタン
  const [automationSetting, setAutomationSetting] = React.useState(false);

  //入札締切・削除設定ボタン
  const [autoDeleteSetting, setAutoDeleteSetting] = React.useState(false);

  //画面の表示データ制御
  const [displayData, setDisplayData] = React.useState(testData[0]);
  //自動価格調整のスイッチ
  const [switchActivity, setSwitchActivity] = React.useState(false);
  //自社状況のニューメリックアップダウンの活性・非活性
  const [inputNumberActivity, setInputNumberActivity] = React.useState(true);
  //入札状況テーブルのセルの番号
  const [cellNumber, setCellNumber] = React.useState(0);
  // テキストフィールドの小数点以下0埋めして表示するためのstate
  const [padSellingPriceText, setPadSellingPriceText] = useState<string[]>(
    new Array(48).fill(true).map((_) => "0.00")
  );
  // テキストフィールドの小数点以下0埋めして表示するためのstate
  const [padProcurementPriceText, setPadProcurementPriceText] = useState<
    string[]
  >(new Array(48).fill(true).map((_) => "0.00"));

  //調達計画が存在するかのフラグ
  const procurementFlag = React.useRef<boolean>(false);
  //DatePickerで選択可能な最大日付
  const [maxDate, setMaxDate] = React.useState<Date>(time);

  const hourEriaBitInfoData: resHourBid = {
    targetDate: "",
    area: 0,
    areaValue: {
      targetDate: "",
      area: 0,
      blockNumber: 0,
      dayBit: new Array(48).fill(true).map((_, index) => ({
        saleBitValue: 0,
        saleBitLowPrice: 0,
        buyBitValue: 0,
        buyBitHighPrice: 0,
        newExecutionPrice: 0,
        averageExecutionPrice: 0,
        productStatus: "0",
      })),
    },
    companyValue: {
      targetDate: "",
      area: 0,
      blockNumber: 0,
      dayBit: new Array(48).fill(true).map((_, index) => ({
        saleBitValue: 0,
        salesContractValue: 0,
        saleBitPrice: 0,
        salesContractPrice: 0,
        buyBitValue: 0,
        buyContractValue: 0,
        buyBitPrice: 0,
        buyContractPrice: 0,
      })),
    },
    bidingValue: {
      targetDate: "",
      area: 0,
      blockNumber: 0,
      dayBit: new Array(48).fill(true).map((_, index) => ({
        type: "",
        bidValue: 0,
        contractValue: 0,
        bidPrice: 0,
        contractPrice: 0,
        contractStatus: "",
      })),
    },
  };

  //DBから来たデータ
  const [responseData, setResponseData] =
    useState<resHourBid>(hourEriaBitInfoData);

  // DBからデータ取得
  //datePecker
  const dateValue = useContext(DateContext);

  // 入札制限データ
  const [bidLimit, setBidLimit] = useState<bitLimmitReq>({
    area: Number(areaId),
    dayLimitBit: new Array(2).fill(true).map((_, index) => {
      const data: bitLimitCells = {
        type: index + 1,
        cells: new Array(48).fill(true).map((_) => ({
          upperPowerVolume: "0.00",
          lowerPowerVolume: "0.00",
          upperPowerPrice: "0.00",
          lowerPowerPrice: "0.00",
        })),
      };
      return data;
    }),
  });

  //自動価格調整のセレクトボックス
  const [priceAdjustment, setPriceAdjustment] = React.useState<string>("");
  //入札締め切り時間のセレクトボックス
  const [deadlineTime, setDeadlineTime] = React.useState<string>("");
  //自動札削除のセレクトボックス
  const [autoDelete, setAutoDelete] = React.useState<string>("");
  //開始時間のセレクトボックス
  const [startTime, setStartTime] = React.useState<string>("");
  //調整周期のセレクトボックス
  const [adjustmentCycle, setAdjustmentCycle] = React.useState<string>("");
  //調整量のテキストフィールド
  // const [adjustmentAmount, setAdjustmentAmount] = React.useState<string>("");
  const [holdeAdjustmentAmount, setHoldeAdjustmentAmount] =
    React.useState<number>(0);
  // DB編集系ボタンの表示・非表示
  const [hidden, setHidden] = useState<boolean>(true);

  // ログインユーザのロールによって保存ボタンの表示・非表示を切り替える
  const dicideSaveButtonVisibility = () => {
    api
      .get(GET_ROLE_JUDGMENT_URL, { params: { userRole: roleContext.role } })
      .then((res) => {
        setHidden(res.data ? false : true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 一時間前入札画面のデータ取得
  const getIntradayData = async () => {
    await api
      .get(INTRADAY_DATA_URL, {
        params: {
          targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
          areaId: areaId,
        },
      })
      .then((res) => {
        ChartData(res.data.reqHourBitData);
        getAutoSetting(res.data.reqHourBitData);
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 504) {
          setDialogMessage(languageContext.words.could_not_communicate_jepx);
        } else {
          setDialogMessage(languageContext.words.get_error);
        }
        setDigOpen(true);
      });
  };

  // 1時間前商品一覧取得
  const getIntradayProduct = async () => {
    await api
      .get(GET_INTRADAY_PRODUCT_URL, {
        params: {
          targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
          areaId: areaId,
        },
      })
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 約定結果反映
  const setContractOrder = async () => {
    await api
      .post(POST_INTRADAY_CONTRACT_URL, {
        areaId: areaId,
        targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
      })
      .then((res) => {
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //テーブルの色生成
  const TableColor = (nowColor: string, OKColor: string, NGColor: string) => {
    const colorData = [];
    if (dateValue.pickDate != null) {
      //今の時間の全ての経過日数・時間(分)を求める
      const nowTime = time;
      const nowYear = nowTime.getFullYear();
      const nowMonth = nowTime.getMonth() + 1;
      let numberOfDaysElapsed = 0;
      for (let i = 1; i < nowMonth; i++) {
        const lastDate = new Date(nowYear, i, 0);
        numberOfDaysElapsed += lastDate.getDate();
      }
      numberOfDaysElapsed += nowTime.getDate();
      numberOfDaysElapsed += (nowYear - 1) * 365;
      const elapsedMinutes =
        numberOfDaysElapsed * 24 * 60 +
        nowTime.getHours() * 60 +
        nowTime.getMinutes() +
        90;

      //DatePickerの全ての経過日数・時間(分)を求める
      const pickerYear = dateValue.pickDate?.getFullYear();
      const pickerMonth = dateValue.pickDate?.getMonth() + 1;
      let pickerNumberOfDaysElapsed = 0;
      for (let i = 1; i < pickerMonth; i++) {
        const lastDate = new Date(pickerYear, i, 0);
        pickerNumberOfDaysElapsed += lastDate.getDate();
      }
      pickerNumberOfDaysElapsed += dateValue.pickDate?.getDate();
      pickerNumberOfDaysElapsed += (pickerYear - 1) * 365;
      let pickerElapsedMinutes = pickerNumberOfDaysElapsed * 24 * 60;

      //色を求める
      for (let i = 0; i < 48; i++) {
        if (
          pickerElapsedMinutes < elapsedMinutes &&
          elapsedMinutes <= pickerElapsedMinutes + 30
        ) {
          colorData[i] = nowColor;
        } else if (elapsedMinutes <= pickerElapsedMinutes) {
          colorData[i] = OKColor;
        }
        if (
          pickerElapsedMinutes + 30 <= elapsedMinutes ||
          format(dateValue.pickDate, "yyyy-MM-dd") >
            format(addDays(time, 1), "yyyy-MM-dd") ||
          (startDate.current > time &&
            format(dateValue.pickDate, "yyyy-MM-dd") ===
              format(addDays(time, 1), "yyyy-MM-dd"))
        ) {
          colorData[i] = NGColor;
        }
        pickerElapsedMinutes += 30;
      }
    }
    return colorData;
  };

  // GC後の商品で約定されていないもののステータスを「出来ず」に変更
  const updateGCOrderStatus = async () => {
    const tableColor = TableColor(
      "rgb(255,255,255)",
      "rgb(255,255,255)",
      "rgb(200,200,200)"
    );

    const afterGCItem: string[] = [];

    responseData.bidingValue.dayBit.forEach((value, index) => {
      if (tableColor[index] === "rgb(200,200,200)") {
        afterGCItem.push((index + 1).toString().padStart(2, "0"));
      }
    });

    if (afterGCItem.length !== 0) {
      await api
        .post(UPDATE_AFTER_GC_STATUS, {
          areaId: areaId,
          targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
          itemArray: afterGCItem,
        })
        .then((res) => {
          //
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  React.useLayoutEffect(() => {
    dateValue.setPickDate(time);
  }, []);

  useEffect(() => {
    dicideSaveButtonVisibility();
    getAreaList();
    leavePageContext.setBlockLeave(false);
  }, []);

  useEffect(() => {
    if (time >= startDate.current) {
      setMaxDate(addDays(time, 1));
    } else {
      setMaxDate(time);
    }
    startDate.current = new Date(
      time.getFullYear(),
      time.getMonth(),
      time.getDate(),
      17
    );
    if (
      areaId !== "" &&
      areaId !== "00" &&
      format(maxDate, "yyyy-MM-dd HH:mm") >=
        format(dateValue.pickDate as Date, "yyyy-MM-dd HH:mm")
    ) {
      setContractOrder();
      updateGCOrderStatus();
      getIntradayProduct();
      getIntradayData();
    }
  }, [time, dateValue.pickDate, areaId]);

  useEffect(() => {
    if (
      areaId !== "" &&
      areaId !== "00" &&
      format(maxDate, "yyyy-MM-dd HH:mm") >=
        format(dateValue.pickDate as Date, "yyyy-MM-dd HH:mm")
    ) {
      getSalesPlan();
      getProcurementPlan();
      getLimitData();
    }
  }, [dateValue.pickDate, areaId]);

  const getLimitData = () => {
    if (areaId !== "" && areaId !== "00") {
      api
        .get(GET_BID_LIMIT, {
          params: {
            areaId: areaId,
            targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
          },
        })
        .then((response) => {
          const bidLimit: bitLimmitReq = response.data;
          setBidLimit({
            area: Number(areaId),
            dayLimitBit: bidLimit.dayLimitBit.map((value) => {
              const data: bitLimitCells = {
                type: value.type,
                cells: value.cells.map((item) => ({
                  upperPowerVolume: Number(item.upperPowerVolume).toFixed(2),
                  lowerPowerVolume: Number(item.lowerPowerVolume).toFixed(2),
                  upperPowerPrice: Number(item.upperPowerPrice).toFixed(2),
                  lowerPowerPrice: Number(item.lowerPowerPrice).toFixed(2),
                })),
              };
              return data;
            }),
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // DBから取得したレスポンスの商品受付状況から市場情報テーブルに表示する記号を作成
  const createMarketDisplayData = (response: resHourBid) => {
    const tempDayBid = response.areaValue.dayBit;
    tempDayBid.forEach((dayBid) => {
      switch (dayBid.productStatus) {
        case "1":
          dayBid.productStatus = "〇";
          break;
        case "2":
          dayBid.productStatus = "◎";
          break;
        case "3":
          dayBid.productStatus = "✕";
          break;
        default:
          dayBid.productStatus = "";
      }
    });
    return tempDayBid;
  };

  // DBから取得したレスポンスの売買種別から入札状況テーブルに表示する文字列を作成
  const createTypeText = (type: string) => {
    let typeText: string;
    if (type === "1") {
      typeText = languageContext.words.sell;
    } else if (type === "2") {
      typeText = languageContext.words.buy;
    } else {
      typeText = "";
    }

    return typeText;
  };

  // DBから取得したレスポンスの注文ステータスから入札状況テーブルに表示する文字列を作成
  const createStatusText = (status: string) => {
    const AgreementContent = [
      languageContext.words.waiting_for_bid, //入札待ち
      languageContext.words.waiting_for_contract, //約定待ち
      languageContext.words.under_contract, //約定中
      languageContext.words.contracted, //"約定済"
      languageContext.words.deleted, //"取消済"
      languageContext.words.intraday_bid_failure, //入札失敗
      languageContext.words.unable_to_do, //出来ず
      languageContext.words.partial_contracted, //一部約定
      languageContext.words.delete_request, // 取消要求
      languageContext.words.delete_failed, // 取り消し失敗
    ];

    let statusText: string;

    switch (status) {
      case "0": //入札前
        statusText = AgreementContent[0];
        break;
      case "1": //入札済
        statusText = AgreementContent[1];
        break;
      case "2": //"約定済"
        statusText = AgreementContent[3];
        break;
      case "3": //取消要求
        statusText = AgreementContent[8];
        break;
      case "4": //"取消済";
        statusText = AgreementContent[4];
        break;
      case "5": //"入札失敗";
        statusText = AgreementContent[5];
        break;
      case "6": //取消失敗
        statusText = AgreementContent[9];
        break;
      case "7": //出来ず
        statusText = AgreementContent[6];
        break;
      default:
        statusText = "";
    }

    return statusText;
  };

  function ChartData(responseData: resHourBid) {
    if (responseData !== undefined) {
      responseData.areaValue.dayBit = createMarketDisplayData(responseData);
      setResponseData(responseData);
    }
  }

  const getSalesPlan = () => {
    api
      .get(GET_SALES_DATA, {
        params: {
          areaId: areaId,
          targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
        },
      })
      .then((res) => {
        const numVolumes: number[] = res.data.cellVolumes;
        const stringVolumes: string[] =
          numVolumes.length !== 0
            ? numVolumes.map((value) => value.toFixed(2))
            : new Array(48).fill(true).map((_) => "0.00");
        setPadSellingPriceText(stringVolumes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProcurementPlan = () => {
    api
      .get(GET_PROCUREMENT_DATA, {
        params: {
          areaId: areaId,
          targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
        },
      })
      .then((res) => {
        if (res.data.cellVolumes.length !== 0) {
          procurementFlag.current = true;
          const numVolumes: number[] = res.data.cellVolumes;
          const stringVolumes: string[] = numVolumes.map((value) =>
            value.toFixed(2)
          );
          setPadProcurementPriceText(stringVolumes);
        } else {
          setPadProcurementPriceText(
            new Array(48).fill(true).map((_) => "0.00")
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //販売計画のテキストフィールド
  const SellingPriceHandle = (inputBoxNumber: number, inputValue: string) => {
    const tempStringValues = padSellingPriceText.map((value) => value);
    tempStringValues[inputBoxNumber] = inputValue.replace(/-/g, "");
    setPadSellingPriceText(tempStringValues);
  };

  //調達計画のテキストフィールド
  const ProcurementPlanHandle = (
    inputBoxNumber: number,
    inputValue: string
  ) => {
    const tempStringValues = padProcurementPriceText.map((value) => value);
    tempStringValues[inputBoxNumber] = inputValue.replace(/-/g, "");
    setPadProcurementPriceText(tempStringValues);
  };

  const [bidBtnDisable, setBidBtnDisable] = useState<boolean[]>(
    new Array(48).fill(true).map((_) => true)
  );
  const [deleteBtnDisable, setDeleteBtnDisable] = useState<boolean[]>(
    new Array(48).fill(true).map((_) => true)
  );

  // 入札ボタンのdisable管理
  const bidDisabled = (
    responseData: resHourBid,
    priceAdjustment: string,
    deadlineTime: string
  ) => {
    const disable = [];
    for (let index = 0; index < 48; index++) {
      const status = responseData.bidingValue.dayBit[index].contractStatus;

      const deadline = createStartDeleteTime(
        deadlineTime,
        (index + 1).toString().padStart(2, "0"),
        format(dateValue.pickDate as Date, "yyyy-MM-dd")
      );

      const tableColor = TableColor(
        "rgb(255,255,255)",
        "rgb(255,255,255)",
        "rgb(200,200,200)"
      );

      if (
        tableColor[index] === "rgb(200,200,200)" ||
        priceAdjustment === "00"
      ) {
        disable.push(true);
      } else {
        if (
          status !== CONTRACT_STATUS.waitingContract &&
          status !== CONTRACT_STATUS.cancellationFailure &&
          status !== CONTRACT_STATUS.waitingBid &&
          status !== CONTRACT_STATUS.couldNot &&
          status !== CONTRACT_STATUS.contracted &&
          format(deadline, "yyyy/MM/dd HH:mm") >
            format(time, "yyyy/MM/dd HH:mm")
        ) {
          disable.push(false);
        } else {
          disable.push(true);
        }
      }
    }
    setBidBtnDisable(disable);
  };

  // 入札取消ボタンのdisable管理
  const deleteBidDisabled = (
    responseData: resHourBid,
    priceAdjustment: string
  ) => {
    const disable = [];
    const tableDisabled = TableDisabled();
    for (let index = 0; index < 48; index++) {
      const status = responseData.bidingValue.dayBit[index].contractStatus;
      const tableColor = TableColor(
        "rgb(255,255,255)",
        "rgb(255,255,255)",
        "rgb(200,200,200)"
      );
      if (
        tableColor[index] === "rgb(200,200,200)" ||
        priceAdjustment === "00"
      ) {
        disable.push(true);
      } else {
        if (
          status === CONTRACT_STATUS.waitingContract ||
          (status === CONTRACT_STATUS.cancellationFailure &&
            tableDisabled[index] === true)
        ) {
          disable.push(false);
        } else {
          disable.push(true);
        }
      }
    }
    setDeleteBtnDisable(disable);
  };

  // 一括入札ボタンのdisable管理
  const bulkBidDisable = () => {
    const array = responseData.bidingValue.dayBit.filter(
      (value, index) =>
        value.contractStatus !== CONTRACT_STATUS.waitingContract &&
        value.contractStatus !== CONTRACT_STATUS.cancellationFailure &&
        value.contractStatus !== CONTRACT_STATUS.waitingBid &&
        value.contractStatus !== CONTRACT_STATUS.couldNot &&
        value.contractStatus !== CONTRACT_STATUS.contracted &&
        bidBtnDisable[index] === false
    );

    let disable = false;
    if (array.length === 0) {
      disable = true;
    } else {
      disable = false;
    }
    return disable;
  };

  // 一括入札取消ボタンのdisable管理
  const bulkDeleteBidDisable = () => {
    const tableDisabled = TableDisabled();
    const array = responseData.bidingValue.dayBit.filter(
      (value, index) =>
        (value.contractStatus === CONTRACT_STATUS.waitingContract ||
          value.contractStatus === CONTRACT_STATUS.cancellationFailure) &&
        tableDisabled[index] === true
    );

    let disable = false;
    if (array.length === 0) {
      disable = true;
    } else {
      disable = false;
    }
    return disable;
  };

  //テーブルの非活性
  const TableDisabled = () => {
    const tableColor = TableColor(
      "rgb(255,255,255)",
      "rgb(255,255,255)",
      "rgb(200,200,200)"
    );
    const tableDisabled = [];
    for (let i = 0; i < tableColor.length; i++) {
      if (tableColor[i] === "rgb(255,255,255)") {
        tableDisabled[i] = true;
      } else {
        tableDisabled[i] = false;
      }
    }
    return tableDisabled;
  };

  //ボタンの非活性
  const ButtonDisabled = () => {
    const tableColor = TableColor(
      "rgb(255,255,255)",
      "rgb(255,255,255)",
      "rgb(200,200,200)"
    );
    const tableDisabled = [];
    for (let i = 0; i < tableColor.length; i++) {
      if (tableColor[i] === "rgb(200,200,200)" || priceAdjustment === "00") {
        tableDisabled[i] = false;
      } else {
        tableDisabled[i] = true;
      }
    }
    return tableDisabled;
  };

  //状況のテーブル
  const StatusTable = () => {
    const tableColor = TableColor(
      "rgb(255,255,255)",
      "rgb(255,255,255)",
      "rgb(200,200,200)"
    );
    const statusTableContent = ["◎", "〇", "✕"];
    const statusTable = [];
    for (let i = 0; i < tableColor.length; i++) {
      if (tableColor[i] === "rgb(255,255,0)") {
        statusTable[i] = statusTableContent[1]; //yellow
      } else if (tableColor[i] === "rgb(255,255,255)") {
        statusTable[i] = statusTableContent[0]; //white
      } else if (tableColor[i] === "rgb(200,200,200)") {
        statusTable[i] = statusTableContent[2]; //gray
      }
    }
    return statusTable;
  };

  //約定状況のテーブル
  const AgreementStatusTable = () => {
    const tableColor = TableColor(
      "rgb(255,204,255)",
      "rgb(255,255,204)",
      "rgb(204,255,204)"
    );
    const AgreementContent = [
      languageContext.words.waiting_for_bid,
      languageContext.words.waiting_for_contract,
      languageContext.words.under_contract,
      languageContext.words.contracted,
      languageContext.words.deleted,
      languageContext.words.unable_to_do,
      languageContext.words.partial_contracted,
    ];
    const agreementTable = [];
    for (let i = 0; i < tableColor.length; i++) {
      if (tableColor[i] === "rgb(255,204,255)") {
        agreementTable[i] = AgreementContent[2]; //yellow
      } else if (tableColor[i] === "rgb(255,255,204)") {
        agreementTable[i] = AgreementContent[1]; //white
      } else if (tableColor[i] === "rgb(204,255,204)") {
        agreementTable[i] = AgreementContent[3]; //gray
      }
      if (i === 15 || i === 21) {
        agreementTable[i] = AgreementContent[3]; //gray
      }
    }
    return agreementTable;
  };

  //市場詳細モーダル
  const [openMarketDetails, setOpenMarketDetails] =
    React.useState<boolean>(false);
  //入札モーダル
  const [openBid, setOpenBid] = React.useState<boolean>(false);
  //個別削除モーダル
  const [openBidDelete, setOpenBidDelete] = React.useState<boolean>(false);
  //一括入札モーダル
  const [openBulkBit, setOpenBulkBit] = React.useState<boolean>(false);
  //一括取消モーダル
  const [openBulkUndo, setOpenBulkUndo] = React.useState<boolean>(false);
  //自動化設定モーダル
  const [openAutomationSettings, setOpenAutomationSettings] =
    React.useState<boolean>(false);
  //入札締切・自動削除モーダル
  const [openAutoDeleteSettings, setOpenAutoDeleteSettings] =
    React.useState<boolean>(false);
  //入札制限モーダル
  const [openBidLimitSetting, setOpenBidLimitSetting] =
    React.useState<boolean>(false);

  // 発電計画
  const [pfpPowerItems, setPfpPowerItems] = useState<PfpType.powerPlanList[]>(
    []
  );

  // 販売計画
  // 自己託送
  const [pfpSaleSelfItems, setPfpSaleSelfItems] = useState<
    PfpType.saleSelfConsignmentList[]
  >([]);
  // スポット
  const [pfpSaleSpotItems, setPfpSaleSpotItems] = useState<
    PfpType.saleAreaList[]
  >([]);
  // 1時間前
  const [pfpSaleOneHourItems, setPfpSaleOneHourItems] = useState<
    PfpType.saleAreaList[]
  >([]);

  //調達計画
  // スポット
  const [pfpProvideSpotItems, setPfpProvideSpotItems] = useState<
    PfpType.provideAreaList[]
  >(null!);
  // １時間前
  const [pfpProvideOneHourItems, setPfpProvideOneHourItems] = useState<
    PfpType.provideAreaList[]
  >(null!);

  //エリアのハンドル
  const AreaHandleChange = (selectItem: number) => {
    setAreaId(selectItem.toString().padStart(2, "0"));
    testData.forEach((data, index) => {
      if (convertIdToAreaName(areaId) === data.areaName) {
        setDisplayData(testData[index]);
      }
    });
  };

  const autoBidding = async () => {
    await api
      .post(AUTO_BIDDING, {
        areaId: areaId,
        targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
      })
      .then((res) => {
        //
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //DBに設定内容を登録する
  const postAutoSetting = async (adjustId: string) => {
    await api
      .post(POST_AUTO_SETTING, {
        areaId: areaId,
        startTime: startTime,
        deleteTime: autoDelete,
        priceAdjust: adjustId,
        bidDeadline: deadlineTime,
        adjustCycle: adjustmentCycle,
        adjustValue: holdeAdjustmentAmount,
      })
      .then(async (res) => {
        await autoBidding();
      })
      .catch((err) => {
        console.log(err);
      });
    return "postSetting";
  };

  //DBから設定内容を取得する
  const getAutoSetting = async (responseData: resHourBid) => {
    await api
      .get(GET_AUTO_SETTING, {
        params: { areaId: areaId },
      })
      .then((res) => {
        setDeadlineTime(res.data.bidDeadline);
        setPriceAdjustment(res.data.priceAdjust);
        setAutoDelete(res.data.deleteTime);
        setStartTime(res.data.startTime);
        setAdjustmentCycle(res.data.adjustCycle);
        setHoldeAdjustmentAmount(res.data.adjustValue);
        if (res.data.priceAdjust === "01") {
          setSwitchActivity(false);
          setInputNumberActivity(true);
        } else if (res.data.priceAdjust === "00") {
          setSwitchActivity(true);
          setInputNumberActivity(false);
        }
        bidDisabled(responseData, res.data.priceAdjust, res.data.bidDeadline);
        deleteBidDisabled(responseData, res.data.priceAdjust);
      })
      .catch((err) => console.log(err));

    return "getSetting";
  };

  // エリアリストを取得
  const getAreaList = async () => {
    await api
      .get(GET_AREA_NAME_LIST)
      .then((res) => {
        setAreaList(res.data.map((v: any) => v.areaId));
        setAreaId(
          Number(areaId) === 0 || areaId === "00"
            ? res.data[0].areaId.toString().padStart(2, "0")
            : areaId.padStart(2, "0")
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 発電計画取得
  const getPfpPower = () => {
    const requestParam: PfpType.reqPowerForecastPlanPower = {
      areaId: areaId,
      targetDate: format(dateValue.pickDate as Date, "yyy/MM/dd"),
    };
    //console.log(requestParam);
    api
      .get(GET_PFP_POWER_URL, { params: requestParam })
      .then((res) => {
        //console.log(res.data);
        if (res.data !== undefined || res.data.powerPlan.length !== 0) {
          res.data.powerPlan.forEach((element: PfpType.powerPlanList) => {
            if (
              element.valueList === undefined ||
              element.valueList.length === 0
            ) {
              element.valueList = new Array(CELLCOUNT)
                .fill(true)
                .map((_, index) => ({
                  timeCd: (index + 1).toString().padStart(2, "0"),
                  value: 0,
                }));
            }
          });
          setPfpPowerItems(res.data.powerPlan);
        } else {
          const tempList: PfpType.powerPlanList = {
            powerId: 0,
            powerName: "",
            powerCategory: "",
            maxPower: 0,
            valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
              timeCd: (index + 1).toString().padStart(2, "0"),
              value: 0,
            })),
          };
          setPfpPowerItems([tempList]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 販売計画取得
  const getPfpSale = () => {
    const requestParam: PfpType.reqPowerForecastPlanSale = {
      areaId: areaId,
      targetDate: format(dateValue.pickDate as Date, "yyy/MM/dd"),
    };
    //console.log(requestParam);
    api
      .get(GET_PFP_SALE_URL, { params: requestParam })
      .then((res) => {
        //console.log(res.data);
        // 自己託送
        if (res.data !== undefined) {
          if (
            res.data.selfConsignmentList !== undefined &&
            res.data.selfConsignmentList.length !== 0
          ) {
            res.data.selfConsignmentList.forEach(
              (element: PfpType.saleSelfConsignmentList) => {
                if (element.valueList.length === 0) {
                  element.valueList = new Array(CELLCOUNT)
                    .fill(true)
                    .map((_, index) => ({
                      timeCd: (index + 1).toString().padStart(2, "0"),
                      value: 0,
                    }));
                }
              }
            );
            setPfpSaleSelfItems(res.data.selfConsignmentList);
            // } else {
            //   const tempList: PfpType.saleSelfConsignmentList = {
            //     customerName: "",
            //     //saleId: 0,
            //     saleGroupId: 0,
            //     salePlanKind: "12",
            //     demandId: 0,
            //     valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
            //       timeCd: (index + 1).toString().padStart(2, "0"),
            //       value: 0,
            //     })),
            //   };
            //   setPfpSaleSelfItems([tempList]);
          }
          // スポット
          if (
            res.data.spotList !== undefined &&
            res.data.spotList.length !== 0
          ) {
            res.data.spotList.forEach((element: PfpType.saleAreaList) => {
              if (element.valueList.length === 0) {
                element.valueList = new Array(CELLCOUNT)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
            });
            setPfpSaleSpotItems(res.data.spotList);
          } else {
            const tempList: PfpType.saleAreaList = {
              areaId: "",
              //saleId: 0,
              saleGroupId: 0,
              salePlanKind: "21",
              valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                value: 0,
              })),
            };
            setPfpSaleSpotItems([tempList]);
          }

          // 1時間前
          if (
            res.data.oneHourList !== undefined &&
            res.data.oneHourList.length !== 0
          ) {
            res.data.oneHourList.forEach((element: PfpType.saleAreaList) => {
              if (element.valueList.length === 0) {
                element.valueList = new Array(CELLCOUNT)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
            });
            setPfpSaleOneHourItems(res.data.oneHourList);
          } else {
            const tempList: PfpType.saleAreaList = {
              areaId: "",
              //saleId: 0,
              saleGroupId: 0,
              salePlanKind: "31",
              valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                value: 0,
              })),
            };
            setPfpSaleOneHourItems([tempList]);
          }
        } else {
          // const self: PfpType.saleSelfConsignmentList = {
          //   customerName: "",
          //   //saleId: 0,
          //   saleGroupId: 0,
          //   salePlanKind: "12",
          //   demandId: 0,
          //   valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
          //     timeCd: (index + 1).toString().padStart(2, "0"),
          //     value: 0,
          //   })),
          // };
          // setPfpSaleSelfItems([self]);
          const spot: PfpType.saleAreaList = {
            areaId: "",
            //saleId: 0,
            saleGroupId: 0,
            salePlanKind: "21",
            valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
              timeCd: (index + 1).toString().padStart(2, "0"),
              value: 0,
            })),
          };
          setPfpSaleSpotItems([spot]);
          const onehour: PfpType.saleAreaList = {
            areaId: "",
            //saleId: 0,
            saleGroupId: 0,
            salePlanKind: "31",
            valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
              timeCd: (index + 1).toString().padStart(2, "0"),
              value: 0,
            })),
          };
          setPfpSaleOneHourItems([onehour]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 調達計画取得
  const getPfpProvide = () => {
    const requestParam: PfpType.reqPowerForecastPlanProvide = {
      areaId: areaId,
      targetDate: format(dateValue.pickDate as Date, "yyy/MM/dd"),
    };
    //console.log(requestParam);
    api
      .get(GET_PFP_PROVIDE_URL, { params: requestParam })
      .then((res) => {
        // スポット
        if (res.data !== undefined) {
          if (
            res.data.spotList !== undefined &&
            res.data.spotList.length !== 0
          ) {
            res.data.spotList.forEach((element: PfpType.provideAreaList) => {
              if (element.valueList.length === 0) {
                element.valueList = new Array(CELLCOUNT)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
            });
            setPfpProvideSpotItems(res.data.spotList);
          } else {
            const tempList: PfpType.provideAreaList = {
              areaId: "",
              saleGroupId: 0,
              salePlanKind: "21",
              saleId: 0,
              valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                value: 0,
              })),
            };
            setPfpProvideSpotItems([tempList]);
          }

          // 1時間前
          if (
            res.data.oneHourList !== undefined &&
            res.data.oneHourList.length !== 0
          ) {
            res.data.oneHourList.forEach((element: PfpType.provideAreaList) => {
              if (element.valueList.length === 0) {
                element.valueList = new Array(CELLCOUNT)
                  .fill(true)
                  .map((_, index) => ({
                    timeCd: (index + 1).toString().padStart(2, "0"),
                    value: 0,
                  }));
              }
            });
            setPfpProvideOneHourItems(res.data.oneHourList);
          } else {
            const tempList: PfpType.provideAreaList = {
              areaId: "",
              saleId: 0,
              saleGroupId: 0,
              salePlanKind: "31",
              valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
                timeCd: (index + 1).toString().padStart(2, "0"),
                value: 0,
              })),
            };
            setPfpProvideOneHourItems([tempList]);
          }
        } else {
          const spot: PfpType.provideAreaList = {
            areaId: "",
            saleGroupId: 0,
            salePlanKind: "21",
            saleId: 0,
            valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
              timeCd: (index + 1).toString().padStart(2, "0"),
              value: 0,
            })),
          };
          setPfpProvideSpotItems([spot]);
          const onehour: PfpType.provideAreaList = {
            areaId: "",
            saleId: 0,
            saleGroupId: 0,
            salePlanKind: "31",
            valueList: new Array(CELLCOUNT).fill(true).map((_, index) => ({
              timeCd: (index + 1).toString().padStart(2, "0"),
              value: 0,
            })),
          };
          setPfpProvideOneHourItems([onehour]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 日付文字列を作成
  const createDateString = (tradeDate: string, time: string) => {
    const dateString = tradeDate + " " + time;
    return dateString;
  };

  // 商品IDを日時に変換
  const convertItemId = (itemId: string, tradeDate: string) => {
    let itemTime = new Date();
    timeData.forEach((time, index) => {
      if (itemId === (index + 1).toString().padStart(2, "0")) {
        itemTime = new Date(createDateString(tradeDate, time));
      }
    });

    return itemTime;
  };

  //入札締切・削除設定入力チェック
  const isInputSetting = (): boolean => {
    let result = false;

    //調整時間(調整開始時間 - 入札締切時間)
    const adjustTime =
      convertToTimeNum(startTime) - convertToTimeNum(deadlineTime);
    if (Number(startTime) > Number(deadlineTime)) {
      if (adjustTime > convertToAdjustCycleNum(adjustmentCycle)) {
        result = true;
      }
    }
    return result;
  };

  //開始時間、入札締切時間を数値に変換
  const convertToTimeNum = (timeId: string): number => {
    const initTime = 120;
    let time: number;

    if (timeId !== "00") {
      time = initTime + Number(timeId) * 60;
    } else {
      time = initTime;
    }
    return time;
  };

  //調整周期を数値に変換
  const convertToAdjustCycleNum = (timeId: string): number => {
    const initCycle = 10;
    let time: number;

    if (timeId !== "00") {
      time = initCycle + Number(timeId) * 10;
    } else {
      time = initCycle;
    }
    return time;
  };

  // 自動入札の開始時間ID、締切時間IDと対象商品の商品IDから、自動入札開始時刻、締切時刻を作成
  const createStartDeleteTime = (
    timeId: string,
    itemId: string,
    tradeDate: string
  ) => {
    //timeIdから開始時間を計算
    const startIdNum = 60 * (parseInt(timeId) + 2);
    const date = subMinutes(convertItemId(itemId, tradeDate), startIdNum);

    return date;
  };

  //調整周期のIDから表示する文字列を作成
  const createCycleStr = (id: string) => {
    let adjustCycleStr = "";

    for (let i = 0; i < AutomationSettingsList.adjustmentCycle.length; i++) {
      if (Number(id) === AutomationSettingsList.adjustmentCycle[i].id) {
        adjustCycleStr = AutomationSettingsList.adjustmentCycle[i].name;
        break;
      }
    }

    return adjustCycleStr;
  };

  //自動価格調整のスイッチのハンドル
  const AutomaticPriceAdjustmentHandle = async () => {
    setCheckDigOpen(false);
    if (switchActivity) {
      setSwitchActivity(false);
      setInputNumberActivity(true);
      setAutomationSetting(false);
      setAutoDeleteSetting(false);
      await postAutoSetting("01");
      await getAutoSetting(responseData);
    } else {
      setSwitchActivity(true);
      setInputNumberActivity(false);
      setAutomationSetting(true);
      setAutoDeleteSetting(true);
      await postAutoSetting("00");
      await getAutoSetting(responseData);
    }
  };

  //自動化設定ON時に表示する文字の色を赤にする
  const AutomaticPriceAdjustmentTextColor = () => {
    let textColor = "rgb(0,0,0)";
    if (priceAdjustment === "00") {
      textColor = "rgb(255,0,0)";
    } else {
      textColor = "";
    }
    return textColor;
  };

  //ニューメリックアップダウンの活性非活性の判定
  const TableVisiblity = (tableDisabled: boolean) => {
    return !tableDisabled || !inputNumberActivity;
  };

  //市場詳細入札モーダル開く
  const HandleOpenMarketDetails = () => setOpenMarketDetails(true);
  //市場詳細モーダル閉じる
  const HandleCloseMarketDetails = () => {
    setOpenMarketDetails(false);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //入札モーダル開く
  const HandleOpenBid = (buttonNumber: number) => {
    setCellNumber(buttonNumber);
    leavePageContext.setBlockLeave(false);
    setOpenBid(true);
  };
  //入札モーダル閉じる
  const HandleCloseBid = () => {
    setOpenBid(false);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //個別削除モーダル開く
  const HandleOpenBidDelete = (buttonNumber: number) => {
    setCellNumber(buttonNumber);
    setOpenBidDelete(true);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //個別削除モーダル閉じる
  const HandleCloseBidDelete = () => {
    setOpenBidDelete(false);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //一括入札モーダル開く
  const HandleOpenBulkBit = () => setOpenBulkBit(true);
  //一括入札モーダル閉じる
  const HandleCloseBulkBit = () => setOpenBulkBit(false);
  //一括取消モーダル開く
  const HandleOpenBulkUndo = () => {
    leavePageContext.setBlockLeave(false);
    setOpenBulkUndo(true);
  };
  //一括取消モーダル閉じる
  const HandleCloseBulkUndo = () => {
    setOpenBulkUndo(false);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //自動化設定モーダル開く
  const HandleOpenAutomationSettings = () => setOpenAutomationSettings(true);
  //自動化設定モーダル閉じる
  const HandleCloseAutomationSettings = () => {
    setOpenAutomationSettings(false);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //入札締切・自動削除設定モーダル開く
  const HandleOpenAutoDeleteSettings = () => setOpenAutoDeleteSettings(true);
  //入札締切・自動削除設定モーダル閉じる
  const HandleCloseAutoDeleteSettings = () => {
    setOpenAutoDeleteSettings(false);
    if (changeFlag.current === true) {
      leavePageContext.setBlockLeave(true);
    }
  };
  //入札制限モーダル開く
  const HandleOpenBidLimitSetting = () => setOpenBidLimitSetting(true);
  //入札制限モーダル閉じる
  const HandleCloseBidLimitSetting = () => setOpenBidLimitSetting(false);
  //カスタムダイアログのフラグ
  const [digOpen, setDigOpen] = useState<boolean>(false);
  //カスタムダイアログに表示するメッセージ
  const [dialogMessage, setDialogMessage] = useState<string>("");

  //入札自動化確認ダイアログのフラグ
  const [checkDigOpen, setCheckDigOpen] = useState<boolean>(false);
  //カスタムダイアログに表示するメッセージ
  const [checkDigMessage, setCheckDigMessage] = useState<string>("");

  // カスタムダイアろグ表示後処理
  const acceptHandler = () => {
    // なし
  };

  //販売・調達計画保存
  const insertSalesValues = async () => {
    await api
      .post(POST_SALES_PLAN, {
        areaId: areaId,
        targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
        values: padSellingPriceText as unknown as number[],
      })
      .then((res) => {
        if (procurementFlag.current) {
          insertProcurementValues();
        } else {
          setDialogMessage(languageContext.words.intraday_save_supply_plan);
          setDigOpen(true);
          leavePageContext.setBlockLeave(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(
          languageContext.words.intraday_save_failed_supply_plan
        );
        setDigOpen(true);
      });
  };

  const insertProcurementValues = () => {
    api
      .post(POST_PROCUREMENT_PLAN, {
        areaId: areaId,
        targetDate: format(dateValue.pickDate as Date, "yyyy-MM-dd"),
        values: padProcurementPriceText as unknown as number[],
      })
      .then((res) => {
        setDialogMessage(
          languageContext.words.save_sales_plan_procurement_plan
        );
        setDigOpen(true);
        leavePageContext.setBlockLeave(false);
      })
      .catch((err) => {
        console.log(err);
        setDialogMessage(
          languageContext.words.cannot_save_sales_plan_procurement_plan
        );
        setDigOpen(true);
      });
  };

  //GC直前のコマのインデックスを取得
  const CreateEditableFirstTime = () => {
    const editableTableList = TableColor(
      "rgb(255,255,255)",
      "rgb(255,255,255)",
      "rgb(200,200,200)"
    );
    let editableFirstTime = 0;
    for (let i = 0; i < editableTableList.length; i++) {
      if (editableTableList[i] === "rgb(255,255,255)") {
        editableFirstTime = i;
        break;
      }
    }
    return editableFirstTime;
  };

  //ツールチップに表示する文字列を作成
  const createToolTipText = (
    lowerEnd: string,
    upperEnd: string,
    unit: string
  ) => {
    if (languageContext.mode === "jp") {
      return (
        convertToLocaleString(Number(lowerEnd)) +
        "～" +
        convertToLocaleString(Number(upperEnd)) +
        unit +
        "の範囲で入力してください。"
      );
    } else {
      return (
        "Enter in the range " +
        convertToLocaleString(Number(lowerEnd)) +
        " to " +
        convertToLocaleString(Number(upperEnd)) +
        " " +
        unit
      );
    }
  };

  //小数点第二位までかつカンマ区切りに変換
  const convertToLocaleString = (value: number) => {
    return Number(value).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  //保存ボタンのイベント
  const SaveButtonEvent = async () => {
    await insertSalesValues();
    getLimitData();
  };

  //左側のテーブルのレンダー
  const BidManagementTableRender = () => {
    const tableColor = TableColor(
      "",
      "",
      PaletteMode === "dark" ? "rgb(90,90,90)" : "rgb(200,200,200)"
    );
    const tableDisabled = TableDisabled();

    // フォーカスアウト時にテキストフィールドに表示している値をtoFixedする
    const processText = (
      index: number,
      text: string[],
      setText: React.Dispatch<React.SetStateAction<string[]>>
    ) => {
      if (Math.floor(Number(text[index]) * 10) / 10 > PLAN_MAX_VALUE) {
        text[index] = PLAN_MAX_VALUE.toString();
      }
      const tempText = text.map((value) => value);
      tempText[index] = (Math.floor(Number(text[index]) * 10) / 10).toFixed(2);
      setText(tempText);
    };

    return (
      <TableContainer
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <StyledTable
          sx={{
            borderCollapse: "separate",
            minWidth: "100%",
            height: "100%",
          }}
        >
          <TableHead
            sx={{
              height: "auto",
              width: "100%",
              display: "block",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <TableRow sx={{ display: "flex" }}>
              <HeaderCell sx={{ width: "110px" }}>
                {languageContext.words.timeB}
              </HeaderCell>
              <TableRow sx={{ display: "block" }}>
                <HeaderCell
                  sx={{
                    display: "block",
                    width: "calc(100% - 1px)",
                  }}
                  style={{ height: "calc(20% + 1px)" }}
                >
                  {languageContext.words.market_status_entire_area}
                </HeaderCell>
                <TableRow sx={{ display: "flex" }}>
                  <HeaderCell
                    sx={{
                      width: "40px",
                    }}
                    title={languageContext.words.status_jokyo}
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.status_jokyo}
                    </Box>
                  </HeaderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.sell +
                      " " +
                      languageContext.words.bidding_volume +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.sell}
                      <br />
                      {languageContext.words.bidding_volume}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.sell +
                      " " +
                      languageContext.words.lowest_price_sell +
                      " " +
                      "(" +
                      languageContext.words.yen_kwh +
                      ")"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.sell}
                      <br />
                      {languageContext.words.lowest_price_sell}
                      <br />({languageContext.words.yen_kwh})
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.buy +
                      " " +
                      languageContext.words.bidding_volume +
                      " " +
                      "(MWh / h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.buy}
                      <br />
                      {languageContext.words.bidding_volume}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.buy +
                      " " +
                      languageContext.words.highest_price_buy +
                      " " +
                      "(" +
                      languageContext.words.yen_kwh +
                      ")"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.buy}
                      <br />
                      {languageContext.words.highest_price_buy}
                      <br />({languageContext.words.yen_kwh})
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.latest +
                      " " +
                      languageContext.words.contrac_price +
                      " " +
                      "(" +
                      languageContext.words.yen_kwh +
                      ")"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.latest}
                      <br />
                      {languageContext.words.contrac_price}
                      <br />({languageContext.words.yen_kwh})
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.average +
                      " " +
                      languageContext.words.contrac_price +
                      " " +
                      "(" +
                      languageContext.words.yen_kwh +
                      ")"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.average}
                      <br />
                      {languageContext.words.contrac_price}
                      <br />({languageContext.words.yen_kwh})
                    </Box>
                  </AmountHedderCell>
                </TableRow>
              </TableRow>
              <TableRow sx={{ display: "block" }}>
                <HeaderCell
                  sx={{
                    display: "block",
                    width: "calc(100% - 1px)",
                  }}
                >
                  <span style={{ marginRight: "5px" }}>
                    {languageContext.words.in_house_status}
                  </span>
                  <SaveDataButton
                    hidden={hidden}
                    disabled={!TableDisabled()[47] || hidden}
                    message={languageContext.words.saved}
                    onAccept={SaveButtonEvent}
                  />
                </HeaderCell>
                <TableRow sx={{ display: "flex" }}>
                  <AmountHedderCell
                    title={
                      languageContext.words.sale +
                      " " +
                      languageContext.words.plan +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.sale}
                      <br />
                      {languageContext.words.plan}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.supply +
                      " " +
                      languageContext.words.plan +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.supply}
                      <br />
                      {languageContext.words.plan}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.sell +
                      " " +
                      languageContext.words.bidding_volume +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.sell}
                      <br />
                      {languageContext.words.bidding_volume}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.sell +
                      " " +
                      languageContext.words.contract_quantity +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.sell}
                      <br />
                      {languageContext.words.contract_quantity}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.buy +
                      " " +
                      languageContext.words.bidding_volume +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.buy}
                      <br />
                      {languageContext.words.bidding_volume}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                  <AmountHedderCell
                    title={
                      languageContext.words.buy +
                      " " +
                      languageContext.words.contract_quantity +
                      " " +
                      "(MWh/h)"
                    }
                  >
                    <Box sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                      {languageContext.words.buy}
                      <br />
                      {languageContext.words.contract_quantity}
                      <br />
                      (MWh/h)
                    </Box>
                  </AmountHedderCell>
                </TableRow>
              </TableRow>
            </TableRow>
          </TableHead>
          <ScrollSyncPane group="vertical">
            <TableBody
              sx={{
                width: "100%",
                height: "647px",
                display: "block",
                overflowX: "hidden",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "10px",
                  backgroundColor: "transparent",
                  height: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#AAA",
                  borderRadius: "10px",
                },
              }}
            >
              {responseData.areaValue.dayBit.map((marketData, index) => (
                <TableRow key={responseData.areaValue.blockNumber}>
                  <TableCell
                    key={"timeCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                    sx={{ width: "110px" }}
                  >
                    {timeData[index]}～{timeData[index + 1]}
                  </TableCell>
                  <TableCell
                    key={"statusCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                    sx={{ width: "40px" }}
                  >
                    {marketData.productStatus}
                  </TableCell>
                  <AmountTableCell
                    key={"saleBitValueCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(marketData.saleBitValue)}
                    {/* 売り入札量 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"saleBitLowPriceCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(marketData.saleBitLowPrice)}
                    {/* 売り最安値 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"buyBitValue" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(marketData.buyBitValue)}
                    {/* 買い入札量 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"buyBitHighPriceCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(marketData.buyBitHighPrice)}
                    {/* 買い最高値 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"newExecutionPriceCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(marketData.newExecutionPrice)}
                    {/* 最新約定価格 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"averageExecutionPriceCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(marketData.averageExecutionPrice)}
                    {/* 平均約定価格 */}
                  </AmountTableCell>
                  <TableCell
                    key={"companySalesPlanCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                    sx={{ width: "74px" }}
                  >
                    <TextField
                      type="number"
                      disabled={TableVisiblity(tableDisabled[index])}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: PLAN_MAX_VALUE,
                          step: "0.1",
                          style: {
                            padding: 0,
                            textAlign: "right",
                            fontSize: "14px",
                            backgroundColor: TableVisiblity(
                              tableDisabled[index]
                            )
                              ? PaletteMode === "dark"
                                ? "rgb(90,90,90)"
                                : "rgb(200,200,200)"
                              : "",
                          },
                        },
                      }}
                      onFocus={(e) => {
                        if (padSellingPriceText[index] === "0.00") {
                          e.currentTarget.select();
                        }
                      }}
                      onBlur={(e) => {
                        processText(
                          index,
                          padSellingPriceText,
                          setPadSellingPriceText
                        );
                      }}
                      onKeyDown={(event) => {
                        if (
                          event.key === "e" ||
                          event.key === "E" ||
                          event.key === "-"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        SellingPriceHandle(index, event.target.value);
                        leavePageContext.setBlockLeave(true);
                        changeFlag.current = true;
                      }}
                      value={padSellingPriceText[index]}
                    ></TextField>
                  </TableCell>
                  <TableCell
                    key={"companyProcurementPlanCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                    sx={{ width: "74px" }}
                  >
                    <TextField
                      type="number"
                      disabled={TableVisiblity(tableDisabled[index])}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: PLAN_MAX_VALUE,
                          step: "0.1",
                          style: {
                            padding: 0,
                            textAlign: "right",
                            fontSize: "14px",
                            backgroundColor: TableVisiblity(
                              tableDisabled[index]
                            )
                              ? PaletteMode === "dark"
                                ? "rgb(90,90,90)"
                                : "rgb(200,200,200)"
                              : "",
                          },
                        },
                      }}
                      onFocus={(e) => {
                        if (padProcurementPriceText[index] === "0.00") {
                          e.currentTarget.select();
                        }
                      }}
                      onBlur={(e) => {
                        processText(
                          index,
                          padProcurementPriceText,
                          setPadProcurementPriceText
                        );
                      }}
                      onKeyDown={(event) => {
                        if (
                          event.key === "e" ||
                          event.key === "E" ||
                          event.key === "-"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(event) => {
                        ProcurementPlanHandle(index, event.target.value);
                        leavePageContext.setBlockLeave(true);
                        changeFlag.current = true;
                      }}
                      value={padProcurementPriceText[index]}
                    ></TextField>
                  </TableCell>
                  <AmountTableCell
                    key={"companySaleBitValueCell" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {responseData.bidingValue.dayBit[index].contractStatus !==
                    CONTRACT_STATUS.canceled
                      ? convertToLocaleString(
                          responseData.companyValue.dayBit[index].saleBitValue
                        )
                      : "0.00"}
                    {/* 売り入札量 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"companySalesContractValue" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(
                      responseData.companyValue.dayBit[index].salesContractValue
                    )}
                    {/* 売り約定量 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"companyBuyBitValue" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {responseData.bidingValue.dayBit[index].contractStatus !==
                    CONTRACT_STATUS.canceled
                      ? convertToLocaleString(
                          responseData.companyValue.dayBit[index].buyBitValue
                        )
                      : "0.00"}
                    {/* 買い入札量 */}
                  </AmountTableCell>
                  <AmountTableCell
                    key={"companyBuyContractValue" + index}
                    style={{ backgroundColor: tableColor[index] }}
                  >
                    {convertToLocaleString(
                      responseData.companyValue.dayBit[index].buyContractValue
                    )}
                    {/* 買い約定量 */}
                  </AmountTableCell>
                </TableRow>
              ))}
            </TableBody>
          </ScrollSyncPane>
        </StyledTable>
      </TableContainer>
    );
  };

  //入札状況のテーブルのレンダー
  const BiddingStatusTableRender = () => {
    const agreementTableData = AgreementStatusTable();
    const buttonDisabled = ButtonDisabled();
    const tableBodyColor = TableColor(
      "",
      "",
      PaletteMode === "dark" ? "rgb(90,90,90)" : "rgb(200,200,200)"
    );
    const contractTableColor = TableColor(
      "rgb(38,181,246)",
      "rgb(255,156,10)",
      "rgb(150,150,150)"
    );

    return (
      <TableContainer
        sx={{
          minWidth: "100%",
          height: "100%",
        }}
      >
        <StyledTable
          sx={{
            minWidth: "100%",
            height: "100%",
            borderCollapse: "separate",
          }}
          aria-label="spanning table"
        >
          <TableHead
            sx={{
              height: "auto",
              width: "100%",
              display: "block",
              position: "sticky",
              overflow: "auto",
              top: 0,
              zIndex: 1,
            }}
          >
            <TableRow sx={{ display: "block" }}>
              <HeaderCell
                sx={{
                  display: "block",
                  width: "calc(100% - 11px)",
                }}
              >
                {languageContext.words.details_of_bid_status}
              </HeaderCell>
              <TableRow sx={{ display: "flex" }}>
                <HeaderCell sx={{ width: "110px" }}>
                  {languageContext.words.bidding_time_jikoku}
                </HeaderCell>
                <AmountHedderCell title={languageContext.words.salebuy_type}>
                  <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {languageContext.words.salebuy_type}
                  </Box>
                </AmountHedderCell>
                <AmountHedderCell
                  title={
                    languageContext.words.bid_price +
                    " " +
                    "(" +
                    languageContext.words.yen_kwh +
                    ")"
                  }
                >
                  <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {languageContext.words.bid_price}
                    <br />({languageContext.words.yen_kwh})
                  </Box>
                </AmountHedderCell>
                <AmountHedderCell
                  title={
                    languageContext.words.contrac_price +
                    " " +
                    "(" +
                    languageContext.words.yen_kwh +
                    ")"
                  }
                >
                  <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {languageContext.words.contrac_price}
                    <br />({languageContext.words.yen_kwh})
                  </Box>
                </AmountHedderCell>
                <AmountHedderCell
                  title={languageContext.words.bidding_volume + " " + "(MWh/h)"}
                >
                  <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {languageContext.words.bidding_volume}
                    <br />
                    (MWh/h)
                  </Box>
                </AmountHedderCell>
                <AmountHedderCell
                  title={
                    languageContext.words.contract_quantity + " " + "(MWh/h)"
                  }
                >
                  <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {languageContext.words.contract_quantity}
                    <br />
                    (MWh/h)
                  </Box>
                </AmountHedderCell>
                <AmountHedderCell
                  title={languageContext.words.contract_status_jokyo}
                >
                  <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                    {languageContext.words.contract_status_jokyo}
                  </Box>
                </AmountHedderCell>
                <HeaderCell sx={{ width: "166px" }}>
                  <Box sx={{ display: "flex" }}>
                    <StyledButton
                      disabled={
                        !TableDisabled()[47] ||
                        !buttonDisabled[47] ||
                        bulkBidDisable()
                      }
                      variant="outlined"
                      onClick={HandleOpenBulkBit}
                      sx={{ margin: "0px 0px 0px 5px" }}
                    >
                      {languageContext.words.bulk_bidding}
                    </StyledButton>
                    {BulkBit(
                      TableColor,
                      openBulkBit,
                      HandleCloseBulkBit,
                      responseData.areaValue.dayBit,
                      responseData.bidingValue.dayBit,
                      format(dateValue.pickDate as Date, "yyyy-MM-dd"),
                      areaId,
                      bidLimit as bitLimmitReq,
                      getIntradayData,
                      setDigOpen,
                      setDialogMessage,
                      padSellingPriceText,
                      padProcurementPriceText,
                      bidBtnDisable,
                      hidden,
                      createToolTipText
                    )}
                    <StyledButton
                      disabled={
                        !TableDisabled()[47] ||
                        bulkDeleteBidDisable() ||
                        !buttonDisabled[47]
                      }
                      variant="outlined"
                      onClick={HandleOpenBulkUndo}
                      sx={{ margin: "0px 0px 0px 5px" }}
                    >
                      {languageContext.words.bulk_revocation}
                    </StyledButton>
                    {BulkUndo(
                      openBulkUndo,
                      HandleCloseBulkUndo,
                      CreateEditableFirstTime(),
                      TableColor(
                        "rgb(255,255,255)",
                        "rgb(255,255,255)",
                        "rgb(200,200,200)"
                      ),
                      responseData.bidingValue.dayBit,
                      responseData.areaValue.dayBit,
                      format(dateValue.pickDate as Date, "yyyy-MM-dd"),
                      areaId,
                      setDigOpen,
                      setDialogMessage,
                      getIntradayData,
                      hidden,
                      createTypeText,
                      createStatusText
                    )}
                  </Box>
                </HeaderCell>
              </TableRow>
            </TableRow>
          </TableHead>
          <ScrollSyncPane group="vertical">
            <TableBody
              sx={{
                width: "100%",
                height: "648px",
                display: "block",
                overflowX: "hidden",
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "10px",
                  backgroundColor: "transparent",
                  height: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#AAA",
                  borderRadius: "10px",
                },
              }}
            >
              {responseData.bidingValue.dayBit.map((bidData, index) => {
                return (
                  <TableRow key={responseData.bidingValue.blockNumber}>
                    <TableCell
                      key={"timeCell" + index}
                      style={{ backgroundColor: tableBodyColor[index] }}
                      sx={{ width: "110px" }}
                    >
                      {timeData[index]}～{timeData[index + 1]}
                    </TableCell>
                    <TableCell
                      key={"typeCell" + index}
                      style={{ backgroundColor: tableBodyColor[index] }}
                      sx={{ width: "74px" }}
                    >
                      {bidData.contractStatus !== CONTRACT_STATUS.canceled
                        ? createTypeText(bidData.type)
                        : ""}
                    </TableCell>
                    <AmountTableCell
                      key={"bidPriceCell" + index}
                      style={{ backgroundColor: tableBodyColor[index] }}
                    >
                      {bidData.contractStatus !== CONTRACT_STATUS.canceled
                        ? convertToLocaleString(bidData.bidPrice)
                        : "0.00"}
                      {/* 入札価格 */}
                    </AmountTableCell>
                    <AmountTableCell
                      key={"contractPriceCell" + index}
                      style={{ backgroundColor: tableBodyColor[index] }}
                    >
                      {convertToLocaleString(bidData.contractPrice)}
                      {/* 約定価格 */}
                    </AmountTableCell>
                    <AmountTableCell
                      key={"bidVolumeCell" + index}
                      style={{ backgroundColor: tableBodyColor[index] }}
                    >
                      {bidData.contractStatus !== CONTRACT_STATUS.canceled
                        ? convertToLocaleString(bidData.bidValue)
                        : "0.00"}
                      {/* 入札量 */}
                    </AmountTableCell>
                    <AmountTableCell
                      key={"contractValueCell" + index}
                      style={{ backgroundColor: tableBodyColor[index] }}
                    >
                      {convertToLocaleString(bidData.contractValue)}
                      {/* 約定量 */}
                    </AmountTableCell>
                    <TableCell
                      key={"contractStatusCell" + index}
                      style={{ backgroundColor: tableBodyColor[index] }}
                      sx={{ width: "74px" }}
                      title={createStatusText(bidData.contractStatus)}
                    >
                      <Box
                        style={{
                          color:
                            bidData.contractStatus ===
                            CONTRACT_STATUS.contracted
                              ? "orange"
                              : "",
                        }}
                        sx={{
                          width: "70px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {createStatusText(bidData.contractStatus)}
                      </Box>
                    </TableCell>
                    <TableCell
                      key={"bidProcessingCell" + index}
                      style={{ backgroundColor: tableBodyColor[index] }}
                      sx={{ width: "166px" }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <StyledButton
                          variant="outlined"
                          onClick={() => HandleOpenBid(index)}
                          disabled={bidBtnDisable[index] || hidden}
                          sx={{ margin: "0px 0px 0px 13px" }}
                        >
                          {languageContext.words.bidding}
                        </StyledButton>
                        <StyledButton
                          variant="outlined"
                          onClick={() => {
                            HandleOpenBidDelete(index);
                            leavePageContext.setBlockLeave(false);
                          }}
                          disabled={deleteBtnDisable[index] || hidden}
                          sx={{
                            margin: "0px 0px 0px 13px",
                            whiteSpace: "nowrap",
                          }}
                          title={languageContext.words.cancellation}
                        >
                          <Box
                            sx={{
                              maxWidth: "52px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {languageContext.words.cancellation}
                          </Box>
                        </StyledButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </ScrollSyncPane>
        </StyledTable>
      </TableContainer>
    );
  };

  return (
    <ScrollSync>
      <div
        //ダークモード対応
        className={`cn-main-display ${
          PaletteMode === "dark" ? "dark" : "light"
        }`}
        style={{ display: "flex" }}
      >
        <Box sx={{ width: "59%", height: "100%", margin: "0px 10px 0px 0px" }}>
          <Box sx={{ width: "100%", height: "105px" }}>
            <Stack spacing={1} direction="row">
              <CustomSelectList
                label={""}
                value={areaId}
                options={areaOptions(areaList, false)}
                onChange={(e: number) => AreaHandleChange(e)}
              />
              <GlobalDatePicker isDipsTodayBtn={true} maxDate={maxDate} />
            </Stack>
            <Stack
              spacing={1}
              direction="row"
              style={{ margin: "20px 0px 0px 0px" }}
            >
              <label>*{languageContext.words.example_one_hour}</label>
            </Stack>
          </Box>
          <Box sx={{ width: "100%", height: "88%" }}>
            {BidManagementTableRender()}
          </Box>
        </Box>
        <Box sx={{ width: "41%", height: "100%", margin: "0px 0px 0px 10px" }}>
          <Box sx={{ width: "100%", height: "105px" }}>
            <Stack
              spacing={1}
              direction="row"
              style={{ padding: "20px 0px 0px 0px" }}
            >
              <StyledButton
                // disabled={!TableDisabled()[47]}
                onClick={() => {
                  HandleOpenMarketDetails();
                  leavePageContext.setBlockLeave(false);
                }}
                variant="outlined"
              >
                {languageContext.words.market_details}
              </StyledButton>
              {MarketDetails(
                openMarketDetails,
                HandleCloseMarketDetails,
                areaId
              )}
              <StyledButton
                variant="outlined"
                onClick={HandleOpenBidLimitSetting}
              >
                {languageContext.words.bidding_limit}
              </StyledButton>
              {BidLimitSetting(
                openBidLimitSetting,
                HandleCloseBidLimitSetting,
                bidLimit as bitLimmitReq,
                setBidLimit,
                areaId,
                hidden
              )}
              <StyledButton
                disabled={switchActivity || hidden }
                variant="outlined"
                onClick={() => {
                  HandleOpenAutomationSettings();
                  leavePageContext.setBlockLeave(false);
                }}
              >
                {languageContext.words.automatic_setting}
              </StyledButton>
              {AutomationSettings(
                AutomationSettingsList,
                openAutomationSettings,
                HandleCloseAutomationSettings,
                areaId,
                setSwitchActivity,
                startTime,
                autoDelete,
                priceAdjustment,
                deadlineTime,
                adjustmentCycle,
                holdeAdjustmentAmount,
                setDeadlineTime,
                setPriceAdjustment,
                setAutoDelete,
                setStartTime,
                setAdjustmentCycle,
                setHoldeAdjustmentAmount,
                hidden
              )}
              <StyledButton
                disabled={switchActivity || hidden }
                variant="outlined"
                onClick={() => {
                  HandleOpenAutoDeleteSettings();
                  leavePageContext.setBlockLeave(false);
                }}
              >
                {languageContext.words.automatic_delete_deadline_setting}
              </StyledButton>
              {AutoDeleteSettings(
                AutomationSettingsList,
                openAutoDeleteSettings,
                HandleCloseAutoDeleteSettings,
                areaId,
                setSwitchActivity,
                startTime,
                autoDelete,
                priceAdjustment,
                deadlineTime,
                adjustmentCycle,
                holdeAdjustmentAmount,
                setDeadlineTime,
                setPriceAdjustment,
                setAutoDelete,
                setStartTime,
                setAdjustmentCycle,
                setHoldeAdjustmentAmount,
                getIntradayData,
                hidden
              )}
            </Stack>
            <Stack direction="row" style={{ padding: "15px 0px 5px 0px" }}>
              <label
                style={{
                  color: "rgb(150, 150, 150)",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {languageContext.words.cycle}
              </label>
              <label
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  // width: "50px",
                }}
              >
                {createCycleStr(adjustmentCycle)}
              </label>
              <label
                style={{
                  color: "rgb(150, 150, 150)",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  width: languageContext.mode === "en" ? "143px" : "70px",
                  paddingLeft: "5px",
                }}
              >
                {languageContext.words.adjustment_amount_comma}
              </label>
              <label
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {holdeAdjustmentAmount.toFixed(2)}(
                {languageContext.words.yen_kwh})
              </label>
              <label
                style={{
                  color: "rgb(150, 150, 150)",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "5px",
                }}
              >
                {languageContext.words.status_jotai_colon}
              </label>
              <label
                style={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  width: "150px",
                  color: AutomaticPriceAdjustmentTextColor(),
                }}
              >
                {priceAdjustment === "00"
                  ? languageContext.words.auto_bidding
                  : languageContext.words.auto_bidding_off}
              </label>
              <FormControlLabel
                sx={{
                  marginLeft: languageContext.mode === "en" ? "2%" : "18%",
                  marginRight: "0px",
                  fontSize: "14px",
                  visibility: hidden ? "hidden" : "visible",
                  width: languageContext.mode === "en" ? "155px" : "157px",
                }}
                control={
                  <Switch
                    disabled={!TableDisabled()[47] || hidden}
                    onChange={() => {
                      if (switchActivity) {
                        setCheckDigOpen(true);
                        setCheckDigMessage(languageContext.words.auto_bidding_stop_check_msg);
                      } else {
                        if (isInputSetting()) {
                          setCheckDigOpen(true);
                          setCheckDigMessage(languageContext.words.auto_bidding_start_check_msg);
                        } else {
                          setSwitchActivity(false);
                          setDialogMessage(
                            languageContext.words.bid_automation_failed +
                              "\n" +
                              languageContext.words.reconsider_automatic_setting
                          );
                          setDigOpen(true);
                        }
                      }
                    }}
                    checked={switchActivity}
                    style={{ fontSize: "14px" }}
                  />
                }
                label={
                  <Typography style={{ fontSize: "14px" }}>
                    {languageContext.words.automation_setting_enabled}
                  </Typography>
                }
                color="primary"
                style={{ fontSize: "14px" }}
              />
              <CustomDialog
                title={languageContext.words.auto_bidding_check_title}
                message={checkDigMessage}
                buttonType={ButtonType.OkCancel}
                open={checkDigOpen}
                onAccept={AutomaticPriceAdjustmentHandle}
                onClose={() => setCheckDigOpen(false)}
              />
            </Stack>
          </Box>
          <Box sx={{ width: "100%", height: "88%" }}>
            {BiddingStatusTableRender()}
            {Bid(
              openBid,
              HandleCloseBid,
              cellNumber,
              bidLimit as bitLimmitReq,
              convertIdToAreaName(areaId),
              areaId,
              format(dateValue.pickDate as Date, "yyyy-MM-dd"),
              setDigOpen,
              setDialogMessage,
              getIntradayData,
              responseData.bidingValue.dayBit,
              createToolTipText
            )}
            {BidDelete(
              openBidDelete,
              HandleCloseBidDelete,
              cellNumber,
              areaId,
              convertIdToAreaName(areaId),
              format(dateValue.pickDate as Date, "yyyy-MM-dd"),
              setDigOpen,
              setDialogMessage,
              getIntradayData
            )}
          </Box>
          <CustomDialog
            title={languageContext.words.one_hour_ago_bidding}
            message={dialogMessage}
            buttonType={ButtonType.OkOnly}
            open={digOpen}
            onAccept={acceptHandler}
            onClose={() => setDigOpen(false)}
          />
        </Box>
      </div>
    </ScrollSync>
  );
};

export default IntradayBidding;
